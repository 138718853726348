import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchange } from '@fortawesome/pro-regular-svg-icons'

import logo from '../../img/cc-light-alt.png'

export const AdminHeader = () => {
  const { pathname } = useLocation();

  return (
    <header>
      <img src={logo} style={{width: 80}} />
      <nav>
        <Link to="/admin" className={pathname.endsWith('/admin') ? 'selected':''}>Home</Link>
        <Link to="/admin/accounts" className={pathname.endsWith('/accounts') ? 'selected':''}>Accounts</Link>
        <Link to="/admin/users" className={pathname.endsWith('/users') ? 'selected':''}>Users</Link>
        <Link to="/logout">Logout</Link>
      </nav>
    </header>
  )
}
