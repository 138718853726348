import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { faDraftingCompass, faUserChart } from '@fortawesome/pro-regular-svg-icons'
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'

import { ResourceUserRole } from 'services/src/dto/account'
import { Invitation, InvitationStatus } from 'services/src/dto/invitation'
import { ResourceUsers, UserEx } from 'components/src/resourceUsers'
import { ChangeRoleModal } from 'components/src/resourceUsers/ChangeRoleModal'
import { InfoModal } from 'components/src/modal/InfoModal'
import { ConfirmModal } from 'components/src/modal/ConfirmModal'
import { deleteInvitation, deletePhaseUser, resendInvitation, updateProjectUserRole } from 'services/src/api'
import { ProjectEx, useCurrentUserAccount, useEditProject, useRights, UserType } from 'services/src/state'
import { useSaving } from 'components/src/saving'
import { useAlert } from 'components/src/alerts'
import { ProjectStatus } from 'services/src/dto/project'
import { TabBar, TabItem } from 'components/src/tabs'
import { InvitationList } from '../../settings/InvitationList'
import { InviteModal } from '../common/InviteModal'

export const PhaseStaff: React.FC<{ phaseId: string }> = ({ phaseId }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { url, path } = useRouteMatch()
  const [, setSaving] = useSaving()
  const { alertDanger, alertSuccess } = useAlert()
  const rights = useRights()
  const [account] = useCurrentUserAccount()

  const { project, setProject } = useEditProject()

  console.log('PhaseStaff', project)

  const phase = useMemo(() => project.phases.find((x) => x.id === phaseId)!, [project, phaseId])

  const roles = useMemo(
    () => [
      {
        role: ResourceUserRole.Owner,
        label: t(rights?.userType === UserType.client ? `General.ClientPhaseRoles.${ResourceUserRole.Owner}` : `General.PhaseRoles.${ResourceUserRole.Owner}`)
      },
      {
        role: ResourceUserRole.Administrator,
        label: t(rights?.userType === UserType.client ? `General.ClientPhaseRoles.${ResourceUserRole.Administrator}` : `General.PhaseRoles.${ResourceUserRole.Administrator}`)
      },
      {
        role: ResourceUserRole.Contributor,
        label: t(rights?.userType === UserType.client ? `General.ClientPhaseRoles.${ResourceUserRole.Contributor}` : `General.PhaseRoles.${ResourceUserRole.Contributor}`)
      },
      {
        role: ResourceUserRole.Guest,
        label: t(rights?.userType === UserType.client ? `General.ClientPhaseRoles.${ResourceUserRole.Guest}` : `General.PhaseRoles.${ResourceUserRole.Guest}`)
      }
    ],
    [rights, t]
  )

  const [changeRole, setChangeRole] = useState<UserEx>()
  const [confirmDeleteUser, setConfirmDeleteUser] = useState<UserEx>()
  const [confirmDeleteInvitation, setConfirmDeleteInvitation] = useState<Invitation>()
  const [info, setInfo] = useState<{ title: string; message: string }>()
  const [invite, setInvite] = useState<boolean>(false)

  const readyUsers = useMemo(() => {
    const users: UserEx[] = []

    phase.users?.forEach((x) => {
      if (users.find((u) => u.id === x.id)) return

      const pu = project.providerAccountWithUsers.users.find((pu) => pu.id === x.id)

      users.push({
        ...x,
        canEdit: !pu,
        roleLabel: pu ? t(`General.ProviderRoles.${pu.role}`) : t(`General.ClientPhaseRoles.${x.role}`),
        userIcon: pu ? faDraftingCompass : faUserChart
      })
    })

    project.users?.forEach((x) => {
      if (users.find((u) => u.id === x.id)) return

      const pu = project.providerAccountWithUsers.users.find((pu) => pu.id === x.id)

      users.push({
        ...x,
        badge: t('General.Inherited.Project'),
        roleLabel: pu ? t(`General.ProviderRoles.${x.role}`) : t(`General.ClientProjectRoles.${x.role}`),
        userIcon: pu ? faDraftingCompass : faUserChart,
        isInherited: true
      })
    })

    if (rights?.userType === UserType.provider) {
      phase.estimates?.forEach((est) => {
        console.log('est', est)
        est.labor.forEach((l) => {
          if (!l.user) return

          const u = users.find((u) => u.id === l.user!.id)
          if (!u) {
            users.push({
              ...l.user,
              roleLabel: t(`General.ProviderRoles.${l.user.role}`),
              badge: t(`General.Disciplines.${est.discipline}`),
              userIcon: faDraftingCompass,
              disciplines: [t(`General.Disciplines.${est.discipline}`)],
              canEdit: false,
              isLower: true
            })
          } else {
            u.canEdit = false
            u.isInherited = false
            u.roleLabel = t(`General.ProviderRoles.${l.user.role}`)
            u.badge = est ? t(`General.Disciplines.${est.discipline}`) : undefined
            u.userIcon = faDraftingCompass
            u.isLower = true

            if (!u.disciplines) u.disciplines = []
            u.disciplines!.push(t(`General.Disciplines.${est.discipline}`))
          }
        })
      })
    }

    return users
  }, [project, phase, rights, t])

  const changeUserRole = useCallback(
    (role: ResourceUserRole) => {
      if (!changeRole) return

      if (changeRole.role === role) {
        setChangeRole(undefined)
        return
      }

      if (changeRole.role === ResourceUserRole.Owner) {
        const owners = readyUsers.filter((x) => x.role === ResourceUserRole.Owner)
        if (owners.length <= 1) {
          setInfo({
            title: t('EditProject.ChangeOwnerRoleTitle'),
            message: t('EditProject.ChangeOwnerRole')
          })
          return
        }
      }

      setChangeRole(undefined)

      if (!project.id) return

      const p: ProjectEx = JSON.parse(JSON.stringify(project))

      const user = p.accountWithUsers.projects.find((x) => x.id === project.id)?.users?.find((x) => x.id === changeRole.id)
      if (!user) return

      user.role = role
      setProject(p)

      setSaving(true)
      updateProjectUserRole(project.account.id, project.id, user.id, role)
        .catch((err) => {
          alertDanger({
            title: t('EditProject.Errors.UserRoleChangeTitle'),
            message: t('EditProject.Errors.UserRoleChange'),
            error: err
          })
        })
        .finally(() => setSaving(false))
    },
    [changeRole, setChangeRole, readyUsers, setInfo, project, setProject, t]
  )

  const canDeleteUser = useCallback(
    (user: UserEx) => {
      if (user.role !== ResourceUserRole.Owner) {
        setConfirmDeleteUser(user)
        return
      }

      const owners = readyUsers?.filter((x) => x.id && x.canEdit && x.role === ResourceUserRole.Owner) || []
      if (owners.length > 1) {
        setConfirmDeleteUser(user)
        return
      }

      setInfo({
        title: t('EditProject.DeleteOwnerTitle'),
        message: t('EditProject.DeleteOwner')
      })
    },
    [setConfirmDeleteUser, setInfo, readyUsers, t]
  )

  const deleteUser = useCallback(() => {
    setConfirmDeleteUser(undefined)

    if (!project.id || !confirmDeleteUser) return

    const p: ProjectEx = JSON.parse(JSON.stringify(project))
    const ph = p.phases.find((ph) => ph.id === phaseId)
    if (!ph) return

    const idx = ph.users?.findIndex((x) => x.id === confirmDeleteUser.id)
    if (idx === undefined || idx < 0) return

    ph.users!.splice(idx, 1)
    setProject(p)

    setSaving(true)
    deletePhaseUser(project.account.id, ph.id!, confirmDeleteUser.id)
      .catch((err) => {
        alertDanger({
          title: t('EditProject.Errors.DeletedPhasePeopleTitle'),
          message: t('EditProject.Errors.DeletedPhasePeople'),
          error: err
        })
      })
      .finally(() => setSaving(false))
  }, [confirmDeleteUser, setConfirmDeleteUser, phaseId, project, setProject, setSaving, t])

  const readyInvitations = useMemo(
    () => project?.accountWithUsers?.invitations.filter((i) => !!i.phaseRole && i.phaseId === phaseId && i.status === InvitationStatus.Pending),
    [phaseId, project.accountWithUsers]
  )

  const handleDeleteInvitation = useCallback(
    (invitation: Invitation) => {
      const p: ProjectEx = JSON.parse(JSON.stringify(project))

      const idx = p.accountWithUsers.invitations.findIndex((x) => x?.id === invitation?.id)
      if (idx < 0) return

      p.accountWithUsers.invitations.splice(idx, 1)
      setProject(p)

      setSaving(true)
      deleteInvitation(project.account.id, invitation.id)
        .then(() =>
          alertSuccess({
            title: t('EditProject.DeletedInvitationTitle'),
            message: t('EditProject.DeletedInvitation', {
              name: `${invitation!.givenName} ${invitation!.familyName}`,
              email: invitation!.emailAddress
            })
          })
        )
        .catch((err) =>
          alertDanger({
            title: t('EditProject.Errors.DeletedInvitationTitle'),
            message: t('EditProject.Errors.DeletedInvitation'),
            error: err
          })
        )
        .finally(() => setSaving(false))
    },
    [confirmDeleteInvitation, setConfirmDeleteInvitation, alertDanger, t]
  )

  const handleResendInvitation = useCallback(
    (invitation: Invitation) => {
      setSaving(true)
      resendInvitation(project.account.id, invitation.id)
        .then(() =>
          alertSuccess({
            title: t('EditProject.InvitationResentTitle'),
            message: t('EditProject.InvitationResent', {
              name: `${invitation!.givenName} ${invitation!.familyName}`,
              email: invitation!.emailAddress
            })
          })
        )
        .catch((err) =>
          alertDanger({
            title: t('EditProject.Errors.InvitationResentTitle'),
            message: t('EditProject.Errors.InvitationResent'),
            error: err
          })
        )
        .finally(() => setSaving(false))
    },
    [setSaving, t]
  )

  const tabs = useMemo<TabItem[]>(
    () => [
      {
        label: t('General.Staff'),
        selected: pathname.endsWith('staff'),
        to: url
      },
      {
        label: t('General.Invitations'),
        to: `${url}/invitations`,
        selected: pathname.endsWith('invitations')
      }
    ],
    [pathname, t]
  )

  if (!phase) return null

  return (
    <>
      <TabBar tabs={tabs} style={{ justifyContent: 'center' }} onChange={() => {}} />
      <br />

      <Switch>
        <Route path={`${path}/invitations`}>
          {readyInvitations && <InvitationList invitations={readyInvitations} roles={roles} onNew={() => setInvite(true)} onDelete={handleDeleteInvitation} onResend={handleResendInvitation} />}
        </Route>

        <Route path="*">
          <ResourceUsers
            users={readyUsers}
            onSelect={(user) => setChangeRole({ ...user })}
            onDelete={canDeleteUser}
            readOnly={phase.isGuest || [ProjectStatus.Canceled, ProjectStatus.Complete].includes(phase.status!)}
          />
        </Route>
      </Switch>

      {changeRole && (
        <ChangeRoleModal
          title={t('Settings.ChangeRoleTitle')}
          message={t('Settings.ChangeRole')}
          roles={roles}
          selected={changeRole.role}
          yes={t('General.Ok')}
          no={t('General.Cancel')}
          onClose={() => setChangeRole(undefined)}
          onYes={(role) => changeUserRole(role as ResourceUserRole)}
        />
      )}

      {info && <InfoModal title={info.title} message={info.message} ok={t('General.Ok')} onClose={() => setInfo(undefined)} />}

      {invite && account && (
        <InviteModal
          account={account}
          project={project}
          phase={phase}
          onClose={(invitation) => {
            setInvite(false)
            if (!invitation) return

            const p: ProjectEx = JSON.parse(JSON.stringify(project))
            p.accountWithUsers.invitations.push(invitation.invitation!)
            setProject(p)
          }}
        />
      )}

      {confirmDeleteUser && (
        <ConfirmModal
          title={t('EditProject.DeletePhasePeopleTitle')}
          message={t('EditProject.DeletePhasePeople', {
            name: `${confirmDeleteUser.givenName} ${confirmDeleteUser.familyName}`
          })}
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={deleteUser}
          onClose={() => setConfirmDeleteUser(undefined)}
        />
      )}
      {confirmDeleteInvitation && (
        <ConfirmModal
          title={t('Settings.DeleteInvitationTitle')}
          message={t('Settings.DeleteInvitation', {
            name: `${confirmDeleteInvitation.givenName} ${confirmDeleteInvitation.familyName}`,
            email: confirmDeleteInvitation.emailAddress
          })}
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={() => handleDeleteInvitation(confirmDeleteInvitation)}
          onClose={() => setConfirmDeleteInvitation(undefined)}
        />
      )}
    </>
  )
}
