import React, { SVGProps } from 'react'
import './style.scss'

const MenuClose: React.FC<SVGProps<SVGSVGElement>> = ({ className, ...rest }) => (
  <svg viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" className={`ui-svg ui-svg-menu-close ${className || ''}`} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.395 8.64505C5.59 8.84005 5.905 8.84005 6.1 8.64505L7.895 6.85005C8.09 6.65505 8.09 6.34005 7.895 6.14505L6.1 4.35005C5.905 4.15505 5.59 4.15505 5.395 4.35005C5.2 4.54505 5.2 4.86005 5.395 5.05505L6.335 6.00005H2C1.725 6.00005 1.5 6.22505 1.5 6.50005C1.5 6.77505 1.725 7.00005 2 7.00005H6.335L5.395 7.94005C5.2 8.13505 5.205 8.45505 5.395 8.64505ZM9.5 2H2.5C1.945 2 1.5 2.45 1.5 3V4.5C1.5 4.775 1.725 5 2 5C2.275 5 2.5 4.775 2.5 4.5V3.5C2.5 3.225 2.725 3 3 3H9C9.275 3 9.5 3.225 9.5 3.5V9.5C9.5 9.775 9.275 10 9 10H3C2.725 10 2.5 9.775 2.5 9.5V8.5C2.5 8.225 2.275 8 2 8C1.725 8 1.5 8.225 1.5 8.5V10C1.5 10.55 1.95 11 2.5 11H9.5C10.05 11 10.5 10.55 10.5 10V3C10.5 2.45 10.05 2 9.5 2Z"
      fill="#E0E0E0"
    />
  </svg>
)
export default MenuClose
