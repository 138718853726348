import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { createAccount, getAccountById, getCompanyTypes, getDisciplineSkills, updateUser, useProjectSupport } from 'services/src/api'
import { Account, AccountStatus, AccountType, Availability, CompanyType, EducationLevel, ExpertType, ResourceUserRole, User, UserStatus } from 'services/src/dto/account'
import { useMsalEmail, useMsalName } from 'services/src/aad'
import { useMe } from 'services/src/state'
import { useAlert } from 'components/src/alerts'
import { Loading } from 'components/src/loading'
import { useTheme } from 'components/src/theme'

import logoLight from 'components/src/img/cc-light.png'
import logoDark from 'components/src/img/cc-dark.png'
import bgLight from 'components/src/img/bgLight.webp'
import bgDark from 'components/src/img/bgDark.webp'

import { ExpertCertificationEx, NewAccountModel } from './common/model'
import './common/style.scss'

import { BusinessProfile } from './common/BusinessProfile'
import { EmployeeProfile } from './common/EmployeeProfile'
import { NextSteps } from './common/NextSteps'
import { StringParam, useQueryParam } from 'use-query-params'
import { ProjectExperience } from './common/ProjectExperience'
import { ExpertProfile } from './expert/ExpertProfile'
import { ExpertProfessionalExpertise } from './expert/ExpertProfessionalExpertise'
import { ExpertProjectExperience } from './expert/ExpertProjectExperience'
import { ExpertEducation } from './expert/ExpertEducation'
import { ExpertIndustryCertifications } from './expert/ExpertIndustryCertifications'
import { ExpertNextSteps } from './expert/ExpertNextSteps'
import moment from 'moment/moment'

export const Onboarding: React.FC = () => {
  const [action] = useQueryParam('action', StringParam)
  const [type] = useQueryParam('type', StringParam)

  const { t, i18n } = useTranslation()
  const { path, url } = useRouteMatch()
  const history = useHistory()
  const [me, refreshMe, setMe] = useMe(undefined, action, type)
  const { givenName, familyName } = useMsalName()
  const email = useMsalEmail()
  const { alertDanger } = useAlert()
  const { theme } = useTheme()

  const [ready, setReady] = useState(false)
  const [saving, setSaving] = useState(false)
  const [companyTypes, setCompanyTypes] = useState<CompanyType[]>()
  const [account, setAccount] = useState<Account>()
  const [user, setUser] = useState<User>()

  const [{ buildingCategories, loading: supportLoading }] = useProjectSupport()

  const [model, setModel] = useState<NewAccountModel>({
    id: '',
    role: ResourceUserRole.Owner,
    status: UserStatus.Ready,
    companyName: '',
    title: '',
    phone: '',
    extension: '',
    givenName,
    familyName,
    email,
    createUserType: type,
    primaryDiscipline: undefined,
    disciplineSkills: [],
    skills: [],
    yearsOfExp: undefined,
    currentCategory: 0,
    categoryProjectTypes: [],

    educationLevelIdx: -1,
    currentCertification: {} as ExpertCertificationEx,
    certifications: []
  })

  useEffect(() => {
    document.body.classList.add('no-padding')
    return () => {
      document.body.classList.remove('no-padding')
    }
  }, [])

  useEffect(() => {
    if (user || !me) return
    setUser({ ...me })
    setModel((current) => ({
      ...current,
      id: me.id,
      createUserType: me.createUserType
    }))
  }, [user, me])

  const loadSupport = () =>
    Promise.all([
      getCompanyTypes(),
      getDisciplineSkills(i18n.language)
    ])

  useEffect(() => {
    if (!user || supportLoading) return

    loadSupport()
      .then(async ([companyTypes, disciplineSkills]) => {
        try {
          const account = await getAccountById(user.id)
          return { account, companyTypes, disciplineSkills }
        } catch {
          // DO NOTHING
        }
        return { account: null, companyTypes, disciplineSkills }
      })
      .then(({ account, companyTypes, disciplineSkills }) => {
        setCompanyTypes(Object.keys(companyTypes).map((id: string) => ({ id, label: companyTypes[id] })))

        const newModel = {
          ...model,
          ...(me as User as any),
          email: user.emails?.length ? user.emails[0].email : '',
          disciplineSkills,
          skills: disciplineSkills.find((x) => x.discipline === user.primaryDiscipline)?.skills.map((s) => ({ label: s })) || [],
          currentCategory: 1,
          categoryProjectTypes: buildingCategories.map((c) => ({
            category: c.category,
            projectTypes: c.projectTypes.map((pt) => ({
              ...pt,
              label: pt.type,
              selected: user.categoryExperiences?.find((x) => x.category === c.category)?.items.find((x) => x.projectTypeId === pt.id)
            }))
          })),

          educationLevelIdx: -1,
          industrySpecific: true,

          currentCertification: { name: '', number: '', expMonth: 0, expYear: 0 },
          certifications: [...(user.certifications || [])]
        };

        if (user.educationLevel === EducationLevel.Associate) newModel.educationLevelIdx = 0
        else if (user.educationLevel === EducationLevel.Bachelor) newModel.educationLevelIdx = 1;
        else if (user.educationLevel === EducationLevel.Graduate) newModel.educationLevelIdx = 2;
        else if (user.educationLevel === EducationLevel.Doctoral) newModel.educationLevelIdx = 3;

        setModel(newModel)

        console.log('account', account)
        return account
      })
      .then(account => {
        if (account && user) {
          if (user.onboardedAt) history.replace(`${url}/next`)
          else {
            user.onboardedAt = new Date()
            updateUser(null, null, user, undefined, 'complete')
              .then((me) => {
                setMe(me)
                setUser({ ...me })
              })
              .finally(() => history.replace(`${url}/next`))
          }
          return
        }

        if (user.createUserType === 'expert') {
          history.replace(`${url}/profile`)
        } else {
          if (account) {
            history.replace(`${url}/profile`)
            return
          }
          history.replace(`${url}/company`)
        }


      })
      .catch(() => {
        if (!url.endsWith('/company')) history.replace(`${url}/company`)
      })
      .finally(() => setReady(true))

  }, [user, supportLoading])

  const rightRef = useRef<HTMLDivElement>(null)

  const submit = useCallback(() => {
    setSaving(true)

    const account: Account = {
      id: model.id,
      type: model.createUserType === 'provider' ?  AccountType.Provider : AccountType.Client,
      status: model.createUserType === 'provider' ?  AccountStatus.New : AccountStatus.Ready,
      companyName: model.companyName,
      companyType: model.companyType!.id,
      numberOfEmployees: model.numberOfEmployees!.id,
      tagLine: model.tagLine,
      overview: model.overview,
      companyAddress: model.companyAddress
    }

    model.categoryProjectTypes.forEach(cpt => {
      const selected = cpt.projectTypes.filter(x => !!x.selected)
      if (selected.length <= 0) return;

      account.categoryExperiences ||= [];

      account.categoryExperiences.push({
        category: cpt.category,
        items: selected.map((x) => ({
          projectTypeId: x.id,
          type: x.type
        }))
      })
    })

    const user: User = {
      id: model.id,
      status: UserStatus.Ready,
      emails: [{ email: model.email }],
      givenName: model.givenName,
      familyName: model.familyName,
      jobTitle: model.title,
      expertType: model.createUserType === 'provider' ?  ExpertType.FTE : undefined,
      availability: model.createUserType === 'provider' ? Availability.FullTime: undefined,
      businessPhones: [
        {
          number: model.phone,
          extension: model.extension || undefined
        }
      ],
      mobilePhone: model.mobilePhone
    } as User

    createAccount(account, user, model.logo, model.picture)
      .then(({ account, user }) => {
        setAccount(account)
        setUser(user)
        return refreshMe()
      })
      .then(() => {
        history.push(`${path}/next`)
        rightRef.current?.scrollTo(0, 0)
      })
      .catch((err) => {
        alertDanger({
          title: t('Onboarding.CreateFailed.Title'),
          message: t('Onboarding.CreateFailed.Message'),
          error: err
        })
      })
      .finally(() => setSaving(false))

  }, [model, email])

  const submitExpert = useCallback(() => {
    if (!me) return

    setSaving(true)

    const account: Account = {
      id: model.id,
      type: AccountType.Expert,
      status: AccountStatus.New,
      companyName: `${me.givenName} ${me.familyName}`,
      companyType: 'Private',
      numberOfEmployees: '1-5',
    }

    const user: User = {
      ...me,
      businessPhones: model.phone
        ? [
          {
            number: model.phone,
            extension: model.extension
          }
        ]
        : undefined,
      mobilePhone: model.mobilePhone,

      primaryDiscipline: model.primaryDiscipline,
      primaryDisciplineSkills: [...model.skills.filter((x) => !!x.selected).map((x) => x.label)],
      yearsOfExperience: model.yearsOfExp?.id,
      availability: model.availability?.id,

      expertRole: model.expertRole,
      hourlyRate: model.hourlyRate,

      categoryExperiences: model.categoryProjectTypes.map((c) => ({
        category: c.category,
        items: c.projectTypes
          .filter((pt) => !!pt.selected)
          .map((pt) => ({
            projectTypeId: pt.id,
            type: pt.type
          }))
      })),

      educationLevel: model.educationLevel,
      schoolName: model.schoolName,
      gradYear: model.gradYear ? parseInt(model.gradYear, 10) : undefined,
      industrySpecificDegree: model.industrySpecific,

      certifications: model.certifications.map((c) => ({
        name: c.name,
        number: c.number,
        expires: moment({ year: c.expYear, month: c.expMonth - 1 }).toDate()
      }))
    }

    createAccount(account, user, model.logo, model.picture)
      .then(({ account, user }) => {
        setAccount(account)
        setUser(user)
        return refreshMe();
      })
      .then(() => {
        history.push(`${path}/next`)
        rightRef.current?.scrollTo(0, 0)
      })
      .catch((err) => {
        alertDanger({
          title: t('Onboarding.Expert.ExpertSavedTitle'),
          message: t('Onboarding.Expert.ExpertSaved'),
          error: err
        })
      })
      .finally(() => setSaving(false))
  }, [setSaving, me, model, alertDanger, t, history, path, setModel])

  if (!ready) return <Loading />

  const isExpert = model.createUserType === 'expert'
  const isProvider = model.createUserType === 'provider'

  if (isExpert)
    return (
      <div className="onboarding">
        <div className="page-left" style={{ backgroundImage: `url("${theme === 'dark' ? bgDark : bgLight}")` }}>
          <img className="logo" alt="" src={theme === 'dark' ? logoLight : logoDark} />
        </div>
        <div className="page-right" ref={rightRef}>
          <Route path={`${path}/profile`}>
            <ExpertProfile
              model={model}
              onChangeModel={setModel}
              onNext={() => {
                history.push(`${path}/expertise`)
                rightRef.current?.scrollTo(0, 0)
              }}
              onBack={() => {
                history.goBack()
                rightRef.current?.scrollTo(0, 0)
              }}
            />
          </Route>

          <Route path={`${path}/expertise`}>
            <ExpertProfessionalExpertise
              model={model}
              onChangeModel={setModel}
              onNext={() => {
                history.push(`${path}/project-experience`)
                rightRef.current?.scrollTo(0, 0)
              }}
              onBack={() => {
                history.goBack()
                rightRef.current?.scrollTo(0, 0)
              }}
            />
          </Route>

          <Route path={`${path}/project-experience`}>
            <ExpertProjectExperience
              model={model}
              onChangeModel={setModel}
              onNext={() => {
                history.push(`${path}/education`)
                rightRef.current?.scrollTo(0, 0)
              }}
              onBack={() => {
                history.goBack()
                rightRef.current?.scrollTo(0, 0)
              }} />
          </Route>

          <Route path={`${path}/education`}>
            <ExpertEducation
              model={model}
              onChangeModel={setModel}
              onNext={() => {
                history.push(`${path}/certifications`)
                rightRef.current?.scrollTo(0, 0)
              }}
              onBack={() => {
                history.goBack()
                rightRef.current?.scrollTo(0, 0)
              }} />
          </Route>

          <Route path={`${path}/certifications`}>
            <ExpertIndustryCertifications
              model={model}
              onChangeModel={setModel}
              onNext={() => {
                submitExpert()
                rightRef.current?.scrollTo(0, 0)
              }}
              onBack={() => {
                history.goBack()
                rightRef.current?.scrollTo(0, 0)
              }} />
          </Route>

          <Route path={`${path}/next`}>
            <ExpertNextSteps model={model} />
          </Route>

          {saving && <Loading variant="parent" />}
        </div>
      </div>
    )

  return (
    <div className="onboarding">
      <div className="page-left" style={{ backgroundImage: `url("${theme === 'dark' ? bgDark : bgLight}")` }}>
        <img className="logo" alt="" src={theme === 'dark' ? logoLight : logoDark} />
      </div>
      <div className="page-right" ref={rightRef}>
        <Route path={`${path}/company`}>
          <BusinessProfile
            model={model}
            companyTypes={companyTypes || []}
            onChangeModel={(model) => setModel(model)}
            onNext={() => {
              history.push(`${path}/profile`)
              rightRef.current?.scrollTo(0, 0)
            }}
          />
        </Route>

        <Route path={`${path}/profile`}>
          <EmployeeProfile
            model={model}
            onChangeModel={(model) => setModel(model)}
            onNext={() => {
              if (!isProvider) {
                submit()
                return
              }
              history.push(`${path}/project-experience`)
              rightRef.current?.scrollTo(0, 0)
            }}
            onBack={() => {
              history.replace(`${path}/company`)
              rightRef.current?.scrollTo(0, 0)
            }}
          />
        </Route>

        {isProvider && (
          <Route path={`${path}/project-experience`}>
            <ProjectExperience
              model={model}
              onChangeModel={(m) => setModel(m as NewAccountModel)}
              onNext={() => {
                submit()
              }}
              onBack={() => {
                history.goBack()
                rightRef.current?.scrollTo(0, 0)
              }}
            />
          </Route>
        )}

        <Route path={`${path}/next`}>
          <NextSteps account={account} />
        </Route>

        {saving && <Loading variant="parent" />}
      </div>
    </div>
  )
}
