import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GridApi, ICellRendererParams, RowClickedEvent, IGetRowsParams, GridReadyEvent, FilterChangedEvent, ValueGetterParams, SortModelItem } from 'ag-grid-community'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise'

import parsePhoneNumber from 'libphonenumber-js'

import LoadingSvg from 'components/src/svg/Loading'
import { getOffices } from 'services/src/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import { Office, ResourceUserRole } from 'services/src/dto/account'
import { useMe } from 'services/src/state'
import { SortChangedEvent } from 'ag-grid-community/dist/lib/events'

const officeDatasource = (gridApi: GridApi, accountId: string) => ({
  // called by the grid when more rows are required
  getRows: ({ sortModel, filterModel, successCallback, failCallback, startRow, endRow }: IGetRowsParams) => {
    let sort = 'name'
    let sortDir = 'asc'
    if (sortModel?.length) {
      sort = sortModel[0].colId
      sortDir = sortModel[0].sort
    }

    let filter: string | undefined
    if (filterModel) {
      const parts: string[] = []
      Object.keys(filterModel).forEach((colId) => {
        const f = filterModel[colId]
        if (f.filterType === 'text') parts.push(`${colId} ${f.type}('${f.filter}')`)
        else if (f.filterType === 'date') {
          if (f.type === 'inRange') parts.push(`${colId} ${f.type}('${f.dateFrom.split(' ')[0]}', '${f.dateTo.split(' ')[0]}')`)
          else parts.push(`${colId} ${f.type}('${f.dateFrom.split(' ')[0]}')`)
        } else if (f.filterType === 'set') {
          parts.push(`${colId} contains('${f.values.join(',')}')`)
        }
      })
      filter = parts.join(' and ')
    }

    getOffices(accountId, startRow, endRow - startRow, sort, sortDir, filter)
      .then(({ total, offices }) => {
        successCallback(offices, total)
        if (!total) gridApi.showNoRowsOverlay()
        else gridApi.hideOverlay()
      })
      .catch(() => failCallback())
  }
})

const NoOfficeOverlay = ({ canCreate }: any) => {
  const { t } = useTranslation()
  const { url } = useRouteMatch()

  return (
    <div className="no-rows-overlay">
      <h3>{t('General.NoOffices')}</h3>
      {canCreate && (
        <div className="buttons">
          <Link to={`${url}/new`} className="ui-btn ui-btn-primary ui-btn-solid">
            {t('Offices.CreateNew')}
          </Link>
        </div>
      )}
    </div>
  )
}

type NameCellProps = Omit<ICellRendererParams, 'data'> & {
  data: Office
  loadingMessage?: string
}
const NameCell: React.FC<NameCellProps> = ({ data: office, loadingMessage }: NameCellProps) => {
  const { t } = useTranslation()
  const [me] = useMe()

  const phone = useMemo(() => {
    if (!office?.phoneNumbers?.length) return null

    let p = office.phoneNumbers.find((x: any) => !x.label || x.label === 'Primary')
    // eslint-disable-next-line prefer-destructuring
    if (!p) p = office.phoneNumbers[0]

    const pn = parsePhoneNumber(p.number?.startsWith('+') ? p.number : `+${p.number}`)

    return (
      <div className="ui-text-sm" style={{ marginTop: 5 }}>
        {pn ? pn.formatNational() : p.number}
        {p.extension ? ` ${t('General.Extension', { extension: p.extension })}` : ''}
      </div>
    )
  }, [office, t])

  if (!office)
    return (
      <div className="ag-custom-loading-cell ui-flex ui-flex-nowrap ui-text-muted" style={{ alignItems: 'center' }}>
        <LoadingSvg style={{ width: 25 }} />
        {loadingMessage && <div style={{ marginLeft: 10 }}>{loadingMessage}</div>}
      </div>
    )

  return (
    <div className="ag-html-cell">
      {(() => {
        const ou = office.users?.find((u) => u.id === me?.id)
        return <div className={`ui-text-lg${ou?.role === ResourceUserRole.Owner ? ' ui-is-me' : ''}`}>{office.name}</div>
      })()}
      {phone}
    </div>
  )
}

const CountCell: React.FC<ICellRendererParams> = ({ value }) => {
  if (value === undefined) return <div />

  return (
    <div className="ag-html-cell">
      <div className="ui-text-xl">{value || 0}</div>
    </div>
  )
}

export interface OfficeListProps {
  accountId: string
  sortKey?: string
  filterKey?: string
  onCreate?: () => void
}

export const OfficeTable: React.FC<OfficeListProps> = ({ accountId, sortKey, filterKey, onCreate }) => {
  const { t } = useTranslation()
  const { url } = useRouteMatch()
  const history = useHistory()

  const [height, setHeight] = useState(500)
  const containerRef = useRef<HTMLDivElement>(null)

  const [gridApi, setGridApi] = useState<GridApi>()
  const gridReady = useCallback(
    ({ api, columnApi }: GridReadyEvent) => {
      setGridApi(api)
      api.setFilterModel(filter)
      columnApi.applyColumnState({
        state: [...sort]
      })
      api.setDatasource(officeDatasource(api, accountId))
    },
    [setGridApi]
  )

  const [sort, setSort] = useState(() => {
    if (!sortKey) return [{ colId: 'name', sort: 'asc' }]
    const s = localStorage.getItem(sortKey)
    return s ? JSON.parse(s) : [{ colId: 'name', sort: 'asc' }]
  })

  const [filter, setFilter] = useState(() => {
    if (!filterKey) return undefined
    const f = localStorage.getItem(filterKey)
    return f ? JSON.parse(f) : undefined
  })

  const rowClicked = useCallback(
    (e: RowClickedEvent) => {
      if (!e.data?.id) return
      history.push(`${url}/${e.data.id}`)
    },
    [url]
  )

  const sortChange = useCallback(
    ({ columnApi }: SortChangedEvent) => {
      const s = columnApi.getColumnState()
      const sm = s.filter((x) => !!x.colId && !!x.sort).map<SortModelItem>((x) => ({ colId: x.colId!, sort: x.sort! }))

      if (sm.length <= 0) return

      setSort(sm)
      if (sortKey) localStorage.setItem(sortKey, JSON.stringify(sm))
    },
    [setSort, sortKey]
  )

  const filterChange = useCallback(
    ({ api }: FilterChangedEvent) => {
      const fm = api.getFilterModel()
      if (!fm || !Object.keys(fm).length) {
        if (filterKey) localStorage.removeItem(filterKey)
        setFilter(undefined)
        return
      }

      setFilter(fm)
      if (filterKey) localStorage.setItem(filterKey, JSON.stringify(fm))
    },
    [setFilter, filterKey]
  )

  useEffect(() => {
    const resize = () => {
      if (containerRef.current) {
        const r = containerRef.current.getBoundingClientRect()
        setHeight(window.innerHeight - r.top)
      }
    }
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  useEffect(() => {
    if (containerRef.current) {
      const r = containerRef.current.getBoundingClientRect()
      setHeight(window.innerHeight - r.top)
    }
  }, [containerRef.current])

  return (
    <>
      <div className="office-index-filter">
        <button
          type="button"
          className={`ui-btn ui-btn-xs${filter ? ' ui-btn-pressed ui-btn-info' : ''}`}
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            if (filter) gridApi?.setFilterModel(undefined)
          }}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
          <span style={{ marginLeft: 5 }}>{t('General.ClearFilter')}</span>
        </button>
      </div>

      <div className="ag-theme-cc" style={{ height, width: '100%' }} ref={containerRef}>
        <AgGridReact
          defaultColDef={{
            width: 100,
            editable: false,
            resizable: true,
            sortable: true,
            sortingOrder: ['asc', 'desc']
          }}
          frameworkComponents={{
            noOfficeOverlay: NoOfficeOverlay,
            nameCell: NameCell,
            countCell: CountCell
          }}
          rowHeight={75}
          noRowsOverlayComponent="noOfficeOverlay"
          noRowsOverlayComponentParams={{ onCreate }}
          blockLoadDebounceMillis={500}
          cacheBlockSize={25}
          rowSelection="single"
          suppressCellSelection
          suppressRowHoverHighlight
          rowModelType="infinite"
          onRowClicked={rowClicked}
          onSortChanged={sortChange}
          onFilterChanged={filterChange}
          onGridReady={gridReady}
        >
          <AgGridColumn
            field="name"
            headerName={t('Offices.Name')}
            width={2000}
            minWidth={150}
            flex={0.75}
            filter="agTextColumnFilter"
            filterParams={{
              filterOptions: ['contains', 'startsWith', 'endsWith'],
              defaultOption: 'contains',
              suppressAndOrCondition: true
            }}
            cellRenderer="nameCell"
            cellRendererParams={{ loadingMessage: t('General.OneMoment') }}
          />
          <AgGridColumn
            field="address"
            headerName={t('Offices.Address')}
            width={2000}
            minWidth={150}
            flex={1}
            filter="agTextColumnFilter"
            filterParams={{
              filterOptions: ['contains', 'startsWith', 'endsWith'],
              defaultOption: 'contains',
              suppressAndOrCondition: true
            }}
          />
          <AgGridColumn
            field="users"
            headerName={t('Offices.Users')}
            width={120}
            minWidth={120}
            maxWidth={120}
            resizable={false}
            sortable={false}
            valueGetter={({ data }: ValueGetterParams) => (data ? data.users?.length || 0 : undefined)}
            cellRenderer="countCell"
          />
          <AgGridColumn
            field="projects"
            headerName={t('Offices.Projects')}
            width={120}
            minWidth={120}
            maxWidth={120}
            resizable={false}
            sortable={false}
            valueGetter={({ data }: ValueGetterParams) => (data ? data.counts?.total || 0 : undefined)}
            cellRenderer="countCell"
          />
          <AgGridColumn
            field="portfolioManagers"
            headerName={t('Offices.PortfolioManagers')}
            width={120}
            minWidth={120}
            maxWidth={120}
            resizable={false}
            sortable={false}
            valueGetter={({ data }: ValueGetterParams) => (data ? data.users?.filter((x: any) => x.role === ResourceUserRole.Owner).length || 0 : undefined)}
            cellRenderer="countCell"
          />
          <AgGridColumn
            field="projectManagers"
            headerName={t('Offices.ProjectManagers')}
            width={120}
            minWidth={120}
            maxWidth={120}
            resizable={false}
            sortable={false}
            valueGetter={({ data }: ValueGetterParams) => (data ? data.users?.filter((x: any) => x.role === ResourceUserRole.Contributor).length || 0 : undefined)}
            cellRenderer="countCell"
          />
        </AgGridReact>
      </div>
    </>
  )
}
