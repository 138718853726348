import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { ProjectContentEx, useProjectFiles } from 'services/src/state'
import { ProjectContentCategory } from 'services/src/dto/content'
import { DisciplineKey } from 'services/src/dto/project'
import { useMsalAccessToken } from 'services/src/aad'
import { Loading } from 'components/src/loading'
import './style.scss'
import { DocumentViewerToolbar } from './DocumentViewToolbar'
import { ImageView } from './ImageView'
import { decZoom, incZoom } from './common'
import { PdfView } from './PdfView'
import { OfficeView } from './OfficeView'
import { TextView } from './TextView'

export const DocumentView: React.FC<{
  disciplines?: string[]
  back?: string
  onTagDiscipline?: (content: ProjectContentEx, discipline?: DisciplineKey) => void
  category?: ProjectContentCategory
}> = ({ disciplines, back, onTagDiscipline, category: initCategory }) => {
  const {
    params: { category, contentId }
  } = useRouteMatch<{ category: string; contentId: string }>()
  const accessToken = useMsalAccessToken()
  const [ready, setReady] = useState(false)
  const docViewRef = useRef<HTMLDivElement>(null)

  const [anchorPt, setAnchorPt] = useState({ left: 0, top: 0 })
  const [zoom, setZoom] = useState(-1)
  const [isFit, setIsFit] = useState(true)
  const [rotate, setRotate] = useState(0)
  const [view, setView] = useState('normal')

  const resize = useCallback(
    (full?: boolean) => {
      if (!docViewRef.current) return

      const r = docViewRef.current.getBoundingClientRect()

      docViewRef.current.style.height = full ? `${window.innerHeight}px` : `${window.innerHeight - r.top - 40}px`
    },
    [docViewRef]
  )

  const keyDown = useCallback(
    (e: KeyboardEvent) => {
      const stopEvent = () => {
        e.preventDefault()
        e.stopPropagation()
      }
      if (e.metaKey) {
        if (e.key === '+' || e.key === '=') {
          setZoom((z) => incZoom(z))
          stopEvent()
          return
        }
        if (e.key === '-' || e.key === '_') {
          setZoom((z) => decZoom(z))
          stopEvent()
        }
      }
    },
    [setZoom]
  )

  const handleRotate = useCallback(
    (e: CustomEvent<{ rotate: number }>) => {
      setRotate(e.detail.rotate)
    },
    [setRotate]
  )

  const handleZoom = useCallback(
    (e: CustomEvent<{ zoom: number }>) => {
      setZoom(e.detail.zoom)
      setIsFit(false)
    },
    [setZoom]
  )

  const handleFit = useCallback(() => {
    setIsFit(true)
  }, [setIsFit])

  const handleView = useCallback(
    (e: CustomEvent<{ view: string }>) => {
      setView(e.detail.view)
      resize(e.detail.view === 'full')

      /* if (e.detail.view !== 'full') {
      const elm = document as any;
      const exitFs = elm.exitFullscreen ||
        elm.webkitExitFullscreen ||
        elm.webkitExitFullScreen ||
        elm.mozCancelFullScreen ||
        elm.msExitFullscreen;
      if (exitFs) exitFs.apply(elm);
    } */
    },
    [resize, setView]
  )

  const handleMax = useCallback(() => {
    const elm = document.body as any
    const requestFs = elm.requestFullScreen || elm.webkitRequestFullscreen || elm.webkitRequestFullScreen || elm.mozRequestFullScreen || elm.msRequestFullscreen
    if (requestFs) requestFs.apply(elm)
  }, [])

  const handleFsChange = useCallback(() => {
    setView((current) => {
      const view = current === 'full' ? 'normal' : 'full'
      resize(view === 'full')
      return view
    })
  }, [setView, resize])

  useEffect(() => {
    const handleResize = () => resize(view === 'full')

    document.body.style.overflow = 'hidden'
    window.addEventListener('resize', handleResize)
    window.addEventListener('keydown', keyDown)
    window.addEventListener('fullscreenchange', handleFsChange)
    window.addEventListener('mozfullscreenchange', handleFsChange)
    window.addEventListener('webkitfullscreenchange', handleFsChange)
    window.addEventListener('msfullscreenchange', handleFsChange)

    window.addEventListener('docView.rotate', handleRotate as any)
    window.addEventListener('docView.zoom', handleZoom as any)
    window.addEventListener('docView.fit', handleFit as any)
    window.addEventListener('docView.view', handleView as any)
    window.addEventListener('docView.max', handleMax as any)

    return () => {
      document.body.style.overflow = ''
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('keydown', keyDown)
      window.removeEventListener('fullscreenchange', handleFsChange)
      window.removeEventListener('mozfullscreenchange', handleFsChange)
      window.removeEventListener('webkitfullscreenchange', handleFsChange)
      window.removeEventListener('msfullscreenchange', handleFsChange)

      window.removeEventListener('docView.rotate', handleRotate as any)
      window.removeEventListener('docView.zoom', handleZoom as any)
      window.removeEventListener('docView.fit', handleFit as any)
      window.removeEventListener('docView.view', handleView as any)
      window.removeEventListener('docView.max', handleMax as any)
    }
  }, [keyDown, handleFsChange, handleRotate, handleZoom, handleFit, handleView, handleMax])

  const contentCategory = useMemo(() => {
    if (!category) return initCategory!
    switch (category.toLowerCase()) {
      case 'drawings':
        return ProjectContentCategory.Drawing
      case 'photos':
        return ProjectContentCategory.Picture
      case 'documents':
        return ProjectContentCategory.Document
      case 'estimates':
        return ProjectContentCategory.Estimate
      case 'boe':
        return ProjectContentCategory.Boe
      default:
        return ProjectContentCategory.Document
    }
  }, [category, initCategory])

  const { files: allFiles } = useProjectFiles(contentCategory)

  const { content, next, prev } = useMemo<{
    content?: ProjectContentEx
    renderContent?: ProjectContentEx
    next?: ProjectContentEx
    prev?: ProjectContentEx
  }>(() => {
    if (!allFiles) return {}

    let theFiles = allFiles

    let content: ProjectContentEx | undefined
    if (contentCategory === ProjectContentCategory.Drawing) {
      const parent = allFiles.find((x) => !!x.children?.find((c) => c.id === contentId))
      if (!parent) return {}

      theFiles = parent.children
        .filter((f) => {
          if (f.id === contentId) content = f

          if (!disciplines?.length) return true

          if (!f.discipline) return disciplines.includes('$noDiscipline$')

          let result = f.discipline && disciplines.includes(f.discipline.toString())

          if (disciplines.includes('$danger$')) {
            if (!f.disciplineById) {
              result = !!f.classification && f.score! <= 0.5
            }
          }
          if (disciplines.includes('$warn$')) {
            if (!f.disciplineById) {
              result = !!f.classification && f.score! > 0.5 && f.score! <= 0.75
            }
          }

          return result
        })
        .map((c, pageIndex) => ({ ...c, pageIndex }))
    } else {
      content = allFiles.find((f) => f.id === contentId)
    }

    if (!content) return {}

    let next: ProjectContentEx | undefined
    let prev: ProjectContentEx | undefined
    const idx = theFiles.findIndex((x) => x.id === content?.id)
    if (idx >= 0) {
      if (theFiles.length > 1) {
        // eslint-disable-next-line no-nested-ternary
        next = idx + 1 <= theFiles.length - 1 ? theFiles[idx + 1] : theFiles.length > 0 ? theFiles[0] : undefined
        // eslint-disable-next-line no-nested-ternary
        prev = idx > 0 ? theFiles[idx - 1] : theFiles.length > 0 ? theFiles[allFiles.length - 1] : undefined
      }
      // eslint-disable-next-line prefer-destructuring
    } else if (theFiles.length > 0) next = theFiles[0]

    resize()
    setTimeout(() => setReady(true), 500)

    return { content, next, prev }
  }, [contentCategory, contentId, allFiles, disciplines])

  useEffect(() => {
    if (ready) setTimeout(() => resize())
  }, [ready])

  useLayoutEffect(() => {
    resize(view === 'full')
  }, [resize, view])

  return (
    <div className={`ui-document-view ${view}`} ref={docViewRef}>
      {(!ready || !content) && (
        <div style={{ marginTop: 200 }}>
          <Loading size="sm" variant="static" />
        </div>
      )}

      {ready && content && <DocumentViewerToolbar content={content} next={next} prev={prev} zoom={zoom} rotate={rotate} view={view} back={back} onTagDiscipline={onTagDiscipline} />}

      {(() => {
        if (!ready || !content) return null

        const c = content
        if (c.category === ProjectContentCategory.Drawing && c.images?.full)
          return (
            <div
              className="ui-document-view-container image-view"
              onWheel={(e) => {
                if (e.metaKey) {
                  if (e.deltaY > 0) setZoom(Math.min(10, zoom + 0.01 * e.deltaY))
                  else if (e.deltaY < 0) setZoom(Math.max(0.01, zoom - 0.01 * Math.abs(e.deltaY)))
                } else {
                  setAnchorPt((pt) => ({
                    left: pt.left - e.deltaX,
                    top: pt.top - e.deltaY
                  }))
                }
              }}
            >
              <ImageView src={c.images.full} view={view} anchorPt={anchorPt} onAnchorPtChange={(pt) => setAnchorPt(pt)} zoom={zoom} onZoomChange={(z) => setZoom(z)} isFit={isFit} rotate={rotate} />
            </div>
          )

        if (c?.contentType.endsWith('/pdf'))
          return (
            <div
              className="ui-document-view-container pdf-view"
              onWheel={(e) => {
                if (e.metaKey) {
                  e.preventDefault()
                  if (e.deltaY > 0) setZoom(Math.min(10, zoom + 0.01 * e.deltaY))
                  else if (e.deltaY < 0) setZoom(Math.max(0.025, zoom - 0.01 * Math.abs(e.deltaY)))
                }
              }}
            >
              <PdfView content={c} view={view} onAnchorPtChange={(pt) => setAnchorPt(pt)} zoom={zoom} onZoomChange={(z) => setZoom(z)} isFit={isFit} rotate={rotate} />
            </div>
          )

        if (c?.contentType.startsWith('image/'))
          return (
            <div
              className="ui-document-view-container image-view"
              onWheel={(e) => {
                if (e.metaKey) {
                  if (e.deltaY > 0) setZoom(Math.min(10, zoom + 0.01 * e.deltaY))
                  else if (e.deltaY < 0) setZoom(Math.max(0.01, zoom - 0.01 * Math.abs(e.deltaY)))
                } else {
                  setAnchorPt((pt) => ({
                    left: pt.left - e.deltaX,
                    top: pt.top - e.deltaY
                  }))
                }
              }}
            >
              <ImageView
                src={`${c.url.includes('sv=') ? c.url : `${c.url}?access_token=${accessToken}`}`}
                view={view}
                anchorPt={anchorPt}
                onAnchorPtChange={(pt) => setAnchorPt(pt)}
                zoom={zoom}
                onZoomChange={(z) => setZoom(z)}
                isFit={isFit}
                rotate={rotate}
              />
            </div>
          )

        if (c.contentType.includes('openxmlformats-officedocument') || c.contentType.endsWith('/msword') || c.contentType.endsWith('/ms-excel') || c.contentType.endsWith('/ms-powerpoint'))
          return (
            <div className="ui-document-view-container office-view">
              <OfficeView content={c} />
            </div>
          )

        if (c?.contentType.startsWith('text/') || c?.contentType.endsWith('json') || c?.contentType.endsWith('xml'))
          return (
            <div className="ui-document-view-container text-view">
              <TextView content={c} />
            </div>
          )

        return null
      })()}
    </div>
  )
}
