import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { DisciplineKey } from 'services/src/dto/projectShare'

import { Grid, GridColumn, GridContentRenderProps } from 'components/src/grid'
import { PhaseEx, useEditProject } from 'services/src/state'

import { Estimate } from 'services/src/dto/project'

export const FeePhaseBreakdown: React.FC = () => {
  const { t } = useTranslation()

  const { project } = useEditProject()

  const renderHours = useCallback((phase: PhaseEx, discipline: DisciplineKey) => {
    const est = phase.estimates?.find((x: Estimate) => x.discipline === discipline)
    if (!est) return <div />

    const total = est.negotiatedTotals ? est.negotiatedTotals.hours : est.manualLevelOfEffortHours
    return <div className="ui-text-center">{total > 0 ? `${total} ${t('General.HoursAlt')}` : '-'}</div>
  }, [])

  const renderTotalHours = useCallback((phase: PhaseEx) => {
    let total = 0
    phase.estimates?.forEach((est) => {
      total += est.negotiatedTotals ? est.negotiatedTotals.hours : est.manualLevelOfEffortHours
    })

    return <div className="ui-text-center">{`${total} ${t('General.HoursAlt')}`}</div>
  }, [])

  return (
    <>
      <h3>{t('EditProject.Fees.Breakdown')}</h3>
      <br />

      <div style={{ overflowX: 'auto' }}>
        <Grid rowData={project.phases} className="ui-grid-wrapper-header" renderHeader={({ name }, index) => <div className={index > 0 ? 'ui-text-xxs' : ''}>{name}</div>}>
          <GridColumn
            name={t('General.Phase')}
            width={200}
            minWidth={150}
            flex={1}
            render={({ data: { phaseType, name } }) => (
              <div className="ui-text-bold">
                {t(`EditProject.${phaseType}`)}
                {name && (
                  <span className="ui-text-muted" style={{ marginLeft: 5 }}>
                    ({name})
                  </span>
                )}
              </div>
            )}
          />

          <GridColumn name={t(`General.Disciplines.${DisciplineKey.ProjectPrincipal}`)} width={80} minWidth={80} render={({ data }) => renderHours(data, DisciplineKey.ProjectPrincipal)} />

          <GridColumn name={t(`General.Disciplines.${DisciplineKey.ProjectManagement}`)} width={80} minWidth={80} render={({ data }) => renderHours(data, DisciplineKey.ProjectManagement)} />

          <GridColumn name={t(`General.Disciplines.${DisciplineKey.QaQc}`)} width={80} minWidth={80} render={({ data }) => renderHours(data, DisciplineKey.QaQc)} />

          <GridColumn name={t(`General.Disciplines.${DisciplineKey.General}`)} width={80} minWidth={80} render={({ data }) => renderHours(data, DisciplineKey.General)} />

          <GridColumn name={t(`General.Disciplines.${DisciplineKey.Mechanical}`)} width={80} minWidth={80} render={({ data }) => renderHours(data, DisciplineKey.Mechanical)} />

          <GridColumn name={t(`General.Disciplines.${DisciplineKey.Electrical}`)} width={80} minWidth={80} render={({ data }) => renderHours(data, DisciplineKey.Electrical)} />

          <GridColumn name={t(`General.Disciplines.${DisciplineKey.SiteCivil}`)} width={80} minWidth={80} render={({ data }) => renderHours(data, DisciplineKey.SiteCivil)} />

          <GridColumn name={t(`General.Disciplines.${DisciplineKey.TechnicalAssistant}`)} width={80} minWidth={80} render={({ data }) => renderHours(data, DisciplineKey.TechnicalAssistant)} />

          <GridColumn name={t(`General.Disciplines.${DisciplineKey.SubjectMaterExpert}`)} width={80} minWidth={80} render={({ data }) => renderHours(data, DisciplineKey.SubjectMaterExpert)} />

          <GridColumn name={t(`General.TotalHours`)} width={80} minWidth={80} render={({ data }) => renderTotalHours(data)} />

          <GridColumn
            name={t(`General.Total`)}
            width={80}
            minWidth={80}
            render={({ data: { negotiatedTotals, manualTotals } }: GridContentRenderProps<PhaseEx>) => (
              <div className="ui-text-center ui-text-bold">{t('General.Currency.Value', { value: negotiatedTotals ? negotiatedTotals.total : manualTotals?.total || 0 })}</div>
            )}
          />
        </Grid>
      </div>
    </>
  )
}
