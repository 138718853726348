import { useCallback, useEffect, useRef } from 'react'
import { atom, useRecoilState, useRecoilValue } from 'recoil'
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr'
import { Comment } from '../dto/comment'
import { getAccessToken, aadConfig } from '../aad'
import { useCurrentUserAccount, useMe } from './account'

// The one and only comment connection object
let connection: HubConnection | undefined

export const commentConnectionReadyAtom = atom<boolean>({
  key: 'commentConnectionReady',
  default: false
})

export interface CommentChannel {
  channel: string
  sourceChannel?: string
  name?: string
  userId?: string
  userType?: string
  scope?: string
  role?: string
  newCount: number
}
export interface CommentChannels {
  accountId: string
  projectId?: string
  groups: CommentChannel[]
  direct: CommentChannel[]
}

export const commentChannelsAtom = atom<CommentChannels | undefined>({
  key: 'commentChannels',
  default: undefined
})
export const currentCommentChannelAtom = atom<CommentChannel | undefined>({
  key: 'currentCommentChannel',
  default: undefined
})

export type CommentEx = Comment & {
  newCount?: number
}

export const commentsAtom = atom<{ [channel: string]: CommentEx[] }>({
  key: 'comments',
  default: {}
})

export const RECENT_COMMENTS_KEY = '$recent$'

/** *
 * Comment websocket hook.
 */
export const useCommentWs = () => {
  const [account] = useCurrentUserAccount()
  const [me] = useMe()

  const [ready, setReady] = useRecoilState(commentConnectionReadyAtom)
  const [channels, setChannels] = useRecoilState(commentChannelsAtom)
  const channelsRef = useRef<CommentChannels>()
  channelsRef.current = channels

  const [currentChannel, setCurrentChannel] = useRecoilState(currentCommentChannelAtom)
  const currentChannelRef = useRef<CommentChannel>()
  currentChannelRef.current = currentChannel

  const [, setComments] = useRecoilState(commentsAtom)

  const fetchChannels = useCallback(async (accountId: string, projectId?: string) => {
    if (connection?.state !== HubConnectionState.Connected) return
    return await connection.send('FetchChannels', accountId, projectId || null)
  }, [])

  const fetchComments = useCallback(async (channel: string, sourceChannel: string | null, before: number) => {
    if (connection?.state !== HubConnectionState.Connected) return
    return await connection.send('FetchComments', channel, sourceChannel, before, null)
  }, [])

  const fetchRecent = useCallback(async () => {
    if (connection?.state !== HubConnectionState.Connected) return
    return await connection.send('FetchRecent')
  }, [])

  const postComment = useCallback(
    async (content: any, plainText: string, url?: string) => {
      if (connection?.state !== HubConnectionState.Connected || !currentChannelRef.current) return

      const { channel, sourceChannel } = currentChannelRef.current
      return await connection.send('Post', channel, sourceChannel, JSON.stringify(content), plainText, url, null, null)
    },
    [account]
  )

  const handleConnected = useCallback(() => {
    setReady(true)
  }, [setReady])

  const handleFetchChannels = useCallback(
    (chs: CommentChannels) => {
      setChannels(chs)
      setCurrentChannel(chs.groups[0])
    },
    [setChannels, setCurrentChannel]
  )

  const addComments = useCallback(
    (comments: Comment[], isRecent?: boolean) => {
      if (isRecent) {
        setComments((current) => {
          const cx = { ...current }

          Object.keys(current).forEach((key) => {
            cx[key] = [...current[key]]
          })

          comments.forEach((c) => {
            if (!cx[RECENT_COMMENTS_KEY]) cx[RECENT_COMMENTS_KEY] = []

            const idx = cx[RECENT_COMMENTS_KEY].findIndex((x) => x.id === c.id)
            if (idx < 0) cx[RECENT_COMMENTS_KEY].push(c)
            else cx[RECENT_COMMENTS_KEY][idx] = c
          })

          if (!cx[RECENT_COMMENTS_KEY]) cx[RECENT_COMMENTS_KEY] = []
          cx[RECENT_COMMENTS_KEY] = cx[RECENT_COMMENTS_KEY].sort((a, b) => a.createdAt - b.createdAt)

          return cx
        })

        return
      }

      setComments((current) => {
        const cx = { ...current }
        let isInitLoad = false

        Object.keys(current).forEach((key) => {
          cx[key] = [...current[key]]
        })

        if (!comments.length) {
          if (!currentChannelRef.current) return cx

          const currentCh = currentChannelRef.current
          let isInitLoad = false

          let key = `${currentCh.channel}${currentCh.sourceChannel || ''}`
          if (!cx[key]) {
            cx[key] = []
            isInitLoad = true
          }

          if (currentCh.sourceChannel) {
            key = `${currentCh.sourceChannel}${currentCh.channel}`
            if (!cx[key]) {
              cx[key] = []
              isInitLoad = true
            }
          }

          if (isInitLoad) window.dispatchEvent(new Event('initLoadComments'))

          return cx
        }

        comments.forEach((c) => {
          let key = `${c.channel}${c.sourceChannel || ''}`

          if (!cx[key]) {
            cx[key] = []
            isInitLoad = true
          }

          let idx = cx[key].findIndex((x) => x.id === c.id)
          if (idx < 0) cx[key].push(c)
          else cx[key][idx] = c

          cx[key] = cx[key].sort((a, b) => a.createdAt - b.createdAt)

          if (c.sourceChannel) {
            key = `${c.sourceChannel}${c.channel}`

            if (!cx[key]) {
              cx[key] = []
              isInitLoad = true
            }

            idx = cx[key].findIndex((x) => x.id === c.id)
            if (idx < 0) cx[key].push(c)
            else cx[key][idx] = c

            cx[key] = cx[key].sort((a, b) => a.createdAt - b.createdAt)
          }
        })

        if (isInitLoad) window.dispatchEvent(new Event('initLoadComments'))
        return cx
      })
    },
    [setComments]
  )

  const handleFetchComments = useCallback(
    (comments: Comment[]) => {
      addComments(comments)
    },
    [addComments]
  )

  const handleRecentComments = useCallback(
    (comments: Comment[]) => {
      addComments(comments, true)
    },
    [addComments]
  )

  const handleNewChannels = useCallback(() => {}, [])

  const handleNewComment = useCallback(
    (comment: Comment) => {
      addComments([comment])
      addComments([comment], true)

      window.dispatchEvent(new Event('newComment'))

      if (comment.sourceUser.id === me?.id) return

      if (channelsRef.current) {
        const ch = JSON.parse(JSON.stringify(channelsRef.current)) as CommentChannels

        const chs = comment.sourceChannel
          ? ch.direct.filter((x) => (x.channel === comment.channel && x.sourceChannel === comment.sourceChannel) || (x.channel === comment.sourceChannel && x.sourceChannel === comment.channel))
          : ch.groups.filter((x) => x.channel === comment.channel)

        if (chs.length) {
          chs.forEach((x) => {
            x.newCount = (x.newCount || 0) + 1
          })
          setChannels(ch)
        }
      }
    },
    [addComments, me]
  )

  const handleUpdateChannels = useCallback(() => {}, [])

  const handleUpdateComment = useCallback(
    (comment: Comment) => {
      addComments([comment])
    },
    [addComments]
  )

  const handleDeleteChannels = useCallback(() => {}, [])

  const handleDeleteComment = useCallback(() => {}, [])

  useEffect(() => {
    if (!account?.id || connection?.baseUrl.endsWith(account.id)) return

    if (connection && connection.state === HubConnectionState.Connected) {
      connection.stop().then(() => {})
    }

    // Build the connection
    connection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_COMMENT_API}/comments?aid=${account.id}`, {
        accessTokenFactory: () => getAccessToken(aadConfig().commentScopes).then((r) => r as string)
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Warning)
      .build()

    setTimeout(() => {
      // Start the connection
      connection!.start().then(() => {
        if (!connection) return

        connection.on('RecvFetchChannels', handleFetchChannels)
        connection.on('RecvFetchComments', handleFetchComments)
        connection.on('RecvRecentComments', handleRecentComments)
        connection.on('RecvNewChannels', handleNewChannels)
        connection.on('RecvNewComment', handleNewComment)
        connection.on('RecvUpdateChannels', handleUpdateChannels)
        connection.on('RecvUpdateComment', handleUpdateComment)
        connection.on('RecvDeleteChannels', handleDeleteChannels)
        connection.on('RecvDeleteComment', handleDeleteComment)

        handleConnected()
      })
    })
  }, [account])

  useEffect(() => {
    if (!currentChannel || !channelsRef.current) return

    const ch = JSON.parse(JSON.stringify(channelsRef.current)) as CommentChannels

    const chs = currentChannel.sourceChannel
      ? ch.direct.filter(
          (x) =>
            (x.channel === currentChannel.channel && x.sourceChannel === currentChannel.sourceChannel) || (x.channel === currentChannel.sourceChannel && x.sourceChannel === currentChannel.channel)
        )
      : ch.groups.filter((x) => x.channel === currentChannel.channel)

    if (chs.length) {
      chs.forEach((x) => {
        x.newCount = 0
      })
      setChannels(ch)
    }
  }, [currentChannel])

  return {
    connection,
    ready,
    fetchChannels,
    fetchComments,
    fetchRecent,
    postComment
  }
}

export interface CommentPanelState {
  view?: 'closed' | 'open' | 'full'
  title?: string
  accountId?: string
  projectId?: string
}
export const commentPanelAtom = atom<CommentPanelState>({
  key: 'commentPanel',
  default: {}
})

export const useCommentPanel = () => useRecoilState(commentPanelAtom)

/** *
 * Comment channel hook.
 * @param {string} accountId - The account id.
 * @param {string} projectId - Optional project id.
 */
export const useCommentChannels = (accountId?: string, projectId?: string) => {
  const [current, setCurrent] = useRecoilState(commentChannelsAtom)
  const [currentChannel, setCurrentChannel] = useRecoilState(currentCommentChannelAtom)
  const { fetchChannels } = useCommentWs()

  const setChannels = useCallback(
    (channels?: CommentChannels) => {
      setCurrent(channels)
      setCurrentChannel(channels?.groups[0])
    },
    [setCurrent, setCurrentChannel]
  )

  useEffect(() => {
    if (!accountId) return

    if (current?.accountId === accountId && current?.projectId === projectId) return
    fetchChannels(accountId, projectId).then(() => {})
  }, [accountId, projectId, fetchChannels])

  const setChannel = useCallback(
    (ch?: CommentChannel) => {
      window.dispatchEvent(new Event('commentChannelChangeStart'))
      setCurrentChannel(ch)
      setTimeout(() => window.dispatchEvent(new Event('commentChannelChangeDone')))
    },
    [setCurrentChannel]
  )

  return {
    channels: current,
    setChannels,
    currentChannel,
    setCurrentChannel: setChannel
  }
}

/** *
 * Comment collection hook.
 */
export const useComments = () => {
  const ready = useRecoilValue(commentConnectionReadyAtom)
  const [currentChannel, setCurrentChannel] = useRecoilState(currentCommentChannelAtom)
  const [comments, setComments] = useRecoilState(commentsAtom)

  const { fetchComments, fetchRecent } = useCommentWs()

  const loadComments = useCallback(
    (before?: number) => {
      if (!currentChannel) return
      fetchComments(currentChannel.channel, currentChannel.sourceChannel || null, before || Date.now()).then(() => {})
    },
    [currentChannel, fetchComments]
  )

  const loadRecent = useCallback(() => {
    fetchRecent().then(() => {})
  }, [fetchRecent])

  const setChannel = useCallback(
    (ch?: CommentChannel) => {
      window.dispatchEvent(new Event('commentChannelChangeStart'))
      setCurrentChannel(ch)
      setTimeout(() => window.dispatchEvent(new Event('commentChannelChangeDone')))
    },
    [setCurrentChannel]
  )

  return {
    ready,
    comments,
    loadComments,
    loadRecent,
    setComments,
    currentChannel,
    setCurrentChannel: setChannel
  }
}
