import React from 'react'
import { useTranslation } from 'react-i18next'

import '../new/style.scss'
import { Modal, ModalProps } from 'components/src/modal'

export type UploadingProps = ModalProps & {
  filename: string
  progress: number
  onCancel: () => void
}

export const Uploading: React.FC<UploadingProps> = ({ filename, progress, onCancel, ...props }) => {
  const { t } = useTranslation()

  return (
    <Modal header={<h3>{t('NewProject.Upload.UploadingTitle')}</h3>} closeOnClickOutside={false} {...props}>
      <div className="new-project-uploading">
        <div className="current-progress">
          <div style={{ width: `${Math.min(Math.max(0, progress * 100), 100)}%` }} />
        </div>
        <div className="current-name">{t('NewProject.Upload.Uploading', { filename })}</div>

        <button type="button" className="ui-btn ui-btn-primary ui-btn-solid" style={{ width: '100%' }} onClick={onCancel}>
          {t('General.Cancel')}
        </button>
      </div>
    </Modal>
  )
}
