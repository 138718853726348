import React from 'react'
import { useTranslation } from 'react-i18next'
import { Number } from 'components/src/numberField/Number'
import { useSaving } from 'components/src/saving'
import { ProjectStatus } from 'services/src/dto/projectShare'
import { FeeGridViewModelEstimate } from './common'

export const FeeGridBillableHours: React.FC<{
  estimate: FeeGridViewModelEstimate
  onChange: (estimate: FeeGridViewModelEstimate) => void
  canEdit?: boolean
}> = ({ estimate, onChange, canEdit }) => {
  const { t } = useTranslation()
  const [saving] = useSaving()

  return (
    <>
      <div className="ui-text-sm" style={{ marginLeft: 20 }}>
        <div className="ui-text-center ui-text-xxs ui-flex" style={{ marginBottom: 5 }}>
          <div className="ui-text-uppercase ui-text-bold ui-text-muted" style={{ width: 100 }}>
            {t('EditProject.Fees.BillableHours')}
          </div>
        </div>

        <div className="ui-flex">
          {canEdit ? (
            <div style={{ height: 25, width: 100 }}>
              <Number
                value={estimate.hours || 0}
                disabled={saving || !canEdit}
                className="ui-text-sm"
                digits={1}
                step={0.25}
                min={0}
                max={5000}
                style={{
                  height: 25,
                  lineHeight: '23px'
                }}
                onChange={(h) => {
                  const hours = h || 0

                  const labor = estimate.labor
                    .filter((l) => l.status !== ProjectStatus.Rejected)
                    .map((l) => {
                      const lx = { ...l }
                      if (!lx.split) lx.split = 1

                      if (lx.user?.expertType === 'FTE') {
                        lx.hours = hours * (lx.split || 0)
                      } else if (![ProjectStatus.Approved, ProjectStatus.InProgress, ProjectStatus.Complete, ProjectStatus.Canceled].includes(lx.status)) {
                        lx.hours = hours * (lx.split || 0)
                      }
                      lx.cost = (lx.hours || 0) * lx.rate

                      lx.margin = (estimate.providerRate - lx.rate) / estimate.providerRate
                      return lx
                    })

                  const subtotal = estimate.providerRate * hours
                  onChange({
                    ...estimate,
                    labor,
                    hours,
                    subtotal,
                    total: subtotal + estimate.otherFeeTotal
                  })
                }}
              />
            </div>
          ) : (
            <div className="ui-flex ui-frame ui-frame-no-padding ui-text-center" style={{ height: 25, width: 100, lineHeight: '23px' }}>
              {estimate.hours || '--'}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
