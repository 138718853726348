import { useHistory, useRouteMatch } from 'react-router-dom'
import React, { useEffect, useMemo, useState } from 'react'
import { ResourceUserRole, User, UserStatus } from 'services/src/dto/account'
import { getUsers, updateUser } from 'services/src/api'
import { OverlayModal } from '../layout/OverlayModal'
import { Loading } from '../../loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { SelectField } from '../../selectField'
import { TextField } from '../../textField'
import moment from 'moment/moment'

export const EditUser = () => {
  const {
    params: { id }
  } = useRouteMatch<{ id: string }>()
  const history = useHistory()

  const [saving, setSaving] = useState<boolean>(false)
  const [workUser, setWorkUser] = useState<User>()
  const [workStatus, setWorkStatus] = useState<any>()

  const statusItems = useMemo(() => {
    return [
      {
        id: UserStatus.Ready,
        label: 'Active'
      },
      {
        id: UserStatus.Unavailable,
        label: 'Deactivated'
      }
    ]
  }, [workUser])

  const [errors, setErrors] = useState<any>({})

  const initState = (u: User) => {
    setWorkUser(u)
    setWorkStatus(statusItems.find((x) => x.id === u.status) ?? statusItems[0])
  }

  useEffect(() => {
    if (id === 'new') {
      setWorkUser({
        id: '$new$',
        givenName: '',
        familyName: '',
        status: UserStatus.Ready,
        role: ResourceUserRole.Owner
      })
      setTimeout(() => setWorkStatus(statusItems[0]))
      return
    }
    getUsers({ ids: [id] }).then((u) => {
      initState(u[0])
    })
  }, [id])

  useEffect(() => {
    if (!workUser) return
    setWorkStatus(statusItems.find((x) => x.id === workUser.status) ?? statusItems[0])
  }, [workUser, statusItems])

  const handleChange = (property: string, value: string) => {
    const newWorkUser = { ...workUser } as any
    newWorkUser[property] = value
    setWorkUser(newWorkUser)

    const newErrors = { ...errors }
    delete newErrors[property]
    setErrors(newErrors)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (!workUser) return

    const newErrors: any = {}

    if (!workUser.givenName) newErrors.givenName = 'Required'
    if (!workUser.familyName) newErrors.familyName = 'Required'

    setErrors(newErrors)
    if (Object.keys(newErrors).length) return

    const user = { ...workUser }

    setSaving(true)
    if (user.id === '$new$') {
      // createUser(user)
      //   .then(({ account }) => {
      //     initState(account)
      //   })
      //   .catch((e) => {
      //     console.error(e)
      //   })
      //   .finally(() => setSaving(false))
      return
    }

    updateUser(null, user.id, user)
      .then((user) => {
        initState(user)
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => setSaving(false))
  }

  return (
    <OverlayModal>
      {saving && <Loading size="md" />}
      {workUser && (
        <>
          <button className="close" onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h1>{workUser.id === '$new$' ? 'Create' : 'Edit'} User</h1>

          <form onSubmit={handleSubmit}>
            <div className="ui-flex">
              <div style={{ flex: 0.8 }}>
                <TextField name="givenName" label="First Name" placeholder="First name" onChange={(v) => handleChange('givenName', v)} value={workUser.givenName} />
                <TextField name="familyName" label="Last Name" placeholder="Last name" onChange={(v) => handleChange('familyName', v)} value={workUser.familyName} />
              </div>

              <div style={{ flex: 0.2, marginLeft: 50, minWidth: 200 }}>
                <div className="ui-flex">
                  <button type="submit" className="ui-btn ui-btn-primary" style={{ flex: 1 }}>
                    {workUser.id === '$new$' ? 'Create User' : 'Update User'}
                  </button>
                  <button type="button" className="ui-btn ui-btn-secondary" style={{ marginLeft: 15, flex: 1 }} onClick={() => history.goBack()}>
                    Cancel
                  </button>
                </div>
                <br />
                <hr />
                <br />

                <SelectField
                  label="User Status"
                  items={statusItems}
                  labelField="label"
                  selected={workStatus}
                  onSelectedChange={(v) => {
                    setWorkStatus(v)
                    setWorkUser({ ...workUser, status: v.id })
                  }}
                />


                <div>
                  <label>Created At</label>
                  <div>{moment(workUser.createdAt).format('M/D/YYYY h:mm a')}</div>
                </div>
                <br />
                <div>
                  <label>Updated At</label>
                  <div>TBD</div>
                  {/* <div>{moment(workUser.updatedAt).format('M/D/YYYY h:mm a')}</div> */}
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </OverlayModal>
  )
}
