import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faUsersGear, faRulerTriangle } from '@fortawesome/pro-duotone-svg-icons'

import './style.scss'
import { Layout } from 'components/src/pages/layout/public'
import logoLight from 'components/src/img/cc-light-alt.png'
import logoDark from 'components/src/img/cc-dark-alt.png'
import { useTheme } from 'components/src/theme'

export const Home: React.FC = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()

  useEffect(() => {
    // remove just in case!
    localStorage.removeItem('inB2c')
    sessionStorage.clear()
    document.cookie.split(";").forEach(function(c) {
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  }, [])

  return (
    <>
      <Layout className="home">
        <div className="logo ui-text-center">
          <a href={process.env.REACT_APP_CC_SITE}>
            <img
              alt="Construction Check, Inc"
              src={theme === 'dark' ? logoLight : logoDark}
              style={{ width: '75%', maxWidth: 200 }}
            />
          </a>
        </div>
        <h1 style={{ marginTop: 30 }}>{t('Home.Text1')}</h1>
        <h2>{t('Home.Text2')}</h2>

        <div className="main-signin">
          <div>
            <Link to={`/login?back=${encodeURIComponent(`${process.env.REACT_APP_REDIRECT_URI}`)}`} className="ui-btn ui-btn-info ui-btn-solid ui-btn ui-btn-lg" style={{ width: '100%' }}>
              {t('General.SignIn')}
            </Link>
          </div>

          {/* <div style={{marginTop: 20}}> */}
          {/*   <Link to="/login?saml=1" className="ui-btn ui-btn-info ui-btn-solid ui-btn ui-btn-lg" style={{ width: '100%' }}> */}
          {/*     {t('General.SAMLSignIn')} */}
          {/*   </Link> */}
          {/* </div> */}

          <div style={{ marginTop: 50 }}>
            <h2>{t('Home.RegisterText')}</h2>
            <br />
            <Link to={`/register/client?back=${encodeURIComponent(`${process.env.REACT_APP_REDIRECT_URI}`)}`} className="register-btn" style={{ width: '100%' }}>
              <FontAwesomeIcon icon={faUsers} size="2x" />
              <div style={{ marginLeft: 20 }}>
                {t('Home.ClientRegister')}
                <div className="ui-text-xs">{t('Home.ClientRegisterHelp')}</div>
              </div>
            </Link>
            <Link to={`/register/provider?back=${encodeURIComponent(`${process.env.REACT_APP_REDIRECT_URI}`)}`} className="register-btn" style={{ width: '100%', marginTop: 20 }}>
              <FontAwesomeIcon icon={faUsersGear} size="2x" />
              <div style={{ marginLeft: 20 }}>
                {t('Home.ProviderRegister')}
                <div className="ui-text-xs">{t('Home.ProviderRegisterHelp')}</div>
              </div>
            </Link>
            <Link to={`/register/expert?back=${encodeURIComponent(`${process.env.REACT_APP_REDIRECT_URI}`)}`} className="register-btn" style={{ width: '100%', marginTop: 20 }}>
              <FontAwesomeIcon icon={faRulerTriangle} size="2x" />
              <div style={{ marginLeft: 20 }}>
                {t('Home.ExpertRegister')}
                <div className="ui-text-xs">{t('Home.ExpertRegisterHelp')}</div>
              </div>
            </Link>
          </div>
        </div>
      </Layout>
    </>
  )
}
