import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateLeft } from '@fortawesome/pro-regular-svg-icons'

import { getDifficultyItems } from 'services/src/common'
import { Select } from 'components/src/select'
import { useSaving } from 'components/src/saving'
import { FeeGridViewModel, FeeGridViewModelEstimate } from './common'
import { Number } from 'components/src/numberField/Number'
import { ProjectStatus } from 'services/src/dto/projectShare'

export const FeeGridBillingLaborRate: React.FC<{
  vm: FeeGridViewModel
  estimate: FeeGridViewModelEstimate
  onChange: (estimate: FeeGridViewModelEstimate) => void
  canEdit?: boolean
}> = ({ vm, estimate, onChange, canEdit }) => {
  const { t } = useTranslation()
  const [saving] = useSaving()

  const [difficultyItems] = useState(getDifficultyItems(t))
  const [selectedDifficulty, setSelectedDifficulty] = useState(difficultyItems[estimate.difficulty])

  if (vm.variant === 'assign') return null

  return (
    <>
      <div className="ui-text-sm">
        <div
          className="ui-flex ui-text-xxs ui-text-center ui-text-uppercase ui-text-bold ui-text-muted"
          style={{
            marginBottom: 5,
            justifyContent: estimate.isManualProviderRate ? 'flex-end' : undefined
          }}
        >
          {!estimate.isManualProviderRate && <div style={{ width: 100 }}>{t('EditProject.Fees.BaseRate')}</div>}
          {!estimate.isManualProviderRate && <div style={{ flex: 1 }}>{t('EditProject.Fees.Difficulty')}</div>}

          {estimate.isManualProviderRate && <div style={{ width: 230, minWidth: 230 }} />}
          <div style={{ width: 100, minWidth: 100, maxWidth: 100 }}>{t('EditProject.Fees.ProviderRate')}</div>
        </div>

        <div className="ui-flex">
          {estimate.isManualProviderRate ? (
            <div style={{ width: 233, minWidth: 233 }} />
          ) : (
            <>
              <div className="ui-flex ui-frame ui-frame-no-padding ui-text-center" style={{ height: 25, width: 100, lineHeight: '23px' }}>
                {t('General.Currency.Value', { value: estimate.providerBaseRate })}
              </div>

              <div className="ui-text-muted" style={{ margin: '0 3px', width: 10, height: 25, lineHeight: '23px' }}>
                &times;
              </div>

              <div style={{ width: 120 }}>
                <Select
                  items={difficultyItems!}
                  selected={selectedDifficulty}
                  className="ui-select-sm"
                  disabled={saving || !canEdit}
                  onSelectedChange={(d) => {
                    setSelectedDifficulty(d)

                    const vm = { ...estimate, difficulty: parseInt(d.id, 10) }
                    vm.providerRate = estimate.providerBaseRate * difficultyItems[vm.difficulty].multiplier
                    vm.subtotal = vm.providerRate * estimate.hours
                    vm.total = vm.subtotal + estimate.otherFeeTotal
                    vm.labor = vm.labor.map((l) => {
                      const lx = { ...l }
                      lx.margin = (vm.providerRate - lx.rate) / vm.providerRate
                      return lx
                    })

                    onChange(vm)
                  }}
                />
              </div>
              <div className="ui-flex ui-frame ui-frame-no-padding ui-text-center" style={{ height: 25, width: 35, marginLeft: 3, lineHeight: '23px' }}>
                <span className="ui-text-muted">{selectedDifficulty.multiplier.toFixed(2)}</span>
              </div>

              <div className="ui-text-muted" style={{ margin: '0 3px', width: 10, height: 25, lineHeight: '23px' }}>
                =
              </div>
            </>
          )}

          {canEdit ? (
            <>
              {estimate.isManualProviderRate && (
                <button
                  type="button"
                  className="ui-btn-empty ui-info"
                  style={{ marginRight: 5, width: 25 }}
                  tabIndex={-1}
                  title={t('EditProject.Fees.RestoreProviderRate')}
                  onClick={() => {
                    const vm = { ...estimate, isManualProviderRate: false, providerBaseRate: estimate.estimate.baseRate }
                    vm.providerRate = vm.providerBaseRate * difficultyItems[vm.difficulty].multiplier
                    vm.subtotal = vm.providerRate * estimate.hours
                    vm.total = vm.subtotal + estimate.otherFeeTotal
                    vm.labor = vm.labor.map((l) => {
                      const lx = { ...l }
                      lx.margin = (vm.providerRate - lx.rate) / vm.providerRate
                      return lx
                    })

                    onChange(vm)
                  }}
                >
                  <FontAwesomeIcon icon={faRotateLeft} />
                </button>
              )}
              <div style={{ height: 25, width: 100, minWidth: 100, maxWidth: 100 }}>
                <Number
                  value={parseFloat(estimate.providerRate.toFixed(2))}
                  disabled={saving || !canEdit}
                  className="ui-text-sm"
                  digits={2}
                  step={0.01}
                  min={0}
                  max={500}
                  style={{
                    height: 25,
                    lineHeight: '23px'
                  }}
                  onChange={(pr) => {
                    const providerRate = pr || 0

                    const labor = estimate.labor
                      .filter((l) => l.status !== ProjectStatus.Rejected)
                      .map((l) => {
                        const lx = { ...l }
                        lx.margin = (providerRate - lx.rate) / providerRate
                        return lx
                      })

                    const subtotal = providerRate * estimate.hours
                    onChange({
                      ...estimate,
                      isManualProviderRate: estimate.estimate.baseRate !== providerRate,
                      providerRate,
                      labor,
                      subtotal,
                      total: subtotal + estimate.otherFeeTotal
                    })
                  }}
                />
              </div>
            </>
          ) : (
            <>
              {estimate.isManualProviderRate && <div style={{ width: 30, minWidth: 30, maxWidth: 30 }} />}
              <div className="ui-flex ui-frame ui-frame-no-padding ui-text-center" style={{ height: 25, width: 100, lineHeight: '23px' }}>
                {t('General.Currency.Value', { value: estimate.providerRate })}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
