import React, { useCallback, useMemo, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useCurrentUserAccount, useEditProject, useRights, UserType } from 'services/src/state'
import { ProjectStatus } from 'services/src/dto/project'

import { useSaving } from 'components/src/saving'
import { ConfirmModal } from 'components/src/modal/ConfirmModal'
import { deleteProject } from 'services/src/api'
import { makeDashboardPath } from 'services/src/dom'
import { useAlert } from 'components/src/alerts'
import { Footer } from './Footer'
import { ProjectSummary } from '../common/ProjectSummary'

export const ProjectOverview: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { project } = useEditProject()
  const [saving, setSaving] = useSaving()
  const { pathname } = useLocation()
  const { alertSuccess, alertDanger } = useAlert()
  const [account] = useCurrentUserAccount()
  const rights = useRights()

  const [confirmCancel, setConfirmCancel] = useState(false)

  const [confirmDelete, setConfirmDelete] = useState(false)

  const handleDelete = useCallback(() => {
    if (!project.account.id || !project.id) return

    setConfirmDelete(false)
    setSaving(true)
    deleteProject(project.account.id, project.id)
      .then(() => {
        alertSuccess({
          title: t('EditProject.DeleteProject.SuccessTitle'),
          message: t('EditProject.DeleteProject.Success', { name: project.name })
        })
        history.replace(makeDashboardPath(account?.id, 'projects'))
      })
      .catch((err) => {
        alertDanger({
          title: t('EditProject.DeleteProject.FailedTitle'),
          message: t('EditProject.DeleteProject.Failed'),
          error: err
        })
      })
      .finally(() => setSaving(false))
  }, [project, t])

  const footerActions = useMemo(() => {
    const left: JSX.Element[] = []
    const right: JSX.Element[] = []

    if (project.status === ProjectStatus.Draft || rights?.isGuest) return []

    left.push(
      <Link key="L0" to={makeDashboardPath(account?.id, 'projects')} className={`ui-btn ui-btn-primary ui-btn-solid${saving ? ' ui-disabled' : ''}`} style={{ width: 150, marginRight: 10 }}>
        {t('General.Exit')}
      </Link>
    )

    if (rights?.userType === UserType.provider) {
      if ([ProjectStatus.Approved, ProjectStatus.InProgress].includes(project.status!)) {
        left.push(
          <Link
            key="L1"
            to={pathname}
            className={`ui-btn ui-btn-danger ui-btn-solid${saving ? ' ui-disabled' : ''}`}
            style={{ width: 'auto' }}
            onClick={(e) => {
              e.preventDefault()
              if (saving) return
              setConfirmCancel(true)
            }}
          >
            {t('General.CancelProject')}
          </Link>
        )
      }

      if ([ProjectStatus.Canceled].includes(project.status!)) {
        left.push(
          <Link
            key="L2"
            to={pathname}
            className={`ui-btn ui-btn-danger ui-btn-solid${saving ? ' ui-disabled' : ''}`}
            style={{ width: 'auto' }}
            onClick={(e) => {
              e.preventDefault()
              if (saving) return
              setConfirmDelete(true)
            }}
          >
            {t('EditProject.DeleteProject.Title')}
          </Link>
        )
      }

      if (project.status === ProjectStatus.Submitted)
        right.push(
          <Link
            key="R0"
            to={pathname}
            className={`ui-btn ui-btn-info ui-btn-solid${saving ? ' ui-disabled' : ''}`}
            style={{ width: 'auto' }}
            onClick={(e) => {
              e.preventDefault()
              if (saving) return
              window.dispatchEvent(new Event('acceptProject'))
            }}
          >
            {t('General.AcceptProjectBtn')}
          </Link>
        )

      if (project.status === ProjectStatus.Accepted)
        right.push(
          <Link
            key="R1"
            to={pathname}
            className={`ui-btn ui-btn-info ui-btn-solid${saving ? ' ui-disabled' : ''}`}
            style={{ width: 'auto' }}
            onClick={(e) => {
              e.preventDefault()
              if (saving) return
              window.dispatchEvent(new Event('submitProject'))
            }}
          >
            {t('General.AcceptToClientProject')}
          </Link>
        )

      if (project.status === ProjectStatus.Approved) {
        right.push(
          <Link
            key="R2"
            to={pathname}
            className={`ui-btn ui-btn-info ui-btn-solid${saving ? ' ui-disabled' : ''}`}
            style={{ width: 'auto' }}
            onClick={(e) => {
              e.preventDefault()
              if (saving) return
              window.dispatchEvent(new Event('startProject'))
            }}
          >
            {t('General.StartProject')}
          </Link>
        )
      }

      /*
      if (project.canComplete)
        right.push(
          <Link
            key="R3"
            to={pathname}
            className={`ui-btn ui-btn-info ui-btn-solid${saving ? ' ui-disabled' : ''}`}
            style={{ width: 'auto' }}
            onClick={(e) => {
              e.preventDefault();
              if (saving) return;
              window.dispatchEvent(new Event('completeProject'));
            }}
          >
            {t('General.CompleteProject')}
          </Link>,
        );
      */
    }

    if (project.canEdit)
      right.push(
        <Link
          key="R4"
          to={`${pathname}/edit`}
          className={`ui-btn ui-btn-secondary ui-btn-solid${saving ? ' ui-disabled' : ''}`}
          style={{ width: 150, marginLeft: 10 }}
          onClick={(e) => {
            if (saving) e.preventDefault()
          }}
        >
          {t('General.EditDetails')}
        </Link>
      )

    if (left.length && right.length) return [...left, <div key="xx" style={{ marginLeft: 'auto' }} />, ...right]
    if (left.length) return left
    if (right.length) return [<div key="xx" style={{ marginLeft: 'auto' }} />, ...right]

    return []
  }, [rights, project, pathname, saving, t])

  return (
    <>
      <ProjectSummary project={project} />

      {/* <InfoGraph project={project} /> */}

      {footerActions.length > 0 && (
        <Footer>
          <div className="ui-flex ui-flex-nowrap">
            {footerActions.map((a, idx) => (
              <React.Fragment key={idx}>{a}</React.Fragment>
            ))}
          </div>
        </Footer>
      )}

      {confirmCancel && (
        <ConfirmModal
          title={t('EditProject.CancelProject.Title')}
          message={
            <div
              dangerouslySetInnerHTML={{
                __html: t('EditProject.CancelProject.Confirm', {
                  name: project.name,
                  interpolation: { escapeValue: false }
                })
              }}
            />
          }
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={() => {
            window.dispatchEvent(new Event('cancelProject'))
            setConfirmCancel(false)
          }}
          onClose={() => setConfirmCancel(false)}
        />
      )}

      {confirmDelete && (
        <ConfirmModal
          title={t('EditProject.DeleteProject.Title')}
          message={
            <div
              dangerouslySetInnerHTML={{
                __html: t('EditProject.DeleteProject.Confirm', {
                  name: project.name,
                  interpolation: { escapeValue: false }
                })
              }}
            />
          }
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={handleDelete}
          onClose={() => setConfirmDelete(false)}
        />
      )}
    </>
  )
}
