import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalProps } from './index'
import { TextAreaField } from '../textAreaField'
import { SelectField } from '../selectField'

type ConfirmModalProps = ModalProps & {
  title: JSX.Element | string
  message: JSX.Element | string
  yes?: JSX.Element | string
  no?: JSX.Element | string
  onYes: (reason?: string) => void
  onClose: () => void
  reasonLabel?: JSX.Element | string
  reasonPlaceholder?: string
  reasonItems?: string[]
  reasonError?: string
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({ title, message, yes, no, onYes, onClose, reasonLabel, reasonPlaceholder, reasonItems: initReasonItems, reasonError, ...rest }) => {
  const { t } = useTranslation()

  const [reason, setReason] = useState('')
  const reasonItems = useMemo<{ id: string; label: string }[] | undefined>(() => initReasonItems?.map((r) => ({ id: r, label: r })), [initReasonItems])

  const submitRef = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    submitRef.current?.focus()
  }, [])

  return (
    <Modal header={<h3>{title}</h3>} onClose={onClose} closeOnClickOutside={false} {...rest}>
      <form
        noValidate
        onSubmit={(e) => {
          e.preventDefault()
          e.stopPropagation()
          onYes(reason || undefined)
        }}
      >
        <div className="ui-form-group">
          {message}
          {reasonLabel && !reasonItems && (
            <div style={{ marginTop: 20 }}>
              <TextAreaField name="reason" className="ui-text-sm" label={reasonLabel} placeholder={reasonPlaceholder} error={reasonError} rows={3} value={reason} onChange={(v) => setReason(v)} />
            </div>
          )}
          {reasonLabel && reasonItems && (
            <div style={{ marginTop: 20 }}>
              <SelectField
                className="ui-text-sm"
                label={reasonLabel}
                placeholder={reasonPlaceholder}
                error={reasonError}
                items={reasonItems}
                selected={reasonItems.find((r) => r.id === reason)}
                onSelectedChange={(i) => setReason(i.id)}
              />
            </div>
          )}
        </div>

        <div className="ui-row">
          <div className="ui-col-6">
            <button
              type="button"
              className="ui-btn ui-btn-default ui-btn-solid"
              style={{ width: '100%' }}
              onClick={() => {
                if (onClose) onClose()
              }}
            >
              {no || t('General.No')}
            </button>
          </div>
          <div className="ui-col-6">
            <button type="submit" ref={submitRef} className="ui-btn ui-btn-primary ui-btn-solid" style={{ width: '100%' }}>
              {yes || t('General.Yes')}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
