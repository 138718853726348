import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PhaseEx, useCurrentUserAccount, useEditProject, useRights, UserType } from 'services/src/state'
import { PhaseTypeKey } from 'services/src/dto/projectShare'

import { Grid, GridColumn, GridContentRenderProps } from 'components/src/grid'
import drawing from 'components/src/img/drawing.png'
import moment from 'moment'
import { makeDashboardPath } from 'services/src/dom'
import { ProjectStatus } from 'services/src/dto/project'

interface PhaseItem {
  phase?: PhaseEx
  phaseType: PhaseTypeKey
  placeholder?: string
  name?: string
  isParent?: boolean
  hasParent?: boolean
}

export const FeeComparisonSummary: React.FC = () => {
  const { t } = useTranslation()
  const [account] = useCurrentUserAccount()
  const { project } = useEditProject()
  const rights = useRights();

  const [phases, setPhases] = useState<PhaseItem[]>([])

  useEffect(() => {
    const phases: PhaseItem[] = []

    // Group by type
    ;[
      {
        phaseType: PhaseTypeKey.Concept,
        phases: project.phases.filter((p) => p.phaseType === PhaseTypeKey.Concept && p.status !== ProjectStatus.New)
      },
      {
        phaseType: PhaseTypeKey.Schematic,
        phases: project.phases.filter((p) => p.phaseType === PhaseTypeKey.Schematic && p.status !== ProjectStatus.New)
      },
      {
        phaseType: PhaseTypeKey.DesignDoc,
        phases: project.phases.filter((p) => p.phaseType === PhaseTypeKey.DesignDoc && p.status !== ProjectStatus.New)
      },
      {
        phaseType: PhaseTypeKey.ConstructionDoc,
        phases: project.phases.filter((p) => p.phaseType === PhaseTypeKey.ConstructionDoc && p.status !== ProjectStatus.New)
      },
      {
        phaseType: PhaseTypeKey.Proposal,
        phases: project.phases.filter((p) => p.phaseType === PhaseTypeKey.Proposal && p.status !== ProjectStatus.New)
      }
    ].forEach((x) => {
      const hasParent = x.phases.length > 1

      // Multiple of the same type?
      // if (hasParent) {
      //  phases.push({isParent: true, phaseType: x.phaseType});
      // }

      // Add phases
      x.phases.forEach((phase, index) => {
        const phaseType = phase.phaseType as PhaseTypeKey

        let placeholder = t(`EditProject.${phaseType}`)
        if (hasParent) placeholder = t(`EditProject.Fees.OptionIndex`, { index })

        phases.push({
          phase,
          phaseType,
          hasParent,
          name: phase.name,
          placeholder
        })
      })
    })

    setPhases(phases)
  }, [project, setPhases, t])

  const { hasNeg } = useMemo(() => {
    const hasNeg = phases.filter((ph) => !!ph.phase?.negotiatedTotals).length > 0
    return { hasNeg }
  }, [phases])

  return (
    <div className="edit-project-fees-comparison-grid">
      <h3>{t('EditProject.Fees.ComparisonSummary')}</h3>
      <br />

      <div style={{ overflowX: 'auto' }}>
        <Grid rowData={phases}>
          <GridColumn
            field="phase"
            name={t('EditProject.Fees.Phase')}
            render={({ data }: GridContentRenderProps<PhaseItem>) => (
              <div className="ui-text-ellipsis ui-text-bold">
                <Link to={`${makeDashboardPath(account?.id, `projects/${project.id}/phases/${data.phase?.id}/fees`)}`}>
                  {t(`EditProject.${data.phaseType}`)}
                  {data.name && (
                    <span className="ui-text-muted" style={{ marginLeft: 5 }}>
                      ({data.name})
                    </span>
                  )}
                </Link>
              </div>
            )}
            flex={1}
            width={230}
            minWidth={150}
          />

          <GridColumn
            field="drawings"
            name={t('EditProject.Fees.Drawings')}
            render={({ data }: GridContentRenderProps<PhaseItem>) => (
              <div className="ui-text-center">
                <Link to={`${makeDashboardPath(account?.id, `projects/${project.id}/phases/${data.phase?.id}/files/drawings`)}`}>
                  <img alt="" src={drawing} style={{ width: 20 }} />
                </Link>
              </div>
            )}
            width={80}
            minWidth={80}
          />

          <GridColumn
            field="subDel"
            style={{ marginLeft: 20 }}
            name={t('General.SubmissionDeliveryDate')}
            render={({ data }: GridContentRenderProps<PhaseItem>) => (
              <div className="ui-text-center">
                {data.phase?.submissionDate && moment(data.phase.submissionDate).format('ll')}
                &nbsp;-&nbsp;
                {data.phase?.deliveryDate && moment(data.phase.deliveryDate).format('ll')}
              </div>
            )}
            width={190}
            minWidth={190}
          />

          <GridColumn
            field="genFee"
            name={t('EditProject.Fees.GenFee')}
            render={({ data }: GridContentRenderProps<PhaseItem>) => {
              if (!data.phase?.status) return <div />

              return <div className="ui-text-center">{data.phase?.generatedTotals && t('General.Currency.Value', { value: data.phase.generatedTotals.total })}</div>
            }}
            width={80}
            minWidth={80}
          />

          <GridColumn
            field="genPercent"
            style={{ marginLeft: 5 }}
            name={t('EditProject.Fees.FeePercent')}
            render={({ data }: GridContentRenderProps<PhaseItem>) => {
              if (!data.phase?.status) return <div />

              if (data.phase?.phaseType === PhaseTypeKey.Concept) return <div className="ui-text-center">{t('General.NA')}</div>
              return <div className="ui-text-center">{data.phase?.feeWeight && t('General.PercentValue', { value: (data.phase.feeWeight * 100).toFixed(1) })}</div>
            }}
            width={60}
            minWidth={60}
          />

          <GridColumn
            field="manualFee"
            name={t('EditProject.Fees.Fee')}
            render={({ data }: GridContentRenderProps<PhaseItem>) => {
              if (!data.phase?.status) return <div />

              return <div className="ui-text-center">{data.phase?.manualTotals && t('General.Currency.Value', { value: data.phase.manualTotals.total })}</div>
            }}
            width={80}
            minWidth={80}
          />

          <GridColumn
            field="manualPercent"
            style={{ marginLeft: 5 }}
            name={t('EditProject.Fees.FeePercent')}
            render={({ data }: GridContentRenderProps<PhaseItem>) => {
              if (!data.phase?.status) return <div />

              if (data.phase?.phaseType === PhaseTypeKey.Concept) return <div className="ui-text-center">--</div>

              return <div className="ui-text-center">{data.phase?.manualFeeWeight && t('General.PercentValue', { value: (data.phase.manualFeeWeight * 100).toFixed(1) })}</div>
            }}
            width={60}
            minWidth={60}
          />

          <GridColumn
            field="manualMargin"
            style={{ marginLeft: 5 }}
            name={t('EditProject.Fees.FeeMargin')}
            render={({ data }: GridContentRenderProps<PhaseItem>) => {
              if (!data.phase?.status) return <div />

              return <div className="ui-text-center">{data.phase?.manualTotals?.margin && t('General.PercentValue', { value: (data.phase.manualTotals.margin * 100).toFixed(1) })}</div>
            }}
            width={80}
            minWidth={80}
          />

          {hasNeg && (
            <GridColumn
              className="ui-danger"
              field="negFee"
              name={t('EditProject.Fees.NegFee')}
              render={({ data }: GridContentRenderProps<PhaseItem>) => {
                if (!data.phase?.status) return <div />

                return <div className="ui-text-center">{data.phase?.negotiatedTotals ? t('General.Currency.Value', { value: data.phase.negotiatedTotals.total }) : '--'}</div>
              }}
              width={80}
              minWidth={80}
            />
          )}

          {hasNeg && (
            <GridColumn
              className="ui-danger"
              field="negPercent"
              style={{ marginLeft: 5 }}
              name={t('EditProject.Fees.FeePercent')}
              render={({ data }: GridContentRenderProps<PhaseItem>) => {
                if (!data.phase?.status) return <div />

                if (data.phase?.phaseType === PhaseTypeKey.Concept) return <div className="ui-text-center">--</div>

                return (
                  <div className="ui-text-center">
                    {data.phase?.negotiatedTotals && data.phase?.manualFeeWeight ? t('General.PercentValue', { value: (data.phase!.manualFeeWeight! * 100).toFixed(1) }) : '--'}
                  </div>
                )
              }}
              width={60}
              minWidth={60}
            />
          )}

          {hasNeg && (
            <GridColumn
              className="ui-danger"
              field="negMargin"
              style={{ marginLeft: 5 }}
              name={t('EditProject.Fees.FeeMargin')}
              render={({ data }: GridContentRenderProps<PhaseItem>) => {
                if (!data.phase?.status) return <div />

                return <div className="ui-text-center">{data.phase?.negotiatedTotals?.margin ? t('General.PercentValue', { value: (data.phase.negotiatedTotals.margin * 100).toFixed(1) }) : '--'}</div>
              }}
              width={80}
              minWidth={80}
            />
          )}

          {/*rights?.userType === UserType.provider && (
            <GridColumn
              field="providerFee"
              name={t('EditProject.Fees.TechFeeAlt')}
              render={({ data }: GridContentRenderProps<PhaseItem>) => {
                if (!data.phase?.status) return <div />

                return <div className="ui-text-center">{data.phase?.providerFee && t('General.Currency.Value', { value: data.phase.providerFee })}</div>
              }}
              width={80}
              minWidth={80}
            />
          )*/}

          <GridColumn
            field="status"
            style={{ marginLeft: 20 }}
            name={t('EditProject.Fees.Latest')}
            render={({ data: { phase } }: GridContentRenderProps<PhaseItem>) => {
              const sh = phase?.statusHistory![0]
              if (!sh) return null

              if (sh.metadata?.reason) {
                return (
                  <div>
                    <label className="ui-text-xxs ui-text-bold">{moment(sh.createdAt).format('LL')}</label>
                    <div className="ui-text-xxs">{sh.metadata?.reason}</div>
                  </div>
                )
              }

              return null
            }}
            width={200}
            minWidth={200}
          />
        </Grid>
      </div>
    </div>
  )
}
