import React, { useEffect, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { logout, useSessionTimeout } from 'services/src/aad'
import { useTranslation } from 'react-i18next'

import { Loading } from 'components/src/loading'

import { Login } from 'components/src/pages/Login'
import { Logout } from 'components/src/pages/Logout'
import { Register } from 'components/src/pages/Register'
import { InviteVerify, InviteComplete } from 'components/src/pages/invite'
import { NotFound } from 'components/src/pages/error/notFound'
import { Onboarding } from 'components/src/pages/onboarding/Onboarding'
import { useAlert } from 'components/src/alerts'
import { WrongApp } from 'components/src/pages/error/wrongApp'
import { Protected } from './Protected'
import { ApprovalPending } from 'components/src/pages/error/ApprovalPending'
import { StringParam, useQueryParam } from 'use-query-params'
import { makeDashboardPath } from 'services/src/dom'
import { getProjectBasic } from 'services/src/api'

const Admin = React.lazy(() => import('../admin/Admin').then(({ Admin }) => ({ default: Admin })))
const Dashboard = React.lazy(() => import('../dashboard/Dashboard').then(({ Dashboard }) => ({ default: Dashboard })))
const Home = React.lazy(() => import('../home/Home').then(({ Home }) => ({ default: Home })))

const ProjectEntry = () => {
  const [projectId] = useQueryParam("projectId", StringParam)
  const history = useHistory()

  useEffect(() => {
    if (!projectId) {
      history.replace(makeDashboardPath())
      return;
    }

    getProjectBasic(projectId)
      .then((p) => {
        history.replace(makeDashboardPath(p.accountId, `projects/${p.projectId}`))
      });

  }, [projectId])

  return <Loading />
}

const Routes: React.FC = () => {
  const { t } = useTranslation()

  const msal = useMsal()
  const history = useHistory()

  const { isNearEnd, isEnd } = useSessionTimeout()
  const { alertDanger, clearAlert } = useAlert()
  const [timeoutAlertId, setTimeoutAlertId] = useState(-1)

  useEffect(() => {
    const l = history.listen(() => {
      window.scrollTo(0, 0)
      window.dispatchEvent(new Event('toggleAlertDrawerClose'))
    })

    return () => {
      l()
    }
  }, [history])

  useEffect(() => {
    if (!isNearEnd) {
      if (timeoutAlertId < 0) return
      clearAlert(timeoutAlertId)
      setTimeoutAlertId(-1)
      return
    }
    if (timeoutAlertId >= 0) return

    setTimeout(() => {
      setTimeoutAlertId(
        alertDanger({
          title: t('General.TimeoutTitle'),
          message: t('General.Timeout'),
          noAutoHide: true
        })
      )
    })
  }, [isNearEnd, alertDanger, clearAlert, timeoutAlertId, t])

  useEffect(() => {
    if (!isEnd) return
    logout().then(() => {})
  }, [isEnd])

  if (['startup', 'handleRedirect'].includes(msal.inProgress)) return <Loading />

  return (
    <Switch>
      <Route path="/admin" component={Admin} />
      <Route path="/dashboard/:accountId?" component={Dashboard} />
      <Route path="/dashboard/projects" component={ProjectEntry} />
      <Route path="/onboarding">
        <Protected>
          <Onboarding />
        </Protected>
      </Route>
      <Route path="/invite" exact component={InviteVerify} />
      <Route path="/invite/complete" component={InviteComplete} />
      <Route path="/login" component={Login} />
      <Route path="/logout" component={Logout} />
      <Route path="/register/:which?" component={Register} />
      <Route path="/approval-pending" exact component={ApprovalPending} />
      <Route path="/switch-app" exact component={WrongApp} />
      <Route path="/" exact component={Home} />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}
export default Routes
