import React, { SVGProps } from 'react'
import './style.scss'

const FolderOpen: React.FC<SVGProps<SVGSVGElement>> = ({ className, ...rest }) => (
  <svg viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg" className={`ui-svg ui-svg-folder-open ${className || ''}`} {...rest}>
    <path
      d="M15.6245 2.27542H8.87457V1.71542C8.87457 1.30307 8.52496 0.96875 8.09278 0.96875H3.00602C2.57384 0.96875 2.22266 1.30307 2.22266 1.71542V2.92763V3.58208V14.1607C2.22266 14.5209 2.52962 14.7821 2.90722 14.7821H15.6247C16.0023 14.7821 16.3093 14.5209 16.3093 14.1607V2.92763C16.3091 2.56755 16.0021 2.27542 15.6245 2.27542Z"
      fill="#0786E2"
    />
    <path
      d="M15.2479 14.5954H3.28185C3.11985 14.5954 2.97253 14.4729 2.95238 14.321L1.68363 4.73006C1.65448 4.50923 1.79025 4.3291 1.98727 4.3291H16.5424C16.7395 4.3291 16.8752 4.50923 16.8461 4.73006L15.5773 14.321C15.5572 14.4729 15.4099 14.5954 15.2479 14.5954Z"
      fill="#17FADE"
    />
    <path
      d="M16.4236 14.4535C16.359 14.743 16.0323 14.9692 15.6923 14.9692H2.8388C2.49877 14.9692 2.17205 14.743 2.10748 14.4535L0.287599 6.29594C0.183907 5.83114 0.457027 5.44922 0.899575 5.44922H17.6315C18.074 5.44922 18.3474 5.83114 18.2437 6.29594L16.4236 14.4535Z"
      fill="#0786E2"
    />
    <path d="M15.5252 3.95565V3.47741C15.5252 3.29728 15.3876 3.20898 15.199 3.20898H3.33005C3.14125 3.20898 3.00391 3.29728 3.00391 3.47741V3.95565H15.5252Z" fill="#7CEEE7" />
  </svg>
)
export default FolderOpen
