import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { TFunction, useTranslation } from 'react-i18next'
import moment from 'moment'
import { StringParam, useQueryParam } from 'use-query-params'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { FilterChangedEvent, GridApi, GridReadyEvent, ICellRendererParams, IDatasource, IGetRowsParams, RowClickedEvent, SortChangedEvent, SortModelItem, ValueGetterParams } from 'ag-grid-community'
import 'ag-grid-enterprise'

import './styles.scss'

import { getProjects } from 'services/src/api'

import LoadingSvg from 'components/src/svg/Loading'
import { StatusBadge } from 'components/src/statusBadge'
import { EstimateTypeKey, ProjectStatus } from 'services/src/dto/project'

import { useCurrentUserAccount, useCurrentUserOffice, useRights, UserType } from 'services/src/state'
import { setTitle } from 'services/src/dom'

import { ProjectListStatusFilter, projectListStatusFilterItems } from './ProjectListStatusFilter'
import { ProjectListHeader } from './ProjectListHeader'
import { AccountType } from 'services/src/dto/account'

const projectsDatasource = (gridApi: GridApi, accountId?: string, officeId?: string, search?: string) =>
  ({
    // called by the grid when more rows are required
    getRows: ({ sortModel, filterModel, successCallback, failCallback, startRow, endRow }: IGetRowsParams) => {
      let sort = 'name'
      let sortDir = 'asc'
      if (sortModel?.length) {
        sort = sortModel[0].colId
        sortDir = sortModel[0].sort
      }

      let filter: string | undefined
      if (filterModel) {
        const parts: string[] = []
        Object.keys(filterModel).forEach((colId) => {
          const f = filterModel[colId]
          if (f.filterType === 'text') parts.push(`${colId} ${f.type}('${f.filter}')`)
          else if (f.filterType === 'date') {
            if (f.type === 'inRange') parts.push(`${colId} ${f.type}('${f.dateFrom.split(' ')[0]}', '${f.dateTo.split(' ')[0]}')`)
            else parts.push(`${colId} ${f.type}('${f.dateFrom.split(' ')[0]}')`)
          } else if (f.filterType === 'set') {
            parts.push(`${colId} contains('${f.values.join(',')}')`)
          }
        })
        filter = parts.join(' and ')
      }

      if (search) filter = filter ? `${filter} and $qs contains('${search}')` : `$qs contains('${search}')`

      getProjects(accountId || null, officeId || null, startRow, endRow - startRow, sort, sortDir, filter)
        .then(({ total, projects }) => {
          successCallback(projects, total)
          if (!total) gridApi.showNoRowsOverlay()
          else gridApi.hideOverlay()
        })
        .catch(() => failCallback())
    }
  } as IDatasource)

const NoProjectOverlay = () => {
  const { t } = useTranslation()
  return (
    <div className="no-rows-overlay">
      <h3>{t('General.NoProjects')}</h3>
    </div>
  )
}

type NameCellProps = ICellRendererParams & {
  loadingMessage?: string
}
const NameCell = ({ value, loadingMessage }: NameCellProps) => {
  if (value === undefined)
    return (
      <div className="ag-custom-loading-cell ui-flex ui-flex-nowrap ui-text-muted" style={{ alignItems: 'center' }}>
        <LoadingSvg style={{ width: 25 }} />
        {loadingMessage && <div style={{ marginLeft: 10 }}>{loadingMessage}</div>}
      </div>
    )

  return value
}

type StatusBadgeCellProps = ICellRendererParams & {
  t: TFunction
  labelPrefix: string
}
const StatusBadgeCell = ({ value, labelPrefix, t }: StatusBadgeCellProps) => {
  if (!value) return ''
  return <StatusBadge style={{ marginTop: 10 }} label={t(`${labelPrefix}${value}`)} type={value} />
}

export const ProjectList: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { url } = useRouteMatch()
  const [initStatus] = useQueryParam('status', StringParam)

  const [account] = useCurrentUserAccount()
  const [office, setOffice] = useCurrentUserOffice()
  const rights = useRights()

  const sortKey = useMemo(() => (rights?.userType === UserType.client ? 'clientProjectsSort' : 'providerProjectsSort'), [rights])
  const filterKey = useMemo(() => (rights?.userType === UserType.client ? 'clientProjectsFilter' : 'providerProjectsFilter'), [rights])

  const [height, setHeight] = useState(500)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTitle(t('Projects.Title'))
    return () => {
      setTitle()
    }
  }, [t])

  const [gridApi, setGridApi] = useState<GridApi>()
  const gridReady = useCallback(
    ({ api, columnApi }: GridReadyEvent) => {
      setGridApi(api)
      api.setFilterModel(filter)
      columnApi.applyColumnState({
        state: [...sort]
      })
    },
    [setGridApi]
  )

  const [search, setSearch] = useState<string>(initStatus ? '' : localStorage.getItem(`${filterKey}-search`) || '')
  const searchTimerRef = useRef<any>()

  useEffect(() => {
    if (!gridApi) return
    gridApi.setDatasource(projectsDatasource(gridApi, account?.id, office?.id, search))
  }, [gridApi, account, office])

  const [sort, setSort] = useState(() => {
    const s = localStorage.getItem(sortKey)
    return s ? JSON.parse(s) : [{ colId: 'name', sort: 'asc' }]
  })

  const sortChange = useCallback(
    ({ columnApi }: SortChangedEvent) => {
      const s = columnApi.getColumnState()
      const sm = s.filter((x) => !!x.colId && !!x.sort).map<SortModelItem>((x) => ({ colId: x.colId!, sort: x.sort! }))

      if (sm.length <= 0) return

      setSort(sm)
      if (sortKey) localStorage.setItem(sortKey, JSON.stringify(sm))
    },
    [setSort, sortKey]
  )

  const [filter, setFilter] = useState(() => {
    const f = localStorage.getItem(filterKey)
    let fx: any = f ? JSON.parse(f) : undefined
    if (initStatus) {
      if (!fx) fx = {}
      fx.status = { values: [initStatus] }
    }
    return fx
  })

  const [statusFilter, setStatusFilter] = useState<ProjectStatus[]>(() => {
    if (!filter?.status) return []
    return [...filter.status.values]
  })

  const filterChange = useCallback(
    ({ api }: FilterChangedEvent) => {
      const fm = api.getFilterModel()
      if (!fm || !Object.keys(fm).length) {
        if (filterKey) localStorage.removeItem(filterKey)
        setFilter(undefined)
        setStatusFilter([])
        return
      }

      setFilter(fm)
      if (filterKey) localStorage.setItem(filterKey, JSON.stringify(fm))
      setStatusFilter(fm?.status?.values?.length ? fm.status.values : [])
    },
    [setFilter, filterKey, setStatusFilter]
  )

  const rowClick = useCallback(
    (e: RowClickedEvent) => {
      if (!e.data?.id) return

      const path = e.data.status === ProjectStatus.Draft
        ? `${url}/new?projectId=${e.data.id}`
        : `${url}/${e.data.id}`

      const ev = e.event as any
      if (ev.metaKey) {
        window.open(path)
        return
      }

      history.push(path)
    },
    [history]
  )

  useEffect(() => {
    const resize = () => {
      if (containerRef.current) {
        const r = containerRef.current.getBoundingClientRect()
        setHeight(window.innerHeight - r.top)
      }
    }
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  useEffect(() => {
    if (containerRef.current) {
      const r = containerRef.current.getBoundingClientRect()
      setHeight(window.innerHeight - r.top)
    }
  }, [containerRef.current])

  const toggleStatus = useCallback(
    (s: ProjectStatus) => {
      setStatusFilter((current) => {
        const f = [...current]

        const i = f.findIndex((x) => x === s)
        if (i >= 0) f.splice(i, 1)
        else f.push(s)

        if (gridApi) {
          const fm = gridApi.getFilterModel()
          fm.status = { filterType: 'set', values: [...f] }
          gridApi.setFilterModel(fm)
        }
        return f
      })
    },
    [setStatusFilter, gridApi]
  )

  return (
    <>
      <ProjectListHeader url={url} />

      <div className="project-index" style={{ paddingBottom: 0 }}>
        <ProjectListStatusFilter
          selected={statusFilter}
          onToggle={toggleStatus}
          canClear={!!filter || !!search}
          onClear={() => {
            setOffice(undefined)
            if (filterKey) localStorage.removeItem(filterKey)
            setFilter(undefined)
            setStatusFilter([])
            setSearch('')
            localStorage.removeItem(`${filterKey}-search`)
            if (gridApi) {
              gridApi.setDatasource(projectsDatasource(gridApi, account?.id, office?.id))
              gridApi.setFilterModel(undefined)
              gridApi?.onFilterChanged()
            }
          }}
          search={search}
          onSearchChange={(search) => {
            setSearch(search)
            localStorage.setItem(`${filterKey}-search`, search)

            // Debounce the change
            clearTimeout(searchTimerRef.current)
            searchTimerRef.current = setTimeout(() => {
              if (!gridApi) return

              gridApi.setDatasource(projectsDatasource(gridApi, account?.id, office?.id, search))
              gridApi?.onFilterChanged()
            }, 500)
          }}
        />
        <br />

        <div className="ag-theme-cc" style={{ height, width: '100%' }} ref={containerRef}>
          <AgGridReact
            defaultColDef={{
              width: 150,
              minWidth: 150,
              editable: false,
              resizable: true,
              sortable: true,
              sortingOrder: ['asc', 'desc']
            }}
            frameworkComponents={{
              noProjectOverlay: NoProjectOverlay,
              nameCell: NameCell,
              statusBadgeCell: StatusBadgeCell
            }}
            isExternalFilterPresent={() => !!search}
            doesExternalFilterPass={() => true}
            noRowsOverlayComponent="noProjectOverlay"
            blockLoadDebounceMillis={500}
            cacheBlockSize={25}
            rowSelection="single"
            suppressCellSelection
            suppressRowHoverHighlight
            rowModelType="infinite"
            onRowClicked={rowClick}
            onSortChanged={sortChange}
            onFilterChanged={filterChange}
            onGridReady={gridReady}
          >
            <AgGridColumn field="id" hide headerName="Id" />

            <AgGridColumn
              field="name"
              headerName={t('Projects.Name')}
              minWidth={150}
              width={2000}
              flex={1}
              filter="agTextColumnFilter"
              filterParams={{
                filterOptions: ['contains', 'startsWith', 'endsWith'],
                defaultOption: 'contains',
                suppressAndOrCondition: true
              }}
              cellRenderer="nameCell"
              cellRendererParams={{ loadingMessage: t('General.OneMoment') }}
            />

            <AgGridColumn
              field="estimateType"
              headerName={t('Projects.EstType')}
              width={150}
              maxWidth={150}
              minWidth={150}
              filter="agSetColumnFilter"
              filterParams={{
                values: [t('General.EstimateTypes.IndependentCostEstimate'), t('General.EstimateTypes.IndependentCostReview')],
                suppressSorting: true,
                suppressMiniFilter: true,
                defaultToNothingSelected: true
              }}
              valueGetter={({ data }: ValueGetterParams) => (data?.estimateType ? t(`General.EstimateTypes.${data.estimateType || EstimateTypeKey.IndependentCostEstimate}`) : '')}
            />

            {account?.type === AccountType.Provider && (
              <AgGridColumn
                field={rights?.userType === UserType.client ? 'client' : 'account'}
                headerName={rights?.userType === UserType.client ? t('Projects.Client') : t('General.Account')}
                width={180}
                minWidth={150}
                flex={0.5}
                filter="agTextColumnFilter"
                filterParams={{
                  filterOptions: ['contains', 'startsWith', 'endsWith'],
                  defaultOption: 'contains',
                  suppressAndOrCondition: true
                }}
                valueGetter={({ data }: ValueGetterParams) => (rights?.userType === UserType.client ? data?.client || '' : data?.account || '')}
              />
            )}


            <AgGridColumn
              field="location"
              headerName={t('Projects.Location')}
              width={250}
              minWidth={150}
              flex={0.25}
              filter="agTextColumnFilter"
              filterParams={{
                filterOptions: ['contains', 'startsWith', 'endsWith'],
                defaultOption: 'contains',
                suppressAndOrCondition: true
              }}
              valueGetter={({ data }: ValueGetterParams) => data?.location || ''}
            />

            {account?.type === AccountType.Client && (
              <AgGridColumn
                field="provider"
                headerName={t('Projects.Provider')}
                minWidth={150}
                width={250}
                filter="agTextColumnFilter"
                filterParams={{
                  filterOptions: ['contains', 'startsWith', 'endsWith'],
                  defaultOption: 'contains',
                  suppressAndOrCondition: true
                }}
                valueGetter={({ data }: ValueGetterParams) => data?.provider}
              />
            )}

            <AgGridColumn
              field="pm"
              headerName={rights?.userType === UserType.provider ? t('Projects.ClientProjectManager') : t('Projects.ProviderProjectManager')}
              width={200}
              minWidth={150}
              flex={0.25}
              filter="agTextColumnFilter"
              filterParams={{
                filterOptions: ['contains', 'startsWith', 'endsWith'],
                defaultOption: 'contains',
                suppressAndOrCondition: true
              }}
              valueGetter={({ data }: ValueGetterParams) => data?.projectManager || ''}
            />

            <AgGridColumn
              field="startsAt"
              headerName={t('Projects.StartsAt')}
              width={150}
              maxWidth={150}
              minWidth={150}
              filter="agDateColumnFilter"
              filterParams={{
                suppressAndOrCondition: true
              }}
              valueGetter={({ data }: ValueGetterParams) => {
                if (!data) return ''
                return data.startsAt ? moment(data.startsAt).format('ll') : '--'
              }}
            />

            <AgGridColumn
              field="status"
              headerName={t('Projects.Status')}
              width={150}
              maxWidth={150}
              minWidth={150}
              filter="agSetColumnFilter"
              filterParams={{
                values: projectListStatusFilterItems,
                cellRenderer: (p: ICellRendererParams) => {
                  if (p.value.startsWith('(')) return p.value
                  return rights?.userType === UserType.client ? t(`Projects.ClientStatus.${p.value}`) : t(`Projects.${p.value}`)
                },
                suppressSorting: true,
                suppressMiniFilter: true,
                defaultToNothingSelected: true
              }}
              cellRenderer="statusBadgeCell"
              cellRendererParams={{
                t,
                labelPrefix: rights?.userType === UserType.client ? 'Projects.ClientStatus.' : 'Projects.'
              }}
              valueGetter={({ data }: ValueGetterParams) => data?.status}
            />
          </AgGridReact>
        </div>
      </div>
    </>
  )
}
