import React, { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faSignOut, faBellExclamation, faMoon, faSunBright } from '@fortawesome/pro-duotone-svg-icons'
import { faQuestion } from '@fortawesome/pro-solid-svg-icons'

import { useCurrentUserAccount, useMe, useRights } from 'services/src/state'

import './style.scss'
import { makeDashboardPath } from 'services/src/dom'
import { useAlert } from '../alerts'
import { useTheme } from '../theme'

export const UserMenu: React.FC = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [me] = useMe()
  const [account] = useCurrentUserAccount()
  const { count: alertCount } = useAlert()
  const rights = useRights()

  const initials = useMemo(() => {
    if (!me) return ''
    return `${me.givenName[0].toUpperCase()}${me.familyName[0].toUpperCase()}`
  }, [me])

  const { theme, setTheme } = useTheme()

  return (
    <div className="ui-user-menu ui-xs-hidden ui-sm-hidden ui-md-hidden ui-lg-visible">

      <div className="ui-user-menu-action" title={t('General.Support')}>
        <a href="https://support.constructioncheck.io/wp-login.php?clientUrl=1&redirect_to=/my-support-page" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faQuestion} />
        </a>
      </div>

      <div className="ui-user-menu-action" title={t('General.LogOut')}>
        <Link to="/logout">
          <FontAwesomeIcon icon={faSignOut} />
        </Link>
      </div>

      <div className="ui-user-menu-action" title={t('General.Settings')}>
        <Link to={makeDashboardPath(account?.id, 'settings/user')}>
          <FontAwesomeIcon icon={faCog} />
        </Link>
      </div>

      <div className="ui-user-menu-action" title={t('General.ToggleTheme')}>
        <Link
          to={pathname}
          onClick={(e) => {
            e.preventDefault()
            setTheme(theme === 'dark' ? 'light' : 'dark')
          }}
        >
          <FontAwesomeIcon icon={theme === 'dark' ? faSunBright : faMoon} />
        </Link>
      </div>

      <div className={`ui-user-menu-action${alertCount <= 0 ? ' disabled' : ''}`} title={t('General.Alerts')}>
        <Link
          to={pathname}
          onClick={(e) => {
            e.preventDefault()
            if (alertCount <= 0) return
            window.dispatchEvent(new Event('toggleAlertDrawer'))
          }}
        >
          <FontAwesomeIcon icon={faBellExclamation} />
          {alertCount > 0 && <span className="ui-user-menu-alert-badge">{alertCount}</span>}
        </Link>
      </div>

      {(() => {
        if (me?.pictureUrl) return <div className="ui-user-menu-ident ui-user-menu-image" style={{ backgroundImage: `url('${me.pictureUrl}')` }} />

        if (initials) return <div className="ui-user-menu-ident ui-user-menu-initials">{initials}</div>

        return <div className="ui-user-menu-ident ui-user-menu-image" />
      })()}
    </div>
  )
}
