import React from 'react'
import { useTranslation } from 'react-i18next'

import { NewProjectModel } from './model'
import { SummaryFooter } from './Footer'
import { ProjectSummary } from '../common/ProjectSummary'

export interface SummaryProps {
  model: NewProjectModel
  active: boolean
  step: number
  onBack: () => void
  onSubmit: () => void
  onSaveForLater: () => void
  onCancel: () => void
}

export const Summary: React.FC<SummaryProps> = ({ model, active, step, onBack, onCancel, onSubmit, onSaveForLater }) => {
  const { t } = useTranslation()

  if (!active) return null

  return (
    <>
      <div className="new-project-step-header">
        <div>{t('General.StepN', { N: step + 1 })}</div>
        <div>{t('NewProject.Summary.Title')}</div>
      </div>

      <ProjectSummary project={model} />

      <SummaryFooter onBack={onBack} onSubmit={onSubmit} onSaveForLater={onSaveForLater} onCancel={onCancel} />
    </>
  )
}
