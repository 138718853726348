import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/pro-solid-svg-icons'
import { Link, useLocation } from 'react-router-dom'
import { faBellExclamation, faCog, faMoon, faSignOut, faSunBright } from '@fortawesome/pro-duotone-svg-icons'

import './style.scss'
import { useCurrentUserAccount, useMe, useRights, UserType } from 'services/src/state'
import { makeDashboardPath } from 'services/src/dom'
import { useAlert } from 'components/src/alerts'
import { useTheme } from 'components/src/theme'

interface MainAltNavProps {
  desktop: boolean
  open: boolean
  onCloseNav: () => void
}

export const MainAltNav: React.FC<MainAltNavProps> = ({ desktop, open, onCloseNav }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [account] = useCurrentUserAccount()
  const { count: alertCount } = useAlert()
  const [me] = useMe()
  const rights = useRights()

  const initials = useMemo(() => {
    if (!me) return ''
    return `${me.givenName[0].toUpperCase()}${me.familyName[0].toUpperCase()}`
  }, [me])

  const { theme, setTheme } = useTheme()

  return (
    <>
      {!desktop && open && <div className="main-nav-overlay" role="menu" tabIndex={-1} onKeyDown={() => {}} onClick={onCloseNav} />}
      <nav
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        role="menu"
        className={`main-alt-nav ${desktop ? 'desktop' : ''} ${open ? 'open' : ''}`}
        tabIndex={-1}
        onKeyDown={() => {}}
        onClick={onCloseNav}
      >
        <div className="ui-flex ui-flex-column" style={{ alignItems: 'center', height: '100%' }}>
          <div className="ui-badge ui-badge-primary ui-text-uppercase" style={{ marginTop: 20, marginBottom: 20 }}>
            {rights?.userType === UserType.client && t('General.Client')}
            {rights?.userType === UserType.provider && t('General.Provider')}
            {rights?.userType === UserType.expert && t('General.Expert')}
          </div>

          {/* eslint-disable-next-line no-nested-ternary */}
          {me?.pictureUrl ? (
            <div className="ui-user-menu-ident ui-user-menu-image" style={{ backgroundImage: `url('${me.pictureUrl}')` }} />
          ) : initials ? (
            <div className="ui-user-menu-ident ui-user-menu-initials">{initials}</div>
          ) : (
            <div className="ui-user-menu-ident ui-user-menu-image" />
          )}

          <div className="ui-user-menu-action" title={t('General.Settings')} style={{ marginTop: 40 }}>
            <Link to={makeDashboardPath(account?.id, 'settings/user')}>
              <FontAwesomeIcon icon={faCog} />
            </Link>
          </div>

          <div className={`ui-user-menu-action${alertCount <= 0 ? ' disabled' : ''}`} title={t('General.Alerts')}>
            <Link
              to={pathname}
              onClick={(e) => {
                e.preventDefault()
                window.dispatchEvent(new Event('toggleAlertDrawer'))
              }}
            >
              <FontAwesomeIcon icon={faBellExclamation} />
              {alertCount > 0 && <span className="ui-user-menu-alert-badge">{alertCount}</span>}
            </Link>
          </div>

          <div className="ui-user-menu-action" title={t('General.Support')}>
            <a href="https://support.constructioncheck.io" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faQuestion} />
            </a>
          </div>

          <div className="ui-user-menu-action" style={{ marginTop: 'auto' }} title={t('General.LogOut')}>
            <Link to="/logout">
              <FontAwesomeIcon icon={faSignOut} />
            </Link>
          </div>

          <div className="ui-user-menu-action" style={{ marginTop: 10, marginBottom: 20 }} title={t('General.ToggleTheme')}>
            <Link
              to={pathname}
              onClick={(e) => {
                e.preventDefault()
                setTheme(theme === 'dark' ? 'light' : 'dark')
              }}
            >
              <FontAwesomeIcon icon={theme === 'dark' ? faSunBright : faMoon} />
            </Link>
          </div>
        </div>
      </nav>
    </>
  )
}
