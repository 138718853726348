import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCheck, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'

import { completeInvitation } from 'services/src/api'
import { useCurrentUserAccount, useMe, useUserAccounts } from 'services/src/state'

import './style.scss'
import { Loading } from 'components/src/loading'
import { Account, AccountType } from 'services/src/dto/account'
import { Project } from 'services/src/dto/project'

import { Layout } from '../layout/public'

export const InviteComplete: React.FC = () => {
  const { t } = useTranslation()
  const [key] = useQueryParam('key', StringParam)
  const [, refreshMe] = useMe()
  const [, refreshAccounts] = useUserAccounts()
  const [, setAccount] = useCurrentUserAccount()

  const [error, setError] = useState<string | undefined>(!key ? 'NoKey' : undefined)
  const [result, setResult] = useState<{ account: Account; project?: Project, onboardClient?: boolean } | undefined>()
  const [ready, setReady] = useState<boolean>(false)
  const [inUpdate, setInUpdate] = useState<boolean>(false)

  const details = useMemo(() => {
    if (!result) return null

    const { account, onboardClient } = result

    const lines: React.ReactNode[] = []
    if (onboardClient) {
      lines.push(
        <div>{t('Invite.ClientOnboard')}</div>
      )
      lines.push(
        <div className="ui-flex" style={{ justifyContent: 'center' }}>
          <Link
            to="/onboarding"
            className="ui-btn ui-btn-info"
            style={{ padding: '20px 30px', width: 'auto' }}
          >
            {t('Invite.FinishOnboarding')}
          </Link>
        </div>
      )

    } else {
      if (account.type === AccountType.Provider)
        lines.push(
          // eslint-disable-next-line react/no-danger
          <div dangerouslySetInnerHTML={{ __html: t('Invite.ProviderAccountAccess', { account: account.companyName }) }} />
        )
      else
        lines.push(
          // eslint-disable-next-line react/no-danger
          <div dangerouslySetInnerHTML={{ __html: t('Invite.ClientAccountAccess', { account: account.companyName }) }} />
        )

      lines.push(
        <div className="ui-flex" style={{ justifyContent: 'center' }}>
          <Link
            to="/dashboard"
            className="ui-btn ui-btn-info"
            style={{ padding: '20px 30px', width: 'auto' }}
            onClick={() => setAccount(account.id)}
          >
            {t('Invite.Workspace')}
          </Link>
        </div>
      )
    }


    return (
      <div className="details">
        {lines.map((l, idx) => (
          <div key={idx}>{l}</div>
        ))}
      </div>
    )
  }, [result, setAccount, t])

  useEffect(() => {
    if (!key || ready || inUpdate) return

    setInUpdate(true)
    completeInvitation(key)
      .then((result) => {
        setResult(result)
        return Promise.all([refreshMe(), refreshAccounts()])
      })
      .catch((err) => {
        if (err.message.includes('404')) setError('NotFound')
        else if (err.message.includes('400')) setError('BadRequest')
        else setError('Unknown')
      })
      .finally(() => {
        setInUpdate(false)
        setReady(true)
      })
  }, [key, refreshMe, refreshAccounts, setResult, setError, setReady])

  if (error) {
    return (
      <Layout className="invite">
        <div className="error-code">
          <FontAwesomeIcon icon={faExclamationTriangle} /> {t(`Invite.Errors.${error}Title`)}
        </div>
        <div className="error-text ui-text-center">{t(`Invite.Errors.${error}`)}</div>
        <div className="ui-text-center ui-text-lg">
          <a href={process.env.REACT_APP_CC_SITE} className="ui-info">
            <FontAwesomeIcon icon={faArrowLeft} /> {t('General.BackToCC')}
          </a>
        </div>
      </Layout>
    )
  }

  if (!result) return <Loading />

  return (
    <Layout className="invite complete">
      <div className="ui-text-center">
        <h3>
          <FontAwesomeIcon icon={faCheck} /> {t('Invite.Welcome')}
        </h3>
      </div>
      {details}
    </Layout>
  )
}
