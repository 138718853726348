import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Account, ResourceUserRole } from 'services/src/dto/account'
import { CreateInvitation } from 'services/src/dto/invitation'

import { Modal, ModalProps } from 'components/src/modal'
import { TextField } from 'components/src/textField'
import { SelectField } from 'components/src/selectField'
import { validateEmail } from 'services/src/validate'

type InviteModalProps = Omit<ModalProps, 'onClose'> & {
  account: Account
  providerClient?: boolean
  onClose: (sent?: boolean) => void
  onSend: (invitation: CreateInvitation) => void
}

export const InviteModal: React.FC<InviteModalProps> = ({ account, providerClient, onClose, onSend, ...rest }) => {
  const { t } = useTranslation()

  const [errors, setErrors] = useState<any>({})
  const clearError = (name: string) => {
    const theErrors = { ...errors }
    delete theErrors[name]
    setErrors(theErrors)
  }

  const [clientName, setClientName] = useState('')
  const [givenName, setGivenName] = useState('')
  const [familyName, setFamilyName] = useState('')
  const [email, setEmail] = useState('')

  const roles = useMemo(
    () => [
      {
        role: ResourceUserRole.Owner.toString(),
        label: t(`General.AccountRoles.${ResourceUserRole.Owner}`)
      },
      {
        role: ResourceUserRole.Administrator.toString(),
        label: t(`General.AccountRoles.${ResourceUserRole.Administrator}`)
      },
      {
        role: ResourceUserRole.Contributor.toString(),
        label: t(`General.AccountRoles.${ResourceUserRole.Contributor}`)
      },
      {
        role: ResourceUserRole.Guest.toString(),
        label: t(`General.AccountRoles.${ResourceUserRole.Guest}`)
      }
    ],
    [t]
  )

  const [role, setRole] = useState()

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      e.stopPropagation()

      const errors: any = {}
      if (providerClient && !clientName) errors.clientName = t('General.Errors.Required')
      if (!givenName) errors.givenName = t('General.Errors.Required')
      if (!familyName) errors.familyName = t('General.Errors.Required')
      if (!email) errors.email = t('General.Errors.Required')
      else if (!validateEmail(email)) errors.email = t('General.Errors.InvalidEmail')
      if (!providerClient && !role) errors.role = t('General.Errors.Required')

      setErrors(errors)
      if (Object.keys(errors).length) return

      onSend({
        givenName,
        familyName,
        emailAddress: email,
        accountRole: role,
        providerClient: clientName || undefined
      })
    },
    [setErrors, t, givenName, familyName, email, role, clientName, providerClient]
  )

  return (
    <Modal header={<h3>{t(providerClient ? 'Settings.InviteProviderClientTitle' : 'Settings.InviteTitle')}</h3>} onClose={() => onClose(false)} closeOnClickOutside={false} {...rest}>
      <form noValidate onSubmit={submit}>
        <div className={`ui-row${errors.clientName || errors.givenName || errors.familyName || errors.email ? ' ui-has-error' : ''}`}>
          {providerClient && (
            <div className="ui-col-12">
              <TextField
                label={t('Settings.ClientName')}
                name="clientName"
                type="text"
                value={clientName}
                error={errors.clientName}
                placeholder={t('Settings.ClientNameSample')}
                onChange={(v) => {
                  setClientName(v)
                  clearError('clientName')
                }}
              />
            </div>
          )}

          <div className="ui-col-12">
            <label>{t('Settings.To')}</label>
          </div>

          <div className="ui-col-6">
            <TextField
              name="givenName"
              type="text"
              value={givenName}
              error={errors.givenName}
              placeholder={t('Settings.GivenNameSample')}
              onChange={(v) => {
                setGivenName(v)
                clearError('givenName')
              }}
            />
          </div>

          <div className="ui-col-6">
            <TextField
              name="familyName"
              type="text"
              value={familyName}
              error={errors.familyName}
              placeholder={t('Settings.FamilyNameSample')}
              onChange={(v) => {
                setFamilyName(v)
                clearError('familyName')
              }}
            />
          </div>

          <div className="ui-col-12">
            <TextField
              name="email"
              type="email"
              value={email}
              error={errors.email}
              placeholder={t('Settings.ToSample')}
              onChange={(v) => {
                setEmail(v)
                clearError('email')
              }}
            />
          </div>
        </div>

        {!providerClient && (
          <SelectField
            items={roles}
            selected={role}
            onSelectedChange={(item) => {
              setRole(item.role)
              clearError('role')
            }}
            labelField="label"
            valueField="role"
            error={errors.role}
            label={t('Settings.InviteRole')}
            placeholder={t('Settings.InviteRoleSample')}
            maxDropDown={5}
            renderItem={(r) => <div>{r.label}</div>}
            renderSelected={(r) => <div>{t(`Settings.${r}`)}</div>}
          />
        )}

        <div className="ui-text-right">
          <button type="submit" className="ui-btn ui-btn-primary ui-btn-solid" style={{ width: '100%' }}>
            {t('Settings.SendInvite')}
          </button>
        </div>
      </form>
    </Modal>
  )
}
