import { faLightbulbOn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loading } from 'components/src/loading';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostRequests } from 'services/src/api/boe';
import { endpoints } from 'services/src/lib/axios';
import { useEditProject } from 'services/src/state';
import { TextAreaField } from '../../../textAreaField';
import ProjectBoeProgressBar from './ProjectBoeProgressBar';

interface ProjectBoePurposeSectionProps {
  handleNext: () => void;
  handlePrevious: () => void;
  setBoeData: (data: any) => void;
  boeData: any;
  isProgressBarVisible: boolean;
  setIsProgressBarVisible: (value: boolean) => void;
}

interface Errors {
  [key: string]: string | undefined;
  purposeSection?: string;
}

const BoeProject11: React.FC<ProjectBoePurposeSectionProps> = ({
  handleNext,
  handlePrevious,
  setBoeData,
  boeData,
  isProgressBarVisible,
  setIsProgressBarVisible,
}) => {
  const { t } = useTranslation();
  const { project } = useEditProject();
  const progress = useMemo(() => 3, []);
  const [call, setCall] = useState<boolean>(false);
  const [purposeSection, setPurpose] = useState<string>(boeData?.purposeSection || '');
  const [errors, setErrors] = useState<Errors>({});

  const vm = useMemo(
    () => ({
      projectPhase: 'Schematic Documents (SD Docs)',
      projectTitle: project.name || '',
      projectLocation: project?.location?.freeformAddress || '',
      proposalDate: new Date().toISOString().split('T')[0],
      clientName: project.account?.companyName || '',
      ccCustomer: project.account?.users
        ? `${project.account?.users[0]?.familyName} ${project.account?.users[0]?.givenName}` || ''
        : '',
      clientAddress: project?.account?.offices ? project?.account?.offices[0]?.address?.freeformAddress || '' : '',
    }),
    [project]
  );

  const { loading, data, error, postRequests } = usePostRequests();

  useEffect(() => {
    if (vm && !call && purposeSection === '') {
      setCall(true);
      postRequests(endpoints.boe.purposeSection, vm);
    }
  }, [vm, call, postRequests, purposeSection]);

  useEffect(() => {
    if (data?.response) {
      setPurpose(data?.response || '');
    }
  }, [data?.response]);

  const clearError = (name: string) => {
    setErrors((current) => {
      const newErrors = { ...current };
      delete newErrors[name];
      return newErrors;
    });
  };

  const handleNextClick = () => {
    setBoeData((prev: any) => ({ ...prev, purposeSection }));
    handleNext();
  };

  return (
    <div className="boe-content">
      <div className="boe-form-content">
        <ProjectBoeProgressBar
          progress={progress}
          title={t('BasisOfEstimate.Introduction.ProgressTitle')}
          leftText={t('BasisOfEstimate.Introduction.ProgressLeftText')}
          rightText={t('BasisOfEstimate.Introduction.ProgressRightText')}
          isProgressBarVisible={isProgressBarVisible}
          setIsProgressBarVisible={setIsProgressBarVisible}
          boeData={boeData}
        />
       
       <div className="boe-content-flex-between">
          <h2 className="boe-section-header">{t('BasisOfEstimate.ProjectBoeScopeDefinitionSection.Title')}</h2>
          <div className="boe-section-header-right-content">
            <FontAwesomeIcon icon={faLightbulbOn} />
            <p>{t('BasisOfEstimate.ProjectBoeScopeDefinitionSection.Dynamic')}</p>
          </div>
        </div>

        {loading && <Loading />}
        {error && (
          <div className="boe-error-container">
            <p>{t('General.Error')}</p>
          </div>
        )}
        <TextAreaField
          name="purposeSection"
          label=""
          placeholder=""
          autoFocus
          error={errors.purposeSection}
          style={{ height: 324 }}
          value={purposeSection}
          onChange={(e) => {
            setPurpose(e);
            clearError('purposeSection');
          }}
        />
      </div>
      <div className="boe-form-action-container">
        <button type="button" disabled={loading} className="btn-next" onClick={handlePrevious}>
          {t('General.Previous')}
        </button>
        <button type="button" className="btn-previous" onClick={handleNextClick}>
          {t('General.Next')}
        </button>
      </div>
    </div>
  );
};

export default BoeProject11;