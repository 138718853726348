import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import moment, { Moment } from 'moment'

import { PhaseEx, useEditProject } from 'services/src/state'
import { ProjectStatus } from 'services/src/dto/project'
import logo from 'components/src/img/logo-alt.png'

export const FeeClientApproved: React.FC = () => {
  const { t } = useTranslation()
  const { project } = useEditProject()

  const { phases, total, approvedAt } = useMemo<{
    phases: PhaseEx[]
    total: number
    originalTotal: number
    approvedAt: Moment
  }>(() => {
    const phases = project.phases.filter((x) => x.status === ProjectStatus.Approved || x.status === ProjectStatus.InProgress || x.status === ProjectStatus.Complete)

    let total = 0
    let originalTotal = 0
    let approvedAt = moment()
    phases.forEach((ph) => {
      const sh = ph.statusHistory?.find((x) => x.newStatus === ProjectStatus.Approved)
      if (!sh) return

      total += sh.metadata?.fee || 0
      if (moment(sh.createdAt).isBefore(approvedAt)) approvedAt = moment(sh.createdAt)

      if (sh.oldStatus === ProjectStatus.FeeCounter) originalTotal += sh.metadata?.originalFee || 0
    })

    return { phases, total, originalTotal, approvedAt }
  }, [project])

  if (!phases.length) return null

  return (
    <div className="project-edit-client-accept ui-frame ui-frame-bg">
      <div className="the-total">
        <img alt="" src={logo} />
        <div style={{ marginLeft: 10 }}>{t('EditProject.Fees.FeeApproved')}</div>
        <div style={{ marginLeft: 'auto' }}>{approvedAt ? approvedAt.format('ll') : null}</div>
      </div>

      <div style={{ marginTop: 20 }} className="ui-text-sm">
        {phases.map((ph) => (
          <div key={ph.id} className="ui-flex ui-flex-nowrap">
            <div>
              {t(`EditProject.${ph.phaseType}`)}
              {ph.name && (
                <span className="ui-text-muted" style={{ marginLeft: 5 }}>
                  ({ph.name})
                </span>
              )}
            </div>
            <div style={{ marginLeft: 'auto' }}>
              {(() => {
                const sh = ph.statusHistory?.find((x) => x.newStatus === ProjectStatus.Approved)
                if (!sh) return undefined

                const value = sh.metadata?.fee || 0
                if (value <= 0) return undefined

                return t('General.Currency.Value', { value })
              })()}
            </div>
          </div>
        ))}
      </div>

      <div className="ui-flex ui-flex-nowrap ui-text-lg ui-secondary" style={{ marginTop: 10 }}>
        <div style={{ width: 200 }}>{t('General.Total')}</div>
        <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: total })}</div>
      </div>

      {/* originalTotal > 0 && (
          <div className="ui-text-sm">
            <div className="ui-flex ui-flex-nowrap ui-text-muted" style={{marginTop: 10}}>
              <div style={{width:200}}>{t('General.OriginalTotal')}</div>
              <div style={{marginLeft: 'auto'}}>
                {t('General.Currency.Value', {value: originalTotal})}
              </div>
            </div>
            <div className="ui-flex ui-flex-nowrap ui-text-muted">
              <div style={{width:200}}>{t('General.Adjustment')}</div>
              <div style={{marginLeft: 'auto'}}>
                {t('General.Currency.Value', {value: total - originalTotal})}
              </div>
            </div>
          </div>
        ) */}
    </div>
  )
}
