import React from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, ModalProps } from 'components/src/modal'
import ProjectSvg from 'components/src/svg/Projects'
import { PhaseEx } from 'services/src/state'

export type FeeApproveModalProps = ModalProps & {
  totalFee: number
  phases: PhaseEx[]
  onYes: () => void
}

export const FeeApproveModal: React.FC<FeeApproveModalProps> = ({ onClose, phases, totalFee, onYes }) => {
  const { t } = useTranslation()

  return (
    <Modal onClose={onClose} closeOnClickOutside={false} header={<h3 className="ui-text-bold">{t('EditProject.Fees.ApproveFeesModal.Title')}</h3>}>
      <div className="ui-text-center">
        <ProjectSvg width="100" />
      </div>
      <br />

      <div className="ui-text-sm" style={{ padding: '0 30px' }}>
        {phases.map((ph) => (
          <div key={ph.id} className="ui-flex ui-flex-nowrap">
            <div>{t(`EditProject.${ph.phaseType}`)}</div>
            <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: ph.fee || 0 })}</div>
          </div>
        ))}
        <hr />
        <div className="ui-flex ui-flex-nowrap ui-text-bold">
          <div>{t('EditProject.Fees.TotalFees')}</div>
          <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: totalFee })}</div>
        </div>
      </div>
      <br />

      <div>
        <button type="button" className="ui-btn ui-btn-secondary ui-btn-solid" style={{ width: '100%' }} onClick={() => onYes()}>
          {t('EditProject.Fees.ApproveFeesModal.Yes')}
        </button>
      </div>
    </Modal>
  )
}
