import { Invitation } from 'services/src/dto/invitation';

export const PRIMARY_PROVIDER_ID = '00000000-0000-0000-0000-000000000000';

export interface Account {
  id: string
  type: AccountType
  status: AccountStatus
  companyName: string
  tagLine?: string
  overview?: string
  companyType: string
  numberOfEmployees?: string
  logoUrl?: string
  primaryDiscipline?: string
  primaryDisciplineSkills?: string[]
  yearsOfExperience?: YearsOfExperience
  categoryExperiences?: ExpertCategoryExperience[]
  companyAddress?: Address
  clients?: Client[] | null
  offices?: Office[] | null
  invitations?: Invitation[] | null
  users?: ResourceUser[] | null
  owner?: string
  estimateApps?: EstimateApp[];
  providerAccountIds?: string[];
  providerFee?: {
    phaseFee?: number
  }
  clientAccountIds?: string[];
  createdAt?: string;
  updatedAt?: string;
  counts?: {
    [key: string]: number | { id: string; count: number }[]
    clients: number
    clientProjects: { id: string; count: number }[]
    offices: number
    officeProjects: { id: string; count: number }[]
    projects: number
    userTotal: number
  }
}

export type EstimateApp = {
  type: string
}

export type AccountUser = ResourceUser & {
  invitationId?: string
  invitationStatus?: string
  invitationStatusAt?: Date
  invitationCreatedAt?: Date
}

export enum AccountType {
  Provider = 'Provider',
  Client = 'Client',
  Expert = 'Expert'
}

export enum AccountStatus {
  New = 'New',
  Ready = 'Ready',
  Canceling = 'Canceling',
  Closed = 'Closed'
}

export enum ResourceUserRole {
  Owner = 'Owner',
  Administrator = 'Administrator',
  Contributor = 'Contributor',
  Guest = 'Guest'
}

export interface ResourceUser {
  id: string
  role: ResourceUserRole
  status: string
  givenName: string
  familyName: string
}
export enum UserStatus {
  Ready = 'Ready',
  Unavailable = 'Unavailable'
}
export interface User extends ResourceUser {
  status: UserStatus
  givenName: string
  familyName: string
  jobTitle?: string
  theme?: string
  emails?: EmailAddress[]
  pictureUrl?: string
  businessPhones?: PhoneNumber[]
  mobilePhone?: string

  providerId?: string
  createUserType?: string | null
  hourlyRate?: number
  primaryDiscipline?: string
  primaryDisciplineSkills?: string[]
  yearsOfExperience?: YearsOfExperience
  availability?: Availability
  expertRole?: ExpertRole
  expertType?: ExpertType
  categoryExperiences?: ExpertCategoryExperience[]
  educationLevel?: EducationLevel
  schoolName?: string
  gradYear?: number
  industrySpecificDegree?: boolean
  certifications?: ExpertCertification[]

  personalAddress?: Address
  distance?: number

  invitations?: Invitation[]
  createdAt?: Date
  welcomeAt?: Date
  onboardedAt?: Date
}

export interface Address {
  [key: string]: any
  label?: string
  type?: string
  buildingNumber?: string
  country?: string
  countryCode?: string
  countrySubdivision?: string
  countrySubdivisionName?: string
  countrySecondarySubdivision?: string
  countryTertiarySubdivision?: string
  crossStreet?: string
  extendedPostalCode?: string
  freeformAddress?: string
  localName?: string
  municipality?: string
  municipalitySubdivision?: string
  postalCode?: string
  street?: string
  streetName?: string
  streetNameAndNumber?: string
  streetNumber?: string
  lat?: number
  lon?: number
}

export interface EmailAddress {
  label?: string
  email: string
}
export interface PhoneNumber {
  label?: string
  number: string
  extension?: string
}

export interface Client {
  id: string
  name: string
  contactName?: string
  addresses: Address[]
  emailAddresses?: EmailAddress[] | null
  phoneNumbers?: PhoneNumber[] | null
  users?: ResourceUser[] | null
}

export interface Office {
  id: string
  name: string
  address?: Address
  phoneNumbers?: PhoneNumber[] | null
  users?: User[] | null
  counts?: {
    [key: string]: number
  }
}

export interface CompanyType {
  id: string
  label: string
}

export interface NumberOfEmployees {
  id: string
  label: string
}

export interface ResourceWithUsers {
  id: string
  users: User[]
}
export interface ProjectWithUsers extends ResourceWithUsers {
  createdBy?: User
  updatedBy?: User
  officeId?: string
  phases: PhaseWithUsers[]
}
export interface PhaseWithUsers extends ResourceWithUsers {
  estimates: ResourceWithUsers[]
}

export interface AccountWithUsers extends ResourceWithUsers {
  type: AccountType
  invitations: Invitation[]
  offices: ResourceWithUsers[]
  clients: ResourceWithUsers[]
  projects: ProjectWithUsers[]
}

export enum ExpertRole {
  CostEngineer = 'CostEngineer',
  CostEstimator = 'CostEstimator',
  QuantitySurveyor = 'QuantitySurveyor'
}

export enum ExpertType {
  FTE = 'FTE',
  Contractor = 'Contractor'
}

export enum YearsOfExperience {
  Lt2 = 'Lt2',
  In2to5 = 'In2to5',
  In5to10 = 'In5to10',
  In10to15 = 'In10to15',
  In15to20 = 'In15to20',
  In20to25 = 'In20to25',
  Gt25 = 'Gt25'
}

export enum Availability {
  FullTime = 'FullTime',
  PartTime = 'PartTime',
  Contractor = 'Contractor'
}

export enum EducationLevel {
  Associate = 'Associate',
  Bachelor = 'Bachelor',
  Graduate = 'Graduate',
  Doctoral = 'Doctoral'
}

export interface ExpertCategoryExperienceItem {
  projectTypeId: string
  type: string
}
export interface ExpertCategoryExperience {
  category: number
  items: ExpertCategoryExperienceItem[]
}

export interface ExpertCertification {
  name: string
  number?: string
  expires?: Date
}
