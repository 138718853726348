import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

export interface TabItem {
  label: JSX.Element | string
  to: string
  selected?: boolean
}

export type TabBarProps = React.HTMLAttributes<HTMLDivElement> & {
  tabs: TabItem[]
  onChange?: (tab: TabItem) => void
}

export const TabBar: React.FC<TabBarProps> = ({ tabs, onChange, className, ...rest }) => (
  <div className={`ui-tab-bar${className ? ` ${className}` : ''}`} {...rest}>
    {tabs.map((tab, idx) => {
      if (tab.label === '-') return <div key={idx} className="ui-tab-bar-sep" />

      return (
        <Link
          key={idx}
          to={tab.to}
          className={`ui-tab${tab.selected ? ' ui-tab-bar-selected' : ''}`}
          onClick={() => {
            if (onChange) onChange(tab)
          }}
        >
          {tab.label}
        </Link>
      )
    })}
  </div>
)
