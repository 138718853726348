import React from 'react'

import '../style.scss'
import { AdminHeader } from './AdminHeader'

export const AdminLayout: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  return (
    <div className="admin-layout">
      <AdminHeader />
      {children}
    </div>
  )
}
