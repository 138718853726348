import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import { User } from 'services/src/dto/account'
import { FeeGridViewModel, FeeGridViewModelEstimate } from './common'
import { FeeGridGeneratedFee } from './FeeGridGeneratedFee'

export const FeeGridTitle: React.FC<{
  vm: FeeGridViewModel
  estimate: FeeGridViewModelEstimate
  onToggle: () => void
}> = ({ vm, estimate, onToggle }) => {
  const { t } = useTranslation()

  const laborUsers = useMemo(() => {
    if (!estimate.labor.length) return []

    const users: User[] = []
    estimate.labor
      .filter((l) => !!l.user)
      .forEach((l) => {
        if (!users.find((u2) => u2.id === l.user!.id)) users.push(l.user!)
      })
    return users
  }, [estimate])

  return (
    <div className="ui-flex" style={{ flex: 1 }}>
      <div>
        <button
          type="button"
          className="ui-btn-empty ui-action-item ui-text-bold"
          tabIndex={-1}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onToggle()
          }}
        >
          <FontAwesomeIcon icon={estimate.isOpen ? faChevronUp : faChevronDown} style={{ marginRight: 10 }} />
          {t(`General.Disciplines.${estimate.discipline}`)}
        </button>

        {laborUsers.length > 0 && (
          <div className="edit-project-fee-grid-quick-labor">
            {laborUsers.map((user, idx) => (
              <div key={idx} title={`${user.givenName} ${user.familyName}`} style={user?.pictureUrl ? { border: 'none' } : undefined}>
                {user.pictureUrl ? <div style={{ backgroundImage: `url("${user.pictureUrl}")` }} /> : `${user.givenName[0].toUpperCase()}${user.familyName[0].toUpperCase()}`}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
