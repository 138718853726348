import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
// config
// ----------------------------------------------------------------------

const API_KEY = process.env.REACT_APP_BOE_API_KEY as string;
const BASE_URL = process.env.REACT_APP_BOE_BASE_URL as string;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "api-key": API_KEY
  },
});

axiosInstance.interceptors.request.use((config) => config);

axiosInstance.interceptors.response.use(
  (res: AxiosResponse) => res,
  (error: any) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig?]): Promise<any> => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  boe: {
    coverLetter: '/boe/cover-letter',
    purposeSection: '/boe/purpose-section',
    projectTaskDescription: '/boe/project-task-description',
    projectBackground: '/boe/project-background',
    projectMethodology: '/boe/project-methodology',
    classification: '/boe/classification',
    directCostDevelopment: '/boe/direct-cost-development',
    indirectCostDevelopment: '/boe/indirect-cost-development',
    biddingAssumptions: '/boe/bidding-assumptions',
    assumptionsNotes: '/boe/assumptions-notes',
    designBasis: '/boe/design-basis',
    planningBasis: '/boe/project-planning-basis',
    risksAndAssumptions: '/boe/risks-and-assumptions',
    projectPhysicalScope: '/boe/project-physical-scope',
    contractorOtherEstimateMarkups: '/boe/contractor-other-estimate-markups',
    laborMarkups: '/boe/labor-markups',
    equipment: '/boe/equipment',
    subcontractorMarkup: '/boe/subcontractor-markup',
    salesTax: '/boe/sales-tax',
    contractorStartup: '/boe/contractor-startup',
    buildersRiskInsurance: '/boe/builders-risk-insurance',
    materialShippingHandling: '/boe/material-shipping-handling',
    escalationMidpoint: '/boe/escalation-midpoint',
    managementReserve: '/boe/management-reserve',
    exclusions: '/boe/exclusions',
    exceptions: '/boe/exceptions',
    contingencies: '/boe/contingencies',
    reconciliation: '/boe/reconciliation',
    benchmarking: '/boe/benchmarking',
    estimateQualityAssurance: '/boe/estimate-quality-assurance',
    estimateReview: '/boe/estimate-review',
    limitationOfEstimate: '/boe/limitation-of-estimate',
    accuracyOfEstimate: '/boe/accuracy-of-estimate',
    generateBasisOfEstimate: '/boe/'
  }
};