import React from 'react'
import './style.scss'

export type StatusBadgeProps = React.HTMLAttributes<HTMLDivElement> & {
  prefixLabel?: JSX.Element | string
  label: JSX.Element | string
  type?: string
  variant?: string
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({ prefixLabel, label, type, variant, className, ...rest }) => {
  if (prefixLabel)
    return (
      <div className="ui-status-badge-prefix" {...rest}>
        <div>{prefixLabel}</div>
        <div className={`ui-status-badge ui-status-${variant || type || 'default'} ui-status-box${className ? ` ${className}` : ''}`}>{label}</div>
      </div>
    )

  return (
    <div className={`ui-status-badge ui-status-${variant || type || 'default'} ui-status-box${className ? ` ${className}` : ''}`} {...rest}>
      {label}
    </div>
  )
}
