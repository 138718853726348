import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentUserAccount, useCurrentUserOffice, useProjectCounts, useRights, UserType } from 'services/src/state'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'react-router-dom'
import { ProjectCounts } from 'services/src/dto/project'

export const ProjectListHeader: React.FC<{ url: string }> = ({ url }) => {
  const { t } = useTranslation()
  const [office] = useCurrentUserOffice()
  const [account] = useCurrentUserAccount()
  const rights = useRights()
  const projectCounts = useProjectCounts()

  const counts = useMemo<any>(() => {
    if (!account || !projectCounts) return undefined
    if (office?.id) {
      const c = projectCounts.find((x: any) => x.officeId === office.id)
      if (c) return c
      return {
        accountId: account.id,
        officeId: office.id,
        total: 0
      }
    }
    const counts: ProjectCounts = {
      accountId: account.id,
      providerId: account.id,
      total: 0,
      draft: 0,
      submitted: 0,
      accepted: 0,
      feeReady: 0,
      feeCounter: 0,
      approved: 0,
      inProgress: 0,
      complete: 0,
      rejected: 0,
      canceled: 0
    }

    projectCounts
      .filter((x: any) => x.accountId === account.id || x.providerId === account.id)
      .forEach((c) => {
        counts.accountId = c.accountId
        counts.providerId = c.providerId
        counts.total += c.total || 0
        counts.draft += c.draft || 0
        counts.submitted += c.submitted || 0
        counts.accepted += c.accepted || 0
        counts.feeReady += c.feeReady || 0
        counts.feeCounter += c.feeCounter || 0
        counts.approved += c.approved || 0
        counts.inProgress += c.inProgress || 0
        counts.complete += c.complete || 0
        counts.rejected += c.rejected || 0
        counts.canceled += c.canceled || 0
      })
    return counts
  }, [account, office, projectCounts])

  return (
    <div className="project-index-subheader">
      <div className="left">
        <div>
          <div>{t('Projects.Title')}</div>
          <div>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
          {!office ? <div className="ui-text-muted">{t('General.AllLocations')}</div> : <div>{office.name}</div>}
        </div>
        <div className="ui-text-xs ui-text-muted ui-flex ui-flex-nowrap">
          {!counts ? (
            <div>...</div>
          ) : (
            (() => {
              const items = Object.keys(counts)
                .filter((k) => (k === 'total' || !['accountId', 'providerId', 'officeId'].includes(k)) && !!counts[k])
                .map((k, idx) => {
                  const kx = k === 'total' ? 'Project' : `${k[0].toUpperCase()}${k.substring(1)}`
                  return (
                    <React.Fragment key={idx}>
                      {k !== 'total' && <div>|</div>}
                      <div className="ui-text-ellipsis">
                        {t(`Projects.${rights?.userType === UserType.client ? 'ClientCounts.' : ''}${kx}Count`, {
                          count: counts[k]
                        })}
                      </div>
                    </React.Fragment>
                  )
                })
              return items.length ? items : <div>&nbsp;</div>
            })()
          )}
        </div>
      </div>
      <div className="right">
        {rights?.canCreateProject && (
          <Link to={`${url}/new`} className="ui-btn ui-btn-secondary ui-btn-solid">
            <FontAwesomeIcon icon={faPlusCircle} />
            <span style={{ marginLeft: 5 }}>{t('General.CreateProject')}</span>
          </Link>
        )}
      </div>
    </div>
  )
}
