import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { parsePhoneNumber } from 'libphonenumber-js'

import { MAX_PHOTO_SIZE, numberOfEmployeesItems, PHOTO_TYPES } from 'services/src/common'

import { DropzoneField } from 'components/src/dropzoneField'
import { TextField } from 'components/src/textField'
import { SelectField } from 'components/src/selectField'
import { useSaving } from 'components/src/saving'
import { Footer } from '../Footer'
import { AccountProfileViewModel } from './model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/pro-solid-svg-icons'
import { AccountType } from 'services/src/dto/account'
import { TextAreaField } from '../../../textAreaField'
import { makeDashboardPath } from 'services/src/dom'

export const AccountGeneral: React.FC<{
  vm: AccountProfileViewModel
  onChange: (vm: AccountProfileViewModel) => void
  onSubmit: (view: string, vm: AccountProfileViewModel) => void
}> = ({ vm, onChange, onSubmit }) => {
  const { t } = useTranslation()
  const [saving] = useSaving()
  const [dirty, setDirty] = useState(false)

  const onDropPicture = useCallback(
    (acceptedFiles: any[]) => {
      if (!acceptedFiles.length) return

      const errors = { ...vm.errors }
      delete errors.picture
      onChange({
        ...vm,
        picture: acceptedFiles[0],
        preview: URL.createObjectURL(acceptedFiles[0]),
        errors
      })
      setDirty(true)
    },
    [vm, onChange, setDirty]
  )

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      const vmx: AccountProfileViewModel = { ...vm, errors: {} }

      if (!vmx.companyName || !vmx.companyName.trim()) vmx.errors.companyName = t('General.Errors.Required')
      if (!vmx.selectedCompanyType) vmx.errors.companyType = t('General.Errors.Required')
      if (!vmx.selectedNumberOfEmployees) vmx.errors.numberOfEmployees = t('General.Errors.Required')

      if (Object.keys(vmx.errors).length) {
        onChange(vmx)
        return
      }

      onSubmit('general', vmx)
      setDirty(false)
    },
    [vm, onChange, onSubmit, setDirty]
  )

  return (
    <form noValidate onSubmit={submit}>
      <div className="settings-account-layout">
        <div className="account-picture">
          <DropzoneField
            onDrop={onDropPicture}
            variant="round"
            disabled={saving}
            accept={PHOTO_TYPES}
            maxFiles={1}
            maxSize={MAX_PHOTO_SIZE}
            rejectLabel={t('Settings.Errors.InvalidPicture')}
            error={vm.errors.logo}
          >
            {(() => {
              if (vm.preview || vm.logoUrl) return <div className="bg-preview" style={{ backgroundImage: `url('${vm.preview || vm.logoUrl}')` }} />
              return (
                <div className="empty">
                  <FontAwesomeIcon icon={faCamera} />
                </div>
              )
            })()}
          </DropzoneField>
        </div>

        <TextField
          name="companyName"
          disabled={saving}
          label={t('Settings.CompanyName')}
          error={vm.errors.companyName}
          value={vm.companyName}
          onChange={(companyName) => {
            const errors = { ...vm.errors }
            delete errors.companyName
            onChange({ ...vm, companyName, errors })
            setDirty(true)
          }}
        />

        <div className="ui-row">
          <div className="ui-col-12 ui-col-lg-6">
            <SelectField
              items={vm.companyTypes}
              disabled={saving}
              selected={vm.selectedCompanyType}
              onSelectedChange={(selectedCompanyType) => {
                const errors = { ...vm.errors }
                delete errors.companyType
                onChange({ ...vm, selectedCompanyType, errors })
                setDirty(true)
              }}
              placeholder={t('Settings.CompanyTypePlaceholder')}
              label={t('Settings.CompanyType')}
              error={vm.errors.companyType}
              labelField="label"
              valueField="id"
              maxDropDown={12}
            />
          </div>

          <div className="ui-col-12 ui-col-lg-6">
            <SelectField
              items={numberOfEmployeesItems}
              disabled={saving}
              selected={vm.selectedNumberOfEmployees}
              onSelectedChange={(selectedNumberOfEmployees) => {
                const errors = { ...vm.errors }
                delete errors.numberOfEmployees
                onChange({ ...vm, selectedNumberOfEmployees, errors })
                setDirty(true)
              }}
              placeholder={t('Settings.EmployeesPlaceholder')}
              label={t('Settings.Employees')}
              error={vm.errors.numberOfEmployees}
              labelField="label"
              valueField="id"
              maxDropDown={5}
            />
          </div>
        </div>

        {vm.type === AccountType.Provider && (
          <TextField
            name="tagLine"
            disabled={saving}
            label={t('Settings.TagLine')}
            placeholder={t('Settings.TagLineSample')}
            error={vm.errors.tagLine}
            value={vm.tagLine}
            onChange={(tagLine) => {
              const errors = { ...vm.errors }
              delete errors.tagLine
              onChange({ ...vm, tagLine, errors })
              setDirty(true)
            }}
          />
        )}

        {vm.type === AccountType.Provider && (
          <TextAreaField
            name="overview"
            disabled={saving}
            label={t('Settings.Overview')}
            placeholder={t('Settings.OverviewSample')}
            error={vm.errors.overview}
            value={vm.overview || ''}
            rows={5}
            onChange={(overview) => {
              const errors = { ...vm.errors }
              delete errors.overview
              onChange({ ...vm, overview, errors })
              setDirty(true)
            }}
          />
        )}

        <hr/>
        <h4 className="ui-text-bold">{t('General.Offices')}</h4>
        <div>
          {vm.offices?.map(o => {
            let p: string | null = null
            if (o.phoneNumbers?.length) {
              p = parsePhoneNumber(o.phoneNumbers[0].number.startsWith('+') ? o.phoneNumbers[0].number : `+${o.phoneNumbers[0].number}`).formatNational()
              if (o.phoneNumbers[0].extension) p = `${p} x${o.phoneNumbers[0].extension}`
            }

            let addressLines: string[] = [];
            if (o.address) {
              let line1 = ''
              if (o.address.streetNameAndNumber) line1 = o.address.streetNameAndNumber
              else if (o.address.streetNumber && o.address.streetName) line1 = `${o.address.streetNumber} ${o.address.streetName}`
              else if (o.address.streetName) line1 = o.address.streetName

              let line2 = ''
              if (o.address.municipality && (o.address.countrySubdivisionName || o.address.countrySubdivision) && o.address.postalCode)
                line2 = `${o.address.municipality}, ${o.address.countrySubdivisionName || o.address.countrySubdivision} ${o.address.postalCode}`
              else if (o.address.municipality && (o.address.countrySubdivisionName || o.address.countrySubdivision))
                line2 = `${o.address.municipality}, ${o.address.countrySubdivisionName || o.address.countrySubdivision}`
              else if (o.address.municipality && o.address.postalCode) line2 = `${o.address.municipality} ${o.address.postalCode}`
              else if ((o.address.countrySubdivisionName || o.address.countrySubdivision) && o.address.postalCode)
                line2 = `${o.address.countrySubdivisionName || o.address.countrySubdivision} ${o.address.postalCode}`

              const line3 = o.address.country || o.address.countryCode

              if (line1 && line2 && line3) addressLines = [line1, line2, line3]
              else if (line1 && line2) addressLines = [line1, line2]
              else if (line1) addressLines = [line1]
              else addressLines = [o.address.freeformAddress!]
            }

            return (
              <div key={o.id} className="ui-flex" style={{ marginTop: 15 }}>
                <div style={{ flex: 1 }}>
                  <Link to={makeDashboardPath(vm.id, `offices/${o.id}`)}>{o.name}</Link>
                </div>
                <div className="ui-text-sm">
                  {addressLines.map((l, idx) => (
                    <div key={idx}>{l}</div>
                  ))}
                  {p && <div>{p}</div>}
                </div>
              </div>
            )
          })}
        </div>

        <Footer>
          <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center' }}>
            {/* TODO DELETE ACCOUNT!
                canDelete && (
                  <button type="button"
                          style={{width: 150}}
                          className="ui-btn ui-btn-danger ui-btn-solid">
                    {t('General.DeleteAccount')}
                  </button>
                ) */}

            <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center', marginLeft: 'auto' }}>
              <button type="submit" disabled={saving} style={{ width: 120 }} className="ui-btn ui-btn-secondary ui-btn-solid">
                {t('General.Save')}
              </button>
            </div>
          </div>
        </Footer>
      </div>
    </form>
  )
}
