import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { faLightbulbOn } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostRequests } from 'services/src/api/boe';
import { endpoints } from 'services/src/lib/axios';
import { useEditProject } from 'services/src/state';
import { Loading } from '../../../loading';
import ProjectBoeProgressBar from './ProjectBoeProgressBar';

interface ProjectBoeScopeDefinitionSectionProps {
  handleNext: () => void;
  handlePrevious: () => void;
  setBoeData: (data: any) => void;
  boeData: any;
  isProgressBarVisible: boolean;
  setIsProgressBarVisible: (value: boolean) => void;
}

interface Errors {
  [key: string]: string | undefined;
  projectPhysicalScope?: string;
}

const BoeProject113: React.FC<ProjectBoeScopeDefinitionSectionProps> = ({
  handleNext,
  handlePrevious,
  setBoeData,
  boeData,
  isProgressBarVisible,
  setIsProgressBarVisible,
}) => {
  const { t } = useTranslation();
  const progress = useMemo(() => 42, []);
  const { data, postRequests, loading, error } = usePostRequests();
  const [errors, setErrors] = useState<Errors>({});
  const { project } = useEditProject();
  const [projectPhysicalScope, setAccuracyOfEstimate] = useState<{
    introduction: string;
    exclustions: string[];
    conclusion: string;
  }>(boeData?.projectPhysicalScope || {
    introduction: '',
    exclustions: [],
    conclusion: ''
  });
  const [exclusionsOptions, setExclusionsOptions] = useState<string[]>(boeData?.exclusionsOptions || []);
  const [selectedExclusions, setSelectedExclusions] = useState<string[]>(boeData?.projectPhysicalScope?.exclustions || []);
  const [newExclusion, setNewExclusion] = useState<string>('');
  const [showNewExclusionInput, setShowNewExclusionInput] = useState<boolean>(false);
  const [isNotApplicable, setIsNotApplicable] = useState<boolean>(boeData?.projectPhysicalScope?.exclustions?.length === 0 || false);

  const handleSelectExclusion = (exclusion: string) => {
    setSelectedExclusions((prev) => {
      if (prev.includes(exclusion)) {
        return prev.filter((item) => item !== exclusion);
      }
      return [...prev, exclusion];
    });
  };

  const handleAddExclusion = () => {
    if (newExclusion && !exclusionsOptions.includes(newExclusion)) {
      setExclusionsOptions([...exclusionsOptions, newExclusion]);
      setNewExclusion('');
      setShowNewExclusionInput(false);
    }
  };

  const handleNextClick = () => {
    if (!isNotApplicable && selectedExclusions.length === 0) {
      setErrors({ projectPhysicalScope: 'Please select at least one exclusion or mark as Not Applicable.' });
      return;
    }
    setBoeData((prev: any) => ({
      ...prev,
      projectPhysicalScope: {
        ...projectPhysicalScope,
        exclustions: isNotApplicable ? [] : selectedExclusions
      },
      exclusionsOptions
    }));
    handleNext();
  };

  useEffect(() => {
    if (projectPhysicalScope.introduction === '') {
      postRequests(endpoints.boe.projectPhysicalScope, {Provider: project?.provider?.companyName});
    }
  }, [projectPhysicalScope, postRequests, project?.provider?.companyName  ]);

  useEffect(() => {
    if (data?.response) {
      setAccuracyOfEstimate({
        introduction: data.response.introduction,
        exclustions: data.response.content,
        conclusion: data.response.conclusion
      });
      setExclusionsOptions(data.response.content);
    }
  }, [data?.response]);  

  return (
    <div className="boe-content">
      <div className="boe-form-content">
        <ProjectBoeProgressBar
          progress={progress}
          title={t('BasisOfEstimate.BoeProject113.ProgressTitle')}
          leftText={t('BasisOfEstimate.BoeProject113.ProgressLeftText')}
          rightText={t('BasisOfEstimate.BoeProject113.ProgressRightText')}
          isProgressBarVisible={isProgressBarVisible}
          setIsProgressBarVisible={setIsProgressBarVisible}
          boeData={boeData}
        />
        
        <div className="boe-content-flex-between">
          <h2 className="boe-section-header">{t('BasisOfEstimate.BoeProject113.Title')}</h2>
          <div className="boe-section-header-right-content">
            <FontAwesomeIcon icon={faLightbulbOn} />
            <p>{t('BasisOfEstimate.BoeProject113.Dynamic')}</p>
          </div>
        </div>

        {loading && <Loading />}

        {error && (
          <div className="boe-error-container">
            <p>{t('General.Error')}</p>
          </div>
        )}

        <div className='boe-selectable-content'>
          <div className='boe-flex-row'>
            <input
              type="checkbox"
              className='boe-assumption-checkbox'
              checked={isNotApplicable}
              onChange={() => setIsNotApplicable(!isNotApplicable)}
            />
            <p>Not Applicable</p>
          </div>
          {!isNotApplicable && (
            <>
              <h3 className='boe-project-physical-title'>{projectPhysicalScope.introduction}</h3>
              <ul>
                {exclusionsOptions.map((exclusion, index) => (
                  <li key={index}>
                    <input
                      type="checkbox"
                      className='boe-assumption-checkbox'
                      checked={selectedExclusions.includes(exclusion)}
                      onChange={() => handleSelectExclusion(exclusion)}
                    />
                    {exclusion}
                  </li>
                ))}

              </ul>
              {showNewExclusionInput ? (
                <div>
                  <input
                    type="text"
                    value={newExclusion}
                    onChange={(e) => setNewExclusion(e.target.value)}
                    placeholder="Add new exclusion"
                  />
                  <button type="button" className='boe-form-assumption-button' onClick={handleAddExclusion}>{t('General.Save')}</button>
                </div>
              ) : (
                <button type="button" className='boe-form-assumption-button-new' onClick={() => setShowNewExclusionInput(true)}>
                  <FontAwesomeIcon icon={faPlusCircle} />
                  Add Custom
                </button>
              )}
              <h3 className='boe-project-physical-title'>{projectPhysicalScope.conclusion}</h3>
            </>
          )}
        </div>

        {errors.projectPhysicalScope && (
          <div className="boe-error-container">
            <p className='boe-error-message'>{errors.projectPhysicalScope}</p>
          </div>
        )}
      </div>
      <div className="boe-form-action-container">
        <button type="button" className="btn-next" onClick={handlePrevious}>
          {t('General.Previous')}
        </button>
        <button type="button" className="btn-previous" onClick={handleNextClick}>
          {t('General.Next')}
        </button>
      </div>
    </div>
  );
};

export default BoeProject113;