import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MAX_PHOTO_SIZE, numberOfEmployeesItems, PHOTO_TYPES } from 'services/src/common'

import { DropzoneField } from 'components/src/dropzoneField'
import { TextField } from 'components/src/textField'
import { SelectField } from 'components/src/selectField'
import { useSaving } from 'components/src/saving'
import { Footer } from '../Footer'
import { ProviderAccountProfileViewModel } from './model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/pro-solid-svg-icons'
import { AccountType } from 'services/src/dto/account'
import { TextAreaField } from '../../../textAreaField'

export const ProviderAccountGeneral: React.FC<{
  vm: ProviderAccountProfileViewModel
  onChange: (vm: ProviderAccountProfileViewModel) => void
  onSubmit: (view: string, vm: ProviderAccountProfileViewModel) => void
}> = ({ vm, onChange, onSubmit }) => {
  const { t } = useTranslation()
  const [saving] = useSaving()
  const [dirty, setDirty] = useState(false)

  const onDropPicture = useCallback(
    (acceptedFiles: any[]) => {
      if (!acceptedFiles.length) return

      const errors = { ...vm.errors }
      delete errors.picture
      onChange({
        ...vm,
        picture: acceptedFiles[0],
        preview: URL.createObjectURL(acceptedFiles[0]),
        errors
      })
      setDirty(true)
    },
    [vm, onChange, setDirty]
  )

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      const vmx: ProviderAccountProfileViewModel = { ...vm, errors: {} }

      if (!vmx.companyName || !vmx.companyName.trim()) vmx.errors.companyName = t('General.Errors.Required')
      if (!vmx.selectedCompanyType) vmx.errors.companyType = t('General.Errors.Required')
      if (!vmx.selectedNumberOfEmployees) vmx.errors.numberOfEmployees = t('General.Errors.Required')

      if (Object.keys(vmx.errors).length) {
        onChange(vmx)
        return
      }

      onSubmit('general', vmx)
      setDirty(false)
    },
    [vm, onChange, onSubmit, setDirty]
  )

  return (
    <form noValidate onSubmit={submit}>
      <div className="settings-account-layout">
        <div className="account-picture">
          <DropzoneField
            onDrop={onDropPicture}
            variant="round"
            disabled={saving}
            accept={PHOTO_TYPES}
            maxFiles={1}
            maxSize={MAX_PHOTO_SIZE}
            rejectLabel={t('Settings.Errors.InvalidPicture')}
            error={vm.errors.logo}
          >
            {(() => {
              if (vm.preview || vm.logoUrl) return <div className="bg-preview" style={{ backgroundImage: `url('${vm.preview || vm.logoUrl}')` }} />
              return (
                <div className="empty">
                  <FontAwesomeIcon icon={faCamera} />
                </div>
              )
            })()}
          </DropzoneField>
        </div>

        <TextField
          name="companyName"
          disabled={saving}
          label={t('Settings.CompanyName')}
          error={vm.errors.companyName}
          value={vm.companyName}
          onChange={(companyName) => {
            const errors = { ...vm.errors }
            delete errors.companyName
            onChange({ ...vm, companyName, errors })
            setDirty(true)
          }}
        />

        <div className="ui-row">
          <div className="ui-col-12 ui-col-lg-6">
            <SelectField
              items={vm.companyTypes}
              disabled={saving}
              selected={vm.selectedCompanyType}
              onSelectedChange={(selectedCompanyType) => {
                const errors = { ...vm.errors }
                delete errors.companyType
                onChange({ ...vm, selectedCompanyType, errors })
                setDirty(true)
              }}
              placeholder={t('Settings.CompanyTypePlaceholder')}
              label={t('Settings.CompanyType')}
              error={vm.errors.companyType}
              labelField="label"
              valueField="id"
              maxDropDown={12}
            />
          </div>

          <div className="ui-col-12 ui-col-lg-6">
            <SelectField
              items={numberOfEmployeesItems}
              disabled={saving}
              selected={vm.selectedNumberOfEmployees}
              onSelectedChange={(selectedNumberOfEmployees) => {
                const errors = { ...vm.errors }
                delete errors.numberOfEmployees
                onChange({ ...vm, selectedNumberOfEmployees, errors })
                setDirty(true)
              }}
              placeholder={t('Settings.EmployeesPlaceholder')}
              label={t('Settings.Employees')}
              error={vm.errors.numberOfEmployees}
              labelField="label"
              valueField="id"
              maxDropDown={5}
            />
          </div>
        </div>

        {vm.type === AccountType.Provider && (
          <TextField
            name="tagLine"
            disabled={saving}
            label={t('Settings.TagLine')}
            placeholder={t('Settings.TagLineSample')}
            error={vm.errors.tagLine}
            value={vm.tagLine}
            onChange={(tagLine) => {
              const errors = { ...vm.errors }
              delete errors.tagLine
              onChange({ ...vm, tagLine, errors })
              setDirty(true)
            }}
          />
        )}

        {vm.type === AccountType.Provider && (
          <TextAreaField
            name="overview"
            disabled={saving}
            label={t('Settings.Overview')}
            placeholder={t('Settings.OverviewSample')}
            error={vm.errors.overview}
            value={vm.overview || ''}
            rows={5}
            onChange={(overview) => {
              const errors = { ...vm.errors }
              delete errors.overview
              onChange({ ...vm, overview, errors })
              setDirty(true)
            }}
          />
        )}

        <Footer>
          <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center' }}>
            {/* TODO DELETE ACCOUNT!
                canDelete && (
                  <button type="button"
                          style={{width: 150}}
                          className="ui-btn ui-btn-danger ui-btn-solid">
                    {t('General.DeleteAccount')}
                  </button>
                ) */}

            <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center', marginLeft: 'auto' }}>
              <button type="submit" disabled={saving} style={{ width: 120 }} className="ui-btn ui-btn-secondary ui-btn-solid">
                {t('General.Save')}
              </button>
            </div>
          </div>
        </Footer>
      </div>
    </form>
  )
}
