import React, { useMemo } from 'react'
import { TabBar } from '../../../tabs'
import { useLocation, useRouteMatch } from 'react-router-dom'

const ProjectBoeTabSwitcher = () => {
    const { pathname } = useLocation()
    const { url, path } = useRouteMatch()
    const tabs = useMemo(
        () => [
            {
                label: (
                    <div className="ui-flex" style={{ alignItems: 'center' }}>
                        {"Edit Sub Section"}
                    </div>
                ),
                selected: pathname.includes('/drawings'),
                to: `${url}/drawings`
            },
            {
                label: (
                    <div className="ui-flex" style={{ alignItems: 'center' }}>
                      {"Preview Sub Section"}
                    </div>
                ),
                selected: pathname.includes('/photos'),
                to: `${url}/photos`
            },
        ],
        [pathname, url]
    )
    return (
        <div>
            <TabBar tabs={tabs} style={{ justifyContent: 'center', height: 30 }} onChange={() => {}} />
        </div>
    )
}

export default ProjectBoeTabSwitcher