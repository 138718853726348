import React, { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { PhaseEx, ProjectEx, useEditProject } from 'services/src/state'
import { TextField } from 'components/src/textField'
import { TextAreaField } from 'components/src/textAreaField'
import moment, { Moment } from 'moment'
import { DatePicker } from 'components/src/datePicker'
import { PROJECT_PHASE_MIN_DAYS } from 'components/src/constants'
import { useProjectSupport } from 'services/src/api'
import { Patch } from 'services/src/dto/common'
import { PhaseTypeKey } from 'services/src/dto/projectShare'
import { PhaseVariations } from 'services/src/dto/project'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import { useSaving } from 'components/src/saving'
import { adjustTimelineItems, TimelineItem, updateTimelineItemsFromProject, updateTimelineMarkersFromProject } from '../common/Timeline'
import { Footer } from './Footer'

interface DetailsViewModel {
  dirty?: boolean
  phase: PhaseEx
  start: Moment
  end: Moment
  name: string
  description: string
  datePatch?: Patch[]
  variations?: PhaseVariations[]
}

interface PickerItem {
  which: string
  timelineItem: TimelineItem
  left: number
  top: number
}

export const PhaseDetails: React.FC<{ phaseId: string }> = ({ phaseId }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [{ phaseTypes }] = useProjectSupport()
  const { project, patch, setProject } = useEditProject()
  const [saving] = useSaving()

  const timelineItems = useMemo(() => updateTimelineItemsFromProject(project, phaseTypes, t), [project, phaseTypes, t])
  const markers = useMemo(() => updateTimelineMarkersFromProject(project, phaseTypes, t), [project, phaseTypes, t])

  const [errors, setErrors] = useState<any>({})
  const clearError = (name: string) => {
    const theErrors = { ...errors }
    delete theErrors[name]
    setErrors(theErrors)
  }

  const [vm, setVm] = useState<DetailsViewModel>({
    phase: {} as any,
    start: moment(),
    end: moment(),
    name: '',
    description: ''
  })

  useEffect(() => {
    const phase = project.phases.find((x) => x.id === phaseId)
    if (!phase || !phase.canEdit) {
      history.goBack()
      return
    }

    const vm: DetailsViewModel = {
      phase,
      start: moment(phase.submissionDate),
      end: moment(phase.deliveryDate),
      name: phase.name || '',
      description: phase.description || '',
      variations: phase.phaseType === PhaseTypeKey.Concept ? [...(phase.variations || [])] : undefined
    }

    setVm(vm)
  }, [setVm, phaseId, project, t])

  const submit = useCallback(
    (e?: FormEvent) => {
      if (e) e.preventDefault()

      const errors: any = []

      if (vm.name) {
        if (vm.name.length < 3 || vm.name.length > 255) errors.name = t('General.Errors.BetweenNAndMCharacters', { N: 3, M: 255 })
      }

      setErrors(errors)
      if (Object.keys(errors).length > 0) return

      const p: ProjectEx = JSON.parse(JSON.stringify(project))
      const ph = p.phases.find((x) => x.id === vm.phase.id)
      if (!ph) return

      ph.name = vm.name || undefined
      ph.description = vm.description || undefined
      ph.variations = vm.variations

      setProject(p)

      const patchItems: Patch[] = [
        {
          propertyName: `Phases.${phaseId}.Name`,
          propertyValue: vm.name || null
        },
        {
          propertyName: `Phases.${phaseId}.Description`,
          propertyValue: vm.description || null
        },
        {
          propertyName: `Phases.${phaseId}.Variations`,
          propertyValue: vm.variations || null
        },
        ...(vm.datePatch || [])
      ]

      patch(patchItems, true).then(() => {})
    },
    [vm, setErrors]
  )

  const datePickerRef = useRef<HTMLDivElement>(null)
  const [datePicker, setDatePicker] = useState<PickerItem>()

  const selectDate = useCallback(
    (d: Moment) => {
      setVm((vm) => {
        if (!datePicker) return vm

        const vmx: DetailsViewModel = JSON.parse(JSON.stringify(vm))

        if (datePicker.which === 'start') {
          const diff = moment.duration(vmx.phase.submissionDate && vmx.phase.deliveryDate ? moment(vmx.phase.deliveryDate).valueOf() - moment(vmx.phase.submissionDate).valueOf() : 0)
          vmx.phase.submissionDate = d.toDate()
          vmx.phase.deliveryDate = moment(d)
            .add(diff.asDays() || PROJECT_PHASE_MIN_DAYS, 'days')
            .toDate()
        } else {
          vmx.phase.deliveryDate = moment(d).toDate()
        }
        vmx.start = moment(vmx.phase.submissionDate)
        vmx.end = moment(vmx.phase.deliveryDate)

        let newTimelineItems = [...timelineItems]
        newTimelineItems.forEach((x) => {
          if (!vmx.phase) return

          const c = x.children?.find((c) => c.phase?.id === vmx.phase?.id)
          if (c) {
            c.phase = { ...vmx.phase }
            c.start = moment(vmx.phase.submissionDate).format()
            c.end = moment(vmx.phase.deliveryDate).format()
          } else if (x.phase?.id === vmx.phase?.id) {
            x.phase = { ...vmx.phase }
            x.start = moment(vmx.phase.submissionDate).format()
            x.end = moment(vmx.phase.deliveryDate).format()
          }
        })

        const patchItems: Patch[] = []

        newTimelineItems = adjustTimelineItems(newTimelineItems)
        newTimelineItems.forEach((x) => {
          if (x.children?.length) {
            x.children?.forEach((c) => {
              if (!c.phase || c.phase.id?.startsWith('-') === true) return

              patchItems.push({
                propertyName: `Phases.${c.phase.id}.SubmissionDate`,
                propertyValue: moment(c.start).toDate()
              })
              patchItems.push({
                propertyName: `Phases.${c.phase.id}.DeliveryDate`,
                propertyValue: moment(c.end).toDate()
              })
            })
            return
          }

          if (!x.phase || x.phase.id?.startsWith('-') === true) return

          patchItems.push({
            propertyName: `Phases.${x.phase.id}.SubmissionDate`,
            propertyValue: moment(x.start).toDate()
          })
          patchItems.push({
            propertyName: `Phases.${x.phase.id}.DeliveryDate`,
            propertyValue: moment(x.end).toDate()
          })
        })

        vmx.datePatch = patchItems
        vmx.dirty = true
        return vmx
      })

      setTimeout(() => setDatePicker(undefined))
    },
    [vm, datePicker, setDatePicker, timelineItems]
  )

  const changeDate = useCallback(
    (elm: HTMLDivElement, which: string) => {
      const timelineItem = timelineItems.find((x) => x.phaseType === vm.phase.phaseType)
      if (!timelineItem) return

      const r = elm.getBoundingClientRect()
      setDatePicker({
        which,
        timelineItem,
        left: r.left,
        top: r.top + 36
      })

      setTimeout(() => datePickerRef.current?.focus())
    },
    [vm, timelineItems, setDatePicker]
  )

  return (
    <>
      <form noValidate onSubmit={submit}>
        <div className="edit-project-details">
          <div className="ui-frame ui-frame-bg" style={{ paddingBottom: 0 }}>
            <div className="ui-row">
              <div className="ui-col-12 ui-col-md-6 ui-form-group">
                <label>{t('General.SubmissionDate')}</label>
                <div
                  className={`ui-frame ui-frame-bg ui-action-item${datePicker?.which === 'start' ? ' ui-frame-focus' : ''}${saving ? ' ui-disabled' : ''}`}
                  style={{ padding: '0 10px', height: 36, lineHeight: '34px' }}
                  tabIndex={0}
                  role="button"
                  onFocus={(e) => {
                    if (saving) {
                      e.currentTarget.blur()
                      return
                    }
                    changeDate(e.currentTarget, 'start')
                  }}
                >
                  {vm.start.format('LL')}
                </div>
              </div>
              <div className="ui-col-12 ui-col-md-6 ui-form-group">
                <label>{t('General.DeliveryDate')}</label>
                <div
                  className={`ui-frame ui-frame-bg ui-action-item${datePicker?.which === 'end' ? ' ui-frame-focus' : ''}${saving ? ' ui-disabled' : ''}`}
                  style={{ padding: '0 10px', height: 36, lineHeight: '34px' }}
                  tabIndex={0}
                  role="button"
                  onFocus={(e) => {
                    if (saving) {
                      e.currentTarget.blur()
                      return
                    }
                    changeDate(e.currentTarget, 'end')
                  }}
                >
                  {vm.end.format('LL')}
                </div>
              </div>
            </div>
          </div>

          <br />

          {vm.variations && (
            <>
              <div className="ui-frame ui-frame-bg">
                {vm.variations.length <= 0 ? (
                  <button
                    type="button"
                    className="ui-btn ui-btn-info ui-btn-solid ui-btn-solid ui-btn-sm"
                    disabled={saving}
                    onClick={() => {
                      setVm((vm) => {
                        const vmx: DetailsViewModel = JSON.parse(JSON.stringify(vm))
                        if (!vmx.variations) return vm

                        vmx.start = moment(vm.start)
                        vmx.end = moment(vm.end)
                        vmx.variations.push({ description: '' })
                        vmx.dirty = true
                        return vmx
                      })
                    }}
                  >
                    <FontAwesomeIcon icon={faPlusCircle} />
                    <span style={{ marginLeft: 5 }}>{t('NewProject.Timeline.AddPhaseVariation')}</span>
                  </button>
                ) : (
                  <>
                    {vm.variations.map((v, idx) => (
                      <div key={idx} style={{ marginTop: idx > 0 ? 20 : 0 }}>
                        <label htmlFor={`var-${idx}`}>
                          <span>
                            {t('NewProject.Timeline.DescribePhaseVariation')} <span className="ui-text-muted">{t('General.Optional')}</span>
                          </span>
                        </label>
                        <textarea
                          name={`var-${idx}`}
                          disabled={!project.canEdit || saving}
                          className="ui-text-sm"
                          style={{ margin: 0 }}
                          value={v.description}
                          placeholder="Variation details..."
                          rows={2}
                          onChange={(e) => {
                            const v = e.currentTarget.value
                            setVm((vm) => {
                              const vmx: DetailsViewModel = JSON.parse(JSON.stringify(vm))
                              if (!vmx.variations) return vm

                              vmx.start = moment(vm.start)
                              vmx.end = moment(vm.end)
                              vmx.variations[idx].description = v
                              vmx.dirty = true
                              return vmx
                            })
                          }}
                        />

                        {!project.canEdit && <div style={{ height: 20 }} />}

                        {project.canEdit && (
                          <div style={{ marginTop: 5 }}>
                            <button
                              type="button"
                              className="ui-btn ui-btn-danger ui-btn-solid ui-btn-solid ui-btn-sm"
                              disabled={!project.canEdit || saving}
                              onClick={() => {
                                setVm((vm) => {
                                  const vmx: DetailsViewModel = JSON.parse(JSON.stringify(vm))
                                  if (!vmx.variations) return vm

                                  vmx.start = moment(vm.start)
                                  vmx.end = moment(vm.end)
                                  vmx.variations.splice(idx, 1)
                                  vmx.dirty = true
                                  return vmx
                                })
                              }}
                            >
                              <FontAwesomeIcon icon={faTimesCircle} />
                              <span style={{ marginLeft: 5 }}>{t('NewProject.Timeline.RemovePhaseVariation')}</span>
                            </button>
                          </div>
                        )}

                        {project.canEdit && vm.variations && vm.variations.length < 3 && idx === vm.variations.length - 1 && (
                          <div style={{ marginTop: 20 }}>
                            <button
                              type="button"
                              className="ui-btn ui-btn-info ui-btn-solid ui-btn-solid ui-btn-sm"
                              disabled={!project.canEdit || saving}
                              onClick={() => {
                                setVm((vm) => {
                                  const vmx: DetailsViewModel = JSON.parse(JSON.stringify(vm))
                                  if (!vmx.variations) return vm

                                  vmx.start = moment(vm.start)
                                  vmx.end = moment(vm.end)
                                  vmx.variations = [...vmx.variations, { description: '' }]
                                  vmx.dirty = true
                                  return vmx
                                })
                              }}
                            >
                              <FontAwesomeIcon icon={faPlusCircle} />
                              <span style={{ marginLeft: 5 }}>{t('NewProject.Timeline.AddAnotherPhaseVariation')}</span>
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </div>

              <br />
            </>
          )}

          <div className="ui-frame ui-frame-bg" style={{ paddingBottom: 0 }}>
            <TextField
              name="name"
              disabled={saving}
              value={vm.name}
              label={
                <>
                  <span>{t('EditProject.PhaseDetails.Name')}</span>
                  <span className="ui-text-muted" style={{ marginLeft: 5 }}>
                    {t('General.Optional')}
                  </span>
                </>
              }
              placeholder={t('EditProject.PhaseDetails.NameSample')}
              error={errors.orderNumber}
              onChange={(name) => {
                clearError('name')
                setVm((vm) => ({ ...vm, name, dirty: true }))
              }}
            />

            <TextAreaField
              name="description"
              className="ui-text-sm"
              disabled={saving}
              value={vm.description}
              label={
                <>
                  <span>{t('EditProject.PhaseDetails.Description')}</span>
                  <span className="ui-text-muted" style={{ marginLeft: 5 }}>
                    {t('General.Optional')}
                  </span>
                </>
              }
              error={errors.description}
              placeholder={t('EditProject.PhaseDetails.DescriptionSample')}
              rows={8}
              onChange={(description) => {
                clearError('description')
                setVm((vm) => ({ ...vm, description, dirty: true }))
              }}
            />
          </div>
        </div>

        <Footer>
          <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center' }}>
            <button type="button" className="ui-btn ui-btn-primary ui-btn-solid" disabled={saving} onClick={() => history.goBack()}>
              {t('General.Exit')}
            </button>

            <div style={{ marginLeft: 'auto' }} />

            <button type="submit" disabled={!vm.dirty || saving} className="ui-btn ui-btn-secondary ui-btn-solid">
              {t('General.Save')}
            </button>
          </div>
        </Footer>
      </form>

      {datePicker && (
        <DatePicker
          style={{ left: datePicker.left, top: datePicker.top }}
          start={moment(vm.start).format()}
          end={moment(vm.end).format()}
          min={datePicker.which === 'end' ? moment(datePicker.timelineItem.min).add(PROJECT_PHASE_MIN_DAYS, 'days').format() : datePicker.timelineItem.min}
          max={datePicker.timelineItem.max}
          markers={markers}
          noHighlight={false}
          maxMonths={1}
          selectRange={false}
          onClose={() => setDatePicker(undefined)}
          onSelectDate={(d) => selectDate(d)}
          ref={datePickerRef}
        />
      )}
    </>
  )
}
