import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMsalEmail, useMsalName } from 'services/src/aad'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/pro-solid-svg-icons'

import 'react-phone-number-input/style.css'
import { DropzoneField } from 'components/src/dropzoneField'
import { TextField } from 'components/src/textField'
import { useGeolocation } from 'services/src/state'
import { PhoneInputField } from 'components/src/phoneInputField'
import { PHOTO_TYPES } from 'services/src/common'

import { NewAccountModel, NewProviderModel } from './model'

export interface EmployeeProfileProps {
  model: NewAccountModel | NewProviderModel
  onChangeModel: (mode: NewAccountModel | NewProviderModel) => void
  onNext: () => void
  onBack?: () => void
}

export const EmployeeProfile: React.FC<EmployeeProfileProps> = ({ model, onChangeModel, onNext, onBack }) => {
  const { t } = useTranslation()
  const { givenName } = useMsalName()
  const email = useMsalEmail()
  const { country_code: geo } = useGeolocation()

  const [errors, setErrors] = useState<any>({})
  const clearError = (name: string) => {
    const theErrors = { ...errors }
    delete theErrors[name]
    setErrors(theErrors)
  }

  const onDropPicture = useCallback(
    (acceptedFiles: any[]) => {
      if (!acceptedFiles.length) return
      const picture = Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0])
      })
      onChangeModel({ ...model, picture })
    },
    [model, onChangeModel]
  )

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      const errors: any = {}
      if (!model.title) errors.title = t('General.Errors.Required')
      else if (model.title.length < 1 || model.title.length > 250) errors.title = t('General.Errors.BetweenNAndMCharacters', { N: 1, M: 250 })

      setErrors(errors)
      if (Object.keys(errors).length > 0) return

      onNext()
    },
    [model, onNext, setErrors, t]
  )

  const isProvider = model.createUserType === 'provider'

  return (
    <form noValidate onSubmit={submit}>
      <h3 className="intro">{t(isProvider ? 'Onboarding.ProviderBusinessIntro' : 'Onboarding.ClientBusinessIntro', { name: givenName })}</h3>
      <hr />
      <br />

      <h2>{model.companyName}</h2>
      <br />

      <div className="ui-form-group ui-text-sm ui-text-muted">{isProvider ? t('Onboarding.Provider.Step2', { N: 2, M: 3 }) : t('Onboarding.ClientStep2')}</div>

      <DropzoneField
        onDrop={onDropPicture}
        variant="round"
        accept={PHOTO_TYPES}
        maxFiles={1}
        maxSize={1024 * 1024}
        rejectLabel={t('Onboarding.InvalidPicture')}
        label={t('Onboarding.Picture')}
        error={errors.picture}
      >
        <div className="ui-flex" style={{ justifyContent: 'center' }}>
          {model.picture?.preview ? (
            <div className="bg-preview" style={{ width: 130, height: 130, backgroundImage: `url('${model.picture?.preview}')` }} />
          ) : (
            <div className="empty" style={{ width: 130, height: 130, paddingTop: 40 }}>
              <FontAwesomeIcon icon={faCamera} />
            </div>
          )}
        </div>
      </DropzoneField>

      <TextField
        name="title"
        value={model.title}
        label={t('Onboarding.TitleOrRole')}
        error={errors.title}
        onChange={(title) => {
          clearError('title')
          onChangeModel({ ...model, title })
        }}
        placeholder={t('Onboarding.TitleOrRolePlaceholder')}
      />

      <TextField name="email" value={email} disabled label={t('Onboarding.Email')} onChange={() => {}} placeholder={t('Onboarding.EmailPlaceholder')} />

      <div className="ui-row">
        <div className="ui-col-6">
          <TextField name="givenName" value={model.givenName} disabled label={t('Onboarding.GivenName')} onChange={() => {}} placeholder={t('Onboarding.GivenNamePlaceholder')} />
        </div>
        <div className="ui-col-6">
          <TextField name="familyName" value={model.familyName} disabled label={t('Onboarding.FamilyName')} onChange={() => {}} placeholder={t('Onboarding.FamilyNamePlaceholder')} />
        </div>
      </div>

      <div className="ui-row">
        <div className="ui-col-8">
          <PhoneInputField
            label={t('Onboarding.Phone')}
            placeholder={t('Onboarding.PhonePlaceholder')}
            value={model.phone}
            error={errors.phone}
            onChange={(phone) => {
              clearError('phone')
              onChangeModel({ ...model, phone })
            }}
            country={geo?.country_code?.toLowerCase() || 'us'}
          />
        </div>
        <div className="ui-col-4">
          <TextField
            name="extension"
            value={model.extension}
            label={t('Onboarding.Extension')}
            onChange={(extension) => {
              clearError('extension')
              onChangeModel({ ...model, extension })
            }}
            placeholder={t('Onboarding.ExtensionPlaceholder')}
          />
        </div>
      </div>

      <div className="ui-row">
        <div className="ui-col-6">
          <button
            type="button"
            className="ui-btn ui-btn-primary ui-btn-solid"
            style={{ width: '100%' }}
            onClick={() => {
              if (onBack) onBack()
            }}
          >
            {t('Onboarding.Back')}
          </button>
        </div>
        <div className="ui-col-6">
          <button type="submit" className="ui-btn ui-btn-secondary ui-btn-solid" style={{ width: '100%' }}>
            {t('Onboarding.Next')}
          </button>
        </div>
      </div>
    </form>
  )
}
