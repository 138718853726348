import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loading } from 'components/src/loading';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostRequests } from 'services/src/api/boe';
import { endpoints } from 'services/src/lib/axios';
import { BoeProjectSectionSubheader } from './BoeProjectSectionSubheader';
import ProjectBoeProgressBar from './ProjectBoeProgressBar';

interface ProjectBoeClassificationSectionProps {
  handleNext: () => void;
  handlePrevious: () => void;
  setBoeData: (data: any) => void;
  boeData: any;
  isProgressBarVisible: boolean;
  setIsProgressBarVisible: (value: boolean) => void;
}

interface Content {
  name: string;
  key: string;
  description: string;
}

interface Details {
  estimateClass: string;
  degreeOfProjectDefinition: string;
  endUsage: string;
  methodology: string;
  expectedAccuracyRange: string;
}

interface Classification {
  description: string;
  content: Content[];
  details: Details[];
}

const BoeProject16: React.FC<ProjectBoeClassificationSectionProps> = ({
  handleNext,
  handlePrevious,
  setBoeData,
  boeData,
  isProgressBarVisible,
  setIsProgressBarVisible,
}) => {
  const { t } = useTranslation();
  const progress = 18;
  const [call, setCall] = useState<boolean>(false);
  const [classification, setClassification] = useState<Classification>();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(boeData?.classification || null);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { loading, data, error, postRequests } = usePostRequests();

  useEffect(() => {
    if (!call) {
      setCall(true);
      postRequests(endpoints.boe.classification, {});
    }
  }, [call, postRequests]);

  useEffect(() => {
    if (data?.response) {
      setClassification(data?.response || '');
    }
  }, [data?.response]);

  const handleSelectDetail = (index: number) => {
    setSelectedIndex(index);
    setErrorMessage(''); // Clear error message when a classification is selected
  };

  const handleNextClick = () => {
    if (selectedIndex === null) {
      setErrorMessage('Please select a classification before proceeding.');
      return;
    }
    setBoeData((prev: any) => ({ ...prev, classification: selectedIndex }));
    handleNext();
  };

  return (
    <div className="boe-content">
      <div className="boe-form-content">
        <ProjectBoeProgressBar
          progress={progress}
          title={t('BasisOfEstimate.Classification.ProgressTitle')}
          leftText={t('BasisOfEstimate.Classification.ProgressLeftText')}
          rightText={t('BasisOfEstimate.Classification.ProgressRightText')}
          isProgressBarVisible={isProgressBarVisible}
          setIsProgressBarVisible={setIsProgressBarVisible}
          boeData={boeData}
        />
        <BoeProjectSectionSubheader title = {t('BasisOfEstimate.BoeProject16.Title')} dynamicText={t('BasisOfEstimate.BoeProject16.Dynamic')} boeData={boeData} />
        {loading && <Loading />}
        {error && (
          <div className="boe-error-container">
            <p>{t('General.Error')}</p>
          </div>
        )}
        {classification && <>
          <p>{classification?.description}</p>
          <div className='boe-classification-table-header'>
            {classification?.content.map((content: any, index) => (
              <div className='boe-classification-table-header-content' key={index}>
                <h6 className='boe-classification-table-head'>{content.name}</h6>
                <div className='ui-flex'>
                  <h6 className='boe-classification-table-sub-head'>{content.description ? `(${content.description})` : ``}</h6>
                  <FontAwesomeIcon icon={faCaretDown} />
                </div>
              </div>
            ))}
          </div>
          {classification?.details.map((detail: any, index) => (
            <div key={index} className='boe-classification-table-row'>
              <input
                type="checkbox"
                className='boe-classification-table-checkbox'
                checked={selectedIndex === index}
                onChange={() => handleSelectDetail(index)}
              />
              {classification?.content.map((content: any, contentIndex: number) => (
                <p className='boe-classification-table-row-content' key={contentIndex}>{detail[content.key]}</p>
              ))}
            </div>
          ))}
        </>}
        {errorMessage && (
          <div className="boe-section-header-right-content">
            <p className='boe-error-message'>{errorMessage}</p>
          </div>
        )}
      </div>
      <div className="boe-form-action-container">
        <button type="button" disabled={loading} className="btn-next" onClick={handlePrevious}>
          {t('General.Previous')}
        </button>
        <button type="button" className="btn-previous" onClick={handleNextClick} disabled={loading}>
          {t('General.Next')}
        </button>
      </div>
    </div>
  );
};

export default BoeProject16;