import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Fuse from 'fuse.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompassDrafting } from '@fortawesome/pro-regular-svg-icons'

import { ProjectType } from 'services/src/dto/projectShare'
import { Modal, ModalProps } from 'components/src/modal'
import { updateProjectType, useProjectSupport } from 'services/src/api'
import { AutoCompleteField } from 'components/src/autoCompleteField'
import { Select } from 'components/src/select'
import { useAlert } from 'components/src/alerts'
import { useSaving } from 'components/src/saving'
import { useEditProject } from 'services/src/state'

export type ClassifyProjectTypeProps = ModalProps & {
  projectType: ProjectType
  onChange: (projectType: Partial<ProjectType>) => void
}

export const ClassifyProjectType: React.FC<ClassifyProjectTypeProps> = ({ projectType: initProjectType, onClose, onChange }) => {
  const { t } = useTranslation()
  const [saving, setSaving] = useSaving()
  const { alertSuccess, alertDanger } = useAlert()

  const [{ constructionCategories, projectTypes }] = useProjectSupport()
  const { project, patch } = useEditProject()

  const [errors, setErrors] = useState<any>({})
  const clearError = (name: string) => {
    const theErrors = { ...errors }
    delete theErrors[name]
    setErrors(theErrors)
  }

  const categories = useMemo(() => {
    const cats: any[] = []
    for (let i = 0; i < 7; i++) cats.push({ id: (i + 1).toString(), cat: (i + 1).toString() })
    return cats
  }, [])
  const [selectedCategory, setSelectedCategory] = useState<{ id: string; cat: string } | undefined>()

  const [name, setName] = useState(initProjectType ? initProjectType.type || '' : '')

  const [projectTypeName, setProjectTypeName] = useState('')
  const [projectTypeSuggestions, setProjectTypeSuggestions] = useState<ProjectType[]>([])
  const [projectType, setProjectType] = useState<ProjectType>()

  const submitNew = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      const errors: any = {}

      if (!selectedCategory) errors.category = t('General.Errors.Required')
      if (!name) errors.name = t('General.Errors.Required')

      setErrors(errors)
      if (Object.keys(errors).length > 0) return

      const pt = { ...initProjectType }
      pt.category = parseInt(selectedCategory!.id, 10)
      pt.subcategory = 0
      pt.type = name

      setSaving(true)
      updateProjectType(pt, project?.id)
        .then((pt) => {
          alertSuccess({
            title: t('EditProject.ClassifyProjectType.ClassifiedTitle'),
            message: t('EditProject.ClassifyProjectType.Classified', { name: pt.type, category: pt.category })
          })
          onChange(pt)
        })
        .catch((error) => {
          alertDanger({
            title: t('EditProject.ClassifyProjectType.Errors.ClassifiedTitle'),
            message: t('EditProject.ClassifyProjectType.Errors.Classified'),
            error
          })
        })
        .finally(() => setSaving(false))
    },
    [setErrors, selectedCategory, name, initProjectType, project, alertSuccess, alertDanger, t]
  )

  const submitAssign = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      const errors: any = {}

      if (!projectType) errors.projectType = t('General.Errors.Required')

      setErrors(errors)
      if (Object.keys(errors).length > 0) return

      setSaving(true)
      patch([
        {
          propertyName: 'ProjectTypeId',
          propertyValue: projectType!.id
        }
      ])
        .then(() => {
          alertSuccess({
            title: t('EditProject.ClassifyProjectType.ChangedTitle'),
            message: t('EditProject.ClassifyProjectType.Changed')
          })
          onChange(projectType!)
        })
        .catch((error) => {
          alertDanger({
            title: t('EditProject.ClassifyProjectType.Errors.ChangedTitle'),
            message: t('EditProject.ClassifyProjectType.Errors.Changed'),
            error
          })
        })
        .finally(() => setSaving(false))
    },
    [onChange, setErrors, projectType, name, constructionCategories, t]
  )

  return (
    <Modal onClose={onClose} closeOnClickOutside={false} header={<h3>{t('EditProject.ClassifyProjectType.Title')}</h3>}>
      <form onSubmit={submitNew} noValidate autoComplete="off">
        <label>{t('EditProject.ClassifyProjectType.ProviderInfo')}</label>
        <div className="ui-frame ui-frame-bg ui-text-sm" style={{ padding: 10 }}>
          <div className="ui-flex">
            <div style={{ flex: 1 }} className="ui-text-muted">
              {t('EditProject.ClassifyProjectType.ConstructionCategory')}
            </div>
            <div>{initProjectType.constructionCategory}</div>
          </div>
          <div className="ui-flex">
            <div style={{ flex: 1 }} className="ui-text-muted">
              {t('EditProject.ClassifyProjectType.ProjectCategory')}
            </div>
            <div>{initProjectType.projectCategory}</div>
          </div>
          <div className="ui-flex">
            <div style={{ flex: 1 }} className="ui-text-muted">
              {t('EditProject.ClassifyProjectType.Type')}
            </div>
            <div>{initProjectType.type}</div>
          </div>
        </div>

        <div className="ui-frame ui-frame-bg ui-text-sm" style={{ padding: 10, marginTop: 10 }}>
          <div>
            <div className="ui-flex">
              <div style={{ width: 70 }} className={errors.category ? 'ui-has-error' : ''}>
                <label>{t('EditProject.ClassifyProjectType.Category')}</label>
                <Select
                  items={categories}
                  selected={selectedCategory}
                  disabled={saving}
                  labelField="cat"
                  onSelectedChange={(x) => {
                    setSelectedCategory(x)
                    clearError('category')
                  }}
                  placeholder={t('EditProject.ClassifyProjectType.CategorySample')}
                  maxDropDown={10}
                />
              </div>
              <div style={{ marginLeft: 10, flex: 1 }} className={errors.name ? 'ui-has-error' : ''}>
                <label>{t('EditProject.ClassifyProjectType.Type')}:</label>
                <input
                  type="text"
                  value={name}
                  disabled={saving}
                  onChange={(e) => {
                    setName(e.currentTarget.value)
                    clearError('name')
                  }}
                />
                {errors.name && <div className="ui-error">{errors.name}</div>}
              </div>
            </div>
            <div className={errors.category ? 'ui-has-error' : ''}>
              <div className="ui-error">{errors.category}</div>
            </div>
          </div>

          <div style={{ marginTop: 15 }}>
            <button type="submit" className="ui-btn ui-btn-primary ui-btn-solid" style={{ width: '100%' }} disabled={saving}>
              {t('EditProject.ClassifyProjectType.SaveNew')}
            </button>
          </div>
        </div>
      </form>

      <form onSubmit={submitAssign} noValidate autoComplete="off">
        <div className="ui-frame ui-frame-bg" style={{ marginTop: 20, padding: 10 }}>
          <AutoCompleteField
            suggestions={projectTypeSuggestions}
            value={projectTypeName}
            placeholder={t('EditProject.ClassifyProjectType.Type')}
            label={t('EditProject.ClassifyProjectType.Type')}
            error={errors.projectType}
            disabled={saving}
            onValueChange={(projectTypeName) => {
              clearError('projectType')

              const projectType = projectTypes.find((x) => x.type.toLowerCase() === projectTypeName.toLowerCase())
              setProjectType(projectType)
              setProjectTypeName(projectTypeName)
              setProjectTypeSuggestions([])
              if (!projectTypeName) return

              if (projectTypes) {
                const fuse = new Fuse(projectTypes, { keys: ['type'] })
                const result = fuse.search(projectTypeName)
                setProjectTypeSuggestions(result.map((x) => x.item))
              }
            }}
            onSelectSuggestion={(projectType) => {
              clearError('projectType')
              setProjectType(projectType)
              setProjectTypeName(projectType.type)
              setProjectTypeSuggestions([])
            }}
            render={(item: ProjectType) => (
              <div>
                <FontAwesomeIcon icon={faCompassDrafting} />
                &nbsp;&nbsp;{item.type}
              </div>
            )}
          />

          <div style={{ marginTop: 15 }}>
            <button type="submit" className="ui-btn ui-btn-primary ui-btn-solid" style={{ width: '100%' }} disabled={saving}>
              {t('EditProject.ClassifyProjectType.Reassign')}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
