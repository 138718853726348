import React, { TextareaHTMLAttributes } from 'react'

export type TextAreaFieldProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
  name: string
  value: string
  onChange: (value: string) => void
  placeholder?: string
  rows?: number
  label?: string | JSX.Element
  error?: string | JSX.Element
}

export const TextAreaField: React.FC<TextAreaFieldProps> = ({ name, value, onChange, placeholder, rows, label, error, ...rest }) => (
  <div className={`ui-form-group ${error ? 'ui-has-error' : ''}`}>
    {label && <label htmlFor={name}>{label}</label>}
    <textarea id={name} name={name} value={value} rows={rows || 5} onChange={(e) => onChange(e.currentTarget.value)} placeholder={placeholder} {...rest} />
    {error && <div className="ui-error">{error}</div>}
  </div>
)
