import React, { SVGProps } from 'react'
import './style.scss'

const Menu: React.FC<SVGProps<SVGSVGElement>> = ({ className, ...rest }) => (
  <svg viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" className={`ui-svg ui-svg-menu ${className || ''}`} {...rest}>
    <g clipPath="url(#clip0)">
      <path d="M7 14.5C10.866 14.5 14 11.366 14 7.5C14 3.63401 10.866 0.5 7 0.5C3.13401 0.5 0 3.63401 0 7.5C0 11.366 3.13401 14.5 7 14.5Z" className="bg" />
      <path d="M10.3507 3.57422H3.63872C3.163 3.57422 2.77734 3.91 2.77734 4.32423C2.77734 4.73844 3.163 5.07422 3.63872 5.07422H10.3507C10.8265 5.07422 11.2121 4.73842 11.2121 4.32423C11.2121 3.91 10.8265 3.57422 10.3507 3.57422Z" />
      <path d="M10.2609 6.63672H3.54888C3.07316 6.63672 2.6875 6.97251 2.6875 7.38672C2.6875 7.80091 3.07316 8.13672 3.54888 8.13672H10.2609C10.7366 8.13672 11.1223 7.80091 11.1223 7.38672C11.1223 6.97251 10.7366 6.63672 10.2609 6.63672Z" />
      <path d="M10.3507 9.69922H3.63872C3.163 9.69922 2.77734 10.035 2.77734 10.4492C2.77734 10.8634 3.163 11.1992 3.63872 11.1992H10.3507C10.8265 11.1992 11.2121 10.8634 11.2121 10.4492C11.2121 10.035 10.8265 9.69922 10.3507 9.69922Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)
export default Menu
