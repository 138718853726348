import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios, { Canceler } from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faFile, faFileArchive, faFileCsv, faFileExcel, faFileImage, faFilePdf, faFilePowerpoint, faFileWord, faTimes } from '@fortawesome/pro-regular-svg-icons'

import { uploadFile, deleteFile, UploadFileEvent } from 'services/src/api'
import { useAlert } from 'components/src/alerts'
import { Dropzone } from 'components/src/dropzone'
import { FileError, FileRejection } from 'react-dropzone'
import { DRAWING_TYPES, MAX_DOCUMENT_SIZE, MAX_DRAWING_SIZE, MAX_PHOTO_SIZE, PHOTO_TYPES } from 'services/src/common'
import { ProjectContent } from 'services/src/dto/content'
import { NewProjectModel } from './model'

import { Footer } from './Footer'
import { Uploading } from '../common/Uploading'

enum Views {
  Drawing,
  Documents,
  Photos
}

export interface UploadProps {
  model: NewProjectModel
  active: boolean
  step: number
  isLastStep: boolean
  onChangeModel: (model: NewProjectModel) => void
  onBack: () => void
  onNext: () => void
  onCancel: () => void
}

const DropzoneContent: React.FC<{
  model: NewProjectModel
  onChangeModel: (model: NewProjectModel) => void
  view: Views
}> = ({ model, onChangeModel, view }) => {
  const { t } = useTranslation()

  if (view === Views.Drawing) {
    if (model.ext.drawing) {
      return (
        <div className="has-drawing-file">
          <div className="ui-success">
            <FontAwesomeIcon icon={faCheckCircle} />
          </div>
          <div>
            <div className="ui-text-lg" style={{ marginTop: 13 }}>
              {t('NewProject.Upload.DrawingFile', { filename: model.ext.drawing.originalName })}
            </div>
            <br />
            <div>
              <button
                type="button"
                className="ui-btn ui-btn-danger ui-btn-sm"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  if (model.account?.id && model.id && model.ext.drawing?.id) deleteFile(model.account.id, model.id, model.ext.drawing.id).then(() => {})

                  onChangeModel({ ...model, ext: { ...model.ext, drawing: undefined } })
                }}
              >
                {t('NewProject.Upload.RemoveDrawing')}
              </button>
            </div>
          </div>
        </div>
      )
    }

    return (
      <>
        <div className="ui-text-lg">{t('General.DropFile')}</div>
        <br />
        <div className="ui-text-muted">{t('NewProject.Upload.DrawingLimit')}</div>
      </>
    )
  }

  if (view === Views.Documents) {
    return (
      <>
        <div className="ui-text-lg">{t('General.DropFile')}</div>
        <br />
        <div className="ui-text-muted">{t('NewProject.Upload.DocumentLimit')}</div>
      </>
    )
  }
  if (view === Views.Photos) {
    return (
      <>
        <div className="ui-text-lg">{t('General.DropFile')}</div>
        <br />
        <div className="ui-text-muted">{t('NewProject.Upload.PhotoLimit')}</div>
      </>
    )
  }
  return null
}

export const Upload: React.FC<UploadProps> = ({ model, active, step, isLastStep, onChangeModel, onBack, onNext, onCancel }) => {
  const { t } = useTranslation()
  const { alertDanger, alertWarn } = useAlert()

  const [view, setView] = useState<Views>(Views.Drawing)

  const [files, setFiles] = useState<File[]>()
  const [currentFileIndex, setCurrentFileIndex] = useState<number>(-1)
  const [currentProgress, setCurrentProgress] = useState<number>(0)
  const [currentCategory, setCurrentCategory] = useState<string>('')
  const [currentPhaseId, setCurrentPhaseId] = useState<string>('')

  const canceler = useRef<Canceler>()

  const onProgress = useCallback(
    (e: UploadFileEvent) => {
      if (e.error) {
        canceler.current = undefined
        setCurrentFileIndex(-1)
        setFiles(undefined)
        setCurrentProgress(0)
        if (e.error.code !== 'ERR_CANCELED') {
          alertDanger({
            title: t('General.Errors.FileUploadTitle'),
            message: t('General.Errors.FileUpload'),
            error: e.error
          })
        }
        return
      }

      if (!files) return

      if (e.state === 'cancel') {
        canceler.current = undefined
        setFiles(undefined)
        setCurrentProgress(0)
        return
      }

      if (e.state === 'complete') {
        if (e.content) {
          if (currentCategory === 'Drawing') {
            onChangeModel({ ...model, ext: { ...model.ext, drawing: e.content } })
          } else if (currentCategory === 'Document') {
            const documents = [...(model.ext.documents || []), e.content]
            onChangeModel({ ...model, ext: { ...model.ext, documents } })
          } else if (currentCategory === 'Picture') {
            const photos = [...(model.ext.photos || []), e.content]
            onChangeModel({ ...model, ext: { ...model.ext, photos } })
          }
        }

        let nextIndex = currentFileIndex + 1
        if (nextIndex >= files.length) nextIndex = -1
        setCurrentFileIndex(nextIndex)

        if (nextIndex < 0) {
          canceler.current = undefined
          setFiles(undefined)
          setCurrentProgress(0)
        }
        return
      }

      setCurrentProgress(e.error ? 1 : e.progress)
    },
    [canceler, currentFileIndex, setCurrentFileIndex, files, model, setFiles, setCurrentProgress, alertDanger]
  )

  useEffect(() => {
    if (currentFileIndex < 0 || !files || !model.id) return

    // Are we done?
    if (currentFileIndex >= files.length) {
      canceler.current = undefined
      setCurrentFileIndex(-1)
      setFiles(undefined)
      setCurrentProgress(0)
      return
    }

    setCurrentProgress(0)
    uploadFile(
      model.account?.id || '',
      model.id,
      currentCategory,
      currentPhaseId,
      files[currentFileIndex],
      onProgress,
      new axios.CancelToken((c: Canceler) => {
        canceler.current = c
      })
    ).then(() => {})
  }, [currentFileIndex, currentCategory, currentPhaseId, files, alertDanger, t])

  const onDropDrawing = useCallback(
    (files: File[]) => {
      const phase = model.phases?.find((x) => !!x.submissionDate)
      if (!phase || !phase.id) {
        // TODO: Report phase not set error!
        // User shouldn't even be on this page if there is no phase, I get this scenario when refreshig page, send them back to project scope?
        return
      }

      setFiles(files)
      setCurrentCategory('Drawing')
      setCurrentProgress(0)
      setCurrentPhaseId(phase.id)

      // Let UI update then start upload
      setTimeout(() => setCurrentFileIndex(0), 10)
    },
    [model, setFiles, setCurrentCategory, setCurrentProgress, setCurrentPhaseId]
  )

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      const formatBytes = (bytes: number, decimals = 2) => {
        if (bytes === 0) return '0 Bytes'
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
      }

      const getDropzoneValidationErrorMsg = (error: FileError) => {
        switch (error.code) {
          case 'file-too-large': {
            let bytes
            switch (view) {
              case Views.Drawing:
                bytes = MAX_DRAWING_SIZE
                break
              case Views.Documents:
                bytes = MAX_DOCUMENT_SIZE
                break
              case Views.Photos:
                bytes = MAX_PHOTO_SIZE
                break
              default:
                bytes = 0
            }
            return t('General.Errors.FileTooLarge', { size: formatBytes(bytes) })
          }

          default:
            return error.message
        }
      }

      const errors = fileRejections.flatMap((fr) => fr.errors)
      errors.forEach((e) => {
        alertWarn({
          title: t('General.Errors.InvalidFile'),
          message: getDropzoneValidationErrorMsg(e)
        })
      })
    },
    [model, setFiles, setCurrentCategory, setCurrentProgress, setCurrentPhaseId]
  )

  const onDropDocument = useCallback(
    (files: File[]) => {
      const phase = model.phases?.find((x) => !!x.submissionDate)
      if (!phase || !phase.id) {
        // TODO: Report phase not set error!
        return
      }

      setFiles(files)
      setCurrentCategory('Document')
      setCurrentProgress(0)
      setCurrentPhaseId(phase.id)

      // Let UI update then start upload
      setTimeout(() => setCurrentFileIndex(0))
    },
    [model, setFiles, setCurrentCategory, setCurrentProgress, setCurrentPhaseId]
  )

  const onDropPhoto = useCallback(
    (files: File[]) => {
      const phase = model.phases?.find((x) => !!x.submissionDate)
      if (!phase || !phase.id) {
        // TODO: Report phase not set error!
        return
      }

      setFiles(files)
      setCurrentCategory('Picture')
      setCurrentProgress(0)
      setCurrentPhaseId(phase.id)

      // Let UI update then start upload
      setTimeout(() => setCurrentFileIndex(0))
    },
    [model, setFiles, setCurrentCategory, setCurrentProgress, setCurrentPhaseId]
  )

  const submit = useCallback(
    (e?: React.FormEvent) => {
      if (e) e.preventDefault()
      onNext()
    },
    [onNext]
  )

  const getIcon = useCallback((f: ProjectContent) => {
    if (f.contentType.startsWith('image/')) return <FontAwesomeIcon icon={faFileImage} />
    if (f.contentType.includes('pdf')) return <FontAwesomeIcon icon={faFilePdf} />
    if (f.contentType === 'text/csv') return <FontAwesomeIcon icon={faFileCsv} />
    if (f.contentType.endsWith('/zip')) return <FontAwesomeIcon icon={faFileArchive} />
    if (f.contentType.endsWith('/msword') || f.contentType.includes('wordprocessingml')) return <FontAwesomeIcon icon={faFileWord} />

    if (f.contentType.endsWith('/ms-excel') || f.contentType.includes('spreadsheetml')) return <FontAwesomeIcon icon={faFileExcel} />

    if (f.contentType.endsWith('/ms-powerpoint') || f.contentType.includes('presentationml')) return <FontAwesomeIcon icon={faFilePowerpoint} />
    return <FontAwesomeIcon icon={faFile} />
  }, [])

  if (!active) return null

  return (
    <>
      <div className="new-project-step-header">
        <div>{t('General.StepN', { N: step + 1 })}</div>
        <div>{t('NewProject.Upload.Title')}</div>
      </div>

      <form onSubmit={submit} noValidate autoComplete="off">
        <div className="ui-item-group" style={{ justifyContent: 'center' }}>
          <button type="button" style={{ width: 150 }} className={`ui-btn ui-btn-primary${view === 0 ? ' ui-btn-selected' : ''}`} onClick={() => setView(0)}>
            {t('NewProject.Upload.Drawing')}
          </button>
          <button type="button" style={{ width: 150 }} className={`ui-btn ui-btn-primary${view === 1 ? ' ui-btn-selected' : ''}`} onClick={() => setView(1)}>
            {t('NewProject.Upload.Documents')}
            {model.ext.documents?.length ? (
              <span className="ui-text-xs" style={{ marginLeft: 5 }}>
                ({model.ext.documents.length})
              </span>
            ) : (
              ''
            )}
          </button>
          <button type="button" style={{ width: 150 }} className={`ui-btn ui-btn-primary${view === 2 ? ' ui-btn-selected' : ''}`} onClick={() => setView(2)}>
            {t('NewProject.Upload.Photos')}
            {model.ext.photos?.length ? (
              <span className="ui-text-xs" style={{ marginLeft: 5 }}>
                ({model.ext.photos.length})
              </span>
            ) : (
              ''
            )}
          </button>
        </div>

        {view === Views.Drawing && (
          <div className="new-project-upload-dropzone">
            <Dropzone
              idleLabel={<DropzoneContent model={model} onChangeModel={onChangeModel} view={view} />}
              onDropAccepted={onDropDrawing}
              onDropRejected={onDropRejected}
              accept={DRAWING_TYPES}
              maxFiles={1}
              maxSize={MAX_DRAWING_SIZE}
            />
          </div>
        )}
        {view === Views.Documents && (
          <>
            <div className="new-project-upload-dropzone">
              <Dropzone idleLabel={<DropzoneContent model={model} onChangeModel={onChangeModel} view={view} />} onDropAccepted={onDropDocument} maxSize={MAX_DOCUMENT_SIZE} />
            </div>

            {model.ext.documents && model.ext.documents.length > 0 && (
              <div className="ui-flex" style={{ flexWrap: 'wrap', marginTop: 20 }}>
                {model.ext.documents.map((f, idx) => (
                  <div key={idx} className="ui-frame ui-flex" style={{ padding: 0, paddingRight: 3 }}>
                    <div style={{ width: 20, fontSize: '1.1rem', padding: '10px 0', marginLeft: 10 }}>{getIcon(f)}</div>

                    <div style={{ padding: '10px 0' }}>{f.originalName}</div>

                    <div
                      className="ui-flex ui-action-item"
                      role="button"
                      tabIndex={-1}
                      onKeyDown={() => {}}
                      onClick={() => {
                        const documents = [...(model.ext.documents || [])]
                        const d = documents[idx]

                        documents.splice(idx, 1)

                        if (model.account?.id && model.id && d.id) deleteFile(model.account.id, model.id, d.id).then(() => {})

                        onChangeModel({ ...model, ext: { ...model.ext, documents } })
                      }}
                      style={{
                        alignItems: 'center',
                        marginLeft: 5,
                        marginTop: -25
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} style={{ width: 13, height: 15, marginRight: 1 }} />
                      <div className="ui-text-xxs ui-text-uppercase">Remove</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        {view === Views.Photos && (
          <>
            <div className="new-project-upload-dropzone">
              <Dropzone idleLabel={<DropzoneContent model={model} onChangeModel={onChangeModel} view={view} />} onDropAccepted={onDropPhoto} accept={PHOTO_TYPES} maxSize={MAX_PHOTO_SIZE} />
            </div>
            {model.ext.photos && model.ext.photos.length > 0 && (
              <div className="ui-flex" style={{ flexWrap: 'wrap' }}>
                {model.ext.photos.map((f, idx) => (
                  <div key={idx} className="ui-frame ui-flex" style={{ padding: 0, paddingRight: 3, marginTop: 20, marginRight: 10 }}>
                    <div style={{ width: 20, fontSize: '1.1rem', padding: '10px 0', marginLeft: 10 }}>{getIcon(f)}</div>

                    <div style={{ padding: '10px 0' }}>{f.originalName}</div>

                    <div
                      className="ui-flex ui-action-item"
                      role="button"
                      tabIndex={-1}
                      onKeyDown={() => {}}
                      onClick={() => {
                        const photos = [...(model.ext.photos || [])]
                        const p = photos[idx]

                        photos.splice(idx, 1)

                        if (model.account?.id && model.id && p.id) deleteFile(model.account.id, model.id, p.id).then(() => {})

                        onChangeModel({ ...model, ext: { ...model.ext, photos } })
                      }}
                      style={{
                        alignItems: 'center',
                        marginLeft: 5,
                        marginTop: -25
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} style={{ width: 13, height: 15, marginRight: 1 }} />
                      <div className="ui-text-xxs ui-text-uppercase">Remove</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </form>

      <Footer canBack={step > 0} onBack={onBack} canNext={!isLastStep} onNext={submit} onCancel={onCancel} />

      {files && currentFileIndex >= 0 && (
        <Uploading
          filename={files[currentFileIndex].name}
          progress={currentProgress}
          onCancel={() => {
            // Cancel the upload
            if (canceler.current) canceler.current()

            canceler.current = undefined
            setCurrentFileIndex(-1)
            setFiles(undefined)
            setCurrentProgress(0)
          }}
        />
      )}
    </>
  )
}
