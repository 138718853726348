import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ProjectContentEx, useRights, UserType } from 'services/src/state'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDoubleLeft, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import { CommentBtn } from '../commentPanel/CommentBtn'

export const DrawingGrid: React.FC<{
  disciplines?: string[]
  content: ProjectContentEx
  selected?: ProjectContentEx
  onSelect: (content?: ProjectContentEx, noOpen?: boolean) => void
}> = ({ disciplines, content, selected, onSelect }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const rights = useRights()

  const gridRef = useRef<HTMLDivElement>(null)
  const [gridHeight, setGridHeight] = useState(0)

  const resize = useCallback(() => {
    if (!gridRef.current) return

    const r = gridRef.current.getBoundingClientRect()
    setGridHeight(window.innerHeight - r.top - 40)
  }, [setGridHeight])

  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => {
      window.addEventListener('resize', resize)
    }
  }, [resize])

  const readyPages = useMemo(() => {
    if (!content.children?.length) return undefined

    const readyPages = content.children.filter((c) => {
      if (!disciplines?.length) return true

      let result = true

      if (disciplines.includes('$noDiscipline$')) result = !c.discipline

      const ex = disciplines.filter((d) => !d.startsWith('$'))
      if (ex.length > 0) {
        if (!(c.discipline && ex.includes(c.discipline.toString()))) result = false
      }

      if (!result) return result

      if (disciplines.includes('$warn$') && disciplines.includes('$danger$')) {
        result = !c.disciplineById && !!c.classification && c.score! <= 0.75
      } else if (disciplines.includes('$warn$')) {
        result = !c.disciplineById && !!c.classification && c.score! > 0.5 && c.score! <= 0.75
      } else if (disciplines.includes('$danger$')) {
        result = !c.disciplineById && !!c.classification && c.score! <= 0.5
      }

      return result
    })

    setTimeout(() => resize())
    return readyPages
  }, [content, disciplines, resize])

  return (
    <>
      <div className="ui-document-view-toolbar ui-no-select">
        <div className="name">
          <Link className="ui-action-item ui-secondary" style={{ marginRight: 10 }} to={history.location.pathname.replace(`/${content.id}`, '')}>
            <FontAwesomeIcon icon={faChevronDoubleLeft} />
          </Link>
          {content.originalName}
        </div>
      </div>

      <div className="ui-drawing-view-grid" ref={gridRef} style={{ height: gridHeight }}>
        {readyPages?.length ? (
          readyPages.map((child) => {
            if (!child.images?.card) return null

            return (
              <div key={child.id} className="ui-drawing-view-grid-cell">
                <div className="ui-drawing-view-grid-cell-frame">
                  <div className="name">{child.originalName}</div>

                  <div className={`preview${selected?.id === child.id ? ' selected' : ''}`} role="row" tabIndex={-1} onKeyDown={() => {}} onClick={() => onSelect(child)}>
                    <img alt="" src={child.images?.card} />
                  </div>

                  <div className="discipline ui-flex ui-flex-nowrap">
                    {(() => {
                      // Set by provider
                      if (child.discipline && child.disciplineById)
                        return (
                          <div className="ui-badge ui-badge-success" style={{ paddingTop: 4, marginRight: 10 }}>
                            {t(`General.Disciplines.${child.discipline}`)}
                          </div>
                        )

                      if (!child.classification) return null

                      const s = child.score || 0
                      return (
                        <div
                          className={`ui-badge ${
                            // eslint-disable-next-line no-nested-ternary
                            s > 0.75 ? 'ui-badge-success' : s > 0.5 ? 'ui-badge-warn' : 'ui-badge-danger'
                          }`}
                          style={{ paddingTop: 4, marginRight: 10 }}
                        >
                          {t(`General.Disciplines.${child.classification}`)}
                        </div>
                      )
                    })()}

                    <div style={{ marginLeft: 'auto' }}>
                      <CommentBtn variant="iconOnly" onOpen={() => onSelect(child, true)} />
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div className="ui-text-lg ui-warn ui-text-center" style={{ marginTop: 100, width: '100%' }}>
            <div>
              <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
            </div>
            <div style={{ marginTop: 30 }}>{t('General.NoPages')}</div>
          </div>
        )}
      </div>
    </>
  )
}
