import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowLeft, faLongArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { MultiSelectField, MultiSelectItem } from 'components/src/multiSelectField'
import { useRights, UserType } from 'services/src/state'
import { NewAccountModel, ProjectTypeEx } from '../common/model'
import { ExpertOnboardingCard } from './ExpertOnboardingCard'

export interface ExpertProjectExperienceProps {
  model: NewAccountModel
  onChangeModel: (model: NewAccountModel) => void
  onNext: () => void
  onBack?: () => void
}

export const ExpertProjectExperience: React.FC<ExpertProjectExperienceProps> = ({ model, onChangeModel, onNext, onBack }) => {
  const { t } = useTranslation()
  const rights = useRights()

  const projectTypes = useMemo(() => model.categoryProjectTypes.find((x) => x.category === model.currentCategory)?.projectTypes || [], [model.currentCategory, model.categoryProjectTypes])

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      onNext()
    },
    [onNext]
  )

  const [projectTypeHeight, setProjectTypeHeight] = useState<number>(400)

  const formRef = useRef<HTMLFormElement>(null)

  const resize = useCallback(() => {
    setProjectTypeHeight((current) => {
      if (!formRef.current) return current

      const r = formRef.current.getBoundingClientRect()
      const wh = window.innerHeight - 80
      if (r.height > window.innerHeight) {
        return Math.max(150, Math.min(400, current - (r.height - wh)))
      }
      return Math.max(150, Math.min(400, current + (wh - r.height)))
    })
  }, [setProjectTypeHeight])

  useEffect(() => {
    window.addEventListener('resize', resize)
    setTimeout(() => resize())
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  return (
    <form noValidate onSubmit={submit} ref={formRef}>
      <h3 className="intro">{t('Onboarding.Expert.ProfessionalExperience', { name: model.givenName })}</h3>
      <hr />
      <br />
      <ExpertOnboardingCard model={model} />
      <br />
      <div className="ui-text-sm ui-text-muted" style={{ marginBottom: 15 }}>
        {t('Onboarding.Expert.Step3', {
          N: rights?.userType === UserType.provider ? 2 : 3,
          M: rights?.userType === UserType.provider ? 4 : 5
        })}
      </div>
      <label className="ui-text ui-text-bold" style={{ marginBottom: 10 }}>
        {t('Onboarding.Expert.Category', { category: model.currentCategory })}
      </label>

      <label style={{ marginBottom: 10 }}>{t('Onboarding.Expert.SelectProjectTypes')}</label>

      <MultiSelectField
        items={projectTypes as MultiSelectItem[]}
        className="ui-text-xs"
        style={{ maxHeight: projectTypeHeight, minHeight: projectTypeHeight }}
        onToggle={(item) => {
          const categoryProjectTypes = [...model.categoryProjectTypes]
          const cat = categoryProjectTypes.find((x) => x.category === model.currentCategory)
          if (!cat) return

          const itemEx = item as ProjectTypeEx
          const pt = cat.projectTypes.find((x) => x.id === itemEx.id)
          if (pt) {
            pt.selected = !pt.selected
            onChangeModel({ ...model, categoryProjectTypes })
          }
        }}
      />

      <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center' }}>
        <div style={{ width: 100 }}>
          <button
            type="button"
            className="ui-btn ui-btn-primary"
            style={{ width: '100%' }}
            onClick={() => {
              if (onBack) onBack()
            }}
          >
            {t('Onboarding.Back')}
          </button>
        </div>

        <div style={{ flex: 1, margin: '0 20px' }}>
          <div className="ui-flex ui-flex-nowrap" style={{ justifyContent: 'center' }}>
            <button
              type="button"
              disabled={model.currentCategory < 2}
              style={{ width: 130, marginRight: 5 }}
              className="ui-btn ui-btn-primary ui-btn-sm"
              onClick={() => {
                onChangeModel({ ...model, currentCategory: model.currentCategory - 1 })
              }}
            >
              {model.currentCategory <= 1 ? (
                <span style={{ marginLeft: 10 }}>{t('Onboarding.Expert.Category', { category: model.currentCategory })}</span>
              ) : (
                <>
                  <FontAwesomeIcon icon={faLongArrowLeft} />
                  <span style={{ marginLeft: 10 }}>{t('Onboarding.Expert.Category', { category: model.currentCategory - 1 })}</span>
                </>
              )}
            </button>

            <button
              type="button"
              disabled={model.currentCategory >= 7}
              style={{ width: 130, marginLeft: 5 }}
              className="ui-btn ui-btn-secondary ui-btn-solid ui-btn-sm"
              onClick={() => {
                onChangeModel({ ...model, currentCategory: model.currentCategory + 1 })
              }}
            >
              {model.currentCategory >= 7 ? (
                <span style={{ marginRight: 10 }}>{t('Onboarding.Expert.Category', { category: model.currentCategory })}</span>
              ) : (
                <>
                  <span style={{ marginRight: 10 }}>{t('Onboarding.Expert.Category', { category: model.currentCategory + 1 })}</span>
                  <FontAwesomeIcon icon={faLongArrowRight} />
                </>
              )}
            </button>
          </div>
        </div>

        <div style={{ width: 100 }}>
          <button type="submit" className="ui-btn ui-btn-secondary ui-btn-solid" style={{ width: '100%' }} disabled={model.currentCategory < 7}>
            {t('Onboarding.Next')}
          </button>
        </div>
      </div>
    </form>
  )
}
