import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { AccountList } from './AccountList'
import { AccountEdit } from './edit/AccountEdit'

export const Accounts: React.FC = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:id`} component={AccountEdit} />
      <Route path={path} exact component={AccountList} />
    </Switch>
  )
}
