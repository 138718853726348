import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'components/src/modal'

export interface ExitModelProps {
  onClose: () => void
  onSaveForLater: () => void
  onAbandon: (noDelete?: boolean) => void
}
export const ExitModal: React.FC<ExitModelProps> = ({ onClose, onSaveForLater, onAbandon }) => {
  const { t } = useTranslation()

  return (
    <Modal onClose={onClose} closeOnClickOutside={false} header={<h3>{t('NewProject.Exit.Header')}</h3>}>
      <form
        className="new-project-exit"
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault()
          onSaveForLater()
        }}
      >
        {t('NewProject.Exit.AbandonMessage')}

        <div className="buttons">
          <button type="submit" className="ui-btn ui-btn-primary ui-btn-solid">
            {t('General.SaveForLater')}
          </button>
          <button type="button" className="ui-btn ui-btn-primary ui-btn-solid" onClick={() => onAbandon()}>
            {t(`NewProject.Exit.Abandon`)}
          </button>
        </div>
      </form>
    </Modal>
  )
}
