import { useEffect, useState } from 'react';


const UsetifulScript = () => {
 
  // Function to increment userI

  useEffect(() => {
    const usetifulTagsScript = document.createElement('script');
    usetifulTagsScript.innerHTML = `
      window.usetifulTags = {  
        ID: "1",
        firstName: "",
        usage: ""
      };
    `;
    document.head.appendChild(usetifulTagsScript);
    // Adding Usetiful script
    const usetifulScript = document.createElement('script');
    usetifulScript.async = true;
    usetifulScript.src = "https://www.usetiful.com/dist/usetiful.js";
    usetifulScript.id = "usetifulScript";
    usetifulScript.dataset.token = "731aa03d1023d9d6fca872d12401248d";
    document.head.appendChild(usetifulScript);
    return () => {
      if (document.head.contains(usetifulTagsScript)) {
        document.head.removeChild(usetifulTagsScript);
      }
      if (document.head.contains(usetifulScript)) {
        document.head.removeChild(usetifulScript);
      }
    };
  },[]); 
  return null;
};

export default UsetifulScript;
