import React, { useEffect } from 'react'
import { useCurrentUserAccount, useRights, useUserAccounts } from 'services/src/state'
import { Loading } from 'components/src/loading'
import { Protected } from '../src/Protected'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { PRIMARY_PROVIDER_ID } from 'services/src/dto/account'
import { AdminLayout } from 'components/src/admin/layout/AdminLayout'
import { Accounts } from 'components/src/admin/accounts/Accounts'
import { Users } from 'components/src/admin/users/Users'
import { AdminHome } from 'components/src/admin/AdminHome'
import { EditAccount } from 'components/src/admin/accounts/EditAccount'
import { EditUser } from 'components/src/admin/users/EditUser'

export const Admin = () => {
  const history= useHistory()
  const { path, url } = useRouteMatch()

  const [account, setAccount] = useCurrentUserAccount()
  const [accounts] = useUserAccounts()
  const rights = useRights()

  useEffect(() => {
    if (!accounts) return

    const a = accounts.find((x) => x.id === PRIMARY_PROVIDER_ID)
    if (a) setAccount(a)
  }, [accounts])

  useEffect(() => {
    if (!rights) return;
    if (!rights.primaryProvider) history.replace('/dashboard')
  }, [])

  useEffect(() => {
    document.body.classList.add('admin')
    return () => {
      document.body.classList.remove('admin')
    }
  }, [])

  if (!account || !rights) return <Loading />

  return (
    <Protected>
      <AdminLayout>
        <Switch>
          <Route path={`${path}/accounts`} exact component={Accounts} />
          <Route path={`${path}/accounts/:id`} component={EditAccount} />
          <Route path={`${path}/users`} exact component={Users} />
          <Route path={`${path}/users/:id`} component={EditUser} />
          <Route path="" exact component={AdminHome} />
        </Switch>
      </AdminLayout>
    </Protected>
  )
}
