import { atom, useRecoilState } from 'recoil'
import { Comment, CommentRead } from 'services/src/dto/comment'
import { useCallback } from 'react'

export const commentContextAtom = atom<CommentContext>({
  key: 'commentContext',
  default: {
    viewState: 'closed',
    comments: {},
    inChange: false
  }
})

export type CommentChannelsFn = (channel?: string, commentReads?: CommentRead[]) => JSX.Element | null
export type CommentAltViewFn = () => JSX.Element | null

export interface CommentContext {
  viewState: 'closed' | 'open' | 'full'
  accountId?: string
  channel?: string
  sourceChannel?: string
  title?: JSX.Element | string | null
  tags?: string[]
  targetIds?: string[]
  comments: CommentGroups
  commentReads?: CommentRead[]
  renderChannels?: CommentChannelsFn
  renderAltView?: CommentAltViewFn
  inChange: boolean
}

export interface CommentGroups {
  [channel: string]: Comment[]
}

export interface SetCommentChannelParams {
  accountId?: string
  channel?: string
  sourceChannel?: string
  title?: JSX.Element | string | null
  tags?: string[]
  targetIds?: string[]
  renderChannels?: CommentChannelsFn
  renderAltView?: CommentAltViewFn
}

export interface OpenCommentPanelParams {
  accountId?: string
  channel: string
  sourceChannel?: string
  title?: JSX.Element | string | null
  tags?: string[]
  targetIds?: string[]
  renderChannels?: CommentChannelsFn
  renderAltView?: CommentAltViewFn
  viewState?: 'open' | 'full'
}

export interface CommentPanelHook {
  viewState: 'closed' | 'open' | 'full'
  accountId?: string
  channel?: string
  sourceChannel?: string
  title?: JSX.Element | string | null
  tags?: string[]

  comments: CommentGroups
  addComments: (comments: Comment[]) => void
  renderChannels?: CommentChannelsFn
  renderAltView?: CommentAltViewFn

  commentReads?: CommentRead[]
  setCommentReads: (commentReads?: CommentRead[]) => void

  setChannel: (params: SetCommentChannelParams) => void
  openCommentPanel: (params: OpenCommentPanelParams) => void
  closeCommentPanel: () => void
  commentPanelEnterFullScreen: () => void
  commentPanelExitFullScreen: () => void
  inChange: boolean
}

export const useCommentContext = (): CommentPanelHook => {
  const [current, setCurrent] = useRecoilState(commentContextAtom)

  const setChannel = useCallback(
    ({ accountId, channel, sourceChannel, title, tags, targetIds, renderChannels, renderAltView }: SetCommentChannelParams) => {
      setCurrent((current) => {
        const ctx = {
          ...current,
          accountId,
          channel,
          sourceChannel,
          title,
          tags,
          targetIds,
          renderChannels,
          renderAltView
        }
        setTimeout(() => {
          setCurrent((current) => ({ ...current, inChange: false }))
        }, 500)
        return ctx
      })
    },
    [setCurrent]
  )

  const openCommentPanel = useCallback(
    ({ accountId, channel, sourceChannel, title, tags, targetIds, renderChannels, renderAltView, viewState }: OpenCommentPanelParams) => {
      setCurrent((current) => {
        const ctx: CommentContext = {
          ...current,
          accountId,
          channel,
          sourceChannel,
          title,
          tags,
          targetIds,
          renderChannels,
          renderAltView,
          viewState: viewState === 'full' ? 'full' : 'open',
          comments: { ...(current?.comments || {}) },
          inChange: true
        }
        const key = `${channel}${sourceChannel || ''}`
        if (!ctx.comments[key]) ctx.comments[key] = []

        setTimeout(() => {
          setCurrent((current) => ({ ...current, inChange: false }))
        }, 500)

        return ctx
      })
    },
    [setCurrent]
  )

  const closeCommentPanel = useCallback(() => {
    setCurrent((current) => ({ ...current, viewState: 'closed' }))
  }, [setCurrent])

  const commentPanelEnterFullScreen = useCallback(() => {
    setCurrent((current) => ({ ...current, viewState: 'full' }))
  }, [setCurrent])

  const commentPanelExitFullScreen = useCallback(() => {
    setCurrent((current) => ({ ...current, viewState: 'open' }))
  }, [setCurrent])

  const addComments = useCallback(
    (comments: Comment[]) => {
      setCurrent((current) => {
        const { title } = current
        const { renderChannels } = current
        const { renderAltView } = current

        const ctx = JSON.parse(JSON.stringify(current)) as CommentContext

        ctx.title = title
        ctx.renderChannels = renderChannels
        ctx.renderAltView = renderAltView

        comments.forEach((x) => {
          let key = `${x.channel}${x.sourceChannel || ''}`

          if (!ctx.comments[key]) ctx.comments[key] = []

          let idx = ctx.comments[key].findIndex((c) => c.id === x.id)
          if (idx < 0) ctx.comments[key].push(x)
          else ctx.comments[key][idx] = x

          ctx.comments[key] = ctx.comments[key].sort((a, b) => a.createdAt - b.createdAt)

          if (!x.sourceChannel) return

          key = `${x.sourceChannel}${x.channel || ''}`

          if (!ctx.comments[key]) ctx.comments[key] = []

          idx = ctx.comments[key].findIndex((c) => c.id === x.id)
          if (idx < 0) ctx.comments[key].push(x)
          else ctx.comments[key][idx] = x

          ctx.comments[key] = ctx.comments[key].sort((a, b) => a.createdAt - b.createdAt)
        })

        return ctx
      })
    },
    [setCurrent]
  )

  const setCommentReads = useCallback(
    (commentReads?: CommentRead[]) => {
      setCurrent((current) => ({ ...current, commentReads }))
    },
    [setCurrent]
  )

  return {
    viewState: current.viewState,
    accountId: current.accountId,
    channel: current.channel,
    sourceChannel: current.sourceChannel,
    tags: current.tags,
    title: current.title,
    comments: current.comments,
    addComments,
    renderChannels: current.renderChannels,
    renderAltView: current.renderAltView,
    commentReads: current.commentReads,
    setCommentReads,
    setChannel,
    openCommentPanel,
    closeCommentPanel,
    commentPanelEnterFullScreen,
    commentPanelExitFullScreen,
    inChange: current?.inChange === true
  }
}
