import { TFunction } from 'react-i18next'
import { DisciplineKey } from './dto/projectShare'

export const MAX_DRAWING_SIZE = 1024 * 1024 * 1000 // 1GB
export const DRAWING_TYPES = { 'application/pdf': [] }

export const MAX_DOCUMENT_SIZE = 1024 * 1024 * 1000 // 1GB

export const PHOTO_TYPES = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/webp': ['.webp']
}
export const MAX_PHOTO_SIZE = 1024 * 1024 * 100 // 100MB

/** Number of employee map. */
export const numberOfEmployeesItems = [
  { id: '1-5', label: '1 - 5' },
  { id: '5-10', label: '5 - 10' },
  { id: '10-20', label: '10 - 20' },
  { id: '20-50', label: '20 - 50' },
  { id: '50-100', label: '50 - 100' },
  { id: '100-250', label: '100 - 250' },
  { id: '250-500', label: '250 - 500' },
  { id: '500-1000', label: '500 - 1000' },
  { id: '1000-5000', label: '1000 - 5000' },
  { id: '5000+', label: '5000+' }
]

export interface DifficultyItem {
  id: string
  label: string
  multiplier: number
}
/**
 * Get discipline collection.
 * @param {TFunction} t - i18 translation function.
 */
export const getDisciplines = (t: TFunction): { id: string; discipline: string }[] => [
  {
    id: DisciplineKey.ProjectPrincipal,
    discipline: t(`General.Disciplines.${DisciplineKey.ProjectPrincipal}`)
  },
  {
    id: DisciplineKey.ProjectManagement,
    discipline: t(`General.Disciplines.${DisciplineKey.ProjectManagement}`)
  },
  {
    id: DisciplineKey.QaQc,
    discipline: t(`General.Disciplines.${DisciplineKey.QaQc}`)
  },
  {
    id: DisciplineKey.General,
    discipline: t(`General.Disciplines.${DisciplineKey.General}`)
  },
  {
    id: DisciplineKey.Mechanical,
    discipline: t(`General.Disciplines.${DisciplineKey.Mechanical}`)
  },
  {
    id: DisciplineKey.Electrical,
    discipline: t(`General.Disciplines.${DisciplineKey.Electrical}`)
  },
  {
    id: DisciplineKey.SiteCivil,
    discipline: t(`General.Disciplines.${DisciplineKey.SiteCivil}`)
  },
  {
    id: DisciplineKey.TechnicalAssistant,
    discipline: t(`General.Disciplines.${DisciplineKey.TechnicalAssistant}`)
  },
  {
    id: DisciplineKey.SubjectMaterExpert,
    discipline: t(`General.Disciplines.${DisciplineKey.SubjectMaterExpert}`)
  }
]

/**
 * Get discipline collection.
 * @param {TFunction} t - i18 translation function.
 */
export const getPageDisciplines = (t: TFunction): { id: string; discipline: string }[] => [
  {
    id: DisciplineKey.General,
    discipline: t(`General.Disciplines.${DisciplineKey.General}`)
  },
  {
    id: DisciplineKey.Mechanical,
    discipline: t(`General.Disciplines.${DisciplineKey.Mechanical}`)
  },
  {
    id: DisciplineKey.Electrical,
    discipline: t(`General.Disciplines.${DisciplineKey.Electrical}`)
  },
  {
    id: DisciplineKey.SiteCivil,
    discipline: t(`General.Disciplines.${DisciplineKey.SiteCivil}`)
  }
]

export const disciplinesWithPages = [DisciplineKey.General, DisciplineKey.Mechanical, DisciplineKey.Electrical, DisciplineKey.SiteCivil]
export const disciplinesWithoutPages = [DisciplineKey.ProjectPrincipal, DisciplineKey.ProjectManagement, DisciplineKey.QaQc, DisciplineKey.TechnicalAssistant, DisciplineKey.SubjectMaterExpert]

const theDifficultyItems: DifficultyItem[] = []
export const getDifficultyItems = (t: TFunction): DifficultyItem[] => {
  if (theDifficultyItems.length) return theDifficultyItems

  theDifficultyItems.push({ id: '0', label: t('General.Difficulties.0'), multiplier: 1 })
  theDifficultyItems.push({ id: '1', label: t('General.Difficulties.1'), multiplier: 1.2 })
  theDifficultyItems.push({ id: '2', label: t('General.Difficulties.2'), multiplier: 1.35 })
  theDifficultyItems.push({ id: '3', label: t('General.Difficulties.3'), multiplier: 1.5 })
  theDifficultyItems.push({ id: '4', label: t('General.Difficulties.4'), multiplier: 1.8 })
  return theDifficultyItems
}

export const fileToDataURL = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.onerror = () => reject(new Error('unable to build data url'))
    reader.readAsDataURL(file);
  })
