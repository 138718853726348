import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCurrentUserAccount, useEditProject, useMe } from 'services/src/state'
import moment from 'moment'

import { EstimateLabor, ProjectStatus } from 'services/src/dto/project'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faCheckCircle, faClock, faExclamationCircle, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import { Patch } from 'services/src/dto/common'
import { makeDashboardPath } from 'services/src/dom'
import { useSaving } from 'components/src/saving'
import { useAlert } from 'components/src/alerts'
import { Grid, GridColumn, GridContentRenderProps } from 'components/src/grid'
import ProjectsSvg from 'components/src/svg/Projects'
import { ExpertDeclineModal } from './ExpertDeclineModal'
import { ExpertAcceptModal } from './ExpertAcceptModal'
import { OfferItem } from './offerTypes'

interface OfferExp {
  [key: string]: moment.Duration | null
}

export const ExpertPhaseList: React.FC = () => {
  const { t } = useTranslation()
  const [me] = useMe()
  const [account] = useCurrentUserAccount()
  const [saving, setSaving] = useSaving()
  const { alertSuccess, alertDanger } = useAlert()
  const history = useHistory()
  const { project, patch } = useEditProject()

  const [pendingItems, setPendingItems] = useState<OfferItem[]>()
  const [acceptedItems, setAcceptedItems] = useState<OfferItem[]>()
  const [exp, setExp] = useState<OfferExp>({})

  useEffect(() => {
    if (!me) return

    const exp: OfferExp = {}
    let pendingItems: OfferItem[] = []

    const pendingPrjItem: OfferItem = {
      project,
      total: 0,
      adjTotal: 0
    }

    project.phases.forEach((ph) => {
      const phItem: OfferItem = {
        project,
        phase: ph,
        total: 0,
        adjTotal: 0,
        accepted: true
      }

      const offerItems: OfferItem[] = []
      ph.estimates!.filter((est) => est.labor?.find((l) => l.userId === me.id && l.status === ProjectStatus.FeeReady)).forEach((est) => {
        ;(est.labor?.filter((l) => l.userId === me.id) || []).forEach((l) => {
          const expires = l.statusHistory?.find((x) => x.newStatus === ProjectStatus.FeeReady)?.metadata?.expires
          exp[l.id!] = expires ? moment.duration(Math.max(0, moment(expires).valueOf() - moment().valueOf())) : null

          const offerItem: OfferItem = {
            project,
            phase: ph,
            estimate: est,
            labor: l,
            total: (l.hours || 0) * l.rate,
            adjTotal: 0,
            accepted: true,
            expires: expires ? moment(expires) : undefined,
            expired: !(!!exp[l.id!] && exp[l.id!]!.asMilliseconds() > 0)
          }
          offerItems.push(offerItem)

          phItem.total += offerItem.total
          if (!offerItem.expired) phItem.adjTotal += offerItem.total
        })
      })

      if (!offerItems.length) return

      pendingPrjItem.total += phItem.total
      pendingPrjItem.adjTotal += phItem.adjTotal

      pendingItems.push(phItem)
      pendingItems = [...pendingItems, ...offerItems]
    })
    if (pendingItems.length) pendingItems.push(pendingPrjItem)
    setPendingItems(pendingItems)
    setExp(exp)

    const acceptedPrjItem: OfferItem = {
      project,
      total: 0,
      adjTotal: 0
    }
    let acceptedItems: OfferItem[] = []

    project.phases.forEach((ph) => {
      const phItem: OfferItem = {
        project,
        phase: ph,
        total: 0,
        adjTotal: 0,
        accepted: true
      }

      const offerItems: OfferItem[] = []
      ph.estimates!.filter((est) =>
        est.labor?.find((l) => l.userId === me.id && [ProjectStatus.Approved, ProjectStatus.InProgress, ProjectStatus.Complete, ProjectStatus.Canceled].includes(l.status))
      ).forEach((est) => {
        ;(est.labor?.filter((l) => l.userId === me.id) || []).forEach((l) => {
          if (![ProjectStatus.Approved, ProjectStatus.InProgress, ProjectStatus.Complete, ProjectStatus.Canceled].includes(l.status)) return

          let sh = l.statusHistory.find((sh) => sh.newStatus === ProjectStatus.Approved)
          if (!sh) sh = l.statusHistory.find((sh) => sh.newStatus === ProjectStatus.InProgress)

          const offerItem: OfferItem = {
            project,
            phase: ph,
            estimate: est,
            labor: l,
            total: l.rate * (l.hours || 0),
            adjTotal: l.rate * (l.hours || 0),
            accepted: true,
            acceptedAt: sh ? moment(sh.createdAt) : moment()
          }
          offerItems.push(offerItem)

          phItem.total += offerItem.total
          phItem.adjTotal += offerItem.total
        })
      })

      if (!offerItems.length) return

      acceptedPrjItem.total += phItem.total
      acceptedPrjItem.adjTotal += phItem.adjTotal

      acceptedItems.push(phItem)
      acceptedItems = [...acceptedItems, ...offerItems]
    })
    if (acceptedItems.length) acceptedItems.push(acceptedPrjItem)
    setAcceptedItems(acceptedItems)
  }, [project, me, t, setPendingItems, setAcceptedItems, setExp])

  useEffect(() => {
    const i = setInterval(() => {
      const exp: OfferExp = {}

      pendingItems?.forEach(({ estimate, labor, expires }) => {
        if (!expires) return
        const id = labor?.id || estimate?.id
        if (!id) return
        exp[id] = expires ? moment.duration(Math.max(0, moment(expires).valueOf() - moment().valueOf())) : null
      })

      setExp(exp)
    }, 1000)

    return () => {
      clearInterval(i)
    }
  }, [exp])

  const [acceptModal, setAcceptModal] = useState(false)
  const [declineModal, setDeclineModal] = useState(false)

  const skills = useCallback((labor: EstimateLabor) => {
    if (!labor.skills?.length) return null
    if (labor.skills.length === 1) return <span style={{ marginLeft: 5 }}>({labor.skills[0]})</span>
    if (labor.skills.length === 2)
      return (
        <span style={{ marginLeft: 5 }}>
          ({labor.skills[0]} and {labor.skills[1]})
        </span>
      )
    const s = [...labor.skills]
    const last = s[s.length - 1]
    s.splice(s.length - 1, 1)
    return (
      <span style={{ marginLeft: 5 }}>
        ({s.join(', ')}, and {last})
      </span>
    )
  }, [])

  if (!pendingItems || !acceptedItems) return null

  return (
    <>
      <div className="ui-form-group edit-project-expert-phase-list">
        <div>
          {pendingItems.length > 0 && (
            <div style={{ marginTop: 40, marginBottom: 40 }} className="ui-frame ui-frame-bg">
              <div style={{ overflowX: 'auto' }}>
                <Grid rowData={pendingItems}>
                  <GridColumn
                    field="phAndDis"
                    name={
                      <>
                        <FontAwesomeIcon icon={faExclamationCircle} />
                        &nbsp;{t('Expert.Overview.PendingOffers')}
                      </>
                    }
                    render={({ data: { phase, estimate, labor } }: GridContentRenderProps<OfferItem>) => {
                      if (labor)
                        return (
                          <div style={{ paddingLeft: 20 }}>
                            {t(`General.Disciplines.${estimate!.discipline}`)}
                            {skills(labor)}
                          </div>
                        )

                      if (estimate) return <div style={{ paddingLeft: 20 }}>{t(`General.Disciplines.${estimate.discipline}`)}</div>

                      if (phase)
                        return (
                          <div>
                            <div className="ui-text-xs ui-text-muted ui-text-uppercase" style={{ fontSize: '0.65rem' }}>
                              {t('General.Phase')}
                            </div>
                            <div>
                              {t(`EditProject.${phase!.phaseType}`)}
                              {phase!.name ? ` (${phase!.name})` : ''}
                            </div>
                            <div className="ui-text-xs ui-text-muted ui-text-uppercase" style={{ fontSize: '0.65rem', paddingLeft: 20, height: 10, marginTop: 10 }}>
                              {t('Expert.PhaseList.DisciplineOrRole')}
                            </div>
                          </div>
                        )

                      return <div style={{ paddingTop: 20 }} />
                    }}
                    flex={1}
                    minWidth="250px"
                    width="100%"
                  />

                  <GridColumn
                    field="times"
                    name={t('Expert.PhaseList.Times')}
                    render={({ data: { phase, estimate, labor } }: GridContentRenderProps<OfferItem>) => {
                      if (labor?.id) {
                        const expires = exp && exp[labor.id] && exp[labor.id]!.asMilliseconds() > 0 ? exp[labor.id] : null

                        if (labor.status === ProjectStatus.FeeReady) {
                          return (
                            <div className="ui-flex ui-text-bold ui-text-xs">
                              {expires ? (
                                <>
                                  <div style={{ flex: 0.5, marginRight: 5, height: 25, lineHeight: '23px' }} className={`ui-text-right ${expires?.asHours() < 1 ? 'ui-danger' : 'ui-secondary'}`}>
                                    <FontAwesomeIcon icon={faClock} />
                                    &nbsp;{t('Expert.Overview.OfferExpiresIn')}
                                  </div>
                                  <div
                                    style={{ flex: 0.5, marginLeft: 5, height: 25, lineHeight: '23px' }}
                                    className={`ui-text-center ui-frame ui-frame-no-padding ui-flex ${expires?.asHours() < 1 ? 'ui-danger' : 'ui-secondary'}`}
                                  >
                                    <div style={{ width: 20, minWidth: 20 }}>{expires.hours() < 10 ? `0${expires.hours()}` : expires.hours()}</div>
                                    <div>:</div>
                                    <div style={{ width: 20, minWidth: 20 }}>{expires.minutes() < 10 ? `0${expires.minutes()}` : expires.minutes()}</div>
                                    <div>:</div>
                                    <div style={{ width: 20, minWidth: 20 }}>{expires.seconds() < 10 ? `0${expires.seconds()}` : expires.seconds()}</div>
                                  </div>
                                </>
                              ) : (
                                <div style={{ flex: 1, marginRight: 5, height: 25, lineHeight: '23px' }} className="ui-text-right ui-danger">
                                  <FontAwesomeIcon icon={faExclamationTriangle} />
                                  &nbsp;{t('Expert.Overview.OfferExpired')}
                                </div>
                              )}
                            </div>
                          )
                        }
                        return null
                      }
                      if (estimate) {
                        /* TODO Deal with this
                        const expires =
                          exp && exp[estimate.id] && exp[estimate.id]!.asMilliseconds() > 0 ? exp[estimate.id] : null;

                        if (estimate.status === ProjectStatus.FeeReady) {
                          return (
                            <div className="ui-flex ui-text-bold ui-text-xs">
                              {expires ? (
                                <>
                                  <div
                                    style={{ flex: 0.5, marginRight: 5, height: 25, lineHeight: '23px' }}
                                    className={`ui-text-right ${expires?.asHours() < 1 ? 'ui-danger' : 'ui-secondary'}`}
                                  >
                                    <FontAwesomeIcon icon={faClock} />
                                    &nbsp;{t('Expert.Overview.OfferExpiresIn')}
                                  </div>
                                  <div
                                    style={{ flex: 0.5, marginLeft: 5, height: 25, lineHeight: '23px' }}
                                    className={`ui-text-center ui-frame ui-frame-no-padding ui-flex ${
                                      expires?.asHours() < 1 ? 'ui-danger' : 'ui-secondary'
                                    }`}
                                  >
                                    <div style={{ width: 20, minWidth: 20 }}>
                                      {expires.hours() < 10 ? `0${expires.hours()}` : expires.hours()}
                                    </div>
                                    <div>:</div>
                                    <div style={{ width: 20, minWidth: 20 }}>
                                      {expires.minutes() < 10 ? `0${expires.minutes()}` : expires.minutes()}
                                    </div>
                                    <div>:</div>
                                    <div style={{ width: 20, minWidth: 20 }}>
                                      {expires.seconds() < 10 ? `0${expires.seconds()}` : expires.seconds()}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div
                                  style={{ flex: 1, marginRight: 5, height: 25, lineHeight: '23px' }}
                                  className="ui-text-right ui-danger"
                                >
                                  <FontAwesomeIcon icon={faExclamationTriangle} />
                                  &nbsp;{t('Expert.Overview.OfferExpired')}
                                </div>
                              )}
                            </div>
                          );
                        }
                        */
                        return null
                      }

                      if (phase)
                        return (
                          <div className="ui-flex">
                            <div style={{ flex: 0.5, marginRight: 5, height: 25, lineHeight: '25px' }} className="ui-text-center ui-frame ui-frame-no-padding">
                              {moment(phase!.submissionDate).format('ll')}
                            </div>
                            <div style={{ flex: 0.5, marginLeft: 5, height: 25, lineHeight: '25px' }} className="ui-text-center ui-frame ui-frame-no-padding">
                              {moment(phase!.deliveryDate).format('ll')}
                            </div>
                          </div>
                        )

                      return null
                    }}
                    minWidth="220px"
                    maxWidth="220px"
                    width="220px"
                  />

                  <GridColumn
                    field="offer"
                    name={t('Expert.PhaseList.Offer')}
                    render={({ data: { phase, estimate, expired, total, adjTotal } }: GridContentRenderProps<OfferItem>) => {
                      if (estimate)
                        return (
                          <div style={{ height: 25, lineHeight: '23px' }} className="ui-text-center ui-frame ui-frame-no-padding ui-text-bold">
                            <span className={expired ? 'ui-text-muted ui-text-line-through' : ''}>{t('General.Currency.Value', { value: total })}</span>
                          </div>
                        )

                      if (phase)
                        return (
                          <div style={{ height: 25, lineHeight: '23px' }} className="ui-text-center ui-frame ui-frame-no-padding ui-text-bold">
                            {t('General.Currency.Value', { value: adjTotal })}
                          </div>
                        )

                      return null
                    }}
                    minWidth="110px"
                    maxWidth="110px"
                    width="110px"
                  />
                </Grid>

                {(() => {
                  const last = pendingItems[pendingItems!.length - 1]

                  return (
                    <div className="ui-frame ui-flex-nowrap ui-flex" style={{ alignItems: 'center', marginTop: 20, minWidth: 620 }}>
                      <div style={{ flex: 0.33 }} className="ui-flex">
                        <button
                          type="button"
                          disabled={saving || last.adjTotal <= 0}
                          className="ui-btn ui-btn-danger ui-btn-solid"
                          style={{
                            width: 150,
                            height: 'auto',
                            lineHeight: 1.2,
                            padding: '15px 5px',
                            display: 'block',
                            marginRight: 20
                          }}
                          onClick={() => setDeclineModal(true)}
                        >
                          <div className="ui-flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <FontAwesomeIcon icon={faBan} />
                            <div style={{ marginLeft: 5 }}>{t('Expert.DeclineOffer')}</div>
                          </div>
                        </button>
                      </div>

                      <div style={{ flex: 0.34, justifyContent: 'center' }} className="ui-flex">
                        {last.adjTotal > 0 && (
                          <div className="ui-text-bold ui-text-center">
                            <div>{t('Expert.TotalOffer')}</div>
                            <div>{t('General.Currency.Value', { value: last.adjTotal })}</div>
                          </div>
                        )}
                      </div>

                      <div style={{ flex: 0.33, justifyContent: 'flex-end' }} className="ui-flex">
                        <button
                          type="button"
                          disabled={saving || last.adjTotal <= 0}
                          className="ui-btn ui-btn-secondary ui-btn-solid"
                          style={{ width: 150, height: 'auto', lineHeight: 1.2, padding: '15px 5px', display: 'block' }}
                          onClick={() => setAcceptModal(true)}
                        >
                          <div className="ui-flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <ProjectsSvg width={20} />
                            <div style={{ marginLeft: 5 }}>{t('Expert.AcceptOffer')}</div>
                          </div>
                        </button>
                      </div>
                    </div>
                  )
                })()}
              </div>
            </div>
          )}

          {acceptedItems.length > 0 && (
            <div style={{ marginTop: 40, marginBottom: 40 }} className="ui-frame ui-frame-bg">
              <div style={{ overflowX: 'auto' }}>
                <Grid rowData={acceptedItems}>
                  <GridColumn
                    field="phAndDis"
                    name={
                      <>
                        <FontAwesomeIcon icon={faCheckCircle} />
                        &nbsp;{t('Expert.Overview.ReadyOffers')}
                      </>
                    }
                    render={({ data: { phase, estimate } }: GridContentRenderProps<OfferItem>) => {
                      if (estimate) return <div style={{ paddingLeft: 20 }}>{t(`General.Disciplines.${estimate.discipline}`)}</div>

                      if (phase)
                        return (
                          <div>
                            <div className="ui-text-xs ui-text-muted ui-text-uppercase" style={{ fontSize: '0.65rem' }}>
                              {t('General.Phase')}
                            </div>
                            <div>
                              {t(`EditProject.${phase!.phaseType}`)}
                              {phase!.name ? ` (${phase!.name})` : ''}
                            </div>
                            <div className="ui-text-xs ui-text-muted ui-text-uppercase" style={{ fontSize: '0.65rem', paddingLeft: 20, height: 10, marginTop: 10 }}>
                              {t('Expert.PhaseList.DisciplineOrRole')}
                            </div>
                          </div>
                        )

                      return <div style={{ paddingTop: 20 }} />
                    }}
                    flex={1}
                    minWidth="250px"
                    width="100%"
                  />

                  <GridColumn
                    field="times"
                    name={t('Expert.PhaseList.Times')}
                    render={({ data: { phase, estimate } }: GridContentRenderProps<OfferItem>) => {
                      if (estimate) return null

                      if (phase)
                        return (
                          <div className="ui-flex">
                            <div style={{ flex: 0.5, marginRight: 5, height: 25, lineHeight: '25px' }} className="ui-text-center ui-frame ui-frame-no-padding">
                              {moment(phase!.submissionDate).format('ll')}
                            </div>
                            <div style={{ flex: 0.5, marginLeft: 5, height: 25, lineHeight: '25px' }} className="ui-text-center ui-frame ui-frame-no-padding">
                              {moment(phase!.deliveryDate).format('ll')}
                            </div>
                          </div>
                        )

                      return null
                    }}
                    minWidth="220px"
                    maxWidth="220px"
                    width="220px"
                  />

                  <GridColumn
                    field="acceptedAt"
                    name={t('Expert.PhaseList.AcceptedAt')}
                    render={({ data: { acceptedAt } }: GridContentRenderProps<OfferItem>) => {
                      if (!acceptedAt) return <div />
                      return (
                        <div className="ui-text-center ui-frame ui-frame-no-padding" style={{ height: 25, lineHeight: '25px' }}>
                          {acceptedAt.format('ll')}
                        </div>
                      )
                    }}
                    minWidth="110px"
                    maxWidth="110px"
                    width="110px"
                  />

                  <GridColumn
                    field="accepted"
                    name={t('Expert.PhaseList.Accepted')}
                    render={({ data: { phase, estimate, total, adjTotal } }: GridContentRenderProps<OfferItem>) => {
                      if (estimate)
                        return (
                          <div style={{ height: 25, lineHeight: '23px' }} className="ui-text-center ui-frame ui-frame-no-padding ui-text-bold">
                            {t('General.Currency.Value', { value: total })}
                          </div>
                        )

                      if (phase)
                        return (
                          <div style={{ height: 25, lineHeight: '23px' }} className="ui-text-center ui-frame ui-frame-no-padding ui-text-bold">
                            {t('General.Currency.Value', { value: adjTotal })}
                          </div>
                        )

                      return null
                    }}
                    minWidth="110px"
                    maxWidth="110px"
                    width="110px"
                  />
                </Grid>
              </div>

              {(() => {
                const last = acceptedItems[acceptedItems!.length - 1]

                return (
                  <div className="ui-frame ui-flex-nowrap ui-flex ui-text-lg" style={{ alignItems: 'center', marginTop: 20 }}>
                    <div style={{ flex: 0.5 }} className="ui-flex">
                      <div className="ui-text-bold">
                        <div>{t('Expert.PhaseList.Total')}</div>
                      </div>
                    </div>
                    <div style={{ flex: 0.5, justifyContent: 'flex-end' }} className="ui-flex ui-text-bold">
                      <div>{t('General.Currency.Value', { value: last.adjTotal })}</div>
                    </div>
                  </div>
                )
              })()}
            </div>
          )}
        </div>
      </div>

      {acceptModal && (
        <ExpertAcceptModal
          items={pendingItems}
          onClose={() => setAcceptModal(false)}
          onYes={() => {
            setAcceptModal(false)

            const p: Patch[] = []
            pendingItems
              .filter((item) => item.phase && !item.estimate)
              .forEach((phItem) => {
                if (!phItem.phase) return

                pendingItems
                  ?.filter((item) => item.estimate && item.phase!.id === phItem.phase!.id)
                  .forEach(({ estimate, labor, accepted, total, expired }) => {
                    if (expired || !estimate) return

                    if (labor) {
                      if (labor.status !== ProjectStatus.FeeReady) return

                      if (phItem.accepted && accepted) {
                        p.push({
                          propertyName: `Phases.${phItem.phase!.id}.Estimates.${estimate!.id}.Labor.${labor.id}.Status`,
                          propertyValue: {
                            status: phItem.phase!.status,
                            metadata: {
                              hourlyRate: labor.rate,
                              levelOfEffortHours: labor.hours || 0,
                              total: labor.rate * (labor.hours || 0),
                              reason: 'Expert accepted labor offer.'
                            }
                          }
                        })
                      } else {
                        p.push({
                          propertyName: `Phases.${phItem.phase!.id}.Estimates.${estimate!.id}.Labor.${labor.id}.Status`,
                          propertyValue: {
                            status: ProjectStatus.New,
                            metadata: {
                              declined: true,
                              hourlyRate: labor.rate,
                              levelOfEffortHours: labor.hours || 0,
                              total: labor.rate * (labor.hours || 0),
                              reason: 'Expert declined labor offer.'
                            }
                          }
                        })
                      }

                      return
                    }

                    const l = estimate.labor?.find((l) => l.userId === me?.id)
                    if (!l || l.status !== ProjectStatus.FeeReady) return

                    if (phItem.accepted && accepted) {
                      p.push({
                        propertyName: `Phases.${phItem.phase!.id}.Estimates.${estimate!.id}.Status`,
                        propertyValue: {
                          status: [ProjectStatus.InProgress].includes(phItem.phase!.status!) ? ProjectStatus.InProgress : ProjectStatus.Approved,
                          metadata: {
                            hourlyRate: estimate!.baseRate,
                            levelOfEffortHours: estimate!.levelOfEffortHours,
                            total
                          }
                        }
                      })
                    } else {
                      p.push({
                        propertyName: `Phases.${phItem.phase!.id}.Estimates.${estimate!.id}.Status`,
                        propertyValue: {
                          status: ProjectStatus.New,
                          metadata: {
                            hourlyRate: estimate!.baseRate,
                            levelOfEffortHours: estimate!.levelOfEffortHours,
                            total
                          }
                        }
                      })
                    }
                  })
              })

            if (!p.length) return

            setSaving(true)
            patch(p, true)
              .then(() => {
                alertSuccess({
                  title: t('Expert.AcceptModal.ApprovedTitle'),
                  message: t('Expert.AcceptModal.Approved')
                })
              })
              .catch((error) => {
                alertDanger({
                  title: t('Expert.AcceptModal.Errors.ApprovedTitle'),
                  message: t('Expert.AcceptModal.Errors.Approved'),
                  error
                })
              })
              .finally(() => setSaving(false))
          }}
        />
      )}

      {declineModal && (
        <ExpertDeclineModal
          items={pendingItems}
          onClose={() => setDeclineModal(false)}
          onYes={(reason) => {
            setDeclineModal(false)

            const p: Patch[] = []
            pendingItems
              .filter((item) => item.phase && !item.estimate)
              .forEach((phItem) => {
                if (!phItem.phase) return

                pendingItems
                  ?.filter((item) => item.estimate && item.labor && item.phase!.id === phItem.phase!.id)
                  .forEach((item) => {
                    if (!item.estimate) return

                    const l = item.estimate.labor?.find((l) => l.userId === me?.id)
                    if (!l || l.status !== ProjectStatus.FeeReady) return

                    p.push({
                      propertyName: `Phases.${phItem.phase!.id}.Estimates.${item.estimate!.id}.Labor.${item.labor!.id}.Status`,
                      propertyValue: {
                        status: ProjectStatus.Rejected,
                        metadata: {
                          rate: item.labor!.rate,
                          hours: item.labor!.hours,
                          cost: item.labor!.cost,
                          declined: true,
                          reason
                        }
                      }
                    })
                  })
              })

            if (!p.length) return

            setSaving(true)
            patch(p, true)
              .then(() => {
                alertSuccess({
                  title: t('Expert.DeclineModal.DeclinedTitle'),
                  message: t('Expert.DeclineModal.Declined')
                })
                history.replace(makeDashboardPath(account?.id, 'projects'))
              })
              .catch((error) => {
                alertDanger({
                  title: t('Expert.DeclineModal.Errors.DeclinedTitle'),
                  message: t('Expert.DeclineModal.Errors.Declined'),
                  error
                })
              })
              .finally(() => setSaving(false))
          }}
        />
      )}
    </>
  )
}
