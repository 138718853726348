import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectStatus } from 'services/src/dto/projectShare'
import { FeeGridViewModel, FeeGridViewModelEstimate } from './common'

export const FeeGridTotals: React.FC<{
  vm: FeeGridViewModel
  estimate: FeeGridViewModelEstimate
}> = ({ vm, estimate }) => {
  const { t } = useTranslation()

  /*
  const { negDiff } = useMemo(() => {
    const laborTotal = estimate.labor.reduce((total, l) => total + l.status === ProjectStatus.Rejected ? 0 : l.cost, 0);
    const subtotal = estimate.estimate.negotiatedTotals?.subtotal || estimate.estimate.manualTotals?.subtotal || 0;
    const margin = (subtotal > 0 && laborTotal > 0) ? (subtotal - laborTotal) / subtotal : null;

    let marginClass = '';
    if (margin !== null) {
      if (margin <= 0.25) marginClass = 'ui-danger ui-text-bold';
      else if (margin > 0.25 && margin <= 0.5) marginClass = 'ui-warn ui-text-bold';
      else if (margin > 0.5) marginClass = 'ui-success ui-text-bold';
    }

    let negDiff = null;
    if (estimate.estimate.negotiatedTotals && estimate.estimate.manualTotals?.total)
      negDiff = (estimate.estimate.negotiatedTotals.total - estimate.estimate.manualTotals.total) / estimate.estimate.manualTotals.total;

    return {margin, marginClass, negDiff};
  }, [estimate]);
  */

  if (vm.variant === 'assign') return null

  return (
    <>
      <div className="ui-text-sm" style={{ marginLeft: 20 }}>
        <div className="ui-flex ui-text-center ui-text-xxs ui-text-uppercase ui-text-bold ui-text-muted" style={{ marginBottom: 5 }}>
          <div style={{ width: 100 }}>{t('EditProject.Fees.Subtotal')}</div>
          <div style={{ marginLeft: 10, width: 100 }}>{t('EditProject.Fees.OtherFees.Title')}</div>
          <div style={{ marginLeft: 10, width: 100 }}>{t('EditProject.Fees.Total')}</div>
          {estimate.estimate.negotiatedTotals && (
            <>
              <div style={{ marginLeft: 20, width: 150 }} className="ui-danger">
                {estimate.phase.status === ProjectStatus.FeeCounter ? t('EditProject.Fees.CounterOffer') : t('EditProject.Fees.NegotiatedFee')}
              </div>
            </>
          )}
        </div>

        <div className="ui-flex">
          <div className="ui-flex ui-frame ui-frame-no-padding ui-text-center" style={{ height: 25, width: 100, lineHeight: '23px' }}>
            {estimate.hours > 0 ? t('General.Currency.Value', { value: estimate.subtotal }) : '--'}
          </div>

          <div className="ui-flex ui-frame ui-frame-no-padding ui-text-center" style={{ marginLeft: 10, height: 25, width: 100, lineHeight: '23px' }}>
            {estimate.hours > 0 ? t('General.Currency.Value', { value: estimate.otherFeeTotal }) : '--'}
          </div>

          <div className="ui-flex ui-frame ui-frame-no-padding ui-text-center ui-text-bold" style={{ marginLeft: 10, height: 25, width: 100, lineHeight: '23px' }}>
            {estimate.hours > 0 ? t('General.Currency.Value', { value: estimate.total }) : '--'}
          </div>

          {estimate.estimate.negotiatedTotals && (
            <>
              <div className="ui-flex ui-frame ui-frame-no-padding ui-text-center ui-text-bold" style={{ marginLeft: 20, height: 25, width: 150, lineHeight: '23px' }}>
                <span className={estimate.estimate.negotiatedTotals.total > 0 ? 'ui-danger' : undefined}>
                  {estimate.estimate.negotiatedTotals.total > 0 ? t('General.Currency.Value', { value: estimate.estimate.negotiatedTotals.total }) : '--'}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
