import React, { useEffect, useState } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'

import { verifyInvitation } from 'services/src/api'
import { aadConfig, psa } from 'services/src/aad'

import './style.scss'
import { Loading } from 'components/src/loading'
import { ResourceUserRole } from 'services/src/dto/account'
import { Layout } from '../layout/public'

export const InviteVerify: React.FC = () => {
  const { t } = useTranslation()
  const [key] = useQueryParam('key', StringParam)

  const [error, setError] = useState<string | undefined>(!key ? 'NoKey' : undefined)

  useEffect(() => {
    if (!key) return

    verifyInvitation(key)
      .then((i) => {
        const userType =
          // eslint-disable-next-line no-nested-ternary
          i.providerRole === ResourceUserRole.Contributor ? 'expert' : i.providerRole ? 'provider' : 'client'

        const fallback = `${process.env.REACT_APP_CC_APP_SITE}`

        psa()
          .loginRedirect({
            prompt: 'login',
            scopes: aadConfig().scopes,
            redirectStartPage: `/invite/complete?key=${encodeURIComponent(key)}`,
            state: JSON.stringify({
              userType,
              invite: true,
              canCreate: true,
              email: i.emailAddress,
              givenName: i.givenName,
              familyName: i.familyName,
              phone: i.metadata?.mobilePhone,
              theme: localStorage.getItem('theme') || 'light',
              fallback
            })
          })
          .then(() => {})
      })
      .catch((err) => {
        if (err.message.includes('404')) setError('NotFound')
        else if (err.message.includes('400')) setError('BadRequest')
        else setError('Unknown')
      })
  }, [key])

  if (error) {
    return (
      <Layout className="invite">
        <div className="error-code">
          <FontAwesomeIcon icon={faExclamationTriangle} /> {t(`Invite.Errors.${error}Title`)}
        </div>
        <div className="error-text ui-text-center">{t(`Invite.Errors.${error}`)}</div>
        <div className="ui-text-center ui-text-lg">
          <a href={process.env.REACT_APP_CC_SITE} className="ui-info">
            <FontAwesomeIcon icon={faArrowLeft} /> {t('General.BackToCC')}
          </a>
        </div>
      </Layout>
    )
  }

  return <Loading />
}
