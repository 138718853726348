import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCheckCircle, faCircle, faEdit, faPencil, faPlusCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import { DatePicker, DatePickerMarker } from 'components/src/datePicker'
import { PROJECT_PHASE_MIN_DAYS } from 'components/src/constants'
import { PhaseVariations, ProjectStatus } from 'services/src/dto/project'
import { StatusBadge } from 'components/src/statusBadge'

export interface ProjectDateProps {
  status?: ProjectStatus
  start?: string
  end?: string
  min?: string
  max?: string
  type: string
  name?: string
  description?: string
  onSelectDate: (date: Moment, which: string) => void
  onClearDate: () => void
  onEditDate?: () => void
  isAvailable?: boolean
  isReady?: boolean
  markers: DatePickerMarker[]
  className?: string
  canEdit: boolean
  variations?: PhaseVariations[]
  onVariationsChange?: (variations: PhaseVariations[]) => void
  disabled?: boolean
  startedAt?: Date
  completedAt?: Date
}

export const ProjectDate: React.FC<ProjectDateProps> = ({
  status,
  start,
  end,
  min,
  max,
  name,
  description,
  type,
  onSelectDate,
  onClearDate,
  onEditDate,
  isAvailable,
  isReady,
  markers,
  className,
  canEdit,
  variations,
  onVariationsChange,
  disabled,
  startedAt,
  completedAt
}) => {
  const { t } = useTranslation()

  const itemRef = useRef<HTMLDivElement>(null)
  const startRef = useRef<HTMLDivElement>(null)
  const endRef = useRef<HTMLDivElement>(null)
  const pickerRef = useRef<HTMLDivElement>(null)

  const [pickerOpen, setPickerOpen] = useState<string>()

  useEffect(() => {
    const resize = () => setPickerOpen(undefined)
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  const readyDescription = useMemo(() => {
    if (!description) return null
    return description.split('\n').map((l, idx) => <p key={idx}>{l}</p>)
  }, [description])

  useLayoutEffect(() => {
    if (pickerOpen === 'start') {
      if (!startRef.current || !pickerRef.current) return

      const rect = startRef.current.getBoundingClientRect()
      if (pickerRef.current.clientHeight + rect.y > window.innerHeight) {
        pickerRef.current.style.top = `${rect.y - pickerRef.current.clientHeight - 6}px`
      } else {
        pickerRef.current.style.top = `${rect.y + rect.height - 2}px`
      }
      pickerRef.current.style.left = `${Math.max(10, rect.x - 90)}px`
      setTimeout(() => pickerRef.current?.focus())
    } else if (pickerOpen === 'end') {
      if (!endRef.current || !pickerRef.current) return

      const rect = endRef.current.getBoundingClientRect()
      if (pickerRef.current.clientHeight + rect.y > window.innerHeight) {
        pickerRef.current.style.top = `${rect.y - pickerRef.current.clientHeight - 6}px`
      } else {
        pickerRef.current.style.top = `${rect.y + rect.height - 2}px`
      }
      pickerRef.current.style.left = `${Math.max(10, rect.x - 90)}px`
      setTimeout(() => pickerRef.current?.focus())
    }
  }, [pickerOpen])

  return (
    <div className={`project-timeline-date-item${!isAvailable || !isReady || disabled ? ' disabled' : ''}${start && end ? ' selected' : ''} ${className || ''}`}>
      <div className={`the-item${pickerOpen ? ' has-focus' : ''}`} ref={itemRef} role="button" tabIndex={0}>
        <div className="the-item-header">
          <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center' }}>
            <FontAwesomeIcon icon={start && end ? faCheckCircle : faCircle} className={start && end ? 'ui-success' : 'ui-text-muted'} />
            <div style={{ marginLeft: 5 }}>{type}</div>
            {start && end && <div style={{ marginLeft: 5 }}>({Math.floor(moment.duration(moment(end).valueOf() - moment(start).valueOf()).asDays())} days)</div>}
          </div>

          <div style={{ marginLeft: 'auto' }} />

          {canEdit && start && end && (
            <div className="ui-text-xs ui-flex ui-flex-nowrap project-timeline-date-item-btn">
              <button
                type="button"
                disabled={disabled}
                tabIndex={-1}
                className="ui-btn ui-btn-primary ui-btn-xs ui-btn-solid"
                style={{ height: 22 }}
                onClick={() => {
                  if (disabled || !canEdit) return
                  if (onEditDate) onEditDate()
                }}
              >
                <FontAwesomeIcon icon={faPencil} />
                &nbsp;{t('General.Edit')}
              </button>

              {![ProjectStatus.Approved, ProjectStatus.InProgress, ProjectStatus.Complete, ProjectStatus.Canceled].includes(status!) && (
                <button
                  type="button"
                  disabled={disabled}
                  tabIndex={-1}
                  className="ui-btn ui-btn-danger ui-btn-xs ui-btn-solid"
                  style={{ marginLeft: 5, height: 22 }}
                  onClick={() => {
                    if (disabled || !canEdit) return
                    onClearDate()
                  }}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                  &nbsp;{t('General.Clear')}
                </button>
              )}
            </div>
          )}

          <div style={{ marginLeft: 10 }}>{status && <StatusBadge label={t(`Projects.ClientStatus.${status}`)} type={status} style={{ marginLeft: 10, padding: '4px 10px', maxHeight: 22 }} />}</div>
        </div>

        <div className="the-item-main ui-row" style={{ margin: 0 }}>
          <div className="ui-col-12 ui-col-md-5 the-item-dates">
            <div>
              <label className="ui-text-sm ui-text-muted">{t('General.SubmissionDate')}</label>
              <div
                className={`start${pickerOpen === 'start' ? ' active' : ''}${!disabled && canEdit ? '' : ' ui-disabled'}`}
                role="button"
                tabIndex={0}
                ref={startRef}
                onKeyDown={() => {}}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (!canEdit || disabled) return
                  if (isAvailable && isReady) setPickerOpen('start')
                }}
              >
                <FontAwesomeIcon icon={faCalendar} className="ui-info" />
                {start && <span style={{ marginLeft: 5, marginRight: 7 }}>{moment(start).format('ll')}</span>}
                {!start && <span style={{ marginLeft: 5, marginRight: 7 }}>{t('General.SubmissionDate')}</span>}
                {!disabled && canEdit && <FontAwesomeIcon icon={faEdit} className="edit-icon ui-primary" />}
              </div>
            </div>

            <div style={{ marginTop: 15 }}>
              <label className="ui-text-sm ui-text-muted">{t('General.DeliveryDate')}</label>
              <div
                className={`end${pickerOpen === 'end' ? ' active' : ''}${!disabled && canEdit ? '' : ' ui-disabled'}`}
                tabIndex={0}
                role="button"
                ref={endRef}
                onKeyDown={() => {}}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (!canEdit || disabled) return
                  if (isAvailable && isReady) setPickerOpen('end')
                }}
              >
                <FontAwesomeIcon icon={faCalendar} className="ui-danger" />
                {end && <span style={{ marginLeft: 5, marginRight: 7 }}>{moment(end).format('ll')}</span>}
                {!end && <span style={{ marginLeft: 5, marginRight: 7 }}>{t('General.DeliveryDate')}</span>}
                {!disabled && canEdit && <FontAwesomeIcon icon={faEdit} className="edit-icon ui-primary" />}
              </div>
            </div>

            {variations &&
              onVariationsChange &&
              // eslint-disable-next-line no-nested-ternary
              (variations.length <= 0 ? (
                canEdit && (
                  <div style={{ marginTop: 40 }}>
                    <button
                      type="button"
                      className="ui-btn ui-btn-info ui-btn-solid ui-btn-solid ui-btn-sm"
                      disabled={!canEdit || disabled}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        onVariationsChange([{ description: '' }])
                        setTimeout(() => itemRef.current?.focus())
                      }}
                    >
                      <FontAwesomeIcon icon={faPlusCircle} />
                      <span style={{ marginLeft: 5 }}>{t('NewProject.Timeline.AddPhaseVariation')}</span>
                    </button>
                  </div>
                )
              ) : canEdit ? (
                <div style={{ marginTop: 40 }}>
                  {variations.map((v, idx) => (
                    <div key={idx}>
                      <label htmlFor={`var-${idx}`}>
                        <span>
                          {t('NewProject.Timeline.DescribePhaseVariation')} <span className="ui-text-muted">{t('General.Optional')}</span>
                        </span>
                      </label>
                      <textarea
                        name={`var-${idx}`}
                        disabled={!canEdit || disabled}
                        className="ui-text-sm"
                        style={{ margin: 0 }}
                        value={v.description}
                        placeholder="Variation details..."
                        rows={2}
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                        }}
                        onChange={(v) => {
                          const vx = JSON.parse(JSON.stringify(variations))
                          vx[idx].description = v.currentTarget.value
                          onVariationsChange(vx)
                        }}
                      />

                      {!canEdit && <div style={{ height: 20 }} />}

                      {canEdit && (
                        <div style={{ margin: '5px 0 20px 0' }}>
                          <button
                            type="button"
                            className="ui-btn ui-btn-danger ui-btn-solid ui-btn-solid ui-btn-sm"
                            disabled={!canEdit || disabled}
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()

                              const vx = [...variations]
                              vx.splice(idx, 1)
                              onVariationsChange(vx)
                              setTimeout(() => itemRef.current?.focus())
                            }}
                          >
                            <FontAwesomeIcon icon={faTimesCircle} />
                            <span style={{ marginLeft: 5 }}>{t('NewProject.Timeline.RemovePhaseVariation')}</span>
                          </button>
                        </div>
                      )}

                      {canEdit && variations.length < 3 && idx === variations.length - 1 && (
                        <div style={{ margin: '5px 0 20px 0' }}>
                          <button
                            type="button"
                            className="ui-btn ui-btn-info ui-btn-solid ui-btn-solid ui-btn-sm"
                            disabled={!canEdit || disabled}
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()

                              if (!variations) return
                              const vx = [...variations, { description: '' }]
                              onVariationsChange(vx)
                              setTimeout(() => itemRef.current?.focus())
                            }}
                          >
                            <FontAwesomeIcon icon={faPlusCircle} />
                            <span style={{ marginLeft: 5 }}>{t('NewProject.Timeline.AddAnotherPhaseVariation')}</span>
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div style={{ padding: '0 40px 20px 40px' }}>
                  <div>{t('EditProject.Timeline.VariationCount', { count: variations.length })}</div>
                  {variations
                    .filter((v) => !!v.description)
                    .map((v, idx) => (
                      <div key={idx} className="ui-flex ui-flex-nowrap ui-text-sm" style={{ marginTop: 5 }}>
                        <div className="ui-frame ui-text-center" style={{ padding: 0, width: 20, height: 24, lineHeight: '22px' }}>
                          {idx + 1}
                        </div>
                        <div style={{ flex: 1, marginTop: 2, marginLeft: 10 }}>{v.description}</div>
                      </div>
                    ))}
                </div>
              ))}
          </div>

          <div className="ui-col-12 ui-col-md-7 the-item-opt ui-form-group">
            {startedAt && (
              <div className="ui-flex ui-text-sm option-desc" style={{ justifyContent: 'flex-end' }}>
                <div>{t('EditProject.Started', { date: moment(startedAt).toDate(), format: 'll' })}</div>
                {completedAt && <div style={{ marginLeft: 20 }}>{t('EditProject.Started', { date: moment(completedAt).toDate() })}</div>}
              </div>
            )}
            {name && <div className="option-name">{name}</div>}
            {readyDescription && <div className="option-desc">{readyDescription}</div>}
          </div>
        </div>
      </div>

      {pickerOpen && (
        <DatePicker
          className={pickerOpen}
          title={pickerOpen === 'end' ? t('General.DeliveryDate') : t('General.SubmissionDate')}
          start={start}
          end={end}
          min={pickerOpen === 'end' ? moment(min).add(PROJECT_PHASE_MIN_DAYS, 'days').format() : min}
          max={max}
          markers={markers}
          noHighlight={false}
          maxMonths={1}
          selectRange={false}
          initDate={pickerOpen === 'end' ? end : start}
          onClose={() => setPickerOpen(undefined)}
          onSelectDate={(d) => {
            onSelectDate(d, pickerOpen)
            setPickerOpen(undefined)
          }}
          ref={pickerRef}
        />
      )}
    </div>
  )
}
