import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpFromBracket, faExclamationTriangle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import axios, { Canceler } from 'axios'

import { FileList } from 'components/src/fileList'
import { Content, ProjectContentCategory } from 'services/src/dto/content'
import { DrawingList } from 'components/src/drawingList'
import { DrawingView } from 'components/src/drawingView'
import { Dropzone } from 'components/src/dropzone'
import { deleteFile, uploadFile, UploadFileEvent } from 'services/src/api'
import { useAlert } from 'components/src/alerts'
import { ConfirmModal } from 'components/src/modal/ConfirmModal'
import { PhaseEx, useCurrentUserAccount, useEditProject, useProjectFilesWs, useRights, UserType } from 'services/src/state'
import { DRAWING_TYPES, getPageDisciplines, MAX_DOCUMENT_SIZE, MAX_DRAWING_SIZE, MAX_PHOTO_SIZE, PHOTO_TYPES } from 'services/src/common'
import { ProjectStatus } from 'services/src/dto/project'
import { DocumentView } from 'components/src/documentView'
import { useSaving } from 'components/src/saving'
import { useMsalAccessToken } from 'services/src/aad'
import { TabBar } from 'components/src/tabs'
import DrawingsIcon from 'components/src/svg/DrawingsIcon'
import DocumentsIcon from 'components/src/svg/DocumentsIcon'
import PhotosIcon from 'components/src/svg/PhotosIcon'
import { Uploading } from '../common/Uploading'

const DisciplineFilter: React.FC<{
  selectedDisciplines?: string[]
  onChangeDisciplines: (disciplines?: string[]) => void
  disabled?: boolean
}> = ({ selectedDisciplines, onChangeDisciplines, disabled }) => {
  const { t } = useTranslation()
  const rights = useRights()

  const disciplines = useMemo<{ id: string; discipline: string; isSpecial?: boolean }[]>(() => {
    const items =
      rights?.userType === UserType.provider
        ? [
            {
              id: '$noDiscipline$',
              discipline: t('General.Disciplines.None')
            },
            {
              id: '$danger$',
              discipline: '< 50%'
            },
            {
              id: '$warn$',
              discipline: '< 75%'
            },
            {
              id: '$-$',
              discipline: ''
            }
          ]
        : []
    return [...items, ...getPageDisciplines(t)]
  }, [t, rights])

  return (
    <div className="drawing-files-filter">
      {disciplines.map((d) => {
        if (d.id === '$-$') return <div key={d.id} className="divider" style={{ marginLeft: 10, marginRight: 15 }} />

        return (
          <button
            type="button"
            key={d.id}
            disabled={disabled}
            style={{ marginRight: 10 }}
            className={`ui-btn ui-btn-xs${selectedDisciplines?.includes(d.id) ? ' ui-btn-secondary ui-btn-solid' : ''}`}
            onClick={() => {
              const sd = [...(selectedDisciplines || [])]
              const idx = sd.indexOf(d.id)
              if (idx >= 0) sd.splice(idx, 1)
              else sd.push(d.id)
              onChangeDisciplines(sd)
            }}
          >
            {d.discipline}
          </button>
        )
      })}

      <button
        type="button"
        disabled={disabled}
        style={{ marginLeft: 'auto' }}
        className={`ui-btn ui-btn-xs ${selectedDisciplines?.length ? ' ui-btn-primary ui-btn-solid' : ''}`}
        onClick={() => onChangeDisciplines(undefined)}
      >
        <FontAwesomeIcon icon={faTimesCircle} />
        <span style={{ marginLeft: 5 }}>{t('General.ClearFilter')}</span>
      </button>
    </div>
  )
}

export const PhaseFiles: React.FC<{
  phaseId: string
}> = ({ phaseId }) => {
  const { t } = useTranslation()
  const { url, path } = useRouteMatch()
  const { pathname } = useLocation()
  const history = useHistory()
  const { alertDanger, alertSuccess } = useAlert()
  const rights = useRights()
  const [account] = useCurrentUserAccount()
  const [saving, setSaving] = useSaving()
  const accessToken = useMsalAccessToken()

  const { project, patch: patchProject } = useEditProject()

  const currentCat = useMemo(() => {
    if (pathname.includes('/drawings')) return ProjectContentCategory.Drawing
    if (pathname.includes('/photos')) return ProjectContentCategory.Picture
    return ProjectContentCategory.Document
  }, [pathname])

  const { phase, phaseIds } = useMemo<{ phase: PhaseEx; phaseIds: string[] }>(() => {
    const phase = project.phases.find((x) => x.id === phaseId)!
    return { phase, phaseIds: [phase.id!] }
  }, [project, phaseId])

  const canUpload = useMemo(() => project.canEdit || (![ProjectStatus.Complete, ProjectStatus.Canceled].includes(phase.status!) && rights?.canUploadFile), [project, phase, rights])

  const { category, allFiles, files: projectFiles, inFetch, patch: patchContent } = useProjectFilesWs(project, phaseIds, currentCat)

  const readyFiles = useMemo(() => projectFiles?.filter((f) => f.category === currentCat), [projectFiles, currentCat])

  const [files, setFiles] = useState<File[]>()
  const [currentFileIndex, setCurrentFileIndex] = useState<number>(-1)
  const [currentProgress, setCurrentProgress] = useState<number>(0)
  const [currentCategory, setCurrentCategory] = useState<string>('')
  const [currentPhaseId, setCurrentPhaseId] = useState<string>('')

  const canceler = useRef<Canceler>()

  const onProgress = useCallback(
    (e: UploadFileEvent) => {
      if (e.error) {
        canceler.current = undefined
        setCurrentFileIndex(-1)
        setFiles(undefined)
        setCurrentProgress(0)

        if (e.error.code !== 'ERR_CANCELED') {
          alertDanger({
            title: t('General.Errors.FileUploadTitle'),
            message: t('General.Errors.FileUpload'),
            error: e.error
          })
        }
        return
      }

      if (!files) return

      if (e.state === 'cancel') {
        canceler.current = undefined
        setFiles(undefined)
        setCurrentProgress(0)
        return
      }

      if (e.state === 'complete') {
        let nextIndex = currentFileIndex + 1
        if (nextIndex >= files.length) nextIndex = -1
        setCurrentFileIndex(nextIndex)
        if (nextIndex < 0) {
          canceler.current = undefined
          setFiles(undefined)
          setCurrentProgress(0)
        }
        return
      }

      setCurrentProgress(e.progress)
    },
    [canceler, currentFileIndex, setCurrentFileIndex, files, setFiles, setCurrentProgress, alertDanger]
  )

  useEffect(() => {
    if (currentFileIndex < 0 || !files || !project?.id) return

    // Are we done?
    if (currentFileIndex >= files.length) {
      canceler.current = undefined
      setCurrentFileIndex(-1)
      setFiles(undefined)
      setCurrentProgress(0)
      return
    }

    setCurrentProgress(0)
    uploadFile(
      project.account?.id || '',
      project.id,
      currentCategory,
      currentPhaseId,
      files[currentFileIndex],
      onProgress,
      new axios.CancelToken((c: Canceler) => {
        canceler.current = c
      })
    ).catch((error) => {
      canceler.current = undefined
      setCurrentFileIndex(-1)
      setFiles(undefined)
      setCurrentProgress(0)
      alertDanger({
        title: t('General.Errors.FileUploadTitle'),
        message: t('General.Errors.FileUpload'),
        error
      })
    })
  }, [currentFileIndex, setCurrentFileIndex, setFiles, setCurrentProgress, alertDanger])

  const handleDrop = useCallback(
    (category: string, acceptedFiles: File[]) => {
      if (!canUpload || !phase?.id) return

      setFiles(acceptedFiles)
      setCurrentCategory(category)
      setCurrentProgress(0)
      setCurrentPhaseId(phase.id)

      setTimeout(() => setCurrentFileIndex(0))
    },
    [project, phase, setFiles, setCurrentCategory, setCurrentProgress, setCurrentPhaseId, setCurrentFileIndex]
  )

  const [confirmDeleteDrawing, setConfirmDeleteDrawing] = useState<Content>()

  const [showDocumentFileDialog, setShowDocumentFileDialog] = useState(false)
  const [confirmDeleteDocument, setConfirmDeleteDocument] = useState<Content>()

  const [showPhotoFileDialog, setShowPhotoFileDialog] = useState(false)
  const [confirmDeletePhoto, setConfirmDeletePhoto] = useState<Content>()

  const dirty = useRef(false)
  useEffect(
    () => () => {
      if (dirty.current && account?.id && project.id) {
        patchProject([
          {
            propertyName: 'UpdatedAt',
            propertyValue: new Date()
          }
        ]).then(() => {})
      }
      dirty.current = false
    },
    [dirty, account, project, patchProject]
  )

  const [selectedDisciplines, setSelectedDisciplines] = useState<string[] | undefined>(() => {
    const f = localStorage.getItem('disciplineFilter')
    if (!f) return undefined
    return JSON.parse(f)
  })

  const [wrapperHeight, setWrapperHeight] = useState(window.innerHeight - 285)
  const resize = useCallback(() => setWrapperHeight(window.innerHeight - 285), [setWrapperHeight])

  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  const tabs = useMemo(
    () => [
      {
        label: (
          <div className="ui-flex" style={{ alignItems: 'center' }}>
            <DrawingsIcon style={{ width: 15, marginRight: 5 }} />
            {t('EditProject.Drawings')}
          </div>
        ),
        selected: pathname.includes('/drawings'),
        to: `${url}/drawings`
      },
      {
        label: (
          <div className="ui-flex" style={{ alignItems: 'center' }}>
            <PhotosIcon style={{ width: 17, marginRight: 3 }} />
            {t('EditProject.Photos')}
          </div>
        ),
        selected: pathname.includes('/photos'),
        to: `${url}/photos`
      },
      {
        label: (
          <div className="ui-flex" style={{ alignItems: 'center' }}>
            <DocumentsIcon style={{ width: 17, marginRight: 3 }} />
            {t('EditProject.Documents')}
          </div>
        ),
        selected: pathname.includes('/documents'),
        to: `${url}/documents`
      }
    ],
    [pathname, url, t]
  )

  const instructions = useMemo(() => {
    if (pathname.includes('/drawings')) return t('EditProject.Instructions.Drawings')
    if (pathname.includes('/documents')) return t('EditProject.Instructions.Documents')
    if (pathname.includes('/photos')) return t('EditProject.Instructions.Photos')
    return undefined
  }, [pathname, t])

  return (
    <>
      <div className="edit-project-files">
        <TabBar tabs={tabs} style={{ justifyContent: 'center', height: 30 }} onChange={() => {}} />

        <div className="edit-project-files-content">
          <Switch>
            <Route path={`${path}/drawings`} exact>
              <div className="drawing-files" style={{ height: wrapperHeight }}>
                {canUpload && (
                  <>
                    <div className="ui-flex">
                      <div className="ui-form-group" style={{ width: 280, height: 200 }}>
                        <div className="ui-frame ui-frame-bg edit-project-files-instructions" style={{ height: '100%', padding: 0 }}>
                          <div className="title">{t('EditProject.Instructions.Title')}</div>
                          {instructions && <div className="content" dangerouslySetInnerHTML={{ __html: instructions }} />}
                        </div>
                      </div>

                      <div className="edit-project-files-dropzone ui-form-group" style={{ height: 200, marginLeft: 20, flex: 1, minWidth: 300 }}>
                        <Dropzone
                          accept={DRAWING_TYPES}
                          maxFiles={1}
                          maxSize={MAX_DRAWING_SIZE}
                          noClick={!canUpload}
                          noDrag={!canUpload}
                          noKeyboard={!canUpload}
                          onDropAccepted={(files) => handleDrop('Drawing', files)}
                        >
                          <div style={{ marginTop: 50, alignItems: 'center' }} className="ui-flex ui-flex-column">
                            <div>{t('General.DropFile')}</div>
                            <button type="button" className="ui-btn ui-btn-solid ui-btn-primary" style={{ marginTop: 30 }}>
                              {t('General.UploadDrawing')}
                              <FontAwesomeIcon icon={faArrowUpFromBracket} style={{ marginLeft: 10 }} />
                            </button>
                          </div>
                        </Dropzone>
                      </div>
                    </div>
                  </>
                )}

                <div className="ui-row" style={{ flex: 1 }}>
                  {false /* rights?.userType === UserType.provider */ && (
                    <div className="ui-col-12 ui-col-md-4 ui-col-lg-3 ui-col-xl-2 ui-form-group ui-flex ui-flex-column" style={{ height: '100%' }}>
                      <div className="ui-frame ui-frame-bg" style={{ marginTop: 61, flex: 1 }}>
                        Left
                      </div>
                    </div>
                  )}
                  <div
                    className={`${false /* rights?.userType === UserType.provider */ ? 'ui-col-12 ui-col-md-8 ui-col-lg-9 ui-col-xl-10' : 'ui-col-12'} ui-form-group ui-flex ui-flex-column`}
                    style={{ height: '100%' }}
                  >
                    <DisciplineFilter
                      selectedDisciplines={selectedDisciplines}
                      onChangeDisciplines={(d) => {
                        setSelectedDisciplines(d)
                        if (d) localStorage.setItem('disciplineFilter', JSON.stringify(d))
                        else localStorage.removeItem('disciplineFilter')
                      }}
                    />

                    <DrawingList
                      drawings={!inFetch && category === currentCat ? readyFiles : undefined}
                      disciplines={selectedDisciplines}
                      canCreate={canUpload}
                      onDelete={(id) => {
                        if (!canUpload) return
                        if (!allFiles) return

                        const f = allFiles.find((x) => x.id === id)
                        if (f) setConfirmDeleteDrawing(f)
                      }}
                      onOpen={(content) => history.push(`${url}/drawings/${content.id}`)}
                    />
                  </div>
                </div>
              </div>
            </Route>

            <Route path={`${path}/documents`} exact>
              <div className="doc-files" style={{ height: wrapperHeight }}>
                {canUpload && (
                  <>
                    <div className="ui-flex">
                      <div className="ui-form-group" style={{ width: 280, height: 200 }}>
                        <div className="ui-frame ui-frame-bg edit-project-files-instructions" style={{ height: '100%', padding: 0 }}>
                          <div className="title">{t('EditProject.Instructions.Title')}</div>
                          {instructions && <div className="content" dangerouslySetInnerHTML={{ __html: instructions }} />}
                        </div>
                      </div>

                      <div className="edit-project-files-dropzone ui-form-group" style={{ height: 200, marginLeft: 20, flex: 1, minWidth: 300 }}>
                        <Dropzone
                          maxSize={MAX_DOCUMENT_SIZE}
                          noClick={!canUpload}
                          noDrag={!canUpload}
                          noKeyboard={!canUpload}
                          onDropAccepted={(files) => handleDrop('Document', files)}
                          showFileDialog={showDocumentFileDialog}
                        >
                          <div style={{ marginTop: 50, alignItems: 'center' }} className="ui-flex ui-flex-column">
                            <div>{t('General.DropFile')}</div>
                            <button type="button" className="ui-btn ui-btn-solid ui-btn-primary" style={{ marginTop: 30 }}>
                              {t('General.UploadDocuments')}
                              <FontAwesomeIcon icon={faArrowUpFromBracket} style={{ marginLeft: 10 }} />
                            </button>
                          </div>
                        </Dropzone>
                      </div>
                    </div>
                  </>
                )}

                <FileList
                  files={!inFetch && category === currentCat ? readyFiles : undefined}
                  noFilesLabel={
                    <div className="ui-text-lg ui-warn ui-text-center" style={{ marginTop: 100 }}>
                      <div>
                        <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
                      </div>
                      <div style={{ marginTop: 30 }}>{t('General.NoDocuments')}</div>
                    </div>
                  }
                  canCreate={false}
                  canDelete={canUpload}
                  onDelete={(content) => {
                    if (!canUpload) return
                    setConfirmDeleteDocument(content)
                  }}
                  onUpload={() => {
                    if (!canUpload) return
                    setShowDocumentFileDialog(true)
                    setTimeout(() => setShowDocumentFileDialog(false))
                  }}
                  onOpen={(content) => {
                    history.push(`${url}/documents/${content.id}`)
                  }}
                />
              </div>
            </Route>

            <Route path={`${url}/photos`} exact>
              <div className="photo-files" style={{ height: wrapperHeight }}>
                {canUpload && (
                  <>
                    <div className="ui-flex">
                      <div className="ui-form-group" style={{ width: 280, height: 200 }}>
                        <div className="ui-frame ui-frame-bg edit-project-files-instructions" style={{ height: '100%', padding: 0 }}>
                          <div className="title">{t('EditProject.Instructions.Title')}</div>
                          {instructions && <div className="content" dangerouslySetInnerHTML={{ __html: instructions }} />}
                        </div>
                      </div>

                      <div className="edit-project-files-dropzone ui-form-group" style={{ height: 200, marginLeft: 20, flex: 1, minWidth: 300 }}>
                        <Dropzone
                          accept={PHOTO_TYPES}
                          maxSize={MAX_PHOTO_SIZE}
                          noClick={!canUpload}
                          noDrag={!canUpload}
                          noKeyboard={!canUpload}
                          onDropAccepted={(files) => handleDrop('Picture', files)}
                          showFileDialog={showPhotoFileDialog}
                        >
                          <div style={{ marginTop: 50, alignItems: 'center' }} className="ui-flex ui-flex-column">
                            <div>{t('General.DropFile')}</div>
                            <button type="button" className="ui-btn ui-btn-solid ui-btn-primary" style={{ marginTop: 30 }}>
                              {t('General.UploadPhotos')}
                              <FontAwesomeIcon icon={faArrowUpFromBracket} style={{ marginLeft: 10 }} />
                            </button>
                          </div>
                        </Dropzone>
                      </div>
                    </div>
                  </>
                )}

                <FileList
                  files={!inFetch && category === currentCat ? readyFiles : undefined}
                  noFilesLabel={
                    <div className="ui-text-lg ui-warn ui-text-center" style={{ marginTop: 100 }}>
                      <div>
                        <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
                      </div>
                      <div style={{ marginTop: 30 }}>{t('General.NoPhotos')}</div>
                    </div>
                  }
                  thumbs
                  canCreate={false}
                  canDelete={canUpload}
                  onDelete={(content) => {
                    if (!canUpload) return
                    setConfirmDeletePhoto(content)
                  }}
                  onUpload={() => {
                    if (!canUpload) return
                    setShowPhotoFileDialog(true)
                    setTimeout(() => setShowPhotoFileDialog(false))
                  }}
                  onOpen={(content) => {
                    // window.open(`${content.url}?access_token=${accessToken}`);
                    history.push(`${url}/photos/${content.id}`)
                  }}
                />
              </div>
            </Route>

            <Route
              path={`${path}/:category/:contentId`}
              render={({ match }) => {
                if (match.params.category !== 'drawings') return <DocumentView back={`${url}/${match.params.category}`} />

                let isGrid = false
                projectFiles?.find((f) => {
                  if (f.id === match.params.contentId) {
                    isGrid = true
                    return true
                  }
                  return !!f.children.find((f) => f.id === match.params.contentId)
                })

                return (
                  <>
                    <DisciplineFilter
                      selectedDisciplines={selectedDisciplines}
                      onChangeDisciplines={(d) => {
                        setSelectedDisciplines(d)
                        if (d) localStorage.setItem('disciplineFilter', JSON.stringify(d))
                        else localStorage.removeItem('disciplineFilter')
                      }}
                      disabled={!isGrid}
                    />

                    <DrawingView
                      disciplines={selectedDisciplines}
                      onOpen={(content, fromGrid) => {
                        history.push(`${url}/drawings/${content.id}${fromGrid ? '?g=1' : ''}`)
                      }}
                      onTagDiscipline={(content, discipline) => {
                        if (saving) return

                        setSaving(true)
                        patchContent(content.id, [
                          {
                            propertyName: 'Metadata.discipline',
                            propertyValue: discipline || null
                          },
                          {
                            propertyName: 'Metadata.by',
                            propertyValue: rights?.id
                          }
                        ]).finally(() => setTimeout(() => setSaving(false), 1000))
                        dirty.current = true
                      }}
                    />
                  </>
                )
              }}
            />
          </Switch>
        </div>

        {files && currentFileIndex >= 0 && (
          <Uploading
            filename={files[currentFileIndex].name}
            progress={currentProgress}
            onCancel={() => {
              // Cancel the upload
              if (canceler.current) canceler.current()

              canceler.current = undefined
              setCurrentFileIndex(-1)
              setFiles(undefined)
              setCurrentProgress(0)
            }}
          />
        )}
      </div>

      {confirmDeleteDrawing && (
        <ConfirmModal
          title={t('EditProject.DeleteDrawingTitle')}
          message={t('EditProject.DeleteDrawing', { name: confirmDeleteDrawing.originalName })}
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={() => {
            setConfirmDeleteDrawing(undefined)
            if (!account || !project.id || !confirmDeleteDrawing?.id) return
            deleteFile(account.id, project.id, confirmDeleteDrawing.id)
              .then(() => {
                alertSuccess({
                  title: t('EditProject.DeletedDrawingTitle'),
                  message: t('EditProject.DeletedDrawing', { name: confirmDeleteDrawing.originalName })
                })
              })
              .catch(() => {
                alertDanger({
                  title: t('EditProject.Errors.DeletedDrawingTitle'),
                  message: t('EditProject.Errors.DeletedDrawing')
                })
              })
          }}
          onClose={() => setConfirmDeleteDrawing(undefined)}
        />
      )}
      {confirmDeleteDocument && (
        <ConfirmModal
          title={t('EditProject.DeleteDocumentTitle')}
          message={t('EditProject.DeleteDocument', { name: confirmDeleteDocument.originalName })}
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={() => {
            setConfirmDeleteDocument(undefined)
            if (!account || !project.id || !confirmDeleteDocument?.id) return
            deleteFile(account.id, project.id, confirmDeleteDocument.id)
              .then(() => {
                alertSuccess({
                  title: t('EditProject.DeletedDocumentTitle'),
                  message: t('EditProject.DeletedDocument', { name: confirmDeleteDocument.originalName })
                })
              })
              .catch(() => {
                alertDanger({
                  title: t('EditProject.Errors.DeletedDocumentTitle'),
                  message: t('EditProject.Errors.DeletedDocument')
                })
              })
          }}
          onClose={() => setConfirmDeleteDocument(undefined)}
        />
      )}

      {confirmDeletePhoto && (
        <ConfirmModal
          title={t('EditProject.DeletePhotoTitle')}
          message={t('EditProject.DeletePhoto', { name: confirmDeletePhoto.originalName })}
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={() => {
            setConfirmDeletePhoto(undefined)
            if (!account || !project.id || !confirmDeletePhoto?.id) return
            deleteFile(account.id, project.id, confirmDeletePhoto.id)
              .then(() => {
                alertSuccess({
                  title: t('EditProject.DeletedPhotoTitle'),
                  message: t('EditProject.DeletedPhoto', { name: confirmDeletePhoto.originalName })
                })
              })
              .catch(() => {
                alertDanger({
                  title: t('EditProject.Errors.DeletedPhotoTitle'),
                  message: t('EditProject.Errors.DeletedPhoto')
                })
              })
          }}
          onClose={() => setConfirmDeletePhoto(undefined)}
        />
      )}
    </>
  )
}
