import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { GridApi, ICellRendererParams, GridReadyEvent, IGetRowsParams, FilterChangedEvent, SortModelItem, RowClickedEvent } from 'ag-grid-community'
import 'ag-grid-enterprise'

import { Account } from 'services/src/dto/account'
import { getAccountsByAccount } from 'services/src/api'
import LoadingSvg from 'components/src/svg/Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import { useCurrentUserAccount, useRights } from 'services/src/state'

const accountsDatasource = (account: Account, gridApi: GridApi) => ({
  getRows: ({ sortModel, filterModel, successCallback, failCallback, startRow, endRow }: IGetRowsParams) => {
    let sort = 'name'
    let sortDir = 'asc'
    if (sortModel?.length) {
      sort = sortModel[0].colId
      sortDir = sortModel[0].sort
    }

    let filter: string | undefined
    if (filterModel) {
      const parts: string[] = []
      Object.keys(filterModel).forEach((colId) => {
        const f = filterModel[colId]
        if (f.filterType === 'text') parts.push(`${colId} ${f.type}('${f.filter}')`)
        else if (f.filterType === 'date') {
          if (f.type === 'inRange') parts.push(`${colId} ${f.type}('${f.dateFrom.split(' ')[0]}', '${f.dateTo.split(' ')[0]}')`)
          else parts.push(`${colId} ${f.type}('${f.dateFrom.split(' ')[0]}')`)
        } else if (f.filterType === 'set') {
          parts.push(`${colId} contains('${f.values.join(',')}')`)
        }
      })
      filter = parts.join(' and ')
    }

    getAccountsByAccount(account.id, startRow, endRow - startRow, sort, sortDir, filter)
      .then(({ total, accounts }) => {
        successCallback(accounts, total)
        if (!total) gridApi.showNoRowsOverlay()
        else gridApi.hideOverlay()
      })
      .catch(() => failCallback())
  }
})

type NameCellProps = ICellRendererParams & {
  loadingMessage?: string
}
const NameCell: React.FC<NameCellProps> = ({ data, loadingMessage }) => {
  if (data === undefined)
    return (
      <div className="ag-custom-loading-cell ui-flex ui-flex-nowrap ui-text-muted" style={{ alignItems: 'center' }}>
        <LoadingSvg style={{ width: 25 }} />
        {loadingMessage && <div style={{ marginLeft: 10 }}>{loadingMessage}</div>}
      </div>
    )

  const account = data as Account
  return (
    <div className="ag-html-cell">
      <div className="ui-flex ui-flex-nowrap ui-text-xl">
        <div className="ui-picture-circle" style={{ width: 27, height: 27, minWidth: 27, minHeight: 27 }}>
          {account.logoUrl ? <div style={{ backgroundImage: `url('${account.logoUrl}')`, border: 'none' }} /> : <div style={{ lineHeight: '25px' }}>{account.companyName[0].toUpperCase()}</div>}
        </div>
        <div style={{ marginLeft: 10 }}>
          <div>{account.companyName}</div>
          {account.owner && (
            <div className="ui-text-sm ui-text-muted" style={{ marginTop: 5 }}>
              {account.owner}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const CountCell: React.FC<ICellRendererParams> = ({ value }) => {
  if (value === undefined) return <div />
  return (
    <div className="ag-html-cell">
      <div className="ui-text-xl">{value || 0}</div>
    </div>
  )
}

const NoAccountOverlay = () => {
  const { t } = useTranslation()
  return (
    <div className="no-rows-overlay">
      <h3>{t('General.NoAccounts')}</h3>
    </div>
  )
}

export interface AccountListProps {
  sortKey?: string
  filterKey?: string
  onSelect: (account: Account, e: RowClickedEvent) => void
}

export const AccountList: React.FC<AccountListProps> = ({ sortKey, filterKey, onSelect }) => {
  const { t } = useTranslation()
  const [account] = useCurrentUserAccount()
  const rights = useRights()

  const [ready, setReady] = useState(false)
  useEffect(() => {
    setTimeout(() => setReady(true), 250)
  }, [setReady])

  const [height, setHeight] = useState(500)
  const containerRef = useRef<HTMLDivElement>(null)

  const [sort, setSort] = useState(() => {
    if (!sortKey) return [{ colId: 'name', sort: 'asc' }]
    const s = localStorage.getItem(sortKey)
    return s ? JSON.parse(s) : [{ colId: 'name', sort: 'asc' }]
  })

  const sortChange = useCallback(
    ({ columnApi }: GridReadyEvent) => {
      const s = columnApi.getColumnState()
      const sm = s.filter((x) => !!x.colId && !!x.sort).map<SortModelItem>((x) => ({ colId: x.colId!, sort: x.sort! }))

      if (sm.length <= 0) return

      setSort(sm)
      if (sortKey) localStorage.setItem(sortKey, JSON.stringify(sm))
    },
    [setSort, sortKey]
  )

  const [filter, setFilter] = useState(() => {
    if (!filterKey) return undefined
    const f = localStorage.getItem(filterKey)
    return f ? JSON.parse(f) : undefined
  })

  const filterChange = useCallback(
    ({ api }: FilterChangedEvent) => {
      const fm = api.getFilterModel()
      if (!fm || !Object.keys(fm).length) {
        if (filterKey) localStorage.removeItem(filterKey)
        setFilter(undefined)
        return
      }

      setFilter(fm)
      if (filterKey) localStorage.setItem(filterKey, JSON.stringify(fm))
    },
    [setFilter, filterKey]
  )

  const [gridApi, setGridApi] = useState<GridApi>()
  const gridReady = useCallback(
    ({ api, columnApi }: GridReadyEvent) => {
      setGridApi(api)
      api.setFilterModel(filter)
      columnApi.applyColumnState({
        state: [...sort]
      })
      api.setDatasource(accountsDatasource(account!, api))
    },
    [account, setGridApi, filter, sort]
  )

  useEffect(() => {
    const resize = () => {
      if (containerRef.current) {
        const r = containerRef.current.getBoundingClientRect()
        setHeight(window.innerHeight - r.top)
      }
    }
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  useEffect(() => {
    if (containerRef.current) {
      const r = containerRef.current.getBoundingClientRect()
      setHeight(window.innerHeight - r.top)
    }
  }, [])

  return (
    <>
      <div className="ui-flex" style={{ height: 25, justifyContent: 'flex-end' }}>
        <button
          type="button"
          className={`ui-btn ui-btn-xs${filter ? ' ui-btn-pressed ui-btn-info' : ''}`}
          onClick={() => {
            if (filter) {
              gridApi?.setFilterModel(undefined)
            }
          }}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
          <span style={{ marginLeft: 5 }}>{t('General.ClearFilter')}</span>
        </button>
      </div>

      <div
        className="ag-theme-cc"
        style={{
          height,
          width: '100%',
          visibility: ready ? 'visible' : 'hidden'
        }}
        ref={containerRef}
      >
        <AgGridReact
          defaultColDef={{
            width: 120,
            minWidth: 120,
            editable: false,
            resizable: false,
            sortable: false,
            suppressMenu: true,
            sortingOrder: ['asc', 'desc']
          }}
          frameworkComponents={{
            nameCell: NameCell,
            countCell: CountCell,
            noAccountOverlay: NoAccountOverlay
          }}
          rowHeight={75}
          noRowsOverlayComponent="noAccountOverlay"
          blockLoadDebounceMillis={500}
          cacheBlockSize={25}
          rowSelection="single"
          suppressCellSelection
          suppressRowHoverHighlight
          rowModelType="infinite"
          onRowClicked={(e) => {
            onSelect(e.data, e)
          }}
          onSortChanged={sortChange}
          onFilterChanged={filterChange}
          onGridReady={gridReady}
        >
          <AgGridColumn field="id" hide />

          <AgGridColumn
            field="name"
            headerName={t('Accounts.Name')}
            sortable
            filter="agTextColumnFilter"
            filterParams={{
              filterOptions: ['contains', 'startsWith', 'endsWith'],
              defaultOption: 'contains',
              suppressAndOrCondition: true
            }}
            suppressMenu={false}
            suppressColumnsToolPanel
            flex={1}
            cellRenderer="nameCell"
            cellRendererParams={{ loadingMessage: t('General.OneMoment') }}
            valueGetter={({ data }: any) => data?.companyName}
          />

          {rights?.primaryProvider && (
            <AgGridColumn
              field="type"
              headerName={t('Accounts.Type')}
              sortable
              filter="agTextColumnFilter"
              filterParams={{
                filterOptions: ['contains', 'startsWith', 'endsWith'],
                defaultOption: 'contains',
                suppressAndOrCondition: true
              }}
              suppressMenu={false}
              suppressColumnsToolPanel
              width={150}
              valueGetter={({ data }: any) => data?.type}
            />
          )}

          <AgGridColumn
            field="total"
            headerName={t('Accounts.Projects')}
            width={80}
            cellRenderer="countCell"
            valueGetter={({ data }: any) => {
              if (!data) return undefined
              return data.counts?.total || 0
            }}
          />

          <AgGridColumn
            field="submitted"
            headerName={t('Accounts.Submitted')}
            width={80}
            cellRenderer="countCell"
            valueGetter={({ data }: any) => {
              if (!data) return undefined
              return data.counts?.submitted || 0
            }}
          />

          <AgGridColumn
            field="approved"
            headerName={t('Accounts.Approved')}
            width={80}
            cellRenderer="countCell"
            valueGetter={({ data }: any) => {
              if (!data) return undefined
              return data.counts?.approved || 0
            }}
          />

          <AgGridColumn
            field="inProgress"
            headerName={t('Accounts.InProgress')}
            width={80}
            cellRenderer="countCell"
            valueGetter={({ data }: any) => {
              if (!data) return undefined
              return data.counts?.inProgress || 0
            }}
          />

          <AgGridColumn
            field="complete"
            headerName={t('Accounts.Completed')}
            width={80}
            cellRenderer="countCell"
            valueGetter={({ data }: any) => {
              if (!data) return undefined
              return data.counts?.complete || 0
            }}
          />
        </AgGridReact>
      </div>
    </>
  )
}
