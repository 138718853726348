import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { createProEst, useProjectSupport } from 'services/src/api'
import { PhaseTypeKey, ProjectStatus } from 'services/src/dto/projectShare'
import { PhaseEx, ProjectEx, useCurrentUserAccount, useEditProject, useMe, useProjectFiles, useProjectWatcher, useRights, UserType } from 'services/src/state'

import { useAlert } from 'components/src/alerts'
import { Loading } from 'components/src/loading'
import { ConfirmModal } from 'components/src/modal/ConfirmModal'
import './style.scss'

import { makeDashboardPath, setTitle } from 'services/src/dom'
import { ResourceUserRole } from 'services/src/dto/account'
import { Comment } from 'services/src/dto/comment'
import { useCommentChannels, useCommentPanel } from 'services/src/state/comments'
import { DESKTOP_SIZE } from '../../../constants'
import { InviteModal } from '../common/InviteModal'
import { adjustTimelineItems, timelineItemsToPhases, updateTimelineItemsFromProject } from '../common/Timeline'
import { PhaseEdit } from './PhaseEdit'
import ProjectBoeTabSwitcher from './ProjectBoeTabSwitcher'
import { ProjectDetails } from './ProjectDetails'
import { ProjectHeader } from './ProjectHeader'
import { ProjectNav } from './ProjectNav'
import { ProjectOverview } from './ProjectOverview'
import { ProjectStaff } from './ProjectStaff'
import { ProjectTimeline } from './ProjectTimeline'
import { ExpertOverview } from './expert'
import { FeeSummary } from './feeSummary'

export const ProjectEdit = () => {
  const { t } = useTranslation()
  const {
    path,
    url,
    params: { projectId }
  } = useRouteMatch<{ projectId: string }>()
  const { pathname, search, state: toComment } = useLocation<Comment | undefined>()
  const history = useHistory()
  const [me] = useMe()
  const [account] = useCurrentUserAccount()
  const { alertDanger, alertSuccess, alertWarn } = useAlert()

  const [ready, setReady] = useState(false)

  const [projectSupportState] = useProjectSupport()
  const { project, patch, load: loadProject, setProject, loading: projectLoading } = useEditProject()
  const { setAllFiles } = useProjectFiles()

  const rights = useRights(project.office?.id, project.id)
  const rightsRef = useRef(rights)
  rightsRef.current = rights

  const { channels: commentChannels, setCurrentChannel } = useCommentChannels(project?.account?.id || account?.id, project.id)
  const [, setPanelState] = useCommentPanel()

  const handleProjectUpdate = useCallback(
    (accountId: string, projectId: string, byUserId: string) => {
      if (project.id !== projectId || projectLoading) return

      loadProject(accountId, projectId, true)
        .then((p) => {
          if (!p) throw new Error('not found')

          // Don't alert if I did the update!
          if (me?.id === p.updatedBy?.id || me?.id === byUserId) return

          alertSuccess({
            title: t('EditProject.AsyncUpdateTitle'),
            message: t('EditProject.AsyncUpdate', { name: `${p.updatedBy?.givenName} ${p.updatedBy?.familyName}` })
          })
        })
        .catch(() => {
          alertDanger({
            title: t('EditProject.Errors.LoadFailedTitle'),
            message: t('EditProject.Errors.LoadFailed')
          })
          history.replace(makeDashboardPath(account?.id, 'projects'))
        })
    },
    [project, setProject, me, alertSuccess, alertDanger, projectLoading, t]
  )

  const handleProjectDelete = useCallback(
    (accountId: string, projectId: string, byUserId: string) => {
      if (me?.id === byUserId) return

      // Was this project deleted?
      if (project.id === projectId) {
        alertWarn({
          title: t('EditProject.AsyncDeleteTitle'),
          message: t('EditProject.AsyncDelete')
        })
        history.replace(makeDashboardPath(accountId, 'projects'))
      }
    },
    [project, history, alertWarn, t]
  )

  const { setAccount: setWatcherAccount } = useProjectWatcher({
    onUpdate: handleProjectUpdate,
    onDelete: handleProjectDelete
  })

  const [aside, setAside] = useState(window.innerWidth > DESKTOP_SIZE)

  const [invite, setInvite] = useState<{ project: ProjectEx; phase?: PhaseEx } | undefined>()

  useEffect(() => {
    if (projectLoading || !account?.id) return

    setAllFiles(undefined)

    loadProject(account.id, projectId)
      .then((project) => {
        if (!project) throw new Error('not found')

        let accountId = project.account.id
        if (account.id === 'provider') {
          const pu = account.users?.find((u) => u.id === rights?.id && u.role !== ResourceUserRole.Contributor)
          if (pu) accountId = 'provider'
        }

        setWatcherAccount(accountId)

        if (project?.expertOfferPending && url.endsWith(`/${project.id}`)) history.replace(`${url}/offer`)

        setReady(true)
      })
      .catch(() => {
        alertDanger({
          title: t('EditProject.Errors.LoadFailedTitle'),
          message: t('EditProject.Errors.LoadFailed')
        })
        history.replace(makeDashboardPath(account?.id, 'projects'))
      })
  }, [account, projectId, setWatcherAccount, setAllFiles, history])

  useEffect(() => {
    setTitle(project?.name ? project.name : t('EditProject.Title'))

    setPanelState((s) => {
      const sx = {
        ...s,
        accountId: project?.account?.id,
        projectId: project?.id,
        title: `<div>${t('General.Project')}</div><div class="ui-text-sm ui-text-muted">${project.name}</div>`
      }
      if (toComment || search.includes('cid=')) sx.view = 'open'
      return sx
    })

    return () => {
      setTitle()
      setPanelState((s) => ({ ...s, view: 'closed' }))
    }
  }, [project, t, setTitle])

  useEffect(() => {
    if (!toComment || !commentChannels) return

    let c = commentChannels?.groups.find((g) => (g.sourceChannel ? g.sourceChannel === toComment.sourceChannel && g.channel === toComment.channel : g.channel === toComment.channel))
    if (!c) {
      c = commentChannels?.direct.find((g) =>
        g.sourceChannel
          ? (g.sourceChannel === toComment.sourceChannel && g.channel === toComment.channel) || (g.sourceChannel === toComment.channel && g.channel === toComment.sourceChannel)
          : g.channel === toComment.channel
      )
    }
    if (c) setCurrentChannel(c)
  }, [setPanelState, setCurrentChannel])

  const acceptProject = useCallback(() => {
    patch(
      [
        {
          propertyName: 'Status',
          propertyValue: ProjectStatus.Accepted
        }
      ],
      true
    ).then(() => { })
  }, [project])

  const acceptPhase = useCallback(
    (e: CustomEvent<{ phaseId: string }>) => {
      const p: ProjectEx = JSON.parse(JSON.stringify(project))

      const ph = p.phases.find((x) => x.id === e.detail.phaseId)
      if (!ph) return

      patch(
        [
          {
            propertyName: `Phases.${e.detail.phaseId}.Status`,
            propertyValue: ProjectStatus.Accepted
          }
        ],
        true
      ).then(() => { })
    },
    [project]
  )

  const submitProject = useCallback(() => {
    patch(
      [
        {
          propertyName: 'Status',
          propertyValue: ProjectStatus.Submitted
        }
      ],
      true
    )
      .then(() => {
        alertSuccess({
          title: t('EditProject.ProjectSubmittedTitle'),
          message: t('EditProject.ProjectSubmitted')
        })
      })
      .catch((error) => {
        alertDanger({
          title: t('EditProject.Errors.ProjectSubmittedTitle'),
          message: t('EditProject.Errors.ProjectSubmitted'),
          error
        })
      })
  }, [project, alertSuccess, alertDanger, setProject, patch, t])

  const submitPhase = useCallback(
    (e: CustomEvent<{ phaseId: string }>) => {
      patch(
        [
          {
            propertyName: `Phases.${e.detail.phaseId}.Status`,
            propertyValue: ProjectStatus.Submitted
          }
        ],
        true
      )
        .then(() => {
          alertSuccess({
            title: t('EditProject.PhaseSubmittedTitle'),
            message: t('EditProject.PhaseSubmitted')
          })
        })
        .catch((error) => {
          alertDanger({
            title: t('EditProject.Errors.PhaseSubmittedTitle'),
            message: t('EditProject.Errors.PhaseSubmitted'),
            error
          })
        })
    },
    [project, patch, alertSuccess]
  )

  const [confirmStartProject, setConfirmStartProject] = useState(false)
  const startProject = useCallback(() => {
    setConfirmStartProject(true)
  }, [patch, project, alertSuccess])

  const [confirmStartPhase, setConfirmStartPhase] = useState<PhaseEx>()
  const startPhase = useCallback(
    (e: CustomEvent<{ phaseId: string }>) => {
      const ph = project.phases.find((x) => x.id === e.detail.phaseId)
      if (!ph) return

      setConfirmStartPhase(ph)
    },
    [patch, project, alertSuccess]
  )

  const completeProject = useCallback(() => {
    patch(
      [
        {
          propertyName: 'Status',
          propertyValue: ProjectStatus.Complete
        }
      ],
      false
    ).then(() => {
      alertSuccess({
        title: t('EditProject.ProjectCompleteTitle'),
        message: t('EditProject.ProjectComplete', { name: project.name, account: project.account.companyName })
      })
    })
  }, [patch, project, alertSuccess])

  const [confirmCompletePhase, setConfirmCompletePhase] = useState<PhaseEx>()
  const completePhase = useCallback(
    (e: CustomEvent<{ phaseId: string }>) => {
      const ph = project.phases.find((x) => x.id === e.detail.phaseId)
      if (!ph) return

      setConfirmCompletePhase(ph)
    },
    [patch, project, alertSuccess]
  )

  const cancelProject = useCallback(() => {
    patch(
      [
        {
          propertyName: 'Status',
          propertyValue: ProjectStatus.Canceled
        }
      ],
      false
    ).then(() => {
      alertSuccess({
        title: t('EditProject.ProjectCancelTitle'),
        message: t('EditProject.ProjectCancel', { name: project.name, account: project.account.companyName })
      })
    })
  }, [patch, project, alertSuccess])

  const cancelPhase = useCallback(
    (e: CustomEvent<{ phaseId: string }>) => {
      const ph = project.phases.find((x) => x.id === e.detail.phaseId)
      if (!ph) return

      patch(
        [
          {
            propertyName: `Phases.${e.detail.phaseId}.Status`,
            propertyValue: ProjectStatus.Canceled
          }
        ],
        false
      ).then(() => {
        alertSuccess({
          title: t('EditProject.PhaseCancelTitle'),
          message: t('EditProject.PhaseCancel', {
            type: t(`EditProject.${ph.phaseType}`),
            name: project.name,
            account: project.account.companyName
          })
        })
      })
    },
    [patch, project, alertSuccess]
  )

  useEffect(() => {
    window.addEventListener('acceptProject', acceptProject)
    window.addEventListener('acceptPhase', acceptPhase as any)
    window.addEventListener('submitProject', submitProject)
    window.addEventListener('submitPhase', submitPhase as any)
    window.addEventListener('startProject', startProject as any)
    window.addEventListener('startPhase', startPhase as any)
    window.addEventListener('completeProject', completeProject as any)
    window.addEventListener('completePhase', completePhase as any)
    window.addEventListener('cancelProject', cancelProject as any)
    window.addEventListener('cancelPhase', cancelPhase as any)
    return () => {
      window.removeEventListener('acceptProject', acceptProject)
      window.removeEventListener('acceptPhase', acceptPhase as any)
      window.removeEventListener('submitProject', submitProject)
      window.removeEventListener('submitPhase', submitPhase as any)
      window.removeEventListener('startProject', startProject as any)
      window.removeEventListener('startPhase', startPhase as any)
      window.removeEventListener('completeProject', completeProject as any)
      window.removeEventListener('completePhase', completePhase as any)
      window.removeEventListener('cancelProject', cancelProject as any)
      window.removeEventListener('cancelPhase', cancelPhase as any)
    }
  }, [acceptProject, acceptPhase, submitPhase, submitProject, startProject, startPhase, completeProject, completePhase])

  const addPhase = useCallback(
    (ph: PhaseEx) => {
      const p: ProjectEx = JSON.parse(JSON.stringify(project))
      if (!p.phases) p.phases = []

      const idx = p.phases.findIndex((x) => x.id === ph.id)
      if (idx < 0) p.phases.push({ ...ph, canEdit: true, canDelete: true })
      else p.phases[idx] = { ...ph, canEdit: true, canDelete: true }

      p.phases = [
        ...p.phases.filter((x) => x.phaseType === PhaseTypeKey.Concept),
        ...p.phases.filter((x) => x.phaseType === PhaseTypeKey.Schematic),
        ...p.phases.filter((x) => x.phaseType === PhaseTypeKey.DesignDoc),
        ...p.phases.filter((x) => x.phaseType === PhaseTypeKey.ConstructionDoc),
        ...p.phases.filter((x) => x.phaseType === PhaseTypeKey.Proposal)
      ]

      const items = updateTimelineItemsFromProject(p, projectSupportState!.phaseTypes, t)
      p.phases = timelineItemsToPhases(adjustTimelineItems(items))

      history.push(makeDashboardPath(account?.id, `projects/${p.id}/timeline`))

      setProject(p)
    },
    [project, setProject, projectSupportState, history]
  )

  const newInvite = useCallback(() => {
    const segments = pathname.split('/')
    const idx = segments.indexOf('phases')
    if (idx > 0) {
      if (idx > segments.length - 1) return
      const phase = project.phases.find((x) => x.id === segments[idx + 1])
      if (!phase) return

      setInvite({ project, phase })
      return
    }

    setInvite({ project })
  }, [project, pathname])

  if (!ready || !project) return <Loading />

  return (
    <>
      <div className={aside ? 'edit-project-nav-open' : 'edit-project-nav-closed'}>
        <ProjectHeader onToggleAside={() => setAside((o) => !o)} asideOpen={aside} onInvite={newInvite} />

        <div>
          <div className='boe-top-menu'>
            <ProjectBoeTabSwitcher />
          </div>
        </div>

        <ProjectNav visible={aside} url={url} onAddPhase={addPhase} />

        <Switch>
          <Route path={path} exact>
            <div className="edit-project-content">
              <div className="edit-project-main">{project.status === ProjectStatus.Draft ? <ProjectDetails /> : <ProjectOverview />}</div>
            </div>
          </Route>

          <Route path={`${path}/offer`}>
            <div className="edit-project-content">
              <div className="edit-project-main">{project.expertOffer ? <ExpertOverview /> : <Redirect to={url} />}</div>
            </div>
          </Route>

          <Route path={`${path}/edit`}>
            <div className="edit-project-content">
              <div className="edit-project-main">{project.canEdit ? <ProjectDetails /> : <Redirect to={url} />}</div>
            </div>
          </Route>

          <Route path={`${path}/timeline`}>
            <div className="edit-project-content">
              <div className="edit-project-main">
                <ProjectTimeline />
              </div>
            </div>
          </Route>

          <Route path={`${path}/fees`}>
            <div className="edit-project-content edit-project-content-full">
              <div className="edit-project-main">
                {(() => {
                  if (rights?.userType === UserType.expert) return <Redirect to={url} />
                  if (rights?.userType === UserType.provider) return <FeeSummary />
                  if ([ProjectStatus.Accepted, ProjectStatus.FeeReady, ProjectStatus.FeeCounter, ProjectStatus.Approved, ProjectStatus.InProgress, ProjectStatus.Complete].includes(project.status!))
                    return <FeeSummary />
                  return <Redirect to={url} />
                })()}
              </div>
            </div>
          </Route>

          <Route path={`${path}/staff`}>
            <div className="edit-project-content edit-project-content-full">
              <div className="edit-project-main">{rights?.userType !== UserType.expert ? <ProjectStaff /> : <Redirect to={url} />}</div>
            </div>
          </Route>

          <Route path={`${path}/phases/:phaseId`}>
            <PhaseEdit />
          </Route>

          <Redirect to={url} />
        </Switch>
      </div>

      {confirmStartProject && (
        <ConfirmModal
          title={t('EditProject.ConfirmStartProject.Title')}
          message={
            <>
              <div>{t('EditProject.ConfirmStartProject.Message1')}</div>
              <br />
              <div>{t('EditProject.ConfirmStartProject.Message2')}</div>
            </>
          }
          onClose={() => setConfirmStartProject(false)}
          onYes={() => {
            setConfirmStartProject(false)
            patch(
              [
                {
                  propertyName: 'Status',
                  propertyValue: ProjectStatus.InProgress
                }
              ],
              false
            )
              .then((project) => {
                const ph = project.phases.find((ph) => ph.status === ProjectStatus.InProgress)
                if (ph) {
                  if (!ph.proEst?.id) {
                    return createProEst(project.account.id, project.id!, ph.id!).then(() => project)
                  }
                }

                return project
              })
              .then(() => {
                alertSuccess({
                  title: t('EditProject.ProjectStartedTitle'),
                  message: t('EditProject.ProjectStarted', { name: project.name, account: project.account.companyName })
                })
              })
          }}
        />
      )}

      {confirmStartPhase && (
        <ConfirmModal
          title={t('EditProject.ConfirmStartPhase.Title')}
          message={
            <>
              <div>{t('EditProject.ConfirmStartPhase.Message1')}</div>
              <br />
              <div>{t('EditProject.ConfirmStartPhase.Message2')}</div>
              <br />
              <div>{t('EditProject.ConfirmStartPhase.Message3')}</div>
            </>
          }
          onClose={() => setConfirmStartPhase(undefined)}
          yes={t('EditProject.ConfirmStartPhase.Title')}
          no={t('General.Cancel')}
          onYes={() => {
            setConfirmStartPhase(undefined)

            patch(
              [
                {
                  propertyName: `Phases.${confirmStartPhase!.id}.Status`,
                  propertyValue: ProjectStatus.InProgress
                }
              ],
              false
            )
              .then((project) => {
                const ph = project.phases.find((ph) => ph.id === confirmStartPhase!.id)
                if (ph) {
                  if (!ph.proEst?.id) {
                    return createProEst(project.account.id, project.id!, ph.id!).then(() => project)
                  }
                }

                return project
              })
              .then(() => {
                alertSuccess({
                  title: t('EditProject.PhaseStartedTitle'),
                  message: t('EditProject.PhaseStarted', {
                    type: t(`EditProject.${confirmStartPhase.phaseType}`),
                    name: project.name,
                    account: project.account.companyName
                  })
                })
              })
          }}
        />
      )}

      {confirmCompletePhase && (
        <ConfirmModal
          title={t('EditProject.ConfirmCompletePhase.Title')}
          message={
            <>
              <div>{t('EditProject.ConfirmCompletePhase.Message1')}</div>
              <br />
              <div>{t('EditProject.ConfirmCompletePhase.Message2')}</div>
            </>
          }
          onClose={() => setConfirmCompletePhase(undefined)}
          yes={t('EditProject.ConfirmCompletePhase.Title')}
          no={t('General.Cancel')}
          onYes={() => {
            setConfirmCompletePhase(undefined)

            patch(
              [
                {
                  propertyName: `Phases.${confirmCompletePhase!.id}.Status`,
                  propertyValue: ProjectStatus.Complete
                }
              ],
              false
            ).then(() => {
              alertSuccess({
                title: t('EditProject.PhaseCompleteTitle'),
                message: t('EditProject.PhaseComplete', {
                  type: t(`EditProject.${confirmCompletePhase.phaseType}`),
                  name: project.name,
                  account: project.account.companyName
                })
              })
            })
          }}
        />
      )}

      {invite && account && (
        <InviteModal
          account={account}
          project={invite.project}
          phase={invite.phase}
          onClose={({ invitation, user }) => {
            setInvite(undefined)

            const p: ProjectEx = JSON.parse(JSON.stringify(invite.project))

            if (invitation) p.accountWithUsers.invitations.push(invitation)

            if (user) {
              let idx = p.accountWithUsers.users.findIndex((x) => x.id === user.id)
              if (idx >= 0) p.accountWithUsers.users[idx] = user
              else p.accountWithUsers.users.push(user)

              idx = p.users?.findIndex((x) => x.id === user.id) || -1
              if (idx >= 0) p.users![idx] = user
              else {
                if (!p.users) p.users = []
                p.users.push(user)
              }
            }

            setProject(p)
          }}
          onAssignUser={() => { }}
        />
      )}
    </>
  )
}
