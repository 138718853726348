import axios from 'axios'
import { aadConfig, getAccessToken } from '../aad'
import apiProxy from './apiProxy'
import { ProjectCounts } from '../dto/project'

export const getProjectCounts = apiProxy('getProjectCounts', async (accountId: string, officeIds?: string[]): Promise<ProjectCounts[]> => {
  await getAccessToken(aadConfig().projectScopes)

  const q: string[] = [`accounts=${accountId}`]
  if (officeIds) q.push(`offices=${encodeURIComponent(officeIds.join(','))}`)

  const { data } = await axios.get<ProjectCounts[]>(`${process.env.REACT_APP_PROJECT_API}/statistics/project-counts?${q.join('&')}`)
  if (data.length) return data

  return [
    {
      accountId,
      providerId: accountId,
      total: 0,
      draft: 0,
      submitted: 0,
      accepted: 0,
      feeReady: 0,
      feeCounter: 0,
      approved: 0,
      inProgress: 0,
      complete: 0,
      canceled: 0,
      rejected: 0
    }
  ]
})
