import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { TabBar, TabItem } from 'components/src/tabs'
import { useCurrentAccountWithUser, useMe, useRights } from 'services/src/state'
import { getDisciplineSkills, updateAccountUserRole, updateUser } from 'services/src/api'
import { makeDashboardPath } from 'services/src/dom'
import { useSaving } from 'components/src/saving'
import { useAlert } from 'components/src/alerts'
import { ResourceUserRole, UserStatus } from 'services/src/dto/account'
import { ProviderSecurity } from './ProviderSecurity'
import { ProviderExperience } from './ProviderExperience'
import { ProviderGeneral } from './ProviderGeneral'
import { ProviderProfileViewModel } from './model'
import { ProviderEmployment } from './ProviderEmployment'

export const ProviderProfile: React.FC = () => {
  const { t, i18n } = useTranslation()
  const {
    url,
    path,
    params: { userId }
  } = useRouteMatch<{ userId?: string }>()
  const { pathname } = useLocation()
  const history = useHistory()
  const [, setSaving] = useSaving()
  const { alertDanger, alertSuccess } = useAlert()

  const [accountWithUsers, refreshUsers] = useCurrentAccountWithUser()
  const [me, , setMe] = useMe()
  const rights = useRights()
  const [fallbackVm, setFallbackVm] = useState<ProviderProfileViewModel>()
  const [vm, setVm] = useState<ProviderProfileViewModel>()

  useEffect(() => {
    if (!me || !rights || !accountWithUsers) return

    const user = userId ? accountWithUsers.users.find((x) => x.id === userId) : me
    if (!user) {
      history.replace(makeDashboardPath(undefined, '/settings/account/staff'))
      return
    }

    getDisciplineSkills(i18n.language).then((disciplineSkills) => {
      const vm: ProviderProfileViewModel = {
        ...JSON.parse(JSON.stringify(user)),
        notMe: !!userId,
        available: user.status === UserStatus.Ready,
        userType: rights.userType,
        disciplineSkills,
        skills: disciplineSkills.find((x) => x.discipline === user.primaryDiscipline)?.skills || [],
        roles: [
          {
            role: ResourceUserRole.Owner,
            label: t(`Settings.EditExpert.Roles.${ResourceUserRole.Owner}`)
          },
          {
            role: ResourceUserRole.Administrator,
            label: t(`Settings.EditExpert.Roles.${ResourceUserRole.Administrator}`)
          },
          {
            role: ResourceUserRole.Contributor,
            label: t(`Settings.EditExpert.Roles.${ResourceUserRole.Contributor}`)
          },
          {
            role: ResourceUserRole.Guest,
            label: t(`Settings.EditExpert.Roles.${ResourceUserRole.Guest}`)
          }
        ],
        errors: {}
      }
      vm.initRole = accountWithUsers?.users.find((u) => u.id === vm.id)?.role || ResourceUserRole.Guest
      vm.role = vm.initRole
      setVm(vm)
      setFallbackVm(JSON.parse(JSON.stringify(vm)))
    })
  }, [me, rights, userId, accountWithUsers, history, i18n, t, setVm, setFallbackVm])

  const tabs = useMemo<TabItem[]>(() => {
    if (!accountWithUsers || !me) return []

    const tabs = [
      {
        label: t('Expert.Settings.Tabs.General'),
        selected: pathname.endsWith('user') || pathname.endsWith(userId || 'xxx'),
        to: url
      },
      {
        label: userId === me.id ? t('Your Experience') : t('Experience'),
        to: `${url}/experience`,
        selected: pathname.endsWith('experience')
      },
      {
        label: t('Expert.Settings.Tabs.Employment'),
        to: `${url}/employment`,
        selected: pathname.endsWith('employment')
      }
    ]

    if (!userId) {
      tabs.push({
        label: t('Security'),
        to: `${url}/security`,
        selected: pathname.endsWith('security')
      })
    }

    return tabs
  }, [vm, pathname, userId, me, accountWithUsers, t])

  const changeVm = useCallback(
    (vm: ProviderProfileViewModel) => {
      vm.dirty = true
      setVm(vm)
    },
    [setVm]
  )

  const submit = useCallback(
    (view: string, vm: ProviderProfileViewModel) => {
      const u = { ...vm, status: vm.available ? UserStatus.Ready : UserStatus.Unavailable } as any
      delete u.userType
      delete u.available
      delete u.notMe
      delete u.dirty
      delete u.errors
      delete u.picture
      delete u.preview
      delete u.disciplineSkills
      delete u.skills
      delete u.roles
      delete u.role
      delete u.initRole

      setSaving(true)
      updateUser('provider', u.id, u, vm.picture)
        .then((user) => {
          // Was account role changed?
          if (vm.initRole !== vm.role && accountWithUsers?.id) {
            return updateAccountUserRole(accountWithUsers?.id, user.id, vm.role)
              .then(() => user)
              .catch(() => user)
          }

          return Promise.resolve(user)
        })
        .then((user) => {
          const isMe = user.id === me?.id

          const name = user.familyName.endsWith('s') ? `${user.givenName} ${user.familyName}'` : `${user.givenName} ${user.familyName}'s`
          alertSuccess({
            title: isMe ? t('Expert.Settings.SelfSavedTitle') : t('Expert.Settings.OtherSavedTitle', { name }),
            message: isMe ? t('Expert.Settings.SelfSaved') : t('Expert.Settings.OtherSaved', { name })
          })

          if (isMe) setMe(user)
          refreshUsers()

          vm.dirty = false
          setVm(vm)
          setFallbackVm(JSON.parse(JSON.stringify(vm)))
        })
        .catch((error) => {
          const name = vm.familyName.endsWith('s') ? `${vm.givenName} ${vm.familyName}'` : `${vm.givenName} ${vm.familyName}'s`
          alertDanger({
            title: t('Expert.Settings.Errors.SavedTitle'),
            message: vm.id === me?.id ? t('Expert.Settings.Errors.SelfSaved') : t('Expert.Settings.Errors.OtherSaved', { name }),
            error
          })
        })
        .finally(() => setSaving(false))
    },
    [setSaving, alertSuccess, alertDanger, me, setMe, t, vm, setVm]
  )

  if (!vm) return null

  return (
    <>
      {vm && userId && me?.id !== userId && (
        <div style={{ marginBottom: 20 }}>
          <h3>
            {(() => {
              if (!vm) return null
              if (!userId || me?.id === userId) return t('Expert.Settings.YourProfile')
              if (vm.familyName.endsWith('s')) return t('Expert.Settings.OtherProfile', { name: `${vm.givenName} ${vm.familyName}'` })
              return t('Expert.Settings.OtherProfile', { name: `${vm.givenName} ${vm.familyName}'s` })
            })()}
          </h3>
          <hr />
        </div>
      )}

      <TabBar
        tabs={tabs}
        className="settings-tab-bar"
        onChange={() => {
          if (vm?.dirty) setVm(fallbackVm)
        }}
      />

      <Switch>
        <Route path={`${path}/experience`}>
          <ProviderExperience vm={vm} onChange={changeVm} onSubmit={submit} />
        </Route>
        <Route path={`${path}/employment`}>
          <ProviderEmployment vm={vm} onChange={changeVm} onSubmit={submit} />
        </Route>
        {vm.id === me?.id && (
          <Route path={`${path}/security`}>
            <ProviderSecurity />
          </Route>
        )}
        <Route path={path} exact>
          <ProviderGeneral vm={vm} onChange={changeVm} onSubmit={submit} />
        </Route>

        <Redirect to={url} />
      </Switch>
    </>
  )
}
