import React, { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/pro-duotone-svg-icons'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons'

import { useRights, useUserOffices } from 'services/src/state'

export const OfficeListSubheader: React.FC<{
  onInvite?: () => void
}> = ({ onInvite }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const rights = useRights()

  const [offices] = useUserOffices()

  const counts = useMemo<{ total: number; users: number; projects: number } | undefined>(() => {
    if (!offices) return undefined

    let projects = 0
    const uniqueUserIds: string[] = []
    offices.forEach((o) => {
      projects += o.counts?.total || 0
      o.users?.forEach((u) => {
        if (!uniqueUserIds.find((x) => x === u.id)) uniqueUserIds.push(u.id)
      })
    })

    return {
      total: offices?.length || 0,
      users: uniqueUserIds.length,
      projects
    }
  }, [offices])

  return (
    <div className="office-index-subheader">
      <div className="left">
        <div>
          <div>{t('General.Offices')}</div>
        </div>
        <div className="ui-text-xs ui-text-muted ui-flex ui-flex-nowrap">
          {counts && (
            <>
              <div className="ui-text-ellipsis">{t('Offices.LocationCount', { count: counts.total })}</div>
              <div>|</div>
              <div className="ui-text-ellipsis">{t('Offices.UserCount', { count: counts.users })}</div>
              <div>|</div>
              <div className="ui-text-ellipsis">{t('Offices.ProjectCount', { count: counts.projects })}</div>
            </>
          )}
        </div>
      </div>
      <div className="right">
        {rights?.canCreateOffice && (
          <Link to={`${location.pathname}/new`} className="ui-btn ui-btn-secondary ui-btn-solid" style={{ marginRight: 10 }}>
            <FontAwesomeIcon icon={faPlusCircle} />
            <span style={{ marginLeft: 5 }}>{t('General.AddOffice')}</span>
          </Link>
        )}

        {onInvite && (
          <a
            href={location.pathname}
            className="ui-btn ui-btn-primary ui-btn-circle ui-btn-invert"
            onClick={(e) => {
              e.preventDefault()
              onInvite()
            }}
          >
            <FontAwesomeIcon icon={faUserPlus} />
          </a>
        )}
      </div>
    </div>
  )
}
