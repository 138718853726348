import React, { useMemo } from 'react'
import './style.scss'

export interface GridColumnProps {
  className?: string
  style?: React.CSSProperties
  field?: string
  name: JSX.Element | string
  width?: number | string
  maxWidth?: number | string
  minWidth?: number | string
  flex?: number
  render?: (props: GridContentRenderProps) => JSX.Element | null
}
export const GridColumn: React.FC<GridColumnProps> = () => null

export interface GridContentRenderProps<D = any> {
  rowIndex: number
  data: D
  column: GridColumnProps & { columnIndex: number }
}

export interface GridProps {
  className?: string
  style?: React.CSSProperties
  renderHeader?: (props: GridColumnProps, index: number) => JSX.Element | null
  renderContent?: (props: GridContentRenderProps) => JSX.Element | null
  rowData: any[]
}

export const Grid: React.FC<React.PropsWithChildren<GridProps>> = ({ renderContent, renderHeader, className, style, rowData, children }) => {
  const columns = useMemo<GridColumnProps[]>(() => {
    const columns: GridColumnProps[] = []

    React.Children.forEach(children as any, (child: React.ReactElement<GridColumnProps>) => {
      if (!child) return

      if (child.type === GridColumn) columns.push(child.props)
      else {
        const { props }: any = child
        if (props && props.children) {
          props.children.forEach((child: any) => {
            if (child.type === GridColumn && child.props) columns.push(child.props)
          })
        }
      }
    })

    return columns
  }, [children, rowData])

  if (!columns) return null

  return (
    <div className={`ui-grid${className ? ` ${className}` : ''}`} style={style}>
      <div className="ui-grid-header">
        {columns.map((column, columnIndex) => (
          <div
            key={columnIndex}
            className={`ui-grid-column${column.className ? ` ${column.className}` : ''}`}
            style={{
              ...(column.style || {}),
              width: column.width,
              maxWidth: column.maxWidth,
              minWidth: column.minWidth,
              flex: column.flex
            }}
          >
            {renderHeader ? renderHeader(column, columnIndex) : column.name}
          </div>
        ))}
      </div>
      <div className="ui-grid-content">
        {rowData.map((data, rowIndex) => (
          <div key={rowIndex} className={`ui-grid-row${data?.rowClass ? ` ${data.rowClass}` : ''}`}>
            {columns.map((column, columnIndex) => (
              <div
                key={columnIndex}
                className={`ui-grid-column${column.className ? ` ${column.className}` : ''}`}
                style={{
                  ...(column.style || {}),
                  width: column.width,
                  maxWidth: column.maxWidth,
                  minWidth: column.minWidth,
                  flex: column.flex
                }}
              >
                {(() => {
                  if (column.render) return column.render({ rowIndex, data, column: { ...column, columnIndex } })

                  return renderContent ? renderContent({ rowIndex, data, column: { ...column, columnIndex } }) : null
                })()}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
