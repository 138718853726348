import { useCallback, useEffect, useRef, useState } from 'react'
import { atom, useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'

import { useSaving } from 'components/src/saving'
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr'
import moment from 'moment'
import { getAccountUsers, getProEst, getProject, getProjectCounts, getProjectNames, patchProject } from '../api'
import { useCurrentUserAccount, useCurrentUserOffice, useMe, useRights, UserType, useUserOffices } from './account'
import { Estimate, EstimateLabor, Phase, Project, ProjectCounts, ProjectName, ProjectStatus } from '../dto/project'
import { Account, AccountType, AccountWithUsers, ResourceUserRole, User } from '../dto/account'
import { Patch } from '../dto/common'
import {aadConfig, getAccessToken} from '../aad'
import { ProEstEstimate, ProEstEstimateDetails } from '../dto/proEst'

export type ProjectEx = Omit<Project, 'account' | 'phases'> & {
  // [key: string]: any;
  account: Account
  accountWithUsers: AccountWithUsers
  provider: Account
  providerAccountWithUsers: AccountWithUsers
  isGuest: boolean
  canEdit: boolean
  canEditPhase: boolean
  canComplete: boolean
  canDelete: boolean
  canSubmit: boolean
  canInvite: boolean
  canViewStaff?: boolean
  phases: PhaseEx[]
  asyncUpdate?: boolean
  expertOffer?: boolean
  expertOfferPending?: boolean

  clientPm?: User
  providerPm?: User
}
export type PhaseEx = Omit<Phase, 'estimates'> & {
  // [key: string]: any;
  isVisible?: boolean
  isGuest?: boolean
  canEdit?: boolean
  canStart?: boolean
  canComplete?: boolean
  canDelete?: boolean
  canSubmit?: boolean
  canInvite?: boolean
  canViewStaff?: boolean
  estimates?: EstimateEx[]
}
export type EstimateEx = Omit<Estimate, 'labor'> & {
  // [key: string]: any;
  labor: EstimateLaborEx[]
  isVisible?: boolean
  isGuest: boolean
  canEdit?: boolean
}

export type EstimateLaborEx = EstimateLabor & {
  // [key: string]: any;
}

const editProjectAtom = atom<any>({
  key: 'editProject',
  default: {}
})
const editProjectLoadingAtom = atom<boolean>({
  key: 'editProjectLoading',
  default: false
})
const editProjectErrorAtom = atom<any | undefined>({
  key: 'editProjectError',
  default: undefined
})
const projectNamesAtom = atom<ProjectName[]>({
  key: 'projectNames',
  default: []
})
const proEstAtom = atom<{ estimate: ProEstEstimate; details: ProEstEstimateDetails } | undefined>({
  key: 'proEst',
  default: undefined
})

export type UseEditProjectHook<T extends Project> = {
  project: T
  load: (accountId: string, projectId: string, asyncUpdate?: boolean) => Promise<T>
  patch: (patch: Patch[], noDebounce?: boolean) => Promise<T>
  setProject: (project: T) => void
  loading: boolean
  error?: any
}

let patchId = 1
interface PatchOptions {
  id: number
  added: number
  accountId: string
  projectId: string
  patch: Patch[]
  l?: string
}
interface PatchTask {
  options: PatchOptions
  pending: Promise<any>
}
const patchTasks: PatchTask[] = []

export function useEditProject<T extends ProjectEx>(): UseEditProjectHook<T> {
  const { t } = useTranslation()
  const [account] = useCurrentUserAccount()
  const [project, setCurrent] = useRecoilState(editProjectAtom)
  const [loading, setLoading] = useRecoilState(editProjectLoadingAtom)
  const [error, setError] = useRecoilState(editProjectErrorAtom)
  const {
    i18n: { language }
  } = useTranslation()
  const [, setSaving] = useSaving()
  const rights = useRights()
  const [me] = useMe()

  const initialize = useCallback((project: T): T => {
    if (!project) return project

    project.canEdit = false
    project.canEditPhase = false
    project.canDelete = false
    project.canInvite = false
    project.canViewStaff = false
    project.canComplete = false

    project.clientPm = project.users?.find((u) => u.role === ResourceUserRole.Owner && !!project.accountWithUsers.users.find((u2) => u2.id === u.id))
    project.providerPm = project.users?.find((u) => u.role === ResourceUserRole.Owner && !!project.providerAccountWithUsers.users.find((u2) => u2.id === u.id))

    // Find user in this project
    let pu = project.users?.find((u) => u.id === me?.id)
    if (!pu) {
      project.phases.forEach((ph) => {
        pu = ph.users?.find((u) => u.id === me?.id)
        if (!pu) {
          ph.estimates?.forEach((est) => {
            pu = est.labor?.find((l) => l.userId === me?.id)?.user
          })
        }
      })
    }

    let phaseVisible = false
    let providerUser: User | undefined

    if (rights?.userType === UserType.provider) {
      pu = project.providerAccountWithUsers.users.find((u) => u.id === me?.id)!
      providerUser = pu
      project.canEdit = (pu.role === ResourceUserRole.Owner || pu.role === ResourceUserRole.Administrator) && ![ProjectStatus.Complete, ProjectStatus.Canceled].includes(project.status!)
      project.canEditPhase = project.canEdit

      project.canComplete = pu.role === ResourceUserRole.Owner || pu.role === ResourceUserRole.Administrator

      project.canDelete = pu.role === ResourceUserRole.Owner && project.canEdit
      project.canInvite = project.canEdit
      project.canViewStaff = true
      phaseVisible = true
    } else if (pu) {
      project.canEdit = (pu.role === ResourceUserRole.Owner || pu.role === ResourceUserRole.Administrator) && ![ProjectStatus.Complete, ProjectStatus.Canceled].includes(project.status!)
      project.canEditPhase = project.canEdit

      project.canViewStaff = pu.role === ResourceUserRole.Owner || pu.role === ResourceUserRole.Administrator
      project.canInvite = project.canViewStaff && ![ProjectStatus.Complete, ProjectStatus.Canceled].includes(project.status!)

      project.canDelete = pu.role === ResourceUserRole.Owner && project.canEdit
      project.isGuest = pu.role === ResourceUserRole.Guest
      phaseVisible = true
    } else {
      const ou = project.office?.users?.find((x) => x.id === me?.id)
      if (ou) {
        project.canEdit = (ou.role === ResourceUserRole.Owner || ou.role === ResourceUserRole.Administrator) && ![ProjectStatus.Complete, ProjectStatus.Canceled].includes(project.status!)
        project.canEditPhase = project.canEdit
        project.canInvite = project.canEdit
        project.canViewStaff = true

        project.canDelete = ou.role === ResourceUserRole.Owner && project.canEdit
        project.isGuest = ou.role === ResourceUserRole.Guest
        phaseVisible = true
      } else {
        const au = project.account?.users?.find((x) => x.id === me?.id)
        if (au) {
          project.canEdit = (au.role === ResourceUserRole.Owner || au.role === ResourceUserRole.Administrator) && ![ProjectStatus.Complete, ProjectStatus.Canceled].includes(project.status!)
          project.canEditPhase = project.canEdit
          project.canInvite = project.canEdit
          project.canViewStaff = true

          project.canDelete = au.role === ResourceUserRole.Owner && project.canEdit
          project.isGuest = au.role === ResourceUserRole.Guest
          phaseVisible = true
        }
      }
    }

    if (rights?.userType === UserType.expert) {
      project.canEdit = false
      project.canEditPhase = false
      project.canInvite = false
      project.canViewStaff = false
      project.canDelete = false
    }

    project.canSubmit = project.canEdit && project.status === ProjectStatus.Draft && project.phases.length > 0

    if (project.canSubmit) {
      if (!project.budget || (project.budget.isFixed && !project.budget.fixed) || (!project.budget.isFixed && !project.budget.range)) project.canSubmit = false
    }

    let startPhaseId: string
    let canStart = true
    project.phases?.forEach((ph) => {
      ph.canStart = false

      if (ph.status === ProjectStatus.InProgress) canStart = false
      if (canStart && !startPhaseId && ph.status === ProjectStatus.Approved) {
        startPhaseId = ph.id!
        ph.canStart = true
      }

      if (providerUser) {
        ph.canEdit = project.canEdit
        ph.canDelete = project.canDelete
        ph.isGuest = project.isGuest
        ph.canInvite = project.canInvite
        ph.canViewStaff = project.canViewStaff
        ph.isVisible = true

        if (providerUser.role === ResourceUserRole.Owner || providerUser.role === ResourceUserRole.Administrator) {
          ph.canEdit = project.canEdit
          ph.canDelete = project.canDelete
          ph.canComplete = true
        }

        if (rights?.userType === UserType.provider) {
          if ([ProjectStatus.Complete, ProjectStatus.Canceled].includes(ph.status!)) {
            ph.canEdit = false
            ph.canDelete = false
          }
        } else if (![ProjectStatus.New, ProjectStatus.Submitted].includes(ph.status!)) {
          ph.canEdit = false
          ph.canDelete = false
        }

        ph.estimates?.forEach((est) => {
          if (est.labor.filter((l) => ![ProjectStatus.Complete, ProjectStatus.Canceled].includes(l.status)).length > 0) ph.canComplete = false

          if (providerUser!.role === ResourceUserRole.Owner || providerUser!.role === ResourceUserRole.Administrator) {
            est.canEdit = ph.canEdit
            est.isVisible = true
          } else {
            est.canEdit = false
            est.isVisible = false

            if (providerUser!.role === ResourceUserRole.Contributor) {
              const l = est.labor?.find((l) => l.user?.id === providerUser!.id)
              if (l) {
                est.canEdit = false
                est.isVisible = true
              }
            } else if (providerUser!.role === ResourceUserRole.Guest) {
              est.canEdit = false
              est.isVisible = true
            }
          }
        })

        if (!ph.canComplete) project.canComplete = false
      } else {
        const u = ph.users?.find((x) => x.id === me?.id)
        if (u) {
          ph.isGuest = u.role === ResourceUserRole.Guest || project.isGuest
          ph.canEdit = !ph.isGuest && (u.role === ResourceUserRole.Owner || u.role === ResourceUserRole.Administrator)
          ph.canInvite = ph.canEdit

          ph.canDelete = !ph.isGuest && u.role === ResourceUserRole.Owner
          ph.isVisible = true
        } else {
          ph.isGuest = project.isGuest
          ph.canEdit = !ph.isGuest && project.canEdit
          ph.canInvite = !ph.isGuest && project.canInvite
          ph.canViewStaff = project.canViewStaff
          ph.canDelete = !ph.isGuest && project.canDelete
          ph.isVisible = phaseVisible
        }
      }

      ph.canSubmit = !ph.isGuest && project.status !== ProjectStatus.Draft && ph.canEdit && ph.status === ProjectStatus.New && !!project.budget
    })

    if (rights?.userType === UserType.expert) {
      project.phases.forEach((ph) => {
        ph.estimates?.forEach((est) => {
          est.labor
            ?.filter((l) => l.userId === me?.id)
            ?.forEach((l) => {
              project.expertOffer = true

              if (l.status === ProjectStatus.FeeReady) {
                const sh = l.statusHistory.find((sh) => sh.newStatus === ProjectStatus.FeeReady)
                const exp = sh?.createdAt ? moment.duration(60 * 60 * 24 * 1000 - (moment().valueOf() - moment(sh!.createdAt).valueOf())) : null

                project.expertOfferPending = (exp?.asMilliseconds() || 0) > 0
              }
            })
        })
      })
    }

    console.log('project', project, rights);
    return project
  }, [me, rights, t])

  const finishLoad = useCallback((p: T, force?: boolean): Promise<T> => {

    // p.phases = p.phases.sort((a, b) => {});


    if (!force && project?.account?.id === p?.account?.id) {
      p.account = project.account
      p.accountWithUsers = project.accountWithUsers
      p.providerAccountWithUsers = project.providerAccountWithUsers

      const px = initialize(p)
      setCurrent(px)
      return Promise.resolve(px)
    }

    return Promise.all([
      getAccountUsers(p.account!.id!),
      getAccountUsers(p.provider!.id!)
    ])
      .then(([ accountWithUsers, providerAccountWithUsers ]) => {
        p.accountWithUsers = accountWithUsers
        p.providerAccountWithUsers = providerAccountWithUsers
      })
      .then(() => {
        const px = initialize(p)
        setCurrent(px)
        return px
      })
  }, [project, setCurrent])

  const load = useCallback(
    (accountId: string, projectId: string, asyncUpdate?: boolean): Promise<T> => {
      setLoading(true)
      setError(undefined)
      // Load the project
      return getProject(accountId, projectId, language)
        .then((p) => {
          p.asyncUpdate = asyncUpdate
          return finishLoad(JSON.parse(JSON.stringify(p)) as T)
        })
        .catch((err) => setError(err))
        .finally(() => setLoading(false)) as Promise<T>
    },
    [setLoading, setError, finishLoad]
  )

  const patch = useCallback(
    (patch: Patch[], noDebounce?: boolean) => {
      let task = patchTasks.find((x) => x.options.projectId === project.id)
      if (task) {
        // Replace or add patch item
        patch.forEach((p) => {
          if (!task) return
          const idx = task.options.patch.findIndex((x) => x.propertyName === p.propertyName)
          if (idx >= 0) task.options.patch[idx] = p
          else task.options.patch.push(p)
        })
        task.options.added = Date.now()
        return task.pending
      }

      const stage = (options: PatchOptions) =>
        new Promise<PatchOptions>((resolve) => {
          if (noDebounce) {
            setSaving(true)
            resolve(options)
            return
          }

          const debounce = () => {
            if (Date.now() - options.added < 500) {
              setTimeout(debounce, 500)
              return
            }
            setSaving(true)
            resolve(options)
          }
          setTimeout(debounce, 500)
        })
          .then((options) => {
            const idx = patchTasks.findIndex((x) => x.options.id === options.id)
            if (idx >= 0) patchTasks.splice(idx, 1)
            return patchProject(options.accountId, options.projectId, options.patch, options.l)
          })
          .then((p) => finishLoad(p as T))
          .finally(() => setSaving(false))

      const options = {
        id: patchId++,
        added: Date.now(),
        accountId: account!.id,
        projectId: project.id,
        patch: [...patch],
        l: language
      }
      task = { options, pending: stage(options) }
      patchTasks.push(task)
      return task.pending
    },
    [project, account]
  )

  const setProject = useCallback(
    (project: T) => {
      finishLoad(project, true).then(() => {})
    },
    [setCurrent, finishLoad]
  )

  return {
    project,
    load,
    patch,
    setProject,
    loading,
    error
  }
}

const projectCountsAtom = atom<ProjectCounts[]>({
  key: 'projectCounts',
  default: []
})

export function useProjectCounts(): ProjectCounts[] {
  const [current, setCurrent] = useRecoilState(projectCountsAtom)

  const rights = useRights()
  const [account] = useCurrentUserAccount()
  const [offices] = useUserOffices()
  const [office] = useCurrentUserOffice()

  useEffect(() => {
    if (!rights || !account) return

    let officeIds: string[] | undefined
    if (account.type !== AccountType.Provider) {
      officeIds = office ? [office.id] : offices?.map((x) => x.id)
    }

    getProjectCounts(account.id, officeIds)
      .then((counts) => setCurrent(counts))
      .catch(() => {})
  }, [account, offices, office, rights, setCurrent])

  return current
}

export interface ProjectWatcherParams {
  accountId?: string
  onNew?: (accountId: string, projectId: string, byUserId: string) => void
  onUpdate?: (accountId: string, projectId: string, byUserId: string) => void
  onDelete?: (accountId: string, projectId: string, byUserId: string) => void
}

export interface ProjectWatcherHook {
  accountId?: string
  setAccount: (account?: Account | string) => void
}

export const useProjectWatcher = ({ accountId: initAccountId, onNew, onUpdate, onDelete }: ProjectWatcherParams): ProjectWatcherHook => {
  const [connection, setConnection] = useState<HubConnection>()

  const [accountId, setAccountId] = useState<string | undefined>(initAccountId)
  const setAccount = useCallback((account?: Account | string) => {
    if (typeof account === 'string') setAccountId(account)
    else setAccountId(account?.id)
  }, [])

  const handleNew = useCallback(
    (accountId: string, projectId: string, byUserId: string) => {
      if (onNew) onNew(accountId, projectId, byUserId)
    },
    [onNew]
  )

  const handleUpdate = useCallback(
    (accountId: string, projectId: string, byUserId: string) => {
      if (onUpdate) onUpdate(accountId, projectId, byUserId)
    },
    [onUpdate]
  )

  const handleDelete = useCallback(
    (accountId: string, projectId: string, byUserId: string) => {
      if (onDelete) onDelete(accountId, projectId, byUserId)
    },
    [onDelete]
  )

  const handleConnected = useCallback(() => {}, [connection])

  useEffect(() => {
    if (!accountId) return undefined

    setTimeout(() => {
      setConnection((current) => {
        if (current && current.state !== HubConnectionState.Disconnected) return current

        // Create connection instance
        return new HubConnectionBuilder()
          .withUrl(`${process.env.REACT_APP_PROJECT_API}/projects?aid=${accountId}`, {
            accessTokenFactory: () => getAccessToken(aadConfig().projectScopes).then((r) => r as string)
          })
          .withAutomaticReconnect()
          .configureLogging(LogLevel.Warning)
          .build()
      })
    })

    return () => {
      setConnection((current) => {
        if (current && current.state === HubConnectionState.Connected) {
          current.stop().then(() => {})
        }
        return undefined
      })
    }
  }, [accountId, setConnection])

  const [updateAt, setUpdatedAt] = useState(0)
  const updateAtRef = useRef(0)
  updateAtRef.current = updateAt

  useEffect(() => {
    if (!connection || connection.state !== HubConnectionState.Disconnected) return

    // Start the connection
    connection.start().then(() => {
      if (!connection) return

      connection.on('RecvNew', (accountId: string, projectId: string, byUserId: string, _updateAt: number) => {
        if (updateAtRef.current !== _updateAt) {
          handleNew(accountId, projectId, byUserId)
          setUpdatedAt(_updateAt)
        }
      })
      connection.on('RecvUpdate', (accountId: string, projectId: string, byUserId: string, _updateAt: number) => {
        if (updateAtRef.current !== _updateAt) {
          handleUpdate(accountId, projectId, byUserId)
          setUpdatedAt(_updateAt)
        }
      })
      connection.on('RecvDelete', (accountId: string, projectId: string, byUserId: string, _updateAt: number) => {
        if (updateAtRef.current !== _updateAt) {
          handleDelete(accountId, projectId, byUserId)
          setUpdatedAt(_updateAt)
        }
      })

      handleConnected()
    })
  }, [accountId, connection, updateAt])

  return {
    accountId,
    setAccount
  }
}

export interface ProjectNamesHook {
  accountId?: string
  names: ProjectName[]
}

export const useProjectNames = (): ProjectNamesHook => {
  const [account] = useCurrentUserAccount()
  const [names, setCurrent] = useRecoilState(projectNamesAtom)
  const rights = useRights()

  useEffect(() => {
    if (!account?.id) return
    if (rights?.userType !== UserType.provider) {
      setCurrent([])
      return
    }

    getProjectNames(account?.id).then((names) => {
      setCurrent(names)
    })
  }, [account, rights])

  return {
    accountId: account?.id,
    names
  }
}

export interface ProEstHook {
  proEstId?: number
  load: () => void
  estimate?: ProEstEstimate
  details?: ProEstEstimateDetails
  loading?: boolean
}

export const useProEst = (project?: ProjectEx, phase?: PhaseEx): ProEstHook => {
  const [current, setCurrent] = useRecoilState(proEstAtom)
  const [proEstId, setProEstId] = useState<number | undefined>(undefined)
  const [loading, setLoading] = useState(true)

  const load = useCallback(() => {
    if (!project || !phase) return

    if (!phase.proEst) {
      setProEstId(undefined)
      setCurrent(undefined)
      setLoading(false)
      return
    }

    setProEstId(phase.proEst.id)
    setLoading(true)
    getProEst(project.account.id, project.id!, phase.id!)
      .then((result) => setCurrent(result))
      .finally(() => setLoading(false))
  }, [project, phase])

  useEffect(() => load(), [project, phase])

  return {
    proEstId,
    load,
    estimate: current?.estimate,
    details: current?.details,
    loading
  }
}

export const budgetRangeToLowHigh = (r: string) => {
  if (r === 'Lt1M') return { low: 0, high: 1000000 }
  if (r === 'Gt5000M') return { low: 5000000000, high: -1 }

  const m = /In(\d+)Mto(\d+)M/.exec(r)
  return {
    low: parseInt(m![1], 10) * 1000000,
    high: parseInt(m![2], 10) * 1000000
  }
}
