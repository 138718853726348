import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/pro-duotone-svg-icons'
import Fuse from 'fuse.js'
import { getAccountUsers, getOffices, useProjectSupport } from 'services/src/api'
import { ProjectType } from 'services/src/dto/projectShare'

import { TextField } from 'components/src/textField'
import { AutoComplete } from 'components/src/autoComplete'
import { CardSelectField } from 'components/src/cardSelectField'
import { SelectField } from 'components/src/selectField'
import { Account, AccountType, Office, User } from 'services/src/dto/account'
import { useRights, UserType } from 'services/src/state'
import { useSaving } from 'components/src/saving'
import { AddressField } from 'components/src/addressField'
import { NewProjectType } from './NewProjectType'
import { Footer } from './Footer'
import { NewProjectModel } from './model'

export interface ScopeProps {
  model: NewProjectModel
  active: boolean
  step: number
  isLastStep: boolean
  onChangeModel: (model: NewProjectModel) => void
  onBack: () => void
  onNext: () => void
  onCancel: () => void

  offices: Office[]
  accounts?: Account[]
}

export const Scope: React.FC<ScopeProps> = ({ model, active, step, isLastStep, onChangeModel, onBack, onNext, onCancel, offices: initOffices, accounts }) => {
  const { t } = useTranslation()
  const [saving] = useSaving()
  const rights = useRights()

  const [{ projectTypes, constructionTypes, constructionCategories, deliveryMethods, estimateTypes, loading: isSupportLoading }] = useProjectSupport()

  const [offices, setOffices] = useState<Office[]>([...initOffices])
  useEffect(() => {
    setOffices([...initOffices])
  }, [initOffices])

  const [errors, setErrors] = useState<any>({})
  const clearError = (name: string) => {
    const theErrors = { ...errors }
    delete theErrors[name]
    setErrors(theErrors)
  }

  const [projectTypeName, setProjectTypeName] = useState(model.projectType?.type || '')
  const [projectTypeSuggestion, setProjectTypeSuggestions] = useState<ProjectType[]>([])

  const [newProjectType, setNewProjectType] = useState(false)
  const [aNewProjectType, setANewProjectType] = useState(model.ext.newProjectType)

  const [locationName] = useState(() => (model.location ? model.location.freeformAddress : ''))

  const [selectedConstructionTypes, setSelectedConstructionTypes] = useState<number[]>(() => {
    const idx = constructionTypes.findIndex((x) => x.id === model.ext.constructionType?.id)
    return idx >= 0 ? [idx] : []
  })

  const [accountUsers, setAccountUsers] = useState<User[]>([])

  useEffect(() => {
    if (!model.ext.account.selected?.id || isSupportLoading) return

    const clientPmId = model.ext?.account.pm?.id
    const officeId = model.office?.id

    onChangeModel({ ...model, office: undefined, ext: { ...model.ext, account: { ...model.ext.account, pm: undefined } } })

    Promise.all([getAccountUsers(model.ext.account.selected.id), getOffices(model.ext.account.selected.id, 0, 10000)])
      .then(([{ users }, { offices }]) => {

        setAccountUsers(users)
        const pm = users.find((u) => u.id === clientPmId) || (users.length === 1 ? users[0] : undefined)

        setOffices(offices)
        const office = offices.find((x) => x.id === officeId) || (offices.length === 1 ? offices[0] : undefined)

        onChangeModel({ ...model, office, ext: { ...model.ext, account: { ...model.ext.account, pm } } })
      })
  }, [model.ext.account.selected])

  const submit = useCallback( (e?: React.FormEvent) => {
    if (e) e.preventDefault()

    const theErrors: any = {}
    if (!model.name) theErrors.name = t('General.Errors.Required')
    else if (model.name.length < 3 || model.name.length > 255) theErrors.name = t('General.Errors.BetweenNAndMCharacters', { N: 3, M: 255 })

    if (!model.office) theErrors.office = t('General.Errors.Required')
    if (rights?.userType === UserType.provider) {
      if (!model.ext.account.pm) theErrors.clientPm = t('General.Errors.Required')
    }
    if (!model.projectType && !projectTypeName) theErrors.structureType = t('General.Errors.Required')
    else if (!model.projectType) theErrors.structureType = t('General.Errors.SelectOrAdd')
    if (!model.location) {
      if (!locationName) theErrors.location = t('General.Errors.Required')
      else theErrors.location = t('General.Errors.SelectLocation')
    }
    if (selectedConstructionTypes.length <= 0) theErrors.constructionType = t('General.Errors.Required')
    if (!model.ext.deliveryMethod) theErrors.deliveryMethod = t('General.Errors.Required')
    if (!model.ext.estimateType) theErrors.estimateType = t('General.Errors.Required')

    setErrors(theErrors)
    if (Object.keys(theErrors).length > 0) return

    onNext()
  }, [model, selectedConstructionTypes, setErrors, onNext, projectTypeName, rights, t, locationName])

  if (!active) return null

  const account = model.ext.account.selected;

  return (
    <>
      <div className="new-project-step-header">
        <div>{t('General.StepN', { N: step + 1 })}</div>
        <div>{t('NewProject.Scope.Title')}</div>
      </div>

      <form onSubmit={submit} noValidate autoComplete="off">
        <div className="ui-row">
          <div className="ui-col-12 ui-col-md-8">
            <TextField
              name="name"
              value={model.name || ''}
              error={errors.name}
              label={t('NewProject.Scope.Name')}
              placeholder={t('NewProject.Scope.NameSample')}
              disabled={saving}
              onChange={(name: string) => {
                onChangeModel({ ...model, name })
                clearError('name')
              }}
            />
          </div>
          <div className="ui-col-12 ui-col-md-4">
            <SelectField
              items={estimateTypes}
              disabled={saving}
              selected={model.ext.estimateType}
              labelField="type"
              valueField="id"
              placeholder={t('NewProject.Scope.EstimateTypeSample')}
              label={t('NewProject.Scope.EstimateType')}
              error={errors.estimateType}
              onSelectedChange={(estimateType) => {
                onChangeModel({ ...model, ext: { ...model.ext, estimateType } })
                clearError('estimateType')
              }}
            />
          </div>
        </div>

        <div className="ui-row">
          {rights?.accountType === AccountType.Provider && account && (
            <div className="ui-col-12 ui-col-md-4">
              <div className="ui-flex ui-flex-align-center">
                <div style={{ width: 60, minWidth: 60, paddingTop: 6 }}>
                  <div className="ui-picture-circle ui-picture-circle-md">
                    {account.logoUrl ? <div style={{ backgroundImage: `url('${account.logoUrl}')` }} /> : <div>{account.companyName[0].toUpperCase()}</div>}
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <label>{t('NewProject.account.Label')}</label>
                  <h4 className="ui-text-ellipsis ui-input ui-disabled">{account.companyName}</h4>
                </div>
              </div>
            </div>
          )}

          <div className={`ui-col-12${rights?.accountType === AccountType.Provider ? ' ui-col-md-4' : ''}`}>
            <SelectField
              items={offices || []}
              disabled={!account || saving}
              selected={account ? model.office : undefined}
              renderItem={(x) => <div>{x.name}</div>}
              renderSelected={(x) => x.name}
              placeholder={t('NewProject.Scope.OfficeSample')}
              label={t('NewProject.Scope.Office')}
              error={errors.office}
              onSelectedChange={(office) => {
                onChangeModel({ ...model, office })
                clearError('office')
              }}
            />
          </div>

          {rights?.accountType === AccountType.Provider && (
            <div className="ui-col-12 ui-col-md-4">
              <SelectField
                items={accountUsers || []}
                disabled={!account || saving}
                selected={model.ext.account.pm}
                renderItem={(x) => (
                  <div>
                    {x.givenName} {x.familyName}
                  </div>
                )}
                renderSelected={(x) => (
                  <div>
                    {x.givenName} {x.familyName}
                  </div>
                )}
                placeholder={t('NewProject.Scope.ClientPmSample')}
                label={t('NewProject.Scope.ClientPm')}
                error={errors.clientPm}
                onSelectedChange={(pm) => {
                  onChangeModel({ ...model, ext: { ...model.ext, account: { ...model.ext.account, pm } } })
                  clearError('clientPm')
                }}
              />
            </div>
          )}
        </div>

        <div className={`ui-form-group ${errors.structureType ? 'ui-has-error' : ''}`}>
          <label htmlFor="type">{t('NewProject.Scope.StructureType')}</label>
          <div className="ui-item-group www">
            <AutoComplete
              suggestions={projectTypeSuggestion}
              disabled={saving}
              value={projectTypeName}
              style={{ borderRight: 'none', width: '100%' }}
              onValueChange={(v) => {
                setProjectTypeName(v)
                clearError('structureType')

                onChangeModel({ ...model, projectType: undefined, ext: { ...model.ext, newProjectType: undefined } })
                setANewProjectType(undefined)

                if (!v) {
                  setProjectTypeSuggestions([])
                  return
                }

                if (projectTypes) {
                  const fuse = new Fuse(projectTypes, { keys: ['type'] })
                  const result = fuse.search(v.trim())
                  setProjectTypeSuggestions(result.map((x) => x.item))
                }
              }}
              placeholder={t('NewProject.Scope.StructureTypeSample')}
              onSelectSuggestion={(projectType) => {
                onChangeModel({ ...model, projectType, ext: { ...model.ext, newProjectType: undefined } })
                setANewProjectType(undefined)
                setProjectTypeName(projectType.type)
                setProjectTypeSuggestions([])
                clearError('structureType')
              }}
              render={(item: ProjectType) => (
                <div>
                  <FontAwesomeIcon icon={faMapMarkerAlt} style={{ opacity: saving ? 0.5 : 1 }} />
                  &nbsp;&nbsp;{item.type}
                </div>
              )}
            />

            <button
              className="ui-btn ui-btn-primary"
              type="button"
              style={{ width: 150 }}
              disabled={saving}
              onClick={() => {
                setNewProjectType(true)
              }}
            >
              <FontAwesomeIcon icon={faPlusCircle} />
              <span style={{ marginLeft: 5 }}>{t('General.AddNew')}</span>
            </button>
          </div>
          {errors.structureType && <div className="ui-error">{errors.structureType}</div>}
        </div>

        <AddressField
          address={model.location}
          disabled={saving}
          variant="addressOnly"
          countryLabel={t('NewProject.Scope.LocationCountry')}
          streetLabel={t('NewProject.Scope.Location')}
          streetSample={t('NewProject.Scope.LocationSample')}
          errors={{ street: errors.location }}
          onChange={(location) => {
            clearError('location')

            location = location || { countryCode: model.location?.countryCode }
            onChangeModel({ ...model, location })
          }}
        />

        <SelectField
          items={deliveryMethods || []}
          disabled={saving}
          selected={model.ext.deliveryMethod}
          labelField="method"
          valueField="id"
          placeholder={t('NewProject.Scope.DeliveryMethodSample')}
          label={t('NewProject.Scope.DeliveryMethod')}
          error={errors.deliveryMethod}
          onSelectedChange={(deliveryMethod) => {
            onChangeModel({ ...model, ext: { ...model.ext, deliveryMethod } })
            clearError('deliveryMethod')
          }}
        />

        <CardSelectField
          items={constructionTypes?.map((x) => x.type) || []}
          disabled={saving}
          selected={selectedConstructionTypes}
          onClick={(idx) => {
            setSelectedConstructionTypes([idx])
            onChangeModel({ ...model, ext: { ...model.ext, constructionType: constructionTypes[idx] } })
            clearError('constructionType')
          }}
          error={errors.constructionType}
          label={t('NewProject.Scope.ConstructionType')}
        />
      </form>

      <Footer canBack={step > 0} onBack={onBack} canNext={!isLastStep} onNext={submit} onCancel={onCancel} />

      {newProjectType && (
        <NewProjectType
          projectType={aNewProjectType}
          constructionCategories={constructionCategories}
          onNew={(projectType: Partial<ProjectType>) => {
            setNewProjectType(false)
            setANewProjectType(projectType as ProjectType)
            setProjectTypeName(projectType.type || '')
            onChangeModel({
              ...model,
              projectType: projectType as ProjectType,
              ext: { ...model.ext, newProjectType: projectType as ProjectType }
            })
          }}
          onClose={() => setNewProjectType(false)}
        />
      )}
    </>
  )
}
