import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostRequests } from 'services/src/api/boe';
import { endpoints } from 'services/src/lib/axios';
import { Loading } from '../../../loading';
import { TextAreaField } from '../../../textAreaField';
import { BoeProjectSectionSubheader } from './BoeProjectSectionSubheader';
import ProjectBoeProgressBar from './ProjectBoeProgressBar';

interface ProjectBoeMethodologySectionProps {
  handleNext: () => void;
  handlePrevious: () => void;
  setBoeData: (data: any) => void;
  boeData: any;
  isProgressBarVisible: boolean;
  setIsProgressBarVisible: (value: boolean) => void;
}

interface Errors {
  [key: string]: string | undefined;
  projectMethodology?: string;
  additionalFactors?: string;
}

const BoeProject15: React.FC<ProjectBoeMethodologySectionProps> = ({
  handleNext,
  handlePrevious,
  setBoeData,
  boeData,
  isProgressBarVisible,
  setIsProgressBarVisible,
}) => {
  const { t } = useTranslation();
  const progress = useMemo(() => 15, []);
  const [call, setCall] = useState<boolean>(false);
  const [projectMethodology, setMethodology] = useState<string>(boeData?.projectMethodology || '');
  const [errors, setErrors] = useState<Errors>({});
  const { loading, data, error, postRequests } = usePostRequests();

  const [additionalFactors, setAdditionalFactors] = useState<string>(boeData?.additionalFactors || '');
  const [showInput, setShowInput] = useState<boolean>(boeData?.showInput || false);

  const clearError = (name: string) => {
    setErrors((current) => {
      const newErrors = { ...current };
      delete newErrors[name];
      return newErrors;
    });
  };

  useEffect(() => {
    if (!call && projectMethodology === '') {
      setCall(true);
      postRequests(endpoints.boe.projectMethodology, { projectBackground: '' });
    }
  }, [call, postRequests, projectMethodology]);

  useEffect(() => {
    if (data?.response) {
      setMethodology(data?.response || '');
    }
  }, [data?.response]);

  const handleNextClick = () => {
    const newErrors: Errors = {};

    if (!projectMethodology) {
      newErrors.projectMethodology = 'This field is required';
    }

    if (showInput && !additionalFactors) {
      newErrors.additionalFactors = 'This field is required';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setBoeData((prev: any) => ({ ...prev, projectMethodology, additionalFactors, showInput }));
    handleNext();
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowInput(e.target.value === 'yes');
    if (e.target.value === 'no') {
      setAdditionalFactors('');
      clearError('additionalFactors');
    }
  };

  return (
    <div className="boe-content">
      <div className="boe-form-content">
        <ProjectBoeProgressBar
          progress={progress}
          title={t('BasisOfEstimate.Methodology.ProgressTitle')}
          leftText={t('BasisOfEstimate.Methodology.ProgressLeftText')}
          rightText={t('BasisOfEstimate.Methodology.ProgressRightText')}
          isProgressBarVisible={isProgressBarVisible}
          setIsProgressBarVisible={setIsProgressBarVisible}
          boeData={boeData}
        />
        
        <BoeProjectSectionSubheader title={t('BasisOfEstimate.BoeProject15.Title')} dynamicText={t('BasisOfEstimate.BoeProject15.Dynamic')} boeData={boeData} />

        {loading && <Loading />}
        {error && (
          <div className="boe-error-container">
            <p>{t('General.Error')}</p>
          </div>
        )}

        <TextAreaField
          name="projectMethodology"
          label=""
          placeholder=""
          autoFocus
          error={errors.projectMethodology}
          style={{ height: 324 }}
          value={projectMethodology}
          onChange={(e) => {
            setMethodology(e);
            clearError('projectMethodology');
          }}
        />

        <div className='boe-flex-col'>
          <h3>{t('BasisOfEstimate.BoeProject15.Question')}</h3>
          <div className='boe-flex-row boe-radio-label'>
            <label className='boe-flex-row'>
              <input
                type="radio"
                name="additionalFactors"
                value="yes"
                checked={showInput === true}
                onChange={handleRadioChange}
              />
              <p>{t('General.Yes')}</p>
            </label>
            <label className='boe-flex-row'>
              <input
                type="radio"
                name="additionalFactors"
                value="no"
                checked={showInput === false}
                onChange={handleRadioChange}
              />
              <p>{t('General.No')}</p>
            </label>
          </div>
        </div>
        {showInput && (
          <div className='boe-flex-col'>
            <TextAreaField
              name="additionalFactors"
              label=""
              placeholder=""
              autoFocus
              error={errors.additionalFactors}
              style={{ height: 324 }}
              value={additionalFactors}
              onChange={(e) => {
                setAdditionalFactors(e);
                clearError('additionalFactors');
              }}
            />
          </div>
        )}
      </div>
      <div className="boe-form-action-container">
        <button type="button" className="btn-next" onClick={handlePrevious}>
          {t('General.Previous')}
        </button>
        <button type="button" className="btn-previous" onClick={handleNextClick}>
          {t('General.Next')}
        </button>
      </div>
    </div>
  );
};

export default BoeProject15;