import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { faExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useProjectSupport } from 'services/src/api'
import { ProjectStatus } from 'services/src/dto/project'
import { PhaseEx, useEditProject, useRights, UserType } from 'services/src/state'
import { PhaseModal } from '../common/PhaseModal'

export interface NavProps {
  visible: boolean
  url: string
  onAddPhase: (phase: PhaseEx) => void
}

type ProjectNavPhase = PhaseEx & {
  isOpen?: boolean
}

export const ProjectNav: React.FC<NavProps> = ({ visible, url, onAddPhase }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const rights = useRights()

  const [{ phaseTypes }] = useProjectSupport()
  const { project } = useEditProject()

  const [phaseModal, setPhaseModal] = useState<PhaseEx>()

  const [phases, setPhases] = useState<ProjectNavPhase[]>([])
  useEffect(() => {
    setPhases((current) => {
      let hasOpen = false

      const phases = project.phases.map((ph) => {
        const isOpen = pathname.includes(`${url}/phases/${ph.id}`)
        if (isOpen) {
          hasOpen = true
          localStorage.setItem('activePhase', ph.id!)
        }
        return { ...ph, isOpen }
      })

      if (!hasOpen) {
        const open = current.find((x) => x.isOpen)
        if (!open) {
          const phId = localStorage.getItem('activePhase')
          if (!phId) phases[0].isOpen = true
          else {
            const ph = phases.find((ph) => ph.id === phId)
            if (ph) ph.isOpen = true
          }
        } else {
          const ph = phases.find((ph) => ph.id === open.id)
          if (ph) {
            ph.isOpen = true
            localStorage.setItem('activePhase', ph.id!)
          }
        }
      }
      return phases
    })
  }, [project, rights, pathname])

  if (!visible || !project) return null

  return (
    <>
      <aside className="edit-project-nav">
        <div>
          <div className="edit-project-nav-group" style={{ marginTop: 0 }}>
            <label>{t('General.Project')}</label>

            {project.expertOffer && (
              <Link to={`${url}/offer`} className={pathname === `${url}/offer` ? 'active' : ''}>
                <div className="ui-flex ui-flex-nowrap">
                  <div>{t(`EditProject.Offer`)}</div>

                  {project.expertOfferPending && (
                    <span
                      style={{
                        fontSize: 9,
                        display: 'inline-block',
                        marginLeft: 5,
                        width: 13,
                        lineHeight: '13px',
                        maxHeight: 13,
                        height: 13,
                        padding: 0,
                        border: 'none'
                      }}
                      className="ui-badge ui-badge-danger"
                    >
                      <FontAwesomeIcon icon={faExclamation} />
                    </span>
                  )}
                </div>
              </Link>
            )}

            <Link to={url} className={pathname === url || pathname === `${url}/edit` ? 'active' : ''}>
              {t(`EditProject.Overview`)}
            </Link>

            <Link to={`${url}/timeline`} className={pathname === `${url}/timeline` ? 'active' : ''}>
              <div className="ui-flex ui-flex-nowrap">
                <div>{t(`EditProject.Timeline.Title`)}</div>
                {!project.phases.length && (
                  <span
                    style={{
                      fontSize: 9,
                      display: 'inline-block',
                      marginLeft: 5,
                      width: 13,
                      lineHeight: '13px',
                      maxHeight: 13,
                      height: 13,
                      padding: 0,
                      border: 'none'
                    }}
                    className="ui-badge ui-badge-danger"
                  >
                    <FontAwesomeIcon icon={faExclamation} />
                  </span>
                )}
              </div>
            </Link>
            {(() => {
              if (!rights?.canViewFees || project.status === ProjectStatus.Draft) return null

              let showFees = false
              let showAlert: string | null = null
              project.phases.forEach((ph) => {
                if (rights?.userType === UserType.provider) {
                  if (
                    [
                      ProjectStatus.Accepted,
                      ProjectStatus.FeeSelected,
                      ProjectStatus.FeeReady,
                      ProjectStatus.FeeCounter,
                      ProjectStatus.Approved,
                      ProjectStatus.InProgress,
                      ProjectStatus.Complete,
                      ProjectStatus.Canceled
                    ].includes(ph.status!)
                  ) {
                    showFees = true
                    if ([ProjectStatus.FeeCounter].includes(ph.status!)) showAlert = t('EditProject.Fees.CounterOfferReady')
                  }
                } else if (rights?.userType === UserType.client) {
                  if ([ProjectStatus.FeeReady, ProjectStatus.FeeCounter, ProjectStatus.Approved, ProjectStatus.InProgress, ProjectStatus.Complete].includes(ph.status!)) {
                    showFees = true
                    if (ph.status === ProjectStatus.FeeReady) showAlert = t('EditProject.Fees.FeeReady')
                  }
                }
              })

              if (!showFees) return null

              return (
                <Link to={`${url}/fees`} className={pathname.endsWith(`${project.id}/fees`) ? 'active' : ''}>
                  {t(`EditProject.Fees.Title`)}
                  {showAlert && (
                    <div
                      className="ui-badge ui-badge-xs ui-badge-danger ui-text-bold"
                      title={showAlert}
                      style={{
                        width: 13,
                        height: 13,
                        maxWidth: 13,
                        maxHeight: 13,
                        minWidth: 13,
                        minHeight: 13,
                        lineHeight: '11px',
                        padding: 0,
                        marginLeft: 3,
                        marginTop: -3
                      }}
                    >
                      !
                    </div>
                  )}
                </Link>
              )
            })()}
            {(project.isGuest || project.canViewStaff) && (
              <Link to={`${url}/staff`} className={pathname === `${url}/staff` || pathname === `${url}/staff/invitations` ? 'active' : ''}>
                {t('General.Staff')}
              </Link>
            )}
          </div>

          <div className="edit-project-nav-group">
            <label>{t('General.Phases')}</label>
            {phases.map((ph, idx) => (
              <div key={ph.id} style={idx === 0 ? { marginTop: 0 } : undefined}>
                <Link
                  to={`${url}/phases/${ph.id}`}
                  className={`phase-item phase-item-${idx} ${pathname === `${url}/phases/${ph.id}` || pathname === `${url}/phases/${ph.id}/edit` ? 'active' : ''}`}
                  title={t(`EditProject.${ph.phaseType}`)}
                  style={{ paddingLeft: 20 }}
                >
                  {ph.name ? (
                    <div>
                      <div className="ui-text-bold">{t(`EditProject.${ph.phaseType}`)}</div>
                      <div className="ui-text-xs ui-text-italic ui-text-muted">{ph.name}</div>
                    </div>
                  ) : (
                    <div>
                      <div className="ui-text-bold">{t(`EditProject.${ph.phaseType}`)}</div>
                    </div>
                  )}
                </Link>
                <div className="phase-sub-items open">
                  {(() => {
                    if (project.status === ProjectStatus.Draft) return null

                    if (rights?.userType !== UserType.client) {
                      if (ph.estimates?.find((est) => est.labor?.find((l) => [ProjectStatus.InProgress, ProjectStatus.Complete].includes(l.status!))))
                        return (
                          <>
                            {rights?.userType === UserType.provider && (
                              <>
                              <Link to={`${url}/phases/${ph.id}/estimate`} className={pathname.includes(`${url}/phases/${ph.id}/estimate`) ? 'active' : ''}>
                                {t(`EditProject.Estimate`)}
                              </Link>
                              <Link to={`${url}/phases/${ph.id}/boe`} className={pathname.includes(`${url}/phases/${ph.id}/boe`) ? 'active' : ''}>
                                  {t(`EditProject.Boe`)}
                              </Link>
                              </>

                            )}

                            {rights?.userType === UserType.expert && (
                              <Link to={`${url}/phases/${ph.id}/expert-estimate`} className={pathname.includes(`${url}/phases/${ph.id}/expert-estimate`) ? 'active' : ''}>
                                {t('EditProject.Estimate')}
                              </Link>
                            )}
                          </>
                        )
                    } else if (ph.status === ProjectStatus.Complete || (ph.status === ProjectStatus.InProgress && ph.statusHistory![0].metadata?.clientReview === true))
                      return (
                        <Link to={`${url}/phases/${ph.id}/estimate`} className={`ui-text-bold ui-info${pathname.includes(`${url}/phases/${ph.id}/estimate`) ? ' active' : ''}`}>
                          {t(`EditProject.YourEstimate`)}
                        </Link>
                      )
                    return null
                  })()}
                  {(() => {
                    if (ph.status === ProjectStatus.New) return null

                    if (
                      rights?.userType === UserType.provider &&
                      [
                        ProjectStatus.Accepted,
                        ProjectStatus.FeeSelected,
                        ProjectStatus.FeeReady,
                        ProjectStatus.FeeCounter,
                        ProjectStatus.Approved,
                        ProjectStatus.InProgress,
                        ProjectStatus.Complete,
                        ProjectStatus.Canceled
                      ].includes(ph.status!)
                    ) {
                      if (!rights?.canViewFees) {
                        return (
                          <Link to={`${url}/phases/${ph.id}/assignments`} className={pathname.includes(`/phases/${ph.id}/assignments`) ? 'active' : ''}>
                            {t(`EditProject.Assignments.Title`)}
                          </Link>
                        )
                      }

                      return (
                        <Link to={`${url}/phases/${ph.id}/fees`} className={pathname.includes(`/phases/${ph.id}/fees`) ? 'active' : ''}>
                          {t(`EditProject.Fees.Title`)}
                        </Link>
                      )
                    }

                    return null
                  })()}
                  <Link to={`${url}/phases/${ph.id}/files/drawings`} className={pathname.includes(`${url}/phases/${ph.id}/files`) ? 'active' : ''}>
                    {t(`EditProject.Files`)}
                  </Link>
                  {(ph.isGuest || ph.canViewStaff) && (
                    <Link to={`${url}/phases/${ph.id}/staff`} className={pathname === `${url}/phases/${ph.id}/staff` || pathname === `${url}/phases/${ph.id}/staff/invitations` ? 'active' : ''}>
                      {t('General.Staff')}
                    </Link>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </aside>

      {phaseModal && (
        <PhaseModal
          phaseTypes={phaseTypes}
          project={project}
          phase={phaseModal}
          action="add"
          onClose={() => setPhaseModal(undefined)}
          onSubmit={(ph) => {
            onAddPhase(ph)
            setPhaseModal(undefined)
          }}
        />
      )}
    </>
  )
}
