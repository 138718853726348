import React, { useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare, faCalendar } from '@fortawesome/pro-regular-svg-icons'

import './style.scss'

import moment from 'moment'
import { StatusBadge } from 'components/src/statusBadge'
import { createProEst, getProEstTeam, useProjectSupport } from 'services/src/api'
import { EstimateEx, PhaseEx, useEditProject, useRights, UserType, useUsers } from 'services/src/state'

import { EstimateLabor, ProjectStatus } from 'services/src/dto/project'
import { useSaving } from 'components/src/saving'
import { Grid, GridColumn, GridContentRenderProps } from 'components/src/grid'
import { User } from 'services/src/dto/account'
import { Footer } from './Footer'
import { ProEstTeamEx } from 'services/src/dto/proEst'

interface DetailRow {
  estimate: EstimateEx
  labor?: EstimateLabor
  laborLabel?: string
  user?: User
  parent?: DetailRow
  inProEst?: boolean
}

const ProviderPhaseDetails: React.FC<{
  phase: PhaseEx
  proEstTeam?: ProEstTeamEx[]
}> = ({ phase, proEstTeam }) => {
  const { t } = useTranslation()

  const [rows, setRows] = useState<DetailRow[]>([])

  useEffect(() => {
    const makeLaborLabel = (row: DetailRow) => {
      if (!row.labor) return undefined
      if (row.labor.skills?.length) {
        if (row.labor.skills?.length === 1) return row.labor.skills[0]
        if (row.labor.skills?.length === 2) return `${row.labor.skills[0]} and ${row.labor.skills[1]}`

        const s = [...row.labor.skills]
        const last = s[s.length - 1]
        s.splice(s.length - 1, 1)
        return `${s.join(', ')}, and ${last}`
      }
      return '--'
    }

    const inProEst = (user?: User) => {
      if (!proEstTeam || !user) return undefined

      return !!proEstTeam.find((u) => {
        const e = u.email.toLowerCase()
        const [n, d] = e.split('@')
        const idx = n.indexOf('+')
        if (idx < 0) return e === user.emails![0].email.toLowerCase()
        const nx = n.substring(0, idx)
        return `${nx}@${d}` === user.emails![0].email.toLowerCase()
      })
    }

    const estimates = phase.estimates?.filter((est) => (est.labor?.length || 0) > 0) || []

    const rows: DetailRow[] = []

    estimates.forEach((est) => {
      const labor = est.labor!.length === 1 ? est.labor![0] : undefined
      // if (labor?.status === ProjectStatus.Rejected) return;

      const row: DetailRow = {
        estimate: { ...est },
        labor,
        user: labor?.user,
        inProEst: inProEst(labor?.user)
      }
      row.laborLabel = makeLaborLabel(row)
      rows.push(row)

      if (est.labor!.length > 1) {
        est.labor!.forEach((l) => {
          // if (l?.status === ProjectStatus.Rejected) return;
          const r: DetailRow = {
            estimate: { ...est },
            labor: l,
            parent: row,
            user: labor?.user,
            inProEst: inProEst(labor?.user)
          }
          r.laborLabel = makeLaborLabel(r)
          rows.push(r)
        })
      }
    })

    setRows(rows)
  }, [phase, proEstTeam])

  if (!rows?.length) return null

  return (
    <>
      {rows.length > 0 && <label>{t('EditProject.Fees.Labor')}</label>}
      <Grid rowData={rows}>
        <GridColumn
          field="discipline"
          name={t('Expert.Overview.FocusOrDiscipline')}
          width={250}
          minWidth={250}
          flex={1}
          render={({ data: { estimate, labor, laborLabel, parent } }: GridContentRenderProps<DetailRow>) => (
            <>
              {!parent ? (
                <div>
                  {t(`General.Disciplines.${estimate.discipline}`)}
                  {laborLabel === undefined || laborLabel === '--' ? (
                    ''
                  ) : (
                    <span style={{ marginLeft: 10 }} className="ui-text-muted ui-text-xs">
                      ({laborLabel})
                    </span>
                  )}
                </div>
              ) : (
                <div style={{ paddingLeft: labor ? 10 : 0 }} className={`${laborLabel ? '' : 'ui-text-bold ui-text-uppercase'}${labor?.status === ProjectStatus.Rejected ? ' ui-text-muted' : ''}`}>
                  {laborLabel || t(`General.Disciplines.${estimate.discipline}`)}
                </div>
              )}
            </>
          )}
        />

        <GridColumn
          field="expert"
          name={t('General.Expert')}
          width={200}
          minWidth={150}
          render={({ data: { user, labor } }: GridContentRenderProps<DetailRow>) => {
            if (!user) return null
            return (
              <div className={`ui-flex${labor?.status === ProjectStatus.Rejected ? ' ui-text-muted' : ''}`} style={{ alignItems: 'center' }}>
                {user.pictureUrl ? (
                  <div className="ui-picture-circle">
                    <div style={{ backgroundImage: `url("${user.pictureUrl}")` }} />
                  </div>
                ) : (
                  <div className="ui-picture-circle">
                    <div>{`${user.givenName[0]}${user.familyName[0]}`}</div>
                  </div>
                )}
                <div style={{ marginLeft: 5 }} className={labor?.status === ProjectStatus.Rejected ? 'ui-text-line-through' : ''}>
                  {`${user.givenName} ${user.familyName}`}
                </div>
              </div>
            )
          }}
        />

        <GridColumn
          field="proEstTeam"
          name={t('General.ProEstStatus')}
          width={120}
          minWidth={120}
          className="ui-text-center"
          render={({ data: { inProEst } }: GridContentRenderProps<DetailRow>) => {
            if (inProEst === undefined) return null
            return inProEst ? <div className="ui-success">{t('General.ProEstIn')}</div> : <div className="ui-danger">{t('General.ProEstNotIn')}</div>
          }}
        />

        <GridColumn
          field="expertStatus"
          name={t('Projects.EstimateStatus')}
          width={150}
          minWidth={150}
          render={({ data: { labor, user } }: GridContentRenderProps<DetailRow>) => {
            if (!labor) return null
            if (labor.status === ProjectStatus.New && user?.expertType === 'FTE') return <StatusBadge label={t('Expert.Labor.NewFTE')} type={labor.status} />
            return <StatusBadge label={t(`Expert.Labor.${labor.status}`)} type={labor.status} />
          }}
        />
      </Grid>
    </>
  )
}

const ExpertPhaseDetails: React.FC<{
  phase: PhaseEx
}> = ({ phase }) => {
  const { t } = useTranslation()
  const { getUser } = useUsers()

  const [rows, setRows] = useState<DetailRow[]>([])

  useEffect(() => {
    const makeLaborLabel = (row: DetailRow) => {
      if (!row.labor) return undefined
      if (row.labor.skills?.length) {
        if (row.labor.skills?.length === 1) return row.labor.skills[0]
        if (row.labor.skills?.length === 2) return `${row.labor.skills[0]} and ${row.labor.skills[1]}`

        const s = [...row.labor.skills]
        const last = s[s.length - 1]
        s.splice(s.length - 1, 1)
        return `${s.join(', ')}, and ${last}`
      }
      return '--'
    }
    const estimates = phase.estimates?.filter((est) => (est.labor?.length || 0) > 0) || []

    const userIds: string[] = []
    estimates.forEach((est) => {
      est.labor?.forEach((l) => {
        if (!l.userId) return

        if (!userIds.find((id) => id === l.userId)) userIds.push(l.userId!)
      })
    })

    getUser(userIds).then((users) => {
      const rows: DetailRow[] = []
      estimates.forEach((est) => {
        const labor = est.labor!.length === 1 ? est.labor![0] : undefined
        const row: DetailRow = {
          estimate: { ...est },
          labor,
          user: labor ? users.find((u) => u.id === labor.userId) : undefined
        }
        row.laborLabel = makeLaborLabel(row)
        rows.push(row)

        if (est.labor!.length > 1) {
          est.labor!.forEach((l) => {
            const r: DetailRow = {
              estimate: { ...est },
              labor: l,
              parent: row,
              user: users.find((u) => u.id === l.userId)
            }
            r.laborLabel = makeLaborLabel(r)
            rows.push(r)
          })
        }
      })

      setRows(rows)
    })
  }, [phase])

  if (!rows?.length) return null

  return (
    <>
      {rows.length > 0 && <label>{t('EditProject.Assignments.Title')}</label>}
      <Grid rowData={rows}>
        <GridColumn
          field="discipline"
          name={t('Expert.Overview.FocusOrDiscipline')}
          flex={1}
          width={200}
          minWidth={150}
          render={({ data: { estimate, labor, laborLabel, parent } }: GridContentRenderProps<DetailRow>) => (
            <>
              {!parent ? (
                <div>
                  {t(`General.Disciplines.${estimate.discipline}`)}
                  {laborLabel === undefined || laborLabel === '--' ? (
                    ''
                  ) : (
                    <span style={{ marginLeft: 10 }} className="ui-text-muted ui-text-xs">
                      ({laborLabel})
                    </span>
                  )}
                </div>
              ) : (
                <div style={{ paddingLeft: labor ? 10 : 0 }} className={`${laborLabel ? '' : 'ui-text-bold ui-text-uppercase'}${labor?.status === ProjectStatus.Rejected ? ' ui-text-muted' : ''}`}>
                  {laborLabel || t(`General.Disciplines.${estimate.discipline}`)}
                </div>
              )}
            </>
          )}
        />

        <GridColumn
          field="expertStatus"
          name={t('Projects.EstimateStatus')}
          width={150}
          minWidth={150}
          render={({ data: { labor, user } }: GridContentRenderProps<DetailRow>) => {
            if (!labor) return null
            if (labor.status === ProjectStatus.New && user?.expertType === 'FTE') return <StatusBadge label={t('Expert.Labor.NewFTE')} type={labor.status} />
            return <StatusBadge label={t(`Expert.Labor.${labor.status}`)} type={labor.status} />
          }}
        />
      </Grid>
    </>
  )
}

export const PhaseOverview: React.FC<{ phaseId: string }> = ({ phaseId }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { pathname } = useLocation()
  const rights = useRights()
  const [saving, setSaving] = useSaving()

  const [{ phaseTypes }] = useProjectSupport()
  const { project } = useEditProject()

  const [proEstTeam, setProEstTeam] = useState<ProEstTeamEx[]>()
  useEffect(() => {
    getProEstTeam().then((t) => setProEstTeam(t))
  }, [getProEstTeam])

  const [phase, setPhase] = useState(project.phases.find((x) => x.id === phaseId))
  useEffect(() => {
    const phase = project.phases.find((x) => x.id === phaseId)
    if (!phase) {
      history.goBack()
      return
    }
    setPhase(phase)
  }, [project, phaseId, setPhase])

  const phaseTypeName = useMemo(() => phaseTypes.find((x) => x.id.toLowerCase() === phase?.phaseType?.toLowerCase())?.type, [phaseTypes, phase])

  const { description, startedAt, completedAt, canceledAt } = useMemo(() => {
    if (!phase)
      return {
        description: null,
        startedAt: null,
        completedAt: null,
        canceledAt: null
      }

    const shx = phase.statusHistory?.filter((x) => x.newStatus === ProjectStatus.InProgress) || []

    let sh = shx.length ? shx[shx.length - 1] : undefined
    const startedAt = sh ? moment(sh.createdAt).toDate() : null

    sh = phase.statusHistory?.find((x) => x.newStatus === ProjectStatus.Complete)
    const completedAt = sh ? moment(sh.createdAt).toDate() : null

    sh = phase.statusHistory?.find((x) => x.newStatus === ProjectStatus.Canceled)
    const canceledAt = sh ? moment(sh.createdAt).toDate() : null

    const lines = phase.description?.length ? phase.description?.split('\n') : []
    return {
      description:
        lines.length > 0 ? (
          <div style={{ marginTop: 30 }}>
            <label>{t('Phase.Description')}</label>
            <div className="phase-overview-description">
              {lines.map((l, idx) => {
                l = l.trim()
                return !l ? null : <p key={idx}>{l}</p>
              })}
            </div>
          </div>
        ) : null,
      startedAt,
      completedAt,
      canceledAt
    }
  }, [phase, t])

  if (!phase) return null

  return (
    <>
      <div className="phase-overview">
        <div className="ui-flex">
          <div className="ui-form-group" style={{ flex: 1 }}>
            <label>{t('Phase.Type')}</label>
            <div>{phaseTypeName}</div>
          </div>
          <div className="ui-form-group">
            <StatusBadge
              prefixLabel={
                <>
                  {t('General.Phase')}
                  <br />
                  {t('Projects.Status')}
                </>
              }
              label={phase.statusHistory![0].oldStatus === ProjectStatus.FeeCounter ? t('Projects.ClientStatus.FeeCounterDecline') : t(`Projects.ClientStatus.${phase.status}`)}
              type={phase.statusHistory![0].oldStatus === ProjectStatus.FeeCounter ? 'danger' : phase.status}
            />
          </div>
        </div>

        {phase.name && (
          <div className="ui-form-group">
            <label>{t('General.Name')}</label>
            <div>{phase.name}</div>
          </div>
        )}

        <hr />
        <div className="ui-flex">
          <div style={{ width: 200 }}>
            <label>{t('General.DrawingsRequiredBy')}</label>
            <div>
              <span style={{ marginRight: 10 }} className="ui-info">
                <FontAwesomeIcon icon={faCalendar} />
              </span>
              {moment(phase.submissionDate).format('LL')}
            </div>
          </div>
          <div style={{ width: 200 }}>
            <label>{t('General.DeliveryDate')}</label>
            <div>
              <span style={{ marginRight: 10 }} className="ui-danger">
                <FontAwesomeIcon icon={faCalendar} />
              </span>
              {moment(phase.deliveryDate).format('LL')}
            </div>
          </div>

          <div style={{ marginLeft: 'auto', width: 200 }}>
            <label>{t('EditProject.StartedAt')}</label>
            <div>
              <span style={{ marginRight: 10 }} className="ui-success">
                <FontAwesomeIcon icon={faCalendar} />
              </span>
              {startedAt ? moment(startedAt).format('LL') : '--'}
            </div>
          </div>

          {canceledAt ? (
            <div style={{ width: 200 }}>
              <label>{t('EditProject.CanceledAt')}</label>
              <div>
                <span style={{ marginRight: 10 }} className="ui-primary">
                  <FontAwesomeIcon icon={faCalendar} />
                </span>
                {moment(canceledAt).format('LL')}
              </div>
            </div>
          ) : (
            <div style={{ width: 200 }}>
              <label>{t('EditProject.CompletedAt')}</label>
              <div>
                <span style={{ marginRight: 10 }} className="ui-success">
                  <FontAwesomeIcon icon={faCalendar} />
                </span>
                {completedAt ? moment(completedAt).format('LL') : '--'}
              </div>
            </div>
          )}
        </div>
        <hr />

        {phase.variations && phase.variations.length > 0 && (
          <div className="ui-form-group" style={{ marginTop: 30 }}>
            <label>{t('EditProject.Timeline.Variations')}</label>
            <div className="wrapped-text filled">{t('EditProject.Timeline.VariationCount', { count: phase.variations.length })}</div>
            {phase.variations.map((v, idx) => {
              if (!v.description) return null

              return (
                <div key={idx} className="ui-flex ui-flex-nowrap ui-text-sm" style={{ marginTop: 10 }}>
                  <div className="ui-frame ui-frame-no-padding ui-text-center" style={{ width: 20, height: 24, lineHeight: '21px', padding: 0 }}>
                    {idx + 1}
                  </div>
                  <div style={{ flex: 1, marginTop: 3, marginLeft: 10 }}>{v.description}</div>
                </div>
              )
            })}
          </div>
        )}

        {description}

        {rights?.userType !== UserType.client && phase.proEst?.id && (
          <div style={{ marginTop: 40 }}>
            <a
              href={`https://cloud.proest.com/projects/${phase.proEst.id}/estimate`}
              target="proEst"
              style={{ marginRight: 20, paddingLeft: 20, paddingRight: 20, width: 200, maxWidth: 200 }}
              className="ui-btn ui-btn-info ui-btn-solid"
            >
              {t('Projects.WorkOnEstimate')}&nbsp;&nbsp;
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </a>
          </div>
        )}

        {rights?.userType === UserType.provider && (
          <>
            {!phase.proEst?.id && (
              <div style={{ marginTop: 40 }}>
                <a
                  href="https://cloud.proest.com/app/projects"
                  target="proEst"
                  style={{ marginRight: 20, paddingLeft: 20, paddingRight: 20, width: 200, maxWidth: 200 }}
                  className="ui-btn ui-btn-info ui-btn-solid"
                  onClick={(e) => {
                    e.preventDefault()

                    setSaving(true)
                    createProEst(project.account.id, project.id!, phase.id!)
                      .then(({ estimate }) => {
                        if (estimate.id) window.open(`https://cloud.proest.com/projects/${estimate.id}/estimate`, 'proEst')
                      })
                      .finally(() => setSaving(false))
                  }}
                >
                  {t('Projects.WorkOnEstimate')}&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </a>
              </div>
            )}
            <div style={{ marginTop: 40 }}>
              <ProviderPhaseDetails phase={phase} proEstTeam={proEstTeam} />
            </div>
          </>
        )}

        {rights?.userType === UserType.expert && (
          <div style={{ marginTop: 40 }}>
            <ExpertPhaseDetails phase={phase} />
          </div>
        )}
      </div>

      {(phase.canEdit || phase.canSubmit) && (
        <Footer>
          <div className="ui-flex ui-flex-nowrap">
            <div style={{ marginLeft: 'auto' }} />
            {phase.status === ProjectStatus.New && phase.canSubmit && (
              <button
                type="button"
                className="ui-btn ui-btn-info ui-btn-solid"
                disabled={saving}
                style={{ marginRight: 10, width: 150 }}
                onClick={() => {
                  if (phase.id) window.dispatchEvent(new CustomEvent('submitPhase', { detail: { phaseId: phase.id } }))
                }}
              >
                {t('General.SubmitPhase')}
              </button>
            )}

            {rights?.userType === UserType.provider && (
              <>
                {project.status !== ProjectStatus.Submitted && phase.status === ProjectStatus.Submitted && (
                  <button
                    type="button"
                    className="ui-btn ui-btn-info ui-btn-solid"
                    disabled={saving}
                    style={{ marginRight: 10, width: 'auto' }}
                    onClick={() => {
                      if (phase.id) window.dispatchEvent(new CustomEvent('acceptPhase', { detail: { phaseId: phase.id } }))
                    }}
                  >
                    {t('General.AcceptPhase')}
                  </button>
                )}

                {phase.canStart && (
                  <button
                    type="button"
                    className="ui-btn ui-btn-info ui-btn-solid"
                    disabled={saving}
                    style={{ marginRight: 10, width: 150 }}
                    onClick={() => {
                      if (phase.id) window.dispatchEvent(new CustomEvent('startPhase', { detail: { phaseId: phase.id } }))
                    }}
                  >
                    {t('General.StartPhase')}
                  </button>
                )}

                {phase.status === ProjectStatus.InProgress && (
                  <button
                    type="button"
                    className="ui-btn ui-btn-info ui-btn-solid"
                    disabled={saving}
                    style={{ marginRight: 10, width: 'auto' }}
                    onClick={() => {
                      if (phase.id) window.dispatchEvent(new CustomEvent('completePhase', { detail: { phaseId: phase.id } }))
                    }}
                  >
                    {t('General.CompletePhase')}
                  </button>
                )}
              </>
            )}

            {((phase.canEdit && !project.isGuest) || !phase.isGuest) && (
              <Link
                to={`${pathname}/edit`}
                style={{ width: 150 }}
                className={`ui-btn ui-btn-secondary ui-btn-solid${saving ? ' ui-disabled' : ''}`}
                onClick={(e) => {
                  if (saving) e.preventDefault()
                }}
              >
                {t('General.EditDetails')}
              </Link>
            )}
          </div>
        </Footer>
      )}
    </>
  )
}
