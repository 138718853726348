import { ProjectStatus } from 'services/src/dto/project'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import { useRights, UserType } from 'services/src/state'

export const projectListStatusFilterItems = [
  ProjectStatus.Draft,
  ProjectStatus.Submitted,
  ProjectStatus.Accepted,
  ProjectStatus.FeeReady,
  ProjectStatus.FeeCounter,
  ProjectStatus.Approved,
  ProjectStatus.InProgress,
  ProjectStatus.Complete
]

export const expertProjectListStatusFilterItems = [ProjectStatus.Approved, ProjectStatus.InProgress, ProjectStatus.Complete]

export const ProjectListStatusFilter: React.FC<{
  selected: ProjectStatus[]
  onToggle: (status: ProjectStatus) => void
  canClear?: boolean
  onClear: () => void
  search: string
  onSearchChange: (search: string) => void
}> = ({ selected, onToggle, canClear, onClear, search, onSearchChange }) => {
  const { t } = useTranslation()
  const rights = useRights()

  if (!rights) return null

  return (
    <div className="project-index-status-filter">
      <div style={{ marginRight: 10 }}>
        <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <input
            type="text"
            className="ui-input-xs"
            style={{ maxWidth: 200, minWidth: 100, width: 150 }}
            value={search}
            onChange={(e) => onSearchChange(e.currentTarget.value)}
            placeholder={t('Projects.Search')}
          />
        </form>
      </div>
      <div className="divider" style={{ marginRight: 8 }} />
      <div className="ui-flex ui-flex-nowrap">
        {(rights?.userType === UserType.expert ? expertProjectListStatusFilterItems : projectListStatusFilterItems).map((s) => (
          <button key={s} type="button" style={{ marginRight: 5 }} className={`ui-btn ui-btn-xs${selected.includes(s) ? ' ui-btn-pressed ui-btn-info' : ''}`} onClick={() => onToggle(s)}>
            {rights?.userType === UserType.client ? t(`Projects.ClientStatus.${s}`) : t(`Projects.${s}`)}
          </button>
        ))}
      </div>
      <div className="ui-flex ui-flex-nowrap" style={{ marginLeft: 'auto' }}>
        <button type="button" className={`ui-btn ui-btn-xs${canClear ? ' ui-btn-pressed ui-btn-info' : ''}`} onClick={() => onClear()}>
          <FontAwesomeIcon icon={faTimesCircle} />
          <span style={{ marginLeft: 5 }}>{t('General.ClearFilter')}</span>
        </button>
      </div>
    </div>
  )
}
