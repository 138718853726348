import React, { useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import highchartsSolidGauge from 'highcharts/modules/solid-gauge'
import HighchartsReact from 'highcharts-react-official'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faConstruction, faSyncAlt } from '@fortawesome/pro-regular-svg-icons'

import './style.scss'
import { useCurrentUserAccount, useCurrentUserOffice, useProjectCounts, useRights } from 'services/src/state'
import LoadingSvg from 'components/src/svg/Loading'
import { makeDashboardPath } from 'services/src/dom'
import { ProjectCounts, ProjectStatus } from 'services/src/dto/project'
import { AccountType } from 'services/src/dto/account'

const CELL_HEIGHT = 230

highchartsMore(Highcharts)
highchartsSolidGauge(Highcharts)

const colors: { [key: string]: string } = {
  [ProjectStatus.Draft]: '#777777',
  [ProjectStatus.Submitted]: '#DF7F26',
  [ProjectStatus.Accepted]: '#2686DF',
  [ProjectStatus.FeeReady]: '#7E57C2',
  [ProjectStatus.FeeCounter]: '#a11c1f',
  [ProjectStatus.Approved]: '#097913',
  [ProjectStatus.InProgress]: '#2aab36',
  [ProjectStatus.Complete]: '#528611',
  [ProjectStatus.Canceled]: '#777777'
}

const theme: Highcharts.Options = {
  credits: {
    enabled: false
  },
  colors: [
    colors[ProjectStatus.Draft],
    colors[ProjectStatus.Submitted],
    colors[ProjectStatus.Accepted],
    colors[ProjectStatus.FeeReady],
    colors[ProjectStatus.FeeCounter],
    colors[ProjectStatus.Approved],
    colors[ProjectStatus.InProgress],
    colors[ProjectStatus.Complete],
    colors[ProjectStatus.Canceled]
  ],
  chart: {
    backgroundColor: {
      linearGradient: { x1: 0, x2: 0, y1: 500, y2: 500 },
      stops: [
        [0, 'rgb(255, 255, 255)'],
        [1, 'rgb(240, 240, 255)']
      ]
    }
  },
  title: {
    style: {
      color: '#000',
      font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
    }
  },
  subtitle: {
    style: {
      color: '#666666',
      font: 'bold 12px "Trebuchet MS", Verdana, sans-serif'
    }
  },
  legend: {
    itemStyle: {
      font: '9pt Trebuchet MS, Verdana, sans-serif',
      color: 'black'
    },
    itemHoverStyle: {
      color: 'gray'
    }
  }
}

Highcharts.setOptions(theme)

const makeOptions = (color: string, count: number, total: number, highlight: boolean, onClick: () => void): Highcharts.Options => ({
  chart: {
    type: 'solidgauge',
    backgroundColor: 'none',
    height: highlight ? 180 : 120,
    events: {
      click: () => onClick()
    }
  },
  title: { text: undefined },
  tooltip: { enabled: false },
  pane: {
    startAngle: 0,
    endAngle: 360,
    center: ['50%', '50%'],
    background: [
      {
        outerRadius: '100%',
        innerRadius: '85%',
        backgroundColor: Highcharts.color(color).setOpacity(0.3).get(),
        borderWidth: 0
      }
    ]
  },
  yAxis: {
    min: 0,
    max: 100,
    lineWidth: 0,
    tickPositions: []
  },
  plotOptions: {
    solidgauge: {
      dataLabels: {
        enabled: true,
        borderWidth: 0,
        color,
        padding: 0,
        verticalAlign: 'middle',
        style: {
          fontSize: '30px',
          fontWeight: 'normal'
        },
        format: count?.toString() || '0'
      },
      linecap: 'round',
      stickyTracking: false,
      rounded: true,
      events: {
        click: () => onClick()
      }
    }
  },
  series: [
    {
      name: 'Count',
      type: 'solidgauge',
      data: [
        {
          color,
          radius: '100%',
          innerRadius: '85%',
          y: count ? Math.floor((count / total) * 100) : 0
        }
      ]
    }
  ]
})

export const ProjectCountCharts: React.FC<{
  className?: string
}> = ({ className }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [account] = useCurrentUserAccount()
  const [office] = useCurrentUserOffice()
  const rights = useRights()
  const projectCounts = useProjectCounts()

  const [counts, setCounts] = useState<ProjectCounts>()
  const [inError] = useState(false)

  useEffect(() => {
    if (!account?.id) return

    if (office?.id) {
      const counts = projectCounts.find((x) => x.officeId === office.id)
      if (counts) {
        setCounts(counts)
        return
      }
    }

    const counts: ProjectCounts = {
      accountId: account!.id,
      providerId: account!.id,
      total: 0,
      draft: 0,
      submitted: 0,
      accepted: 0,
      feeReady: 0,
      feeCounter: 0,
      approved: 0,
      inProgress: 0,
      complete: 0,
      rejected: 0,
      canceled: 0
    }
    projectCounts
      .filter((x: any) => x.accountId === account.id || x.providerId === account.id)
      .forEach((c) => {
        counts.total += c.total || 0
        counts.draft += c.draft || 0
        counts.submitted += c.submitted || 0
        counts.accepted += c.accepted || 0
        counts.feeReady += c.feeReady || 0
        counts.feeCounter += c.feeCounter || 0
        counts.approved += c.approved || 0
        counts.inProgress += c.inProgress || 0
        counts.complete += c.complete || 0
        counts.rejected += c.rejected || 0
        counts.canceled += c.canceled || 0
      })
    setCounts(counts)
  }, [projectCounts, account, office])

  const handleClick = useCallback(
    (status: ProjectStatus) => {
      history.push(makeDashboardPath(account?.id, `projects?status=${status}`))
    },
    [account, history]
  )

  return (
    <div className={`dashboard-home-card${className ? ` ${className}` : ''}`}>
      <div className="dashboard-home-charts">
        {/* eslint-disable-next-line no-nested-ternary */}
        {inError ? (
          <div style={{ height: CELL_HEIGHT }} className="dashboard-home-cell-error">
            <button type="button" tabIndex={-1} className="recycle" onClick={() => {}}>
              <FontAwesomeIcon icon={faSyncAlt} />
            </button>
            <div>{t(`Dashboard.Errors.LoadFailed`)}</div>
          </div>
        ) : !counts || projectCounts.length <= 0 ? (
          <div className="dashboard-home-cell-loading">
            <LoadingSvg style={{ width: 30 }} />
          </div>
        ) : (
          <div className="ui-row" style={{ minHeight: CELL_HEIGHT }}>
            <div className="ui-col-12 ui-col-lg-3 totals ui-text-center">
              <div className="label">{t(`Dashboard.ProjectCountCharts.TotalProjects`)}</div>
              <div className="total">{counts.total}</div>
              {rights?.canCreateProject && (
                <div className="ui-flex" style={{ fontWeight: 'normal', marginTop: 30, justifyContent: 'center' }}>
                  <Link to={makeDashboardPath(account?.id, 'projects/new')} className="ui-btn ui-btn-secondary ui-btn-solid" style={{ width: 200 }}>
                    <FontAwesomeIcon icon={faConstruction} />
                    <span style={{ marginLeft: 5 }}>{t(`Dashboard.ProjectCountCharts.CreateProject`)}</span>
                  </Link>
                </div>
              )}
            </div>

            <div className="ui-col-12 ui-col-lg-9">
              <div className="ui-row">
                {[
                  ProjectStatus.Submitted,
                  ProjectStatus.Accepted,
                  ProjectStatus.FeeReady,
                  ProjectStatus.FeeCounter,
                  ProjectStatus.Approved,
                  ProjectStatus.InProgress,
                  ProjectStatus.Complete,
                  ProjectStatus.Draft,
                  ProjectStatus.Canceled
                ].map((key, idx) => {
                  if (!Object.keys(counts).find((k) => k.toLowerCase() === key.toLowerCase())) return null

                  const k = (() => {
                    switch (key) {
                      case ProjectStatus.FeeReady:
                        return 'feeReady'
                      case ProjectStatus.FeeCounter:
                        return 'feeCounter'
                      case ProjectStatus.InProgress:
                        return 'inProgress'
                      default:
                        return key.toLowerCase()
                    }
                  })()

                  const count = (counts as any)[k]

                  const highlight = idx < 3

                  if (highlight)
                    return (
                      <div key={key} className="ui-col-4 ui-col-lg-4 chart">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={{
                            ...makeOptions(colors[key]!, count, counts.total, highlight, () => handleClick(key))
                          }}
                        />
                        <h5>{account?.type === AccountType.Client ? t(`Dashboard.ProjectCountCharts.${key}`) : t(`Dashboard.ProviderProjectCountCharts.${key}`)}</h5>
                      </div>
                    )

                  return (
                    <div key={key} className="ui-col-6 ui-col-lg-2 chart">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                          ...makeOptions(colors[key]!, count, counts.total, highlight, () => handleClick(key))
                        }}
                      />
                      <h5>{account?.type === AccountType.Client ? t(`Dashboard.ProjectCountCharts.${key}`) : t(`Dashboard.ProviderProjectCountCharts.${key}`)}</h5>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
