import React, { useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ProjectContentEx, useRights, UserType } from 'services/src/state'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCompress,
  faExpand,
  faMinusCircle,
  faPlusCircle,
  faRotateLeft,
  faRotateRight,
  faSquareCode,
  faChevronDoubleLeft,
  faChevronLeft,
  faChevronRight,
  faCheckCircle,
  faTimesCircle,
  faCircleExclamationCheck
} from '@fortawesome/pro-regular-svg-icons'
import { NumberParam, useQueryParam } from 'use-query-params'
import { useTranslation } from 'react-i18next'
import { getPageDisciplines } from 'services/src/common'
import { DisciplineKey } from 'services/src/dto/project'
import { decZoom, incZoom } from './common'
import { Select } from '../select'
import { useSaving } from '../saving'

export const DocumentViewerToolbar: React.FC<{
  content?: ProjectContentEx
  next?: ProjectContentEx
  prev?: ProjectContentEx
  zoom: number
  rotate: number
  view: string
  back?: string
  onTagDiscipline?: (content: ProjectContentEx, discipline?: DisciplineKey) => void
}> = ({ content, next, prev, zoom, rotate, view, back, onTagDiscipline }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [g] = useQueryParam('g', NumberParam)
  const rights = useRights()
  const [saving] = useSaving()

  const disciplines = useMemo(() => getPageDisciplines(t), [t])

  const hasControls = useMemo(() => {
    if (!content) return false

    return content?.contentType.endsWith('/pdf') || content?.contentType.startsWith('image/')
  }, [content])

  if (!content)
    return (
      <div className="ui-document-view-toolbar">
        <div>&nbsp;</div>
      </div>
    )

  return (
    <div className="ui-document-view-toolbar ui-no-select">
      <div style={{}}>
        {back && (
          <Link className="ui-action-item ui-secondary" style={{ marginRight: 10 }} to={back}>
            <FontAwesomeIcon icon={faChevronDoubleLeft} />
          </Link>
        )}
        {prev && (
          <Link className="ui-action-item ui-secondary" style={{ marginRight: 10 }} to={`${history.location.pathname.replace(content.id, prev.id)}${g ? '?g=1' : ''}`}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </Link>
        )}
        {next && (
          <Link className="ui-action-item ui-secondary" style={{ marginRight: 10 }} to={`${history.location.pathname.replace(content.id, next.id)}${g ? '?g=1' : ''}`}>
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        )}
      </div>
      <div className="name ui-text-ellipsis" style={{ minWidth: 100 }}>
        {content.originalName}
      </div>

      {hasControls && (
        <>
          {content.parentId && (
            <>
              {rights?.userType === UserType.provider && content.classification && !content.disciplineById && (
                <div className="ui-flex" style={{ marginRight: 10, alignItems: 'center' }}>
                  <div
                    style={{ marginRight: 5 }}
                    className={`ui-text-xs ${
                      // eslint-disable-next-line no-nested-ternary
                      content.score! > 0.75 ? 'ui-success' : content.score! > 0.5 ? 'ui-warn' : 'ui-danger'
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={
                        // eslint-disable-next-line no-nested-ternary
                        content.score! > 0.75 ? faCheckCircle : content.score! > 0.5 ? faMinusCircle : faTimesCircle
                      }
                    />
                    &nbsp;
                    {t(`General.Disciplines.${content.classification}`)}
                    &nbsp; ({t('General.PercentValue', { value: (content.score || 0) * 100 })})
                  </div>
                  {onTagDiscipline && (
                    <button
                      type="button"
                      className="ui-btn-empty ui-info"
                      style={{ fontSize: 16 }}
                      title="Accept Classification"
                      onClick={() => {
                        if (!content?.discipline) return
                        onTagDiscipline(content, content?.discipline)
                      }}
                    >
                      <FontAwesomeIcon icon={faCircleExclamationCheck} />
                    </button>
                  )}
                </div>
              )}
              {rights?.userType === UserType.provider && onTagDiscipline ? (
                <Select
                  items={disciplines}
                  disabled={saving}
                  className="ui-select-sm ui-select-round ui-select-info"
                  style={{ width: 200, marginLeft: 'auto' }}
                  selected={content.discipline ? { id: content.discipline } : undefined}
                  placeholder={t('General.Discipline')}
                  labelField="discipline"
                  onSelectedChange={(d) => {
                    onTagDiscipline(content, d.id)
                  }}
                  maxDropDown={11}
                  onClear={() => onTagDiscipline(content)}
                  renderSelected={() => <div>{disciplines.find((x) => x.id === content.discipline)?.discipline || ''}</div>}
                />
              ) : (
                <>
                  {(() => {
                    if (!content.discipline) return null

                    if (content.discipline && content.disciplineById)
                      return (
                        <div className="ui-badge ui-badge-success" style={{ paddingTop: 4, marginRight: 10 }}>
                          {t(`General.Disciplines.${content.discipline}`)}
                        </div>
                      )

                    if (!content.classification) return null

                    const s = content.score || 0
                    return (
                      <div
                        className={`ui-badge ${
                          // eslint-disable-next-line no-nested-ternary
                          s > 0.75 ? 'ui-badge-success' : s > 0.5 ? 'ui-badge-warn' : 'ui-badge-danger'
                        }`}
                        style={{ marginRight: 10 }}
                      >
                        {t(`General.Disciplines.${content.classification}`)}
                      </div>
                    )
                  })()}
                </>
              )}

              <div className="divider" />
            </>
          )}

          <button
            type="button"
            className="ui-btn-empty"
            disabled={saving}
            onClick={() => {
              let r = rotate - 90
              if (r <= -360) r = 0
              window.dispatchEvent(new CustomEvent('docView.rotate', { detail: { rotate: r } }))
            }}
          >
            <FontAwesomeIcon icon={faRotateLeft} />
          </button>
          <button
            type="button"
            className="ui-btn-empty"
            disabled={saving}
            onClick={() => {
              let r = rotate + 90
              if (r >= 360) r = 0
              window.dispatchEvent(new CustomEvent('docView.rotate', { detail: { rotate: r } }))
            }}
          >
            <FontAwesomeIcon icon={faRotateRight} />
          </button>

          <div className="divider" />

          <button
            type="button"
            className="ui-btn-empty"
            disabled={saving}
            onClick={() => {
              window.dispatchEvent(new CustomEvent('docView.zoom', { detail: { zoom: decZoom(zoom) } }))
            }}
          >
            <FontAwesomeIcon icon={faMinusCircle} />
          </button>

          <div className="zoom">{zoom >= 0 ? `${Math.floor(zoom * 100)}%` : ''}</div>

          <button
            type="button"
            className="ui-btn-empty"
            disabled={saving}
            onClick={() => {
              window.dispatchEvent(new CustomEvent('docView.zoom', { detail: { zoom: incZoom(zoom) } }))
            }}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
          </button>

          <div className="divider" />

          <button
            type="button"
            className="ui-btn-empty"
            disabled={saving}
            onClick={() => {
              window.dispatchEvent(new Event('docView.fit'))
            }}
          >
            <FontAwesomeIcon icon={faSquareCode} />
          </button>

          <div className="divider" />

          <button
            type="button"
            className={`ui-btn-empty${view === 'full' ? ' ui-btn-pressed' : ''}`}
            disabled={saving}
            onClick={() => {
              window.dispatchEvent(new CustomEvent('docView.view', { detail: { view: view === 'full' ? 'normal' : 'full' } }))
            }}
          >
            <FontAwesomeIcon icon={view === 'full' ? faCompress : faExpand} />
          </button>
        </>
      )}
    </div>
  )
}
