import React from 'react'
import { Modal, ModalProps } from './index'

type InfoModalProps = ModalProps & {
  title: JSX.Element | string
  message: JSX.Element | string
  ok: JSX.Element | string
  onClose: () => void
}

export const InfoModal: React.FC<InfoModalProps> = ({ title, message, ok, onClose, ...rest }) => (
  <Modal header={<h3>{title}</h3>} onClose={onClose} {...rest}>
    <form
      noValidate
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onClose()
      }}
    >
      <div className="ui-form-group">{message}</div>

      <div className="ui-row">
        <div className="ui-col-12">
          <button type="submit" className="ui-btn ui-btn-primary ui-btn-solid" style={{ width: '100%' }}>
            {ok}
          </button>
        </div>
      </div>
    </form>
  </Modal>
)
