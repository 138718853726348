import React, { SVGProps } from 'react'
import './style.scss'

const Transactions: React.FC<SVGProps<SVGSVGElement>> = ({ className, ...rest }) => (
  <svg viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg" className={`ui-svg ui-svg-transactions ${className || ''}`} {...rest}>
    <path d="M0.803139 9.51528H19.2281C19.3785 9.51528 19.4988 9.39497 19.4988 9.24458V7.81372C19.4988 7.72778 19.4602 7.64614 19.3914 7.59458L10.1789 0.758253C10.0844 0.685207 9.95119 0.685207 9.85666 0.758253L0.644155 7.59888C0.575405 7.65044 0.536733 7.73208 0.536733 7.81802V9.24888C0.532436 9.39497 0.652748 9.51528 0.803139 9.51528ZM9.02736 6.30552H9.659C9.659 6.45161 9.69337 6.55903 9.76642 6.61919C9.83947 6.68364 9.934 6.71372 10.0543 6.71372C10.1617 6.71372 10.2391 6.68794 10.2863 6.63208C10.3336 6.58052 10.3594 6.50747 10.3594 6.42153C10.3594 6.31841 10.3336 6.24107 10.2863 6.1895C10.2391 6.13794 10.1445 6.08208 10.007 6.02192C9.70197 5.9145 9.47853 5.79849 9.33244 5.66958C9.19064 5.54068 9.11759 5.35591 9.11759 5.11099C9.11759 4.89185 9.19064 4.71138 9.34103 4.57388C9.49142 4.43208 9.68908 4.35474 9.94259 4.33325V3.92935H10.2477V4.34185C10.4883 4.37193 10.6731 4.46216 10.8106 4.61255C10.9481 4.76294 11.0125 4.952 11.0082 5.18833L11.0039 5.19693H10.3723C10.3723 5.06802 10.3465 4.97349 10.2906 4.91333C10.2348 4.85318 10.1574 4.8231 10.0543 4.8231C9.95119 4.8231 9.87384 4.84888 9.83087 4.90474C9.7879 4.9606 9.76642 5.03364 9.76642 5.12388C9.76642 5.20982 9.7879 5.27857 9.83517 5.33013C9.88244 5.38169 9.97697 5.44185 10.1188 5.5063C10.4152 5.60083 10.6344 5.71685 10.7848 5.85435C10.9309 5.99185 11.0082 6.18091 11.0082 6.42583C11.0082 6.64927 10.9352 6.82974 10.7934 6.96724C10.6516 7.10474 10.4582 7.18208 10.209 7.20357V7.59028H9.90392V7.20357C9.64611 7.18208 9.43126 7.10044 9.26798 6.95864C9.1004 6.81685 9.02306 6.6063 9.02736 6.327V6.30552ZM1.80861 8.12739H18.2227C18.4117 8.12739 18.5664 8.28208 18.5664 8.47114C18.5664 8.66021 18.4117 8.81489 18.2227 8.81489H1.80861C1.61955 8.81489 1.46486 8.66021 1.46486 8.47114C1.46486 8.28208 1.61955 8.12739 1.80861 8.12739Z" />
    <path d="M19.7523 20.9622H0.278912C0.128522 20.9622 0.00820923 21.0825 0.00820923 21.2329V21.9204C0.00820923 22.0708 0.128522 22.1911 0.278912 22.1911H19.7523C19.9027 22.1911 20.0231 22.0708 20.0231 21.9204V21.2329C20.0231 21.0825 19.9027 20.9622 19.7523 20.9622Z" />
    <path d="M0.970703 19.5743V20.2618C0.970703 20.4122 1.09102 20.5325 1.24141 20.5325H18.7898C18.9402 20.5325 19.0605 20.4122 19.0605 20.2618V19.5743C19.0605 19.4239 18.9402 19.3036 18.7898 19.3036H1.24141C1.09102 19.3036 0.970703 19.4239 0.970703 19.5743Z" />
    <path d="M15.6188 18.1262C15.5414 18.1262 15.477 18.1907 15.477 18.268V18.6762C15.477 18.7536 15.5414 18.818 15.6188 18.818H18.0594C18.1367 18.818 18.2012 18.7536 18.2012 18.6762V18.268C18.2012 18.1907 18.1367 18.1262 18.0594 18.1262H17.8188V10.8731H18.0594C18.1367 10.8731 18.2012 10.8086 18.2012 10.7313V10.3231C18.2012 10.2458 18.1367 10.1813 18.0594 10.1813H15.6188C15.5414 10.1813 15.477 10.2458 15.477 10.3231V10.7313C15.477 10.8086 15.5414 10.8731 15.6188 10.8731H15.8594V18.1305H15.6188V18.1262Z" />
    <path d="M12.207 18.1262C12.1297 18.1262 12.0652 18.1907 12.0652 18.268V18.6762C12.0652 18.7536 12.1297 18.818 12.207 18.818H14.6477C14.725 18.818 14.7895 18.7536 14.7895 18.6762V18.268C14.7895 18.1907 14.725 18.1262 14.6477 18.1262H14.407V10.8731H14.6477C14.725 10.8731 14.7895 10.8086 14.7895 10.7313V10.3231C14.7895 10.2458 14.725 10.1813 14.6477 10.1813H12.207C12.1297 10.1813 12.0652 10.2458 12.0652 10.3231V10.7313C12.0652 10.8086 12.1297 10.8731 12.207 10.8731H12.4477V18.1305H12.207V18.1262Z" />
    <path d="M8.79533 18.1262C8.71799 18.1262 8.65353 18.1907 8.65353 18.268V18.6762C8.65353 18.7536 8.71799 18.818 8.79533 18.818H11.2359C11.3133 18.818 11.3777 18.7536 11.3777 18.6762V18.268C11.3777 18.1907 11.3133 18.1262 11.2359 18.1262H10.9953V10.8731H11.2359C11.3133 10.8731 11.3777 10.8086 11.3777 10.7313V10.3231C11.3777 10.2458 11.3133 10.1813 11.2359 10.1813H8.79533C8.71799 10.1813 8.65353 10.2458 8.65353 10.3231V10.7313C8.65353 10.8086 8.71799 10.8731 8.79533 10.8731H9.03595V18.1305H8.79533V18.1262Z" />
    <path d="M5.38356 18.1262C5.30621 18.1262 5.24176 18.1907 5.24176 18.268V18.6762C5.24176 18.7536 5.30621 18.818 5.38356 18.818H7.82418C7.90153 18.818 7.96598 18.7536 7.96598 18.6762V18.268C7.96598 18.1907 7.90153 18.1262 7.82418 18.1262H7.58356V10.8731H7.82418C7.90153 10.8731 7.96598 10.8086 7.96598 10.7313V10.3231C7.96598 10.2458 7.90153 10.1813 7.82418 10.1813H5.38356C5.30621 10.1813 5.24176 10.2458 5.24176 10.3231V10.7313C5.24176 10.8086 5.30621 10.8731 5.38356 10.8731H5.62418V18.1305H5.38356V18.1262Z" />
    <path d="M1.96757 18.1262C1.89023 18.1262 1.82578 18.1907 1.82578 18.268V18.6762C1.82578 18.7536 1.89023 18.818 1.96757 18.818H4.4082C4.48554 18.818 4.55 18.7536 4.55 18.6762V18.268C4.55 18.1907 4.48554 18.1262 4.4082 18.1262H4.16757V10.8731H4.4082C4.48554 10.8731 4.55 10.8086 4.55 10.7313V10.3231C4.55 10.2458 4.48554 10.1813 4.4082 10.1813H1.96757C1.89023 10.1813 1.82578 10.2458 1.82578 10.3231V10.7313C1.82578 10.8086 1.89023 10.8731 1.96757 10.8731H2.2082V18.1305H1.96757V18.1262Z" />
  </svg>
)
export default Transactions
