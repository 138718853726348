import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useEditProject } from 'services/src/state'

import logo from 'components/src/img/cc-dark.png'
import { ExpertSummaryBar } from './ExpertSummaryBar'
import { ExpertPhaseList } from './ExpertPhaseList'

export const ExpertOverview: React.FC = () => {
  const { t } = useTranslation()
  const { project } = useEditProject()

  const description = useMemo(() => {
    if (!project.description) return null
    return (
      <div className="ui-text-sm">
        {project.description
          .trim()
          .split('\n')
          .map((l, idx) => (
            <p key={idx}>{l}</p>
          ))}
      </div>
    )
  }, [project, t])

  return (
    <div>
      <h1 className="ui-flex ui-flex-nowrap ui-form-group" style={{ alignItems: 'center' }}>
        <img alt="" src={logo} style={{ width: 30 }} />
        <span style={{ marginLeft: 20 }}>{project.name}</span>
      </h1>

      <ExpertSummaryBar />

      {description && (
        <>
          <div>
            <label>{t('Expert.Overview.Summary')}</label>
            {description}
          </div>
          <hr style={{ margin: '30px 0' }} />
        </>
      )}

      <ExpertPhaseList />
    </div>
  )
}
