import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectStatus } from 'services/src/dto/projectShare'
import { FeeGridViewModel, FeeGridViewModelEstimate } from './common'

export const FeeGridLaborTotals: React.FC<{
  vm: FeeGridViewModel
  estimate: FeeGridViewModelEstimate
}> = ({ vm, estimate }) => {
  const { t } = useTranslation()

  const { allocatedHours, laborTotal } = useMemo(() => {
    let allocatedHours = 0
    let laborTotal = 0
    estimate.labor
      .filter((l) => l.status !== ProjectStatus.Rejected)
      .forEach((l) => {
        allocatedHours += l.hours || 0
        laborTotal += l.cost || 0
      })

    return {
      allocatedHours,
      laborTotal
    }
  }, [estimate])

  return (
    <div className="ui-text-sm" style={{ marginLeft: 20 }}>
      <div className="ui-text-center ui-text-xxs ui-flex" style={{ marginBottom: 5 }}>
        <div style={{ width: 100, position: 'relative' }}>
          <div className="ui-text-uppercase ui-text-bold ui-text-muted">{t('EditProject.Fees.LaborHours')}</div>
          {allocatedHours < estimate.hours && (
            <div
              className="ui-badge ui-badge-danger ui-text-xxs"
              title={t('EditProject.Fees.UnallocatedHours')}
              style={{
                cursor: 'default',
                fontSize: '0.6rem',
                fontWeight: 'bold',
                lineHeight: '0.6rem',
                position: 'absolute',
                top: 0,
                right: -3,
                width: 12,
                height: 12,
                padding: 0
              }}
            >
              !
            </div>
          )}
        </div>
        {vm.variant !== 'assign' && (
          <div className="ui-text-uppercase ui-text-bold ui-text-muted" style={{ width: 100, marginLeft: 10 }}>
            {t('EditProject.Fees.LaborCost')}
          </div>
        )}
      </div>

      <div className="ui-flex">
        <div className="ui-flex ui-frame ui-frame-no-padding ui-text-center" style={{ height: 25, width: 100, lineHeight: '23px' }}>
          {estimate.hours <= 0 ? '--' : allocatedHours}
        </div>

        {vm.variant !== 'assign' && (
          <div className="ui-flex ui-frame ui-frame-no-padding ui-text-center" style={{ marginLeft: 10, height: 25, width: 100, lineHeight: '23px' }}>
            <span className={estimate.hours > 0 ? 'ui-danger' : undefined}>{estimate.hours <= 0 ? '--' : t('General.Currency.Value', { value: laborTotal })}</span>
          </div>
        )}
      </div>
    </div>
  )
}
