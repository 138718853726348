import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Office } from 'services/src/dto/account'
import { useCurrentUserAccount, useGeolocation } from 'services/src/state'

import { TextField } from 'components/src/textField'
import { PhoneInputField } from 'components/src/phoneInputField'
import { AddressField } from 'components/src/addressField'
import { Link } from 'react-router-dom'
import { makeDashboardPath } from 'services/src/dom'

export interface OfficeProfileProps {
  office: Office
  canEditOffice: boolean
  canDeleteOffice: boolean
  onChange: (office: Office) => void
  onSubmit: (office: Office) => void
  onDelete: (office: Office) => void
}

const OfficeProfile: React.FC<OfficeProfileProps> = ({ office, canEditOffice, canDeleteOffice, onChange, onSubmit, onDelete }) => {
  const { t } = useTranslation()
  const { country_code: geo } = useGeolocation()
  const [account] = useCurrentUserAccount()

  const [errors, setErrors] = useState<any>({})
  const clearError = (name: string) => {
    const theErrors = { ...errors }
    delete theErrors[name]
    setErrors(theErrors)
  }

  const [phone, setPhone] = useState<string>(() => {
    const pn = office.phoneNumbers?.find((x) => x.label === 'Primary')
    return pn?.number || (office.phoneNumbers?.length ? office.phoneNumbers[0].number : '')
  })
  const [phoneCountry, setPhoneCountry] = useState<string>(() => geo?.toLowerCase() || 'us')
  const [phoneChanged, setPhoneChanged] = useState<boolean>(false)

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      const errors: any = {}
      if (!office.name) errors.name = t('General.Errors.Required')

      setErrors(errors)
      if (Object.keys(errors).length > 0) return

      if (phone) office.phoneNumbers = [{ label: 'Primary', number: phone }]

      onSubmit(office)
    },
    [office, setErrors, phone, t, onSubmit]
  )

  return (
    <div className="office-edit-profile">
      <form onSubmit={submit} noValidate>
        <div className="ui-row">
          <div className="ui-col-12 ui-col-md-8">
            <TextField
              name="name"
              label={t('EditOffice.Name')}
              placeholder={t('EditOffice.NameSample')}
              value={office.name}
              error={errors.name}
              disabled={!canEditOffice}
              onChange={(name) => {
                onChange({ ...office, name })
                clearError('name')
              }}
            />
          </div>
          <div className="ui-col-12 ui-col-md-4">
            <PhoneInputField
              value={phone}
              onChange={(phone) => {
                setPhone(phone)
                clearError('phone')
                setPhoneChanged(true)
              }}
              label={t('EditOffice.Phone')}
              error={errors.phone}
              placeholder={t('EditOffice.PhoneSample')}
              country={phoneCountry}
              countryCodeEditable={false}
              disabled={!canEditOffice}
            />
          </div>
        </div>

        <AddressField
          address={office.address}
          onChange={(address) => {
            onChange({ ...office, address: { ...address } })
            if (!phoneChanged && address?.countryCode) setPhoneCountry(address.countryCode.toLowerCase())
          }}
          countryLabel={t('EditOffice.Address.Country')}
          streetLabel={t('EditOffice.Address.Street')}
          disabled={!canEditOffice}
        />

        {canEditOffice && (
          <footer className="office-edit-footer ui-flex ui-flex-nowrap">
            <Link to={makeDashboardPath(account?.id)} className="ui-btn ui-btn-primary ui-btn-solid">
              {t('Dashboard.Title')}
            </Link>

            {office.id && canDeleteOffice && (
              <button className="ui-btn ui-btn-danger ui-btn-solid" type="button" style={{ width: 150, marginLeft: 10 }} onClick={() => onDelete(office)}>
                {t('EditOffice.Delete')}
              </button>
            )}

            <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center', marginLeft: 'auto' }}>
              <button className="ui-btn ui-btn-secondary ui-btn-solid" type="submit" style={{ width: 120 }}>
                {t(office.id ? 'General.Save' : 'General.Create')}
              </button>
            </div>
          </footer>
        )}
      </form>
    </div>
  )
}
export default OfficeProfile
