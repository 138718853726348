import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { Modal, ModalProps } from 'components/src/modal'
import ProjectSvg from 'components/src/svg/Projects'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import { OfferItem } from './offerTypes'

export const ExpertAcceptModal: React.FC<
  ModalProps & {
    items: OfferItem[]
    onYes: () => void
  }
> = ({ items, onClose, onYes }) => {
  const { t } = useTranslation()

  return (
    <Modal onClose={onClose} closeOnClickOutside={false} header={<h3 className="ui-text-bold">{t('Expert.AcceptModal.Title')}</h3>}>
      <div className="ui-text-center">
        <ProjectSvg width="100" />
      </div>
      <br />

      <div className="ui-text-sm" style={{ padding: '0 30px' }}>
        <div className="ui-flex ui-flex-nowrap ui-text-bold">
          <div>{t('Expert.AcceptModal.PhaseDisRole')}</div>
          <div style={{ marginLeft: 'auto' }}>{t('Expert.Overview.DueDate')}</div>
        </div>

        {items
          .filter((item) => item.phase && !item.estimate && item.accepted)
          .map((phItem) => {
            const estimateItems = items.filter((item) => item.estimate && item.accepted && item.phase?.id === phItem.phase!.id)
            return (
              <div key={phItem.phase!.id} className="ui-flex ui-flex-nowrap">
                <div>
                  <div style={{ marginBottom: 5 }}>{t(`EditProject.${phItem.phase!.phaseType}`)}</div>

                  {estimateItems.map(({ estimate, expired }) => (
                    <div key={estimate?.id} style={{ paddingLeft: 15 }} className={expired ? 'ui-danger' : ''}>
                      {t(`General.Disciplines.${estimate!.discipline}`)}
                    </div>
                  ))}
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <div style={{ marginBottom: 5 }} className="ui-text-right">
                    {moment(phItem.phase!.deliveryDate).format('LL')}
                  </div>

                  {estimateItems.map(({ estimate, total, labor, expired }) =>
                    expired ? (
                      <div key={estimate!.id} className="ui-text-right ui-danger">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        &nbsp;{t('Expert.Overview.OfferExpired')}
                      </div>
                    ) : (
                      <div key={estimate!.id} className="ui-text-right">
                        {t('General.Currency.Value', { value: labor ? labor.rate * (labor.hours || 0) : total })}
                      </div>
                    )
                    /*
                    <div key={estimate!.id} className="ui-text-right">
                      {expired
                        ? t('General.Currency.Value', { value: labor ? labor.rate * (labor.hours || 0) : total })
                        : t('General.Currency.Value', { value: labor ? labor.rate * (labor.hours || 0) : total });
                    </div>
                    */
                  )}
                </div>
              </div>
            )
          })}

        <hr />
        <div className="ui-flex ui-flex-nowrap ui-text-bold">
          <div>{t('Expert.AcceptModal.TotalOffer')}</div>
          <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: items[items.length - 1].adjTotal })}</div>
        </div>
      </div>
      <br />

      <div>
        <button type="button" className="ui-btn ui-btn-secondary ui-btn-solid" style={{ width: '100%' }} onClick={() => onYes()}>
          {t('Expert.AcceptModal.Title')}
        </button>
      </div>
    </Modal>
  )
}
