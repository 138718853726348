import React, { useCallback, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/pro-regular-svg-icons'
import { useReactToPrint } from 'react-to-print'

import { ResourceUserRole, User } from 'services/src/dto/account'
import { DisciplineKey, FeeType, ProjectStatus } from 'services/src/dto/project'
import { useProjectSupport } from 'services/src/api'
import { useCurrentUserAccount, useEditProject, useRights, UserType } from 'services/src/state'
import { makeDashboardPath } from 'services/src/dom'
import { useSaving } from 'components/src/saving'
import { Patch } from 'services/src/dto/common'
import { SummaryBar } from './SummaryBar'
import { FeePhaseBreakdown } from './FeePhaseBreakdown'
import { FeeClientAccept } from './FeeClientAccept'
import { FeeComparisonSummary } from './FeeComparisonSummary'
import { FeeClientApproved } from './FeeClientApproved'
import { FeeClientCounter } from './FeeClientCounter'
import { FeeProviderReady } from './FeeProviderReady'
import { FeeProviderApprove } from './FeeProviderApprove'
import { FeeProviderCounter, PhaseWithCounter } from './FeeProviderCounter'
import { FeeProviderSent } from './FeeProviderSent'
import { Footer } from '../Footer'
import { PhaseWithFee } from './FeeClientDeclined'
import { AssignmentPanel } from '../../common/AssignmentPanel'
import { FeeClientSummaryPrint } from './FeeClientSummaryPrint'

export const FeeSummary: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { pathname } = useLocation()
  const rights = useRights()
  const [saving] = useSaving()
  const [account] = useCurrentUserAccount()

  const [{ budgetRanges, constructionTypes }] = useProjectSupport()
  const { project, patch } = useEditProject()

  const [showChangeProvider, setShowChangeProvider] = useState(false)
  const changeProvider = useCallback(
    (users?: User[]) => {
      setShowChangeProvider(false)

      if (!users?.length) return

      // Filter out all provider users
      const u = project.users?.filter((u) => !!project.accountWithUsers.users.find((u2) => u2.id === u.id)) || []

      // Add new provider
      u.push({ ...users[0], role: ResourceUserRole.Owner })

      patch(
        [
          {
            propertyName: 'ResourceUsers',
            propertyValue: u.map((x: User) => ({
              id: x.id,
              role: x.role,
              userType: users[0].id === x.id ? 'provider' : 'client'
            }))
          }
        ],
        true
      ).then(() => {})
    },
    [project, patch, setShowChangeProvider]
  )

  const sendToClient = useCallback(() => {
    const phases = project.phases.filter((x) => x.status === ProjectStatus.FeeSelected || x.status === ProjectStatus.Accepted)
    const totalFee = phases.reduce((sum, ph) => sum + (ph.manualTotals?.total || 0), 0)

    if ([ProjectStatus.Approved, ProjectStatus.InProgress, ProjectStatus.Complete].includes(project.status!)) {
      const p: Patch[] = []
      phases.forEach((ph) => {
        p.push({
          propertyName: `Phases.${ph.id}.Status`,
          propertyValue: {
            status: ProjectStatus.FeeReady,
            metadata: {
              [`phase.${ph.id}.fee`]: ph.manualTotals?.total || 0,
              [`phase.${ph.id}.subtotal`]: ph.manualTotals?.subtotal || 0,
              [`phase.${ph.id}.otherFees`]: ph.manualTotals?.otherFeeTotal || 0,
              [`phase.${ph.id}.providerFee`]: ph.providerFee || 0
            }
          }
        })
      })

      patch(p).then(() => {})
    } else {
      const metadata: any = { totalFee }
      phases.forEach((ph) => {
        metadata[`phase.${ph.id}.fee`] = ph.manualTotals?.total || 0
        metadata[`phase.${ph.id}.subtotal`] = ph.manualTotals?.subtotal || 0
        metadata[`phase.${ph.id}.otherFees`] = ph.manualTotals?.otherFeeTotal || 0
        metadata[`phase.${ph.id}.providerFee`] = ph.providerFee || 0
      })

      patch([
        {
          propertyName: `Status`,
          propertyValue: {
            status: ProjectStatus.FeeReady,
            metadata
          }
        }
      ]).then(() => {})
    }
  }, [project, patch])

  const approveFee = useCallback(() => {
    const phases = project.phases.filter((x) => x.status === ProjectStatus.FeeReady)
    const totalFee = phases.reduce((sum, ph) => sum + (ph.fee || 0), 0)

    if ([ProjectStatus.Approved, ProjectStatus.InProgress, ProjectStatus.Complete].includes(project.status!)) {
      const p: Patch[] = []
      phases.forEach((ph) => {
        p.push({
          propertyName: `Phases.${ph.id}.Status`,
          propertyValue: {
            status: ProjectStatus.Approved,
            metadata: {
              [`phase.${ph.id}.fee`]: ph.manualTotals?.total || 0,
              [`phase.${ph.id}.subtotal`]: ph.manualTotals?.subtotal || 0,
              [`phase.${ph.id}.otherFees`]: ph.manualTotals?.otherFeeTotal || 0,
              [`phase.${ph.id}.providerFee`]: ph.providerFee || 0
            }
          }
        })
      })

      patch(p).then(() => {})
    } else {
      const metadata: any = { totalFee }
      phases.forEach((ph) => {
        metadata[`phase.${ph.id}.fee`] = ph.manualTotals?.total || 0
        metadata[`phase.${ph.id}.subtotal`] = ph.manualTotals?.subtotal || 0
        metadata[`phase.${ph.id}.otherFees`] = ph.manualTotals?.otherFeeTotal || 0
        metadata[`phase.${ph.id}.providerFee`] = ph.providerFee || 0
      })

      patch([
        {
          propertyName: `Status`,
          propertyValue: {
            status: ProjectStatus.Approved,
            metadata
          }
        }
      ]).then(() => {})
    }
  }, [project, patch])

  const declineFee = useCallback(
    (reason: string) => {
      const phases = project.phases.filter((x) => x.status === ProjectStatus.FeeReady)
      const totalFee = phases.reduce((sum, ph) => sum + (ph.fee || 0), 0)

      if ([ProjectStatus.Approved, ProjectStatus.InProgress, ProjectStatus.Complete].includes(project.status!)) {
        const p: Patch[] = []
        phases.forEach((ph) => {
          p.push({
            propertyName: `Phases.${ph.id}.Status`,
            propertyValue: {
              status: ProjectStatus.Canceled,
              metadata: {
                [`phase.${ph.id}.fee`]: ph.manualTotals?.total || 0,
                [`phase.${ph.id}.subtotal`]: ph.manualTotals?.subtotal || 0,
                [`phase.${ph.id}.otherFees`]: ph.manualTotals?.otherFeeTotal || 0,
                [`phase.${ph.id}.providerFee`]: ph.providerFee || 0,
                declined: true
              }
            }
          })
        })

        patch(p).finally(() => history.replace(makeDashboardPath(account?.id, 'projects')))
        return
      }

      const metadata: any = { totalFee, declined: true, reason }
      phases.forEach((ph) => {
        metadata[`phase.${ph.id}.fee`] = ph.manualTotals?.total || 0
        metadata[`phase.${ph.id}.subtotal`] = ph.manualTotals?.subtotal || 0
        metadata[`phase.${ph.id}.otherFees`] = ph.manualTotals?.otherFeeTotal || 0
        metadata[`phase.${ph.id}.providerFee`] = ph.providerFee || 0
      })

      patch([
        {
          propertyName: `Status`,
          propertyValue: {
            status: ProjectStatus.Canceled,
            metadata
          }
        }
      ]).finally(() => history.replace(makeDashboardPath(account?.id, 'projects')))
    },
    [project, patch, history, account]
  )

  const negotiateFee = useCallback(
    (phases: PhaseWithFee[], reason: string) => {
      const totalFee = phases.reduce((sum, ph) => sum + (ph.fee || 0), 0)
      const totalUserFee = phases.reduce((sum, ph) => sum + (ph.userFee || 0), 0)

      if ([ProjectStatus.Approved, ProjectStatus.InProgress, ProjectStatus.Complete].includes(project.status!)) {
        const p: Patch[] = []
        phases.forEach((ph) => {
          p.push({
            propertyName: `Phases.${ph.id}.Status`,
            propertyValue: {
              status: ProjectStatus.FeeCounter,
              metadata: {
                [`phase.${ph.id}.originalFee`]: ph.manualTotals?.total || 0,
                [`phase.${ph.id}.originalSubtotal`]: ph.manualTotals?.subtotal || 0,
                [`phase.${ph.id}.originalOtherFees`]: ph.manualTotals?.otherFeeTotal || 0,
                [`phase.${ph.id}.providerFee`]: ph.providerFee || 0,
                [`phase.${ph.id}.fee`]: ph.userFee || 0
              }
            }
          })
        })

        patch(p).then(() => {})
      } else {
        const metadata: any = { totalFee, totalUserFee, reason }
        phases.forEach((ph) => {
          metadata[`phase.${ph.id}.originalFee`] = ph.manualTotals?.total || 0
          metadata[`phase.${ph.id}.originalSubtotal`] = ph.manualTotals?.subtotal || 0
          metadata[`phase.${ph.id}.originalOtherFees`] = ph.manualTotals?.otherFeeTotal || 0
          metadata[`phase.${ph.id}.providerFee`] = ph.providerFee || 0
          metadata[`phase.${ph.id}.fee`] = ph.userFee || 0
        })

        patch([
          {
            propertyName: 'Status',
            propertyValue: {
              status: ProjectStatus.FeeCounter,
              metadata
            }
          }
        ]).then(() => {})
      }
    },
    [project, patch, history, account]
  )

  const acceptCounter = useCallback(
    (phases: PhaseWithCounter[]) => {
      const totalFee = phases.reduce((sum, ph) => sum + (ph.fee || 0), 0)
      const totalUserFee = phases.reduce((sum, ph) => sum + (ph.counterFee || 0), 0)

      if ([ProjectStatus.Approved, ProjectStatus.InProgress, ProjectStatus.Complete].includes(project.status!)) {
        const p: Patch[] = []
        phases.forEach((ph) => {
          p.push({
            propertyName: `Phases.${ph.id}.Status`,
            propertyValue: {
              status: ProjectStatus.Approved,
              metadata: {
                [`phase.${ph.id}.originalFee`]: ph.manualTotals?.total || 0,
                [`phase.${ph.id}.originalSubtotal`]: ph.manualTotals?.subtotal || 0,
                [`phase.${ph.id}.originalOtherFees`]: ph.manualTotals?.otherFeeTotal || 0,
                [`phase.${ph.id}.providerFee`]: ph.providerFee || 0,
                [`phase.${ph.id}.fee`]: ph.counterFee || 0
              }
            }
          })
        })

        patch(p).then(() => {})
      } else {
        const metadata: any = { totalFee, totalUserFee }
        phases.forEach((ph) => {
          metadata[`phase.${ph.id}.originalFee`] = ph.manualTotals?.total || 0
          metadata[`phase.${ph.id}.originalSubtotal`] = ph.manualTotals?.subtotal || 0
          metadata[`phase.${ph.id}.originalOtherFees`] = ph.manualTotals?.otherFeeTotal || 0
          metadata[`phase.${ph.id}.providerFee`] = ph.providerFee || 0
          metadata[`phase.${ph.id!}.fee`] = ph.counterFee || 0
        })

        patch([
          {
            propertyName: 'Status',
            propertyValue: {
              status: ProjectStatus.Approved,
              metadata
            }
          }
        ]).then(() => {})
      }
    },
    [patch]
  )

  const rejectCounter = useCallback(
    (message: string) => {
      const phases = project.phases.filter((x) => x.status === ProjectStatus.FeeCounter)

      const sh = project.statusHistory?.find((x) => x.newStatus === ProjectStatus.FeeCounter)
      const amount = parseFloat(sh?.metadata?.totalUserFee || '0')
      const originalTotalFee = parseFloat(sh?.metadata?.totalFee || '0')

      if ([ProjectStatus.Approved, ProjectStatus.InProgress, ProjectStatus.Complete].includes(project.status!)) {
        const p: Patch[] = []
        phases.forEach((ph) => {
          p.push({
            propertyName: `Phases.${ph.id}.Status`,
            propertyValue: {
              status: ProjectStatus.FeeReady,
              metadata: {
                [`phase.${ph.id}.fee`]: ph.manualTotals?.total || 0,
                [`phase.${ph.id}.subtotal`]: ph.manualTotals?.subtotal || 0,
                [`phase.${ph.id}.otherFees`]: ph.manualTotals?.otherFeeTotal || 0,
                [`phase.${ph.id}.providerFee`]: ph.providerFee || 0,
              }
            }
          })
        })

        patch(p).then(() => {})
      } else {
        const metadata: any = { totalFee: amount, originalTotalFee, isCounterReject: true, message }
        phases.forEach((ph) => {
          const phaseFee = sh?.metadata[`phase.${ph.id}.fee`]
          metadata[`phase.${ph.id}.counterOffer`] = phaseFee || 0
          metadata[`phase.${ph.id}.fee`] = ph.manualTotals?.total || 0
          metadata[`phase.${ph.id}.subtotal`] = ph.manualTotals?.subtotal || 0
          metadata[`phase.${ph.id}.otherFees`] = ph.manualTotals?.otherFeeTotal || 0
          metadata[`phase.${ph.id}.providerFee`] = ph.providerFee || 0
        })

        patch([
          {
            propertyName: 'Status',
            propertyValue: {
              status: ProjectStatus.FeeReady,
              metadata
            }
          }
        ]).then(() => {})
      }
    },
    [project, patch]
  )

  const printComponentRef = useRef(null)
  const handlePrint = useReactToPrint({
    documentTitle: project.name,
    content: () => printComponentRef.current
  })

  return (
    <>
      <div className="ui-flex">
        <h3>{t('EditProject.Fees.ProjectDetailSummary')}</h3>
        {rights?.userType === UserType.client && (
          <button type="button" className="ui-btn-empty" style={{ marginLeft: 'auto' }} tabIndex={-1} onClick={handlePrint}>
            <FontAwesomeIcon icon={faPrint} style={{ fontSize: 20 }} />
          </button>
        )}
      </div>
      <br />

      <SummaryBar
        constructionType={constructionTypes.find((x) => x.id === project.constructionType)?.type || ''}
        projectType={project.projectType?.type || ''}
        buildCategory={
          // eslint-disable-next-line no-nested-ternary
          project.projectType?.category === 99 ? '-' : project.projectType?.category && project.projectType?.subcategory ? `${project.projectType?.category}.${project.projectType?.subcategory}` : ''
        }
        feeCategory={project.projectType?.category === 99 ? '-' : project.projectType?.category?.toString() || ''}
        budgetType={t(project.budget?.isFixed ? 'General.Currency.Fixed' : 'General.Currency.Range')}
        budget={project.budget?.isFixed ? t('General.Currency.Value', { value: project.budget.fixed, maxDigits: 0 }) : budgetRanges.find((x) => x.id === project.budget?.range)?.range || '0'}
        providerUser={project.users?.find((u) => u.role === ResourceUserRole.Owner && !!project.providerAccountWithUsers.users.find((u2) => u2.id === u.id))}
        onAssignProvider={() => setShowChangeProvider(true)}
      />

      <br />
      <br />

      {rights?.userType === UserType.provider ? (
        <>
          <div>
            <FeeComparisonSummary />
            <div className="edit-project-summary-cards">
              <FeeProviderReady onSendToClient={() => sendToClient()} />
              <FeeProviderSent />

              <FeeProviderApprove />
              <FeeProviderCounter onAccept={(amount) => acceptCounter(amount)} onReject={(message) => rejectCounter(message)} />
            </div>
          </div>

          {[ProjectStatus.Submitted, ProjectStatus.Approved, ProjectStatus.InProgress].includes(project.status!) && (
            <Footer>
              <div className="ui-flex ui-flex-nowrap">
                {project.status === ProjectStatus.Submitted && (
                  <Link
                    to={pathname}
                    className={`ui-btn ui-btn-info ui-btn-solid${saving ? ' ui-disabled' : ''}`}
                    style={{ marginLeft: 'auto', width: 'auto' }}
                    onClick={(e) => {
                      e.preventDefault()
                      if (saving) return
                      window.dispatchEvent(new Event('acceptProject'))
                    }}
                  >
                    {t('General.AcceptProjectBtn')}
                  </Link>
                )}

                {project.status === ProjectStatus.Approved && (
                  <Link
                    to={pathname}
                    className={`ui-btn ui-btn-info ui-btn-solid${saving ? ' ui-disabled' : ''}`}
                    style={{ marginLeft: 'auto', width: 'auto' }}
                    onClick={(e) => {
                      e.preventDefault()
                      if (saving) return
                      window.dispatchEvent(new Event('startProject'))
                    }}
                  >
                    {t('General.StartProject')}
                  </Link>
                )}

                {project.status === ProjectStatus.InProgress && (
                  <Link
                    to={pathname}
                    className={`ui-btn ui-btn-info ui-btn-solid${saving ? ' ui-disabled' : ''}`}
                    style={{ marginLeft: 'auto', width: 'auto' }}
                    onClick={(e) => {
                      e.preventDefault()
                      if (saving) return
                      window.dispatchEvent(new Event('completeProject'))
                    }}
                  >
                    {t('General.CompleteProject')}
                  </Link>
                )}
              </div>
            </Footer>
          )}
        </>
      ) : (
        <div>
          <FeePhaseBreakdown />

          <div className="edit-project-summary-cards">
            <FeeClientAccept onAccept={approveFee} onDecline={declineFee} onNegotiate={negotiateFee} />
            <FeeClientApproved />
            <FeeClientCounter />
          </div>

          <div style={{ display: 'none' }}>
            <FeeClientSummaryPrint ref={printComponentRef} />
          </div>
        </div>
      )}

      {showChangeProvider && <AssignmentPanel discipline={DisciplineKey.ProjectManagement.toString()} onClose={() => setShowChangeProvider(false)} onSubmit={(user: User) => changeProvider([user])} />}
    </>
  )
}
