import React from 'react'
import './style.scss'

export const ToggleSwitch: React.FC<{
  className?: string
  style?: React.CSSProperties
  value?: boolean
  onClick?: () => void
  disabled?: boolean
}> = ({ className, style, value, onClick, disabled }) => (
  // eslint-disable-next-line jsx-a11y/interactive-supports-focus
  <div
    role="switch"
    aria-checked={value}
    tabIndex={0}
    className={`ui-toggle-switch${disabled ? ' ui-disabled' : ''}${className ? ` ${className}` : ''} ${value ? 'on' : 'off'}`}
    style={style}
    onClick={() => {
      if (disabled) return
      if (onClick) onClick()
    }}
    onKeyDown={(e) => {
      if (e.key === 'Enter' && onClick) onClick()
    }}
  >
    <div>
      <div style={value ? { right: 3 } : { left: 3 }} />
    </div>
  </div>
)
