import axios from 'axios'
import moment from 'moment'
import { getAccessToken } from '../aad'
import { Account, AccountWithUsers, Client, Office, ResourceUser, ResourceUserRole, User } from '../dto/account'
import { Project } from '../dto/project'
import { CreateInvitation, Invitation } from '../dto/invitation'
import { Transaction } from '../dto/transaction'
import apiProxy from './apiProxy'
import { Patch } from '../dto/common'
import { aadConfig } from '../aad'


/** Create an account. */
export const createAccount = async (account: Account, user?: User, logo?: File, picture?: File): Promise<{ account: Account; user?: User }> => {
  await getAccessToken(aadConfig().accountScopes)

  const { data: a } = await axios.post<Account>(`${process.env.REACT_APP_ACCOUNT_API}/accounts`, account)

  if (logo) await uploadAccountLogo(a.id, logo)

  if (!user) return { account: a }

  const { data: u } = await axios.put<User>(`${process.env.REACT_APP_ACCOUNT_API}/users/me?onboard=complete`, user)

  if (picture) await uploadUserPicture(u.id, picture)

  return { account: a, user: u }
}

/** Update an account. */
export const updateAccount = async (account: Account, logo?: File): Promise<Account> => {
  await getAccessToken(aadConfig().accountScopes)

  const { data } = await axios.put<Account>(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${account.id}`, account)

  try {
    if (logo) await uploadAccountLogo(account.id, logo)
  } catch {
    // DO NOTHING
  }

  return data
}

/** Delete an account. */
export const deleteAccount = async (id: string): Promise<void> => {
  await getAccessToken(aadConfig().accountScopes)

  await axios.delete(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${id}`)
}

/** Upload account logo. */
export const uploadAccountLogo = async (accountId: string, logo: File): Promise<Account> => {
  await getAccessToken(aadConfig().accountScopes)

  const body = new FormData()
  body.append('logo', logo)

  const { data } = await axios.post<Account>(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/logo`, body, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return data;
}

/** Update a user */
export const updateUser = async (accountId: string | null, id: string | null, user: User, picture?: File, onboard?: string): Promise<User> => {
  await getAccessToken(aadConfig().accountScopes)

  const u = { ...user, accountId }

  const { data } = await axios.put<User>(`${process.env.REACT_APP_ACCOUNT_API}/users/${id || 'me'}${onboard ? `?onboard=${onboard}` : ''}`, u)

  try {
    if (picture) await uploadUserPicture(u.id, picture)
  } catch {
    // DO NOTHING
  }

  return data
}

/** Upload account logo. */
export const uploadUserPicture = async (userId: string, picture: File): Promise<User> => {
  await getAccessToken(aadConfig().accountScopes)

  const body = new FormData()
  body.append('picture', picture)

  const { data } = await axios.post<User>(`${process.env.REACT_APP_ACCOUNT_API}/users/${userId}/picture`, body, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return data;
}

/** Fetch an account. */
export const getAccountById = apiProxy('getAccountById', async (id: string): Promise<Account> => {
  await getAccessToken(aadConfig().accountScopes)
  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${id}`)
  return data
})

/** Fetch all user accounts. */
export const getAccounts = apiProxy('getAccounts', async (ids?: string[]): Promise<Account[]> => {
  await getAccessToken(aadConfig().accountScopes)
  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/accounts${ids ? `?ids=${ids.join(',')}` : ''}`)
  return data
})

/** Fetch all client accounts. */
export const getClientAccounts = apiProxy('getClientAccounts', async (accountId: string): Promise<Account[]> => {
  await getAccessToken(aadConfig().accountScopes)
  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/client-accounts`)
  return data
})

/** Fetch all provider accounts. */
export const getProviderAccounts = apiProxy('getProviderAccounts', async (accountId: string): Promise<Account[]> => {
  await getAccessToken(aadConfig().accountScopes)
  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/provider-accounts`)
  return data
})

/** Fetch all client account by the provider id. */
export const getAccountsByAccount = apiProxy(
  'getAccountsByAccount',
  async (accountId: string, offset: number, limit: number, sort?: string, sortDir?: string, filter?: string, noCounts?: boolean): Promise<{ total: number; accounts: Account[] }> => {
    await getAccessToken(aadConfig().accountScopes)

    const q: string[] = [`offset=${offset}`, `limit=${limit}`]
    if (sort) {
      q.push(`sort=${encodeURIComponent(sort)}`)
      q.push(`sortDir=${encodeURIComponent(sortDir || 'asc')}`)
    }
    if (filter) q.push(`filter=${encodeURIComponent(filter)}`)
    if (noCounts === true) q.push('noCounts=true')

    const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/list${q.length ? `?${q.join('&')}` : ''}`)
    return data
  }
)

/** Get account users. */
export const getAccountUsers = apiProxy('getAccountUsers', async (accountId: string, projectId?: string): Promise<AccountWithUsers> => {
  await getAccessToken(aadConfig().accountScopes)

  const q: string[] = []
  if (projectId) q.push(`projectId=${projectId}`)

  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/users${q.length ? `?${q.join('&')}` : ''}`)
  return data
})

/** Get account counts. */
export const getAccountCounts = apiProxy('getAccountCounts', async (accountId: string): Promise<{ [key: string]: number }> => {
  await getAccessToken(aadConfig().accountScopes)
  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/counts`)
  return data
})

/** Link account users. */
export const linkAccountUser = apiProxy('linkAccountUser', async (accountId: string, users: ResourceUser[]): Promise<void> => {
  await getAccessToken(aadConfig().accountScopes)

  const patch: Patch[] = [
    {
      propertyName: 'Users',
      propertyValue: users
    }
  ]

  await axios.patch(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}`, patch)
})

/** Delete account users. */
export const deleteAccountUser = apiProxy('deleteAccountUser', async (accountId: string, userId: string): Promise<void> => {
  await getAccessToken(aadConfig().accountScopes)
  await axios.delete(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/users/${userId}`)
})

/** Delete account users. */
export const updateAccountUserRole = apiProxy('updateAccountUserRole', async (accountId: string, userId: string, role: ResourceUserRole): Promise<void> => {
  await getAccessToken(aadConfig().accountScopes)

  const q: string[] = [`role=${role}`]
  await axios.put(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/users/${userId}?${q.join('&')}`)
})

/** Fetch account offices. */
export const getOffices = apiProxy(
  'getOffices',
  async (accountId: string, offset: number, limit: number, sort?: string, sortDir?: string, filter?: string): Promise<{ offices: Office[]; total: number }> => {
    await getAccessToken(aadConfig().accountScopes)

    const q: string[] = [`offset=${offset}`, `limit=${limit}`]
    if (sort) {
      q.push(`sort=${encodeURIComponent(sort)}`)
      q.push(`sortDir=${encodeURIComponent(sortDir || 'asc')}`)
    }
    if (filter) q.push(`filter=${encodeURIComponent(filter)}`)

    const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/offices${q.length ? `?${q.join('&')}` : ''}`)
    return data
  }
)

/** Fetch a single office. */
export const getOffice = apiProxy('getOffice', async (accountId: string, officeId: string): Promise<Office> => {
  await getAccessToken(aadConfig().accountScopes)

  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/offices/${officeId}`)
  return data
})

/** Create/update account office. */
export const upsertOffice = apiProxy('upsertOffice', async (accountId: string, office: Office): Promise<Office> => {
  await getAccessToken(aadConfig().accountScopes)
  const { data } = await axios.post(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/offices`, office)
  return data
})

/** Delete account office. */
export const deleteOffice = apiProxy('deleteOffice', async (accountId: string, officeId: string): Promise<void> => {
  await getAccessToken(aadConfig().accountScopes)
  await axios.delete(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/offices/${officeId}`)
})

/** Delete account office user. */
export const deleteOfficeUser = apiProxy('deleteOfficeUser', async (accountId: string, officeId: string, userId: string): Promise<void> => {
  await getAccessToken(aadConfig().accountScopes)
  await axios.delete(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/offices/${officeId}/users/${userId}`)
})

/** Delete account office. */
export const updateOfficeUserRole = apiProxy('updateOfficeUserRole', async (accountId: string, officeId: string, userId: string, role: ResourceUserRole): Promise<void> => {
  await getAccessToken(aadConfig().accountScopes)
  await axios.put(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/offices/${officeId}/users/${userId}?role=${role}`)
})

/** Fetch account clients. */
export const getClients = apiProxy('getClients', async (accountId: string): Promise<Client[]> => {
  await getAccessToken(aadConfig().accountScopes)
  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/clients`)
  return data
})

/** Fetch self. */
export const getMe = apiProxy('getMe', async (providerId?: string, action?: string | null, type?: string | null): Promise<User> => {
  await getAccessToken(aadConfig().accountScopes)
  const params: string[] = [];
  if (providerId) params.push(`providerId=${providerId}`)
  if (action) params.push(`action=${action}`)
  if (type) params.push(`type=${type}`)

  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/users/me${params.length ? `?${params.join('&')}` : ''}`)

  data.providerId = providerId
  data.createdAt = data.createdAt ? new Date(data.createdAt) : new Date()
  return data
})

export interface GetUsersParams {
  [key: string]: any
  ids?: string[]
  lat?: number
  lon?: number
  accountId?: string
  givenName?: string
  familyName?: string
  email?: string
  disciplines?: string[]
  category?: number
  projectTypeId?: string
  search?: string
  country?: string
  countrySubdivision?: string
  municipality?: string
  providerOnly?: boolean
  status?: string | undefined
  expertType?: string
  offset?: number
  limit?: number
}
export const getUsers = apiProxy('getUsers', async (params: GetUsersParams): Promise<User[]> => {
  await getAccessToken(aadConfig().accountScopes)

  const qs: string[] = []
  Object.keys(params).forEach((k) => {
    if (params[k] == null || params[k] === undefined) return

    if (k === 'ids') {
      qs.push(`${k}=${encodeURIComponent(params[k]!.join(','))}`)
      return
    }

    if (k === 'lat' || k === 'lon' || k === 'offset' || k === 'limit' || k === 'category') {
      qs.push(`${k}=${encodeURIComponent(params[k]!.toString())}`)
      return
    }
    if (k === 'disciplines') {
      qs.push(`${k}=${encodeURIComponent((params[k] as string[]).join(','))}`)
      return
    }
    qs.push(`${k}=${encodeURIComponent(params[k] as string)}`)
  })

  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/users?${qs.join('&')}`)
  return data
})

/** Fetch provider users. */
export const getProviderUsers = apiProxy('getProviderUsers', async (id: string): Promise<User[]> => {
  await getAccessToken(aadConfig().accountScopes)
  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/users/${id}`)
  return data
})

/** Fetch all company types. */
export const getCompanyTypes = apiProxy('getCompanyTypes', async (): Promise<{ [key: string]: string }> => {
  await getAccessToken(aadConfig().accountScopes)
  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/support/companytypes`)
  return data
})

/** Create an invitation. */
export const createInvitation = apiProxy('createInvitation', async (accountId: string, create: CreateInvitation): Promise<Invitation> => {
  await getAccessToken(aadConfig().accountScopes)
  const { data } = await axios.post(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/invitations`, create)
  return data
})

/** Verify the invitation. */
export const verifyInvitation = apiProxy('verifyInvitation', async (key: string): Promise<Invitation> => {
  const { data } = await axios.get(`${process.env.REACT_APP_ACCOUNT_API}/invitations?key=${encodeURIComponent(key)}`)
  return data
})

/** Complete the invitation. */
export const completeInvitation = apiProxy(
  'completeInvitation',
  async (
    key: string
  ): Promise<{
    account: Account
    project?: Project,
    onboardClient?: boolean
  }> => {
    await getAccessToken(aadConfig().accountScopes)
    const { data } = await axios.post(`${process.env.REACT_APP_ACCOUNT_API}/invitations?key=${encodeURIComponent(key)}`)
    return data
  }
)

/** Delete an invitation. */
export const deleteInvitation = apiProxy('deleteInvitation', async (accountId: string, invitationId: string): Promise<Invitation> => {
  await getAccessToken(aadConfig().accountScopes)
  const { data } = await axios.delete(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/invitations/${invitationId}`)
  return data
})

/** Resend an invitation. */
export const resendInvitation = apiProxy('resendInvitation', async (accountId: string, invitationId: string): Promise<Invitation> => {
  await getAccessToken(aadConfig().accountScopes)
  const { data } = await axios.put(`${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}/invitations/${invitationId}/resend`)
  return data
})

/** Lookup a school. */
export const schoolLookup = apiProxy('schoolLookup', async (name: string) => {
  const { data } = await axios.get(
    `https://api.data.gov/ed/collegescorecard/v1/schools.json?fields=id,school.name&school.name=${encodeURIComponent(name)}&api_key=KWCevTbRUDsZtdQi5oSi5qbyENY8dWmdO68ihA5n`
  )
  return data
})

/** Fetch all transactions. */
export const getAllTransactions = apiProxy(
  'getAllTransactions',
  async (offset?: number, limit?: number, sort?: string, sortDir?: string, filter?: string): Promise<{ total: number; transactions: Transaction[] }> => {
    await getAccessToken(aadConfig().accountScopes)

    const q: string[] = [`offset=${offset || 0}`, `limit=${limit || 50}`]
    if (sort) {
      q.push(`sort=${encodeURIComponent(sort)}`)
      q.push(`sortDir=${encodeURIComponent(sortDir || 'asc')}`)
    }
    if (filter) q.push(`filter=${encodeURIComponent(filter)}`)

    const { data } = await axios.get<{ total: number; transactions: Transaction[] }>(`${process.env.REACT_APP_ACCOUNT_API}/transactions?${q.join('&')}`)
    return data
  }
)

export interface TransactionStatItem {
  total?: number
  amount: number
  credit: number
  debit: number
}
export type TransactionStats = TransactionStatItem & {
  threeMonth: (TransactionStatItem & { month: number; year: number })[]
}

export interface GetTransactionStatsParams {
  resourceId?: string
  type?: string
  start?: number
  end?: number
  sort?: string
  sortDir?: string
  filter?: string
}
/** Fetch transaction statistics. */
export const getTransactionStats = apiProxy('getTransactionStats', async ({ resourceId, type, start, end, sort, sortDir, filter }: GetTransactionStatsParams): Promise<TransactionStats> => {
  await getAccessToken(aadConfig().accountScopes)

  const q: string[] = [`start=${start || moment.utc().startOf('y').valueOf()}`, `end=${end || moment.utc().endOf('y').valueOf()}`]
  if (sort) {
    q.push(`sort=${encodeURIComponent(sort)}`)
    q.push(`sortDir=${encodeURIComponent(sortDir || 'asc')}`)
  }
  if (filter) q.push(`filter=${encodeURIComponent(filter)}`)

  q.push(`resourceId=${resourceId}`)
  q.push(`type=${type}`)

  const { data } = await axios.get<TransactionStats>(`${process.env.REACT_APP_ACCOUNT_API}/transactions/statistics?${q.join('&')}`)
  return data
})

/** Fetch client transactions. */
export const getClientTransactions = apiProxy(
  'getClientTransactions',
  async (clientId: string, offset?: number, limit?: number, sort?: string, sortDir?: string, filter?: string): Promise<{ total: number; transactions: Transaction[] }> => {
    await getAccessToken(aadConfig().accountScopes)

    const q: string[] = [`offset=${offset || 0}`, `limit=${limit || 50}`]
    if (sort) {
      q.push(`sort=${encodeURIComponent(sort)}`)
      q.push(`sortDir=${encodeURIComponent(sortDir || 'asc')}`)
    }
    if (filter) q.push(`filter=${encodeURIComponent(filter)}`)

    const { data } = await axios.get<{ total: number; transactions: Transaction[] }>(`${process.env.REACT_APP_ACCOUNT_API}/transactions/clients/${clientId}?${q.join('&')}`)
    return data
  }
)

/** Fetch provider transactions. */
export const getProviderTransactions = apiProxy(
  'getProviderTransactions',
  async (providerId: string, offset?: number, limit?: number, sort?: string, sortDir?: string, filter?: string): Promise<{ total: number; transactions: Transaction[] }> => {
    await getAccessToken(aadConfig().accountScopes)

    const q: string[] = [`offset=${offset || 0}`, `limit=${limit || 50}`]
    if (sort) {
      q.push(`sort=${encodeURIComponent(sort)}`)
      q.push(`sortDir=${encodeURIComponent(sortDir || 'asc')}`)
    }
    if (filter) q.push(`filter=${encodeURIComponent(filter)}`)

    const { data } = await axios.get<{ total: number; transactions: Transaction[] }>(`${process.env.REACT_APP_ACCOUNT_API}/transactions/providers/${providerId}?${q.join('&')}`)
    return data
  }
)

/** Fetch user transactions. */
export const getUserTransactions = apiProxy(
  'getUserTransactions',
  async (userId: string, offset?: number, limit?: number, sort?: string, sortDir?: string, filter?: string): Promise<{ total: number; transactions: Transaction[] }> => {
    await getAccessToken(aadConfig().accountScopes)

    const q: string[] = [`offset=${offset || 0}`, `limit=${limit || 50}`]
    if (sort) {
      q.push(`sort=${encodeURIComponent(sort)}`)
      q.push(`sortDir=${encodeURIComponent(sortDir || 'asc')}`)
    }
    if (filter) q.push(`filter=${encodeURIComponent(filter)}`)

    const { data } = await axios.get<{ total: number; transactions: Transaction[] }>(`${process.env.REACT_APP_ACCOUNT_API}/transactions/users/${userId}?${q.join('&')}`)
    return data
  }
)
