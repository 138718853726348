import React, { useMemo } from 'react'
import { ProjectContentEx } from 'services/src/state'
import { useMsalAccessToken } from 'services/src/aad'

export const OfficeView: React.FC<{ content: ProjectContentEx }> = ({ content }) => {
  const accessToken = useMsalAccessToken()

  const contentUrl = useMemo(() => {
    const url = `${content.url.includes('sv=') ? content.url : `${content.url}?access_token=${accessToken}`}`
    return `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(url)}`
  }, [content, accessToken])

  if (!content.url) return null

  return (
    <iframe title={content.originalName} src={contentUrl} style={{ width: '100%', height: '100%', border: 'none' }} width="100%" height="100%" frameBorder="0">
      <p>Your browser does not support iframes.</p>
    </iframe>
  )
}
