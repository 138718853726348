import React, { SVGProps } from 'react'
import './style.scss'

const NewProject: React.FC<SVGProps<SVGSVGElement>> = ({ className, ...rest }) => (
  <svg viewBox="0 0 25 23" xmlns="http://www.w3.org/2000/svg" className={`ui-svg ui-svg-new-project ${className || ''}`} {...rest}>
    <path
      d="M22.5078 2.94727H1.50781C0.955528 2.94727 0.507812 3.39498 0.507812 3.94727V19.4473C0.507812 19.9995 0.955528 20.4473 1.50781 20.4473H22.5078C23.0601 20.4473 23.5078 19.9995 23.5078 19.4473V3.94727C23.5078 3.39498 23.0601 2.94727 22.5078 2.94727Z"
      className="folder"
    />
    <path
      d="M3.00781 0.947266H10.0078C10.273 0.947266 10.5274 1.05262 10.7149 1.24016C10.9025 1.4277 11.0078 1.68205 11.0078 1.94727V2.94727H2.00781V1.94727C2.00781 1.68205 2.11317 1.4277 2.30071 1.24016C2.48824 1.05262 2.7426 0.947266 3.00781 0.947266Z"
      className="brand"
    />
    <path
      d="M19.0078 22.9473C21.7692 22.9473 24.0078 20.7087 24.0078 17.9473C24.0078 15.1858 21.7692 12.9473 19.0078 12.9473C16.2464 12.9473 14.0078 15.1858 14.0078 17.9473C14.0078 20.7087 16.2464 22.9473 19.0078 22.9473Z"
      className="brand"
    />
    <path d="M17.0078 17.9473H21.0078" className="plus" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M19.0078 19.9473V15.9473" className="plus" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
  </svg>
)
export default NewProject
