import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ProjectContentEx } from 'services/src/state'
import { useMsalAccessToken } from 'services/src/aad'
import LoadingSvg from 'components/src/svg/Loading'

import { PDFDocumentProxy } from 'pdfjs-dist'
import { PDFPageProxy } from 'react-pdf'
import { Point } from './common'
import { Loading } from '../loading'

const pdfjsLib = window['pdfjs-dist/build/pdf' as any] as any
pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.js'

const vpScale = () => {
  const scales: any = { 1: 3.2, 2: 4 }
  const defaultScale = 3
  return scales[window.devicePixelRatio] || defaultScale
}

interface PageEx {
  page?: PDFPageProxy
  dataUrl?: string
  width?: number
  height?: number
}

const PdfPage: React.FC<{
  page: PageEx
  parentWidth: number
  zoom: number
  rotate: number
}> = ({ page, parentWidth, zoom, rotate }) => {
  const [loading, setLoading] = useState(false)
  const [dataUrl, setDataUrl] = useState<string>()
  const [size, setSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 })

  // useEffect(() => {
  //   pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js';
  // }, [])

  useEffect(() => {
    if (!page.page) return

    const scale = vpScale()

    // const r = (360 - page.page.rotate)
    const viewport = page.page.getViewport({ scale, rotation: page.page.rotate + rotate })
    const { width, height } = viewport

    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height

    const displayWidth = 1.5
    canvas.style.width = `${(width * displayWidth) / scale}px`
    canvas.style.height = `${(height * displayWidth) / scale}px`

    setLoading(true)
    page.page
      .render({
        canvasContext: canvas.getContext('2d') as any,
        viewport
      })
      .promise.then(() => {
        setSize({ width, height })
        setDataUrl(canvas.toDataURL())
      })
      .finally(() => setLoading(false))
  }, [page, rotate, setDataUrl, setLoading])

  if (!dataUrl) {
    const height = page.height ? page.height * zoom : 792 * zoom
    return (
      <div className="ui-document-view-page-loading" style={{ height }}>
        <LoadingSvg style={{ width: 30, height: 30 }} />
      </div>
    )
  }

  return (
    <div
      style={{
        position: 'relative',
        left: parentWidth / 2 - (size.width * zoom) / 2,
        width: size.width * zoom,
        height: size.height * zoom
      }}
    >
      {loading && <LoadingSvg style={{ width: 30, height: 30 }} />}
      <img alt="" src={dataUrl} />
    </div>
  )
}

export const PdfView: React.FC<{
  content: ProjectContentEx
  view: string
  onAnchorPtChange: (anchorPt: Point) => void
  zoom: number
  onZoomChange: (zoom: number) => void
  isFit: boolean
  rotate: number
}> = ({ content, view, onAnchorPtChange, zoom, onZoomChange, isFit, rotate }) => {

  const accessToken = useMsalAccessToken()

  const [, setPdfDoc] = useState<PDFDocumentProxy>()

  const [pdfPages, setPdfPages] = useState<PageEx[] | undefined>()

  const pdfPageRef = useRef<PageEx[] | undefined>()
  pdfPageRef.current = pdfPages

  const [ready, setReady] = useState(false)

  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleFit = useCallback(
    (pages?: PageEx[], maxZoom?: number) => {
      if (!pages) pages = pdfPages
      if (!pages?.length || !pages[0].page || !wrapperRef.current) return

      const parentRect = wrapperRef.current.getBoundingClientRect()

      const scale = vpScale()

      const viewport = pages[0].page.getViewport({ scale, rotation: pages[0].page.rotate + rotate })

      // const pw = parentRect.width - 40;
      const z = maxZoom ? Math.min(maxZoom, parentRect.width / viewport.width) : parentRect.width / viewport.width
      onZoomChange(z)

      onAnchorPtChange({
        left: parentRect.width / 2 - (viewport.width * z) / 2,
        top: 0
      })
    },
    [pdfPages, onZoomChange, onAnchorPtChange, rotate]
  )

  useEffect(() => {
    if (isFit) handleFit()
    else {
      const parentRect = wrapperRef.current?.getBoundingClientRect()
      if (pdfPages?.length && parentRect) {
        const vp = pdfPages[0]!.page!.getViewport({ scale: 1 })
        onAnchorPtChange({
          left: parentRect.width / 2 - (vp.width * zoom) / 2,
          top: 0
        })
      }
    }
  }, [isFit, view, handleFit])

  useEffect(() => {
    const intHandleFit = () => handleFit(pdfPageRef.current, 1)
    window.addEventListener('docView.fit', intHandleFit)
    return () => {
      window.removeEventListener('docView.fit', intHandleFit)
    }
  }, [handleFit])

  useEffect(() => {
    const pages: PageEx[] = []

    pdfjsLib
      .getDocument(`${content.url.includes('sv=') ? content.url : `${content.url}?access_token=${accessToken}`}`)
      .promise.then((pdf: PDFDocumentProxy) => {
        setPdfDoc(pdf)

        const { numPages } = pdf
        const p: Promise<void>[] = []

        // Push empty pages to generate loading frames.
        for (let i = 0; i < numPages; i++) {
          pages.push({})
        }
        setPdfPages(pages)

        // Load the pages
        for (let i = 0; i < numPages; i++) {
          p.push(
            pdf.getPage(i + 1).then((page) => {
              const scale = vpScale()

              const viewport = page.getViewport({ scale, rotation: page.rotate + rotate })
              const { width, height } = viewport

              pages[page.pageNumber - 1] = {
                page: page as PDFPageProxy,
                width,
                height
              }
              setPdfPages([...pages])
            })
          )
        }
        return Promise.all(p)
      })
      .finally(() => {
        handleFit(pages, 1)
        setTimeout(() => setReady(true))
      })
  }, [])

  if (!pdfPages)
    return (
      <div style={{ marginTop: 200 }}>
        <Loading size="sm" variant="static" />
      </div>
    )

  return (
    <div ref={wrapperRef} style={{ visibility: ready ? 'visible' : 'hidden' }}>
      {pdfPages.map((page, idx) => {
        const parentRect = wrapperRef.current?.getBoundingClientRect()
        return <PdfPage key={idx} page={page} parentWidth={parentRect?.width || 0} zoom={zoom} rotate={rotate} />
      })}
    </div>
  )
}
