import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConstructionCategory } from 'services/src/dto/projectSupport'
import { ProjectType, ProjectTypeSource } from 'services/src/dto/projectShare'

import { TextField } from 'components/src/textField'
import { Modal, ModalProps } from 'components/src/modal'
import { CardSelectField } from 'components/src/cardSelectField'
import { SelectField } from 'components/src/selectField'

export type NewProjectTypeProps = ModalProps & {
  projectType?: ProjectType
  onNew: (projectType: Partial<ProjectType>) => void
  constructionCategories: ConstructionCategory[]
}

export const NewProjectType: React.FC<NewProjectTypeProps> = ({ projectType, onClose, onNew, constructionCategories }) => {
  const { t } = useTranslation()

  const [errors, setErrors] = useState<any>({})
  const clearError = (name: string) => {
    const theErrors = { ...errors }
    delete theErrors[name]
    setErrors(theErrors)
  }

  const [name, setName] = useState(projectType ? projectType.type || '' : '')

  const [selectedConstructionCategoryIdx, setSelectedConstructionCategoryIdx] = useState<number>(() => {
    if (!projectType || !constructionCategories) return -1
    return constructionCategories.findIndex((x) => x.category === projectType.constructionCategory)
  })

  const [projectCategories, setProjectCategories] = useState<{ id: string; label: string }[]>([])
  const [selectedProjectCategory, setSelectedProjectCategory] = useState<{ id: string; label: string }>()

  useEffect(() => {
    if (selectedConstructionCategoryIdx < 0) {
      setProjectCategories([])
      return
    }

    const c = constructionCategories[selectedConstructionCategoryIdx]
    const pc = c.projectCategories.map((x) => ({ id: x, label: x }))
    setProjectCategories(pc)
    setSelectedProjectCategory(pc.find((x) => x.label === projectType?.projectCategory))
  }, [selectedConstructionCategoryIdx, constructionCategories, setProjectCategories, setSelectedProjectCategory, projectType?.projectCategory])

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      const errors: any = {}

      if (selectedConstructionCategoryIdx < 0) errors.constructionCategory = t('General.Errors.Required')
      if (!selectedProjectCategory) errors.projectCategory = t('General.Errors.Required')
      if (!name) errors.name = t('General.Errors.Required')

      setErrors(errors)
      if (Object.keys(errors).length > 0) return

      onNew({
        constructionCategory: constructionCategories ? constructionCategories[selectedConstructionCategoryIdx].category : '',
        projectCategory: selectedProjectCategory?.label || '',
        source: ProjectTypeSource.User,
        type: name
      })
    },
    [onNew, setErrors, selectedConstructionCategoryIdx, selectedProjectCategory, name, constructionCategories, t]
  )

  return (
    <Modal onClose={onClose} closeOnClickOutside={false} header={<h3>{t('NewProject.NewProjectType.AddProjectType')}</h3>}>
      <form onSubmit={submit} noValidate autoComplete="off">
        <CardSelectField
          items={constructionCategories ? constructionCategories.map((x) => x.category) : []}
          selected={selectedConstructionCategoryIdx >= 0 ? [selectedConstructionCategoryIdx] : []}
          onClick={(idx) => {
            setSelectedConstructionCategoryIdx(idx)
            clearError('constructionCategory')
          }}
          label={t('NewProject.NewProjectType.ConstructionCategory')}
          error={errors.constructionCategory}
        />

        <SelectField
          items={projectCategories}
          selected={selectedProjectCategory}
          label={t('NewProject.NewProjectType.ProjectCategory')}
          error={errors.projectCategory}
          onSelectedChange={(x) => {
            setSelectedProjectCategory(x)
            clearError('projectCategory')
          }}
          placeholder={t('NewProject.NewProjectType.ProjectCategorySample')}
          disabled={projectCategories.length <= 0}
          maxDropDown={4}
        />

        <TextField
          name="name"
          value={name}
          error={errors.name}
          label={t('NewProject.NewProjectType.Name')}
          placeholder={t('NewProject.NewProjectType.NameSample')}
          onChange={(name: string) => {
            setName(name)
            clearError('name')
          }}
        />

        <button type="submit" className="ui-btn ui-btn-primary ui-btn-solid" style={{ width: '100%' }}>
          {t('General.Create')}
        </button>
      </form>
    </Modal>
  )
}
