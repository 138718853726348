import { EstimateEx, PhaseEx, ProjectEx, UseUsersHookGetUserFn } from 'services/src/state'
import { EstimateLabor, OtherFee } from 'services/src/dto/project'
import { User } from 'services/src/dto/account'
import { getDifficultyItems } from 'services/src/common'
import { TFunction } from 'react-i18next'

export interface FeeGridViewModel {
  isReady: boolean
  isDirty: boolean
  variant: 'all' | 'assign'
  project: ProjectEx
  phase: PhaseEx
  estimates: FeeGridViewModelEstimate[]
  providerFee?: number
  errors?: any
}
export interface FeeGridViewModelEstimate {
  phase: PhaseEx
  estimate: EstimateEx
  discipline: string
  isOpen?: boolean

  generatedHours: number
  generatedTotal: number

  difficulty: number
  providerBaseRate: number
  providerRate: number
  isManualProviderRate: boolean

  hours: number

  subtotal: number
  otherFeeTotal: number
  total: number

  labor: FeeGridViewModelEstimateLabor[]

  otherFees: FeeGridViewModelOtherFee[]

  errors?: any
}

export type FeeGridViewModelEstimateLabor = EstimateLabor & {
  userSuggestions: User[]
  userName: string
  user?: User
  assignToAll?: boolean
}

export type FeeGridViewModelOtherFee = OtherFee & {
  errors?: any
}

export const toViewModel = async (project: ProjectEx, phase: PhaseEx, vmx: FeeGridViewModel, t: TFunction, getUser: UseUsersHookGetUserFn): Promise<FeeGridViewModel> => {
  const difficultyItems = getDifficultyItems(t)

  const userIds: string[] = []
  phase.users?.forEach((u) => userIds.findIndex((id) => id === u.id) < 0 && userIds.push(u.id))
  phase.estimates?.forEach((est) => {
    est.labor?.filter((l) => !!l.userId).forEach((l) => userIds.findIndex((id) => id === l.userId) < 0 && userIds.push(l.userId!))
  })

  const users = await getUser(userIds)

  /*
  phase.estimates!.forEach(est => {
    const f = est.manualTotals!.subtotal / phase.manualTotals!.subtotal;

    est.negotiatedTotals = {...est.manualTotals!};
    est.negotiatedTotals.subtotal = phase.negotiatedFee! * f;
    est.negotiatedTotals.total = est.negotiatedTotals.subtotal + (est.manualTotals?.otherFeeTotal || 0);
  });
  */

  const vm: FeeGridViewModel = {
    isReady: true,
    isDirty: false,
    variant: 'all',
    project,
    phase,
    providerFee: phase.providerFee,
    estimates: phase.estimates!.map((estimate) => {
      const multiplier = difficultyItems.find((d) => d.id === estimate.difficulty.toString(10))?.multiplier || 1

      const otherFeeTotal = estimate.otherFees?.reduce((a, b) => a + (b.amount || 0), 0) || 0

      const providerRate = estimate.manualBaseRate === estimate.baseRate ? estimate.baseRate * multiplier : estimate.manualBaseRate

      const existingEst = vmx.estimates?.find((est) => est.estimate.id === estimate.id)

      const e: FeeGridViewModelEstimate = {
        isOpen: existingEst?.isOpen,
        phase,
        estimate,
        discipline: estimate.discipline,

        generatedHours: estimate.levelOfEffortHours,
        generatedTotal: estimate.baseRate * estimate.levelOfEffortHours,

        difficulty: estimate.difficulty,
        providerBaseRate: estimate.manualBaseRate,
        providerRate,
        isManualProviderRate: estimate.manualBaseRate !== estimate.baseRate,

        hours: estimate.manualLevelOfEffortHours,

        subtotal: estimate.manualLevelOfEffortHours * providerRate,
        otherFeeTotal,
        total: estimate.manualLevelOfEffortHours * providerRate + otherFeeTotal,

        labor:
          estimate.labor?.map<FeeGridViewModelEstimateLabor>((l) => {
            const user = users.find((u) => u.id === l.userId)

            const lx: FeeGridViewModelEstimateLabor = {
              id: l.id,
              userId: l.userId,
              status: l.status,
              statusHistory: l.statusHistory,
              rate: l.rate || 0,
              split: l.split || 0,
              skills: [...(l.skills || [])],
              userSuggestions: [],
              userName: user ? `${user.givenName} ${user.familyName}` : '',
              user,
              hours: l.hours,
              cost: l.cost,
              margin: l.margin
            }

            if (user) {
              if (!lx.rate) lx.rate = user.hourlyRate || 0
            }

            lx.cost = lx.rate * (lx.hours || 0)
            lx.margin = (providerRate - lx.rate) / providerRate

            return lx
          }) || [],
        otherFees: estimate.otherFees.map((of) => ({ ...of }))
      }

      return e
    })
  }

  return vm
}

export const toModel = (vm: FeeGridViewModel): PhaseEx => {
  const phase: PhaseEx = {
    ...vm.phase,
    providerFee: vm.providerFee,
    estimates: vm.estimates!.map((estimate) => {
      const est: EstimateEx = {
        ...estimate.estimate,
        difficulty: estimate.difficulty,
        manualBaseRate: estimate.providerBaseRate,
        manualLevelOfEffortHours: estimate.hours,
        labor: estimate.labor.map((l) => ({
          id: l.userId,
          userId: l.userId,
          split: l.split,
          rate: l.rate,
          hours: l.hours,
          cost: l.cost,
          margin: l.margin,
          status: l.status,
          statusHistory: l.statusHistory
        })),
        otherFees: estimate.otherFees.map((of) => ({ ...of }))
      }
      return est
    })
  }

  return phase
}
