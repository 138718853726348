import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faArrowUpRightFromSquare, faCopy, faUpFromLine, faDownFromLine } from '@fortawesome/pro-regular-svg-icons'
import { parsePhoneNumber } from 'libphonenumber-js'
import './style.scss'

import { Account, User } from 'services/src/dto/account'
import { useTranslation } from 'react-i18next'

import { ICellRendererParams, RowClassParams } from 'ag-grid-community'
import 'ag-grid-enterprise'

import { useMe } from 'services/src/state'
import { IconDefinition } from '@fortawesome/pro-light-svg-icons'

export type UserEx = User & {
  canEdit?: boolean
  roleLabel?: string
  userIcon?: IconDefinition
  badge?: string
  disciplines?: string[]
  isInherited?: boolean
  isLower?: boolean
}

type NameCellProps = Omit<ICellRendererParams, 'data'> & {
  data: Account
}
const NameCell: React.FC<NameCellProps> = ({ data }) => {
  const { t } = useTranslation()
  const [me] = useMe()

  return (
    <div className={`ui-flex ui-text-ellipsis${me?.id === data.id ? ' ui-is-me' : ''}`} style={{ alignItems: 'center' }}>
      {data.logoUrl ? (
        <div className="ui-picture-circle" style={{width: 30, height: 30}}>
          <div style={{ backgroundImage: `url("${data.logoUrl}")` }} />
        </div>
      ) : (
        <div className="ui-picture-circle" style={{width: 30, height: 30}}>
          <div>
            {data.companyName[0].toUpperCase()}
          </div>
        </div>
      )}
      <div style={{marginLeft: 10}}>{data.companyName}</div>
    </div>
  )
}

const NoRowsOverlay = () => {
  const { t } = useTranslation()
  return (
    <div className="no-rows-overlay">
      <h3>{t('General.NoClients')}</h3>
    </div>
  )
}

export interface ResourceUsersProps {
  accounts?: Account[]
  onDelete?: (user: User) => void
  onSelect?: (user: User) => void
  readOnly?: boolean
}

export const AccountPeerList: React.FC<ResourceUsersProps> = ({ accounts, onDelete, onSelect, readOnly }) => {
  const { t } = useTranslation()

  const [height, setHeight] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)

  const resize = useCallback(() => {
    if (!containerRef.current) return
    const r = containerRef.current.getBoundingClientRect()
    setHeight(window.innerHeight - r.top)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  return (
    <div className="ui-resource-users">
      <div className="ag-theme-cc" style={{ height, width: '100%', overflow: 'hidden' }} ref={containerRef} onLoad={() => setTimeout(() => resize())}>
        <AgGridReact
          defaultColDef={{
            width: 150,
            minWidth: 150,
            editable: false,
            resizable: true,
            sortable: true,
            sortingOrder: ['asc', 'desc']
          }}
          frameworkComponents={{
            nameCell: NameCell,
            noRowsOverlay: NoRowsOverlay
          }}
          rowData={accounts}
          rowClassRules={{
            'ag-row-inactive': ({ data }: RowClassParams) => !!data.isInherited || !!data.isLower
          }}
          // animateRows
          rowSelection="single"
          suppressCellSelection
          suppressRowHoverHighlight
          suppressRowClickSelection
          noRowsOverlayComponent="noRowsOverlay"
          onCellClicked={() => {}}
          onSortChanged={() => {}}
          onGridReady={() => {
            resize()
          }}
        >
          <AgGridColumn
            field="companyName"
            headerName={t('General.Name')}
            cellRenderer="nameCell"
            valueGetter={({ data }: any) => data.companyName}
            filter="agTextColumnFilter"
            filterParams={{
              filterOptions: ['contains', 'startsWith', 'endsWith'],
              defaultOption: 'contains',
              suppressAndOrCondition: true
            }}
            minWidth={150}
            width={2000}
            flex={1}
          />
          <AgGridColumn
            field="offices"
            headerName={t('General.Offices')}
            valueGetter={({ data }: any) => data.counts?.offices || 0 }
            type="rightAligned"
            filter="agTextColumnFilter"
            filterParams={{
              filterOptions: ['contains', 'startsWith', 'endsWith'],
              defaultOption: 'contains',
              suppressAndOrCondition: true
            }}
            minWidth={120}
            width={120}
          />
          <AgGridColumn
            field="projects"
            headerName={t('General.Projects')}
            valueGetter={({ data }: any) => data.counts?.projects || 0 }
            type="rightAligned"
            filter="agTextColumnFilter"
            filterParams={{
              filterOptions: ['contains', 'startsWith', 'endsWith'],
              defaultOption: 'contains',
              suppressAndOrCondition: true
            }}
            minWidth={120}
            width={120}
          />
        </AgGridReact>
      </div>
    </div>
  )
}
