import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EstimateTypeKey, ProjectStatus } from 'services/src/dto/project'
import { useProjectSupport } from 'services/src/api'

import { PhaseTypeKey } from 'services/src/dto/projectShare'
import { DatePickerMarker } from 'components/src/datePicker'
import { PhaseEx, ProjectEx, useEditProject } from 'services/src/state'
import { useSaving } from 'components/src/saving'
import { PhaseModal } from '../common/PhaseModal'
import { Footer } from './Footer'
import { Timeline, adjustTimelineItems, TimelineItem, timelineItemsToPhases, updateTimelineItemsFromProject, updateTimelineMarkersFromProject } from '../common/Timeline'

export const ProjectTimeline: React.FC = () => {
  const { t } = useTranslation()

  const [{ phaseTypes }] = useProjectSupport()
  const { project: initProject, patch } = useEditProject()
  const [saving] = useSaving()

  const [project, setProject] = useState<ProjectEx>(JSON.parse(JSON.stringify(initProject)))
  const [dirty, setDirty] = useState(false)

  const [items, setItems] = useState<TimelineItem[]>()
  const [markers, setMarkers] = useState<DatePickerMarker[]>()

  useEffect(() => {
    setProject(JSON.parse(JSON.stringify(initProject)))
  }, [initProject, setProject])

  useEffect(() => {
    if (!project || !phaseTypes) return

    const items = updateTimelineItemsFromProject(project, phaseTypes, t)
    if (project.canEdit) {
      if ([ProjectStatus.InProgress, ProjectStatus.Complete, ProjectStatus.Canceled].includes(project.status!)) {
        setItems(() => {
          let found = -1
          return items.filter((item, idx) => {
            if (found >= 0) return true
            if (item.start) {
              found = idx
              return true
            }
            return false
          })
        })
      } else setItems(items)
    } else {
      setItems(() => items.filter((x) => (x.phase?.isVisible && !!x.start) || x.children?.find((c) => c.phase?.isVisible)))
    }

    const markers = updateTimelineMarkersFromProject(project, phaseTypes, t)
    setMarkers(markers)
  }, [project, phaseTypes, t])

  const [phaseModal, setPhaseModal] = useState<PhaseEx>()
  const [phaseModalAction, setPhaseModalAction] = useState<'add' | 'edit'>()

  const handleAddPhase = useCallback(() => {
    setPhaseModalAction('add')
    setPhaseModal({ id: `-${Date.now()}` } as PhaseEx)
  }, [setPhaseModalAction, setPhaseModal])

  useEffect(() => {
    window.addEventListener('addProjectPhase', handleAddPhase)
    return () => {
      window.removeEventListener('addProjectPhase', handleAddPhase)
    }
  }, [handleAddPhase])

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      if (!items) return

      const newItems = [...(items || [])]

      const patchPhases = project.phases.map((x) => {
        const r: PhaseEx = JSON.parse(JSON.stringify(x))
        if (r.id?.startsWith('-')) r.id = undefined
        r.name = r.name || undefined
        r.description = r.description || undefined
        r.variations?.forEach((v) => {
          if (!v.description) v.description = undefined
        })
        return r
      })

      if (!patchPhases.length) {
        newItems[0].error = t('NewProject.Timeline.OneRequired')
        setItems(newItems)
        return
      }

      patch([
        {
          propertyName: 'Phases',
          propertyValue: patchPhases
        }
      ]).then(() => {})

      setDirty(false)
    },
    [project, patch, items]
  )

  if (!items) return null

  return (
    <>
      <form noValidate onSubmit={submit}>
        <Timeline
          items={items || []}
          markers={markers}
          canEdit={project.canEdit}
          noAdd={project.estimateType === EstimateTypeKey.IndependentCostReview}
          maxPhases={project.estimateType === EstimateTypeKey.IndependentCostReview ? 1 : undefined}
          onChange={(items) => {
            const newItems = adjustTimelineItems([...items])
            const phases = timelineItemsToPhases(newItems)
            const p = { ...project, phases }
            setProject(p)
            setDirty(true)
          }}
          onEditPhase={(item: TimelineItem) => {
            const ph = project.phases?.find((x) => x.id === item.phaseId)
            if (!ph) return
            setPhaseModalAction('edit')
            setPhaseModal(ph)
            setDirty(true)
          }}
        />

        {(project.canEdit || project.canEditPhase) && (
          <Footer>
            <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center' }}>
              {/* <button type="button"
                      className="ui-btn ui-btn-primary ui-btn-solid"
                      onClick={() => history.goBack()}>
                {t('General.Exit')}
              </button> */}

              <div style={{ marginLeft: 'auto' }} />

              {/* project.canEditPhase && (
                <button type="button"
                        disabled={saving}
                        className="ui-btn ui-btn-primary ui-btn-solid"
                        style={{marginRight: 10}}
                        onClick={() => handleAddPhase()}>
                  {t('EditProject.Timeline.AddPhase')}
                </button>
              ) */}

              {project.canEdit && (
                <button type="submit" disabled={!dirty || saving} className="ui-btn ui-btn-secondary ui-btn-solid">
                  {t('General.Save')}
                </button>
              )}
            </div>
          </Footer>
        )}
      </form>

      {phaseModal && (
        <PhaseModal
          phaseTypes={phaseTypes}
          project={project}
          phase={phaseModal}
          action={phaseModalAction || 'add'}
          onClose={() => setPhaseModal(undefined)}
          onSubmit={(ph: PhaseEx) => {
            const p: ProjectEx = JSON.parse(JSON.stringify(project))
            if (!p.phases) p.phases = []

            const idx = p.phases.findIndex((x) => x.id === ph.id)
            if (idx < 0) p.phases.push({ ...ph, status: ProjectStatus.New, canEdit: true, canDelete: true })
            else p.phases[idx] = ph

            p.phases = [
              ...p.phases.filter((x) => x.phaseType === PhaseTypeKey.Concept),
              ...p.phases.filter((x) => x.phaseType === PhaseTypeKey.Schematic),
              ...p.phases.filter((x) => x.phaseType === PhaseTypeKey.DesignDoc),
              ...p.phases.filter((x) => x.phaseType === PhaseTypeKey.ConstructionDoc),
              ...p.phases.filter((x) => x.phaseType === PhaseTypeKey.Proposal)
            ]

            const items = updateTimelineItemsFromProject(p, phaseTypes, t)
            p.phases = timelineItemsToPhases(adjustTimelineItems(items))

            setProject(p)
            setPhaseModal(undefined)
            setDirty(true)
          }}
        />
      )}
    </>
  )
}
