import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ResourceUserRole } from 'services/src/dto/account'
import { Invitation } from 'services/src/dto/invitation'
import { ConfirmModal } from 'components/src/modal/ConfirmModal'
import { useSaving } from 'components/src/saving'

import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import 'ag-grid-enterprise'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareSquare, faTrashAlt } from '@fortawesome/pro-duotone-svg-icons'
import moment from 'moment'

type ActionCellProps = ICellRendererParams & {
  onDelete: (user: Invitation) => void
  onResend: (user: Invitation) => void
  readOnly?: boolean
}
const ActionCell: React.FC<ActionCellProps> = ({ data, onDelete, onResend, readOnly }) => {
  const { t } = useTranslation()
  const [saving] = useSaving()

  if (readOnly) return null

  return (
    <div>
      <button
        type="button"
        disabled={saving}
        className="ui-btn-empty ui-info"
        style={{ marginRight: 5 }}
        title={t('General.Resend')}
        onClick={(e) => {
          e.preventDefault()
          onResend(data)
        }}
      >
        <FontAwesomeIcon icon={faShareSquare} />
      </button>
      <button
        type="button"
        disabled={saving}
        className="ui-btn-empty ui-danger"
        title={t('General.Remove')}
        onClick={(e) => {
          e.preventDefault()
          onDelete(data)
        }}
      >
        <FontAwesomeIcon icon={faTrashAlt} />
      </button>
    </div>
  )
}

const NoRowsOverlay: React.FC<{ onNew: () => void }> = ({ onNew }) => {
  const { t } = useTranslation()
  return (
    <div className="no-rows-overlay">
      <h3>{t('General.NoInvitations')}</h3>
      <button type="button" className="ui-btn ui-btn-primary ui-btn-solid" style={{ marginTop: 30 }} onClick={() => onNew()}>
        {t('General.CreateInvitation')}
      </button>
    </div>
  )
}

export interface InvitationListProps {
  invitations: Invitation[]
  roles: { role: ResourceUserRole; label: string }[]
  clientList?: boolean
  onNew: () => void
  onDelete: (user: Invitation) => void
  onResend: (user: Invitation) => void
}

export const InvitationList: React.FC<InvitationListProps> = ({ invitations, roles, clientList, onNew, onDelete, onResend }) => {
  const { t } = useTranslation()

  const [height, setHeight] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const resize = () => {
      if (containerRef.current) {
        const r = containerRef.current.getBoundingClientRect()
        setHeight(window.innerHeight - r.top)
      }
    }
    window.addEventListener('resize', resize)
    setTimeout(() => resize())
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  useEffect(() => {
    if (containerRef.current) {
      const r = containerRef.current.getBoundingClientRect()
      containerRef.current.style.overflow = 'inherit'
      setHeight(window.innerHeight - r.top)
    }
  }, [containerRef.current])

  const [confirmDeleteInvitation, setConfirmDeleteInvitation] = useState<Invitation>()

  return (
    <>
      <div className="ui-invitations">
        <div className="ag-theme-cc" style={{ height, width: '100%', overflow: 'hidden' }} ref={containerRef}>
          <AgGridReact
            defaultColDef={{
              width: 150,
              editable: false,
              resizable: true,
              sortable: true,
              sortingOrder: ['asc', 'desc']
            }}
            frameworkComponents={{
              actionCell: ActionCell,
              noRowsOverlay: NoRowsOverlay
            }}
            rowData={invitations}
            rowSelection="single"
            noRowsOverlayComponent="noRowsOverlay"
            noRowsOverlayComponentParams={{ onNew }}
            suppressCellSelection
            suppressRowHoverHighlight
            onCellClicked={() => {
              // if (p.column.getColId() === 'action') return;
            }}
            onSortChanged={() => {}}
            onGridReady={() => {}}
          >
            <AgGridColumn
              field="name"
              headerName={t('General.Name')}
              valueGetter={({ data }: ValueGetterParams) => `${data.givenName} ${data.familyName}`}
              filter="agTextColumnFilter"
              filterParams={{
                filterOptions: ['contains', 'startsWith', 'endsWith'],
                defaultOption: 'contains',
                suppressAndOrCondition: true
              }}
              minWidth={200}
              width={2000}
              flex={1}
            />

            <AgGridColumn
              field="email"
              headerName={t('General.EmailAddress')}
              valueGetter={({ data }: ValueGetterParams) => data.emailAddress || t('General.NA')}
              filter="agTextColumnFilter"
              filterParams={{
                filterOptions: ['contains', 'startsWith', 'endsWith'],
                defaultOption: 'contains',
                suppressAndOrCondition: true
              }}
              minWidth={200}
              flex={1}
            />

            {clientList && (
              <AgGridColumn
                field="providerClient"
                headerName={t('General.ClientAccount')}
                valueGetter={({ data }: ValueGetterParams) => data.providerClient || t('General.NA')}
                filter="agTextColumnFilter"
                filterParams={{
                  filterOptions: ['contains', 'startsWith', 'endsWith'],
                  defaultOption: 'contains',
                  suppressAndOrCondition: true
                }}
                minWidth={200}
                flex={1}
              />
            )}


            {!clientList && (
              <AgGridColumn
                field="role"
                headerName={t('General.Role')}
                filter="agSetColumnFilter"
                filterParams={{
                  values: roles?.map((r) => r.role),
                  cellRenderer: (p: ICellRendererParams) => {
                    if (p.value.startsWith('(')) return p.value
                    return roles?.find((r) => r.role === p.value)?.label || ''
                  },
                  suppressSorting: true,
                  suppressMiniFilter: true,
                  defaultToNothingSelected: true
                }}
                minWidth={100}
                flex={1}
                valueGetter={({ data }: ValueGetterParams) => {
                  if (data.accountRole) return data.accountRole
                  if (data.officeRole) return data.officeRole
                  if (data.clientRole) return data.clientRole
                  if (data.projectRole) return data.projectRole
                  if (data.phaseRole) return data.phaseRole
                  if (data.providerRole) return data.providerRole
                  return undefined
                }}
                cellRenderer={({ data }: ICellRendererParams) => {
                  if (!roles) return ''

                  if (data.accountRole) return roles.find((x) => x.role === data.accountRole)?.label || t('General.NA')
                  if (data.officeRole) return roles.find((x) => x.role === data.officeRole)?.label || t('General.NA')
                  if (data.clientRole) return roles.find((x) => x.role === data.clientRole)?.label || t('General.NA')
                  if (data.projectRole) return roles.find((x) => x.role === data.projectRole)?.label || t('General.NA')
                  if (data.phaseRole) return roles.find((x) => x.role === data.phaseRole)?.label || t('General.NA')
                  if (data.providerRole) return roles.find((x) => x.role === data.providerRole)?.label || t('General.NA')

                  return t('General.NA')
                }}
              />
            )}

            <AgGridColumn
              field="created"
              headerName={t('General.Created')}
              filter="agDateColumnFilter"
              filterParams={{
                suppressAndOrCondition: true
                // browserDatePicker: false
              }}
              minWidth={150}
              width={150}
              valueGetter={({ data }: ValueGetterParams) => moment(data.statusAt).toDate()}
              cellRenderer={({ data }: ICellRendererParams) => moment(data.statusAt).format('ll')}
            />

            <AgGridColumn
              field="action"
              sortable={false}
              resizable={false}
              suppressMenu
              suppressMovable
              headerName=""
              minWidth={80}
              maxWidth={80}
              cellRenderer="actionCell"
              cellRendererParams={{
                onDelete: setConfirmDeleteInvitation,
                onResend
              }}
            />
          </AgGridReact>
        </div>
      </div>

      {confirmDeleteInvitation && (
        <ConfirmModal
          title={t('Settings.DeleteInvitationTitle')}
          message={t('Settings.DeleteInvitation', {
            name: `${confirmDeleteInvitation.givenName} ${confirmDeleteInvitation.familyName}`,
            email: confirmDeleteInvitation.emailAddress
          })}
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={() => {
            setConfirmDeleteInvitation(undefined)
            onDelete(confirmDeleteInvitation)
          }}
          onClose={() => setConfirmDeleteInvitation(undefined)}
        />
      )}
    </>
  )
}
