import React, { useEffect, useMemo, useState } from 'react'
import { useRights } from 'services/src/state'
import { PRIMARY_PROVIDER_ID, User } from 'services/src/dto/account'
import { getUsers } from 'services/src/api'
import { Loading } from '../../loading'
import { AgGridReact } from 'ag-grid-react'
import { ICellRendererParams } from 'ag-grid-community'
import { useHistory, useLocation } from 'react-router-dom'

const NameCell: React.FC<ICellRendererParams> = ({ data }) => {
  const user = data as User
  return (
    <div className="ui-flex ui-flex-nowrap ui-flex-align-center">
      <div className="ui-picture-circle" style={{ width: 25, height: 25, minWidth: 25, minHeight: 25 }}>
        {user.pictureUrl ? <div style={{ backgroundImage: `url('${user.pictureUrl}')`, border: 'none' }} /> : <div style={{ lineHeight: '23px' }}>{user.givenName[0].toUpperCase()}{user.familyName[0].toUpperCase()}</div>}
      </div>
      <div style={{ marginLeft: 10 }}>
        <div>{user.givenName} {user.familyName}</div>
      </div>
    </div>
  )
}

const AdminTools = () => {
  return (
    <div className="admin-tools">
      <button className="ui-btn ui-btn-primary">Create User</button>
    </div>
  )
}

export const Users = () => {
  const rights = useRights()
  const [users, setUsers] = useState<User[] | undefined>(undefined)

  const [height, setHeight] = useState(window.innerHeight - 140)

  const history = useHistory()
  const { pathname } = useLocation()

  useEffect(() => {
    getUsers({ providerId: PRIMARY_PROVIDER_ID }).then((users) => {
      setUsers(users)
    })
  }, [])

  useEffect(() => {
    const resize = () => {
      setHeight(window.innerHeight - 140)
    }
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  const columnDefs = useMemo(
    () => [
      {
        field: 'name',
        flex: 1,
        cellRenderer: 'nameCell',
        valueGetter: ({ data }: any) => `${data.givenName} ${data.familyName}`
      },
      {
        field: 'email',
        flex: 1,
        valueGetter: ({ data }: any) => data.emails[0].email
      }
    ],
    []
  )

  if (!users) return <Loading />

  return (
    <>
      <AdminTools />

      <div
        className="ag-theme-cc admin-table"
        style={{ height }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={users}
          defaultColDef={{
            editable: false,
            resizable: true,
            sortable: true,
            suppressMenu: false,
            sortingOrder: ['asc', 'desc'],
            suppressColumnsToolPanel: true,
            filter: 'agMultiColumnFilter',
            filterParams: {
              filterOptions: ['contains', 'startsWith', 'endsWith'],
              defaultOption: 'contains',
              suppressAndOrCondition: true
            },
          }}
          frameworkComponents={{
            nameCell: NameCell
          }}
          rowSelection="single"
          suppressCellSelection={true}
          onCellClicked={(c) => {
            if (c.colDef.field === undefined) return;
            history.push(`${pathname}/${c.data.id}`)
          }}
        />
      </div>
    </>
  )
}
