import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'

import logo from 'components/src/img/logo-alt.png'
import { useEditProject } from 'services/src/state'
import { ProjectStatus } from 'services/src/dto/project'
import { useSaving } from 'components/src/saving'
import { FeeApproveModal } from './FeeApproveModal'
import { FeeClientDeclined, PhaseWithFee } from './FeeClientDeclined'

export interface FeeClientAcceptProps {
  onAccept: () => void
  onDecline: (reason: string) => void
  onNegotiate: (phases: PhaseWithFee[], reason: string) => void
}
export const FeeClientAccept: React.FC<FeeClientAcceptProps> = ({ onAccept, onDecline, onNegotiate }) => {
  const { t } = useTranslation()
  const { project } = useEditProject()
  const [feeDeclined, setFeeDeclined] = useState(false)
  const [saving] = useSaving()

  const phases = useMemo(() => project.phases.filter((x) => x.status === ProjectStatus.FeeReady), [project])

  const totalFee = useMemo(() => phases.reduce((sum, ph) => sum + (ph.fee || 0), 0), [phases])

  const counterInfo = useMemo<{ message: string; totalFee: number } | undefined>(() => {
    const m = project.statusHistory?.find((x) => x.newStatus === ProjectStatus.FeeReady)?.metadata
    if (!m || !m.isCounterReject) return undefined
    return {
      message: m.message || '',
      totalFee: parseFloat(m.totalFee || '0')
    }
  }, [project])

  const [feeApprove, setFeeApprove] = useState(false)

  if (phases.length <= 0) return null

  return (
    <>
      <div className="project-edit-client-accept ui-frame ui-frame-bg ui-form-group">
        <div className="the-total">
          <img alt="" src={logo} />
          <div style={{ marginLeft: 10 }}>{t('EditProject.Fees.TotalBillable')}</div>
          <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: totalFee })}</div>
        </div>

        <div style={{ marginTop: 20 }} className="ui-text-sm">
          {phases.map((ph) => (
            <div key={ph.id}>
              <div className="ui-flex">
                <div>{t(`EditProject.${ph.phaseType}`)}</div>
                <div style={{ marginLeft: 'auto' }}>
                  {(() => {
                    if (!ph.fee || ph.fee <= 0) return null
                    return t('General.Currency.Value', { value: ph.fee })
                  })()}
                </div>
              </div>
              <div className="ui-text-xs ui-danger ui-flex" style={{ marginBottom: 10 }}>
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginTop: 2 }} />
                <div style={{ marginLeft: 5 }}>{t(`Phase.FeeNote.${ph.phaseType}`)}</div>
              </div>
            </div>
          ))}
        </div>

        {counterInfo && (
          <div style={{ marginTop: 20 }}>
            <div>
              {t('EditProject.Fees.CountOfferReject', {
                amount: t('General.Currency.Value', { value: counterInfo.totalFee })
              })}
            </div>
            {counterInfo.message && <blockquote>{counterInfo.message}</blockquote>}
          </div>
        )}

        <div style={{ marginTop: 30 }}>
          <button type="button" disabled={saving} className="ui-btn ui-btn-secondary ui-btn-solid" onClick={() => setFeeApprove(true)}>
            {t('EditProject.Fees.AcceptFees')}
          </button>
        </div>
        <div style={{ marginTop: 10 }}>
          <button type="submit" className="ui-btn ui-btn-primary ui-btn-solid" disabled={saving} onClick={() => setFeeDeclined(true)}>
            {t('EditProject.Fees.RejectFees')}
          </button>
        </div>
      </div>

      {feeDeclined && (
        <FeeClientDeclined
          originalFee={totalFee}
          onDecline={(reason) => {
            setFeeDeclined(false)
            onDecline(reason)
          }}
          onNegotiate={(phases, reason) => {
            setFeeDeclined(false)
            onNegotiate(phases, reason)
          }}
          onClose={() => setFeeDeclined(false)}
        />
      )}

      {feeApprove && (
        <FeeApproveModal
          onClose={() => setFeeApprove(false)}
          onYes={() => {
            setFeeApprove(false)
            onAccept()
          }}
          phases={phases}
          totalFee={totalFee}
        />
      )}
    </>
  )
}
