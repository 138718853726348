import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { setTitle } from 'services/src/dom'

import { OfficeCards } from 'components/src/dashboardParts/OfficeCards'
import { ProjectCountCharts } from 'components/src/dashboardParts/ProjectCountCharts'
import { RecentComments } from 'components/src/dashboardParts/RecentComments'
import { useRights } from 'services/src/state'
import { AccountType } from 'services/src/dto/account'

export const Home: React.FC = () => {
  const { t } = useTranslation()
  const rights = useRights()

  useEffect(() => {
    setTitle(t('Dashboard.Title'))
    return () => {
      setTitle()
    }
  }, [t])

  return (
    <div className="dashboard-home">
      <div className="ui-row">
        <div className="ui-col-12 ui-col-xl-9 dashboard-home-cell">
          <ProjectCountCharts />
        </div>
        <div className="ui-col-12 ui-col-xl-3 dashboard-home-cell">
          <RecentComments />
        </div>
        {rights?.accountType !== AccountType.Expert && (
          <div className="ui-col-12">
            <OfficeCards />
          </div>
        )}
      </div>
    </div>
  )
}
