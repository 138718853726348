import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment, { Moment } from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullseyeArrow, faCalculator, faCalendarClock, faCircle, faCompassDrafting, faMoneyBill1Wave } from '@fortawesome/pro-solid-svg-icons'
import { faCancel, faPenSquare, faSave } from '@fortawesome/pro-regular-svg-icons'

import { budgetRangeToLowHigh, PhaseEx, useEditProject, useProEst, useRights, UserType } from 'services/src/state'
import { useProjectSupport } from 'services/src/api'
import { PhaseTypeKey, ProjectStatus } from 'services/src/dto/projectShare'

import { useTheme } from 'components/src/theme'
import { Loading } from 'components/src/loading'

import logo from 'components/src/img/cc-light-alt.png'
import logoDk from 'components/src/img/cc-dark-alt.png'

import logoSm from 'components/src/img/logo.png'
import logoAlt from 'components/src/img/logo-alt.png'
import { CurrencyInput } from 'components/src/currencyInput'
import { useSaving } from 'components/src/saving'
import { DatePicker } from 'components/src/datePicker'
import { useAlert } from 'components/src/alerts'

export const PhaseEstimateSummary: React.FC<{
  phase: PhaseEx
}> = ({ phase }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const [{ budgetRanges }] = useProjectSupport()
  const { project, patch } = useEditProject()
  const [saving, setSaving] = useSaving()
  const rights = useRights()
  const { alertSuccess, alertDanger } = useAlert()

  const { estimate, details, loading: proEstLoading } = useProEst(project, phase)

  const { estimateTotal, accuracyLow, accuracyHigh, startDate, endDate, scopeDate, deltaClass, deltaLegend } = useMemo(() => {
    const total = (details ? details?.costs?.total || 0 : phase.manualEstimate?.total) || 0

    const scopeDate = phase.manualEstimate?.scopeDate || phase.submissionDate

    let lBy = 0
    let hBy = 0
    if (phase.phaseType === PhaseTypeKey.Concept) {
      lBy = 0.2
      hBy = 1
    } else if (phase.phaseType === PhaseTypeKey.Schematic) {
      lBy = 0.15
      hBy = 0.5
    } else if (phase.phaseType === PhaseTypeKey.DesignDoc) {
      lBy = 0.1
      hBy = 0.3
    } else if (phase.phaseType === PhaseTypeKey.ConstructionDoc) {
      lBy = 0.05
      hBy = 0.2
    } else if (phase.phaseType === PhaseTypeKey.Proposal) {
      lBy = 0.03
      hBy = 0.15
    }

    let accuracyLow: JSX.Element
    let accuracyHigh: JSX.Element
    let deltaClass: string | undefined

    if (project.budget?.isFixed) {
      const f = project.budget.fixed || 0
      accuracyLow = (
        <div>
          L: {t('General.Currency.Value', { value: f - f * lBy })} <span className="ui-success">(-{t('General.PercentValue', { value: lBy * 100 })})</span>
        </div>
      )
      accuracyHigh = (
        <div>
          H: {t('General.Currency.Value', { value: f + f * hBy })} <span className="ui-danger">(+{t('General.PercentValue', { value: hBy * 100 })})</span>
        </div>
      )

      if (total > 0) {
        const d = total / f
        if (d > 1) deltaClass = 'ui-danger'
        if (d >= 0.95 && d <= 1) deltaClass = 'ui-warn'
        if (d < 0.95) deltaClass = 'ui-success'
      }
    } else {
      const { low, high } = budgetRangeToLowHigh(project.budget?.range || 'Lt1M')
      accuracyLow = (
        <div>
          L: {t('General.Currency.Value', { value: low - low * lBy })} <span className="ui-success">(-{t('General.PercentValue', { value: lBy * 100 })})</span>
        </div>
      )
      accuracyHigh = (
        <div>
          H: {t('General.Currency.Value', { value: high + high * hBy })} <span className="ui-danger">(+{t('General.PercentValue', { value: hBy * 100 })})</span>
        </div>
      )

      if (total > 0) {
        const d = total / high
        if (d > 1) deltaClass = 'ui-danger'
        if (d >= 0.95 && d <= 1) deltaClass = 'ui-warn'
        if (d < 0.95) deltaClass = 'ui-success'
      }
    }

    const deltaLegend = (
      <>
        <div className="ui-flex ui-text-xxs" style={{ alignItems: 'center', marginTop: 10 }}>
          <FontAwesomeIcon icon={faCircle} className="ui-danger" style={{ fontSize: 7 }} />
          <div style={{ marginLeft: 3 }}>{t(project.budget?.isFixed ? 'EditProject.EstSummary.BudgetGt100' : 'EditProject.EstSummary.BudgetGtMax')}</div>

          <FontAwesomeIcon icon={faCircle} className="ui-warn" style={{ fontSize: 7, marginLeft: 10 }} />
          <div style={{ marginLeft: 3 }}>{t(project.budget?.isFixed ? 'EditProject.EstSummary.Budget95-100' : 'EditProject.EstSummary.Budget95-100Max')}</div>

          <FontAwesomeIcon icon={faCircle} className="ui-success" style={{ fontSize: 7, marginLeft: 10 }} />
          <div style={{ marginLeft: 3 }}>{t(project.budget?.isFixed ? 'EditProject.EstSummary.Budget0-95' : 'EditProject.EstSummary.Budget0-95Max')}</div>
        </div>
      </>
    )

    let sh = phase.statusHistory?.find((x) => x.newStatus === ProjectStatus.InProgress)
    const startDate = sh ? moment(sh.createdAt) : null

    sh = phase.statusHistory?.find((x) => [ProjectStatus.Complete, ProjectStatus.Canceled].includes(x.newStatus))
    const endDate = sh ? moment(sh.createdAt) : null

    return {
      estimateTotal: total,
      accuracyLow,
      accuracyHigh,
      startDate,
      endDate,
      scopeDate,
      deltaClass,
      deltaLegend
    }
  }, [estimate, details, phase, t])

  const [inEditTotal, setInEditTotal] = useState(false)
  const [editTotal, setEditTotal] = useState<string | undefined>()
  const [editTotalError, setEditTotalError] = useState<string | undefined>()
  const editTotalRef = useRef<HTMLInputElement>(null)

  const submitTotal = useCallback(
    (e?: React.FormEvent) => {
      if (e) e.preventDefault()

      if (!editTotal) {
        setEditTotalError(t('General.Errors.Required'))
        editTotalRef.current?.focus()
        return
      }

      setSaving(true)
      patch(
        [
          {
            propertyName: `Phases.${phase.id}.ManualEstimateTotal`,
            propertyValue: parseFloat(editTotal)
          }
        ],
        true
      )
        .then(() => {
          alertSuccess({
            title: t('EditProject.EstSummary.TotalUpdatedTitle'),
            message: t('EditProject.EstSummary.TotalUpdated')
          })
          setInEditTotal(false)
        })
        .catch((error) => {
          alertDanger({
            title: t('EditProject.EstSummary.Errors.TotalUpdatedTitle'),
            message: t('EditProject.EstSummary.Errors.TotalUpdated'),
            error
          })
        })
        .finally(() => setSaving(false))
    },
    [phase, editTotal, setEditTotalError, setSaving, patch, t]
  )

  const [inEditScopeDate, setInEditScopeDate] = useState(false)
  const [editScopeDate, setEditScopeDate] = useState<string | undefined>()
  const editScopeDateRef = useRef<any>(null)

  const submitScopeDate = useCallback(
    (d: Moment) => {
      setSaving(true)
      patch(
        [
          {
            propertyName: `Phases.${phase.id}.ScopeDate`,
            propertyValue: d.toDate()
          }
        ],
        true
      )
        .then(() => {
          alertSuccess({
            title: t('EditProject.EstSummary.ScopeDateUpdatedTitle'),
            message: t('EditProject.EstSummary.ScopeDateUpdated')
          })
          setInEditScopeDate(false)
        })
        .catch((error) => {
          alertDanger({
            title: t('EditProject.EstSummary.Errors.ScopeDateUpdatedTitle'),
            message: t('EditProject.EstSummary.Errors.ScopeDateUpdated'),
            error
          })
        })
        .finally(() => setSaving(false))
    },
    [phase, editTotal, setSaving, patch, t]
  )

  return (
    <div className="ui-frame ui-frame-bg ui-frame-xl-center" style={{ padding: 40, minHeight: 700 }}>
      {proEstLoading ? (
        <Loading variant="parent" size="sm" className="ui-loading-no-overlay" />
      ) : (
        <div className="ui-row">
          <div className="ui-col-12 ui-col-md-5">
            <h1 className="ui-secondary" style={{ fontSize: '2rem' }}>
              {t('EditProject.Summary')}
            </h1>

            <br />
            <div className="ui-form-group">
              <label>{t('EditProject.EstSummary.OPCLabel')}</label>
              <p>{t('EditProject.EstSummary.OPCInfo')}</p>
            </div>

            <div className="ui-form-group">
              <label>{t('EditProject.EstSummary.PurposeOfEstimate')}</label>

              <div className="ui-flex ui-flex-nowrap" style={{ marginTop: 15 }}>
                <div className="ui-frame ui-frame-no-padding ui-text-center" style={{ width: 35, height: 35, paddingTop: 7 }}>
                  <FontAwesomeIcon icon={faCompassDrafting} style={{ fontSize: '18px' }} />
                </div>
                <div style={{ marginLeft: 15 }}>
                  <div className="ui-text-xs ui-text-muted">{t('EditProject.EstSummary.TypeOfEstimate')}</div>
                  <div className="ui-text-sm">{t(`EditProject.${phase.phaseType}`)}</div>
                </div>
              </div>

              <div className="ui-flex ui-flex-nowrap" style={{ marginTop: 15 }}>
                <div className="ui-frame ui-frame-no-padding ui-text-center" style={{ width: 35, height: 35, paddingTop: 7 }}>
                  <FontAwesomeIcon icon={faBullseyeArrow} style={{ fontSize: '20px' }} />
                </div>
                <div style={{ marginLeft: 15 }}>
                  <div className="ui-text-xs ui-text-muted">{t('EditProject.EstSummary.LevelOfDetail')}</div>
                  <div className="ui-text-sm">{t(`EditProject.ClassLOD.${phase.phaseType}`)}</div>
                </div>
              </div>

              <div className="ui-flex ui-flex-nowrap" style={{ marginTop: 15 }}>
                <div className="ui-frame ui-frame-no-padding ui-text-center" style={{ width: 35, height: 35, paddingTop: 6 }}>
                  <FontAwesomeIcon icon={faCalculator} style={{ fontSize: '20px' }} />
                </div>
                <div style={{ marginLeft: 15 }}>
                  <div className="ui-text-xs ui-text-muted">{t('EditProject.EstSummary.ExpectedAccuracyRange')}</div>
                  <div className="ui-text-sm">
                    {accuracyLow}
                    {accuracyHigh}
                  </div>
                </div>
              </div>
            </div>

            <div className="ui-form-group">
              <label>{t('EditProject.EstSummary.Maturity')}</label>

              <div className="ui-flex ui-flex-nowrap" style={{ marginTop: 15 }}>
                <div className="ui-frame ui-frame-no-padding ui-text-center" style={{ width: 35, height: 35, paddingTop: 7 }}>
                  <FontAwesomeIcon icon={faCalendarClock} style={{ fontSize: '20px' }} />
                </div>
                <div style={{ marginLeft: 15 }}>
                  <div className="ui-text-xs ui-text-muted">{t('EditProject.EstSummary.DocumentsScopeDate')}</div>
                  {rights?.userType === UserType.provider ? (
                    <>
                      <div
                        className={`ui-text-sm ui-flex ui-action-item${inEditScopeDate ? ' ui-info' : ''}`}
                        onClick={() => {
                          setEditScopeDate(moment(scopeDate).format())
                          setInEditScopeDate(true)
                          setTimeout(() => {
                            editScopeDateRef.current?.focus()
                          }, 10)
                        }}
                        role="button"
                        tabIndex={-1}
                        style={{ position: 'relative' }}
                      >
                        {moment(scopeDate).format('LL')}
                        <FontAwesomeIcon icon={faPenSquare} style={{ marginLeft: 2, marginTop: 1, fontSize: '0.70rem' }} />
                      </div>

                      {inEditScopeDate && (
                        <DatePicker
                          ref={editScopeDateRef}
                          style={{ position: 'absolute', left: 200, top: 230 }}
                          maxMonths={1}
                          title={t('EditProject.EstSummary.DocumentsScopeDate')}
                          start={editScopeDate}
                          onClose={() => setInEditScopeDate(false)}
                          onSelectDate={(d) => {
                            setInEditScopeDate(false)
                            submitScopeDate(d)
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <div className="ui-text-sm">{moment(scopeDate).format('LL')}</div>
                  )}
                </div>
              </div>

              <div className="ui-flex ui-flex-nowrap" style={{ marginTop: 15 }}>
                <div className="ui-frame ui-frame-no-padding ui-text-center" style={{ width: 35, height: 35, paddingTop: 7 }}>
                  <FontAwesomeIcon icon={faCalendarClock} style={{ fontSize: '20px' }} />
                </div>
                <div style={{ marginLeft: 15 }}>
                  <div className="ui-text-xs ui-text-muted">{t('EditProject.EstSummary.StartDate')}</div>
                  <div className="ui-text-sm">{startDate ? startDate.format('LL') : t('General.NA')}</div>
                </div>
              </div>

              <div className="ui-flex ui-flex-nowrap" style={{ marginTop: 15 }}>
                <div className="ui-frame ui-frame-no-padding ui-text-center" style={{ width: 35, height: 35, paddingTop: 7 }}>
                  <FontAwesomeIcon icon={faCalendarClock} style={{ fontSize: '20px' }} />
                </div>
                <div style={{ marginLeft: 15 }}>
                  <div className="ui-text-xs ui-text-muted">{t('EditProject.EstSummary.EndDate')}</div>
                  <div className="ui-text-sm">{endDate ? endDate.format('LL') : t('General.NA')}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="ui-col-12 ui-offset-md-2 ui-col-md-5">
            {/*
          <div className="ui-form-group">
            <label>{t('EditProject.EstSummary.PricePerSQFT')}</label>

            <div style={{ marginTop: 15 }}>
              <div className="ui-text-xs ui-text-muted">{t('EditProject.EstSummary.BudgetPricePerSQFT')}</div>
              <div className="ui-text-sm">TBD</div>
            </div>

            <div style={{ marginTop: 15 }}>
              <div className="ui-text-xs ui-text-muted">{t('EditProject.EstSummary.CheckPricePerSQFT')}</div>
              <div className="ui-text-sm">TBD</div>
            </div>

            <div style={{ marginTop: 15 }}>
              <div className="ui-text-xs ui-text-muted">{t('EditProject.EstSummary.PercentDifference')}</div>
              <div className="ui-text-sm">TBD</div>
            </div>
          </div>
          */}

            <div className="ui-form-group">
              <img alt="" src={theme === 'dark' ? logo : logoDk} style={{ width: 170 }} />

              <div className="ui-flex ui-flex-nowrap" style={{ marginTop: 15 }}>
                <div className="ui-frame ui-frame-no-padding ui-text-center" style={{ width: 35, height: 35, paddingTop: 6 }}>
                  <FontAwesomeIcon icon={faMoneyBill1Wave} style={{ fontSize: '20px' }} />
                </div>
                <div style={{ marginLeft: 15 }}>
                  <div className="ui-text-xs ui-text-muted">{t('EditProject.EstSummary.ProjectBudget')}</div>
                  <div className="ui-text-lg">
                    {project.budget?.isFixed ? t('General.Currency.Value', { value: project.budget.fixed || 0 }) : budgetRanges.find((r) => r.id === project.budget?.range)?.range || ''}
                  </div>
                </div>
              </div>

              <div className="ui-flex ui-flex-nowrap" style={{ marginTop: 15 }}>
                <div className="ui-frame ui-frame-no-padding ui-text-center" style={{ width: 35, height: 35, paddingTop: 7 }}>
                  <img alt="" src={theme === 'dark' ? logoAlt : logoSm} style={{ width: 22 }} />
                </div>
                <div style={{ marginLeft: 15, position: 'relative' }}>
                  <div className="ui-text-xs ui-text-muted">{t('General.ProviderName')}</div>

                  {rights?.userType === UserType.provider ? (
                    <>
                      <div
                        className={`ui-text-lg ui-flex ui-action-item ${deltaClass || ''}`}
                        onClick={() => {
                          setEditTotal(estimateTotal.toFixed(2))
                          setInEditTotal(true)
                          setEditTotalError(undefined)
                          setTimeout(() => {
                            if (editTotalRef.current) {
                              editTotalRef.current.focus()
                              editTotalRef.current.select()
                            }
                          }, 10)
                        }}
                        role="button"
                        tabIndex={-1}
                      >
                        {t('General.Currency.Value', { value: estimateTotal })}
                        <FontAwesomeIcon icon={faPenSquare} style={{ marginLeft: 2, marginTop: 2, fontSize: '0.75rem' }} />
                      </div>
                      {inEditTotal && (
                        <form noValidate onSubmit={submitTotal}>
                          <div
                            className="ui-flex"
                            style={{
                              position: 'absolute',
                              left: 0,
                              top: 15,
                              width: '100%',
                              zIndex: 1
                            }}
                          >
                            <div className={editTotalError ? 'ui-has-error' : ''}>
                              <CurrencyInput
                                ref={editTotalRef}
                                disabled={saving}
                                amount={editTotal}
                                allowNegativeValue={false}
                                min={0}
                                style={{ width: 150 }}
                                onChange={(v) => {
                                  setEditTotal(v?.toString())
                                  setEditTotalError(undefined)
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Escape') {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setInEditTotal(false)
                                  }
                                  if (e.key === 'Enter') {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    submitTotal()
                                  }
                                }}
                                onBlur={(e) => {
                                  const { id } = (e.relatedTarget as any) || {}
                                  if (id === 'editTotalSubmit') return

                                  setInEditTotal(false)
                                }}
                              />
                              {editTotalError && <div className="ui-error ui-error-bg">{editTotalError}</div>}
                            </div>

                            <div style={{ marginLeft: 5 }}>
                              <div style={{ marginTop: -2, marginLeft: 1 }}>
                                <button type="submit" disabled={saving} className="ui-btn-empty ui-info" id="editTotalSubmit">
                                  <FontAwesomeIcon icon={faSave} />
                                </button>
                              </div>
                              <div style={{ marginTop: -5 }}>
                                <button type="button" disabled={saving} className="ui-btn-empty ui-danger" id="editTotalCancel" onClick={() => setInEditTotal(false)}>
                                  <FontAwesomeIcon icon={faCancel} />
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </>
                  ) : (
                    <div className={`ui-text-lg ${deltaClass || ''}`}>{t('General.Currency.Value', { value: estimateTotal })}</div>
                  )}
                </div>
              </div>
              {deltaLegend}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
