import React from 'react'
import { useTranslation } from 'react-i18next'
import { FeeGridExtraLabor } from './FeeGridExtraLabor'
import { FeeGridExtraOtherFees } from './FeeGridExtraOtherFees'
import { FeeGridViewModel, FeeGridViewModelEstimate } from './common'

export const FeeGridExtra: React.FC<{
  vm: FeeGridViewModel
  estimate: FeeGridViewModelEstimate
  onChange: (estimate: FeeGridViewModelEstimate) => void
}> = ({ vm, estimate, onChange }) => {
  const { t } = useTranslation()
  return (
    <>
      <label className="ui-text-bold ui-text-uppercase ui-text-xs">{t('EditProject.Fees.Labor')}</label>
      <FeeGridExtraLabor vm={vm} estimate={estimate} onChange={onChange} />

      {vm.variant !== 'assign' && <FeeGridExtraOtherFees estimate={estimate} onChange={onChange} />}
    </>
  )
}
