import React, { useEffect } from 'react'
import { signup } from 'services/src/aad'
import { Loading } from '../loading'
import { StringParam, useQueryParam } from 'use-query-params'
import { useRouteMatch } from 'react-router-dom'

export const Register: React.FC = () => {
  const { params} = useRouteMatch<{which: string}>()
  const [back] = useQueryParam('back', StringParam)

  useEffect(() => {
    // if already in b2c, fall back to app entry
    if (localStorage.getItem('inB2c')) {
      localStorage.removeItem('inB2c')
      window.location.href = '/'
      return
    }
    localStorage.setItem('inB2c', 'yes')
    signup(params.which || 'client', `${process.env.PUBLIC_URL || ''}/onboarding?action=signup&type=${params.which || 'client'}`, back)
      .catch(console.error)
  }, [])

  return <Loading />
}
