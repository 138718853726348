import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Account, ExpertRole, ExpertType, ResourceUserRole } from 'services/src/dto/account'
import { CreateInvitation } from 'services/src/dto/invitation'
import { DisciplineKey } from 'services/src/dto/project'

import { Modal, ModalProps } from 'components/src/modal'
import { TextField } from 'components/src/textField'
import { validateEmail } from 'services/src/validate'
import { PhoneInputField } from 'components/src/phoneInputField'
import { useGeolocation, useRights } from 'services/src/state'
import { CurrencyInputField } from 'components/src/currencyInputField'
import { CardSelectField } from 'components/src/cardSelectField'
import { SelectField } from 'components/src/selectField'

interface InviteViewModel {
  role?: ResourceUserRole
  givenName?: string
  familyName?: string
  email?: string
  mobilePhone?: string
  hourlyRate?: string
  discipline?: DisciplineKey
  expertType?: ExpertType
  expertRole?: ExpertRole
  errors: any
}

const InviteProvider: React.FC<{
  vm: InviteViewModel
  onChange: (vm: InviteViewModel) => void
  onSubmit: (vm: InviteViewModel) => void
}> = ({ vm, onChange, onSubmit }) => {
  const { t } = useTranslation()
  const { country_code: geo } = useGeolocation()
  const [saving] = useState(false)

  const disciplines = useMemo<
    {
      discipline: DisciplineKey
      label: string
    }[]
  >(
    () => [
      { discipline: DisciplineKey.General, label: t(`General.Disciplines.${DisciplineKey.General}`) },
      { discipline: DisciplineKey.Mechanical, label: t(`General.Disciplines.${DisciplineKey.Mechanical}`) },
      { discipline: DisciplineKey.Electrical, label: t(`General.Disciplines.${DisciplineKey.Electrical}`) },
      { discipline: DisciplineKey.SiteCivil, label: t(`General.Disciplines.${DisciplineKey.SiteCivil}`) }
    ],
    [t]
  )

  const [discipline, setDiscipline] = useState<{ discipline: DisciplineKey; label: string } | undefined>(disciplines.find((x) => x.discipline === vm.discipline))

  const expertTypes = useMemo<
    {
      id: ExpertType
      type: string
    }[]
  >(
    () => [
      { id: ExpertType.FTE, type: t(`General.ExpertTypes.FTE`) },
      { id: ExpertType.Contractor, type: t(`General.ExpertTypes.Contractor`) }
    ],
    [t]
  )
  const [expertType, setExpertType] = useState<{ id: ExpertType; type: string } | undefined>(expertTypes.find((x) => x.id === vm.expertType))

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      e.stopPropagation()

      const vmx: InviteViewModel = { ...vm, errors: {} }

      if (!vmx.givenName) vmx.errors.givenName = t('General.Errors.Required')
      if (!vmx.familyName) vmx.errors.familyName = t('General.Errors.Required')
      if (!vmx.email) vmx.errors.email = t('General.Errors.Required')
      else if (!validateEmail(vmx.email)) vmx.errors.email = t('General.Errors.InvalidEmail')
      if (!vmx.mobilePhone) vmx.errors.mobilePhone = t('General.Errors.Required')
      if (!vmx.hourlyRate || parseFloat(vmx.hourlyRate) <= 0) vmx.errors.hourlyRate = t('General.Errors.Required')
      if (!expertType) vmx.errors.expertType = t('General.Errors.Required')
      if (!discipline) vmx.errors.discipline = t('General.Errors.Required')

      if (Object.keys(vmx.errors).length) {
        onChange(vmx)
        return
      }

      onSubmit(vmx)
    },
    [vm, onChange, onSubmit]
  )

  return (
    <form noValidate onSubmit={submit}>
      <div className={`ui-row${vm.errors.givenName || vm.errors.familyName || vm.errors.email ? ' ui-has-error' : ''}`}>
        <div className="ui-col-12">
          <label>{t('Settings.To')}</label>
        </div>
        <div className="ui-col-6">
          <TextField
            name="givenName"
            type="text"
            value={vm.givenName}
            error={vm.errors.givenName}
            placeholder={t('Settings.GivenNameSample')}
            onChange={(givenName) => {
              const errors = { ...vm.errors }
              delete errors.givenName
              onChange({ ...vm, givenName, errors })
            }}
          />
        </div>

        <div className="ui-col-6">
          <TextField
            name="familyName"
            type="text"
            value={vm.familyName}
            error={vm.errors.familyName}
            placeholder={t('Settings.FamilyNameSample')}
            onChange={(familyName) => {
              const errors = { ...vm.errors }
              delete errors.familyName
              onChange({ ...vm, familyName, errors })
            }}
          />
        </div>

        <div className="ui-col-12">
          <TextField
            name="email"
            type="email"
            value={vm.email}
            error={vm.errors.email}
            placeholder={t('Settings.ToSample')}
            onChange={(email) => {
              const errors = { ...vm.errors }
              delete errors.email
              onChange({ ...vm, email, errors })
            }}
          />
        </div>
      </div>

      <div className="ui-row">
        <div className="ui-col-12">
          <SelectField
            items={expertTypes}
            onSelectedChange={(x) => {
              setExpertType(x)

              const errors = { ...vm.errors }
              delete errors.expertType
              onChange({ ...vm, expertType: x.id, errors })
            }}
            placeholder={t('Settings.InviteProvider.ExportTypeSample')}
            selected={expertType}
            labelField="type"
            valueField="id"
            error={vm.errors.expertType}
            label={t('Settings.InviteProvider.ExportType')}
          />
        </div>
      </div>

      <div className="ui-row">
        <div className="ui-col-8">
          <PhoneInputField
            label={t('Settings.InviteProvider.MobilePhone')}
            placeholder={t('Settings.InviteProvider.MobilePhoneSample')}
            value={vm.mobilePhone}
            error={vm.errors.mobilePhone}
            onChange={(mobilePhone) => {
              const errors = { ...vm.errors }
              delete errors.mobilePhone
              onChange({ ...vm, mobilePhone, errors })
            }}
            country={geo?.country_code?.toLowerCase() || 'us'}
          />
        </div>
        <div className="ui-col-4">
          <CurrencyInputField
            amount={vm.hourlyRate}
            digits={2}
            error={vm.errors.hourlyRate}
            label={t('Settings.InviteProvider.HourlyRate')}
            placeholder={t('General.Currency.Value', { value: 75 })}
            onChange={(hourlyRate) => {
              const errors = { ...vm.errors }
              delete errors.hourlyRate
              onChange({ ...vm, hourlyRate: hourlyRate?.toString(), errors })
            }}
          />
        </div>
      </div>

      <SelectField
        items={disciplines}
        onSelectedChange={(x) => {
          setDiscipline(x)

          const errors = { ...vm.errors }
          delete errors.discipline
          onChange({ ...vm, discipline: x.discipline, errors })
        }}
        placeholder={t('Settings.InviteProvider.PrimaryDisciplineSample')}
        selected={discipline}
        labelField="label"
        valueField="discipline"
        error={vm.errors.discipline}
        label={t('Settings.InviteProvider.PrimaryDiscipline')}
      />

      <div>
        <button type="submit" disabled={saving} className="ui-btn ui-btn-primary ui-btn-solid" style={{ width: '100%' }}>
          {t('Settings.SendInvite')}
        </button>
      </div>
    </form>
  )
}

const InviteExpert: React.FC<{
  vm: InviteViewModel
  onChange: (vm: InviteViewModel) => void
  onSubmit: (vm: InviteViewModel) => void
}> = ({ vm, onChange, onSubmit }) => {
  const { t } = useTranslation()
  const { country_code: geo } = useGeolocation()
  const [saving] = useState(false)

  const disciplines = useMemo<
    {
      discipline: DisciplineKey
      label: string
    }[]
  >(
    () => [
      { discipline: DisciplineKey.General, label: t(`General.Disciplines.${DisciplineKey.General}`) },
      { discipline: DisciplineKey.Mechanical, label: t(`General.Disciplines.${DisciplineKey.Mechanical}`) },
      { discipline: DisciplineKey.Electrical, label: t(`General.Disciplines.${DisciplineKey.Electrical}`) },
      { discipline: DisciplineKey.SiteCivil, label: t(`General.Disciplines.${DisciplineKey.SiteCivil}`) }
    ],
    [t]
  )

  const [discipline, setDiscipline] = useState<{ discipline: DisciplineKey; label: string } | undefined>(disciplines.find((x) => x.discipline === vm.discipline))

  const expertRoles = useMemo<
    {
      id: ExpertRole
      label: string
    }[]
  >(
    () => [
      { id: ExpertRole.CostEngineer, label: t(`General.ExpertRoles.${ExpertRole.CostEngineer}`) },
      { id: ExpertRole.CostEstimator, label: t(`General.ExpertRoles.${ExpertRole.CostEstimator}`) },
      { id: ExpertRole.QuantitySurveyor, label: t(`General.ExpertRoles.${ExpertRole.QuantitySurveyor}`) }
    ],
    [t]
  )

  const [selectedExpertRoleIdx, setSelectedExpertRoleIdx] = useState<number>(expertRoles.findIndex((x) => x.id === vm.expertRole))

  const expertTypes = useMemo<
    {
      id: ExpertType
      type: string
    }[]
  >(
    () => [
      { id: ExpertType.FTE, type: t(`General.ExpertTypes.FTE`) },
      { id: ExpertType.Contractor, type: t(`General.ExpertTypes.Contractor`) }
    ],
    [t]
  )

  const [expertType, setExpertType] = useState<{ id: ExpertType; type: string } | undefined>(expertTypes.find((x) => x.id === vm.expertType))

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      e.stopPropagation()

      const vmx: InviteViewModel = { ...vm, errors: {} }

      if (!vmx.givenName) vmx.errors.givenName = t('General.Errors.Required')
      if (!vmx.familyName) vmx.errors.familyName = t('General.Errors.Required')
      if (!vmx.email) vmx.errors.email = t('General.Errors.Required')
      else if (!validateEmail(vmx.email)) vmx.errors.email = t('General.Errors.InvalidEmail')
      if (!vmx.mobilePhone) vmx.errors.mobilePhone = t('General.Errors.Required')
      if (!vmx.hourlyRate || parseFloat(vmx.hourlyRate) <= 0) vmx.errors.hourlyRate = t('General.Errors.Required')
      if (selectedExpertRoleIdx < 0) vmx.errors.expertRole = t('General.Errors.Required')
      if (!expertType) vmx.errors.expertType = t('General.Errors.Required')
      if (!discipline) vmx.errors.discipline = t('General.Errors.Required')

      if (Object.keys(vmx.errors).length) {
        onChange(vmx)
        return
      }

      onSubmit(vmx)
    },
    [vm, onChange, onSubmit]
  )

  return (
    <form noValidate onSubmit={submit}>
      <div className={`ui-row${vm.errors.givenName || vm.errors.familyName || vm.errors.email ? ' ui-has-error' : ''}`}>
        <div className="ui-col-12">
          <label>{t('Settings.To')}</label>
        </div>
        <div className="ui-col-6">
          <TextField
            name="givenName"
            type="text"
            value={vm.givenName}
            error={vm.errors.givenName}
            placeholder={t('Settings.GivenNameSample')}
            onChange={(givenName) => {
              const errors = { ...vm.errors }
              delete errors.givenName
              onChange({ ...vm, givenName, errors })
            }}
          />
        </div>

        <div className="ui-col-6">
          <TextField
            name="familyName"
            type="text"
            value={vm.familyName}
            error={vm.errors.familyName}
            placeholder={t('Settings.FamilyNameSample')}
            onChange={(familyName) => {
              const errors = { ...vm.errors }
              delete errors.familyName
              onChange({ ...vm, familyName, errors })
            }}
          />
        </div>

        <div className="ui-col-12">
          <TextField
            name="email"
            type="email"
            value={vm.email}
            error={vm.errors.email}
            placeholder={t('Settings.ToSample')}
            onChange={(email) => {
              const errors = { ...vm.errors }
              delete errors.email
              onChange({ ...vm, email, errors })
            }}
          />
        </div>
      </div>

      <div className="ui-row">
        <div className="ui-col-12">
          <SelectField
            items={expertTypes}
            onSelectedChange={(x) => {
              setExpertType(x)

              const errors = { ...vm.errors }
              delete errors.expertType
              onChange({ ...vm, expertType: x.id, errors })
            }}
            placeholder={t('Settings.InviteProvider.ExportTypeSample')}
            selected={expertType}
            labelField="type"
            valueField="id"
            error={vm.errors.expertType}
            label={t('Settings.InviteProvider.ExportType')}
          />
        </div>
      </div>

      <div className="ui-row">
        <div className="ui-col-8">
          <PhoneInputField
            label={t('Settings.InviteProvider.MobilePhone')}
            placeholder={t('Settings.InviteProvider.MobilePhoneSample')}
            value={vm.mobilePhone}
            error={vm.errors.mobilePhone}
            onChange={(mobilePhone) => {
              const errors = { ...vm.errors }
              delete errors.mobilePhone
              onChange({ ...vm, mobilePhone, errors })
            }}
            country={geo?.country_code?.toLowerCase() || 'us'}
          />
        </div>
        <div className="ui-col-4">
          <CurrencyInputField
            amount={vm.hourlyRate}
            digits={2}
            error={vm.errors.hourlyRate}
            label={t('Settings.InviteProvider.HourlyRate')}
            placeholder={t('General.Currency.Value', { value: 75 })}
            onChange={(hourlyRate) => {
              const errors = { ...vm.errors }
              delete errors.hourlyRate
              onChange({ ...vm, hourlyRate: hourlyRate?.toString(), errors })
            }}
          />
        </div>
      </div>

      <SelectField
        items={disciplines}
        onSelectedChange={(x) => {
          setDiscipline(x)

          const errors = { ...vm.errors }
          delete errors.discipline
          onChange({ ...vm, discipline: x.discipline, errors })
        }}
        placeholder={t('Settings.InviteProvider.PrimaryDisciplineSample')}
        selected={discipline}
        labelField="label"
        valueField="discipline"
        error={vm.errors.discipline}
        label={t('Settings.InviteProvider.PrimaryDiscipline')}
      />

      <CardSelectField
        items={expertRoles.map((x) => x.label)}
        selected={selectedExpertRoleIdx >= 0 ? [selectedExpertRoleIdx] : []}
        onClick={(idx) => {
          setSelectedExpertRoleIdx(idx)

          const errors = { ...vm.errors }
          delete errors.expertRole
          onChange({ ...vm, expertRole: expertRoles[idx].id, errors })
        }}
        label={t('Settings.InviteProvider.ExpertRole')}
        error={vm.errors.expertRole}
      />
      <div>
        <button type="submit" disabled={saving} className="ui-btn ui-btn-primary ui-btn-solid" style={{ width: '100%' }}>
          {t('Settings.SendInvite')}
        </button>
      </div>
    </form>
  )
}

type InviteModalProps = Omit<ModalProps, 'onClose'> & {
  account: Account
  onSend: (invitation: CreateInvitation) => void
  onClose: (sent?: boolean) => void
}

export const InviteProviderModal: React.FC<InviteModalProps> = ({ account, onSend, onClose, ...rest }) => {
  const { t } = useTranslation()
  const rights = useRights()

  const [vm, setVm] = useState<InviteViewModel>({
    role: rights?.canInviteAccountExpertOnly ? ResourceUserRole.Contributor : undefined,
    errors: {}
  })

  const roles = useMemo(
    () => [
      {
        role: ResourceUserRole.Owner,
        label: t(`General.ProviderRoles.${ResourceUserRole.Owner}`)
      },
      {
        role: ResourceUserRole.Administrator,
        label: t(`General.ProviderRoles.${ResourceUserRole.Administrator}`)
      },
      {
        role: ResourceUserRole.Contributor,
        label: t(`General.ProviderRoles.${ResourceUserRole.Contributor}`)
      },
      {
        role: ResourceUserRole.Guest,
        label: t(`General.ProviderRoles.${ResourceUserRole.Guest}`)
      }
    ],
    [t]
  )

  const [role, setRole] = useState<{ role: ResourceUserRole; label: string } | undefined>()

  const send = useCallback(
    (vm: InviteViewModel) => {
      const i: CreateInvitation = {
        givenName: vm.givenName!,
        familyName: vm.familyName!,
        emailAddress: vm.email!,
        providerRole: vm.role!,
        metadata: {
          mobilePhone: vm.mobilePhone!,
          expertType: vm.expertType!,
          discipline: vm.discipline!,
          hourlyRate: vm.hourlyRate!
        }
      }

      if (vm.role === ResourceUserRole.Contributor) {
        i.metadata!.expertRole = vm.expertRole!
      }

      onSend(i)
    },
    [onSend, onClose, account, t, role]
  )

  return (
    <Modal
      header={<h3>{vm.role === ResourceUserRole.Contributor ? t('Settings.InviteProvider.ExpertTitle') : t('Settings.InviteProvider.Title')}</h3>}
      onClose={() => onClose(false)}
      closeOnClickOutside={false}
      {...rest}
    >
      {!rights?.canInviteAccountExpertOnly && (
        <SelectField
          items={roles}
          onSelectedChange={(x) => {
            setRole(x)
            setVm((vm) => ({ ...vm, role: x.role, errors: {} }))
          }}
          placeholder={t('Settings.InviteProvider.ProviderRoleSample')}
          selected={role}
          labelField="label"
          valueField="role"
          error={vm.errors.role}
          label={t('Settings.InviteProvider.ProviderRole')}
        />
      )}

      {(() => {
        if (vm.role) {
          if (vm.role === ResourceUserRole.Contributor) return <InviteExpert vm={vm} onChange={(vm) => setVm(vm)} onSubmit={(vm) => send(vm)} />

          return <InviteProvider vm={vm} onChange={(vm) => setVm(vm)} onSubmit={(vm) => send(vm)} />
        }

        return (
          <div>
            <button type="submit" disabled className="ui-btn ui-btn-primary ui-btn-solid" style={{ width: '100%' }}>
              {t('Settings.SendInvite')}
            </button>
          </div>
        )
      })()}
    </Modal>
  )
}
