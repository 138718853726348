import { useCallback, useMemo } from 'react'

export enum Feature {
  SmartDocs = 'SmartDocs',
  BOE = 'BOE',
  MultiProvider = 'MulPro'
}

export interface FeaturesHook {
  features: Feature[]
  hasFeature: (name: Feature) => boolean
}
export const useFeatures = (): FeaturesHook => {
  const features = useMemo<Feature[]>(() => (process.env.REACT_APP_FEATURES || '').split(',') as Feature[], [])
  const hasFeature = useCallback((feature: Feature) => features.includes(feature), [features])
  return { features, hasFeature }
}
