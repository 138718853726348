import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EstimateLaborEx, PhaseEx } from 'services/src/state'
import { Grid, GridColumn, GridContentRenderProps } from 'components/src/grid'
import { ProjectStatus } from 'services/src/dto/projectShare'
import { User } from 'services/src/dto/account'

interface RowDetails {
  user: User
  phase: PhaseEx
  discipline?: string
  labor?: EstimateLaborEx
  seq?: number
}

export const EstimateReview: React.FC<{
  phase: PhaseEx
  onComplete: (userId: string) => void
}> = ({ phase, onComplete }) => {
  const { t } = useTranslation()

  const rows = useMemo<RowDetails[]>(() => {
    const rows: RowDetails[] = []

    const users: {
      user: User
      labor: { discipline: string; labor: EstimateLaborEx[] }[]
    }[] = []

    phase.estimates
      ?.filter((est) => est.labor.length > 0)
      .forEach((est) => {
        est.labor
          .filter((l) => !!l.user)
          .forEach((l) => {
            const u = users.find((u) => u.user.id === l.user?.id)
            if (!u) {
              users.push({
                user: l.user!,
                labor: [{ discipline: est.discipline, labor: [l] }]
              })
            } else {
              const lx = u.labor.find((lx) => lx.discipline === est.discipline)
              if (!lx) {
                u.labor.push({ discipline: est.discipline, labor: [l] })
              } else {
                lx.labor.push(l)
              }
            }
          })

        users.sort((a, b) => `${a.user.givenName} ${a.user.familyName}`.toLowerCase().localeCompare(`${b.user.givenName} ${b.user.familyName}`.toLowerCase()))
      })

    users.forEach((u) => {
      if (u.labor.length === 1) {
        u.labor[0].labor.forEach((l) => {
          rows.push({
            user: u.user,
            phase,
            discipline: u.labor[0].discipline,
            labor: l
          })
        })
        return
      }

      u.labor.forEach((l, seq) => {
        l.labor.forEach((lx) => {
          rows.push({
            user: u.user,
            phase,
            discipline: l.discipline,
            labor: lx,
            seq
          })
        })
      })
    })

    return rows
  }, [phase])

  const skillsList = useCallback((labor: EstimateLaborEx) => {
    if (!labor.skills?.length) return null

    if (labor.skills.length === 1) return <span className="ui-text-muted">&nbsp;({labor.skills[0]})</span>
    if (labor.skills.length === 2)
      return (
        <span className="ui-text-muted">
          &nbsp;({labor.skills[0]} and {labor.skills[1]})
        </span>
      )
    if (labor.skills.length > 2) {
      const s = [...labor.skills]
      const last = s[labor.skills.length - 1]
      s.splice(labor.skills.length - 1, 1)
      return (
        <span className="ui-text-muted">
          &nbsp;({s.join(', ')}, and {last})
        </span>
      )
    }
    return null
  }, [])

  if (!rows.length)
    return (
      <div className="ui-text-center ui-text-muted" style={{ marginTop: 80 }}>
        {t('General.NothingToReview')}
      </div>
    )

  return (
    <div style={{ marginTop: 20 }}>
      <Grid rowData={rows}>
        <GridColumn
          field="contributor"
          name={t('General.ProviderProjectRoles.Contributor')}
          width={200}
          minWidth={150}
          render={({ data: { user, seq } }: GridContentRenderProps<RowDetails>) => {
            if ((seq || 0) > 0) return null

            return (
              <div style={{ marginLeft: 5 }}>
                {user!.givenName} {user!.familyName}
              </div>
            )
          }}
        />

        <GridColumn
          field="discipline"
          name={t('EditProject.Fees.DisciplineOrRole')}
          flex={1}
          render={({ data: { discipline, labor } }: GridContentRenderProps<RowDetails>) => {
            if (!discipline) return null

            return (
              <div style={{ marginLeft: 5 }}>
                {t(`General.Disciplines.${discipline}`)}
                {skillsList(labor!) || ''}
              </div>
            )
          }}
        />

        <GridColumn
          field="status"
          name={t('Projects.Status')}
          width={130}
          minWidth={130}
          render={({ data: { labor } }: GridContentRenderProps<RowDetails>) => {
            if (labor) {
              if (labor.statusHistory?.length && labor.statusHistory[0].metadata?.providerReview) return <div className="ui-badge ui-badge-info">{t('General.ReadyForReview')}</div>

              return <div className="ui-badge ui-badge-success">{t(`Projects.${labor.status}`)}</div>
            }

            return <div className="ui-badge ui-badge-secondary">{t('General.Pending')}</div>
          }}
        />

        <GridColumn
          field="action"
          name={t('EditProject.Action')}
          width={130}
          minWidth={130}
          render={({ data: { user, labor, seq } }: GridContentRenderProps<RowDetails>) => {
            if (!labor || [ProjectStatus.Complete, ProjectStatus.Canceled].includes(labor.status)) return null
            if ((seq || 0) > 0) return null

            return (
              <div>
                <button
                  type="button"
                  tabIndex={-1}
                  className={`ui-btn ui-btn-solid ui-btn-xs ui-btn-solid ${labor.statusHistory?.length && labor.statusHistory[0].metadata?.providerReview ? 'ui-btn-success' : 'ui-btn-danger'}`}
                  style={{ width: '100%', height: 22 }}
                  onClick={() => onComplete(user.id)}
                >
                  {t(labor.statusHistory?.length && labor.statusHistory[0].metadata?.providerReview ? 'EditProject.MarkAsComplete' : 'EditProject.ForceComplete')}
                </button>
              </div>
            )
          }}
        />
      </Grid>
    </div>
  )
}
