import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons'

import { ProEstItem, ProEstEstimate, ProEstEstimateDetails } from 'services/src/dto/proEst'

import { AgGridReact } from 'ag-grid-react'
import { GridApi, ColDef, ColGroupDef, ExcelExportParams, ExcelStyle, SortChangedEvent, SortModelItem, RowNode, FilterChangedEvent, GridReadyEvent } from 'ag-grid-community'
import 'ag-grid-enterprise'

const NoItemsOverlay = () => {
  const { t } = useTranslation()
  return (
    <div className="no-rows-overlay">
      <h3>{t('ProEst.NoItems')}</h3>
    </div>
  )
}

export const ProEstEstimateGridHeader: React.FC<{
  canClear?: boolean
  onClear: () => void
  search: string
  onSearchChange: (search: string) => void
}> = ({ canClear, onClear, search, onSearchChange }) => {
  const { t } = useTranslation()

  return (
    <div className="project-index-status-filter">
      <div style={{ marginRight: 10 }}>
        <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <input
            type="text"
            className="ui-input-xs"
            style={{ maxWidth: 200, minWidth: 100, width: 150 }}
            value={search}
            onChange={(e) => onSearchChange(e.currentTarget.value)}
            placeholder={t('Projects.Search')}
          />
        </form>
      </div>

      <div className="ui-flex ui-flex-nowrap" style={{ marginLeft: 'auto' }}>
        <button type="button" className={`ui-btn ui-btn-xs${canClear ? ' ui-btn-pressed ui-btn-info' : ''}`} onClick={() => onClear()}>
          <FontAwesomeIcon icon={faTimesCircle} />
          <span style={{ marginLeft: 5 }}>{t('General.ClearFilter')}</span>
        </button>
      </div>
    </div>
  )
}

export const ProEstEstimateGrid: React.FC<{
  // eslint-disable-next-line react/no-unused-prop-types
  estimate: ProEstEstimate
  details: ProEstEstimateDetails
}> = ({ details }) => {
  const { t } = useTranslation()

  const [sort, setSort] = useState(() => {
    const s = localStorage.getItem('proEst-sort')
    return s ? JSON.parse(s) : [{ colId: 'code', sort: 'asc' }]
  })

  const sortChange = useCallback(
    ({ columnApi }: SortChangedEvent) => {
      const s = columnApi.getColumnState()
      const sm = s.filter((x) => !!x.colId && !!x.sort).map<SortModelItem>((x) => ({ colId: x.colId!, sort: x.sort! }))

      if (sm.length <= 0) return

      setSort(sm)
      localStorage.setItem('proEst-sort', JSON.stringify(sm))
    },
    [setSort]
  )

  const [filter, setFilter] = useState(() => {
    const f = localStorage.getItem('proEst-filter')
    return f ? JSON.parse(f) : undefined
  })

  const filterChange = useCallback(
    ({ api }: FilterChangedEvent) => {
      const fm = api.getFilterModel()
      if (!fm || !Object.keys(fm).length) {
        localStorage.removeItem('proEst-filter')
        setFilter(undefined)
        return
      }

      setFilter(fm)
      localStorage.setItem('proEst-filter', JSON.stringify(fm))
    },
    [setFilter]
  )

  const [search, setSearch] = useState<string>(localStorage.getItem('proEst-search') || '')
  const searchTimerRef = useRef<any>()
  const searchPass = useCallback(
    ({ data }: RowNode) => {
      if (!search) return true

      if (data.code?.startsWith(search)) return true
      if (data.description?.startsWith(search)) return true
      if (data.quantity?.toString().startsWith(search)) return true
      if (data.unit?.startsWith(search)) return true
      if (data.equipment.total?.toString().startsWith(search)) return true
      if (data.material.total?.toString().startsWith(search)) return true
      if (data.labor.total?.toString().startsWith(search)) return true
      if (data.other.total?.toString().startsWith(search)) return true
      return data.subcontractor.total?.toString().startsWith(search)
    },
    [search]
  )

  const [gridApi, setGridApi] = useState<GridApi>()
  const gridReady = useCallback(
    ({ api, columnApi }: GridReadyEvent) => {
      setGridApi(api)
      api.setFilterModel(filter)
      columnApi.applyColumnState({
        state: [...sort]
      })
    },
    [setGridApi, filter, sort]
  )

  const rows = useMemo<ProEstItem[]>(() => {
    const rows = [...details.items]
    rows.sort((a, b) => a.code!.localeCompare(b.code!))
    return rows
  }, [details])

  const columnDefs = useMemo<(ColDef | ColGroupDef)[]>(
    () =>
      [
        {
          field: 'id',
          headerName: t('ProEst.Id'),
          hide: true
        },
        {
          field: 'division',
          headerName: t('ProEst.CodeA'),
          cellClass: 'division',
          width: 150,
          hide: true,
          valueGetter: ({ data }) => {
            if (data.divisions.length >= 2) return `${data.divisions[0].code}.${data.divisions[1].code}`
            return data.divisions[0].code
          },
          filter: 'agTextColumnFilter',
          filterParams: {
            filterOptions: ['contains', 'startsWith', 'endsWith'],
            defaultOption: 'contains',
            suppressAndOrCondition: true
          }
        },
        {
          field: 'code',
          headerName: t('ProEst.Code'),
          cellClass: 'code',
          width: 150,
          valueGetter: ({ data }) => data?.code || '',
          filter: 'agTextColumnFilter',
          filterParams: {
            filterOptions: ['contains', 'startsWith', 'endsWith'],
            defaultOption: 'contains',
            suppressAndOrCondition: true
          }
        },
        {
          field: 'description',
          headerName: t('ProEst.Description'),
          minWidth: 150,
          width: 200,
          flex: 1,
          filter: 'agTextColumnFilter',
          filterParams: {
            filterOptions: ['contains', 'startsWith', 'endsWith'],
            defaultOption: 'contains',
            suppressAndOrCondition: true
          }
        },
        {
          field: 'quantity',
          cellClass: 'quantity ag-right-aligned-cell',
          type: 'rightAligned',
          headerName: t('ProEst.Quantity'),
          width: 120,
          valueGetter: ({ data }) => data?.quantity || 0,
          cellRenderer: ({ data }: { data?: ProEstItem }) => (data?.quantity ? data.quantity.toFixed(2) : ''),
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
            defaultOption: 'equals',
            suppressAndOrCondition: true,
            allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
            numberParser: (text?: string) => (!text ? null : parseFloat(text.replace(',', '.')))
          }
        },
        {
          field: 'unit',
          cellClass: 'unit',
          headerName: t('ProEst.Unit'),
          width: 100,
          valueGetter: ({ data }) => data?.unit || '',
          filter: 'agTextColumnFilter',
          filterParams: {
            filterOptions: ['contains', 'startsWith', 'endsWith'],
            defaultOption: 'contains',
            suppressAndOrCondition: true
          }
        },
        {
          field: 'material.cost',
          cellClass: ['ag-right-aligned-cell'],
          type: 'rightAligned',
          headerName: t('ProEst.MaterialCost'),
          hide: false,
          width: 150,
          valueGetter: ({ data }) => data?.material?.cost || 0,
          cellRenderer: ({ data }: { data?: ProEstItem }) => (data?.material?.cost ? data?.material?.cost.toFixed(2) : ''),
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
            defaultOption: 'equals',
            suppressAndOrCondition: true,
            allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
            numberParser: (text?: string) => (!text ? null : parseFloat(text.replace(',', '.')))
          }
        },
        {
          field: 'material.qty',
          cellClass: ['ag-right-aligned-cell'],
          type: 'rightAligned',
          headerName: t('ProEst.MaterialQty'),
          hide: true,
          width: 150,
          valueGetter: ({ data }) => data?.material?.quantity || 0,
          cellRenderer: ({ data }: { data?: ProEstItem }) => (data?.material?.quantity ? data?.material?.quantity.toFixed(2) : ''),
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
            defaultOption: 'equals',
            suppressAndOrCondition: true,
            allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
            numberParser: (text?: string) => (!text ? null : parseFloat(text.replace(',', '.')))
          }
        },
        {
          field: 'material',
          cellClass: ['material', 'ag-right-aligned-cell'],
          type: 'rightAligned',
          headerName: t('ProEst.MaterialTotal'),
          hide: true,
          width: 150,
          valueGetter: ({ data }) => data?.material?.total || 0,
          cellRenderer: ({ data }: { data?: ProEstItem }) => (data?.material?.total ? t('General.Currency.Value', { value: data.material?.total }) : ''),
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
            defaultOption: 'equals',
            suppressAndOrCondition: true,
            allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
            numberParser: (text?: string) => (!text ? null : parseFloat(text.replace(',', '.')))
          }
        },
        {
          field: 'labor.cost',
          cellClass: ['ag-right-aligned-cell'],
          type: 'rightAligned',
          headerName: t('ProEst.LaborCost'),
          hide: false,
          width: 150,
          valueGetter: ({ data }) => data?.labor?.cost || 0,
          cellRenderer: ({ data }: { data?: ProEstItem }) => (data?.labor?.cost ? data?.labor?.cost.toFixed(2) : ''),
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
            defaultOption: 'equals',
            suppressAndOrCondition: true,
            allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
            numberParser: (text?: string) => (!text ? null : parseFloat(text.replace(',', '.')))
          }
        },
        {
          field: 'labor.hours',
          cellClass: ['ag-right-aligned-cell'],
          type: 'rightAligned',
          headerName: t('ProEst.LaborHours'),
          hide: true,
          width: 150,
          valueGetter: ({ data }) => data?.labor?.hours || 0,
          cellRenderer: ({ data }: { data?: ProEstItem }) => (data?.labor?.hours ? data?.labor?.hours.toFixed(2) : ''),
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
            defaultOption: 'equals',
            suppressAndOrCondition: true,
            allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
            numberParser: (text?: string) => (!text ? null : parseFloat(text.replace(',', '.')))
          }
        },
        {
          field: 'labor',
          cellClass: ['labor', 'ag-right-aligned-cell'],
          type: 'rightAligned',
          headerName: t('ProEst.LaborTotal'),
          hide: true,
          width: 150,
          valueGetter: ({ data }) => data?.labor?.total || 0,
          cellRenderer: ({ data }: { data?: ProEstItem }) => (data?.labor?.total ? t('General.Currency.Value', { value: data.labor?.total }) : ''),
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
            defaultOption: 'equals',
            suppressAndOrCondition: true,
            allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
            numberParser: (text?: string) => (!text ? null : parseFloat(text.replace(',', '.')))
          }
        },
        {
          field: 'subcontractor',
          cellClass: ['subcontractor', 'ag-right-aligned-cell'],
          type: 'rightAligned',
          headerName: t('ProEst.SubcontractorCost'),
          width: 150,
          valueGetter: ({ data }) => data?.subcontractor?.cost || 0,
          cellRenderer: ({ data }: { data?: ProEstItem }) => (data?.subcontractor?.total ? t('General.Currency.Value', { value: data.subcontractor?.cost }) : ''),
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
            defaultOption: 'equals',
            suppressAndOrCondition: true,
            allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
            numberParser: (text?: string) => (!text ? null : parseFloat(text.replace(',', '.')))
          }
        },
        {
          field: 'subcontractor',
          cellClass: ['subcontractor', 'ag-right-aligned-cell'],
          type: 'rightAligned',
          headerName: t('ProEst.SubcontractorTotal'),
          hide: true,
          width: 150,
          valueGetter: ({ data }) => data?.subcontractor?.total || 0,
          cellRenderer: ({ data }: { data?: ProEstItem }) => (data?.subcontractor?.total ? t('General.Currency.Value', { value: data.subcontractor?.total }) : ''),
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
            defaultOption: 'equals',
            suppressAndOrCondition: true,
            allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
            numberParser: (text?: string) => (!text ? null : parseFloat(text.replace(',', '.')))
          }
        },
        {
          field: 'equipment.cost',
          cellClass: ['ag-right-aligned-cell'],
          type: 'rightAligned',
          headerName: t('ProEst.EquipmentCost'),
          hide: false,
          width: 150,
          valueGetter: ({ data }) => data?.equipment?.cost || 0,
          cellRenderer: ({ data }: { data?: ProEstItem }) => (data?.equipment?.cost ? data?.equipment?.cost.toFixed(2) : ''),
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
            defaultOption: 'equals',
            suppressAndOrCondition: true,
            allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
            numberParser: (text?: string) => (!text ? null : parseFloat(text.replace(',', '.')))
          }
        },
        {
          field: 'equipment.hours',
          cellClass: ['ag-right-aligned-cell'],
          type: 'rightAligned',
          headerName: t('ProEst.EquipmentHours'),
          hide: true,
          width: 150,
          valueGetter: ({ data }) => data?.equipment?.hours || 0,
          cellRenderer: ({ data }: { data?: ProEstItem }) => (data?.equipment?.hours ? data?.equipment?.hours.toFixed(2) : ''),
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
            defaultOption: 'equals',
            suppressAndOrCondition: true,
            allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
            numberParser: (text?: string) => (!text ? null : parseFloat(text.replace(',', '.')))
          }
        },
        {
          field: 'equipment',
          cellClass: ['equipment', 'ag-right-aligned-cell'],
          type: 'rightAligned',
          headerName: t('ProEst.EquipmentTotal'),
          hide: true,
          width: 150,
          valueGetter: ({ data }) => data?.equipment?.total || 0,
          cellRenderer: ({ data }: { data?: ProEstItem }) => (data?.equipment?.total ? t('General.Currency.Value', { value: data.equipment?.total }) : ''),
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
            defaultOption: 'equals',
            suppressAndOrCondition: true,
            allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
            numberParser: (text?: string) => (!text ? null : parseFloat(text.replace(',', '.')))
          }
        },
        {
          field: 'other',
          cellClass: ['other', 'ag-right-aligned-cell'],
          type: 'rightAligned',
          headerName: t('ProEst.OtherTotal'),
          width: 150,
          valueGetter: ({ data }) => data?.other?.total || 0,
          cellRenderer: ({ data }: { data?: ProEstItem }) => (data?.other?.total ? t('General.Currency.Value', { value: data.other?.total }) : ''),
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
            defaultOption: 'equals',
            suppressAndOrCondition: true,
            allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
            numberParser: (text?: string) => (!text ? null : parseFloat(text.replace(',', '.')))
          }
        },

        {
          field: 'total',
          cellClass: ['total', 'ag-right-aligned-cell'],
          type: 'rightAligned',
          headerName: t('ProEst.Total'),
          width: 150,
          valueGetter: ({ data }) => (data?.equipment?.total || 0) + (data?.labor?.total || 0) + (data?.material?.total || 0) + (data?.other?.total || 0) + (data?.subcontractor?.total || 0),
          cellRenderer: ({ value }: { value?: number }) => (value ? t('General.Currency.Value', { value }) : ''),
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
            defaultOption: 'equals',
            suppressAndOrCondition: true,
            allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
            numberParser: (text?: string) => (!text ? null : parseFloat(text.replace(',', '.')))
          }
        }
      ] as ColDef[],
    [t]
  )

  const excelExport = useMemo<ExcelExportParams>(
    () => ({
      // allColumns: true,
      columnKeys: [
        'code', // A
        'description', // B
        'quantity', // C
        'unit', // D
        'equipment.cost', // E
        'equipment.hours', // F
        'equipment', // G
        'material.cost', // H
        'material.qty', // I
        'material', // J
        'labor.cost', // K
        'labor.hours', // L
        'labor', // M
        'other', // N
        'subcontractor', // O
        'total' // P
      ],
      sheetName: t('ProEst.ExportName'),
      fileName: t('ProEst.ExportFile'),
      processCellCallback: ({ node, value, column, accumulatedRowIndex }) => {
        if (!node?.data) return null
        switch (column.getColId()) {
          case 'equipment':
            return `=E${accumulatedRowIndex}*F${accumulatedRowIndex}`
          case 'material':
            return `=H${accumulatedRowIndex}*I${accumulatedRowIndex}`
          case 'labor':
            return `=K${accumulatedRowIndex}*L${accumulatedRowIndex}`
          case 'total':
            return `=G${accumulatedRowIndex}+J${accumulatedRowIndex}+M${accumulatedRowIndex}+N${accumulatedRowIndex}+O${accumulatedRowIndex}`
          default:
            return value
        }
      }
    }),
    [t]
  )

  const excelStyles = useMemo<ExcelStyle[]>(
    () => [
      {
        id: 'header',
        alignment: {
          vertical: 'Center'
        },
        font: {
          bold: true
        }
      },
      {
        id: 'twoDigits',
        numberFormat: {
          format: '#,##0.00'
        }
      },
      {
        id: 'code',
        dataType: 'String'
      },
      {
        id: 'currency',
        numberFormat: {
          format: '$#,##0.00_);[Red]($#,##0.00)'
        }
      },
      {
        id: 'equipment',
        dataType: 'Formula',
        numberFormat: {
          format: '$#,##0.00_);[Red]($#,##0.00)'
        }
      },
      {
        id: 'material',
        dataType: 'Formula',
        numberFormat: {
          format: '$#,##0.00_);[Red]($#,##0.00)'
        }
      },
      {
        id: 'labor',
        dataType: 'Formula',
        numberFormat: {
          format: '$#,##0.00_);[Red]($#,##0.00)'
        }
      },
      {
        id: 'total',
        dataType: 'Formula',
        numberFormat: {
          format: '$#,##0.00_);[Red]($#,##0.00)'
        },
        font: {
          bold: true
        }
      }
    ],
    []
  )

  return (
    <div className="ui-flex ui-flex-column" style={{ height: '100%' }}>
      <div className="ui-flex">
        <div>{details.description}</div>
        <div className="ui-flex" style={{ marginLeft: 'auto', alignItems: 'center' }}>
          <button
            type="button"
            className="ui-btn ui-btn-success ui-btn-solid ui-btn-xs"
            tabIndex={-1}
            style={{ marginLeft: 10, minWidth: 120 }}
            onClick={() => {
              if (!gridApi) return
              gridApi.exportDataAsExcel()
            }}
          >
            {t('ProEst.ExportToExcel')}
          </button>
        </div>
      </div>

      <div className="ui-flex ui-text-sm" style={{ marginTop: 5, marginBottom: 20 }}>
        <div className="ui-flex ui-info">
          {(details.costs?.material?.total || 0) > 0 && (
            <>
              <div>
                {t('ProEst.MaterialTotal')}: {t('General.Currency.Value', { value: details.costs!.material!.total })}
              </div>
              <div style={{ margin: '0 10px' }}>|</div>
            </>
          )}
          {(details.costs?.labor?.total || 0) > 0 && (
            <>
              <div>
                {t('ProEst.LaborTotal')}: {t('General.Currency.Value', { value: details.costs!.labor!.total })}
              </div>
              <div style={{ margin: '0 10px' }}>|</div>
            </>
          )}
          {(details.costs?.subcontractor?.total || 0) > 0 && (
            <>
              <div>
                {t('ProEst.SubcontractorTotal')}: {t('General.Currency.Value', { value: details.costs!.subcontractor!.total })}
              </div>
              <div style={{ margin: '0 10px' }}>|</div>
            </>
          )}
          {(details.costs?.equipment?.total || 0) > 0 && (
            <>
              <div>
                {t('ProEst.EquipmentTotal')}: {t('General.Currency.Value', { value: details.costs!.equipment!.total })}
              </div>
              <div style={{ margin: '0 10px' }}>|</div>
            </>
          )}
          <div className="ui-text-bold">
            {t('ProEst.Total')}: {t('General.Currency.Value', { value: details.costs?.total || 0 })}
          </div>
        </div>
      </div>

      <ProEstEstimateGridHeader
        canClear={!!filter || !!search}
        onClear={() => {
          localStorage.removeItem('proEst-filter')
          setFilter(undefined)
          localStorage.removeItem('proEst-search')
          setSearch('')
          setTimeout(() => {
            gridApi?.setFilterModel(undefined)
            gridApi?.onFilterChanged()
          }, 100)
        }}
        search={search}
        onSearchChange={(search) => {
          setSearch(search)
          localStorage.setItem('proEst-search', search)

          // Debounce the change
          clearTimeout(searchTimerRef.current)
          searchTimerRef.current = setTimeout(() => {
            gridApi?.onFilterChanged()
          }, 500)
        }}
      />

      <div className="ag-theme-cc ag-theme-cc-xs" style={{ width: '100%', flex: 1, marginTop: 10 }}>
        <AgGridReact
          defaultColDef={{
            width: 100,
            minWidth: 50,
            editable: false,
            resizable: true,
            sortable: true,
            sortingOrder: ['asc', 'desc']
          }}
          frameworkComponents={{
            noItemsOverlay: NoItemsOverlay
          }}
          isExternalFilterPresent={() => !!search}
          doesExternalFilterPass={searchPass}
          noRowsOverlayComponent="noItemsOverlay"
          columnDefs={columnDefs}
          rowSelection="single"
          suppressCellSelection
          suppressRowHoverHighlight
          rowData={rows}
          onRowClicked={() => {}}
          onSortChanged={sortChange}
          onFilterChanged={filterChange}
          onGridReady={gridReady}
          defaultExcelExportParams={excelExport}
          excelStyles={excelStyles}
        />
      </div>
    </div>
  )
}
