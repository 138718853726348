import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Comment } from 'services/src/dto/comment'

import './style.scss'
import { CommentItem } from './CommentItem'
import { Loading } from '../loading'

export interface CommentPanelItemsProps {
  comments?: Comment[]
  onLoadMore?: () => void
}

const CommentPanelItems: React.FC<CommentPanelItemsProps> = ({ comments, onLoadMore }) => {
  const { t } = useTranslation()
  const innerRef = useRef<HTMLDivElement>(null)

  const [inChange, setInChange] = useState(false)
  const [inScrollToLatest, setInScrollToLatest] = useState(false)

  const scrollToLatest = useCallback(() => {
    setInScrollToLatest(true)
    setTimeout(() => {
      if (!innerRef.current) return

      const p = innerRef.current.parentElement
      if (!p) return

      const r1 = innerRef.current.getBoundingClientRect()
      const r2 = p.getBoundingClientRect()

      let scrollHeight = 0
      if (r1.height > r2.height) scrollHeight = r1.height - r2.height + 30
      p.scrollTo(0, scrollHeight)
      setInScrollToLatest(false)
    }, 10)
  }, [innerRef.current, setInScrollToLatest])

  const startChange = useCallback(() => setInChange(true), [setInChange])
  const endChange = useCallback(() => {
    setInChange(false)
    scrollToLatest()
  }, [scrollToLatest, setInChange])

  useEffect(() => {
    window.addEventListener('newComment', scrollToLatest)
    window.addEventListener('initLoadComments', scrollToLatest)
    window.addEventListener('commentChannelChangeStart', startChange)
    window.addEventListener('commentChannelChangeDone', endChange)
    return () => {
      window.removeEventListener('newComment', scrollToLatest)
      window.removeEventListener('initLoadComments', scrollToLatest)
      window.removeEventListener('commentChannelChangeStart', startChange)
      window.removeEventListener('commentChannelChangeDone', endChange)
    }
  }, [scrollToLatest])

  return (
    <div
      className="ui-comment-panel-items"
      style={{ visibility: inChange ? 'hidden' : 'visible' }}
      onScroll={(e) => {
        if (!onLoadMore || e.currentTarget.scrollTop >= 100) return
        onLoadMore()
      }}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {inChange || !comments ? (
        <Loading className="ui-loading-no-overlay" size="sm" variant="parent" />
      ) : comments.length <= 0 ? (
        <div className="ui-text-muted ui-text-lg ui-text-center" style={{ marginTop: 100 }}>
          {t('General.FirstComment')}
        </div>
      ) : (
        <div ref={innerRef} style={{ visibility: inScrollToLatest ? 'hidden' : 'visible' }}>
          {comments.map((c) => (
            <CommentItem key={c.id} comment={c} />
          ))}
        </div>
      )}
    </div>
  )
}
export default CommentPanelItems
