import React, { useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons'

import { deleteAccount, getAccountsByAccount } from 'services/src/api'
import { useRights } from 'services/src/state'
import { Account, AccountStatus, AccountType, PRIMARY_PROVIDER_ID } from 'services/src/dto/account'
import { AgGridReact } from 'ag-grid-react'
import { Loading } from '../../loading'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { ConfirmModal } from '../../modal/ConfirmModal'
import moment from 'moment'
import { Link, useHistory, useLocation } from 'react-router-dom'

const NameCell: React.FC<ICellRendererParams> = ({ data }) => {
  const account = data as Account
  return (
    <div className="ui-flex ui-flex-nowrap ui-flex-align-center">
      <div className="ui-picture-circle" style={{ width: 25, height: 25, minWidth: 25, minHeight: 25 }}>
        {account.logoUrl ? <div style={{ backgroundImage: `url('${account.logoUrl}')`, border: 'none' }} /> : <div style={{ lineHeight: '23px' }}>{account.companyName[0].toUpperCase()}</div>}
      </div>
      <div style={{ marginLeft: 10 }}>
        <div>{account.companyName}</div>
      </div>
    </div>
  )
}

type ActionCellProps = ICellRendererParams & {
  onDeleteClick: (account: Account) => void;
}

const ActionCell: React.FC<ActionCellProps> = ({ data, onDeleteClick }) => {
  const account = data as Account
  return (
    <div className="ui-flex ui-flex-nowrap ui-flex-align-center" style={{ height: 40 }}>
      <button className="ui-btn ui-btn-empty ui-btn-danger ui-btn-sm" onClick={() => onDeleteClick(account)}><FontAwesomeIcon icon={faTrashAlt} /></button>
    </div>
  )
}

const TypeCell: React.FC<ICellRendererParams> = ({ data }) => {
  const account = data as Account
  return (
    <div className="ui-flex ui-flex-nowrap ui-flex-align-center" style={{ height: 40, justifyContent: 'center' }}>
      {account.type === AccountType.Client && <div className="ui-badge ui-badge-rouded ui-badge-secondary ui-badge-sm">CLIENT</div>}
      {account.type === AccountType.Provider && account.id === PRIMARY_PROVIDER_ID && <div className="ui-badge ui-badge-rouded ui-badge-danger ui-badge-sm">PRIMARY PROVIDER</div>}
      {account.type === AccountType.Provider && account.id !== PRIMARY_PROVIDER_ID && <div className="ui-badge ui-badge-rouded ui-badge-primary ui-badge-sm">PROVIDER</div>}
      {account.type === AccountType.Expert && <div className="ui-badge ui-badge-rouded ui-badge-info ui-badge-sm">EXPERT</div>}
    </div>
  )
}

const AdminTools = () => {
  const { pathname } = useLocation()

  return (
    <div className="admin-tools">
      <Link to={`${pathname}/new`} className="ui-btn ui-btn-primary">Create Account</Link>
    </div>
  )
}

export const Accounts = () => {
  const rights = useRights()
  const [accounts, setAccounts] = useState<Account[] | undefined>(undefined)

  const [height, setHeight] = useState(window.innerHeight - 140)

  const [delAccount, setDelAccount] = useState<Account | undefined>(undefined)

  const history = useHistory()
  const { pathname } = useLocation()

  useEffect(() => {
    if (!rights) return

    getAccountsByAccount(PRIMARY_PROVIDER_ID, 0, 1000).then(({ accounts }) => {
      setAccounts(accounts)
    })
  }, [rights])

  useEffect(() => {
    const resize = () => {
      setHeight(window.innerHeight - 140)
    }
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  const columnDefs = useMemo(
    () => {
      const columnDefs: ColDef[] = [
        {
          field: 'companyName',
          flex: 1,
          filter: 'agSetColumnFilter',
          cellRenderer: 'nameCell',
          width: 5000,
          valueGetter: ({ data }: any) => data?.companyName
        },
        {
          field: 'type',
          width: 200,
          minWidth: 150,
          filter: 'agSetColumnFilter',
          cellRenderer: 'typeCell',
          valueGetter: ({ data }: { data: Account }) => {
            if (data.type === AccountType.Client) return 'CLIENT'
            if (data.type === AccountType.Provider && data.id === PRIMARY_PROVIDER_ID) return 'PRIMARY PROVIDER'
            if (data.type === AccountType.Provider && data.id !== PRIMARY_PROVIDER_ID) return 'PROVIDER'
            if (data.type === AccountType.Expert) return 'EXPERT'
            return 'UNKNOWN'
          }
        },
        {
          field: 'status',
          width: 200,
          minWidth: 150,
          filter: 'agSetColumnFilter',
          valueGetter: ({ data }: { data: Account }) => {
            if (data.status === AccountStatus.New) {
              return data.type === AccountType.Provider ? 'PENDING APPROVAL' : 'NEW'
            }
            if (data.status === AccountStatus.Ready) return 'ACTIVE'
            if (data.status === AccountStatus.Canceling) return 'CANCELING'
            if (data.status === AccountStatus.Closed) return 'CLOSED'
            return 'UNKNOWN'
          }
        },
        {
          field: 'owner',
          width: 200,
          minWidth: 150,
          filter: 'agSetColumnFilter',
        },
        {
          field: 'createdAt',
          width: 200,
          minWidth: 150,
          filter: 'agSetColumnFilter',
          valueGetter: ({ data }: { data: Account }) => moment(data.createdAt).format('M/D/YYYY h:mm a')
        },
        {
          width: 80,
          minWidth: 80,
          maxWidth: 80,
          sortable: false,
          resizable: false,
          suppressMenu: true,
          cellRenderer: 'actionCell',
          cellRendererParams: { onDeleteClick: (account: Account) => setDelAccount(account) }
        }
      ]
      return columnDefs;
    },
    []
  )

  if (!accounts) return <Loading />

  return (
    <>
      <AdminTools />

      <div
        className="ag-theme-cc admin-table"
        style={{ height }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={accounts}
          defaultColDef={{
            editable: false,
            resizable: true,
            sortable: true,
            suppressMenu: false,
            sortingOrder: ['asc', 'desc'],
            suppressColumnsToolPanel: true,
            filter: 'agMultiColumnFilter',
            filterParams: {
              filterOptions: ['contains', 'startsWith', 'endsWith'],
              defaultOption: 'contains',
              suppressAndOrCondition: true
            },
          }}
          frameworkComponents={{
            nameCell: NameCell,
            actionCell: ActionCell,
            typeCell: TypeCell
          }}
          rowSelection="single"
          suppressCellSelection={true}
          onCellClicked={(c) => {
            if (c.colDef.field === undefined) return;
            history.push(`${pathname}/${c.data.id}`)
          }}
        />
      </div>


      {delAccount && (
        <ConfirmModal
          title={`Delete Account: ${delAccount.companyName}`}
          message={`Sure you want to delete the account ${delAccount.companyName} and all associated resources? This operation cannot be undone.`}
          onYes={() => {
            deleteAccount(delAccount.id)
              .then(() => getAccountsByAccount(PRIMARY_PROVIDER_ID, 0, 1000))
              .then(({ accounts }) => setAccounts(accounts))
              .finally(() => {
                // TODO delete the account
                setDelAccount(undefined)
              })
          }}
          onClose={() => {
            setDelAccount(undefined)
          }}
        />
      )}
    </>

  )
}
