import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Account, ResourceUserRole, User } from 'services/src/dto/account'
import { CreateInvitation, Invitation, InvitationStatus } from 'services/src/dto/invitation'
import { InfoModal } from 'components/src/modal/InfoModal'
import { ConfirmModal } from 'components/src/modal/ConfirmModal'
import { useCurrentAccountWithUser, useCurrentUserAccount, useMe } from 'services/src/state'
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import { createInvitation, deleteAccountUser, deleteInvitation, getAccounts, getClientAccounts, resendInvitation, updateAccountUserRole } from 'services/src/api'
import { TabBar, TabItem } from '../../tabs'
import { InvitationList } from './InvitationList'
import { useAlert } from '../../alerts'
import { useSaving } from '../../saving'
import { InviteModal } from './InviteModal'
import { Loading } from '../../loading'
import { AccountPeerList } from '../../accountPeerList'
import { StringParam, useQueryParam } from 'use-query-params'

export const ProviderClients: React.FC = () => {
  const { t } = useTranslation()
  const [me] = useMe()
  const { url, path } = useRouteMatch()
  const { pathname } = useLocation()
  const [account] = useCurrentUserAccount()
  const [accountWithUsers, refreshUsers] = useCurrentAccountWithUser()
  const { alertSuccess, alertDanger } = useAlert()
  const [, setSaving] = useSaving()
  const [doInvite] = useQueryParam("invite", StringParam)

  const [confirmDeleteUser, setConfirmDeleteUser] = useState<User>()
  const [info, setInfo] = useState<{ title: string; message: string }>()
  const [invite, setInvite] = useState<boolean>(doInvite === 'client')

  const [clientAccounts, setClientAccounts] = useState<Account[] | undefined>(undefined)

  useEffect(() => {
    if (!account) return;

    if (!account?.clientAccountIds?.length) {
      setClientAccounts([]);
      return
    }

    getClientAccounts(account?.id)
      .then(accounts => setClientAccounts(accounts));
  }, [account])

  const handleDeleteUser = useCallback(
    (user: User) => {
      setConfirmDeleteUser(undefined)
      if (!account || !user.id) return

      setSaving(true)
      deleteAccountUser(account.id, user.id)
        .then(() => refreshUsers())
        .then(() =>
          alertSuccess({
            title: t('Settings.DeletedTitle'),
            message: t('Settings.Deleted', { name: `${user.givenName} ${user.familyName}` })
          })
        )
        .catch((err) =>
          alertDanger({
            title: t('Settings.Errors.SaveTitle'),
            message: t('Settings.Errors.Save'),
            error: err
          })
        )
        .finally(() => setTimeout(() => setSaving(false)))
    },
    [account, setSaving, alertSuccess, alertDanger, refreshUsers]
  )

  const readyInvitations = useMemo(() => accountWithUsers?.invitations.filter((i) => !!i.providerClient && i.status === InvitationStatus.Pending), [accountWithUsers])

  const handleSendInvitation = useCallback(
    (invitation: CreateInvitation) => {
      setInvite(false)
      setSaving(true)
      createInvitation(account!.id, invitation)
        .then(() => refreshUsers())
        .then(() => {
          alertSuccess({
            title: t('Settings.InviteCreateTitle'),
            message: t('Settings.InviteCreate', {
              name: `${invitation.givenName} ${invitation.familyName}`,
              email: invitation.emailAddress
            })
          })
        })
        .catch((err) => {
          if (err.message.includes('409')) {
            alertDanger({
              title: t('Settings.Errors.DuplicateInviteTitle'),
              message: t('Settings.Errors.DuplicateInvite', {
                name: `${invitation.givenName} ${invitation.familyName}`,
                email: invitation.emailAddress
              }),
              error: err
            })
            return
          }
          alertDanger({
            title: t('Settings.Errors.InviteCreateTitle'),
            message: t('Settings.Errors.InviteCreate'),
            error: err
          })
        })
        .finally(() => setTimeout(() => setSaving(false)))
    },
    [account, setInvite, setSaving, alertSuccess, alertDanger, refreshUsers]
  )

  const handleDeleteInvitation = useCallback(
    (invitation: Invitation) => {
      if (!accountWithUsers) return

      setSaving(true)
      deleteInvitation(accountWithUsers.id, invitation.id)
        .then(() => refreshUsers())
        .then(() =>
          alertSuccess({
            title: t('Settings.DeletedInvitationTitle'),
            message: t('Settings.DeletedInvitation', {
              name: `${invitation.givenName} ${invitation.familyName}`,
              email: invitation.emailAddress
            })
          })
        )
        .catch((err) =>
          alertDanger({
            title: t('Settings.Errors.DeletedInvitationTitle'),
            message: t('Settings.Errors.DeletedInvitation'),
            error: err
          })
        )
        .finally(() => setTimeout(() => setSaving(false)))
    },
    [accountWithUsers, setSaving, alertSuccess, alertDanger, refreshUsers]
  )

  const handleResendInvitation = useCallback(
    (invitation: Invitation) => {
      if (!accountWithUsers) return

      setSaving(true)
      resendInvitation(accountWithUsers.id, invitation.id)
        .then(() => refreshUsers())
        .then(() =>
          alertSuccess({
            title: t('Settings.InvitationResentTitle'),
            message: t('Settings.InvitationResent', {
              name: `${invitation.givenName} ${invitation.familyName}`,
              email: invitation.emailAddress
            })
          })
        )
        .catch((err) =>
          alertDanger({
            title: t('Settings.Errors.InvitationResentTitle'),
            message: t('Settings.Errors.InvitationResent'),
            error: err
          })
        )
        .finally(() => setTimeout(() => setSaving(false)))
    },
    [accountWithUsers, setSaving, alertSuccess, alertDanger, refreshUsers]
  )

  const tabs = useMemo<TabItem[]>(() => {
    if (!me) return []

    return [
      {
        label: t('General.Clients'),
        selected: pathname.endsWith('clients'),
        to: url
      },
      {
        label: t('General.Invitations'),
        to: `${url}/invitations`,
        selected: pathname.endsWith('invitations')
      }
    ]
  }, [pathname, t])

  if (!clientAccounts) return <Loading />;

  return (
    <>
      <TabBar tabs={tabs} className="settings-tab-bar" onChange={() => {}} />
      <br />

      <Switch>
        <Route path={`${path}/invitations`}>
          {readyInvitations && (
            <InvitationList
              invitations={readyInvitations}
              roles={[]}
              onNew={() => setInvite(true)}
              clientList={true}
              onDelete={handleDeleteInvitation}
              onResend={handleResendInvitation} />
          )}
        </Route>

        <Route path="*">
          <AccountPeerList
            accounts={clientAccounts}
          />
        </Route>
      </Switch>

      {info && (
        <InfoModal
          title={info.title}
          message={info.message}
          ok={t('General.Ok')}
          onClose={() => setInfo(undefined)} />
      )}

      {invite && account && (
        <InviteModal
          account={account}
          providerClient={true}
          onSend={handleSendInvitation}
          onClose={() => setInvite(false)} />
      )}

      {confirmDeleteUser && (
        <ConfirmModal
          title={t('Settings.DeletePeopleTitle')}
          message={t('Settings.DeletePeople', {
            name: `${confirmDeleteUser.givenName} ${confirmDeleteUser.familyName}`
          })}
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={() => handleDeleteUser(confirmDeleteUser)}
          onClose={() => setConfirmDeleteUser(undefined)}
        />
      )}
    </>
  )
}
