import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react'
import { useMe } from 'services/src/state'
import { Point } from './common'

export const ImageView: React.FC<{
  src: string
  view: string
  anchorPt: Point
  onAnchorPtChange: (anchorPt: Point) => void
  zoom: number
  onZoomChange: (zoom: number) => void
  isFit: boolean
  rotate: number
}> = ({ src, view, anchorPt, onAnchorPtChange, zoom, onZoomChange, isFit, rotate }) => {
  const [me] = useMe()

  const imgRef = useRef<HTMLImageElement>(new Image())
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [canvasStyle, setCanvasStyle] = useState<CSSProperties>({ visibility: 'hidden' })

  const handleFit = useCallback(
    (initial?: boolean) => {
      const parentRect = canvasRef.current!.parentElement!.getBoundingClientRect()

      let z
      if (imgRef.current.height > parentRect.height) {
        z = parentRect.height / imgRef.current.height - 0.01
        const w = imgRef.current.width * z
        if (w > parentRect.width) {
          z = parentRect.width / imgRef.current.width - 0.01
        }
      } else if (imgRef.current.width > parentRect.width) {
        z = parentRect.width / imgRef.current.width - 0.01
        const h = imgRef.current.height * z
        if (h > parentRect.height) {
          z = parentRect.height / imgRef.current.height - 0.01
        }
      } else {
        let s = parentRect.height - 80
        z = s / imgRef.current.height

        const w = imgRef.current.width * z
        s = parentRect.width - 80
        if (w > s) {
          z = s / imgRef.current.width
        }
      }
      if (initial && z > 1) z = 1
      onZoomChange(z)

      onAnchorPtChange({
        left: parentRect.width / 2,
        top: parentRect.height / 2
      })

      const fillCanvas = document.createElement('canvas')
      const size = 6
      fillCanvas.width = size
      fillCanvas.height = size
      let ctx = fillCanvas.getContext('2d')!

      const t = me?.theme || localStorage.getItem('theme') || 'light'

      ctx.fillStyle = t === 'dark' ? '#1E1E1E' : '#f7f7f7'
      ctx.fillRect(0, 0, size, size)

      ctx.fillStyle = t === 'dark' ? '#212121' : '#f1f1f1'
      ctx.fillRect(0, 0, size / 2, size / 2)
      ctx.fillRect(size / 2, size / 2, size / 2, size / 2)

      canvasRef.current!.width = imgRef.current.width
      canvasRef.current!.height = imgRef.current.height
      ctx = canvasRef.current!.getContext('2d')!

      ctx.fillStyle = ctx.createPattern(fillCanvas, 'repeat')!
      ctx.fillRect(0, 0, canvasRef.current!.width, canvasRef.current!.height)
      ctx.drawImage(imgRef.current, 0, 0)

      setCanvasStyle((current) => ({ ...current, visibility: 'visible' }))
    },
    [setCanvasStyle, me]
  )

  useEffect(() => {
    const handleWheel = (e: any) => e.preventDefault()

    window.addEventListener('docView.fit', handleFit as any)
    window.document.body.addEventListener('wheel', handleWheel, { passive: false })
    return () => {
      window.removeEventListener('docView.fit', handleFit as any)
      window.document.body.removeEventListener('wheel', handleWheel)
    }
  }, [])

  useEffect(() => {
    if (!imgRef.current || imgRef.current.width <= 0 || imgRef.current.height <= 0) return

    setCanvasStyle((current) => ({
      ...current,
      left: anchorPt.left - (imgRef.current.width * zoom) / 2,
      top: anchorPt.top - (imgRef.current.height * zoom) / 2,
      width: (imgRef.current.width || 0) * zoom,
      height: (imgRef.current.height || 0) * zoom
    }))
  }, [anchorPt, zoom, setCanvasStyle])

  useEffect(() => {
    imgRef.current.onload = () => setTimeout(() => handleFit(true))
    imgRef.current.src = src
  }, [src])

  useEffect(() => {
    setCanvasStyle((current) => ({ ...current, transform: `rotate(${rotate}deg)` }))
  }, [rotate])

  useEffect(() => {
    if (isFit) handleFit()
  }, [isFit, view])

  const [trackStartPt, setTrackStartPt] = useState<Point>()

  return (
    <canvas
      ref={canvasRef}
      style={{ ...canvasStyle, touchAction: 'none', msTouchAction: 'none' }}
      onBlur={() => setTrackStartPt(undefined)}
      onMouseOut={() => setTrackStartPt(undefined)}
      onMouseDown={(e) => setTrackStartPt({ left: e.clientX, top: e.clientY })}
      onMouseMove={(e) => {
        if (!trackStartPt) return
        const pt = {
          left: anchorPt.left + (e.clientX - trackStartPt.left),
          top: anchorPt.top + (e.clientY - trackStartPt.top)
        }
        onAnchorPtChange(pt)
        setTrackStartPt({ left: e.clientX, top: e.clientY })
      }}
      onMouseUp={() => setTrackStartPt(undefined)}
    />
  )
}
