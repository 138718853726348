import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Comment } from 'services/src/dto/comment'
import { useRights, UserType } from 'services/src/state'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'

import draftToHtml from 'draftjs-to-html'

import './style.scss'

export const CommentItem: React.FC<{
  comment: Comment
}> = ({ comment }) => {
  const rights = useRights()

  const { ready, url } = useMemo<{ ready?: JSX.Element | undefined; url?: string }>(() => {
    if (!comment.text) return {}

    let { url } = comment
    try {
      const raw = JSON.parse(comment.text)
      const html = draftToHtml(raw)

      if (url) {
        if (rights?.userType !== UserType.client) {
          const parts = url.split('/')
          if (parts[2] !== 'projects') {
            parts.splice(2, 1)
            url = parts.join('/')
          }
        }
      }

      return {
        // eslint-disable-next-line react/no-danger
        ready: <div dangerouslySetInnerHTML={{ __html: html }} />,
        url
      }
    } catch {
      return { ready: undefined, url }
    }
  }, [comment, rights])

  const formatDate = (comment: Comment) => {
    const m = moment(comment.createdAt)
    if (m.isSame(moment(), 'date')) return m.format('LT')
    return `${m.format('l')} ${m.format('LT')}`
  }

  if (!ready) return null

  return (
    <div className={`ui-comment-item ${comment.sourceUser.id === rights?.id ? 'is-me' : 'is-not-me'} ${comment.isNew ? ' is-new' : 'is-not-new'}`}>
      <div className="ui-comment-item-frame">
        <div className="ui-comment-item-content">{ready}</div>
        {comment.sourceUser.id === rights?.id ? (
          <div className="ui-comment-item-ident">
            <div className="name ui-is-me">
              {comment.sourceUser.givenName} {comment.sourceUser.familyName}
            </div>
            <div className="time ui-text-muted" style={{ marginLeft: 5 }}>
              {formatDate(comment)}
            </div>
            {url && (
              <Link to={url} className="url">
                <FontAwesomeIcon icon={faUpRightFromSquare} />
              </Link>
            )}
          </div>
        ) : (
          <div className="ui-comment-item-ident">
            {comment.sourceUser.givenName && comment.sourceUser.familyName ? (
              <>
                <div className="name">
                  {comment.sourceUser.givenName} {comment.sourceUser.familyName}
                </div>
                <div className="time ui-text-muted">{formatDate(comment)}</div>
                {url && (
                  <Link to={url} className="url">
                    <FontAwesomeIcon icon={faUpRightFromSquare} />
                  </Link>
                )}
              </>
            ) : (
              <span className="time ui-text-muted" style={{ marginLeft: 0 }}>
                {formatDate(comment)}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
