import React from 'react'
import { useTranslation } from 'react-i18next'
import { TransactionStats } from 'services/src/api'
import { useRights, UserType } from 'services/src/state'

export const TransactionListHeader: React.FC<{ stats?: TransactionStats }> = ({ stats }) => {
  const { t } = useTranslation()
  const rights = useRights()

  return (
    <div className="ui-transaction-list-subheader">
      <div className="left">
        <div>
          <div>{t('General.Transactions')}</div>
        </div>
        <div className="ui-text-xs ui-text-muted ui-flex ui-flex-nowrap">
          {!stats && <div>...</div>}
          {stats && (
            <>
              <div>{t('General.TransactionCount', { count: stats.total })}</div>
              {(() => {
                if (!rights) return null

                return (
                  <>
                    {stats && stats.credit > 0 && (
                      <>
                        <div>|</div>
                        {rights?.userType === UserType.provider ? (
                          <div>
                            {t('General.Income')} {t('General.Currency.Value', { value: stats?.credit, minDigits: 0, maxDigits: 0 })} (YTD)
                          </div>
                        ) : (
                          <div>
                            {t('General.ProjectFees')} {t('General.Currency.Value', { value: stats.credit, minDigits: 0, maxDigits: 0 })} (YTD)
                          </div>
                        )}
                      </>
                    )}
                    {stats && stats.debit > 0 && (
                      <>
                        <div>|</div>
                        {rights?.userType === UserType.provider ? (
                          <div>
                            {t('General.Expenses')} {t('General.Currency.Value', { value: stats.debit, minDigits: 0, maxDigits: 0 })} (YTD)
                          </div>
                        ) : (
                          <div>
                            {t('General.ProjectCredits')} {t('General.Currency.Value', { value: stats.debit, minDigits: 0, maxDigits: 0 })} (YTD)
                          </div>
                        )}
                      </>
                    )}
                  </>
                )
              })()}
            </>
          )}
        </div>
      </div>
      <div className="right" />
    </div>
  )
}
