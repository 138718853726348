import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Modal, ModalProps } from 'components/src/modal';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostRequests } from 'services/src/api/boe';
import { endpoints } from 'services/src/lib/axios';
import { Loading } from '../../../loading';

type BoeReportPreviewModalProps = Omit<ModalProps, 'onClose'> & {
    boeData: any;
    onClose: (sent?: boolean) => void
}

// Set the workerSrc property
GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`;

export const BoeReportPreviewModal: React.FC<BoeReportPreviewModalProps> = ({ boeData, onClose, ...rest }) => {
    const { t } = useTranslation();
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const { data, postRequests, loading } = usePostRequests();
    const [pdfUrl, setPdfUrl] = React.useState<string | null>(null);

    useEffect(() => {
        if (Object.keys(boeData).length !== 0) {
            postRequests(endpoints.boe.generateBasisOfEstimate, boeData);
        }
    }, [boeData, postRequests]);

    useEffect(() => {
        if (data?.response) {
            setPdfUrl(data?.response);
        }
    }, [data?.response]);

    return (
        <Modal
            header={
                <div>
                    <h3>{t('BasisOfEstimate.PreviewTitle')}</h3>
                </div>
            }
            onClose={() => onClose(false)}
            className='ui-modal-large'
            closeOnClickOutside={false}
            {...rest}
        >
            <div className="boe-form-content">
                {loading && <Loading />}
                <div style={{ height: '500px' }}>
                    {pdfUrl && (
                        // @ts-ignore
                        <Viewer
                            fileUrl={pdfUrl}
                            plugins={[
                                // Register plugins
                                defaultLayoutPluginInstance
                            ]}
                        />
                    )}
                </div>
            </div>
        </Modal>
    )
}
