import React, { useCallback, useEffect, useState } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useCurrentAccountWithUser, useCurrentUserAccount, useMe, useRights, UserType, useUserAccounts } from 'services/src/state'
import { CreateInvitation } from 'services/src/dto/invitation'
import { createInvitation } from 'services/src/api'

import './style.scss'
import { Loading } from 'components/src/loading'
import { useAlert } from 'components/src/alerts'
import { setTitle } from 'services/src/dom'
import { useSaving } from 'components/src/saving'
import { ExpertProfile } from './expertProfile'

import { AccountProfile } from './accountProfile'
import { AccountStaff } from './AccountStaff'
import { SettingsSubheader } from './SettingsSubheader'
import { SettingsNav } from './SettingsNav'
import { ClientProfile } from './clientProfile'
import { InviteModal } from './InviteModal'
import { InviteProviderModal } from './InviteProviderModal'
import { ProviderStaff } from './ProviderStaff'
import { ProviderAccountProfile } from './providerProfile/ProviderAccountProfile'
import { ProviderProfile } from './providerProfile'
import { ProviderClients } from './ProviderClients'

export const Settings: React.FC = () => {
  const { t } = useTranslation()
  const [me] = useMe()
  const [, refreshAccounts] = useUserAccounts()
  const [account] = useCurrentUserAccount()
  const [, refreshUsers] = useCurrentAccountWithUser()
  const rights = useRights()
  const { url, path } = useRouteMatch()
  const { pathname } = useLocation();
  const { alertSuccess, alertDanger } = useAlert()
  const [, setSaving] = useSaving()

  const [invite, setInvite] = useState<{ type: string; providerClient?: boolean } | undefined>()

  const [aside, setAside] = useState(true)

  useEffect(() => {
    setTitle(t('Settings.Title'))
    return () => {
      setTitle()
      refreshAccounts().then(() => {})
    }
  }, [])

  const handleSendInvitation = useCallback(
    (invitation: CreateInvitation) => {
      setInvite(undefined)
      setSaving(true)
      createInvitation(account!.id, invitation)
        .then(() => refreshUsers())
        .then(() => {
          alertSuccess({
            title: t('Settings.InviteCreateTitle'),
            message: t('Settings.InviteCreate', {
              name: `${invitation.givenName} ${invitation.familyName}`,
              email: invitation.emailAddress
            })
          })
        })
        .catch((err) => {
          if (err.message.includes('409')) {
            alertDanger({
              title: t('Settings.Errors.DuplicateInviteTitle'),
              message: t('Settings.Errors.DuplicateInvite', {
                name: `${invitation.givenName} ${invitation.familyName}`,
                email: invitation.emailAddress
              }),
              error: err
            })
            return
          }
          alertDanger({
            title: t('Settings.Errors.InviteCreateTitle'),
            message: t('Settings.Errors.InviteCreate'),
            error: err
          })
        })
        .finally(() => setTimeout(() => setSaving(false)))
    },
    [account, setInvite, setSaving, alertSuccess, alertDanger, refreshUsers]
  )

  if (!rights) return <Loading />

  return (
    <>
      <div className={aside && rights?.userType !== UserType.expert ? 'settings-nav-open' : 'settings-nav-closed'}>
        {rights?.userType !== UserType.expert && <SettingsNav visible={aside} url={url} />}
        <SettingsSubheader
          account={account}
          me={me}
          onToggleAside={() => setAside((o) => !o)}
          asideOpen={aside}
          onInvite={() => {
            console.log('url', pathname)
            if (pathname.includes('clients')) {
              setInvite({ type: 'client', providerClient: true })
              return
            }

            setInvite({
              type: rights?.userType === UserType.client ? 'client' : 'provider'
            })
          }}
        />

        <div className="settings">
          {rights?.userType === UserType.client && (
            <Switch>
              <Route path={`${path}/account/staff`}>{rights?.canEditAccount ? <AccountStaff /> : <Redirect to={url} />}</Route>

              <Route path={`${path}/account`}>{rights?.canEditAccount ? <AccountProfile /> : <Redirect to={url} />}</Route>

              <Route path={`${path}/user`}>
                <ClientProfile />
              </Route>
            </Switch>
          )}

          {rights?.userType === UserType.expert && (
            <Switch>
              <Route path={`${path}/account/staff`}>{rights?.canEditAccount ? <ProviderStaff /> : <Redirect to={url} />}</Route>

              <Route path={`${path}/account`}>{rights?.canEditAccount ? <AccountProfile /> : <Redirect to={url} />}</Route>

              <Route path={`${path}/user`}>
                <ExpertProfile />
              </Route>
            </Switch>
          )}

          {rights?.userType === UserType.provider && (
            <Switch>
              <Route path={`${path}/account/staff`}>{rights?.canEditAccount ? <ProviderStaff /> : <Redirect to={url} />}</Route>

              <Route path={`${path}/account/clients`}>{rights?.canListClients ? <ProviderClients /> : <Redirect to={url} />}</Route>

              <Route path={`${path}/account`}>{rights?.canEditAccount ? <ProviderAccountProfile /> : <Redirect to={url} />}</Route>

              <Route path={`${path}/user`}>
                <ProviderProfile />
              </Route>
            </Switch>
          )}
        </div>
      </div>

      {invite?.type === 'client' && account && (
        <InviteModal
          providerClient={invite.providerClient}
          account={account}
          onSend={handleSendInvitation}
          onClose={() => setInvite(undefined)} />
      )}

      {invite?.type === 'provider' && account && (
        <InviteProviderModal
          account={account}
          onSend={handleSendInvitation}
          onClose={() => setInvite(undefined)} />
      )}
    </>
  )
}
