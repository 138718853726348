import i18n, { InitOptions } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import moment from 'moment'
import HttpBackend from 'i18next-http-backend'
import { i84nFormat } from 'services/src/i18n'

import en from 'locales/en/translation.json'

interface Translations {
  [key: string]: string
}
const translations: Translations = {
  en: en as unknown as string
}

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    fallbackLng: 'en',
    debug: `${process.env.NODE_ENV}` !== 'production',
    partialBundledLanguages: true,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
      format: i84nFormat
    },
    backend: {
      loadPath: (lng: string[]) => {
        const p = translations[lng.length > 0 ? lng[0] : 'en'];
        return p.replace('./', '/');
      }
    }
  } as InitOptions)
  .then(() => {})

i18n.on('languageChanged', (lng) => {
  moment.locale(lng)
})

export default i18n
