import React, { useCallback, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useOverlayControl } from 'services/src/state'

import './style.scss'

export interface ModalProps {
  className?: string
  header?: string | JSX.Element
  footer?: string | JSX.Element
  closeOnClickOutside?: boolean
  onClose?: () => void
}

export const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({ className, header, footer, closeOnClickOutside = true, onClose, children }) => {
  const keydown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (onClose) onClose()
      }
    },
    [onClose]
  )

  const { addOverlay, removeOverlay } = useOverlayControl()

  const elm = useRef<HTMLDivElement>(document.createElement('div'))
  useEffect(() => {
    document.body.appendChild(elm.current)
    window.addEventListener('keydown', keydown)
    addOverlay(Modal.name)
    return () => {
      removeOverlay(Modal.name)
      window.removeEventListener('keydown', keydown)
      if (elm.current) document.body.removeChild(elm.current)
    }
  }, [])

  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!modalRef.current) return

    const { height, width } = modalRef.current.getBoundingClientRect()
    if (height <= 0 || width <= 0) return

    const left = Math.max(20, Math.floor(window.innerWidth / 2 - width / 2))
    const top = Math.max(20, Math.floor(window.innerHeight / 2 - height / 2))

    modalRef.current.style.left = `${left}px`
    modalRef.current.style.top = `${top}px`
  }, [modalRef.current, children])

  if (!elm.current) return null

  return ReactDOM.createPortal(
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      className="ui-modal-wrapper ui-modal-fadein"
      role="dialog"
      onKeyDown={() => {}}
      onClick={() => {
        if (closeOnClickOutside && onClose) onClose()
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div className={`ui-modal${className ? ` ${className}` : ''}`} ref={modalRef} role="dialog" onKeyDown={() => {}} onClick={(e) => e.stopPropagation()}>
        {header && (
          <div className="ui-modal-header">
            {onClose && (
              <div className="ui-modal-close" role="button" tabIndex={-1} onKeyDown={() => {}} onClick={onClose}>
                &times;
              </div>
            )}
            {header}
          </div>
        )}
        <div className="ui-modal-body">{children}</div>
        {footer && <div className="ui-modal-footer">{footer}</div>}
      </div>
    </div>,
    elm.current
  )
}

export default Modal
