import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { useRights } from 'services/src/state'

export const SettingsNav: React.FC<{
  visible: boolean
  url: string
}> = ({ visible, url }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const rights = useRights()

  if (!visible) return null

  return (
    <div className="settings-nav">
      <label>{t('General.User')}</label>
      <div className={pathname.includes('settings/user') ? 'active' : undefined}>
        <Link to={`${url}/user`}>{t('General.Profile')}</Link>
      </div>

      {rights?.canEditAccount && (
        <>
          <label>{t('General.Account')}</label>
          <div className={pathname.includes('settings/account') && !pathname.includes('/staff') && !pathname.includes('/clients') ? 'active' : undefined}>
            <Link to={`${url}/account`}>{t('General.Profile')}</Link>
          </div>
          <div className={pathname.includes('settings/account/staff') ? 'active' : undefined}>
            <Link to={`${url}/account/staff`}>{t('General.Staff')}</Link>
          </div>
          {rights?.canListClients && (
            <div className={pathname.includes('settings/account/clients') ? 'active' : undefined}>
              <Link to={`${url}/account/clients`}>{t('General.Clients')}</Link>
            </div>
          )}
        </>
      )}
    </div>
  )
}
