import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParam } from 'use-query-params'

import { createProject, deleteProject, getAccountsByAccount, getClientAccounts, getProject, getProjectContent, getProviderAccounts, updateProject, useProjectSupport } from 'services/src/api'
import { PhaseTypeKey } from 'services/src/dto/projectShare'
import { ProjectEx, useCurrentUserAccount, useCurrentUserOffice, useMe, useRights, UserType, useUserOffices } from 'services/src/state'
import { Project, ProjectStatus } from 'services/src/dto/project'

import './style.scss'
import { Loading } from 'components/src/loading'
import { useAlert } from 'components/src/alerts'
import { useSetMainSubheader } from 'components/src/pages/layout/dashboard/MainLayout'
import { AccountType, Office, ResourceUserRole, UserStatus } from 'services/src/dto/account'
import { makeDashboardPath, setTitle } from 'services/src/dom'
import { useSaving } from 'components/src/saving'
import Header from './Header'

import { Scope } from './Scope'
import { Timeline } from './Timeline'
import { Details } from './Details'
import { Upload } from './Upload'
import { Summary } from './Summary'
import { Submitted } from './Submitted'

import { NewProjectModel } from './model'
import { ExitModal } from './ExitModal'
import { Feature, useFeatures } from 'services/src/state/features'
import { SelectAccount } from './SelectAccount'
import { ProjectContentCategory } from 'services/src/dto/content'

interface Step {
  id: string
  stepNo: number
  title: string
}

export const ProjectNew: React.FC = () => {
  const { t, i18n } = useTranslation()

  const { path, url } = useRouteMatch()
  const history = useHistory()
  const { pathname } = useLocation()
  const [projectId] = useQueryParam('projectId', StringParam)

  const [me] = useMe()
  const [account] = useCurrentUserAccount()
  const rights = useRights()
  const { hasFeature } = useFeatures()
  const [offices] = useUserOffices()
  const [office] = useCurrentUserOffice()
  const { alertDanger, alertWarn } = useAlert()

  const [ready, setReady] = useState(false)

  const readyOffices = useMemo<Office[]>(() => {
    if (!account) return []

    const au = account.users?.find((u) => u.id === me?.id)
    if (au && [ResourceUserRole.Owner, ResourceUserRole.Administrator, ResourceUserRole.Contributor].includes(au.role)) {
      return account?.offices || []
    }

    return account?.offices?.filter((o) => !!o.users?.find((u) => u.id === me?.id && [ResourceUserRole.Owner, ResourceUserRole.Administrator, ResourceUserRole.Contributor].includes(u.role))) || []
  }, [account, offices, me])

  const [isDirty, setIsDirty] = useState(false)

  const beforeUnload = useCallback(
    (e: any) => {
      if (!isDirty) return undefined

      if (e.preventDefault) e.preventDefault()

      e.returnValue = t('NewProject.Unload')
      return e.returnValue
    },
    [isDirty, t]
  )

  useEffect(() => {
    setTitle(t('NewProject.Title'))
    return () => {
      setTitle()
    }
  }, [t])

  useEffect(() => {
    document.body.classList.add('new-project')
    window.addEventListener('beforeunload', beforeUnload)
    return () => {
      document.body.classList.remove('new-project')
      window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [beforeUnload])

  const [projectSupportState] = useProjectSupport()

  const [model, setModel] = useState<NewProjectModel>({
    name: '',
    account: rights?.userType === UserType.provider ? undefined : account,
    provider: rights?.userType === UserType.provider && !rights?.primaryProvider ? account : undefined,
    office: rights?.userType === UserType.provider ? undefined : office,
    phases: [
      { phaseType: PhaseTypeKey.Concept },
      { phaseType: PhaseTypeKey.Schematic },
      { phaseType: PhaseTypeKey.DesignDoc },
      { phaseType: PhaseTypeKey.ConstructionDoc },
      { phaseType: PhaseTypeKey.Proposal }
    ],
    projectNumber: '',
    taskOrderNumber: '',
    description: '',
    ext: {
      isFixedBudget: false,
      provider: {
        selected: rights?.userType === UserType.provider && !rights?.primaryProvider ? account : undefined,
        accounts: [],
        search: '',
        pm: rights?.userType === UserType.provider ? me : undefined
      },
      account: {
        selected: rights?.userType === UserType.provider ? undefined : account,
        accounts: [],
        search: '',
        pm: rights?.userType === UserType.client ? me : undefined
      }
    }
  })

  useEffect(() => {
    if (!account?.id || projectSupportState.loading) return

    Promise.all([ getClientAccounts(account.id), getProviderAccounts(account.id)])
      .then(([clientAccounts, providerAccounts]) => {
        if (!projectId) {
          setModel((current) => {
            const p = {
              ...current,
              ext: {
                ...current.ext,
                provider: {
                  ...current.ext.provider,
                  accounts: providerAccounts
                },
                account: {
                  ...current.ext.account,
                  accounts: clientAccounts
                }
              }
            }
            if (providerAccounts.length === 1) p.ext.provider.selected = providerAccounts[0]
            if (clientAccounts.length === 1) p.ext.account.selected = clientAccounts[0]
            return p
          })
          return
        }

        return getProject(account!.id, projectId!, i18n.language)
          .then((project) => getProjectContent(account.id, project.id!).then((content) => ({ project, content })))
          .then(({ project, content }) => {
            const model = {
              ...project,
              ext: {
                constructionType: projectSupportState.constructionTypes.find((x) => x.id === project.constructionType),
                deliveryMethod: projectSupportState.deliveryMethods.find((x) => x.id === project.deliveryMethod),
                procurementMethod: projectSupportState.procurementMethods.find((x) => x.id === project.procurementMethod),
                estimateType: projectSupportState.estimateTypes.find((x) => x.id === project.estimateType),
                isFixedBudget: project.budget?.isFixed,
                fixed: project.budget?.fixed,
                range: projectSupportState.budgetRanges.find((x) => x.id === project.budget?.range),
                drawing: content.find((x) => x.category === ProjectContentCategory.Drawing),
                documents: content.filter((x) => x.category === ProjectContentCategory.Document),
                photos: content.filter((x) => x.category === ProjectContentCategory.Picture),
                provider: {
                  selected: project.provider || (providerAccounts?.length === 1 ? providerAccounts[0] : undefined),
                  accounts: providerAccounts,
                  search: '',
                  pm: project.providerPm
                },
                account: {
                  selected: project.account || (clientAccounts?.length === 1 ? clientAccounts[0] : undefined),
                  accounts: clientAccounts,
                  search: '',
                  pm: project.clientPm
                }
              }
            } as NewProjectModel
            setModel(model)

            const qp = projectId ? `?projectId=${projectId}` : ''

            if (!model.account) history.replace(makeDashboardPath(account?.id, `projects/new/client${qp}`))
            else if (!model.name) history.replace(makeDashboardPath(account?.id, `projects/new/scope${qp}`))
            else if ((model.budget?.isFixed && !model.budget?.fixed) || (!model.budget?.isFixed && !model.budget?.range)) history.replace(makeDashboardPath(account?.id, `projects/new/details${qp}`))
            else if (!model.provider) history.replace(makeDashboardPath(account?.id, `projects/new/provider${qp}`))
            else if (!model.phases?.length) history.replace(makeDashboardPath(account?.id, `projects/new/timeline${qp}`))
            else if (!model.ext.drawing && !model.ext.documents?.length && !model.ext.photos?.length) history.replace(makeDashboardPath(account?.id, `projects/new/upload${qp}`))
            else history.replace(makeDashboardPath(account?.id, `projects/new/summary${qp}`))
            setReady(true)
          })
      })
      .finally(() => setReady(true))
  }, [account, projectSupportState.loading, setReady, history])

  const steps = useMemo<Step[]>(() => {
    const steps: Step[] = []
    let stepNo = 1

    if (account?.type === AccountType.Provider)
      steps.push({
        id: 'account',
        stepNo: stepNo++,
        title: 'NewProject.account.Header'
      })

    steps.push({
      id: 'scope',
      stepNo: stepNo++,
      title: 'NewProject.Scope.Header'
    })
    steps.push({
      id: 'details',
      stepNo: stepNo++,
      title: 'NewProject.Details.Header'
    })

    if ((account?.type === AccountType.Client || rights?.primaryProvider) && hasFeature(Feature.MultiProvider))
      steps.push({
        id: 'provider',
        stepNo: stepNo++,
        title: 'NewProject.provider.Header'
      })

    steps.push({
      id: 'timeline',
      stepNo: stepNo++,
      title: 'NewProject.Timeline.Header'
    })

    steps.push({
      id: 'upload',
      stepNo: stepNo++,
      title: 'NewProject.Upload.Header'
    })

    steps.push({
      id: 'summary',
      stepNo: stepNo++,
      title: 'NewProject.Summary.Header'
    })

    return steps
  }, [rights, account, t])

  const setSubheader = useSetMainSubheader()

  useEffect(() => {
    if (!ready) return

    let currentStep: Step | null = null
    let nextStep: Step | null = null
    for (let i = 0; i < steps.length; i++) {
      if (pathname.endsWith(steps[i].id) || (pathname.endsWith('/new') && i === 0)) {
        currentStep = steps[i++]
        if (i < steps.length) nextStep = steps[i]
        break
      }
    }

    if (!currentStep) {
      setSubheader(undefined)
      return
    }

    setSubheader(
      <Header
        ready={ready}
        title={t(currentStep.title)}
        subTitle={t('General.StepNofM', { N: currentStep.stepNo, M: steps.length })}
        nextTitle={nextStep ? t(nextStep.title) : undefined}
        nextSubTitle={nextStep ? t('General.StepNofM', { N: nextStep.stepNo, M: steps.length }) : undefined}
      />
    )

    return () => {
      setSubheader(undefined)
    }
  }, [steps, pathname, setSubheader, ready])

  useEffect(() => {
    setModel((current) => {
      // if (current.office) return current;

      const id = office?.id
      let o = readyOffices.find((x) => x.id === id)
      if (!o) o = readyOffices.length > 0 ? readyOffices[0] : undefined
      return { ...current, office: o }
    })
  }, [office, readyOffices, setModel])

  const changeModel = useCallback(
    (model: NewProjectModel) => {
      setModel(model)
      setIsDirty(true)
    },
    [setModel, setIsDirty]
  )

  useEffect(() => {
    if (!ready) return
    if (pathname.endsWith('/new')) {
      history.replace(makeDashboardPath(account?.id, `projects/new/${steps[0].id}`))
      return
    }

    if (model.id) return

    // if (steps[0].id === 'account') {
    //   if (!model.ext.account.selected)
    //     history.replace(makeDashboardPath(account?.id, `projects/new/account${projectId ? `?projectId=${projectId}` : ''}`))
    //   else if (!pathname.endsWith('/account') && !pathname.endsWith('/scope'))
    //     history.replace(makeDashboardPath(account?.id, `projects/new/scope${projectId ? `?projectId=${projectId}` : ''}`))
    // } else {
    //   if (!pathname.endsWith('/scope'))
    //     history.replace(makeDashboardPath(account?.id, `projects/new/scope${projectId ? `?projectId=${projectId}` : ''}`))
    // }
  }, [pathname, history, model, account, projectId, steps, ready])

  const [, setSaving] = useSaving()
  const [exit, setExit] = useState(false)

  const saveProject = useCallback(
    (nextView: string, noSave?: boolean) => {
      if (noSave) {
        history.push(makeDashboardPath(account?.id, `projects/new/${nextView}${model.id ? `?projectId=${model.id}` : ''}`))
        return
      }

      setSaving(true)

      const p = { ...model } as Project
      if (nextView === 'submitted') p.status = ProjectStatus.Submitted
      p.account = model.ext.account.selected
      p.provider = model.ext.provider.selected
      p.constructionType = model.ext.constructionType?.id
      p.deliveryMethod = model.ext.deliveryMethod?.id
      p.procurementMethod = model.ext.procurementMethod?.id
      p.estimateType = model.ext.estimateType?.id
      if (!p.description) p.description = undefined
      if (!p.projectNumber) p.projectNumber = undefined
      if (!p.taskOrderNumber) p.taskOrderNumber = undefined
      p.budget = {
        isFixed: model.ext.isFixedBudget,
        fixed: model.ext.fixed,
        range: model.ext.range?.id
      }
      p.phases?.forEach((ph) => {
        if (ph.id?.startsWith('-')) ph.id = undefined
      })

      if (model.ext.account.pm) {
        const { id, givenName, familyName } = model.ext.account.pm
        p.users = [{ id, givenName, familyName, role: ResourceUserRole.Owner, status: UserStatus.Ready }]
      }
      if (model.ext.provider.pm) {
        const { id, givenName, familyName } = model.ext.provider.pm
        p.users = [{ id, givenName, familyName, role: ResourceUserRole.Owner, status: UserStatus.Ready }]
      }

      // Remove the extension
      delete (p as any).ext

      const create = !p.id

      ;(create ? createProject(account?.id!, p, i18n.language) : updateProject(p.account?.id!, p.id!, p, i18n.language))
        .then((updated) => {
          setModel({
            ...(updated as ProjectEx),
            ext: {
              ...model.ext,
              newClient: undefined,
              newProjectType: undefined
            }
          })
          setIsDirty(false)

          history.push(makeDashboardPath(account?.id, `projects/new/${nextView}?projectId=${updated.id}`))
        })
        .catch((err) => {
          alertDanger({
            title: t(create ? 'NewProject.Errors.CreateFailedTitle' : 'NewProject.Errors.UpdateFailedTitle'),
            message: t(create ? 'NewProject.Errors.CreateFailed' : 'NewProject.Errors.UpdateFailed'),
            error: err
          })
        })
        .finally(() => setSaving(false))
    },
    [setSaving, model, setModel, setIsDirty, history, account, alertDanger, t, i18n.language, projectId]
  )

  const routes = useMemo(() => {
    return steps.map((s, idx) => {
      const prev = idx > 0 ? steps[idx - 1] : null
      const next = idx < steps.length - 1 ? steps[idx + 1] : null

      const backUrl = prev ? `${url}/${prev.id}${projectId ? `?projectId=${projectId}` : ''}` : makeDashboardPath(account?.id, 'projects')

      return (
        <Route key={s.id} path={`${path}/${s.id}`} exact>
          {s.id === 'account' && (
            <SelectAccount
              active
              which="account"
              step={idx}
              isLastStep={!next}
              onChangeModel={changeModel}
              onCancel={() => history.replace(backUrl)}
              onBack={() => {}}
              onNext={() => saveProject(next!.id, true)}
              model={model}
            />
          )}
          {s.id === 'scope' && (
            <Scope
              active
              step={idx}
              isLastStep={!next}
              onChangeModel={changeModel}
              onCancel={() => {
                if (idx === 0) history.go(-1);
                else if (idx === 1 && steps[0].id === 'account') history.go(-2);
              }}
              onBack={idx > 0 ? () => history.replace(backUrl) : () => {}}
              onNext={() => saveProject(next!.id)}
              model={model}
              offices={readyOffices}
            />
          )}
          {s.id === 'details' && (
            <Details
              active
              step={idx}
              isLastStep={!next}
              onChangeModel={changeModel}
              onCancel={() => setExit(true)}
              onBack={() => history.replace(backUrl)}
              onNext={() => saveProject(next!.id)}
              model={model}
            />
          )}
          {s.id === 'provider' && (
            <SelectAccount
              active
              which="provider"
              step={idx}
              isLastStep={!next}
              onChangeModel={changeModel}
              onCancel={() => setExit(true)}
              onBack={() => history.replace(backUrl)}
              onNext={() => saveProject(next!.id)}
              model={model}
            />
          )}
          {s.id === 'timeline' && (
            <Timeline
              active
              step={idx}
              isLastStep={!next}
              onChangeModel={changeModel}
              onCancel={() => setExit(true)}
              onBack={() => history.replace(backUrl)}
              onNext={() => saveProject(next!.id)}
              model={model}
            />
          )}
          {s.id === 'upload' && (
            <Upload
              active
              step={idx}
              isLastStep={!next}
              onChangeModel={changeModel}
              onCancel={() => setExit(true)}
              onBack={() => history.replace(backUrl)}
              onNext={() => saveProject(next!.id)}
              model={model}
            />
          )}
          {s.id === 'summary' && (
            <Summary
              active
              step={idx}
              onCancel={() => setExit(true)}
              onBack={() => history.replace(backUrl)}
              onSubmit={() => saveProject('submitted')}
              onSaveForLater={() => history.replace(makeDashboardPath(account?.id, `projects`))}
              model={model}
            />
          )}
        </Route>
      )
    })
  }, [steps, model, changeModel])

  if (!ready) return <Loading />

  return (
    <>
      <div className="new-project main-content-inner">
        <div className="new-project-step">
          {routes}

          <Route path={`${path}/submitted`} exact>
            <Submitted active model={model} />
          </Route>
        </div>
      </div>

      {exit && (
        <ExitModal
          onClose={() => setExit(false)}
          onSaveForLater={() => history.replace(makeDashboardPath(account?.id, `projects`))}
          onAbandon={(noDelete) => {
            setExit(false)
            if (noDelete) {
              history.replace(makeDashboardPath(account?.id, `projects`))
              return
            }

            setSaving(true)
            deleteProject(model.account?.id || '', model.id || '', true)
              .then(() => history.replace(makeDashboardPath(account?.id, `projects`)))
              .catch((err) => {
                alertWarn({
                  title: t('NewProject.Errors.DeleteFailedTitle'),
                  message: t('NewProject.Errors.DeleteFailed'),
                  error: err
                })
                history.replace(makeDashboardPath(account?.id, `projects`))
              })
              .finally(() => setSaving(false))
          }}
        />
      )}
    </>
  )
}
