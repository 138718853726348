import React, { SVGProps } from 'react'
import './style.scss'

const DocumentsIcon: React.FC<SVGProps<SVGSVGElement>> = ({ className, ...rest }) => (
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={`ui-svg ui-svg-documents-icon ${className || ''}`} {...rest}>
    <path d="M16.875 10.125V9H13.5V14.625H14.625V12.375H16.3125V11.25H14.625V10.125H16.875Z" />
    <path d="M10.6875 14.625H8.4375V9H10.6875C11.1349 9.00049 11.5638 9.17844 11.8802 9.4948C12.1966 9.81116 12.3745 10.2401 12.375 10.6875V12.9375C12.3745 13.3849 12.1966 13.8138 11.8802 14.1302C11.5638 14.4466 11.1349 14.6245 10.6875 14.625ZM9.5625 13.5H10.6875C10.8366 13.4998 10.9796 13.4405 11.0851 13.3351C11.1905 13.2296 11.2498 13.0866 11.25 12.9375V10.6875C11.2498 10.5384 11.1905 10.3954 11.0851 10.2899C10.9796 10.1845 10.8366 10.1252 10.6875 10.125H9.5625V13.5Z" />
    <path d="M6.1875 9H3.375V14.625H4.5V12.9375H6.1875C6.48575 12.9371 6.77166 12.8184 6.98255 12.6076C7.19344 12.3967 7.3121 12.1107 7.3125 11.8125V10.125C7.31216 9.82674 7.19352 9.54079 6.98262 9.32988C6.77171 9.11898 6.48576 9.00034 6.1875 9V9ZM4.5 11.8125V10.125H6.1875L6.18806 11.8125H4.5Z" />
    <path d="M12.375 7.87519V5.62519C12.377 5.55126 12.363 5.47778 12.3338 5.4098C12.3047 5.34182 12.2612 5.28097 12.2063 5.23144L8.26875 1.29394C8.21923 1.239 8.15839 1.19546 8.09041 1.16633C8.02243 1.13719 7.94893 1.12316 7.875 1.12519H2.25C1.9519 1.12607 1.66626 1.24488 1.45547 1.45567C1.24468 1.66645 1.12588 1.95209 1.125 2.25019V15.7502C1.125 16.0486 1.24353 16.3347 1.4545 16.5457C1.66548 16.7567 1.95163 16.8752 2.25 16.8752H11.25V15.7502H2.25V2.25019H6.75V5.62519C6.75088 5.92329 6.86968 6.20893 7.08047 6.41972C7.29126 6.63051 7.5769 6.74932 7.875 6.75019H11.25V7.87519H12.375ZM7.875 5.62519V2.47519L11.025 5.62519H7.875Z" />
  </svg>
)
export default DocumentsIcon
