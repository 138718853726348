/* eslint-disable react/no-danger */
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PhaseEx, useEditProject } from 'services/src/state'
import { ProjectStatus } from 'services/src/dto/project'
import moment, { Moment } from 'moment'
import { ConfirmModal } from 'components/src/modal/ConfirmModal'
import { useSaving } from 'components/src/saving'
import { TextAreaField } from 'components/src/textAreaField'
import ProjectSvg from 'components/src/svg/Projects'
import logo from 'components/src/img/logo-alt.png'

export interface FeeProviderCounterProps {
  onAccept: (phases: PhaseWithCounter[]) => void
  onReject: (message: string) => void
}

export type PhaseWithCounter = PhaseEx & {
  counterFee: number
}

export const FeeProviderCounter: React.FC<FeeProviderCounterProps> = ({ onAccept, onReject }) => {
  const { t } = useTranslation()
  const [saving] = useSaving()
  const { project } = useEditProject()

  const { phases, totalFee, counterTotalFee, counterDate } = useMemo<{
    phases: PhaseWithCounter[]
    totalFee: number
    counterTotalFee: number
    counterDate: Moment
  }>(() => {
    let counterDate = moment()

    const phases = project.phases.filter((x) => x.status === ProjectStatus.FeeCounter).map<PhaseWithCounter>((ph) => ({ ...ph, counterFee: 0 }))
    let totalFee = 0
    let counterTotalFee = 0
    phases.forEach((ph) => {
      const sh = ph.statusHistory?.find((x) => x.newStatus === ProjectStatus.FeeCounter)
      if (!sh) return

      if (moment(sh.createdAt).isBefore(counterDate)) counterDate = moment(sh.createdAt)

      totalFee += sh.metadata?.originalFee || 0

      ph.counterFee = sh.metadata?.fee || 0
      counterTotalFee += ph.counterFee
    })

    return { phases, totalFee, counterTotalFee, counterDate }
  }, [project])

  const sendDate = useMemo(() => project.statusHistory?.find((x) => x.newStatus === ProjectStatus.FeeReady)?.createdAt, [project])

  const feeDifference = useMemo(() => {
    const diff = -(((totalFee - counterTotalFee) / ((totalFee + counterTotalFee) / 2)) * 100)
    return `${diff > 0 ? '+' : ''}${t('General.PercentValue', { value: diff.toFixed(1) })}`
  }, [totalFee, counterTotalFee])

  const [confirmAccept, setConfirmAccept] = useState(false)
  const [rejectCounter, setRejectCounter] = useState(false)
  const [rejectMessage, setRejectMessage] = useState('')
  const [rejectMessageError, setRejectMessageError] = useState<string>()

  if (!phases.length) return null

  return (
    <>
      <div className="ui-frame ui-frame-bg project-edit-client-accept" style={{ maxWidth: 450 }}>
        <div className="the-total">
          <img alt="" src={logo} />
          <div style={{ marginLeft: 10 }}>{t('EditProject.Fees.ClientFeeCounter')}</div>
          <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: counterTotalFee })}</div>
        </div>

        <div className="ui-flex ui-flex-nowrap ui-text-muted ui-text-sm" style={{ marginTop: 20 }}>
          {sendDate ? <div>{moment(sendDate).format('ll')}</div> : <div>&nbsp;</div>}
          <div style={{ marginLeft: 'auto' }}>{t('EditProject.Fees.ProviderFee')}</div>
        </div>
        <div className="ui-flex ui-flex-nowrap" style={{ marginTop: 15 }}>
          <div>{t('EditProject.Fees.ClientFeeSent')}</div>
          <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: totalFee })}</div>
        </div>

        <hr />

        <div className="ui-flex ui-flex-nowrap ui-text-muted ui-text-sm" style={{ marginTop: 20 }}>
          {counterDate ? <div>{moment(counterDate).format('ll')}</div> : <div>&nbsp;</div>}
          <div style={{ marginLeft: 'auto' }}>{t('EditProject.Fees.CounterOffer')}</div>
        </div>

        <div style={{ marginTop: 20 }} className="ui-text-sm">
          {phases.map((ph) => (
            <div key={ph.id} className="ui-flex ui-flex-nowrap">
              <div>{t(`EditProject.${ph.phaseType}`)}</div>
              <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: ph.counterFee })}</div>
            </div>
          ))}
        </div>

        <div className={`ui-flex ui-flex-nowrap ui-text ${feeDifference.startsWith('-') ? 'ui-danger' : 'ui-secondary'}`} style={{ marginTop: 15 }}>
          <div>
            {t('EditProject.Fees.ClientFeeCounter')} ({feeDifference})
          </div>
          <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: counterTotalFee })}</div>
        </div>

        <div style={{ marginTop: 30 }}>
          <button type="button" disabled={saving} onClick={() => setConfirmAccept(true)} className="ui-btn ui-btn-solid ui-btn-secondary" style={{ width: '100%' }}>
            {t('EditProject.Fees.AcceptCounter')}
          </button>
        </div>
        <div style={{ marginTop: 10 }}>
          <button
            type="button"
            disabled={saving}
            onClick={() => {
              setRejectMessage('')
              setRejectMessageError(undefined)
              setRejectCounter(true)
            }}
            className="ui-btn ui-btn-primary"
            style={{ width: '100%' }}
          >
            {t('EditProject.Fees.RejectCounter')}
          </button>
        </div>
      </div>

      {confirmAccept && (
        <ConfirmModal
          title={t('EditProject.Fees.AcceptCounterModal.Title')}
          message={
            <>
              <div className="ui-text-center">
                <ProjectSvg width="100" />
              </div>
              <div className="ui-text-sm" style={{ padding: 10 }}>
                {phases.map((ph) => (
                  <div key={ph.id} className="ui-flex ui-flex-nowrap">
                    <div>
                      {t(`EditProject.${ph.phaseType}`)}
                      {ph.name && (
                        <span className="ui-text-muted" style={{ marginLeft: 5 }}>
                          ({ph.name})
                        </span>
                      )}
                    </div>
                    <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: ph.counterFee })}</div>
                  </div>
                ))}
              </div>
              <br />
              <div
                dangerouslySetInnerHTML={{
                  __html: t('EditProject.Fees.AcceptCounterModal.Message', {
                    amount: t('General.Currency.Value', { value: counterTotalFee }),
                    diff: feeDifference
                  })
                }}
              />
            </>
          }
          onYes={() => {
            setConfirmAccept(false)
            onAccept(phases)
          }}
          onClose={() => setConfirmAccept(false)}
        />
      )}

      {rejectCounter && (
        <ConfirmModal
          title={t('EditProject.Fees.RejectCounterModal.Title')}
          message={
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('EditProject.Fees.RejectCounterModal.Message', {
                    amount: t('General.Currency.Value', { value: counterTotalFee }),
                    diff: feeDifference
                  })
                }}
              />

              <br />

              <TextAreaField
                name="rejectMessage"
                rows={3}
                error={rejectMessageError}
                label={<div dangerouslySetInnerHTML={{ __html: t('EditProject.Fees.RejectCounterModal.MessageToClient') }} />}
                placeholder={t('EditProject.Fees.RejectCounterModal.MessageToClientSample')}
                className="ui-text-sm"
                onChange={(v) => {
                  setRejectMessageError(undefined)
                  setRejectMessage(v)
                }}
                value={rejectMessage}
              />
            </>
          }
          onYes={() => {
            if (!rejectMessage) {
              setRejectMessageError(t('General.Errors.Required'))
              return
            }
            setRejectCounter(false)
            onReject(rejectMessage)
          }}
          onClose={() => setRejectCounter(false)}
        />
      )}
    </>
  )
}
