import { Loading } from 'components/src/loading';
import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Sidebar from './BoeRightSideNav';
// import the styles
import './BoeProject.scss';

const BoeProject10 = lazy(() => import('./BoeProject10'));
const BoeProject11 = lazy(() => import('./BoeProject11'));
const BoeProject12 = lazy(() => import('./BoeProject12'));
const BoeProject13 = lazy(() => import('./BoeProject13'));
const BoeProject14 = lazy(() => import('./BoeProject14'));
const BoeProject15 = lazy(() => import('./BoeProject15'));
const BoeProject16 = lazy(() => import('./BoeProject16'));
const BoeProject17 = lazy(() => import('./BoeProject17'));
const BoeProject18 = lazy(() => import('./BoeProject18'));
const BoeProject19 = lazy(() => import('./BoeProject19'));
const BoeProject110 = lazy(() => import('./BoeProject110'));
const BoeProject111 = lazy(() => import('./BoeProject111'));
const BoeProject112 = lazy(() => import('./BoeProject112'));
const BoeProject113 = lazy(() => import('./BoeProject113'));
const BoeProject114 = lazy(() => import('./BoeProject114'));
const BoeProject115 = lazy(() => import('./BoeProject115'));
const BoeProject116 = lazy(() => import('./BoeProject116'));
const BoeProject117 = lazy(() => import('./BoeProject117'));
const BoeProject118 = lazy(() => import('./BoeProject118'));
const BoeProject119 = lazy(() => import('./BoeProject119'));
const BoeProject120 = lazy(() => import('./BoeProject120'));
const BoeProject121 = lazy(() => import('./BoeProject121'));
const BoeProject122 = lazy(() => import('./BoeProject122'));
const BoeProject123 = lazy(() => import('./BoeProject123'));
const BoeProject124 = lazy(() => import('./BoeProject124'));
const BoeProject125 = lazy(() => import('./BoeProject125'));
const BoeProject126 = lazy(() => import('./BoeProject126'));
const BoeProject127 = lazy(() => import('./BoeProject127'));
const BoeProject128 = lazy(() => import('./BoeProject128'));
const BoeProject129 = lazy(() => import('./BoeProject129'));
const BoeProject130 = lazy(() => import('./BoeProject130'));
const BoeProject131 = lazy(() => import('./BoeProject131'));
const BoeProject132 = lazy(() => import('./BoeProject132'));
const BoeProject133 = lazy(() => import('./BoeProject133'));
const BoeProjectFinal = lazy(() => import('./BoeProjectFinal'));

const BasisOfEstimate = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');
  const [boeData, setBoeData] = useState<any>({});
  const [isProgressBarVisible, setIsProgressBarVisible] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<number>(tab ? parseInt(tab, 10) : 0);

  useEffect(() => {
    history.replace(`?tab=${activeTab}`);
  }, [activeTab, history]);

  const openTab = useCallback((tabName: number) => {
    setActiveTab(tabName);
  }, []);

  const handleNext = useCallback(() => {
    setActiveTab((prev) => prev + 1);
  }, []);

  const handlePrevious = useCallback(() => {
    setActiveTab((prev) => prev - 1);
  }, []);

  const tabComponents: { [key: number]: JSX.Element } = useMemo(() => ({
    0: <BoeProject10 handleNext={handleNext} setBoeData={setBoeData} boeData={boeData} />,
    1: <BoeProject11 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    2: <BoeProject12 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    3: <BoeProject13 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    4: <BoeProject14 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    5: <BoeProject15 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    6: <BoeProject16 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    7: <BoeProject17 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    8: <BoeProject18 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    9: <BoeProject19 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    10: <BoeProject110 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    11: <BoeProject111 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    12: <BoeProject112 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    13: <BoeProject113 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    14: <BoeProject114 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    15: <BoeProject115 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    16: <BoeProject116 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    17: <BoeProject117 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    18: <BoeProject118 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    19: <BoeProject119 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    20: <BoeProject120 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    21: <BoeProject121 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    22: <BoeProject122 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    23: <BoeProject123 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    24: <BoeProject124 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    25: <BoeProject125 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    26: <BoeProject126 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    27: <BoeProject127 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    28: <BoeProject128 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    29: <BoeProject129 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    30: <BoeProject130 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    31: <BoeProject131 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    32: <BoeProject132 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    33: <BoeProject133 handleNext={handleNext} handlePrevious={handlePrevious} setBoeData={setBoeData} boeData={boeData} isProgressBarVisible={isProgressBarVisible} setIsProgressBarVisible={setIsProgressBarVisible} />,
    34: <BoeProjectFinal handleNext={handleNext} handlePrevious={handlePrevious} boeData={boeData} />

  }), [handleNext, handlePrevious, setBoeData, boeData, isProgressBarVisible, setIsProgressBarVisible]);

  const sidebarTabs = useMemo(() => <Sidebar activeTab={activeTab} openTab={openTab} />, [activeTab, openTab]);

  const tabContent = useMemo(() => (
    <Suspense fallback={<Loading />}>
      {tabComponents[activeTab]}
    </Suspense>
  )
    , [activeTab, tabComponents]);

  return (
    <div className="basis-of-estimate">
      <div className="boe-content">
        {tabContent}
      </div>
      {sidebarTabs}
    </div>
  );
};

export default BasisOfEstimate;