import React, { useCallback, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBold, faItalic, faUnderline, faStrikethrough, faQuoteRight, faListUl, faListOl, faPaperPlane } from '@fortawesome/pro-solid-svg-icons'

import { convertToRaw, Editor, EditorState, RichUtils, RawDraftContentState, Modifier } from 'draft-js'
import './style.scss'

export interface CommentInputProps {
  height: number
  onSend: (content: RawDraftContentState, plainText: string) => void
  style?: React.CSSProperties
}

interface ToolbarState {
  bold?: boolean
  italic?: boolean
  underline?: boolean
  strikethrough?: boolean
  blockquote?: boolean
  ul?: boolean
  ol?: boolean
}

const CommentInput: React.FC<CommentInputProps> = ({ height, onSend, style }) => {
  const editorRef = useRef<Editor>(null)

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const [toolbarState, setToolbarState] = useState<ToolbarState>({})

  const change = useCallback(
    (editorState: EditorState) => {
      setEditorState(editorState)

      const selectionState = editorState.getSelection()
      const anchorKey = selectionState.getAnchorKey()
      const currentContent = editorState.getCurrentContent()
      const currentContentBlock = currentContent.getBlockForKey(anchorKey)

      const inlineStyle = editorState.getCurrentInlineStyle()
      setToolbarState({
        bold: inlineStyle.has('BOLD'),
        italic: inlineStyle.has('ITALIC'),
        underline: inlineStyle.has('UNDERLINE'),
        strikethrough: inlineStyle.has('STRIKETHROUGH'),
        blockquote: currentContentBlock.getType() === 'blockquote',
        ul: currentContentBlock.getType() === 'unordered-list-item',
        ol: currentContentBlock.getType() === 'ordered-list-item'
      })
    },
    [setEditorState, setToolbarState]
  )

  const resetContent = () => {
    const removeSelectedBlocksStyle = (editorState: EditorState) => {
      const newContentState = RichUtils.tryToRemoveBlockStyle(editorState)
      if (newContentState) {
        return EditorState.push(editorState, newContentState, 'change-block-type')
      }
      return editorState
    }

    const blocks = editorState.getCurrentContent().getBlockMap().toList()
    const updatedSelection = editorState.getSelection().merge({
      anchorKey: blocks.first().get('key'),
      anchorOffset: 0,
      focusKey: blocks.last().get('key'),
      focusOffset: blocks.last().getLength()
    })
    const newContentState = Modifier.removeRange(editorState.getCurrentContent(), updatedSelection, 'forward')

    const newState = EditorState.push(editorState, newContentState, 'remove-range')
    setEditorState(removeSelectedBlocksStyle(newState))
  }

  return (
    <div className="ui-comment-input" style={{ ...style, height, minHeight: height, maxHeight: height }}>
      <div
        className="ui-comment-input-editor"
        style={{ height: height - 50, minHeight: height - 50, maxHeight: height - 50 }}
        role="button"
        tabIndex={-1}
        onKeyDown={() => {}}
        onClick={() => {
          if (!editorRef.current) return
          editorRef.current.focus()
        }}
      >
        <Editor
          ref={editorRef}
          editorState={editorState}
          onChange={change}
          handleReturn={(e, editorState) => {
            if (e.shiftKey) return 'not-handled'

            const selectionState = editorState.getSelection()
            const anchorKey = selectionState.getAnchorKey()
            const currentContent = editorState.getCurrentContent()
            const currentContentBlock = currentContent.getBlockForKey(anchorKey)
            if (currentContentBlock.getType() === 'unordered-list-item' || currentContentBlock.getType() === 'ordered-list-item') {
              return 'not-handled'
            }

            onSend(convertToRaw(currentContent), currentContent.getPlainText())
            resetContent()
            return 'handled'
          }}
          // placeholder={t('General.TypeMessage')}
          handleKeyCommand={(command, editorState) => {
            const newState = RichUtils.handleKeyCommand(editorState, command)
            if (newState) {
              change(newState)
              return 'handled'
            }
            return 'not-handled'
          }}
        />
      </div>

      <div className="ui-comment-input-toolbar">
        <div
          className={toolbarState.bold ? ' active' : ''}
          role="button"
          tabIndex={-1}
          onKeyDown={() => {}}
          onMouseDown={(e) => {
            e.preventDefault()
            const newState = RichUtils.toggleInlineStyle(editorState, 'BOLD')
            if (newState) change(newState)
          }}
        >
          <FontAwesomeIcon icon={faBold} />
        </div>
        <div
          className={toolbarState.italic ? ' active' : ''}
          role="button"
          tabIndex={-1}
          onKeyDown={() => {}}
          onMouseDown={(e) => {
            e.preventDefault()
            const newState = RichUtils.toggleInlineStyle(editorState, 'ITALIC')
            if (newState) change(newState)
          }}
        >
          <FontAwesomeIcon icon={faItalic} />
        </div>
        <div
          className={toolbarState.underline ? ' active' : ''}
          role="button"
          tabIndex={-1}
          onKeyDown={() => {}}
          onMouseDown={(e) => {
            e.preventDefault()
            const newState = RichUtils.toggleInlineStyle(editorState, 'UNDERLINE')
            if (newState) change(newState)
          }}
        >
          <FontAwesomeIcon icon={faUnderline} />
        </div>
        <div
          className={toolbarState.strikethrough ? ' active' : ''}
          role="button"
          tabIndex={-1}
          onKeyDown={() => {}}
          onMouseDown={(e) => {
            e.preventDefault()
            const newState = RichUtils.toggleInlineStyle(editorState, 'STRIKETHROUGH')
            if (newState) change(newState)
          }}
        >
          <FontAwesomeIcon icon={faStrikethrough} />
        </div>
        <div
          className={toolbarState.blockquote ? ' active' : ''}
          role="button"
          tabIndex={-1}
          onKeyDown={() => {}}
          onMouseDown={(e) => {
            e.preventDefault()
            const newState = RichUtils.toggleBlockType(editorState, 'blockquote')
            if (newState) change(newState)
          }}
        >
          <FontAwesomeIcon icon={faQuoteRight} />
        </div>
        <div
          className={toolbarState.ul ? ' active' : ''}
          role="button"
          tabIndex={-1}
          onKeyDown={() => {}}
          onMouseDown={(e) => {
            e.preventDefault()
            const newState = RichUtils.toggleBlockType(editorState, 'unordered-list-item')
            if (newState) change(newState)
          }}
        >
          <FontAwesomeIcon icon={faListUl} />
        </div>
        <div
          className={toolbarState.ol ? ' active' : ''}
          role="button"
          tabIndex={-1}
          onKeyDown={() => {}}
          onMouseDown={(e) => {
            e.preventDefault()
            const newState = RichUtils.toggleBlockType(editorState, 'ordered-list-item')
            if (newState) change(newState)
          }}
        >
          <FontAwesomeIcon icon={faListOl} />
        </div>
        <div
          style={{ marginLeft: 'auto' }}
          role="button"
          tabIndex={-1}
          onKeyDown={() => {}}
          onMouseDown={(e) => {
            e.preventDefault()
            onSend(convertToRaw(editorState.getCurrentContent()), editorState.getCurrentContent().getPlainText())
            setEditorState(EditorState.createEmpty())
          }}
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </div>
      </div>
    </div>
  )
}
export default CommentInput
