import React, { useEffect, useMemo, useState } from 'react'
import { Account, AccountStatus, AccountType } from 'services/src/dto/account'
import { TextField } from '../../textField'
import { createAccount, getAccountById, updateAccount } from 'services/src/api'
import { OverlayModal } from '../layout/OverlayModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { TextAreaField } from '../../textAreaField'
import moment from 'moment'
import { SelectField } from '../../selectField'
import { NumberField } from '../../numberField/NumberField'
import { Loading } from '../../loading'
import { MultiSelectField, MultiSelectItem } from '../../multiSelectField'

export const EditAccount = () => {
  const {
    params: { id }
  } = useRouteMatch<{ id: string }>()
  const history = useHistory()

  const [saving, setSaving] = useState<boolean>(false)
  const [workAccount, setWorkAccount] = useState<Account>()
  const [workStatus, setWorkStatus] = useState<any>()

  const statusItems = useMemo(() => {
    return [
      {
        id: AccountStatus.New,
        label: workAccount?.type === AccountType.Provider ? 'Pending Approval' : 'New'
      },
      {
        id: AccountStatus.Ready,
        label: 'Active'
      },
      {
        id: AccountStatus.Closed,
        label: 'Closed'
      },
    ]
  }, [workAccount])

  const [appItems, setAppItems] = useState<MultiSelectItem[]>([{
    label: 'ProEst',
    selected: true
  }])

  const [errors, setErrors] = useState<any>({})

  const accountType = useMemo(() => [{
    id: 'client',
    label: 'Client',
  }, {
    id: 'expert',
    label: 'Expert',
  }, {
    id: 'provider',
    label: 'Provider',
  }], [])

  const [selAccountType, setSelAccountType] = useState(accountType[0])

  const initState = (a: Account) => {
    setWorkAccount(a)
    setWorkStatus(statusItems.find((x) => x.id === a.status) ?? statusItems[0])

    const sel = accountType.find((x) => x.id === a.type)
    setSelAccountType(sel || accountType[0])

    const newAppItems: MultiSelectItem[] = [{
      label: 'ProEst',
      selected: !!a.estimateApps?.find(a => a.type === 'ProEst')
    }];
    setAppItems(newAppItems)
  }

  useEffect(() => {
    if (id === 'new') {
      setWorkAccount({
        id: '$new$',
        companyName: '',
        tagLine: '',
        overview: '',
        type: AccountType.Client,
        status: AccountStatus.New,
        companyType: '',
      })
      setTimeout(() => setWorkStatus(statusItems[0]))
      return
    }
    getAccountById(id).then((a) => {
      initState(a)
    })
  }, [id])

  useEffect(() => {
    if (!workAccount) return
    setWorkStatus(statusItems.find((x) => x.id === workAccount.status) ?? statusItems[0])
  }, [workAccount, statusItems])

  const handleChange = (property: string, value: string) => {
    const newWorkAccount = { ...workAccount } as any
    newWorkAccount[property] = value
    setWorkAccount(newWorkAccount)

    const newErrors = { ...errors }
    delete newErrors[property]
    setErrors(newErrors)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (!workAccount) return

    const newErrors: any = {}

    if (!workAccount.companyName) newErrors.companyName = 'Required'

    setErrors(newErrors)
    if (Object.keys(newErrors).length) return

    const account = {...workAccount}
    account.type = selAccountType.id as AccountType
    account.estimateApps = appItems.filter(a => a.selected === true).map(a => ({ type: a.label }))

    setSaving(true)
    if (workAccount.id === '$new$') {
      createAccount(account)
        .then(({account}) => {
          initState(account)
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => setSaving(false))
      return
    }

    updateAccount(account)
      .then(account => {
        initState(account)
      })
      .catch(e => {
        console.error(e)
      })
      .finally(() => setSaving(false))
  }

  return (
    <OverlayModal>
      {saving && <Loading size="md" />}
      {workAccount && (
        <>
          <button className="close" onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h1>{workAccount.id === '$new$' ? 'Create' : 'Edit'} Account</h1>

          {workAccount.id !== '$new$' && (
            <>
              {workAccount.type === AccountType.Client && (
                <div className="ui-badge" style={{ width: 150 }}>
                  CLIENT ACCOUNT
                </div>
              )}
              {workAccount.type === AccountType.Expert && (
                <div className="ui-badge" style={{ width: 150 }}>
                  EXPERT ACCOUNT
                </div>
              )}
              {workAccount.type === AccountType.Provider && (
                <div className="ui-badge" style={{ width: 150 }}>
                  PROVIDER ACCOUNT
                </div>
              )}
            </>
          )}
          <br />
          <br />

          <form onSubmit={handleSubmit}>
            <div className="ui-flex">
              <div style={{ flex: 0.8 }}>
                {workAccount.id === '$new$' && (
                  <SelectField
                    items={accountType}
                    selected={selAccountType}
                    onSelectedChange={(at) => setSelAccountType(at)}
                    label="Account Type"/>
                )}

                <TextField
                  name="companyName"
                  label="Company Name"
                  placeholder="Company name"
                  onChange={(v) => handleChange('companyName', v)}
                  value={workAccount.companyName}
                />
                <TextField name="tagLine" label="Tag Line" placeholder="Company tag line" onChange={(v) => handleChange('tagLine', v)} value={workAccount.tagLine} />
                <TextAreaField name="overview" label="Overview" placeholder="Company overview" rows={10} onChange={(v) => handleChange('overview', v)} value={workAccount.overview!} />
              </div>

              <div style={{ flex: 0.2, marginLeft: 50, minWidth: 200 }}>
                <div className="ui-flex">
                  <button type="submit" className="ui-btn ui-btn-primary" style={{ flex: 1 }}>
                    {workAccount.id === '$new$' ? 'Create Account' : 'Update Account'}
                  </button>
                  <button type="button" className="ui-btn ui-btn-secondary" style={{ marginLeft: 15, flex: 1 }} onClick={() => history.goBack()}>
                    Cancel
                  </button>
                </div>
                <br />
                <hr />
                <br />

                <SelectField
                  label="Account Status"
                  items={statusItems}
                  labelField="label"
                  selected={workStatus}
                  onSelectedChange={(v) => {
                    setWorkStatus(v)
                    setWorkAccount({ ...workAccount, status: v.id })
                  }}
                />

                {workAccount.type === AccountType.Provider && (
                  <>
                    <NumberField
                      name="providerFee"
                      label="Technology Fee per Phase"
                      placeholder="Technology fee"
                      digits={0}
                      onChange={(v) => {
                        const x = { ...workAccount }
                        x.providerFee = { phaseFee: v ?? 0 }
                        setWorkAccount(x)
                      }}
                      value={workAccount.providerFee?.phaseFee ?? 0}
                    />

                    <MultiSelectField
                      label="Estimation Applications"
                      className="ui-multi-select-no-highlight"
                      items={appItems}
                      onToggle={(item) => {
                        const newAppItems = [...appItems]
                        const ax = newAppItems.find(a => a.label === item.label)
                        if (ax) ax.selected = !ax.selected
                        setAppItems(newAppItems)
                      }}
                    />
                  </>
                )}

                <div>
                  <label>Created At</label>
                  <div>{moment(workAccount.createdAt).format('M/D/YYYY h:mm a')}</div>
                </div>
                <br />
                <div>
                  <label>Updated At</label>
                  <div>{moment(workAccount.updatedAt).format('M/D/YYYY h:mm a')}</div>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </OverlayModal>
  )
}
