import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import Fuse from 'fuse.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartUser, faCompassDrafting, faMapMarkerAlt, faPlusCircle } from '@fortawesome/pro-regular-svg-icons'

import { BudgetRange, ConstructionType, DeliveryMethod, EstimateType, ProcurementMethod } from 'services/src/dto/projectSupport'
import { ProjectType } from 'services/src/dto/projectShare'
import { Address, Client, Office } from 'services/src/dto/account'

import { AutoComplete } from 'components/src/autoComplete'
import { AutoCompleteField } from 'components/src/autoCompleteField'
import { ProjectEx, useCurrentUserAccount, useEditProject, useGeolocation, useRights, UserType } from 'services/src/state'
import { BudgetField } from 'components/src/budgetField'
import { TextField } from 'components/src/textField'
import { SelectField } from 'components/src/selectField'
import { deleteProject, lookupAddress, useProjectSupport } from 'services/src/api'
import { TextAreaField } from 'components/src/textAreaField'
import { ProjectContent } from 'services/src/dto/content'
import { Patch } from 'services/src/dto/common'
import { makeDashboardPath } from 'services/src/dom'
import { ProjectStatus } from 'services/src/dto/project'
import { ConfirmModal } from 'components/src/modal/ConfirmModal'
import { useAlert } from 'components/src/alerts'
import { useSaving } from 'components/src/saving'

import { Footer } from './Footer'
import { NewClient } from '../new/NewClient'

interface DetailsViewModel {
  clientName: string
  clientSuggestions: Client[]
  client?: Client

  office?: Office

  locationName: string
  locationSuggestions: any[]
  location?: Address

  projectTypeName: string
  projectTypeSuggestions: ProjectType[]
  projectType?: ProjectType

  constructionType?: ConstructionType
  deliveryMethod?: DeliveryMethod
  procurementMethod?: ProcurementMethod
  estimateType?: EstimateType

  isFixedBudget: boolean
  fixed?: number
  range?: BudgetRange

  projectNumber: string
  taskOrderNumber: string

  name: string
  description: string

  drawing?: ProjectContent
  documents?: ProjectContent[]
  photos?: ProjectContent[]

  newProjectType?: ProjectType
  newClient?: Client
}

export const ProjectDetails: React.FC = () => {
  const { t } = useTranslation()
  const { country_code: geo } = useGeolocation()
  const history = useHistory()
  const { alertDanger, alertSuccess } = useAlert()

  const [account] = useCurrentUserAccount()
  const [{ projectTypes, budgetRanges, constructionTypes, procurementMethods, deliveryMethods, estimateTypes }] = useProjectSupport()
  const { project, patch } = useEditProject()
  const rights = useRights()
  const [saving, setSaving] = useSaving()

  const [errors, setErrors] = useState<any>({})
  const clearError = (name: string) => {
    const theErrors = { ...errors }
    delete theErrors[name]
    setErrors(theErrors)
  }

  const initVm = (project: ProjectEx) => {
    const vm: DetailsViewModel = {
      clientName: project.client?.name || '',
      clientSuggestions: [],
      client: project.client,

      office: project.office,

      locationSuggestions: [],
      locationName: project.location?.freeformAddress || '',
      location: project.location,

      projectType: project.projectType,
      projectTypeName: project.projectType?.type || '',
      projectTypeSuggestions: [],

      constructionType: constructionTypes.find((x) => x.id === project.constructionType),
      deliveryMethod: deliveryMethods.find((x) => x.id === project.deliveryMethod),
      procurementMethod: procurementMethods.find((x) => x.id === project.procurementMethod),
      estimateType: estimateTypes.find((x) => x.id === project.estimateType),

      isFixedBudget: project.budget?.isFixed === true,
      fixed: project.budget?.fixed || 0,
      range: budgetRanges.find((x) => x.id === project.budget?.range),

      projectNumber: project.projectNumber || '',
      taskOrderNumber: project.taskOrderNumber || '',

      name: project.name || '',
      description: project.description || ''
    }
    return vm
  }

  const [vm, setVm] = useState<DetailsViewModel>(() => initVm(project))

  const [newClient, setNewClient] = useState(false)

  useEffect(() => setVm(initVm(project)), [project, setVm])

  const validate = useCallback((vm: DetailsViewModel) => {
    const errors: any = []

    // if (!client) errors.client = t('General.Errors.Required');

    if (!vm.isFixedBudget) {
      if (!vm.range) errors.budget = t('General.Errors.Required')
    } else if (!vm.fixed) errors.budget = t('General.Errors.NumericValueRequired')
    if (!vm.procurementMethod) errors.procurementMethod = t('General.Errors.Required')

    if (!vm.name) errors.name = t('General.Errors.Required')
    else if (vm.name.length < 3 || vm.name.length > 255) errors.name = t('General.Errors.BetweenNAndMCharacters', { N: 3, M: 255 })
    if (!vm.office) errors.office = t('General.Errors.Required')
    if (!vm.projectType && !vm.projectTypeName) errors.projectType = t('General.Errors.Required')
    else if (!vm.projectType) errors.projectType = t('General.Errors.SelectOrAdd')
    if (!vm.location || !vm.location.freeformAddress) errors.location = t('General.Errors.Required')
    if (!vm.constructionType) errors.constructionType = t('General.Errors.Required')
    if (!vm.deliveryMethod) errors.deliveryMethod = t('General.Errors.Required')
    if (!vm.estimateType) errors.estimateType = t('General.Errors.Required')

    return errors
  }, [])

  const submit = useCallback(
    (e?: FormEvent, noPatch?: boolean): Patch[] | undefined => {
      if (e) e.preventDefault()

      const errors = validate(vm)
      setErrors(errors)
      if (Object.keys(errors).length > 0) return undefined

      const p: Patch[] = []

      if (vm.newClient) p.push({ propertyName: 'Client', propertyValue: vm.newClient })
      else if (vm.client && vm.client.id !== project.client?.id) p.push({ propertyName: 'ClientId', propertyValue: vm.client.id })

      if (vm.office && vm.office.id !== project.office?.id) p.push({ propertyName: 'OfficeId', propertyValue: vm.office.id })
      if (vm.location) p.push({ propertyName: 'Location', propertyValue: vm.location })
      if (vm.projectType) p.push({ propertyName: 'ProjectTypeId', propertyValue: vm.projectType.id })

      if (vm.constructionType) p.push({ propertyName: 'ConstructionType', propertyValue: vm.constructionType.id })
      if (vm.deliveryMethod) p.push({ propertyName: 'DeliveryMethod', propertyValue: vm.deliveryMethod.id })
      if (vm.procurementMethod) p.push({ propertyName: 'ProcurementMethod', propertyValue: vm.procurementMethod.id })
      if (vm.estimateType) p.push({ propertyName: 'EstimateType', propertyValue: vm.estimateType.id })

      if (vm.name !== project.name) p.push({ propertyName: 'Name', propertyValue: vm.name })
      if (vm.description !== project.description) p.push({ propertyName: 'Description', propertyValue: vm.description || null })
      if (vm.projectNumber !== project.projectNumber) p.push({ propertyName: 'ProjectNumber', propertyValue: vm.projectNumber || null })
      if (vm.taskOrderNumber !== project.taskOrderNumber) p.push({ propertyName: 'TaskOrderNumber', propertyValue: vm.taskOrderNumber || null })

      if (vm.isFixedBudget) p.push({ propertyName: 'Budget.Fixed', propertyValue: vm.fixed })
      else p.push({ propertyName: 'Budget.Range', propertyValue: vm.range?.id })

      if (noPatch) return p

      patch(p, true).then((p) => history.replace(makeDashboardPath(account?.id, `projects/${p.id}`)))

      return undefined
    },
    [vm, setErrors, history, patch]
  )

  const submitProject = useCallback(() => {
    const errors = validate(vm)
    setErrors(errors)
    if (Object.keys(errors).length > 0) return

    if (!project.phases.length) {
      alertDanger({
        title: t('EditProject.Errors.NoPhasesTitle'),
        message: t('EditProject.Errors.NoPhases')
      })
      return
    }

    const p = submit(undefined, true)
    if (!p) return

    p.push({ propertyName: 'Status', propertyValue: ProjectStatus.Submitted })
    patch(p, true).then(() => {})
  }, [vm, setErrors, project, t])

  const [confirmDelete, setConfirmDelete] = useState(false)

  const handleDelete = useCallback(() => {
    setConfirmDelete(false)
    if (!project.account.id || !project.id) return

    setSaving(true)
    deleteProject(project.account.id, project.id)
      .then(() => {
        alertSuccess({
          title: t('EditProject.DeleteProject.SuccessTitle'),
          message: t('EditProject.DeleteProject.Success', { name: project.name })
        })
        history.replace(makeDashboardPath(account?.id, 'projects'))
      })
      .catch((err) => {
        alertDanger({
          title: t('EditProject.DeleteProject.FailedTitle'),
          message: t('EditProject.DeleteProject.Failed'),
          error: err
        })
      })
      .finally(() => setSaving(false))
  }, [project, t])

  return (
    <>
      <form noValidate onSubmit={submit}>
        <div className="edit-project-details">
          <div className="ui-row">
            <div className="ui-col-12">
              <TextField
                name="name"
                value={vm.name}
                error={errors.name}
                label={t('EditProject.Details.Name')}
                placeholder={t('EditProject.Details.Name')}
                disabled={saving}
                onChange={(name: string) => {
                  clearError('name')
                  setVm((vm) => ({ ...vm, name }))
                }}
              />
            </div>

            {/*rights?.userType === UserType.client && (
              <div className="ui-col-12 ui-col-lg-3 ui-form-group">
                <label>{t('General.Provider')}</label>
                {project.provider?.companyName ? (
                  <div className="ui-flex ui-flex-nowrap ui-frame ui-frame-bg" style={{ height: 37, lineHeight: '35px', padding: '0 10px', alignItems: 'center' }}>
                    {project.provider.logoUrl && (
                      <div
                        className="account-logo"
                        style={{
                          backgroundImage: `url('${project.provider.logoUrl}')`,
                          marginRight: 10
                        }}
                      />
                    )}
                    <div className="ui-text-muted ui-text-ellipsis">{project.provider.companyName}</div>
                  </div>
                ) : (
                  <div className="ui-text-muted ui-text-ellipsis">{t('General.NA')}</div>
                )}
              </div>
            )*/}

            <div className="ui-col-12 ui-flex" style={{ flexWrap: 'wrap' }}>
              {account?.id !== project.account?.id && (
                <div className="ui-form-group" style={{ marginRight: 20, minWidth: 150 }}>
                  <label>{t('General.Account')}</label>
                  {project.account?.companyName ? (
                    <div className="ui-flex ui-flex-nowrap ui-frame ui-frame-bg" style={{ height: 37, lineHeight: '35px', padding: '0 10px', alignItems: 'center' }}>
                      {project.account.logoUrl && (
                        <div
                          className="account-logo"
                          style={{
                            backgroundImage: `url('${project.account.logoUrl}')`,
                            marginRight: 10
                          }}
                        />
                      )}
                      <div className="ui-text-muted">{project.account.companyName}</div>
                    </div>
                  ) : (
                    <div>{t('General.NA')}</div>
                  )}
                </div>
              )}

              <div style={{ flex: 0.75 }}>
                <SelectField
                  style={{ width: '100%' }}
                  items={project.account.offices || []}
                  selected={vm.office}
                  renderItem={(x) => <div>{x.name}</div>}
                  renderSelected={(x) => x.name}
                  placeholder={t('EditProject.Details.Office')}
                  label={t('EditProject.Details.Office')}
                  disabled={saving}
                  error={errors.office}
                  onSelectedChange={(office) => {
                    clearError('office')
                    setVm((vm) => ({ ...vm, office }))
                  }}
                />
              </div>

              <div style={{ flex: 1, marginLeft: 20 }}>
                <AutoCompleteField
                  suggestions={vm.locationSuggestions}
                  value={vm.locationName}
                  label={t('EditProject.Details.Location')}
                  placeholder={t('EditProject.Details.Location')}
                  error={errors.location}
                  disabled={saving}
                  onValueChange={(locationName) => {
                    clearError('location')

                    setVm((vm) => ({
                      ...vm,
                      location: { freeformAddress: locationName },
                      locationName,
                      locationSuggestions: []
                    }))
                    if (!locationName) return

                    lookupAddress(locationName, geo ? geo.country_code : 'US', 'Geo').then((locationSuggestions) => setVm((vm) => ({ ...vm, locationSuggestions })))
                  }}
                  onSelectSuggestion={(location: Address) => {
                    clearError('location')
                    setVm((vm) => ({
                      ...vm,
                      location,
                      locationName: location.freeformAddress || '',
                      locationSuggestions: []
                    }))
                  }}
                  render={(address: Address) => (
                    <div>
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                      &nbsp;&nbsp;{address.freeformAddress}
                    </div>
                  )}
                />
              </div>
            </div>

            <div className="ui-col-12">
              <div className={`ui-form-group ${errors.client ? 'ui-has-error' : ''}`}>
                <label>{t('EditProject.Details.Client')}</label>
                <div className="ui-item-group">
                  <AutoComplete
                    suggestions={vm.clientSuggestions}
                    value={vm.clientName}
                    disabled={saving}
                    onValueChange={(clientName) => {
                      clearError('client')
                      const vmNew: DetailsViewModel = { ...vm, clientName, newClient: undefined }
                      if (!clientName) vmNew.clientSuggestions = []
                      else if (project.account?.clients) {
                        const fuse = new Fuse(project.account.clients, { keys: ['name'] })
                        const result = fuse.search(clientName)
                        vmNew.clientSuggestions = result.length > 0 || clientName.length > 1 ? result.map((x) => x.item) : project.account.clients
                      }
                      setVm(vmNew)
                    }}
                    placeholder={t('EditProject.Details.ClientLookup')}
                    onSelectSuggestion={(client: Client) => {
                      clearError('client')

                      setVm((vm) => ({
                        ...vm,
                        client,
                        clientName: client.name,
                        clientSuggestions: []
                      }))
                    }}
                    render={(client: Client) => (
                      <div>
                        <FontAwesomeIcon icon={faChartUser} /> {client.name}
                      </div>
                    )}
                    style={{ width: '100%' }}
                  />

                  <button className="ui-btn ui-btn-primary" type="button" style={{ width: 150 }} disabled={saving} onClick={() => setNewClient(true)}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    <span style={{ marginLeft: 5 }}>{t('NewProject.Details.AddClient')}</span>
                  </button>
                </div>
                {errors.client && <div className="ui-error">{errors.client}</div>}
              </div>
            </div>

            <div className="ui-col-12 ui-col-lg-6">
              <BudgetField
                isFixed={vm.isFixedBudget}
                fixed={vm.fixed}
                range={vm.range}
                disabled={saving}
                onTypeChange={(isFixedBudget) => {
                  clearError('budget')
                  setVm((vm) => ({ ...vm, isFixedBudget }))
                }}
                name="budget"
                label={t('EditProject.Details.Budget')}
                error={errors.budget}
                placeholder={t(vm.isFixedBudget ? 'NewProject.Details.BudgetFixedSample' : 'NewProject.Details.BudgetRangeSample')}
                budgetRanges={budgetRanges}
                onChange={(value) => {
                  clearError('budget')
                  let { fixed } = vm
                  let { range } = vm
                  const isFixedBudget = value.isFixed
                  if (value.isFixed) fixed = value.fixed
                  else range = value.range

                  setVm((vm) => ({ ...vm, isFixedBudget, fixed, range }))
                }}
              />
            </div>

            <div className="ui-col-12 ui-col-lg-6">
              <SelectField
                items={estimateTypes}
                selected={vm.estimateType}
                valueField="id"
                labelField="type"
                disabled={saving}
                onSelectedChange={(estimateType) => {
                  clearError('estimateType')
                  setVm((vm) => ({ ...vm, estimateType }))
                }}
                label={t('EditProject.Details.EstimateType')}
                placeholder={t('EditProject.Details.EstimateType')}
                error={errors.procurementMethod}
              />
            </div>
          </div>

          <div className="ui-row">
            <div className="ui-col-12 ui-col-lg-4">
              <TextField
                name="projectNumber"
                value={vm.projectNumber}
                label={t('EditProject.Details.ProjectNumber')}
                disabled={saving}
                placeholder={t('EditProject.Details.ProjectNumberSample')}
                error={errors.projectNumber}
                onChange={(projectNumber) => {
                  clearError('projectNumber')
                  setVm((vm) => ({ ...vm, projectNumber }))
                }}
              />
            </div>

            <div className="ui-col-12 ui-col-lg-4">
              <TextField
                name="taskOrderNumber"
                value={vm.taskOrderNumber}
                label={t('EditProject.Details.OrderNumber')}
                placeholder={t('EditProject.Details.OrderNumberSample')}
                disabled={saving}
                error={errors.orderNumber}
                onChange={(taskOrderNumber) => {
                  clearError('taskOrderNumber')
                  setVm((vm) => ({ ...vm, taskOrderNumber }))
                }}
              />
            </div>

            <div className="ui-col-12 ui-col-lg-4">
              <SelectField
                items={procurementMethods}
                selected={vm.procurementMethod}
                valueField="id"
                labelField="method"
                disabled={saving}
                onSelectedChange={(procurementMethod) => {
                  clearError('procurementMethod')
                  setVm((vm) => ({ ...vm, procurementMethod }))
                }}
                label={t('EditProject.Details.ProcurementMethod')}
                placeholder={t('EditProject.Details.ProcurementMethodSample')}
                error={errors.procurementMethod}
              />
            </div>
          </div>

          <div className="ui-row">
            <div className="ui-col-12 ui-col-lg-4">
              <AutoCompleteField
                suggestions={vm.projectTypeSuggestions}
                value={vm.projectTypeName}
                placeholder={t('EditProject.Details.ProjectType')}
                label={
                  <div className="ui-flex ui-flex-nowrap">
                    <div>{t('EditProject.Details.ProjectType')}</div>
                    {vm.projectType?.category === 99 && rights?.canClassifyProjectType && (
                      <Link to={makeDashboardPath(account?.id, '')} className="ui-secondary" style={{ marginLeft: 'auto' }}>
                        {t('General.CustomProjectType')}
                      </Link>
                    )}
                  </div>
                }
                error={errors.projectType}
                disabled={saving}
                onValueChange={(projectTypeName) => {
                  clearError('projectType')

                  const projectType = projectTypes.find((x) => x.type.toLowerCase() === projectTypeName.toLowerCase())
                  setVm((vm) => ({ ...vm, projectType, projectTypeName, projectTypeSuggestions: [] }))
                  if (!projectTypeName) return

                  if (projectTypes) {
                    const fuse = new Fuse(projectTypes, { keys: ['type'] })
                    const result = fuse.search(projectTypeName)
                    setVm((vm) => ({ ...vm, projectTypeSuggestions: result.map((x) => x.item) }))
                  }
                }}
                onSelectSuggestion={(projectType) => {
                  clearError('projectType')
                  setVm((vm) => ({
                    ...vm,
                    projectType,
                    projectTypeName: projectType.type,
                    projectTypeSuggestions: []
                  }))
                }}
                render={(item: ProjectType) => (
                  <div>
                    <FontAwesomeIcon icon={faCompassDrafting} />
                    &nbsp;&nbsp;{item.type}
                  </div>
                )}
              />
            </div>
            <div className="ui-col-12 ui-col-lg-5">
              <SelectField
                items={deliveryMethods || []}
                disabled={saving}
                selected={vm.deliveryMethod}
                labelField="method"
                valueField="id"
                placeholder={t('EditProject.Details.DeliveryMethodSample')}
                label={t('EditProject.Details.DeliveryMethod')}
                error={errors.deliveryMethod}
                onSelectedChange={(deliveryMethod) => {
                  clearError('deliveryMethod')
                  setVm((vm) => ({ ...vm, deliveryMethod }))
                }}
              />
            </div>
            <div className="ui-col-12 ui-col-lg-3">
              <SelectField
                items={constructionTypes}
                selected={vm.constructionType}
                valueField="id"
                labelField="type"
                disabled={saving}
                onSelectedChange={(constructionType) => {
                  clearError('constructionType')
                  setVm((vm) => ({ ...vm, constructionType }))
                }}
                label={t('EditProject.Details.ConstructionType')}
                placeholder={t('EditProject.Details.ConstructionType')}
                error={errors.constructionType}
              />
            </div>
          </div>

          <TextAreaField
            className="ui-text-sm"
            name="description"
            value={vm.description || ''}
            label={t('EditProject.Details.Description')}
            error={errors.description}
            placeholder={t('EditProject.Details.Description')}
            disabled={saving}
            rows={8}
            onChange={(description) => {
              clearError('description')
              setVm((vm) => ({ ...vm, description }))
            }}
          />
        </div>

        <Footer>
          <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center' }}>
            <button type="button" className="ui-btn ui-btn-primary ui-btn-solid" disabled={saving} onClick={() => history.goBack()}>
              {t('General.Exit')}
            </button>
            {project.canDelete && (
              <button type="button" disabled={saving} onClick={() => setConfirmDelete(true)} style={{ marginLeft: 10, width: 150 }} className="ui-btn ui-btn-danger ui-btn-solid">
                {t('EditProject.DeleteProject.Title')}
              </button>
            )}

            <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center', marginLeft: 'auto' }}>
              {project.status === ProjectStatus.Draft && (
                <button type="button" disabled={saving} style={{ marginRight: 10, width: 150 }} className="ui-btn ui-btn-info ui-btn-solid" onClick={submitProject}>
                  {t('General.SubmitProject')}
                </button>
              )}

              <button type="submit" disabled={saving} className="ui-btn ui-btn-secondary ui-btn-solid">
                {t('General.Save')}
              </button>
            </div>
          </div>
        </Footer>
      </form>

      {newClient && (
        <NewClient
          client={vm.newClient}
          onNew={(newClient: Client) => {
            setNewClient(false)
            setVm((vm) => ({
              ...vm,
              newClient,
              clientName: newClient.name,
              clientSuggestions: []
            }))
          }}
          onClose={() => setNewClient(false)}
        />
      )}

      {confirmDelete && (
        <ConfirmModal
          title={t('EditProject.DeleteProject.Title')}
          message={
            <div
              dangerouslySetInnerHTML={{
                __html: t('EditProject.DeleteProject.Confirm', {
                  name: project.name,
                  interpolation: { escapeValue: false }
                })
              }}
            />
          }
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={handleDelete}
          onClose={() => setConfirmDelete(false)}
        />
      )}
    </>
  )
}
