import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { faDraftingCompass, faUserChart } from '@fortawesome/pro-regular-svg-icons'
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'

import { ResourceUserRole } from 'services/src/dto/account'
import { Invitation, InvitationStatus } from 'services/src/dto/invitation'
import { ResourceUsers, UserEx } from 'components/src/resourceUsers'
import { ChangeRoleModal } from 'components/src/resourceUsers/ChangeRoleModal'
import { InfoModal } from 'components/src/modal/InfoModal'
import { ConfirmModal } from 'components/src/modal/ConfirmModal'
import { Loading } from 'components/src/loading'
import { ProjectEx, useCurrentUserAccount, useEditProject, useRights, UserType, useUserAccounts } from 'services/src/state'
import { ProjectStatus } from 'services/src/dto/project'
import { deleteInvitation, deleteProjectUser, resendInvitation, updateProjectUserRole } from 'services/src/api'
import { useSaving } from 'components/src/saving'
import { useAlert } from 'components/src/alerts'
import { TabBar, TabItem } from 'components/src/tabs'
import { InvitationList } from '../../settings/InvitationList'
import { InviteModal } from '../common/InviteModal'

export const ProjectStaff: React.FC = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { url, path } = useRouteMatch()
  const rights = useRights()
  const [, refreshAccounts] = useUserAccounts()
  const [account] = useCurrentUserAccount()
  const [, setSaving] = useSaving()
  const { alertDanger, alertSuccess } = useAlert()

  const { project, setProject } = useEditProject()

  const roles = useMemo(
    () => [
      {
        role: ResourceUserRole.Owner,
        label: t(`General.ProjectRoles.${ResourceUserRole.Owner}`)
      },
      {
        role: ResourceUserRole.Administrator,
        label: t(`General.ProjectRoles.${ResourceUserRole.Administrator}`)
      },
      {
        role: ResourceUserRole.Contributor,
        label: t(`General.ProjectRoles.${ResourceUserRole.Contributor}`)
      },
      {
        role: ResourceUserRole.Guest,
        label: t(`General.ProjectRoles.${ResourceUserRole.Guest}`)
      }
    ],
    [t]
  )

  const [changeRole, setChangeRole] = useState<UserEx>()
  const [confirmDeleteUser, setConfirmDeleteUser] = useState<UserEx>()
  const [info, setInfo] = useState<{ title: string; message: string }>()
  const [invite, setInvite] = useState<boolean>(false)

  const readyUsers = useMemo(() => {
    const users: UserEx[] = (project.users || []).map((x) => {
      const pu = project.providerAccountWithUsers.users.find((pu) => pu.id === x.id)

      return {
        ...x,
        canEdit: true,
        roleLabel: pu ? t(`General.ProviderRoles.${x.role}`) : t(`General.ClientProjectRoles.${x.role}`),
        userIcon: pu ? faDraftingCompass : faUserChart
      }
    })

    project.phases.forEach((ph) => {
      ph.users?.forEach((x) => {
        if (users.find((u) => u.id === x.id)) return
        users.push({
          ...x,
          roleLabel: t(`General.ProjectRoles.${x.role}`),
          userIcon: faUserChart,
          isLower: true
        })
      })

      if (rights?.userType === UserType.provider) {
        ph.estimates?.forEach((est) => {
          est.labor.forEach((l) => {
            if (!l.user) return

            const u = users.find((u) => u.id === l.user!.id)
            if (!u) {
              users.push({
                ...l.user,
                roleLabel: t(`General.ProviderRoles.${l.user.role}`),
                badge: t(`General.Disciplines.${est.discipline}`),
                userIcon: faDraftingCompass,
                disciplines: [t(`General.Disciplines.${est.discipline}`)],
                isLower: true
              })
            } else {
              u.isInherited = false
              u.roleLabel = t(`General.ProviderRoles.${l.user.role}`)
              u.badge = est ? t(`General.Disciplines.${est.discipline}`) : undefined
              u.userIcon = faDraftingCompass

              if (!u.disciplines) u.disciplines = []
              const d = t(`General.Disciplines.${est.discipline}`)
              if (!u.disciplines!.find((d2) => d2 === d)) u.disciplines!.push(t(`General.Disciplines.${est.discipline}`))
            }
          })
        })
      }
    })

    return users
  }, [project, rights, t])

  const changeUserRole = useCallback(
    (role: ResourceUserRole) => {
      if (!changeRole) return

      if (changeRole.role === role) {
        setChangeRole(undefined)
        return
      }

      if (changeRole.role === ResourceUserRole.Owner) {
        const owners = readyUsers.filter((x) => x.role === ResourceUserRole.Owner && x.canEdit)
        if (owners.length <= 1) {
          setInfo({
            title: t('EditProject.ChangeOwnerRoleTitle'),
            message: t('EditProject.ChangeOwnerRole')
          })
          return
        }
      }

      setChangeRole(undefined)

      if (!project.id) return

      const p: ProjectEx = JSON.parse(JSON.stringify(project))

      const user = p.accountWithUsers.projects.find((x) => x.id === project.id)?.users?.find((x) => x.id === changeRole.id)
      if (!user) return

      user.role = role
      setProject(p)

      setSaving(true)
      updateProjectUserRole(project.account.id, project.id, user.id, role)
        .catch((err) => {
          alertDanger({
            title: t('EditProject.Errors.UserRoleChangeTitle'),
            message: t('EditProject.Errors.UserRoleChange'),
            error: err
          })
        })
        .finally(() => setSaving(false))
    },
    [changeRole, setChangeRole, readyUsers, setInfo, project, setProject, t]
  )

  const canDeleteUser = useCallback(
    (user: UserEx) => {
      if (user.role !== ResourceUserRole.Owner) {
        setConfirmDeleteUser(user)
        return
      }

      const owners = readyUsers?.filter((x) => x.id && x.canEdit && x.role === ResourceUserRole.Owner) || []
      if (owners.length > 1) {
        setConfirmDeleteUser(user)
        return
      }

      setInfo({
        title: t('EditProject.DeleteOwnerTitle'),
        message: t('EditProject.DeleteOwner')
      })
    },
    [setConfirmDeleteUser, setInfo, readyUsers, t]
  )

  const deleteUser = useCallback(() => {
    setConfirmDeleteUser(undefined)

    if (!project.id || !confirmDeleteUser) return

    const p: ProjectEx = JSON.parse(JSON.stringify(project))

    const prj = p.accountWithUsers.projects.find((x) => x.id === project.id)
    if (!prj || !prj.users) return

    let idx = prj.users.findIndex((x) => x.id === confirmDeleteUser.id)
    if (idx >= 0) prj.users.splice(idx, 1)
    idx = p.users?.findIndex((x) => x.id === confirmDeleteUser.id) || -1
    if (idx >= 0) p.users?.splice(idx, 1)

    setProject(p)

    setSaving(true)
    deleteProjectUser(project.account.id, project.id, confirmDeleteUser.id)
      .catch((err) => {
        alertDanger({
          title: t('EditProject.Errors.DeletedPeopleTitle'),
          message: t('EditProject.Errors.DeletedPeople'),
          error: err
        })
      })
      .finally(() => setSaving(false))
  }, [confirmDeleteUser, setConfirmDeleteUser, project, setProject, setSaving, t])

  const readyInvitations = useMemo(
    () => project?.accountWithUsers?.invitations.filter((i) => !!i.projectRole && i.projectId === project.id && i.status === InvitationStatus.Pending),
    [project.accountWithUsers]
  )

  const handleDeleteInvitation = useCallback(
    (invitation: Invitation) => {
      const p: ProjectEx = JSON.parse(JSON.stringify(project))

      const idx = p.accountWithUsers.invitations.findIndex((x) => x?.id === invitation?.id)
      if (idx < 0) return

      p.accountWithUsers.invitations.splice(idx, 1)
      setProject(p)

      setSaving(true)
      deleteInvitation(project.account.id, invitation.id)
        .then(() => {
          refreshAccounts().then(() => {})
          alertSuccess({
            title: t('EditProject.DeletedInvitationTitle'),
            message: t('EditProject.DeletedInvitation', {
              name: `${invitation!.givenName} ${invitation!.familyName}`,
              email: invitation!.emailAddress
            })
          })
        })
        .catch((err) =>
          alertDanger({
            title: t('EditProject.Errors.DeletedInvitationTitle'),
            message: t('EditProject.Errors.DeletedInvitation'),
            error: err
          })
        )
        .finally(() => setSaving(false))
    },
    [project, setProject, alertDanger, setSaving, refreshAccounts, t]
  )

  const handleResendInvitation = useCallback(
    (invitation: Invitation) => {
      if (!invitation) return

      setSaving(true)
      resendInvitation(project.account.id, invitation.id)
        .then(() =>
          alertSuccess({
            title: t('EditProject.InvitationResentTitle'),
            message: t('EditProject.InvitationResent', {
              name: `${invitation.givenName} ${invitation.familyName}`,
              email: invitation.emailAddress
            })
          })
        )
        .catch((err) =>
          alertDanger({
            title: t('EditProject.Errors.InvitationResentTitle'),
            message: t('EditProject.Errors.InvitationResent'),
            error: err
          })
        )
        .finally(() => setSaving(false))
    },
    [project, setSaving, t]
  )

  const tabs = useMemo<TabItem[]>(
    () => [
      {
        label: t('General.Staff'),
        selected: pathname.endsWith('staff'),
        to: url
      },
      {
        label: t('General.Invitations'),
        to: `${url}/invitations`,
        selected: pathname.endsWith('invitations')
      }
    ],
    [pathname, t]
  )

  if (!readyUsers) return <Loading />

  return (
    <>
      <TabBar tabs={tabs} style={{ justifyContent: 'center' }} onChange={() => {}} />
      <br />

      <Switch>
        <Route path={`${path}/invitations`}>
          {readyInvitations && <InvitationList invitations={readyInvitations} roles={roles} onNew={() => setInvite(true)} onDelete={handleDeleteInvitation} onResend={handleResendInvitation} />}
        </Route>

        <Route path="*">
          <ResourceUsers
            users={readyUsers}
            roles={roles}
            onSelect={(user) => setChangeRole({ ...user })}
            onDelete={canDeleteUser}
            readOnly={project.isGuest || [ProjectStatus.Canceled, ProjectStatus.Complete].includes(project.status!)}
          />
        </Route>
      </Switch>

      {changeRole && (
        <ChangeRoleModal
          title={t('General.ChangeRoleTitle')}
          message={t('General.ChangeRole')}
          roles={roles}
          selected={changeRole.role}
          yes={t('General.Ok')}
          no={t('General.Cancel')}
          onClose={() => setChangeRole(undefined)}
          onYes={(role) => changeUserRole(role as ResourceUserRole)}
        />
      )}

      {invite && account && (
        <InviteModal
          account={account}
          project={project}
          onClose={({ invitation, user }) => {
            setInvite(false)

            const p: ProjectEx = JSON.parse(JSON.stringify(project))
            if (invitation) p.accountWithUsers.invitations.push(invitation)
            if (user) {
              let idx = p.accountWithUsers.users.findIndex((x) => x.id === user.id)
              if (idx >= 0) p.accountWithUsers.users[idx] = user
              else p.accountWithUsers.users.push(user)

              idx = p.users?.findIndex((x) => x.id === user.id) || -1
              if (idx >= 0) p.users![idx] = user
              else {
                if (!p.users) p.users = []
                p.users.push(user)
              }
            }

            setProject(p)
          }}
        />
      )}

      {info && <InfoModal title={info.title} message={info.message} ok={t('General.Ok')} onClose={() => setInfo(undefined)} />}

      {confirmDeleteUser && (
        <ConfirmModal
          title={t('EditProject.DeletePeopleTitle')}
          message={t('EditProject.DeletePeople', {
            name: `${confirmDeleteUser.givenName} ${confirmDeleteUser.familyName}`
          })}
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={deleteUser}
          onClose={() => setConfirmDeleteUser(undefined)}
        />
      )}
    </>
  )
}
