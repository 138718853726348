import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/pro-solid-svg-icons'

import { useCurrentUserAccount, useGeolocation } from 'services/src/state'

import { useSaving } from 'components/src/saving'
import { Dropzone } from 'components/src/dropzone'
import { TextField } from 'components/src/textField'
import { PhoneInputField } from 'components/src/phoneInputField'
import { AddressField } from 'components/src/addressField'
import { validateEmail } from 'services/src/validate'
import { makeDashboardPath } from 'services/src/dom'
import { MAX_PHOTO_SIZE, PHOTO_TYPES } from 'services/src/common'

import { ClientProfileViewModel } from './model'
import { Footer } from '../Footer'

export const ClientGeneral: React.FC<{
  vm: ClientProfileViewModel
  onChange: (vm: ClientProfileViewModel) => void
  onSubmit: (view: string, vm: ClientProfileViewModel) => void
}> = ({ vm, onChange, onSubmit }) => {
  const { t } = useTranslation()
  const { country_code: geo } = useGeolocation()
  const [account] = useCurrentUserAccount()
  const [saving] = useSaving()

  const [dirty, setDirty] = useState(false)

  const onDropPicture = useCallback(
    (acceptedFiles: any[]) => {
      if (!acceptedFiles.length) return

      const errors = { ...vm.errors }
      delete errors.picture
      onChange({
        ...vm,
        picture: acceptedFiles[0],
        preview: URL.createObjectURL(acceptedFiles[0]),
        errors
      })
      setDirty(true)
    },
    [vm, onChange, setDirty]
  )

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      const vmx: ClientProfileViewModel = { ...vm, errors: {} }

      if (!vmx.givenName || !vmx.givenName.trim()) vmx.errors.givenName = t('General.Errors.Required')
      if (!vmx.familyName || !vmx.familyName.trim()) vmx.errors.familyName = t('General.Errors.Required')
      if (!vmx.emails![0].email || !vmx.emails![0].email.trim()) vmx.errors.email = t('General.Errors.Required')
      else if (!validateEmail(vmx.emails![0].email)) vmx.errors.email = t('General.Errors.InvalidEmail')

      if (Object.keys(vmx.errors).length) {
        onChange(vmx)
        return
      }

      onSubmit('general', vmx)
      setDirty(false)
    },
    [vm, onChange, onSubmit, setDirty]
  )

  return (
    <form noValidate onSubmit={submit}>
      <div className="settings-user-layout">
        <div className="user-picture">
          <Dropzone onDrop={onDropPicture} variant="round" accept={PHOTO_TYPES} maxFiles={1} maxSize={MAX_PHOTO_SIZE} rejectLabel={t('Settings.Errors.InvalidPicture')} disabled={saving}>
            {(() => {
              if (vm.preview || vm.pictureUrl) return <div className="bg-preview" style={{ backgroundImage: `url('${vm.preview || vm.pictureUrl}')` }} />
              return (
                <div className="empty">
                  <FontAwesomeIcon icon={faCamera} />
                </div>
              )
            })()}
          </Dropzone>
        </div>

        <div className="ui-row">
          <div className="ui-col-6">
            <TextField
              name="givenName"
              type="text"
              disabled={saving}
              value={vm.givenName}
              error={vm.errors.givenName}
              label={t('Settings.EditExpert.GivenName')}
              onChange={(givenName) => {
                const errors = { ...vm.errors }
                delete errors.givenName
                onChange({ ...vm, givenName, errors })
                setDirty(true)
              }}
            />
          </div>

          <div className="ui-col-6">
            <TextField
              name="familyName"
              type="text"
              disabled={saving}
              value={vm.familyName}
              error={vm.errors.familyName}
              label={t('Settings.EditExpert.FamilyName')}
              onChange={(familyName) => {
                const errors = { ...vm.errors }
                delete errors.familyName
                onChange({ ...vm, familyName, errors })
                setDirty(true)
              }}
            />
          </div>
        </div>

        <TextField
          name="email"
          type="email"
          disabled
          value={vm.emails?.length ? vm.emails[0].email : ''}
          error={vm.errors.email}
          label={t('Settings.EditExpert.Email')}
          onChange={(email) => {
            const errors = { ...vm.errors }
            delete errors.email
            onChange({ ...vm, emails: [{ email }], errors })
            setDirty(true)
          }}
        />

        <AddressField
          address={vm.personalAddress}
          streetLabel={t('Settings.EditExpert.PersonalAddress')}
          onChange={(personalAddress) => {
            const errors = { ...vm.errors }
            delete errors.personalAddress
            onChange({ ...vm, personalAddress, errors })
            setDirty(true)
          }}
        />

        <div className="ui-row">
          <div className="ui-col-5">
            <PhoneInputField
              label={t('Settings.EditExpert.BusinessPhone')}
              placeholder={t('Settings.EditExpert.BusinessPhoneSample')}
              value={vm.businessPhones?.length ? vm.businessPhones[0].number : undefined}
              disabled={saving}
              error={vm.errors.businessPhone}
              onChange={(number) => {
                const errors = { ...vm.errors }
                delete errors.businessPhone

                let businessPhones = [...(vm.businessPhones || [{ number }])]
                if (!businessPhones.length) businessPhones = [{ number }]

                businessPhones[0].number = number
                onChange({ ...vm, businessPhones, errors })
                setDirty(true)
              }}
              country={geo?.country_code?.toLowerCase() || 'us'}
            />
          </div>

          <div className="ui-col-2">
            <TextField
              name="extension"
              disabled={saving}
              value={vm.businessPhones?.length ? vm.businessPhones[0].extension : undefined}
              error={vm.errors.businessPhoneExt}
              label={t('Settings.EditExpert.BusinessPhoneExt')}
              placeholder={t('Settings.EditExpert.BusinessPhoneExtSample')}
              onChange={(extension) => {
                const errors = { ...vm.errors }
                delete errors.businessPhone

                const businessPhones = [...(vm.businessPhones || [{ number: '', extension }])]
                businessPhones[0].extension = extension
                onChange({ ...vm, businessPhones, errors })
                setDirty(true)
              }}
            />
          </div>

          <div className="ui-col-5">
            <PhoneInputField
              label={t('Settings.EditExpert.MobilePhone')}
              placeholder={t('Settings.EditExpert.MobilePhoneSample')}
              value={vm.mobilePhone}
              disabled={saving}
              error={vm.errors.mobilePhone}
              onChange={(mobilePhone) => {
                const errors = { ...vm.errors }
                delete errors.mobilePhone
                onChange({ ...vm, mobilePhone, errors })
                setDirty(true)
              }}
              country={geo?.country_code?.toLowerCase() || 'us'}
            />
          </div>
        </div>
      </div>

      <Footer>
        <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center' }}>
          <Link to={makeDashboardPath(account?.id)} className="ui-btn ui-btn-primary ui-btn-solid">
            {t('Dashboard.Title')}
          </Link>
          <button type="submit" style={{ marginLeft: 'auto' }} disabled={!dirty || saving} className="ui-btn ui-btn-secondary ui-btn-solid">
            {t('General.Update')}
          </button>
        </div>
      </Footer>
    </form>
  )
}
