import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentsAlt } from '@fortawesome/pro-duotone-svg-icons'

import './style.scss'

import { useCommentChannels, useCommentPanel } from 'services/src/state/comments'

export interface CommentBtnProps {
  variant?: 'toolbar' | 'iconOnly'
  onOpen?: (scopeId: string) => void
}

export const CommentBtn: React.FC<CommentBtnProps> = ({ variant, onOpen }) => {
  const [, setPanelState] = useCommentPanel()
  const { currentChannel } = useCommentChannels()

  if (!currentChannel) return null

  return (
    <button
      type="button"
      className={`ui-comment-btn ${variant === 'toolbar' ? 'ui-btn ui-btn-primary ui-btn-circle ui-btn-invert' : 'ui-btn-empty'}`}
      onClick={() => {
        if (!currentChannel) return
        setPanelState((s) => ({ ...s, view: 'open' }))
        if (onOpen) onOpen(currentChannel.channel)
        window.dispatchEvent(new Event('initLoadComments'))
      }}
    >
      <FontAwesomeIcon icon={faCommentsAlt} />
    </button>
  )
}
