import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { Modal, ModalProps } from 'components/src/modal'
import { SelectField } from 'components/src/selectField'
import { PhaseEx, useEditProject } from 'services/src/state'
import { ProjectStatus } from 'services/src/dto/project'
import { CurrencyInput } from 'components/src/currencyInput'

const getFeeDeclineReasons = (t: TFunction): { id: string; label: string }[] => [
  {
    id: 'OverBudget',
    label: t('EditProject.Fees.Decline.Reasons.OverBudget')
  },
  {
    id: 'Terminated',
    label: t('EditProject.Fees.Decline.Reasons.Terminated')
  }
]

export type PhaseWithFee = PhaseEx & {
  userFee: number
}

export type FeeDeclinedProps = ModalProps & {
  originalFee: number
  onDecline: (reason: string) => void
  onNegotiate: (phases: PhaseWithFee[], reason: string) => void
}

export const FeeClientDeclined: React.FC<FeeDeclinedProps> = ({ onClose, originalFee, onDecline, onNegotiate }) => {
  const { t } = useTranslation()

  const [errors, setErrors] = useState<any>({})
  const clearError = (name: string) => {
    const theErrors = { ...errors }
    delete theErrors[name]
    setErrors(theErrors)
  }

  const { project } = useEditProject()

  const [selectedFeeDeclineReason, setSelectedFeeDeclineReason] = useState<{ id: string; label: string }>()
  const [feeDeclineReasons, setFeeDeclineReasons] = useState<{ id: string; label: string }[]>([])

  const [negotiateFees, setNegotiateFees] = useState(false)
  const [declineReasons, setDeclineReasons] = useState(true)

  useEffect(() => setFeeDeclineReasons(getFeeDeclineReasons(t)), [t])

  const [phases, setPhases] = useState<PhaseWithFee[]>(project.phases.filter((ph) => ph.status === ProjectStatus.FeeReady).map((ph) => ({ ...ph, userFee: ph.fee || 0 } as PhaseWithFee)))

  const userTotal = useMemo(() => {
    let total = 0
    phases.forEach((ph) => {
      total += ph.userFee
    })
    return total
  }, [phases])

  const feeDifference = useMemo(() => {
    if (!originalFee || !userTotal) return undefined

    return -(((originalFee - userTotal) / ((originalFee + userTotal) / 2)) * 100)
  }, [originalFee, userTotal])

  const feeDifferenceText = useMemo(() => {
    if (feeDifference === undefined) return undefined

    return `${feeDifference > 0 ? '+' : ''}${t('General.PercentValue', { value: feeDifference.toFixed(1) })}`
  }, [feeDifference])

  const submitReject = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      const errors: any = {}
      if (!selectedFeeDeclineReason) errors.feeDeclineReasons = t('General.Errors.Required')

      setErrors(errors)
      if (Object.keys(errors).length > 0) return

      onDecline(selectedFeeDeclineReason?.label || '')
    },
    [setErrors, onDecline, selectedFeeDeclineReason]
  )

  const submitNegotiate = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      const errors: any = {}
      phases.forEach((ph) => {
        if (ph.userFee <= 0) errors[ph.id!] = t('General.Errors.Required')
      })

      // if ((feeDifference || 0) < -NEGOTIATION_LIMIT)
      //  errors.limit = t('EditProject.Fees.RejectFeesModal.NegotiateFeeLimit', {limit: NEGOTIATION_LIMIT});

      setErrors(errors)
      if (Object.keys(errors).length > 0) return

      onNegotiate(phases, selectedFeeDeclineReason?.label || '')
    },
    [setErrors, onNegotiate, feeDifference, phases]
  )

  return (
    <Modal onClose={onClose} closeOnClickOutside={false} header={<h3>{declineReasons ? t('EditProject.Fees.RejectFeesModal.FeeDeclined') : t('EditProject.Fees.RejectFeesModal.NegotiateFees')}</h3>}>
      {declineReasons && (
        <form onSubmit={submitReject} noValidate autoComplete="off">
          <SelectField
            items={feeDeclineReasons}
            selected={selectedFeeDeclineReason}
            label={t('EditProject.Fees.RejectFeesModal.DeclineReason')}
            error={errors.feeDeclineReasons}
            onSelectedChange={(x) => {
              setSelectedFeeDeclineReason(x)
              clearError('feeDeclineReasons')
            }}
            placeholder={t('EditProject.Fees.RejectFeesModal.DeclineReasonSample')}
            disabled={feeDeclineReasons.length <= 0}
          />
          <div style={{ marginTop: 15 }}>
            <button
              type="button"
              onClick={() => {
                if (!selectedFeeDeclineReason) {
                  setErrors({ feeDeclineReasons: t('General.Errors.Required') })
                  return
                }
                setNegotiateFees(true)
                setDeclineReasons(false)
              }}
              className="ui-btn ui-btn-secondary ui-btn-solid"
              style={{ width: '100%' }}
            >
              {t('EditProject.Fees.RejectFeesModal.NegotiateFeesOption')}
            </button>
          </div>
          <div style={{ marginTop: 15 }}>
            <button type="submit" className="ui-btn ui-btn-primary ui-btn-solid" style={{ width: '100%' }}>
              {t('EditProject.Fees.RejectFeesModal.DeclineFeesAndArchive')}
            </button>
          </div>
        </form>
      )}

      {negotiateFees && (
        <form onSubmit={submitNegotiate} noValidate autoComplete="off">
          <div className="negotiate-fees-header ui-text-sm">{t('EditProject.Fees.RejectFeesModal.ClientFeeProposal')}</div>
          <div className="negotiate-fees ui-frame ui-frame-bg">
            {phases.map((ph) => (
              <div key={ph.id} style={{ marginBottom: 15 }}>
                <label>
                  {t(`EditProject.${ph.phaseType}`)}
                  {ph.name && (
                    <span className="ui-text-muted" style={{ marginLeft: 10 }}>
                      ({ph.name})
                    </span>
                  )}
                </label>
                <div style={{ position: 'relative' }} className={errors[ph.id!] ? 'ui-has-error' : ''}>
                  <CurrencyInput
                    style={{ paddingRight: 100 }}
                    digits={2}
                    placeholder={t('General.Currency.Value', { value: ph.fee, maxDigits: 2 })}
                    amount={ph.userFee?.toString()}
                    onChange={(fee) => {
                      const pxs = [...phases]
                      const px = pxs.find((x) => x.id === ph.id)
                      if (!px) return
                      px.userFee = fee || 0
                      setPhases(pxs)
                      clearError(ph.id!)
                      clearError('limit')
                    }}
                  />
                  {errors[ph.id!] && <div className="ui-error ui-text-xs">{errors[ph.id!]}</div>}

                  <div
                    className="ui-text-muted ui-text-sm"
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      height: '100%',
                      borderLeft: 'solid 1px #ccc',
                      paddingLeft: 10,
                      paddingTop: 9,
                      width: 100
                    }}
                  >
                    {t('General.Currency.Value', { value: ph.fee, maxDigits: 2 })}
                  </div>
                </div>
              </div>
            ))}

            <div className="original-fee-difference ui-flex">
              <div>
                <div className="ui-text-sm">{t('EditProject.Fees.RejectFeesModal.FeeProposal')}</div>
                <div className="value">{t('General.Currency.Value', { value: userTotal })}</div>
              </div>
              {feeDifference !== undefined && feeDifferenceText && (
                <div className={`${feeDifference < 0 ? 'ui-danger' : 'ui-secondary'}`}>
                  <div className="ui-text-sm ui-text-right">{t('EditProject.Fees.RejectFeesModal.Difference')}</div>
                  <div className="value">{feeDifferenceText}</div>
                </div>
              )}
            </div>

            {errors.limit && (
              <div className="ui-text-center ui-text-italic ui-danger ui-text-sm" style={{ height: 0 }}>
                {errors.limit}
              </div>
            )}
          </div>

          <div>
            <button type="submit" className="ui-btn ui-btn-secondary ui-btn-solid" style={{ width: '100%' }}>
              {t('EditProject.Fees.RejectFeesModal.NegotiateFees')}
            </button>
          </div>
        </form>
      )}
    </Modal>
  )
}
