import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useEditProject } from 'services/src/state'
import { ResourceUserRole } from 'services/src/dto/account'
import { useProjectSupport } from 'services/src/api'
import moment, { Duration } from 'moment'

export const ExpertSummaryBar: React.FC = () => {
  const { t } = useTranslation()

  const { project } = useEditProject()
  const [{ constructionTypes }] = useProjectSupport()

  const pm = useMemo(() => project.users?.find((u) => u.role === ResourceUserRole.Owner && project.providerAccountWithUsers.users.find((u2) => u2.id === u.id)), [project])

  const constructionType = useMemo(() => {
    if (!constructionTypes) return <>&nbsp;</>
    const ct = constructionTypes.find((x) => x.id === project.constructionType)
    return ct ? ct.type : <>&nbsp;</>
  }, [constructionTypes, project])

  const duration = useMemo<Duration>(() => {
    let min = moment(project.phases[0].submissionDate)
    let max = moment(project.phases[0].deliveryDate)

    project.phases.forEach((ph) => {
      const d1 = moment(ph.submissionDate)
      const d2 = moment(ph.deliveryDate)
      if (d1.isBefore(min)) min = d1
      if (d2.isAfter(max)) max = d2
    })
    return moment.duration(max.valueOf() - min.valueOf())
  }, [project])

  return (
    <div className="ui-form-group">
      <label>{t('Expert.Overview.ProjectOverview')}</label>
      <div className="ui-frame ui-frame-bg" style={{ paddingBottom: 0 }}>
        <div className="ui-row">
          <div className="ui-col-12 ui-col-lg-3" style={{ marginBottom: 20, paddingTop: 2 }}>
            {pm && (
              <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center' }}>
                <div className="ui-picture-circle" style={{ width: 45, height: 45, minWidth: 45, minHeight: 45 }}>
                  {pm.pictureUrl ? (
                    <div style={{ backgroundImage: `url('${pm.pictureUrl}')`, border: 'none' }} />
                  ) : (
                    <div style={{ lineHeight: '45px', fontSize: '19px' }}>
                      {pm.givenName && pm.givenName[0].toUpperCase()}
                      {pm.familyName && pm.familyName[0].toUpperCase()}
                    </div>
                  )}
                </div>
                <div style={{ marginLeft: 15 }}>
                  <div className="ui-text-bold">
                    {pm.givenName} {pm.familyName}
                  </div>
                  <div className="ui-text-xs ui-text-muted">{t(`General.ProviderRoles.${pm.role}`)}</div>
                </div>
              </div>
            )}
          </div>

          <div className="ui-col-12 ui-col-lg-6" style={{ marginBottom: 20 }}>
            <div className="ui-row ui-text-sm ui-text-center">
              <div className="ui-col-4">
                <label className="ui-text-sm">{t('Expert.Overview.Role')}</label>
                <div className="ui-frame ui-text-ellipsis" style={{ padding: '5px 10px', marginTop: 5, border: 'none' }}>
                  {constructionType}
                </div>
              </div>
              <div className="ui-col-8">
                <label className="ui-text-sm">{t('Expert.Overview.ProjectType')}</label>
                <div className="ui-frame ui-text-ellipsis" style={{ padding: '5px 10px', marginTop: 5, border: 'none' }}>
                  {project.projectType?.type}
                </div>
              </div>
            </div>
          </div>

          <div className="ui-col-12 ui-col-lg-3" style={{ marginBottom: 20 }}>
            <div className="ui-row ui-text-sm ui-text-center">
              <div className="ui-col-4">
                <label className="ui-text-sm">{t('Expert.Overview.Phases')}</label>
                <div className="ui-frame ui-text-ellipsis" style={{ padding: '5px 10px', marginTop: 5, border: 'none' }}>
                  {project.phases.length}
                </div>
              </div>
              <div className="ui-col-8">
                <label className="ui-text-sm">{t('Expert.Overview.Duration')}</label>
                <div className="ui-frame ui-text-ellipsis" style={{ padding: '5px 10px', marginTop: 5, border: 'none' }}>
                  {duration.humanize()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
