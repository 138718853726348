import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { faAngleRight, faBookmark, faCalculator, faFileInvoice, faListCheck, faPenToSquare, faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SidebarProps {
  activeTab: number;
  openTab: (tabName: number) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ activeTab, openTab }) => {
  
  const { t } = useTranslation();

  return (
    <div className="sidebar">
      <div className="sidebar-content">
        <h2>{t('BasisOfEstimate.BasisOfCostEstimate')}</h2>
        <ul className="menu">
          <li className={`menu-item ${activeTab === 0 ? 'active' : ''}`}>
            <div className="menu-header" onClick={() => openTab(0)}>
              <FontAwesomeIcon icon={faFileInvoice} />
              <span className="menu-text">{t('BasisOfEstimate.ConverLetter.Title')}</span>
            </div>
          </li>
          <li className={`menu-item ${activeTab >= 1 && activeTab <= 4 ? 'active' : ''}`}>
            <div className="menu-header" onClick={() => openTab(1)}>
              <FontAwesomeIcon icon={faUser} />
              <span className="menu-text">{
                t('BasisOfEstimate.Introduction.Heading')
                }</span>
            </div>
            {activeTab >= 1 && activeTab <= 4 && (
              <ul className="submenu">
                <li onClick={() => openTab(1)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.Introduction.Title')}</span>
                </li>
                <li onClick={() => openTab(2)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.ProjectTasksDescriptionSection.Title')}</span>
                </li>
                <li onClick={() => openTab(4)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.ProjectTasksDescriptionSection.Title')}</span>
                </li>
              </ul>
            )}
          </li>
          <li className={`menu-item ${activeTab >= 5 && activeTab <= 6 ? 'active' : ''}`}>
            <div className="menu-header" onClick={() => openTab(5)}>
              <FontAwesomeIcon icon={faListCheck} />
              <span className="menu-text">{t('BasisOfEstimate.BasisAssumption')}</span>
            </div>
            {activeTab >= 5 && activeTab <= 6 && (
              <ul className="submenu">
                <li onClick={() => openTab(5)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.Methodology.Title')}</span>
                </li>
                <li onClick={() => openTab(6)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.Classification.Title')}</span>
                </li>
              </ul>
            )}
          </li>
          <li className={`menu-item ${activeTab >= 7 && activeTab <= 12 ? 'active' : ''}`}>
            <div className="menu-header" onClick={() => openTab(6)}>
              <FontAwesomeIcon icon={faCalculator} />
              <span className="menu-text">{t('BasisOfEstimate.CostDevelopment')}</span>
            </div>
            {activeTab >= 7 && activeTab <= 12 && (
              <ul className="submenu">
                <li onClick={() => openTab(7)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.DirectCostDevelopment.Title')}</span>
                </li>
                <li onClick={() => openTab(8)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.IndirectCostDevelopment.Title')}</span>
                </li>
                <li onClick={() => openTab(9)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.BiddingAssumption.Title')}</span>
                </li>
                <li onClick={() => openTab(10)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.DesignBasis.Title')}</span>
                </li>
                <li onClick={() => openTab(11)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.PlanningBasis.Title')}</span>
                </li>
                <li onClick={() => openTab(12)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.RisksAndOpportunities.Title')}</span>
                </li>
              </ul>
            )}
          </li>
          <li className={`menu-item ${activeTab >= 13 && activeTab <= 14 ? 'active' : ''}`}>
            <div className="menu-header" onClick={() => openTab(12)}>
              <FontAwesomeIcon icon={faPenToSquare} />
              <span className="menu-text">{t('BasisOfEstimate.Allowance')}</span>
            </div>
            {activeTab >= 13 && activeTab <= 14 && (
              <ul className="submenu">
                <li onClick={() => openTab(13)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.ProjectPhysicalScope.Title')}</span>
                </li>
                <li onClick={() => openTab(14)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.AssumptionsNotes.Title')}</span>
                </li>
              </ul>
            )}
          </li>
          <li className={`menu-item ${activeTab >= 15 ? 'active' : ''}`}>
            <div className="menu-header" onClick={() => openTab(15)}>
              <FontAwesomeIcon icon={faSquareCheck} />
              <span className="menu-text">{t('BasisOfEstimate.LastSection')}</span>
            </div>
            {activeTab >= 15 && (
              <ul className="submenu">
                <li onClick={() => openTab(14)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.ContractorOtherEstimateMarkups.Title')}</span>
                </li>
                <li onClick={() => openTab(15)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.LabourMarkups.Title')}</span>
                </li>
                <li onClick={() => openTab(16)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.Equipment.Title')}</span>
                </li>
                <li onClick={() => openTab(17)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.SubcontractorMarkup.Title')}</span>
                </li>
                <li onClick={() => openTab(18)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.SalesTax.Title')}</span>
                </li>
                <li onClick={() => openTab(19)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.BoeProject120.Title')}</span>
                </li>
                <li onClick={() => openTab(20)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.BoeProject121.Title')}</span>
                </li>
                <li onClick={() => openTab(21)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.BoeProject122.Title')}</span>
                </li>
                <li onClick={() => openTab(22)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.BoeProject123.Title')}</span>
                </li>
                <li onClick={() => openTab(23)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.BoeProject124.Title')}</span>
                </li>
                <li onClick={() => openTab(24)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.BoeProject125.Title')} </span>
                </li>
                <li onClick={() => openTab(26)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.BoeProject126.Title')} </span>
                </li>
                <li onClick={() => openTab(27)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.BoeProject127.Title')} </span>
                </li>
                <li onClick={() => openTab(28)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.BoeProject128.Title')} </span>
                </li>
                <li onClick={() => openTab(29)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.BoeProject129.Title')}</span>
                </li>
                <li onClick={() => openTab(30)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.BoeProject130.Title')} </span>
                </li>
                <li onClick={() => openTab(31)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.BoeProject131.Title')} </span>
                </li>
                <li onClick={() => openTab(32)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.BoeProject132.Title')}</span>
                </li>
                <li onClick={() => openTab(33)}>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <span>{t('BasisOfEstimate.BoeProject133.Title')}</span>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
      <div className="sidebar-content">
        <p className="sidebar-call-to-action">{t('BasisOfEstimate.HideNonEditableSections')}</p>
        <ul className="menu">
          <li className={`menu-item ${activeTab === 35 ? 'active' : ''}`}>
            <div className="menu-header" onClick={() => openTab(33)}>
              <FontAwesomeIcon icon={faBookmark} />
              <span className="menu-text">{t('BasisOfEstimate.SaveSubSection')}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar;