import axios from 'axios'
import { aadConfig, getAccessToken } from '../aad'
import apiProxy from './apiProxy'
import { ProjectContent } from '../dto/content'

/** Fetch content as raw buffer. */
export const getContent = apiProxy('getContent', async (url: string): Promise<{ data: Buffer; contentType: string }> => {
  // await getAccessToken();

  const axiosEx = axios.create()
  axiosEx.defaults.headers.common = {}

  const resp = await axiosEx.get(url, { responseType: 'arraybuffer' })
  return {
    data: Buffer.from(resp.data, 'binary'),
    contentType: resp.headers['content-type'] || 'application/octet-stream'
  }
})

/** Fetch project content list. */
export const getProjectContent = apiProxy('getProjectContent', async (accountId: string, projectId: string, phaseId?: string, category?: string): Promise<ProjectContent[]> => {
  await getAccessToken(aadConfig().contentScopes)

  const q: string[] = []
  if (phaseId) q.push(`phaseId=${phaseId}`)
  if (category) q.push(`category=${category}`)

  const { data } = await axios.get(`${process.env.REACT_APP_CONTENT_API}/accounts/${accountId}/projects/${projectId}${q.length > 0 ? `?${q.join('&')}` : ''}`)
  return data
})
