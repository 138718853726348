import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostRequests } from 'services/src/api/boe';
import { endpoints } from 'services/src/lib/axios';
import { Loading } from '../../../loading';
import { TextAreaField } from '../../../textAreaField';
import { BoeProjectSectionSubheader } from './BoeProjectSectionSubheader';
import ProjectBoeProgressBar from './ProjectBoeProgressBar';

interface ProjectBoeScopeDefinitionSectionProps {
  handleNext: () => void;
  handlePrevious: () => void;
  setBoeData: (data: any) => void;
  boeData: any;
  isProgressBarVisible: boolean;
  setIsProgressBarVisible: (value: boolean) => void;
}

interface Errors {
  [key: string]: string | undefined;
  escalationMidpoint?: string;
}

const BoeProject123: React.FC<ProjectBoeScopeDefinitionSectionProps> = ({
  handleNext,
  handlePrevious,
  setBoeData,
  boeData,
  isProgressBarVisible,
  setIsProgressBarVisible,
}) => {
  const { t } = useTranslation();
  const progress = useMemo(() => 69, []);
  const { data, postRequests, loading, error } = usePostRequests();
  const [errors, setErrors] = useState<Errors>({});
  const [escalationMidpoint, setAccuracyOfEstimate] = useState<string>(boeData?.escalationMidpoint || '');

  const clearError = (name: string) => {
    setErrors((current) => {
      const newErrors = { ...current };
      delete newErrors[name];
      return newErrors;
    });
  };

  const handleNextClick = () => {
    if (!escalationMidpoint) {
      setErrors({ escalationMidpoint: 'This field is required' });
      return;
    }
    setBoeData((prev: any) => ({ ...prev, escalationMidpoint }));
    handleNext();
  };

  useEffect(() => {
    if (escalationMidpoint === '') {
      postRequests(endpoints.boe.escalationMidpoint, {});
    }
  }, [escalationMidpoint, postRequests]);

  useEffect(() => {
    if (data?.response) {
      setAccuracyOfEstimate(data?.response || '');
    }
  }, [data?.response]);


  return (
    <div className="boe-content">
      <div className="boe-form-content">
        <ProjectBoeProgressBar
          progress={progress}
          title={t('BasisOfEstimate.BoeProject123.ProgressTitle')}
          leftText={t('BasisOfEstimate.BoeProject123.ProgressLeftText')}
          rightText={t('BasisOfEstimate.BoeProject123.ProgressRightText')}
          isProgressBarVisible={isProgressBarVisible}
          setIsProgressBarVisible={setIsProgressBarVisible}
          boeData={boeData}
        />
        <BoeProjectSectionSubheader title={t('BasisOfEstimate.BoeProject123.Title')} dynamicText={t('BasisOfEstimate.BoeProject123.Dynamic')} boeData={boeData} />

        {loading && <Loading />}
        {error && (
          <div className="boe-error-container">
            <p>{t('General.Error')}</p>
          </div>
        )}

        <TextAreaField
          name="escalationMidpoint"
          label=""
          placeholder=""
          autoFocus
          error={errors.escalationMidpoint}
          style={{ height: 324 }}
          value={escalationMidpoint}
          onChange={(e) => {
            setAccuracyOfEstimate(e);
            clearError('escalationMidpoint');
          }}
        />
      </div>
      <div className="boe-form-action-container">
        <button type="button" className="btn-next" onClick={handlePrevious}>
          {t('General.Previous')}
        </button>
        <button type="button" className="btn-previous" onClick={handleNextClick}>
          {t('General.Next')}
        </button>
      </div>
    </div>
  );
};

export default BoeProject123;