import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useSaving } from 'components/src/saving'
import { DisciplineKey, ProjectStatus } from 'services/src/dto/project'
import { User } from 'services/src/dto/account'
import { AutoComplete } from 'components/src/autoComplete'
import { getDisciplineSkills, getUsers } from 'services/src/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare, faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { faClock, faLock, faLockOpen, faSquare, faSquareCheck, faThumbsDown } from '@fortawesome/pro-regular-svg-icons'
import { Number } from 'components/src/numberField/Number'
import { DisciplineSkill } from 'services/src/dto/projectSupport'
import { Select } from 'components/src/select'
import { InfoModal } from 'components/src/modal/InfoModal'
import { AssignmentPanel } from '../../common/AssignmentPanel'
import { FeeGridViewModel, FeeGridViewModelEstimate, FeeGridViewModelEstimateLabor } from './common'

export const FeeGridExtraLabor: React.FC<{
  vm: FeeGridViewModel
  estimate: FeeGridViewModelEstimate
  onChange: (estimate: FeeGridViewModelEstimate) => void
}> = ({ vm, estimate, onChange }) => {
  const { t, i18n } = useTranslation()
  const [saving] = useSaving()

  const [assignment, setAssignment] = useState<{ estimate: FeeGridViewModelEstimate; idx: number } | undefined>()

  const { totalCost, totalMargin } = useMemo(() => {
    let totalCost = 0
    let totalHours = 0
    estimate.labor
      .filter((l) => !!l.userId && l.status !== ProjectStatus.Rejected)
      .forEach((l) => {
        totalCost += l.cost
        totalHours += l.hours || 0
      })

    return {
      totalCost,
      totalHours,
      totalMargin: estimate.subtotal > 0 ? (estimate.subtotal - totalCost) / estimate.subtotal : undefined
    }
  }, [estimate])

  const [allDisciplineSkills, setAllDisciplineSkills] = useState<DisciplineSkill[]>()

  useEffect(() => {
    getDisciplineSkills(i18n.language).then((disciplineSkills) => {
      setAllDisciplineSkills(disciplineSkills)
    })
  }, [setAllDisciplineSkills, i18n])

  const addLabor = useCallback(() => {
    const l: FeeGridViewModelEstimateLabor = {
      id: null,
      userId: null,
      hours: 0,
      rate: 0,
      split: 0,
      userSuggestions: [],
      userName: '',
      cost: 0,
      margin: 0,
      skills: [],
      status: ProjectStatus.New,
      statusHistory: []
    }
    onChange({ ...estimate, labor: [...estimate.labor, l] })
  }, [estimate, onChange])

  const setUser = useCallback(
    (user: User | null, idx: number, assignToAll?: boolean) => {
      const vm = { ...estimate }

      const availableHours = Math.max(
        0,
        estimate.labor.filter((l) => l.status !== ProjectStatus.Rejected).reduce((hours, l) => hours - (l.hours || 0), estimate.hours)
      )

      vm.labor = estimate.labor.map((l, i) => {
        const lx = { ...l, assignToAll }
        if (idx === i) {
          lx.user = user || undefined
          lx.userId = user?.id || null
          lx.userName = user ? `${user.givenName} ${user.familyName}` : ''
          lx.rate = user?.hourlyRate || 0

          if (!lx.hours) lx.hours = availableHours

          lx.cost = lx.rate * lx.hours
          lx.margin = (estimate.providerRate - lx.rate) / estimate.providerRate
        }

        lx.userSuggestions = []
        lx.split = (lx.hours || 0) / estimate.hours

        return lx
      })

      onChange(vm)
    },
    [estimate, onChange]
  )

  const { canAdd } = useMemo(() => {
    const canAdd = ![ProjectStatus.Complete, ProjectStatus.Canceled].includes(estimate.phase.status!)
    return { canAdd }
  }, [estimate])

  const [history, setHistory] = useState<FeeGridViewModelEstimateLabor>()

  const autoCompleteTimerId = useRef<any>()

  return (
    <>
      <div className="edit-project-fee-grid-labor">
        {estimate.labor.map((l, idx) => {
          const disciplineSkills = allDisciplineSkills?.find((x) => x.discipline === estimate.discipline)?.skills?.map((s) => ({ id: s, label: s }))

          const canEdit = [ProjectStatus.New].includes(l.status)

          return (
            <React.Fragment key={idx}>
              <div className="edit-project-fee-grid-labor-item ui-flex">
                <div style={{ width: 250, minWidth: 250 }}>
                  {idx === 0 && (
                    <div className="ui-text-xxs ui-text-uppercase ui-text-bold ui-text-muted" style={{ marginLeft: 30, marginBottom: 5 }}>
                      {t('EditProject.Fees.Assignments')}
                    </div>
                  )}
                  <div className="ui-flex">
                    <div
                      style={{ width: 30 }}
                      title={
                        // eslint-disable-next-line no-nested-ternary
                        l.status === ProjectStatus.New ? t('EditProject.Fees.UnlockNote') : l.status === ProjectStatus.Rejected ? t('EditProject.Fees.RejectNote') : t('EditProject.Fees.LockNote')
                      }
                    >
                      <FontAwesomeIcon
                        icon={
                          // eslint-disable-next-line no-nested-ternary
                          l.status === ProjectStatus.New ? faLockOpen : l.status === ProjectStatus.Rejected ? faThumbsDown : faLock
                        }
                        style={{ fontSize: '1rem' }}
                      />
                    </div>

                    {l.status === ProjectStatus.Rejected && (
                      <div className="ui-text-sm ui-flex ui-frame ui-frame-no-padding" style={{ height: 25, lineHeight: '23px', width: '100%', paddingLeft: 5 }}>
                        {l.user?.givenName} {l.user?.familyName}
                      </div>
                    )}

                    {l.status !== ProjectStatus.Rejected && (
                      <>
                        <AutoComplete
                          style={{ flex: 1 }}
                          suggestions={l.userSuggestions!}
                          className="ui-auto-complete-sm"
                          value={l.userName}
                          disabled={saving || !canEdit}
                          onValueChange={(v) => {
                            clearTimeout(autoCompleteTimerId.current)

                            if (!v) {
                              setUser(null, idx)
                              return
                            }

                            const est = { ...estimate, labor: [...estimate.labor] }
                            est.labor[idx].userName = v
                            onChange(est)

                            autoCompleteTimerId.current = setTimeout(() => {
                              const [gn, fn] = est.labor[idx].userName.split(' ')
                              getUsers({
                                givenName: gn,
                                familyName: fn,
                                providerOnly: true,
                                providerId: vm.project.provider.id,
                                experts: true
                              }).then((users) => {
                                const est2 = { ...est, labor: [...est.labor] }
                                est2.labor[idx].userSuggestions = users
                                onChange(est2)
                              })
                            }, 1000)
                          }}
                          placeholder={t('EditProject.Fees.ExpertOrProviderLookup')}
                          searchIconUrl={l.user?.pictureUrl}
                          onSelectSuggestion={(user: User) => setUser(user, idx, false)}
                          render={(user: User) => (
                            <div className="ui-flex" style={{ alignItems: 'center' }}>
                              <div className="ui-picture-circle" style={{ width: 20, height: 20, minWidth: 20, minHeight: 20 }}>
                                {user.pictureUrl ? (
                                  <div style={{ backgroundImage: `url('${user.pictureUrl}')`, border: 'none' }} />
                                ) : (
                                  <div style={{ lineHeight: '18px', fontSize: '11px' }}>{`${user.givenName[0].toUpperCase()}${user.familyName[0].toUpperCase()}`}</div>
                                )}
                              </div>
                              <div style={{ marginLeft: 5 }}>
                                {user.givenName} {user.familyName}
                              </div>
                            </div>
                          )}
                        />
                      </>
                    )}
                  </div>

                  {canAdd && idx === estimate.labor.length - 1 && (
                    <div style={{ marginTop: 10 }}>
                      <button type="button" className="ui-btn-empty ui-btn-sm ui-primary" style={{ marginTop: 5 }} onClick={addLabor} tabIndex={-1}>
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp;
                        {t('EditProject.Fees.AddLabor')}
                      </button>
                    </div>
                  )}
                </div>

                <div style={{ marginLeft: 5, width: 30 }}>
                  <button
                    type="button"
                    disabled={saving || !canEdit || l.status === ProjectStatus.Rejected}
                    style={{ width: '100%', marginTop: idx === 0 ? 19 : 0 }}
                    className="ui-btn-empty ui-secondary"
                    onClick={() => setAssignment({ estimate, idx })}
                  >
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ fontSize: '1.1rem' }} />
                  </button>
                </div>

                <div style={{ width: 30 }}>
                  <button type="button" disabled={saving} style={{ width: '100%', marginTop: idx === 0 ? 19 : 0 }} className="ui-btn-empty ui-info" onClick={() => setHistory(l)}>
                    <FontAwesomeIcon icon={faClock} style={{ fontSize: '1.05rem' }} />
                  </button>
                </div>

                <div style={{ marginLeft: 5, marginRight: 20, flex: 1, width: 200, minWidth: 200 }}>
                  {idx === 0 && (
                    <div className="ui-text-xxs ui-text-uppercase ui-text-bold ui-text-muted" style={{ marginBottom: 5 }}>
                      {t('EditProject.Fees.DisciplineOrRole')}
                    </div>
                  )}

                  {l.status === ProjectStatus.Rejected && (
                    <div className="ui-text-sm ui-flex ui-frame ui-frame-no-padding" style={{ height: 25, lineHeight: '23px', width: '100%', paddingLeft: 5 }}>
                      {l.skills?.length ? l.skills[0] : ''}
                    </div>
                  )}

                  {l.status !== ProjectStatus.Rejected && (
                    <>
                      {disciplineSkills?.length ? (
                        <Select
                          disabled={saving}
                          items={disciplineSkills}
                          placeholder={t('EditProject.Fees.SelectMultiDisciplines')}
                          className="ui-select-sm"
                          selected={!!l.skills?.length}
                          onSelectedChange={({ id }) => {
                            const vm = { ...estimate, labor: estimate.labor.map((l) => ({ ...l })) }
                            const l = vm.labor[idx]
                            if (!l.skills) l.skills = []
                            const i = l.skills.findIndex((s) => s === id)
                            if (i < 0) l.skills.push(id)
                            else l.skills.splice(i, 1)
                            onChange(vm)
                            return true
                          }}
                          renderItem={(item) => {
                            if (l.skills?.find((s) => s === item.id))
                              return (
                                <div>
                                  <FontAwesomeIcon icon={faSquareCheck} /> {item.label}
                                </div>
                              )
                            return (
                              <div>
                                <FontAwesomeIcon icon={faSquare} /> {item.label}
                              </div>
                            )
                          }}
                          renderSelected={() => {
                            if (!l.skills?.length) return <div />
                            if (l.skills.length === 1) return <div>{l.skills[0]}</div>
                            if (l.skills.length === 2) return <div>{`${l.skills[0]} and ${l.skills[1]}`}</div>
                            return <div>{`${l.skills.filter((s, idx) => idx !== l.skills!.length - 1).join(', ')}, and ${l.skills[l.skills.length - 1]}`}</div>
                          }}
                        />
                      ) : (
                        <input
                          type="text"
                          disabled={saving}
                          className="ui-input-sm"
                          placeholder={t('EditProject.Fees.OptionalDisciplineName')}
                          value={l.skills?.length ? l.skills[0] : ''}
                          onChange={(e) => {
                            const vm = { ...estimate, labor: estimate.labor.map((l) => ({ ...l })) }
                            if (!vm.labor[idx].skills) vm.labor[idx].skills = []
                            vm.labor[idx].skills![0] = e.currentTarget.value

                            onChange(vm)
                          }}
                        />
                      )}
                    </>
                  )}
                </div>

                <div style={{ width: 100, minWidth: 100 }}>
                  {idx === 0 && (
                    <div className="ui-text-center ui-text-xxs ui-text-uppercase ui-text-bold ui-text-muted" style={{ marginBottom: 5 }}>
                      {t('EditProject.Fees.Hours')}
                    </div>
                  )}

                  {canEdit ? (
                    <Number
                      value={l.hours ? parseFloat(l.hours.toFixed(1)) : 0}
                      className="ui-text-sm"
                      disabled={saving || !canEdit}
                      style={{
                        height: 25,
                        lineHeight: '23px'
                      }}
                      min={0}
                      max={1000}
                      onChange={(v) => {
                        const est = { ...estimate }
                        let hours = vm.phase.status === ProjectStatus.Accepted ? 0 : est.hours
                        est.labor = estimate.labor.map((l, i) => {
                          const lx = { ...l }

                          if (idx === i) lx.hours = v

                          lx.cost = lx.rate * (lx.hours || 0)
                          lx.margin = (estimate.providerRate - lx.rate) / estimate.providerRate

                          if (vm.phase.status === ProjectStatus.Accepted) hours += lx.hours || 0

                          return lx
                        })

                        est.subtotal = est.hours * est.providerRate
                        est.total = est.subtotal + est.otherFeeTotal

                        est.labor.forEach((l) => {
                          l.split = (l.hours || 0) / est.hours
                        })

                        est.hours = hours

                        onChange(est)
                      }}
                    />
                  ) : (
                    <div className="ui-text-sm ui-flex ui-frame ui-frame-no-padding ui-text-center" style={{ height: 25, lineHeight: '23px', width: '100%' }}>
                      {l.status !== ProjectStatus.Rejected ? l.hours : '--'}
                    </div>
                  )}
                </div>

                <div style={{ marginLeft: 20, width: 50, minWidth: 50 }}>
                  {idx === 0 && (
                    <div className="ui-text-center ui-text-xxs ui-text-uppercase ui-text-bold ui-text-muted" style={{ marginBottom: 5 }}>
                      {t('General.ExpertTypesAlt.Title')}
                    </div>
                  )}
                  <div className="ui-text-sm ui-flex ui-frame ui-frame-no-padding ui-text-center" style={{ height: 25, lineHeight: '23px' }}>
                    {l.user?.expertType ? t(`General.ExpertTypesAlt.${l.user!.expertType}`) : '--'}
                  </div>
                </div>

                {vm.variant !== 'assign' && (
                  <div style={{ marginLeft: 20, width: 100, minWidth: 100 }}>
                    {idx === 0 && (
                      <div className="ui-text-center ui-text-xxs ui-text-uppercase ui-text-bold ui-text-muted" style={{ marginBottom: 5 }}>
                        {t('EditProject.Fees.HourlyRate')}
                      </div>
                    )}
                    <div className="ui-text-sm ui-flex ui-frame ui-frame-no-padding ui-text-center" style={{ height: 25, lineHeight: '23px' }}>
                      {t('General.Currency.Value', { value: l.rate })}
                    </div>
                  </div>
                )}

                {vm.variant !== 'assign' && (
                  <div style={{ marginLeft: 10, width: 100, minWidth: 100 }}>
                    {idx === 0 && (
                      <div className="ui-text-center ui-text-xxs ui-text-uppercase ui-text-bold ui-text-muted" style={{ marginBottom: 5 }}>
                        {t('EditProject.Fees.Cost')}
                      </div>
                    )}
                    <div
                      className={`ui-text-sm ui-flex ui-frame ui-frame-no-padding ui-text-center${l.status === ProjectStatus.Rejected ? ' ui-text-line-through' : ''}`}
                      style={{ height: 25, lineHeight: '23px' }}
                    >
                      {t('General.Currency.Value', { value: l.cost })}
                    </div>

                    {idx === estimate.labor.length - 1 && (
                      <div>
                        <hr style={{ margin: '10px 0' }} />
                        <div className="ui-text-sm ui-flex ui-frame ui-frame-no-padding ui-text-center ui-text-bold" style={{ height: 25, width: 100, lineHeight: '23px' }}>
                          {t('General.Currency.Value', { value: totalCost })}
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {vm.variant !== 'assign' && (
                  <div className="ui-text-sm" style={{ marginLeft: 20, width: 60, minWidth: 60 }}>
                    {idx === 0 && (
                      <div className="ui-text-center ui-text-xxs ui-text-uppercase ui-text-bold ui-text-muted" style={{ marginBottom: 5 }}>
                        {t('EditProject.Fees.FeeMargin')}
                      </div>
                    )}

                    {l.status === ProjectStatus.Rejected ? (
                      <div className="ui-frame ui-frame-no-padding ui-text-sm ui-text-center" style={{ height: 25, lineHeight: '23px' }}>
                        --
                      </div>
                    ) : (
                      <div className="ui-frame ui-frame-no-padding ui-text-sm ui-flex ui-text-center" style={{ height: 25, lineHeight: '23px' }}>
                        <span
                          className={
                            // eslint-disable-next-line no-nested-ternary
                            l.margin <= 0.25 ? 'ui-danger' : l.margin > 0.25 && l.margin <= 0.5 ? 'ui-warn' : 'ui-success'
                          }
                        >
                          {t('General.PercentValue', { value: (l.margin * 100).toFixed(1) })}
                        </span>
                      </div>
                    )}

                    {totalCost > 0 && idx === estimate.labor.length - 1 && (
                      <div>
                        <hr style={{ margin: '10px 0' }} />
                        <div className="ui-frame ui-frame-no-padding ui-text-sm ui-text-center ui-text-bold" style={{ height: 25, lineHeight: '23px' }}>
                          <span
                            className={
                              // eslint-disable-next-line no-nested-ternary
                              (totalMargin || 0) <= 0.25 ? 'ui-danger' : (totalMargin || 0) > 0.25 && (totalMargin || 0) <= 0.5 ? 'ui-warn' : 'ui-success'
                            }
                          >
                            {t('General.PercentValue', { value: (totalMargin ? totalMargin * 100 : 0).toFixed(1) })}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div style={{ width: 30, marginLeft: 5 }}>
                  <button
                    type="button"
                    disabled={saving || !canEdit || l.status === ProjectStatus.Rejected}
                    style={{ width: '100%', marginTop: idx === 0 ? 19 : 0 }}
                    className="ui-btn-empty ui-danger"
                    onClick={() => {
                      const vm = { ...estimate, labor: [...estimate.labor] }

                      let recoveredHours = vm.labor[idx].hours || 0
                      vm.labor.splice(idx, 1)

                      const len = vm.labor.filter((l) => l.status === ProjectStatus.New && !!l.user).length
                      const newHours = len > 0 ? recoveredHours / len : 0

                      vm.labor.forEach((l) => {
                        if (l.status === ProjectStatus.New) {
                          if (!l.hours) l.hours = 0
                          l.hours += Math.min(newHours, recoveredHours)
                          recoveredHours -= newHours
                        }

                        l.split = (l.hours || 0) / vm.hours
                      })

                      /*
                      if (vm.labor.length > 0) {
                        const split = 1 / vm.labor.length;
                        vm.labor.forEach((l) => {
                          l.split = split;
                          l.hours = vm.hours * l.split;
                          l.cost = l.hours * l.rate;
                        });
                      }
                      */
                      onChange(vm)
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: '1.05rem' }} />
                  </button>
                </div>
              </div>
            </React.Fragment>
          )
        })}

        {canAdd && estimate.labor.length <= 0 && (
          <button type="button" className="ui-btn-empty ui-btn-sm ui-primary" style={{ marginTop: 15 }} onClick={addLabor} tabIndex={-1}>
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;
            {t('EditProject.Fees.AddLabor')}
          </button>
        )}
      </div>

      {history && (
        <InfoModal
          className="ui-modal-lg"
          title={history.user ? `${history.user!.givenName} ${history.user.familyName} History` : 'History'}
          closeOnClickOutside={false}
          message={
            <div className="ui-frame ui-text-sm" style={{ overflow: 'auto', padding: '2px 10px', maxHeight: 300 }}>
              <div className="ui-flex ui-text-bold" style={{ marginBottom: 5 }}>
                <div style={{ width: 180 }}>Date</div>
                <div style={{ width: 180 }}>Status Change</div>
                <div style={{ marginLeft: 20, flex: 1 }}>Additional Information</div>
              </div>
              {history.statusHistory.map((sh, idx) => (
                <div key={idx} className="ui-flex" style={{ marginBottom: 5 }}>
                  <div style={{ width: 180 }}>{moment(sh.createdAt).format('ll LT')}</div>
                  <div style={{ width: 180 }}>
                    {sh.oldStatus} =&gt; {sh.newStatus}
                  </div>
                  <div style={{ marginLeft: 20, flex: 1 }}>{sh.metadata.reason}</div>
                </div>
              ))}
              <div className="ui-flex">
                <div style={{ width: 180 }}>{moment(history.createdAt).format('ll LT')}</div>
                <div>Created</div>
              </div>
            </div>
          }
          ok={t('General.Ok')}
          onClose={() => setHistory(undefined)}
        />
      )}

      {assignment && (
        <AssignmentPanel
          hours={assignment.estimate.estimate.manualLevelOfEffortHours}
          discipline={assignment.estimate.discipline}
          onClose={() => setAssignment(undefined)}
          expertTypes={[DisciplineKey.ProjectPrincipal, DisciplineKey.ProjectManagement].includes(assignment.estimate.discipline as DisciplineKey) ? ['FTE'] : ['Contractor']}
          canAssignAll
          onSubmit={(user, assignToAll) => {
            setAssignment(undefined)
            setUser(user, assignment.idx, assignToAll)
          }}
        />
      )}
    </>
  )
}
