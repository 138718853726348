import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PhaseEx, useCurrentUserAccount, useEditProject, useMe, useProjectFilesWs, useRights } from 'services/src/state'
import { useAlert } from 'components/src/alerts'
import { FileList, ProjectContentGroup } from 'components/src/fileList'
import { Content, ProjectContentCategory } from 'services/src/dto/content'
import { Dropzone } from 'components/src/dropzone'
import axios, { Canceler } from 'axios'
import { createProEst, deleteFile, getProEst, uploadFile, UploadFileEvent } from 'services/src/api'
import { ConfirmModal } from 'components/src/modal/ConfirmModal'
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { ProjectStatus } from 'services/src/dto/project'
import { useSaving } from 'components/src/saving'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpFromBracket, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import { StatusBadge } from 'components/src/statusBadge'
import { DocumentView } from 'components/src/documentView'
import { ProEstEstimate, ProEstEstimateDetails } from 'services/src/dto/proEst'
import { ProEstEstimateGrid } from 'components/src/proEst'
import { TabBar } from 'components/src/tabs'
import { Loading } from 'components/src/loading'

import { Uploading } from '../../common/Uploading'
import { Footer } from '../Footer'
import { Patch } from 'services/src/dto/common'

export const ExpertEstimate: React.FC<{
  phaseId: string
}> = ({ phaseId }) => {
  const { t } = useTranslation()
  const [me] = useMe()
  const { path, url } = useRouteMatch()
  const { pathname } = useLocation()
  const history = useHistory()
  const { project, patch } = useEditProject()
  const { alertSuccess, alertDanger } = useAlert()
  const [, setSaving] = useSaving()
  const [account] = useCurrentUserAccount()
  const [saving] = useSaving()
  const rights = useRights()

  const { phase, phaseIds, estimateIds } = useMemo<{
    phase: PhaseEx
    phaseIds: string[]
    estimateIds: string[]
  }>(() => {
    const phase = project.phases.find((x) => x.id === phaseId)!
    const estimateIds = phase.estimates?.filter((x) => !!x.labor?.find((l) => l.userId === me?.id)).map((est) => est.id) || []
    return { phase, phaseIds: [phase.id!], estimateIds }
  }, [project, phaseId, me])

  const { files: projectFiles } = useProjectFilesWs(project, phaseIds, ProjectContentCategory.Estimate, estimateIds)

  const [files, setFiles] = useState<File[]>()
  const [currentFileIndex, setCurrentFileIndex] = useState<number>(-1)
  const [currentProgress, setCurrentProgress] = useState<number>(0)

  const canceler = useRef<Canceler>()

  const onProgress = useCallback(
    (e: UploadFileEvent) => {
      if (e.error) {
        canceler.current = undefined
        setCurrentFileIndex(-1)
        setFiles(undefined)
        setCurrentProgress(0)
        if (e.error.code !== 'ERR_CANCELED') {
          alertDanger({
            title: t('General.Errors.FileUploadTitle'),
            message: t('General.Errors.FileUpload'),
            error: e.error
          })
        }
        return
      }

      if (!files) return

      if (e.state === 'cancel') {
        canceler.current = undefined
        setFiles(undefined)
        setCurrentProgress(0)
        return
      }

      if (e.state === 'complete') {
        let nextIndex = currentFileIndex + 1
        if (nextIndex >= files.length) nextIndex = -1
        setCurrentFileIndex(nextIndex)
        if (nextIndex < 0) {
          canceler.current = undefined
          setFiles(undefined)
          setCurrentProgress(0)
        }
        return
      }

      setCurrentProgress(e.progress)
    },
    [canceler, currentFileIndex, setCurrentFileIndex, files, setFiles, setCurrentProgress, alertDanger]
  )

  useEffect(() => {
    if (currentFileIndex < 0 || !files || !project?.id || !phase?.id) return

    // Are we done?
    if (currentFileIndex >= files.length) {
      canceler.current = undefined
      setCurrentFileIndex(-1)
      setFiles(undefined)
      setCurrentProgress(0)
      return
    }

    setCurrentProgress(0)
    uploadFile(
      project.account!.id,
      project.id,
      ProjectContentCategory.Estimate,
      phase.id,
      files[currentFileIndex],
      onProgress,
      new axios.CancelToken((c: Canceler) => {
        canceler.current = c
      }),
      estimateIds.length ? estimateIds[0] : undefined,
      {
        userId: me!.id,
        givenName: me!.givenName,
        familyName: me!.familyName
      }
    ).catch((error) => {
      canceler.current = undefined
      setCurrentFileIndex(-1)
      setFiles(undefined)
      setCurrentProgress(0)
      alertDanger({
        title: t('General.Errors.FileUploadTitle'),
        message: t('General.Errors.FileUpload'),
        error
      })
    })
  }, [currentFileIndex, setCurrentFileIndex, setFiles, setCurrentProgress, alertDanger])

  const handleDrop = useCallback(
    (category: string, acceptedFiles: File[]) => {
      if (!phase?.id) return

      setFiles(acceptedFiles)
      setCurrentProgress(0)
      setTimeout(() => setCurrentFileIndex(0))
    },
    [project, phase, setFiles, setCurrentProgress, setCurrentFileIndex]
  )

  const [showFileDialog, setShowFileDialog] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState<Content>()

  const estimates = useMemo(() => phase.estimates?.filter((est) => !!est.labor?.find((l) => l.userId === me?.id)) || [], [phase])

  const inReview = useMemo<boolean>(
    () =>
      (estimates.filter((est) => !!est.labor?.find((l) => l.status === ProjectStatus.InProgress && (l.statusHistory[0]?.metadata?.providerReview || l.statusHistory[0]?.metadata?.clientReview)))
        ?.length || 0) > 0,
    [estimates]
  )

  const canSendToProvider = useMemo<boolean>(
    () =>
      (estimates.filter((est) => !!est.labor?.find((l) => l.status === ProjectStatus.InProgress && !l.statusHistory[0]?.metadata?.providerReview && !l.statusHistory[0]?.metadata?.clientReview))
        ?.length || 0) > 0,
    [estimates]
  )

  const [confirmSendToProvider, setConfirmSendToProvider] = useState<boolean>(false)

  const [fileGroups, setFileGroups] = useState<ProjectContentGroup[]>()
  useEffect(() => {
    if (!projectFiles || !estimates) return
    if (projectFiles.length > 0 && !projectFiles.find((pf) => pf.category === ProjectContentCategory.Estimate)) return

    if (projectFiles.length <= 0) {
      setFileGroups([])
      return
    }

    setFileGroups([
      {
        groupId: me!.id,
        group: (
          <div className="ui-flex ui-flex-nowrap ui-text-sm" style={{ margin: '5px 0', padding: '0 10px', justifyContent: 'flex-end' }}>
            <div className="ui-flex ui-md-right">
              <StatusBadge style={{ width: 120, maxWidth: 120, cursor: 'default' }} label={t(`Expert.Status.${estimates[0].labor[0].status}`)} type={estimates[0].labor[0].status} />
            </div>
          </div>
        ),
        files: projectFiles.filter((f) => f.metadata?.userId === me!.id).sort((a, b) => a.originalName.localeCompare(b.originalName))
      }
    ])
  }, [phase, projectFiles, estimates, setFileGroups, canSendToProvider, inReview])

  const [wrapperHeight, setWrapperHeight] = useState(window.innerHeight - (canSendToProvider ? 345 : 295))
  const resize = useCallback(() => setWrapperHeight(window.innerHeight - (canSendToProvider ? 345 : 295)), [setWrapperHeight, canSendToProvider, pathname])

  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  const [proEstReady, setProEstReady] = useState(false)
  const [proEst, setProEst] = useState<{ estimate: ProEstEstimate; details: ProEstEstimateDetails }>()
  const loadProEst = useCallback(() => {
    if (!project || !phase) return

    if (!phase.proEst) {
      setProEstReady(true)
      return
    }

    getProEst(project.account.id, project.id!, phase.id!)
      .then((proEst) => setProEst(proEst))
      .finally(() => setProEstReady(true))
  }, [project, phase, setProEst])

  useEffect(() => loadProEst(), [project, phase, loadProEst])

  const tabs = useMemo(() => {
    const tabs = [
      {
        label: t('EditProject.EstimateFiles'),
        selected: pathname.endsWith('estimate'),
        to: url
      }
    ]

    if (phase.proEst?.id) {
      tabs.push({
        label: t('EditProject.EstimateDetails'),
        selected: pathname.endsWith('details'),
        to: `${url}/details`
      })
    }

    return tabs
  }, [phase, pathname, url, t])

  const warning = useMemo(() => {
    if (inReview)
      return (
        <>
          <FontAwesomeIcon icon={faExclamationTriangle} /> {t('EditProject.PMEstimateReview')}
        </>
      )

    if (!inReview && !canSendToProvider)
      return (
        <>
          <FontAwesomeIcon icon={faExclamationTriangle} /> {t('EditProject.EstimateComplete')}
        </>
      )
    return null
  }, [inReview, canSendToProvider])

  if (!phase || !rights) return null

  return (
    <>
      <div className="edit-project-files">
        <div className="edit-project-files-content">
          <TabBar tabs={tabs} style={{ justifyContent: 'center', height: 30 }} onChange={() => setTimeout(resize)} />

          <Switch>
            <Route path={`${path}/details`} exact>
              <div className="estimate-files" style={{ height: wrapperHeight }}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {!proEstReady ? (
                  <Loading size="sm" variant="parent" style={{ marginTop: 100 }} className="ui-loading-no-overlay" />
                ) : proEst ? (
                  <ProEstEstimateGrid estimate={proEst.estimate} details={proEst.details} />
                ) : (
                  <div className="ui-text-center" style={{ marginTop: 80 }}>
                    <div className="ui-text-lg ui-danger">
                      <FontAwesomeIcon icon={faExclamationTriangle} /> {t('ProEst.NoEstimate')}
                    </div>
                    <br />
                    <br />
                    <div style={{ maxWidth: 600, margin: '0 auto' }}>{t('ProEst.NoEstimateEx')}</div>
                    <div className="ui-flex" style={{ marginTop: 20, justifyContent: 'center' }}>
                      <button
                        type="button"
                        className="ui-btn ui-btn-primary ui-btn-solid"
                        onClick={() => {
                          setSaving(true)
                          createProEst(project.account.id, project.id!, phase.id!)
                            .then(({ estimate }) => {
                              if (estimate.id) window.open(`https://cloud.proest.com/projects/${estimate.id}/estimate`, 'proEst')
                              loadProEst()
                            })
                            .finally(() => setSaving(false))
                        }}
                      >
                        {t('ProEst.Create')}
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {canSendToProvider && (
                <Footer>
                  <div className="ui-flex" style={{ alignItems: 'center' }}>
                    <div style={{ marginLeft: 'auto' }} />

                    {canSendToProvider && (
                      <button type="button" className="ui-btn ui-btn-secondary ui-btn-solid" disabled={saving} style={{ width: 'auto' }} onClick={() => setConfirmSendToProvider(true)}>
                        {t('Expert.Estimates.SendToProvider')}
                      </button>
                    )}
                  </div>
                </Footer>
              )}
            </Route>

            <Route path={`${path}/:contentId`}>
              <DocumentView category={ProjectContentCategory.Estimate} back={url} />
            </Route>

            <Route path={path} exact>
              <>
                <div className="estimate-files" style={{ height: wrapperHeight }}>
                  {canSendToProvider && ![ProjectStatus.Complete, ProjectStatus.Canceled].includes(phase.status!) && (
                    <>
                      <div className="ui-flex">
                        <div className="ui-form-group" style={{ width: 280, height: 200 }}>
                          <div className="ui-frame ui-frame-bg edit-project-files-instructions" style={{ height: '100%', padding: 0 }}>
                            <div className="title">{t('EditProject.Instructions.Title')}</div>
                            <div className="content" dangerouslySetInnerHTML={{ __html: t('EditProject.Instructions.ExpertEstimate') }} />
                          </div>
                        </div>

                        <div className="edit-project-files-dropzone ui-form-group" style={{ height: 200, marginLeft: 20, flex: 1, minWidth: 300 }}>
                          <Dropzone onDropAccepted={(files) => handleDrop(ProjectContentCategory.Estimate, files)} showFileDialog={showFileDialog}>
                            <div style={{ marginTop: 40, alignItems: 'center' }} className="ui-flex ui-flex-column">
                              <div>{t('General.DropFile')}</div>
                              <button type="button" className="ui-btn ui-btn-solid ui-btn-primary" style={{ marginTop: 30 }}>
                                {t('General.UploadEstimates')}
                                <FontAwesomeIcon icon={faArrowUpFromBracket} style={{ marginLeft: 10 }} />
                              </button>
                            </div>
                          </Dropzone>
                        </div>
                      </div>
                    </>
                  )}

                  <FileList
                    groupedFiles={fileGroups}
                    noFilesLabel={
                      <div className="ui-text-lg ui-warn ui-text-center" style={{ marginTop: 50 }}>
                        <div>
                          <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
                        </div>
                        <div style={{ marginTop: 30 }}>{t('General.NoEstimates')}</div>
                      </div>
                    }
                    canDelete={canSendToProvider}
                    onDelete={(content) => {
                      setConfirmDelete(content)
                    }}
                    onUpload={() => {
                      setShowFileDialog(true)
                      setTimeout(() => setShowFileDialog(false))
                    }}
                    onOpen={(content) => {
                      history.push(`${url}/${content.id}`)
                    }}
                    warning={warning}
                  />
                </div>

                {canSendToProvider && (
                  <Footer>
                    <div className="ui-flex" style={{ alignItems: 'center' }}>
                      <div style={{ marginLeft: 'auto' }} />

                      {canSendToProvider && (
                        <button type="button" className="ui-btn ui-btn-secondary ui-btn-solid" disabled={saving} style={{ width: 'auto' }} onClick={() => setConfirmSendToProvider(true)}>
                          {t('Expert.Estimates.SendToProvider')}
                        </button>
                      )}
                    </div>
                  </Footer>
                )}
              </>
            </Route>
          </Switch>
        </div>

        {files && currentFileIndex >= 0 && (
          <Uploading
            filename={files[currentFileIndex].name}
            progress={currentProgress}
            onCancel={() => {
              // Cancel the upload
              if (canceler.current) canceler.current()

              canceler.current = undefined
              setCurrentFileIndex(-1)
              setFiles(undefined)
              setCurrentProgress(0)
            }}
          />
        )}
      </div>

      {confirmSendToProvider && (
        <ConfirmModal
          title={t('Expert.Estimates.SendToProvider')}
          message={t('Expert.Estimates.SendToProviderMessage')}
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={() => {
            setConfirmSendToProvider(false)

            let p: Patch[] = []

            estimates
              .filter((est) => !!est.labor?.find((l) => l.status === ProjectStatus.InProgress))
              .forEach((est) => {
                p = [
                  ...p,
                  ...est.labor.map((l) => ({
                    propertyName: `Phases.${phase.id}.Estimates.${est.id}.Labor.${l.id}.Status`,
                    propertyValue: {
                      status: ProjectStatus.InProgress,
                      metadata: {
                        providerReview: true
                      }
                    }
                  }))
                ]
              })

            if (!p?.length) return

            setSaving(true)
            patch(p)
              .then(() => {
                alertSuccess({
                  title: t('Expert.Estimates.EstimateSentTitle'),
                  message: t('Expert.Estimates.EstimateSent')
                })
              })
              .catch((error) => {
                alertDanger({
                  title: t('Expert.Estimates.Errors.EstimateSentTitle'),
                  message: t('Expert.Estimates.Errors.EstimateSent'),
                  error
                })
              })
              .finally(() => setSaving(false))
          }}
          onClose={() => setConfirmSendToProvider(false)}
        />
      )}

      {confirmDelete && (
        <ConfirmModal
          title={t('EditProject.DeleteEstimateTitle')}
          message={t('EditProject.DeleteEstimate', { name: confirmDelete.originalName })}
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={() => {
            setConfirmDelete(undefined)
            if (!account || !project.id || !confirmDelete?.id) return

            setSaving(true)
            deleteFile(account.id, project.id, confirmDelete.id)
              .then(() => {
                alertSuccess({
                  title: t('EditProject.DeletedEstimateTitle'),
                  message: t('EditProject.DeletedEstimate', { name: confirmDelete.originalName })
                })
              })
              .catch((error) => {
                alertDanger({
                  title: t('EditProject.Errors.DeletedDocumentTitle'),
                  message: t('EditProject.Errors.DeletedDocument'),
                  error
                })
              })
              .finally(() => setSaving(false))
          }}
          onClose={() => setConfirmDelete(undefined)}
        />
      )}
    </>
  )
}
