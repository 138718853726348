import React, { SVGProps } from 'react'
import './style.scss'

const Offices: React.FC<SVGProps<SVGSVGElement>> = ({ className, ...rest }) => (
  <svg viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" className={`ui-svg ui-svg-offices ${className || ''}`} {...rest}>
    <path d="M3.45312 19.6973V5.94727L11.7031 3.19727V16.9473" strokeWidth="1" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.7656 8.00977L18.5781 10.0723V17.6348" strokeWidth="1" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.39062 19.6973H20.6406" strokeWidth="1" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.57812 2.50977V4.57227" strokeWidth="1" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.26562 8.69727C8.26562 8.35352 8.54063 8.00977 8.95312 8.00977C9.29688 8.00977 9.64062 8.35352 9.64062 8.69727C9.64062 9.04102 9.29688 9.38477 8.95312 9.38477C8.54063 9.38477 8.26562 9.04102 8.26562 8.69727ZM5.51562 8.69727C5.51562 8.35352 5.85938 8.00977 6.20312 8.00977C6.54688 8.00977 6.89062 8.35352 6.89062 8.69727C6.89062 9.04102 6.54688 9.38477 6.20312 9.38477C5.85938 9.38477 5.51562 9.04102 5.51562 8.69727Z" />
    <path d="M8.26562 12.1348C8.26562 11.791 8.54063 11.4473 8.95312 11.4473C9.29688 11.4473 9.64062 11.791 9.64062 12.1348C9.64062 12.4785 9.29688 12.8223 8.95312 12.8223C8.54063 12.8223 8.26562 12.4785 8.26562 12.1348ZM5.51562 12.1348C5.51562 11.791 5.85938 11.4473 6.20312 11.4473C6.54688 11.4473 6.89062 11.791 6.89062 12.1348C6.89062 12.4785 6.54688 12.8223 6.20312 12.8223C5.85938 12.8223 5.51562 12.4785 5.51562 12.1348Z" />
    <path d="M8.26562 15.5723C8.26562 15.2285 8.54063 14.8848 8.95312 14.8848C9.29688 14.8848 9.64062 15.2285 9.64062 15.5723C9.64062 15.916 9.29688 16.2598 8.95312 16.2598C8.54063 16.2598 8.26562 15.916 8.26562 15.5723ZM5.51562 15.5723C5.51562 15.2285 5.85938 14.8848 6.20312 14.8848C6.54688 14.8848 6.89062 15.2285 6.89062 15.5723C6.89062 15.916 6.54688 16.2598 6.20312 16.2598C5.85938 16.2598 5.51562 15.916 5.51562 15.5723Z" />
    <path d="M14.4531 12.1348H15.8281" strokeWidth="1" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
export default Offices
