import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons'

import { Account, ResourceUserRole } from 'services/src/dto/account'
import { CreateInvitation, Invitation } from 'services/src/dto/invitation'

import { Modal, ModalProps } from 'components/src/modal'
import { TextField } from 'components/src/textField'
import { SelectField } from 'components/src/selectField'
import { validateEmail } from 'services/src/validate'
import { createInvitation, updatePhaseUsers, updateProjectUsers } from 'services/src/api'
import { useAlert } from 'components/src/alerts'
import { ProjectEx, PhaseEx } from 'services/src/state'
import { useSaving } from 'components/src/saving'
import { UserEx } from '../../../resourceUsers'
import { AssignmentPanel } from './AssignmentPanel'

type InviteModalProps = Omit<ModalProps, 'onClose'> & {
  account: Account
  project: ProjectEx
  phase?: PhaseEx
  onClose: (args: { invitation?: Invitation; user?: UserEx }) => void
  onAssignUser?: () => void
}

export const InviteModal: React.FC<InviteModalProps> = ({ account, project, phase, onClose, onAssignUser, ...rest }) => {
  const { t } = useTranslation()
  const [saving, setSaving] = useSaving()

  const [errors, setErrors] = useState<any>({})
  const clearError = (name: string) => {
    const theErrors = { ...errors }
    delete theErrors[name]
    setErrors(theErrors)
  }

  const { alertDanger, alertSuccess } = useAlert()

  const [selectedUser, setSelectedUser] = useState<UserEx>()
  const [showAssignmentPanel, setShowAssignmentPanel] = useState(false)

  const [givenName, setGivenName] = useState('')
  const [familyName, setFamilyName] = useState('')
  const [email, setEmail] = useState('')
  const [inviteReady, setInviteReady] = useState(false)

  const roles = useMemo(() => {
    if (phase) {
      return [
        {
          role: ResourceUserRole.Owner.toString(),
          label: t(`General.PhaseRoles.${ResourceUserRole.Owner}`)
        },
        {
          role: ResourceUserRole.Administrator.toString(),
          label: t(`General.PhaseRoles.${ResourceUserRole.Administrator}`)
        },
        {
          role: ResourceUserRole.Contributor.toString(),
          label: t(`General.PhaseRoles.${ResourceUserRole.Contributor}`)
        },
        {
          role: ResourceUserRole.Guest.toString(),
          label: t(`General.PhaseRoles.${ResourceUserRole.Guest}`)
        }
      ]
    }
    return [
      {
        role: ResourceUserRole.Owner.toString(),
        label: t(`General.ProjectRoles.${ResourceUserRole.Owner}`)
      },
      {
        role: ResourceUserRole.Administrator.toString(),
        label: t(`General.ProjectRoles.${ResourceUserRole.Administrator}`)
      },
      {
        role: ResourceUserRole.Contributor.toString(),
        label: t(`General.ProjectRoles.${ResourceUserRole.Contributor}`)
      },
      {
        role: ResourceUserRole.Guest.toString(),
        label: t(`General.ProjectRoles.${ResourceUserRole.Guest}`)
      }
    ]
  }, [phase, t])

  const [role, setRole] = useState<string>()

  const existingUserIds = useMemo(() => {
    const existingUserIds: string[] = []
    if (phase) phase.users?.forEach((u) => existingUserIds.push(u.id))
    project.users?.forEach((u) => existingUserIds.push(u.id))
    return existingUserIds
  }, [project, phase])

  const submitInvite = useCallback(() => {
    const errors: any = {}

    if (!givenName) errors.givenName = t('General.Errors.Required')
    if (!familyName) errors.familyName = t('General.Errors.Required')
    if (!email) errors.email = t('General.Errors.Required')
    else if (!validateEmail(email)) errors.email = t('General.Errors.InvalidEmail')
    if (!role) errors.role = t('General.Errors.Required')

    setErrors(errors)
    if (Object.keys(errors).length) return

    const invite: CreateInvitation = {
      givenName,
      familyName,
      emailAddress: email
    }
    if (phase) {
      invite.phaseId = phase.id
      invite.phaseRole = role
    } else {
      invite.projectId = project.id
      invite.projectRole = role
    }

    setSaving(true)
    createInvitation(project.account.id, invite)
      .then((invitation) => {
        alertSuccess({
          title: t('EditProject.InviteCreateTitle'),
          message: t('EditProject.InviteCreate', { name: `${givenName} ${familyName}`, email })
        })
        onClose({ invitation })
      })
      .catch((err) => {
        alertDanger({
          title: t('EditProject.Errors.InviteCreateTitle'),
          message: t('EditProject.Errors.InviteCreate'),
          error: err
        })
        onClose({})
      })
      .finally(() => setTimeout(() => setSaving(false)))
  }, [email, role, givenName, familyName, account, project, setErrors, onClose, phase, alertDanger, alertSuccess, project, t])

  const submitAssign = useCallback(() => {
    const errors: any = {}

    if (!selectedUser) errors.selectedUser = t('General.Errors.Required')
    if (!role) errors.role = t('General.Errors.Required')

    setErrors(errors)
    if (Object.keys(errors).length) return

    if (phase) {
      setSaving(true)
      updatePhaseUsers(account.id, project.id!, phase.id!, [{ userId: selectedUser!.id, role: role as ResourceUserRole }])
        .then(() => {
          alertSuccess({
            title: t('EditProject.AssignmentTitle'),
            message: t('EditProject.Assignment', { name: `${selectedUser!.givenName} ${selectedUser!.familyName}` })
          })
          onClose({ user: { ...selectedUser!, role: role as ResourceUserRole } })
        })
        .catch((err) => {
          alertDanger({
            title: t('EditProject.Errors.AssignmentTitle'),
            message: t('EditProject.Errors.Assignment'),
            error: err
          })
          onClose({})
        })
        .finally(() => {
          setSaving(false)
        })

      return
    }

    setSaving(true)
    updateProjectUsers(account.id, project.id!, [{ userId: selectedUser!.id, role: role as ResourceUserRole }])
      .then(() => {
        alertSuccess({
          title: t('EditProject.AssignmentTitle'),
          message: t('EditProject.Assignment', { name: `${selectedUser!.givenName} ${selectedUser!.familyName}` })
        })
        onClose({ user: { ...selectedUser!, role: role as ResourceUserRole } })
      })
      .catch((err) => {
        alertDanger({
          title: t('EditProject.Errors.AssignmentTitle'),
          message: t('EditProject.Errors.Assignment'),
          error: err
        })
        onClose({})
      })
      .finally(() => {
        setSaving(false)
      })
  }, [role, selectedUser, account, project, setErrors, onClose, phase, alertDanger, alertSuccess, project, t])

  if (saving) return null

  return (
    <Modal
      header={
        phase ? (
          <>
            <h3>{t('EditProject.PhaseInviteTitle')}</h3>
            <div className="ui-text-sm ui-text-muted">
              {t(`EditProject.${phase.phaseType}`)}
              {phase.name && (
                <span>
                  : <span className="ui-text-italic">{phase.name}</span>
                </span>
              )}
            </div>
          </>
        ) : (
          <h3>{t('EditProject.ProjectInviteTitle')}</h3>
        )
      }
      closeOnClickOutside={false}
      onClose={() => onClose({})}
      {...rest}
    >
      <SelectField
        items={roles}
        selected={role}
        onSelectedChange={(item) => {
          setRole(item.role)
          clearError('role')
        }}
        labelField="label"
        valueField="role"
        error={errors.role}
        label={t('EditProject.InviteRole')}
        placeholder={t(phase ? 'EditProject.PhaseInviteRoleSample' : 'EditProject.InviteRoleSample')}
        maxDropDown={5}
        renderItem={(r) => <div>{r.label}</div>}
        renderSelected={(r) => <div>{t(`EditProject.${r}`)}</div>}
      />

      <div className="ui-frame ui-frame-bg ui-frame-focus-within" style={{ padding: 20, marginBottom: 20, marginTop: 20 }}>
        <div className={`ui-row${errors.givenName || errors.familyName || errors.email ? ' ui-has-error' : ''}`}>
          <div className="ui-col-12">
            <label>{t('EditProject.To')}</label>
          </div>
          <div className="ui-col-6">
            <TextField
              name="givenName"
              type="text"
              value={givenName}
              error={errors.givenName}
              placeholder={t('EditProject.GivenNameSample')}
              onChange={(v) => {
                setGivenName(v)
                clearError('givenName')
                setTimeout(() => setInviteReady(!!(email && givenName && familyName)))
              }}
            />
          </div>

          <div className="ui-col-6">
            <TextField
              name="familyName"
              type="text"
              value={familyName}
              error={errors.familyName}
              placeholder={t('EditProject.FamilyNameSample')}
              onChange={(v) => {
                setFamilyName(v)
                clearError('familyName')
                setTimeout(() => setInviteReady(!!(email && givenName && familyName)))
              }}
            />
          </div>

          <div className={`ui-col-12${errors.email ? ' ui-has-error' : ''}`}>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              placeholder={t('EditProject.ToSample')}
              onChange={(e) => {
                setEmail(e.currentTarget.value)
                clearError('email')
                setTimeout(() => setInviteReady(!!(email && givenName && familyName)))
              }}
            />
            {errors.email && <div className="ui-error">{errors.email}</div>}
          </div>
        </div>

        <div className="ui-flex" style={{ marginTop: 30 }}>
          <button type="button" id="submitInvite" className="ui-btn ui-btn-primary ui-btn-solid" style={{ flex: 1 }} disabled={saving || !inviteReady} onClick={() => submitInvite()}>
            {t('EditProject.SendExternalInvite')}
          </button>
        </div>
      </div>

      <div className={`ui-frame ui-frame-bg ui-frame-focus-within ${errors.selectedUser ? ' ui-has-error' : ''}`} style={{ padding: 20 }}>
        <div>
          {selectedUser ? (
            <div className="ui-flex">
              <div className="ui-flex" style={{ alignItems: 'center' }}>
                {selectedUser.pictureUrl ? (
                  <div className="ui-picture-circle">
                    <div style={{ backgroundImage: `url("${selectedUser.pictureUrl}")` }} />
                  </div>
                ) : (
                  <div className="ui-picture-circle">
                    <div>
                      {selectedUser.givenName[0].toUpperCase()}
                      {selectedUser.familyName[0].toUpperCase()}
                    </div>
                  </div>
                )}
                <div style={{ marginLeft: 5 }}>
                  {selectedUser.givenName} {selectedUser.familyName}
                </div>
              </div>

              <div style={{ marginLeft: 10 }}>
                <button
                  type="button"
                  className={`ui-btn-empty ${errors.selectedUser ? 'ui-danger' : 'ui-info'}`}
                  style={{ width: '100%', fontSize: '16px' }}
                  onClick={() => setShowAssignmentPanel(true)}
                >
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </button>
              </div>
            </div>
          ) : (
            <button type="button" className={`ui-btn-empty ${errors.selectedUser ? 'ui-danger' : 'ui-secondary'}`} style={{ fontSize: '16px' }} onClick={() => setShowAssignmentPanel(true)}>
              {t('EditProject.SelectExistingUser')}&nbsp;&nbsp;
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </button>
          )}
        </div>
        {errors.selectedUser && (
          <div className="ui-error" style={{ marginLeft: 44 }}>
            {errors.selectedUser}
          </div>
        )}

        <div className="ui-flex" style={{ marginTop: 20 }}>
          <button type="button" className="ui-btn ui-btn-primary ui-btn-solid" id="submitAssign" style={{ width: '100%', flex: 1 }} disabled={saving || !selectedUser} onClick={() => submitAssign()}>
            {t('EditProject.AssignUserToProject')}
          </button>
        </div>
      </div>

      {showAssignmentPanel && (
        <AssignmentPanel
          variant="client"
          overlay
          noSelectIds={existingUserIds}
          onClose={() => setShowAssignmentPanel(false)}
          onSubmit={(user) => {
            setShowAssignmentPanel(false)
            setSelectedUser(user)
          }}
        />
      )}
    </Modal>
  )
}
