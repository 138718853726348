import React from 'react'
import { Number, NumberProps } from './Number'

export type NumberFieldProps = NumberProps & {
  label?: string | JSX.Element
  error?: string | JSX.Element
}

export const NumberField: React.FC<NumberFieldProps> = ({ name, label, error, ...rest }) => (
  <div className={`ui-form-group ${error ? 'ui-has-error' : ''}`}>
    {label && <label htmlFor={name}>{label}</label>}
    <Number id={name} name={name} {...rest} />
    {error && <div className="ui-error">{error}</div>}
  </div>
)
