import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCircleUser, faDownload, faExclamationTriangle, faMinusCircle, faTimesCircle, faTrashAlt } from '@fortawesome/pro-regular-svg-icons'

import { Content } from 'services/src/dto/content'

import './style.scss'
import { ProjectContentEx, useRights, UserType } from 'services/src/state'
import moment from 'moment'
import { useMsalAccessToken } from 'services/src/aad'
import LoadingSvg from '../svg/Loading'
import { Loading } from '../loading'

interface ExplodeRowProps {
  content: ProjectContentEx
  onDownload: (content: Content | string) => void
}
const ExplodeRow: React.FC<ExplodeRowProps> = ({ content, onDownload }) => {
  const { t } = useTranslation()
  const rights = useRights()

  if (!content.id) {
    if (content.pageExplodeInfo) {
      return (
        <>
          <div className="drawing-thumb">
            <div />
          </div>
          {content.pageExplodeInfo.error ? (
            <div className="ui-danger" style={{ flex: 1, minWidth: 200, marginLeft: 15 }}>
              <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center' }}>
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <div style={{ marginLeft: 10 }}>{t('General.PageNo', { num: content.pageExplodeInfo.pageNo })}</div>
                <div style={{ marginLeft: 10 }}>{content.pageExplodeInfo.pageName}</div>
              </div>
              <div className="ui-text-xs" style={{ marginLeft: 25 }}>
                {content.pageExplodeInfo.error}
              </div>
            </div>
          ) : (
            <div style={{ flex: 1, minWidth: 200, marginLeft: 15 }}>
              <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center' }}>
                <div>{content.pageExplodeInfo.pageName}</div>
                <div style={{ marginLeft: 15, alignItems: 'center' }} className="ui-text-xs ui-flex ui-flex-nowrap">
                  <LoadingSvg style={{ width: 20, height: 20, position: 'static', transform: 'none' }} />
                  <div style={{ marginLeft: 5 }} className="ui-text-muted">
                    {t('General.Processing')}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )
    }
    return null
  }

  return (
    <>
      <div className="drawing-thumb">
        <div style={content.images?.thumb ? { backgroundImage: `url('${content.images.thumb}')` } : undefined} />
      </div>
      <div style={{ flex: 1, minWidth: 200, marginLeft: 15 }}>
        <div>
          <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center' }}>
            <div>{content.originalName}</div>
            {!content.pageExplodeInfo?.completedAt && (
              <div style={{ marginLeft: 15, alignItems: 'center' }} className="ui-text-xs ui-flex ui-flex-nowrap">
                <LoadingSvg style={{ width: 20, height: 20, position: 'static', transform: 'none' }} />
                <div style={{ marginLeft: 5 }} className="ui-text-muted">
                  {t('General.Processing')}
                </div>
              </div>
            )}
          </div>

          {(() => {
            if (!content.discipline) return null

            // Manually set?
            if (content.disciplineById)
              return (
                <div className="ui-badge ui-badge-success ui-badge-xs" style={{ display: 'inline-flex' }}>
                  <FontAwesomeIcon icon={faCircleUser} />
                  &nbsp;
                  {t(`General.Disciplines.${content.discipline}`)}
                </div>
              )

            if (content.classification)
              return (
                <div
                  className={`ui-badge ui-badge-xs ${
                    // eslint-disable-next-line no-nested-ternary
                    content.score! > 0.75 ? 'ui-badge-success' : content.score! > 0.5 ? 'ui-badge-warn' : 'ui-badge-danger'
                  }`}
                  style={{ display: 'inline-flex' }}
                >
                  <FontAwesomeIcon
                    icon={
                      // eslint-disable-next-line no-nested-ternary
                      content.score! > 0.75 ? faCheckCircle : content.score! > 0.5 ? faMinusCircle : faTimesCircle
                    }
                  />
                  &nbsp;
                  {t(`General.Disciplines.${content.classification}`)}
                  {rights?.userType === UserType.provider && <>&nbsp;({t('General.PercentValue', { value: (content.score || 0) * 100 })})</>}
                </div>
              )

            return null
          })()}
        </div>
      </div>

      <div style={{ minWidth: 100, maxWidth: 100 }} className="ui-text-ellipsis ui-text-right">
        {t('General.SizeAlt', { size: content.size })}
      </div>

      <div style={{ minWidth: 80, maxWidth: 80 }} className="ui-text-right">
        <button
          type="button"
          className="ui-btn-empty"
          onMouseDown={(e) => {
            if (e.button === 0) {
              e.preventDefault()
              e.stopPropagation()
              onDownload(content)
            }
          }}
          onClick={(e) => {
            e.preventDefault()
          }}
        >
          <FontAwesomeIcon icon={faDownload} />
        </button>
      </div>
    </>
  )
}

export interface DrawingListProps {
  drawings?: ProjectContentEx[]
  disciplines?: string[]
  onDelete: (drawingId: string) => void
  onOpen: (content: Content) => void
  canCreate?: boolean
}

export const DrawingList: React.FC<DrawingListProps> = ({ drawings, disciplines, onDelete, onOpen, canCreate }) => {
  const { t } = useTranslation()
  const accessToken = useMsalAccessToken()

  const [readyFiles, setReadyFiles] = useState<ProjectContentEx[]>()

  const download = useCallback(
    (content: Content | string) => {
      if (!readyFiles) return

      if (typeof content === 'string') {
        const f = readyFiles.find((x) => x.id === content)
        if (!f) return
        content = f
      }

      const a = document.createElement('a')

      const url = content.urlAlt || content.url
      a.href = url.includes('sv=') ? url : `${url}${url.includes('?') ? '&' : '?'}access_token=${accessToken}&t=${moment().valueOf()}&attached=${encodeURIComponent(content.originalName)}`
      a.download = content.originalName
      a.rel = 'noopener noreferrer'

      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    [readyFiles, accessToken]
  )

  useEffect(() => {
    if (!drawings) return

    if (disciplines?.length) {
      const readyFiles: ProjectContentEx[] = []
      drawings.forEach((f) => {
        if (!f.children) return

        const children = f.children.filter((c) => {
          if (!disciplines?.length) return true

          let result = true

          if (disciplines.includes('$noDiscipline$')) result = !c.discipline

          const ex = disciplines.filter((d) => !d.startsWith('$'))
          if (ex.length > 0) {
            if (!(c.discipline && ex.includes(c.discipline.toString()))) result = false
          }

          if (!result) return result

          if (disciplines.includes('$warn$') && disciplines.includes('$danger$')) {
            result = !c.disciplineById && !!c.classification && c.score! <= 0.75
          } else if (disciplines.includes('$warn$')) {
            result = !c.disciplineById && !!c.classification && c.score! > 0.5 && c.score! <= 0.75
          } else if (disciplines.includes('$danger$')) {
            result = !c.disciplineById && !!c.classification && c.score! <= 0.5
          }

          return result
        })
        if (children.length) readyFiles.push({ ...f, children })
      })
      setReadyFiles(readyFiles)
    } else setReadyFiles([...drawings])
  }, [drawings, disciplines, setReadyFiles])

  if (!readyFiles)
    return (
      <div className="ui-drawing-list" style={{ paddingTop: 100 }}>
        <Loading size="sm" variant="static" />
      </div>
    )

  return (
    <div className="ui-drawing-list" style={{ visibility: readyFiles ? 'visible' : 'hidden' }}>
      {readyFiles.length <= 0 && (
        <div className="ui-text-lg ui-warn ui-text-center" style={{ marginTop: 100 }}>
          <div>
            <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
          </div>
          <div style={{ marginTop: 30 }}>{t('General.NoDrawings')}</div>
        </div>
      )}

      {readyFiles.map((d) => {
        if (!d) return null

        return (
          <React.Fragment key={d.id}>
            <div
              className={`ui-flex ui-flex-nowrap ui-text-lg ui-drawing-list-parent${!d.children.length ? ' ui-drawing-list-no-children' : ''}`}
              style={{ alignItems: 'center' }}
              role="row"
              tabIndex={-1}
              onKeyDown={() => {}}
              onClick={(e) => {
                if (e.isDefaultPrevented()) return
                onOpen(d)
              }}
            >
              <div style={{ flex: 1, minWidth: 200, alignItems: 'center' }} className="ui-flex ui-flex-nowrap">
                <div className="ui-text-bold">{d.originalName}</div>
                {(() => {
                  if (d.explodeInfo?.totalPages)
                    return (
                      <div style={{ marginLeft: 10 }} className="ui-text-muted ui-text-sm">
                        ({t('General.PageCount', { count: d.explodeInfo?.totalPages })})
                      </div>
                    )
                  if (d.children.length > 0)
                    return (
                      <div style={{ marginLeft: 10 }} className="ui-text-muted ui-text-sm">
                        ({t('General.PageCount', { count: d.children.length })})
                      </div>
                    )
                  return null
                })()}
                {!d.explodeInfo?.completedAt && (
                  <div style={{ marginLeft: 15, alignItems: 'center' }} className="ui-text-xs ui-flex ui-flex-nowrap">
                    <LoadingSvg style={{ width: 20, height: 20, position: 'static', transform: 'none' }} />
                    <div style={{ marginLeft: 5 }} className="ui-text-muted">
                      {t('General.Processing')}
                    </div>
                  </div>
                )}
              </div>
              <div style={{ minWidth: 150, maxWidth: 150 }} className="ui-text-ellipsis">
                {moment(d.createdAt).format('l')}
              </div>
              <div style={{ minWidth: 100, maxWidth: 100 }} className="ui-text-ellipsis ui-text-right">
                {t('General.SizeAlt', { size: d.size })}
              </div>

              <div style={{ minWidth: 80, maxWidth: 80 }} className="ui-text-right">
                {canCreate && (
                  <button
                    type="button"
                    className="ui-btn-empty ui-danger"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      onDelete(d.id)
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                )}
                <button
                  type="button"
                  className="ui-btn-empty"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    download(d)
                  }}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </button>
              </div>
            </div>

            {d.children.length > 0 && (
              <div className="ui-drawing-list-children">
                {d.children.map((c, idx) => (
                  <div
                    key={idx}
                    className={`ui-flex ui-flex-nowrap ${idx % 2 === 0 ? 'even' : 'odd'}`}
                    style={{ alignItems: 'center' }}
                    role="row"
                    tabIndex={-1}
                    onKeyDown={() => {}}
                    onClick={(e) => {
                      if (e.isDefaultPrevented()) return
                      onOpen(c)
                    }}
                  >
                    <ExplodeRow content={c} onDownload={download} />
                  </div>
                ))}
              </div>
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}
