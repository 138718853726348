import React from 'react'
import { Dropzone, DropzoneProps } from '../dropzone'

export type DropzoneFieldProps = DropzoneProps & {
  label?: string | JSX.Element
  error?: string | JSX.Element
}

export const DropzoneField: React.FC<React.PropsWithChildren<DropzoneFieldProps>> = ({ label, error, ...props }) => (
  <div className={`ui-form-group ${error ? 'ui-has-error' : ''}`}>
    {label && <label>{label}</label>}
    <Dropzone {...props} />
    {error && <div className="ui-error">{error}</div>}
  </div>
)
