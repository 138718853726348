import React from 'react'
import { useTranslation } from 'react-i18next'

import LockSvg from 'components/src/svg/Lock'
import { changePassword } from 'services/src/aad'
import { Link } from 'react-router-dom'
import { makeDashboardPath } from 'services/src/dom'
import { useCurrentUserAccount } from 'services/src/state'
import { Footer } from '../Footer'

export const ClientSecurity: React.FC = () => {
  const { t } = useTranslation()
  const [account] = useCurrentUserAccount()

  return (
    <form noValidate onSubmit={() => {}}>
      <div className="settings-user-layout">
        <div className="ui-flex" style={{ justifyContent: 'center', marginTop: 90 }}>
          <LockSvg width="110" />
        </div>

        <div style={{ width: 150, margin: '20px auto 0 auto' }} className="ui-text-center">
          {t('Expert.Settings.ClickToResetPassword')}
        </div>

        <div className="ui-flex" style={{ justifyContent: 'center', marginTop: 15 }}>
          <button type="button" className="ui-btn ui-btn-primary ui-btn-solid" style={{ width: 'auto' }} onClick={() => changePassword()}>
            {t('Expert.Settings.ResetPassword')}
          </button>
        </div>
      </div>

      <Footer>
        <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center' }}>
          <Link to={makeDashboardPath(account?.id)} className="ui-btn ui-btn-primary ui-btn-solid">
            {t('Dashboard.Title')}
          </Link>
        </div>
      </Footer>
    </form>
  )
}
