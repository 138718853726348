import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import { CardSelectField } from 'components/src/cardSelectField'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faDotCircle, faSchool } from '@fortawesome/pro-regular-svg-icons'
import { AutoCompleteField } from 'components/src/autoCompleteField'
import { EducationLevel } from 'services/src/dto/account'
import { schoolLookup } from 'services/src/api'
import { TextField } from 'components/src/textField'
import { useRights, UserType } from 'services/src/state'
import { NewAccountModel } from '../common/model'
import { ExpertOnboardingCard } from './ExpertOnboardingCard'

export interface ExpertEducationProps {
  model: NewAccountModel
  onChangeModel: (model: NewAccountModel) => void
  onNext: () => void
  onBack?: () => void
}

export const ExpertEducation: React.FC<ExpertEducationProps> = ({ model, onChangeModel, onNext, onBack }) => {
  const { t } = useTranslation()
  const rights = useRights()

  const [errors, setErrors] = useState<any>({})
  const clearError = (name: string) => {
    const theErrors = { ...errors }
    delete theErrors[name]
    setErrors(theErrors)
  }

  const educationLevels = useMemo<
    {
      id: EducationLevel
      label: string
    }[]
  >(
    () => [
      { id: EducationLevel.Associate, label: t(`Onboarding.Expert.EducationLevelItems.${EducationLevel.Associate}`) },
      { id: EducationLevel.Bachelor, label: t(`Onboarding.Expert.EducationLevelItems.${EducationLevel.Bachelor}`) },
      { id: EducationLevel.Graduate, label: t(`Onboarding.Expert.EducationLevelItems.${EducationLevel.Graduate}`) },
      { id: EducationLevel.Doctoral, label: t(`Onboarding.Expert.EducationLevelItems.${EducationLevel.Doctoral}`) }
    ],
    [t]
  )

  const [schoolName, setSchoolName] = useState(model.schoolName || '')
  const [schoolSuggestions, setSchoolSuggestions] = useState<any[]>([])

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      const errors: any = {}
      if (!model.educationLevel) errors.educationLevel = t('General.Errors.Required')
      if (model.schoolName) {
        if (!model.gradYear) errors.gradYear = t('General.Errors.Required')
      }

      setErrors(errors)
      if (Object.keys(errors).length > 0) return

      onNext()
    },
    [model, onNext, setErrors, t]
  )

  return (
    <form noValidate onSubmit={submit} className="onboarding-education">
      <h3 className="intro">{t('Onboarding.Expert.EducationIntro', { name: model.givenName })}</h3>
      <hr />
      <br />
      <ExpertOnboardingCard model={model} />
      <br />

      <div className="ui-text-sm ui-text-muted" style={{ marginBottom: 15 }}>
        {t('Onboarding.Expert.Step4', {
          N: rights?.userType === UserType.provider ? 3 : 4,
          M: rights?.userType === UserType.provider ? 4 : 5
        })}
      </div>

      <CardSelectField
        items={educationLevels.map((x) => x.label)}
        selected={model.educationLevelIdx >= 0 ? [model.educationLevelIdx] : []}
        onClick={(educationLevelIdx) => {
          onChangeModel({ ...model, educationLevel: educationLevels[educationLevelIdx].id, educationLevelIdx })
          clearError('educationLevel')
        }}
        label={t('Onboarding.Expert.EducationLevel')}
        error={errors.educationLevel}
      />

      {model.educationLevel && (
        <>
          <div className="ui-form-group">
            <label>{t('Onboarding.Expert.IndustrySpecificDegree')}</label>

            <div
              role="button"
              tabIndex={-1}
              onKeyDown={() => {}}
              onClick={() => onChangeModel({ ...model, industrySpecific: true })}
              className={`ui-flex ui-flex-nowrap ui-frame radio-item${model.industrySpecific ? ' selected' : ''}`}
            >
              <div className="ui-flex">
                <div style={{ marginRight: 10 }}>
                  <FontAwesomeIcon icon={model.industrySpecific ? faDotCircle : faCircle} />
                </div>
                <div>{t('Onboarding.Expert.YesIndustrySpecificDegree')}</div>
              </div>
            </div>

            <div
              role="button"
              tabIndex={-1}
              onKeyDown={() => {}}
              onClick={() => onChangeModel({ ...model, industrySpecific: false })}
              className={`ui-flex ui-flex-nowrap ui-frame radio-item${!model.industrySpecific ? ' selected' : ''}`}
            >
              <div className="ui-flex">
                <div style={{ marginRight: 10 }}>
                  <FontAwesomeIcon icon={!model.industrySpecific ? faDotCircle : faCircle} />
                </div>
                <div>{t('Onboarding.Expert.NoIndustrySpecificDegree')}</div>
              </div>
            </div>
          </div>

          <AutoCompleteField
            suggestions={schoolSuggestions}
            value={schoolName}
            label={t('Onboarding.Expert.CollegeOrUniversity')}
            placeholder={t('Onboarding.Expert.CollegeOrUniversityPlaceholder')}
            error={errors.school}
            onValueChange={(v) => {
              setSchoolName(v || '')
              if (!v) return
              schoolLookup(v).then((r) => setSchoolSuggestions(r.results))
            }}
            onSelectSuggestion={(school) => {
              onChangeModel({ ...model, schoolName: school['school.name'] })
              setSchoolName(school['school.name'])
              setSchoolSuggestions([])
            }}
            render={(school) => (
              <div className="ui-text-ellipsis ui-text-sm">
                <FontAwesomeIcon icon={faSchool} />
                &nbsp;&nbsp;{school['school.name']}
              </div>
            )}
          />

          <TextField
            name="gradYear"
            label={t('Onboarding.Expert.GradYear')}
            error={errors.gradYear}
            style={{ width: 120 }}
            placeholder="1996"
            value={model.gradYear || ''}
            onKeyDown={(e) => {
              clearError('gradYear')

              if (['Backspace', 'Delete', 'Enter', 'Tab'].includes(e.key)) return

              let gradYear = model.gradYear || ''
              if (gradYear.length === 4) {
                e.preventDefault()
                e.stopPropagation()
                return
              }

              if (gradYear.length === 0) {
                if (e.key === '1' || e.key === '2') {
                  gradYear = e.key
                  onChangeModel({ ...model, gradYear })
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }
              } else if (gradYear.length === 1) {
                if (gradYear === '1') {
                  if (e.key === '9') {
                    gradYear += e.key
                    onChangeModel({ ...model, gradYear })
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }
                } else if (gradYear === '2') {
                  if (e.key === '0') {
                    gradYear += e.key
                    onChangeModel({ ...model, gradYear })
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }
                }
              } else if (gradYear.length > 1) {
                if (/[0-9]/.test(e.key)) {
                  gradYear += e.key
                  onChangeModel({ ...model, gradYear })
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }
              }

              e.preventDefault()
              e.stopPropagation()
            }}
            onChange={(gradYear) => onChangeModel({ ...model, gradYear })}
          />
        </>
      )}

      <br />
      <div className="ui-row">
        <div className="ui-col-6">
          <button
            type="button"
            className="ui-btn ui-btn-primary"
            style={{ width: '100%' }}
            onClick={() => {
              if (onBack) onBack()
            }}
          >
            {t('Onboarding.Back')}
          </button>
        </div>
        <div className="ui-col-6">
          <button type="submit" className="ui-btn ui-btn-secondary ui-btn-solid" style={{ width: '100%' }}>
            {t('Onboarding.Next')}
          </button>
        </div>
      </div>
    </form>
  )
}
