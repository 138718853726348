import React from 'react'
import { useTranslation } from 'react-i18next'

import { User } from 'services/src/dto/account'
import { useEditProject, useRights, UserType } from 'services/src/state'

export interface SummaryBarProps {
  constructionType: string
  projectType: string
  buildCategory: string
  feeCategory: string
  budgetType: string
  budget: string
  providerUser?: User
  onAssignProvider: () => void
}

export const SummaryBar: React.FC<SummaryBarProps> = ({ constructionType, projectType, buildCategory, feeCategory, budgetType, budget, providerUser, onAssignProvider }) => {
  const { t } = useTranslation()
  const rights = useRights()
  const { project } = useEditProject()

  return (
    <div className="project-summary-bar ui-frame ui-frame-bg">
      <div className="primary-contact">
        {providerUser ? (
          <>
            <label>{t('EditProject.SummaryBar.PrimaryContact')}</label>
            <div className="items">
              <div className="ui-flex" style={{ alignItems: 'center' }}>
                <div className="ui-picture-circle" style={{ width: 40, height: 40, minWidth: 40, minHeight: 40 }}>
                  {providerUser.pictureUrl ? (
                    <div style={{ backgroundImage: `url('${providerUser.pictureUrl}')`, border: 'none' }} />
                  ) : (
                    <div style={{ lineHeight: '40px', fontSize: '18px' }}>
                      {providerUser.givenName && providerUser.givenName[0].toUpperCase()}
                      {providerUser.familyName && providerUser.familyName[0].toUpperCase()}
                    </div>
                  )}
                </div>
                <div className="ui-flex ui-flex-column">
                  <div style={{ marginLeft: 10 }} className="ui-text-bold ui-text-sm">
                    {providerUser.givenName} {providerUser.familyName}
                  </div>
                  <div style={{ marginLeft: 10 }}>
                    {rights?.userType === UserType.client ? t(`General.ProjectRoles.${providerUser.role}`) : t(`General.ProviderProjectRoles.${providerUser.role}`)}
                    {rights?.userType === UserType.provider && project.canEdit && (
                      <div>
                        <a
                          href={document.location.href}
                          className="ui-secondary"
                          onClick={(e) => {
                            e.preventDefault()
                            onAssignProvider()
                          }}
                        >
                          {t('EditProject.SummaryBar.ChangeProvider')}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="items">
            <div className="ui-flex" style={{ alignItems: 'center' }}>
              {rights?.userType === UserType.provider ? (
                <div style={{ paddingTop: 10, width: '100%' }}>
                  <button type="button" className="ui-btn ui-btn-primary ui-btn-sold" style={{ width: '100%' }} onClick={() => onAssignProvider()}>
                    {t('EditProject.SummaryBar.AssignProvider')}
                  </button>
                </div>
              ) : (
                <div>Provider Not Assigned</div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="category">
        <label>{t('EditProject.SummaryBar.ProjectCategory')}</label>
        <div className="items">
          <div>
            <label>{t('EditProject.SummaryBar.ConstructionType')}</label>
            <div className="value-box">{constructionType}</div>
          </div>
          <div>
            <label>{t('EditProject.SummaryBar.ProjectType')}</label>
            <div className="value-box">{projectType}</div>
          </div>
          <div>
            <label>{t('EditProject.SummaryBar.BuildingCategory')}</label>
            <div className="value-box">{buildCategory}</div>
          </div>
          <div>
            <label>{t('EditProject.SummaryBar.FeeCategory')}</label>
            <div className="value-box">{feeCategory}</div>
          </div>
        </div>
      </div>
      <div className="budget">
        <label>{t('EditProject.SummaryBar.ConstructionBudget')}</label>
        <div className="items">
          <div>
            <label>{t('EditProject.SummaryBar.Type')}</label>
            <div className="value-box">{budgetType}</div>
          </div>
          <div>
            <label>{t('EditProject.SummaryBar.Total')}</label>
            <div className="value-box">{budget}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
