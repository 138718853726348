import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useCurrentUserAccount, useMe, useRights, useUserAccounts } from 'services/src/state'

import { MainLayout } from 'components/src/pages/layout/dashboard'
import { useCommentWs } from 'services/src/state/comments'
import { CommentPanel } from 'components/src/commentPanel'

import { Loading } from 'components/src/loading'
import { Saving } from 'components/src/saving'

import { makeDashboardPath } from 'services/src/dom'
import { Protected } from '../src/Protected'
import { Accounts } from 'components/src/pages/accounts'
import { AccountStatus, PRIMARY_PROVIDER_ID } from 'services/src/dto/account'
import { logout } from 'services/src/aad'
import UsetifulScript from './Usetiful'

const Home = React.lazy(() => import('components/src/pages/analytics/Home').then(({ Home }) => ({ default: Home })))
const OfficeList = React.lazy(() => import('components/src/pages/offices/list').then(({ OfficeList }) => ({ default: OfficeList })))
const EditOffice = React.lazy(() => import('components/src/pages/offices/edit').then(({ EditOffice }) => ({ default: EditOffice })))
const ProjectList = React.lazy(() => import('components/src/pages/projects/list').then(({ ProjectList }) => ({ default: ProjectList })))
const ProjectNew = React.lazy(() => import('components/src/pages/projects/new').then(({ ProjectNew }) => ({ default: ProjectNew })))
const ProjectEdit = React.lazy(() => import('components/src/pages/projects/edit').then(({ ProjectEdit }) => ({ default: ProjectEdit })))
const Settings = React.lazy(() => import('components/src/pages/settings').then(({ Settings }) => ({ default: Settings })))
const TransactionList = React.lazy(() => import('components/src/pages/transactions').then(({ TransactionList }) => ({ default: TransactionList })))
const NotFound = React.lazy(() => import('components/src/pages/error/notFound').then(({ NotFound }) => ({ default: NotFound })))

export const Dashboard: React.FC = () => {
  const { t } = useTranslation()
  const {
    path,
    url,
    params: { accountId }
  } = useRouteMatch<{ accountId?: string }>()
  const history = useHistory()

  const [me] = useMe();
  const [account, setAccount] = useCurrentUserAccount()
  const [accounts] = useUserAccounts()
  const rights = useRights()

  useCommentWs()

  useEffect(() => {
    if (!me) return
    if (!me.onboardedAt) {
      history.replace('/onboarding')
      return
    }

    if (!account) {
      // TODO: No account????
    }
    if (account?.status === AccountStatus.New) {
      logout('/approval-pending').catch(console.error);
    }

  }, [me, account])

  useEffect(() => {
    if (!accounts || accountId === 'projects') return

    if (rights?.primaryProvider) {
      history.replace('/admin')
      return
    }

    if (!accountId) {
      const id = localStorage.getItem('currentAccount')
      if (id === PRIMARY_PROVIDER_ID) history.replace('/admin')
      else history.replace(`/dashboard/${accounts.find((x) => x.id === id)?.id || accounts[0].id}`)
      return
    }

    const a = accounts.find((x) => x.id === accountId)
    if (a) setAccount(a)
  }, [accounts, accountId, history, setAccount])

  useEffect(() => {
    if (rights?.primaryProvider)
      history.replace('/admin')
  }, [rights])

  if (!accounts || !rights) return <Loading />

  return (
    <Protected>
      <UsetifulScript/>
      <MainLayout>
        <CommentPanel />
        <Saving />
        <Switch>
          <Route path={`${path}/accounts`} component={Accounts} />
          <Route path={`${path}/offices/:officeId`} component={EditOffice} />
          <Route path={`${path}/offices`} exact component={OfficeList} />
          <Redirect path={`${path}/projects/new/reset`} to={`${path}/projects/new`} push={false} />
          <Route path={`${path}/projects/new`} component={ProjectNew} />
          <Route path={`${path}/projects/:projectId`} component={ProjectEdit} />
          <Route path={`${path}/projects`} exact component={ProjectList} />
          <Route path={`${path}/settings`} component={Settings} />
          <Route path={`${path}/transactions`} component={TransactionList} />
          <Route path={path} exact render={() => (rights?.isGuest ? <Redirect to={`${url}/projects`} /> : <Home />)} />
          <Route path="*">
            <NotFound back={makeDashboardPath(accountId)} backLabel={t('General.BackToDashboard')} />
          </Route>
        </Switch>
      </MainLayout>
    </Protected>
  )
}
