import React from 'react'

interface MainContentProps {
  desktop?: boolean
  hasSubHeader?: boolean
  noPadding?: boolean
}

export const MainContent: React.FC<React.PropsWithChildren<MainContentProps>> = ({ desktop, hasSubHeader, noPadding, children }) => (
  <div className={`main-content${desktop ? ' desktop' : ''}${hasSubHeader ? ' has-sub-header' : ''}${noPadding ? ' no-padding' : ''}`}>{children}</div>
)
