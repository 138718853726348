import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEditProject } from 'services/src/state';
import { TextAreaField } from '../../../textAreaField';
import { BoeProjectSectionSubheader } from './BoeProjectSectionSubheader';
import ProjectBoeProgressBar from './ProjectBoeProgressBar';

interface ProjectBoeScopeDefinitionSectionProps {
  handleNext: () => void;
  handlePrevious: () => void;
  setBoeData: (data: any) => void;
  boeData: any;
  isProgressBarVisible: boolean;
  setIsProgressBarVisible: (value: boolean) => void;
}

interface Errors {
  [key: string]: string | undefined;
  scopeDescription?: string;
}

 const BoeProject12: React.FC<ProjectBoeScopeDefinitionSectionProps> = ({
  handleNext,
  handlePrevious,
  setBoeData,
  boeData,
  isProgressBarVisible,
  setIsProgressBarVisible,
}) => {
  const { t } = useTranslation();
  const { project } = useEditProject();
  const progress = useMemo(() => 6, []);

  const [errors, setErrors] = useState<Errors>({});
  const [scopeDescription, setQuestion] = useState<string>(boeData?.scopeDescription || '');

  const clearError = (name: string) => {
    setErrors((current) => {
      const newErrors = { ...current };
      delete newErrors[name];
      return newErrors;
    });
  };

  const handleNextClick = () => {
    if (!scopeDescription) {
      setErrors({ scopeDescription: 'This field is required' });
      return;
    }
    setBoeData((prev: any) => ({ ...prev, scopeDescription }));
    handleNext();
  };

  useEffect(() => {
    if (project?.description) {
      setQuestion(project?.description || '');
    }
  }, [project?.description]);

  return (
    <div className="boe-content">
      <div className="boe-form-content">
        <ProjectBoeProgressBar
          progress={progress}
          title={t('BasisOfEstimate.ProjectBoeScopeDefinitionSection.ProgressTitle')}
          leftText={t('BasisOfEstimate.ProjectBoeScopeDefinitionSection.ProgressLeftText')}
          rightText={t('BasisOfEstimate.ProjectBoeScopeDefinitionSection.ProgressRightText')}
          isProgressBarVisible={isProgressBarVisible}
          setIsProgressBarVisible={setIsProgressBarVisible}
          boeData={boeData}
        />
        
        <BoeProjectSectionSubheader title={t('BasisOfEstimate.ProjectBoeScopeDefinitionSection.Title')} dynamicText={t('BasisOfEstimate.Introduction.Dynamic')} boeData={boeData} />
    
        <TextAreaField
          name="scopeDescription"
          label=""
          placeholder=""
          autoFocus
          error={errors.scopeDescription}
          style={{ height: 324 }}
          value={scopeDescription}
          onChange={(e) => {
            setQuestion(e);
            clearError('scopeDescription');
          }}
        />
      </div>
      <div className="boe-form-action-container">
        <button type="button" className="btn-next" onClick={handlePrevious}>
          {t('General.Previous')}
        </button>
        <button type="button" className="btn-previous" onClick={handleNextClick}>
          {t('General.Next')}
        </button>
      </div>
    </div>
  );
};

export default BoeProject12;