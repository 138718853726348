import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/pro-solid-svg-icons'

import { useCurrentAccountWithUser, useCurrentUserAccount, useGeolocation, useMe, useRights, UserType } from 'services/src/state'

import { useSaving } from 'components/src/saving'
import { Dropzone } from 'components/src/dropzone'
import { TextField } from 'components/src/textField'
import { PhoneInputField } from 'components/src/phoneInputField'
import { AddressField } from 'components/src/addressField'
import { validateEmail } from 'services/src/validate'
import { makeDashboardPath } from 'services/src/dom'
import { CurrencyInput } from 'components/src/currencyInput'
import { SelectField } from 'components/src/selectField'
import { ConfirmModal } from 'components/src/modal/ConfirmModal'

import { PHOTO_TYPES } from 'services/src/common'
import { ExpertProfileViewModel } from './model'
import { Footer } from '../Footer'

export const ExpertGeneral: React.FC<{
  vm: ExpertProfileViewModel
  onChange: (vm: ExpertProfileViewModel) => void
  onSubmit: (view: string, vm: ExpertProfileViewModel) => void
}> = ({ vm, onChange, onSubmit }) => {
  const { t } = useTranslation()
  const { country_code: geo } = useGeolocation()
  const [me] = useMe()
  const rights = useRights()
  const [account] = useCurrentUserAccount()
  const [accountWithUsers] = useCurrentAccountWithUser()
  const [saving] = useSaving()

  const [initRole] = useState(vm.role)
  const [confirmRoleChange, setConfirmRoleChange] = useState<ExpertProfileViewModel>()

  const onDropPicture = useCallback(
    (acceptedFiles: any[]) => {
      if (!acceptedFiles.length) return

      const errors = { ...vm.errors }
      delete errors.picture
      onChange({
        ...vm,
        picture: acceptedFiles[0],
        preview: URL.createObjectURL(acceptedFiles[0]),
        errors
      })
    },
    [vm, onChange]
  )

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      const vmx: ExpertProfileViewModel = { ...vm, errors: {} }

      if (!vmx.givenName || !vmx.givenName.trim()) vmx.errors.givenName = t('General.Errors.Required')
      if (!vmx.familyName || !vmx.familyName.trim()) vmx.errors.familyName = t('General.Errors.Required')
      if (!vmx.emails![0].email || !vmx.emails![0].email.trim()) vmx.errors.email = t('General.Errors.Required')
      else if (!validateEmail(vmx.emails![0].email)) vmx.errors.email = t('General.Errors.InvalidEmail')
      if (rights?.userType === UserType.expert && !vmx.personalAddress) vmx.errors.personalAddress = t('General.Errors.Required')

      if (Object.keys(vmx.errors).length) {
        onChange(vmx)
        return
      }

      // Saw role changed?
      if (vmx.role !== initRole) {
        setConfirmRoleChange(vmx)
        return
      }

      onSubmit('general', vmx)
    },
    [vm, rights, initRole, onChange, onSubmit]
  )

  return (
    <>
      <form noValidate onSubmit={submit}>
        <div className="settings-user-layout">
          <div className="user-picture">
            <Dropzone onDrop={onDropPicture} variant="round" accept={PHOTO_TYPES} maxFiles={1} rejectLabel={t('Settings.Errors.InvalidPicture')} disabled={saving}>
              {(() => {
                if (vm.preview || vm.pictureUrl) return <div className="bg-preview" style={{ backgroundImage: `url('${vm.preview || vm.pictureUrl}')` }} />
                return (
                  <div className="empty">
                    <FontAwesomeIcon icon={faCamera} />
                  </div>
                )
              })()}
            </Dropzone>
          </div>

          <div className="ui-flex ui-flex-nowrap">
            <div style={{ flex: 1, marginRight: 5 }}>
              {vm.notMe ? (
                <SelectField
                  items={vm.roles}
                  selected={vm.roles.find((r) => r.role === vm.role)}
                  labelField="label"
                  valueField="role"
                  error={vm.errors.role}
                  label={t('Settings.EditExpert.ProviderRole')}
                  disabled={saving}
                  onSelectedChange={(x) => {
                    const errors = { ...vm.errors }
                    delete errors.role
                    onChange({ ...vm, role: x.role, errors })
                  }}
                />
              ) : (
                <div className="ui-form-group">
                  <label>{t('Settings.EditExpert.ProviderRole')}</label>
                  <div className="ui-frame ui-frame-no-padding ui-text-sm ui-text-ellipsis" style={{ height: 36, lineHeight: '34px', padding: '0 10px' }}>
                    {t(`Settings.EditExpert.Roles.${accountWithUsers?.users.find((u) => u.id === vm.id)?.role || 'Guest'}`)}
                  </div>
                </div>
              )}
            </div>

            {vm.userType === UserType.expert && (
              <>
                <div style={{ marginLeft: 5, marginRight: 5, width: 125 }}>
                  <label>{t('Settings.EditExpert.ExpertRole')}</label>
                  <div className="ui-frame ui-frame-no-padding ui-text-sm ui-text-ellipsis" style={{ height: 36, lineHeight: '34px', padding: '0 10px' }}>
                    {vm.expertRole ? t(`General.ExpertRoles.${vm.expertRole}`) : t('General.NA')}
                  </div>
                </div>

                <div style={{ flex: 1, marginLeft: 5, marginRight: 5 }}>
                  <label>{t('Settings.EditExpert.Discipline')}</label>
                  <div className="ui-frame ui-frame-no-padding ui-text-sm ui-text-ellipsis" style={{ height: 36, lineHeight: '34px', padding: '0 10px' }}>
                    {vm.primaryDiscipline ? t(`General.Disciplines.${vm.primaryDiscipline}`) : t('General.NA')}
                  </div>
                </div>
              </>
            )}

            <div style={{ marginLeft: 5, width: 100 }}>
              <label>{t('Settings.EditExpert.HourlyRate')}</label>
              {rights?.userType !== UserType.provider || vm.id === me?.id ? (
                <div className="ui-frame ui-frame-no-padding ui-text-sm ui-text-ellipsis" style={{ height: 36, lineHeight: '34px', padding: '0 10px' }}>
                  {vm.hourlyRate ? t('General.Currency.Value', { value: vm.hourlyRate }) : t('General.NA')}
                </div>
              ) : (
                <CurrencyInput
                  amount={vm.hourlyRate?.toString()}
                  min={0}
                  max={1000}
                  disabled={saving || rights?.userType !== UserType.provider || vm.id === me?.id}
                  onChange={(hourlyRate) => {
                    const errors = { ...vm.errors }
                    delete errors.hourlyRate
                    onChange({ ...vm, hourlyRate, errors })
                  }}
                />
              )}
            </div>
          </div>

          <div className="ui-row">
            <div className="ui-col-6">
              <TextField
                name="givenName"
                type="text"
                disabled={saving}
                value={vm.givenName}
                error={vm.errors.givenName}
                label={t('Settings.EditExpert.GivenName')}
                onChange={(givenName) => {
                  const errors = { ...vm.errors }
                  delete errors.givenName
                  onChange({ ...vm, givenName, errors })
                }}
              />
            </div>

            <div className="ui-col-6">
              <TextField
                name="familyName"
                type="text"
                disabled={saving}
                value={vm.familyName}
                error={vm.errors.familyName}
                label={t('Settings.EditExpert.FamilyName')}
                onChange={(familyName) => {
                  const errors = { ...vm.errors }
                  delete errors.familyName
                  onChange({ ...vm, familyName, errors })
                }}
              />
            </div>
          </div>

          <TextField
            name="email"
            type="email"
            disabled
            value={vm.emails?.length ? vm.emails[0].email : ''}
            error={vm.errors.email}
            label={t('Settings.EditExpert.Email')}
            onChange={(email) => {
              const errors = { ...vm.errors }
              delete errors.email
              onChange({ ...vm, emails: [{ email }], errors })
            }}
          />

          {vm.userType === UserType.expert && (
            <AddressField
              disabled={saving}
              address={vm.personalAddress}
              errors={vm.errors.personalAddress ? { street: vm.errors.personalAddress } : undefined}
              streetLabel={t('Settings.EditExpert.PersonalAddress')}
              onChange={(personalAddress) => {
                const errors = { ...vm.errors }
                delete errors.personalAddress
                onChange({ ...vm, personalAddress, errors })
              }}
              variant="addressOnly"
            />
          )}

          <div className="ui-row">
            <div className="ui-col-5">
              <PhoneInputField
                label={t('Settings.EditExpert.BusinessPhone')}
                placeholder={t('Settings.EditExpert.BusinessPhoneSample')}
                value={vm.businessPhones?.length ? vm.businessPhones[0].number : undefined}
                disabled={saving}
                error={vm.errors.businessPhone}
                onChange={(number) => {
                  const errors = { ...vm.errors }
                  delete errors.businessPhone

                  let businessPhones = [...(vm.businessPhones || [{ number }])]
                  if (!businessPhones.length) businessPhones = [{ number }]

                  businessPhones[0].number = number

                  onChange({ ...vm, businessPhones, errors })
                }}
                country={geo?.country_code?.toLowerCase() || 'us'}
              />
            </div>

            <div className="ui-col-2">
              <TextField
                name="extension"
                disabled={saving}
                value={vm.businessPhones?.length ? vm.businessPhones[0].extension : undefined}
                error={vm.errors.businessPhoneExt}
                label={t('Settings.EditExpert.BusinessPhoneExt')}
                placeholder={t('Settings.EditExpert.BusinessPhoneExtSample')}
                onChange={(extension) => {
                  const errors = { ...vm.errors }
                  delete errors.businessPhone

                  const businessPhones = [...(vm.businessPhones || [{ number: '', extension }])]
                  businessPhones[0].extension = extension
                  onChange({ ...vm, businessPhones, errors })
                }}
              />
            </div>

            <div className="ui-col-5">
              <PhoneInputField
                label={t('Settings.EditExpert.MobilePhone')}
                placeholder={t('Settings.EditExpert.MobilePhoneSample')}
                value={vm.mobilePhone}
                disabled={saving}
                error={vm.errors.mobilePhone}
                onChange={(mobilePhone) => {
                  const errors = { ...vm.errors }
                  delete errors.mobilePhone
                  onChange({ ...vm, mobilePhone, errors })
                }}
                country={geo?.country_code?.toLowerCase() || 'us'}
              />
            </div>
          </div>
        </div>

        <Footer>
          <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center' }}>
            <Link to={makeDashboardPath(account?.id)} className="ui-btn ui-btn-primary ui-btn-solid">
              {t('Dashboard.Title')}
            </Link>
            {vm.notMe && (
              <Link to={makeDashboardPath(account?.id, 'settings/account/staff')} style={{ marginLeft: 10 }} className="ui-btn ui-btn-primary ui-btn-solid">
                {t('General.Exit')}
              </Link>
            )}
            <button type="submit" style={{ marginLeft: 'auto' }} disabled={!vm.dirty || saving} className="ui-btn ui-btn-secondary ui-btn-solid">
              {t('General.Update')}
            </button>
          </div>
        </Footer>
      </form>

      {confirmRoleChange && (
        <ConfirmModal
          title={t('Settings.ChangeRoleModal.Title')}
          message={t('Settings.ChangeRoleModal.Message')}
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={() => {
            setConfirmRoleChange(undefined)
            onSubmit('general', confirmRoleChange)
          }}
          onClose={() => setConfirmRoleChange(undefined)}
        />
      )}
    </>
  )
}
