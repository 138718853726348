import React from 'react'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract, faUsersCog } from '@fortawesome/pro-regular-svg-icons'

import './style.scss'
import logo from 'components/src/img/cc-light.png'
import DashboardSvg from 'components/src/svg/Dashboard'
import OfficesSvg from 'components/src/svg/Offices'
import ProjectsSvg from 'components/src/svg/Projects'
import NewProjectSvg from 'components/src/svg/NewProject'
import CogSvg from 'components/src/svg/Cog'
import TransactionsSvg from 'components/src/svg/Transactions'
import { useRights, UserType } from 'services/src/state'
import { Feature, useFeatures } from 'services/src/state/features'
import { AccountType } from 'services/src/dto/account'

interface MainNavProps {
  desktop: boolean
  open: boolean
  onCloseNav: () => void
}

export const MainNav: React.FC<MainNavProps> = ({ desktop, open, onCloseNav }) => {
  const { url } = useRouteMatch()
  const location = useLocation()
  const rights = useRights()
  const { hasFeature } = useFeatures()

  const home = () => {
    if (rights?.accountType === AccountType.Client) return true
    if (rights?.accountType === AccountType.Provider && rights.userType !== UserType.expert) return true
    if (rights?.accountType === AccountType.Expert) return true
    if (rights?.isGuest) return true

    return false;
  }

  return (
    <>
      {!desktop && open && <div className="main-nav-overlay" role="menu" tabIndex={-1} onKeyDown={() => {}} onClick={onCloseNav} />}

      <nav
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        role="menu"
        className={`main-nav ${desktop ? 'desktop' : ''} ${open ? 'open' : ''}`}
        tabIndex={-1}
        onKeyDown={() => {}}
        onClick={onCloseNav}
      >
        <div className="brand">
          {home() && (
            <Link to={url} className={`items-dashboard${location.pathname === url ? ' active' : ''}`} tabIndex={-1}>
              <img alt="Construction Check" src={logo} />
            </Link>
          )}
          {!home() && <img alt="Construction Check" src={logo} />}
        </div>

        {rights && (
          <div className="items">
            {home() && (
              <Link to={url} className={`items-dashboard${location.pathname === url ? ' active' : ''}`}>
                <DashboardSvg />
              </Link>
            )}
            {rights.canListAccounts && (
              <Link to={`${url}/accounts`} className={`items-accounts${location.pathname.includes('/accounts') ? ' active' : ''}`} style={{ paddingTop: 11 }}>
                <FontAwesomeIcon icon={faUsersCog} style={{ fontSize: 25, marginTop: 1 }} />
              </Link>
            )}
            {rights.canListOffices && (
              <Link to={`${url}/offices`} className={`items-offices${location.pathname.includes('/offices') ? ' active' : ''}`}>
                <OfficesSvg />
              </Link>
            )}
            <Link to={`${url}/projects`} className={`items-projects${!location.pathname.includes('/projects/new') && location.pathname.includes('/projects') ? ' active' : ''}`}>
              <ProjectsSvg />
            </Link>

            <Link to={`${url}/settings/user`} className={`items-settings${location.pathname.includes('/settings') ? ' active' : ''}`}>
              <CogSvg />
            </Link>

            {hasFeature(Feature.SmartDocs) && rights.canListDocs && (
              <Link to={`${url}/smart-docs`} className={`items-smart-docs${location.pathname.startsWith(`${url}/smart-docs`) ? ' active' : ''}`}>
                <FontAwesomeIcon icon={faFileContract} style={{ fontSize: 30, marginTop: 2 }} />
              </Link>
            )}

            {rights.canListTx && (
              <Link to={`${url}/transactions`} className={`items-transactions${location.pathname.startsWith(`${url}/transactions`) ? ' active' : ''}`}>
                <TransactionsSvg />
              </Link>
            )}

            {rights.canCreateProject && (
              <Link to={`${url}/projects/new/reset`} className={`items-projects-new${location.pathname.includes('/projects/new') ? ' active' : ''}`}>
                <NewProjectSvg />
              </Link>
            )}
          </div>
        )}

        <div className="build-no">
          <div>{process.env.REACT_APP_BUILD_NUMBER}</div>
        </div>
      </nav>
    </>
  )
}
