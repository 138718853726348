import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './style.scss'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { DisciplineKey } from 'services/src/dto/project'
import { ProjectContentEx, useProjectFiles } from 'services/src/state'
import { ProjectContentCategory } from 'services/src/dto/content'
import { DrawingGrid } from './DrawingGrid'
import { DocumentView } from '../documentView'

const FRAME_ADJUST = 400

export interface DrawingViewProps {
  disciplines?: string[]
  onOpen: (content: ProjectContentEx, fromGrid?: boolean) => void
  onTagDiscipline: (content: ProjectContentEx, discipline?: DisciplineKey) => void
}

export const DrawingView: React.FC<DrawingViewProps> = ({ disciplines, onOpen, onTagDiscipline }) => {
  const history = useHistory()
  const {
    url,
    params: { contentId }
  } = useRouteMatch<{ contentId: string }>()

  const { files } = useProjectFiles(ProjectContentCategory.Drawing)

  const [height, setHeight] = useState(Math.max(400, window.innerHeight - FRAME_ADJUST))

  const resize = useCallback(() => {
    setHeight(Math.max(400, window.innerHeight - FRAME_ADJUST))
  }, [setHeight])

  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  const [gridSelected, setGridSelected] = useState<ProjectContentEx | undefined>()

  const [content, setContent] = useState<ProjectContentEx>()
  useEffect(() => {
    if (!files || !contentId) return

    let content: ProjectContentEx | undefined
    files.forEach((f) => {
      if (content) return
      if (f.id === contentId) {
        content = f
        return
      }

      const c = f.children?.find((x) => x.id === contentId)
      if (c) content = c
    })

    if (!content) {
      setTimeout(() => history.replace(`${url}/drawings`))
      return
    }
    setContent(content)
  }, [contentId, url, files, setContent])

  const readyContent = useMemo<ProjectContentEx | undefined>(() => {
    if (!content?.id || !files?.length) return undefined
    const c = { ...content, children: [...content.children] }

    if (c.parent) {
      c.parent = { ...c.parent, children: [...c.parent.children] }

      c.parent.children = c.parent.children
        .filter((c) => {
          if (!disciplines?.length) return true

          let result = true

          if (disciplines.includes('$noDiscipline$')) result = !c.discipline

          const ex = disciplines.filter((d) => !d.startsWith('$'))
          if (ex.length > 0) {
            if (!(c.discipline && ex.includes(c.discipline.toString()))) result = false
          }

          if (!result) return result

          if (disciplines.includes('$warn$') && disciplines.includes('$danger$')) {
            result = !c.disciplineById && !!c.classification && c.score! <= 0.75
          } else if (disciplines.includes('$warn$')) {
            result = !c.disciplineById && !!c.classification && c.score! > 0.5 && c.score! <= 0.75
          } else if (disciplines.includes('$danger$')) {
            result = !c.disciplineById && !!c.classification && c.score! <= 0.5
          }

          return result
        })
        .map((c, pageIndex) => ({ ...c, pageIndex }))
    }

    return c
  }, [content, files, disciplines])

  if (!readyContent) return null

  return (
    <div className="ui-drawing-view">
      {!readyContent.parent ? (
        <DrawingGrid
          disciplines={disciplines}
          content={readyContent}
          selected={gridSelected}
          onSelect={(c, noOpen) => {
            setGridSelected(gridSelected?.id === c?.id ? undefined : c)
            if (noOpen || !c) return
            onOpen(c, true)
          }}
        />
      ) : (
        <div className="ui-drawing-view-frame" style={{ height }}>
          {readyContent && <DocumentView disciplines={disciplines} back={url.replace(`/${readyContent.id}`, '')} onTagDiscipline={onTagDiscipline} />}
        </div>
      )}
    </div>
  )
}
