import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { faLightbulbOn } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostRequests } from 'services/src/api/boe';
import { endpoints } from 'services/src/lib/axios';
import { Loading } from '../../../loading';
import ProjectBoeProgressBar from './ProjectBoeProgressBar';

interface ProjectBoeScopeDefinitionSectionProps {
  handleNext: () => void;
  handlePrevious: () => void;
  setBoeData: (data: any) => void;
  boeData: any;
  isProgressBarVisible: boolean;
  setIsProgressBarVisible: (value: boolean) => void;
}

interface Errors {
  [key: string]: string | undefined;
  projectExclusions?: string;
}

const BoeProject125: React.FC<ProjectBoeScopeDefinitionSectionProps> = ({
  handleNext,
  handlePrevious,
  setBoeData,
  boeData,
  isProgressBarVisible,
  setIsProgressBarVisible,
}) => {
  const { t } = useTranslation();
  const progress = useMemo(() => 75, []);
  const { data, postRequests, loading, error } = usePostRequests();
  const [errors, setErrors] = useState<Errors>({});
  const [projectExclusions, setProjectExclusions] = useState<{
    introduction: string;
    exclustions: string[];
    conclusion: string;
  }>(boeData?.projectExclusions || {
    introduction: '',
    exclustions: [],
    conclusion: ''
  });
  const [eExclusionsOptions, setEexclusionsOptions] = useState<string[]>(boeData?.eExclusionsOptions || []);
  const [selectedExclusions, setSelectedExclusions] = useState<string[]>(boeData?.projectExclusions?.exclustions || []);
  const [newExclusion, setNewExclusion] = useState<string>('');
  const [showNewExclusionInput, setShowNewExclusionInput] = useState<boolean>(false);

  const handleSelectExclusion = (exclusion: string) => {
    setSelectedExclusions((prev) => {
      if (prev.includes(exclusion)) {
        return prev.filter((item) => item !== exclusion);
      }
      return [...prev, exclusion];
    });
  };

  const handleAddExclusion = () => {
    if (newExclusion && !eExclusionsOptions.includes(newExclusion)) {
      setEexclusionsOptions([...eExclusionsOptions, newExclusion]);
      setNewExclusion('');
      setShowNewExclusionInput(false);
    }
  };

  const handleNextClick = () => {
    if (selectedExclusions.length === 0) {
      setErrors({ projectExclusions: 'Please select at least one exclusion or mark as Not Applicable.' });
      return;
    }
    setBoeData((prev: any) => ({
      ...prev,
      projectExclusions: {
        ...projectExclusions,
        exclustions: selectedExclusions
      },
      eExclusionsOptions
    }));
    handleNext();
  };

  useEffect(() => {
    if (projectExclusions.introduction === '') {
      postRequests(endpoints.boe.exclusions, {});
    }
  }, [projectExclusions, postRequests]);

  useEffect(() => {
    if (data?.response) {
      setProjectExclusions({
        introduction: data.response.introduction,
        exclustions: data.response.content,
        conclusion: data.response.conclusion
      });
      setEexclusionsOptions(data.response.content);
    }
  }, [data?.response]);

  return (
    <div className="boe-content">
      <div className="boe-form-content">
        <ProjectBoeProgressBar
          progress={progress}
          title={t('BasisOfEstimate.BoeProject125.ProgressTitle')}
          leftText={t('BasisOfEstimate.BoeProject125.ProgressLeftText')}
          rightText={t('BasisOfEstimate.BoeProject125.ProgressRightText')}
          isProgressBarVisible={isProgressBarVisible}
          setIsProgressBarVisible={setIsProgressBarVisible}
          boeData={boeData}
        />
        <div className="boe-content-flex-between">
          <h2 className="boe-section-header">{t('BasisOfEstimate.BoeProject125.Title')}</h2>
          <div className="boe-section-header-right-content">
            <FontAwesomeIcon icon={faLightbulbOn} />
            <p>{t('BasisOfEstimate.BoeProject125.Dynamic')}</p>
          </div>
        </div>

        {loading && <Loading />}

        {error && (
          <div className="boe-error-container">
            <p>{t('General.Error')}</p>
          </div>
        )}

        <div className='boe-selectable-content'>
          <h3 className='boe-project-physical-title'>{projectExclusions.introduction}</h3>
          <ul>
            {eExclusionsOptions.map((exclusion, index) => (
              <li key={index}>
                <input
                  type="checkbox"
                  className='boe-assumption-checkbox'
                  checked={selectedExclusions.includes(exclusion)}
                  onChange={() => handleSelectExclusion(exclusion)}
                />
                {exclusion}
              </li>
            ))}

          </ul>
          {showNewExclusionInput ? (
            <div>
              <input
                type="text"
                value={newExclusion}
                onChange={(e) => setNewExclusion(e.target.value)}
                placeholder="Add new exclusion"
              />
              <button type="button" className='boe-form-assumption-button' onClick={handleAddExclusion}>{t('General.Save')}</button>
            </div>
          ) : (
            <button type="button" className='boe-form-assumption-button-new' onClick={() => setShowNewExclusionInput(true)}>
                <FontAwesomeIcon icon={faPlusCircle} />
              {t("BasisOfEstimate.BoeProject125.AdditionalExclusions")}
              </button>
          )}
          <h3 className='boe-project-physical-title'>{projectExclusions.conclusion}</h3>
        </div>

        {errors.projectExclusions && (
          <div className="boe-error-container">
            <p className='boe-error-message'>{errors.projectExclusions}</p>
          </div>
        )}
      </div>
      <div className="boe-form-action-container">
        <button type="button" className="btn-next" onClick={handlePrevious}>
          {t('General.Previous')}
        </button>
        <button type="button" className="btn-previous" onClick={handleNextClick}>
          {t('General.Next')}
        </button>
      </div>
    </div>
  );
};

export default BoeProject125;