import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/pro-solid-svg-icons'

import { useSaving } from 'components/src/saving'
import { Dropzone } from 'components/src/dropzone'
import { numberOfEmployeesItems, PHOTO_TYPES } from 'services/src/common'
import { TextField } from 'components/src/textField'
import { SelectField } from 'components/src/selectField'
import { updateAccount } from 'services/src/api'
import { AccountEx } from './common'
import { useHistory } from 'react-router-dom'

export const AccountEditGeneral: React.FC<{
  account: AccountEx
  onChange: (account: AccountEx) => void
}> = ({ account, onChange }) => {
  const { t } = useTranslation()
  const [saving, setSaving] = useSaving()
  const history = useHistory()

  const [errors, setErrors] = useState<any>({})

  const onDropPicture = useCallback(
    (acceptedFiles: any[]) => {
      if (!acceptedFiles.length) return

      onChange({
        ...account,
        logo: acceptedFiles[0],
        logoPreview: URL.createObjectURL(acceptedFiles[0])
      })
    },
    [onChange]
  )

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      const errors: any = {}
      if (!account.companyName.trim().length) errors.companyName = t('General.Errors.Required')

      setErrors(errors)
      if (Object.keys(errors).length) return

      const a = { ...account }

      setSaving(true)
      updateAccount(a, account.logo)
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          setSaving(false)
        })
    },
    [account]
  )

  return (
    <form noValidate onSubmit={submit}>
      <div className="logo-picture">
        <Dropzone onDrop={onDropPicture} variant="round" accept={PHOTO_TYPES} maxFiles={1} rejectLabel={t('Settings.Errors.InvalidPicture')} disabled={saving}>
          {(() => {
            if (account.logoPreview || account.logo)
              return (
                <div
                  className="preview"
                  style={{
                    backgroundImage: `url('${account.logoPreview || account.logo}')`,
                    backgroundSize: 'cover'
                  }}
                >
                  <FontAwesomeIcon icon={faCamera} size="2x" />
                </div>
              )
            return (
              <div className="empty">
                <FontAwesomeIcon icon={faCamera} size="2x" />
              </div>
            )
          })()}
        </Dropzone>
      </div>

      <TextField
        label={t('Accounts.Edit.CompanyName')}
        value={account.companyName}
        error={errors.companyName}
        disabled={saving}
        onChange={(companyName) => {
          const e = { ...errors }
          delete e.component
          setErrors(e)
          onChange({ ...account, companyName })
        }}
      />

      <SelectField
        items={account.companyTypes || []}
        disabled={saving}
        selected={account.newCompanyType}
        onSelectedChange={(newCompanyType) => {
          onChange({ ...account, companyType: newCompanyType.id, newCompanyType })
        }}
        placeholder={t('Settings.CompanyTypePlaceholder')}
        label={t('Settings.CompanyType')}
        // error={errors.companyType}
        labelField="label"
        valueField="id"
        maxDropDown={12}
      />

      <SelectField
        items={numberOfEmployeesItems}
        disabled={saving}
        selected={account.newNumberOfEmployees}
        onSelectedChange={(newNumberOfEmployees) => {
          onChange({ ...account, numberOfEmployees: newNumberOfEmployees.id, newNumberOfEmployees })
        }}
        placeholder={t('Settings.EmployeesPlaceholder')}
        label={t('Settings.Employees')}
        // error={errors.numberOfEmployees}
        labelField="label"
        valueField="id"
        maxDropDown={5}
      />

      <div className="account-edit-footer ui-flex" style={{ alignItems: 'center' }}>
        <button
          type="button"
          className="ui-btn ui-btn-primary ui-btn-solid"
          onClick={() => {
            if (history.length === 1) window.close()
            else history.goBack()
          }}
        >
          {t('General.Exit')}
        </button>
        <button type="submit" className="ui-btn ui-btn-secondary ui-btn-solid" style={{ marginLeft: 'auto'}}>
          {t('Accounts.Edit.Save')}
        </button>
      </div>
    </form>
  )
}
