import React, { useCallback, useEffect, useRef, useState } from 'react'
import { atom, useRecoilValue, useSetRecoilState } from 'recoil'
import { DESKTOP_SIZE } from 'components/src/constants'
import { MainNav } from './MainNav'
import { MainContent } from './MainContent'
import { MainHeader } from './MainHeader'
import { MainAltNav } from './MainAltNav'

import './style.scss'

const mainSubheaderAtom = atom<JSX.Element | undefined>({
  key: 'mainSubheader',
  default: undefined
})
const mainLeftAsideAtom = atom<JSX.Element | undefined>({
  key: 'mainLeftAside',
  default: undefined
})
const mainRightAsideAtom = atom<JSX.Element | undefined>({
  key: 'mainRightAside',
  default: undefined
})

export interface MainLayoutProps {
  noPadding?: boolean
  className?: string
}

export const MainLayout: React.FC<React.PropsWithChildren<MainLayoutProps>> = ({ className, noPadding, children }) => {
  const [desktop, setDesktop] = useState(document.body.clientWidth > DESKTOP_SIZE)
  const [navOpen, setNavOpen] = useState(document.body.clientWidth > DESKTOP_SIZE)
  const [altNavOpen, setAltNavOpen] = useState(document.body.clientWidth > DESKTOP_SIZE)
  const subheader = useRecoilValue(mainSubheaderAtom)
  const leftAside = useRecoilValue(mainLeftAsideAtom)
  const rightAside = useRecoilValue(mainRightAsideAtom)
  const headerRef = useRef<HTMLDivElement>()

  const [dumpAside, setDumpAside] = useState(false)

  const resize = useCallback(() => {
    setDesktop(document.body.clientWidth > DESKTOP_SIZE)
    setNavOpen(document.body.clientWidth > DESKTOP_SIZE)
    setAltNavOpen(document.body.clientWidth > DESKTOP_SIZE)
    setDumpAside((current) => !current)
  }, [setDesktop, setNavOpen])

  const toggleNav = useCallback(() => {
    setNavOpen(desktop || !navOpen)
  }, [desktop, navOpen, setNavOpen])

  const toggleAltNav = useCallback(() => {
    setAltNavOpen(desktop || !altNavOpen)
  }, [desktop, altNavOpen, setAltNavOpen])

  useEffect(() => {
    window.addEventListener('resize', resize)
    window.addEventListener('togglenav', toggleNav)
    window.addEventListener('togglealtnav', toggleAltNav)
    return () => {
      window.removeEventListener('resize', resize)
      window.removeEventListener('togglenav', toggleNav)
      window.removeEventListener('togglealtnav', toggleAltNav)
    }
  }, [resize, toggleNav])

  return (
    <main className={`main-layout${desktop ? ' desktop' : ''}${className ? ` ${className}` : ''}`}>
      <MainHeader onToggleNav={toggleNav} onToggleAltNav={toggleAltNav} desktop={desktop} subheader={subheader} headerRef={headerRef} />

      <MainNav desktop={desktop} open={navOpen} onCloseNav={() => setNavOpen(false)} />

      {!desktop && <MainAltNav desktop={desktop} open={altNavOpen} onCloseNav={() => setAltNavOpen(false)} />}

      <MainContent desktop={desktop} hasSubHeader={!!subheader} noPadding={noPadding}>
        {leftAside && (
          <aside
            className={`main-left-aside${dumpAside ? ' b1' : ' b0'}`}
            style={{
              top: (headerRef.current?.clientHeight || 0) + 10,
              maxHeight: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - (headerRef.current?.clientHeight || 0) + 10 - 100
            }}
          >
            {leftAside}
          </aside>
        )}

        {children}

        {rightAside && (
          <aside
            className={`main-left-aside${dumpAside ? ' b1' : ' b0'}`}
            style={{
              top: (headerRef.current?.clientHeight || 0) + 10,
              maxHeight: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - (headerRef.current?.clientHeight || 0) + 10 - 100
            }}
          >
            {rightAside}
          </aside>
        )}
      </MainContent>
    </main>
  )
}

export const useSetMainSubheader = () => useSetRecoilState(mainSubheaderAtom)
export const useSetMainLeftAside = () => useSetRecoilState(mainLeftAsideAtom)
export const useSetMainRightAside = () => useSetRecoilState(mainRightAsideAtom)
