import { faCloudArrowUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from 'react-i18next';
import { ReportPreview } from "./BoeReportPreview";

interface ProgressBarProps {
  progress: number;
  title: string;
  leftText?: string;
  rightText?: string;
  isProgressBarVisible: boolean;
  boeData?: any;
  setIsProgressBarVisible: (value: boolean) => void;
}

const ProjectBoeProgressBar: React.FC<ProgressBarProps> = ({ progress, title, leftText, rightText, isProgressBarVisible, boeData, setIsProgressBarVisible }) => {
  const { t } = useTranslation();
  const handleToggle = () => {
    setIsProgressBarVisible(!isProgressBarVisible);
  };

  return (
    <div className="boe-progress-container-main">
      {isProgressBarVisible && (
      <div className="boe-progress-container">
        <h6 className="boe-progress-title">{title}</h6>
        <div className="boe-progess-header-content">
          <div className="boe-progess-right-header-content">
            <FontAwesomeIcon icon={faCloudArrowUp} />
            <p className="boe-progess-right-header-content-text">{leftText}</p>
          </div>
          <p className="boe-progess-left-header-content-text">{rightText}</p>
        </div>
        
          <div className="boe-progress-bar">
            <div className="boe-progress" style={{ width: `${progress}%` }} />
            <p className="boe-progress-text">{progress}%</p>
          </div>
      </div>
      )}
        <div className="boe-content-flex-between">
        <div className="boe-input-container">
        <input type="checkbox" id="boe-switch" className="boe-checkbox" onChange={handleToggle} />
        <label htmlFor="boe-switch" className="boe-label">{t('BasisOfEstimate.Toggle')}</label>
        {isProgressBarVisible && <p className="boe-progress-lable-text">{t('BasisOfEstimate.HideProgressBar')}</p>}
        {!isProgressBarVisible && <p className="boe-progress-lable-text">{t('BasisOfEstimate.ShowProgressBar')}</p>}
      </div>
      {boeData && (
        <ReportPreview boeData={boeData} />
      )}
    </div>
   
    </div>
  );
};

export default ProjectBoeProgressBar;