import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment, { Moment } from 'moment'

import { PhaseType } from 'services/src/dto/projectSupport'
import { PhaseTypeKey } from 'services/src/dto/projectShare'
import { PROJECT_PHASE_MIN_DAYS } from 'components/src/constants'

import { TextField } from 'components/src/textField'
import { Modal, ModalProps } from 'components/src/modal'
import { SelectField } from 'components/src/selectField'
import { TextAreaField } from 'components/src/textAreaField'
import { DatePicker } from 'components/src/datePicker'
import { PhaseEx, ProjectEx } from 'services/src/state'
import { updateTimelineMarkersFromProject } from './Timeline'

export type NewPhaseModalProps = ModalProps & {
  project: ProjectEx
  phaseTypes: PhaseType[]
  phase: PhaseEx
  action: 'add' | 'edit'
  onSubmit: (phase: PhaseEx) => void
}

export const PhaseModal: React.FC<NewPhaseModalProps> = ({ project: initProject, phaseTypes, phase, action, onClose, onSubmit }) => {
  const { t } = useTranslation()

  const [errors, setErrors] = useState<any>({})
  const clearError = (name: string) => {
    const theErrors = { ...errors }
    delete theErrors[name]
    setErrors(theErrors)
  }

  const [phaseType, setPhaseType] = useState(phaseTypes.find((x) => x.id === phase?.phaseType))
  const [name, setName] = useState(phase?.name || '')
  const [description, setDescription] = useState(phase?.description || '')

  const [project] = useState(initProject)
  const [markers, setMarkers] = useState(updateTimelineMarkersFromProject(initProject, phaseTypes, t))

  const [pickerOpen, setPickerOpen] = useState<string | undefined>()
  const [pickerStyle, setPickerStyle] = useState<React.CSSProperties>()
  const [start, setStart] = useState(phase?.submissionDate ? moment(phase?.submissionDate).format('ll') : '')
  const [submissionDate, setSubmissionDate] = useState<Moment | undefined>(phase?.submissionDate ? moment(phase.submissionDate) : undefined)
  const [end, setEnd] = useState(phase?.deliveryDate ? moment(phase?.deliveryDate).format('ll') : '')
  const [deliveryDate, setDeliveryDate] = useState<Moment | undefined>(phase?.deliveryDate ? moment(phase.deliveryDate) : undefined)

  const [{ min, max }, setMinMax] = useState<{ min?: Moment; max?: Moment }>(() => ({
    min: undefined,
    max: undefined
  }))

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      const errors: any = {}
      if (!phaseType) errors.phaseType = t('General.Errors.Required')
      if (!submissionDate) errors.start = t('General.Errors.Required')
      if (!deliveryDate) errors.end = t('General.Errors.Required')

      setErrors(errors)
      if (Object.keys(errors).length > 0) return

      onSubmit({
        ...(phase || {}),
        name,
        description,
        submissionDate: submissionDate?.toDate(),
        deliveryDate: deliveryDate?.toDate(),
        phaseType: phaseType?.id || PhaseTypeKey.Concept
      })
    },
    [phase, onSubmit, setErrors, name, description, phaseType, submissionDate, deliveryDate, t]
  )

  const pickerRef = useRef<HTMLDivElement>(null)
  const bodyRef = useRef<HTMLDivElement>(null)

  return (
    <Modal
      className="edit-project-phase-modal"
      closeOnClickOutside={false}
      onClose={onClose}
      header={<h3>{t(action === 'add' ? 'EditProject.NewPhaseModal.AddPhase' : 'EditProject.NewPhaseModal.EditPhase')}</h3>}
    >
      <div ref={bodyRef}>
        <form onSubmit={submit} noValidate autoComplete="off">
          <SelectField
            items={phaseTypes}
            selected={phaseType}
            label={t('EditProject.NewPhaseModal.PhaseType')}
            error={errors.phaseType}
            valueField="id"
            labelField="type"
            disabled={action === 'edit'}
            onSelectedChange={(x) => {
              setPhaseType(x)
              clearError('phaseType')
              setPickerOpen(undefined)
              setStart('')
              setSubmissionDate(undefined)
              setEnd('')
              setDeliveryDate(undefined)
            }}
            placeholder={t('EditProject.NewPhaseModal.PhaseTypeSample')}
            maxDropDown={6}
          />

          <div className={`ui-row${errors.start || errors.end ? ' ui-has-error' : ''}`}>
            <div className="ui-col-12">
              <label>{t('EditProject.NewPhaseModal.Dates')}</label>
            </div>
            <div className="ui-col-6">
              <TextField
                name="start"
                className={pickerOpen === 'edit-project-phase-start' ? 'ui-input-focus' : ''}
                placeholder={t('EditProject.NewPhaseModal.SubmissionDateSample')}
                error={errors.start}
                value={start}
                disabled={!phaseType}
                onChange={() => {}}
                onClick={() => {
                  if (!phaseType || !project.phases) return

                  let min: Moment | undefined
                  let last: Moment | undefined
                  project.phases.forEach((ph) => {
                    if (min) return

                    if (ph.phaseType === phaseType?.id) {
                      if (last) min = moment(last).add(1, 'day')
                      else min = moment().add(1, 'day')
                    }
                    if (!last) last = moment(ph.deliveryDate)
                    else if (ph.deliveryDate && moment(ph.deliveryDate).isAfter(last)) last = moment(ph.deliveryDate)
                  })
                  if (!min) {
                    if (last) min = moment(last).add(1, 'day')
                    else min = moment().add(1, 'day')
                  }

                  setMinMax({ min, max: moment(min).add(1, 'year') })
                  setMarkers(updateTimelineMarkersFromProject(project, phaseTypes, t))
                  setPickerOpen('edit-project-phase-start')
                  setPickerStyle({ visibility: 'hidden' })
                  setTimeout(() => {
                    if (!pickerRef.current || !bodyRef.current) return
                    const r = bodyRef.current.getBoundingClientRect()
                    setPickerStyle({ visibility: 'visible', left: r.left, top: r.top + 147 })
                    pickerRef.current.focus()
                  })
                }}
                readOnly
              />
            </div>
            <div className="ui-col-6">
              <TextField
                name="end"
                className={pickerOpen === 'edit-project-phase-end' ? 'ui-input-focus' : ''}
                placeholder={t('EditProject.NewPhaseModal.DeliveryDateSample')}
                error={errors.end}
                value={end}
                disabled={!phaseType}
                onChange={() => {}}
                onClick={() => {
                  if (!phaseType) return
                  setPickerOpen('edit-project-phase-end')
                  setPickerStyle({ visibility: 'hidden' })
                  setTimeout(() => {
                    if (!pickerRef.current || !bodyRef.current) return
                    const r = bodyRef.current.getBoundingClientRect()
                    setPickerStyle({ visibility: 'visible', left: r.left + 108, top: r.top + 147 })
                    pickerRef.current.focus()
                  })
                }}
                readOnly
              />
            </div>
          </div>

          <TextField
            name="name"
            value={name}
            error={errors.name}
            label={
              <>
                <span>{t('EditProject.NewPhaseModal.Name')}</span> <span className="ui-text-muted">{t('General.Optional')}</span>
              </>
            }
            placeholder={t('EditProject.NewPhaseModal.NameSample')}
            onChange={(name: string) => {
              setName(name)
              clearError('name')
            }}
          />

          <TextAreaField
            name="description"
            className="ui-text-sm"
            value={description}
            error={errors.description}
            rows={5}
            label={
              <>
                <span>{t('EditProject.NewPhaseModal.Description')}</span> <span className="ui-text-muted">{t('General.Optional')}</span>
              </>
            }
            placeholder={t('EditProject.NewPhaseModal.DescriptionSample')}
            onChange={(description: string) => {
              setDescription(description)
              clearError('description')
            }}
          />

          <div>
            <button type="submit" className="ui-btn ui-btn-primary ui-btn-solid" style={{ width: '100%' }}>
              {t(action === 'add' ? 'General.Create' : 'General.Save')}
            </button>
          </div>
        </form>
      </div>

      {pickerOpen && (
        <DatePicker
          title={pickerOpen?.endsWith('end') ? t('General.DeliveryDate') : t('General.SubmissionDate')}
          forwardRef={pickerRef}
          style={pickerStyle}
          className={pickerOpen}
          start={submissionDate?.format()}
          end={deliveryDate?.format()}
          min={min?.format()}
          max={max?.format()}
          initDate={pickerOpen?.endsWith('end') ? end : start}
          markers={markers}
          noHighlight={false}
          maxMonths={1}
          selectRange={false}
          onClose={() => setPickerOpen(undefined)}
          onSelectDate={(d) => {
            if (pickerOpen?.endsWith('start')) {
              const start = moment(d)

              setStart(start.format('ll'))
              setSubmissionDate(start)

              const diff = moment.duration(submissionDate && deliveryDate ? deliveryDate.valueOf() - submissionDate.valueOf() : 0)
              const end = moment(d).add(Math.ceil(diff.asDays()) || PROJECT_PHASE_MIN_DAYS, 'days')

              setEnd(end.format('ll'))
              setDeliveryDate(end)
            } else if (pickerOpen?.endsWith('end')) {
              const end = moment(d)
              setEnd(end.format('ll'))
              setDeliveryDate(end)
            }
            setPickerOpen(undefined)
          }}
        />
      )}
    </Modal>
  )
}
