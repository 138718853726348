import React, { useState } from 'react'
import { Modal, ModalProps } from '../modal'
import { SelectField } from '../selectField'

type ChangeRoleModalProps = ModalProps & {
  title: JSX.Element | string
  message: JSX.Element | string
  roles: { role: string; label: string }[]
  selected: string
  yes: JSX.Element | string
  no: JSX.Element | string
  onYes: (role: string) => void
  onClose: () => void
}

export const ChangeRoleModal: React.FC<ChangeRoleModalProps> = ({ title, message, roles, selected: initSelected, yes, no, onYes, onClose, ...rest }) => {
  const [selected, setSelected] = useState(initSelected)

  return (
    <Modal header={<h3>{title}</h3>} onClose={onClose} closeOnClickOutside={false} {...rest}>
      <form
        noValidate
        onSubmit={(e) => {
          e.preventDefault()
          e.stopPropagation()
          onYes(selected)
        }}
      >
        <SelectField
          items={roles}
          selected={selected}
          label={message}
          labelField="label"
          valueField="role"
          onSelectedChange={(r: any) => setSelected(r.role)}
          renderSelected={() => <div>{roles.find((x) => x.role === selected)?.label}</div>}
        />

        <div className="ui-row">
          <div className="ui-col-6">
            <button
              type="button"
              className="ui-btn ui-btn-default ui-btn-solid"
              style={{ width: '100%' }}
              onClick={() => {
                if (onClose) onClose()
              }}
            >
              {no}
            </button>
          </div>
          <div className="ui-col-6">
            <button type="submit" className="ui-btn ui-btn-primary ui-btn-solid" style={{ width: '100%' }}>
              {yes}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
