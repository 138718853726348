import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment, { Moment } from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/pro-duotone-svg-icons'

import { Phase, Project } from 'services/src/dto/project'

import './style.scss'
import { useCurrentUserAccount, useRights } from 'services/src/state'
import { useProjectSupport } from 'services/src/api'
import { makeDashboardPath } from 'services/src/dom'
import { ClassifyProjectType } from './ClassifyProjectType'
import { Feature, useFeatures } from 'services/src/state/features'

export interface ProjectSummaryProps {
  project: Project
}

export const ProjectSummary: React.FC<ProjectSummaryProps> = ({ project }) => {
  const { t } = useTranslation()
  const [account] = useCurrentUserAccount()
  const rights = useRights()

  const { hasFeature } = useFeatures()
  const hasMultiProvider = hasFeature(Feature.MultiProvider)

  const [{ phaseTypes, budgetRanges, constructionTypes, procurementMethods, deliveryMethods, estimateTypes }] = useProjectSupport()

  const constructionType = useMemo(() => constructionTypes.find((x) => x.id === project.constructionType)?.type || t('General.NA'), [t, project, constructionTypes])

  const description = useMemo(() => {
    if (!project.description) return <div>{t('General.NA')}</div>
    return (
      <div>
        {project.description
          .trim()
          .split('\n')
          .map((l, idx) => (
            <p key={idx}>{l}</p>
          ))}
      </div>
    )
  }, [project, t])

  const budget = useMemo(() => {
    if (project.budget?.isFixed)
      return (
        <span>
          {t('General.Currency.Fixed')} {t('General.Currency.Value', { value: project.budget.fixed || 0 })} {t('General.Currency.Ident')}
        </span>
      )
    return <span>{budgetRanges.find((x) => x.id === project.budget?.range)?.range || t('General.NA')}</span>
  }, [t, project, budgetRanges])

  const procurementMethod = useMemo(() => procurementMethods.find((x) => x.id === project.procurementMethod)?.method || t('General.NA'), [t, project, procurementMethods])

  const deliveryMethod = useMemo(() => deliveryMethods.find((x) => x.id === project.deliveryMethod)?.method || t('General.NA'), [t, project, deliveryMethods])

  const estimateType = useMemo(() => estimateTypes.find((x) => x.id === project.estimateType)?.type || t('General.NA'), [t, project, estimateTypes])

  const phases = useMemo(
    () => (
      <div>
        {phaseTypes.map((pt, idx) => {
          const ph: Phase[] = project.phases?.filter((x) => x.phaseType === pt.id) || []

          let start: Moment = moment.invalid()
          let end: Moment = moment.invalid()
          ph.forEach((ph) => {
            if (!start.isValid() || moment(ph.submissionDate).isBefore(start, 'date')) start = moment(ph.submissionDate)
            if (!end.isValid() || moment(ph.deliveryDate).isAfter(end, 'date')) end = moment(ph.deliveryDate)
          })

          return (
            <div key={idx}>
              <div className="name">
                <h3>{pt.type}</h3>
              </div>
              {start.isValid() && end.isValid() ? (
                <div className="timeline">
                  <FontAwesomeIcon icon={faCalendarAlt} /> {start.format('MMM D')} - {end.format('MMM D')}
                </div>
              ) : (
                <div className="timeline">
                  <FontAwesomeIcon icon={faCalendarAlt} /> {t('General.NA')}
                </div>
              )}
            </div>
          )
        })}
      </div>
    ),
    [t, project, phaseTypes]
  )

  const [showProjectType, setShowProjectType] = useState(false)

  return (
    <>
      <div className="project-summary">
        <div className="ui-row">
          <div className={`ui-col-12 ${hasMultiProvider ? 'ui-col-md-8' : ''} ui-form-group`}>
            <label>{t('General.Project')}</label>
            <div>{project.name}</div>
          </div>

          {hasMultiProvider && (
            <div className="ui-col-12 ui-col-md-4 ui-form-group">
              <label>{t('General.Provider')}</label>
              {project.provider?.companyName ? (
                <div className="ui-flex ui-flex-nowrap">
                  {project.provider.logoUrl && (
                    <div
                      className="account-logo"
                      style={{
                        backgroundImage: `url('${project.provider.logoUrl}')`,
                        marginRight: 10
                      }}
                    />
                  )}
                  <div>{project.provider.companyName}</div>
                </div>
              ) : (
                <div>{t('General.NA')}</div>
              )}
            </div>
          )}
        </div>

        <hr style={{ margin: '-10px 0 10px 0' }} />

        {account?.id !== project.account?.id ? (
          <div className="ui-row">
            <div className="ui-col-12 ui-col-md-4 ui-form-group">
              <label>{t('General.Client')}</label>
              <div>{project.client?.name || t('General.NA')}</div>
            </div>

            <div className="ui-col-12 ui-col-md-4 ui-form-group">
              <label>{t('General.Account')}</label>
              {project.account?.companyName ? (
                <div className="ui-flex ui-flex-nowrap">
                  {project.account.logoUrl && (
                    <div
                      className="account-logo"
                      style={{
                        backgroundImage: `url('${project.account.logoUrl}')`,
                        marginRight: 10
                      }}
                    />
                  )}
                  <div>{project.account.companyName}</div>
                </div>
              ) : (
                <div>{t('General.NA')}</div>
              )}
            </div>

            <div className="ui-col-12 ui-col-md-4 ui-form-group">
              <label>{t('General.Office')}</label>
              {project.office?.name || t('General.NA')}
            </div>
          </div>
        ) : (
          <div className="ui-row">
            <div className="ui-col-12 ui-col-md-8 ui-form-group">
              <label>{t('General.Client')}</label>
              <div>{project.client?.name || t('General.NA')}</div>
            </div>

            <div className="ui-col-12 ui-col-md-4 ui-form-group">
              <label>{t('General.Office')}</label>
              {project.office?.name || t('General.NA')}
            </div>
          </div>
        )}

        <hr style={{ margin: '-10px 0 10px 0' }} />

        <div className="ui-row">
          <div className="ui-col-12 ui-col-md-4 ui-form-group">
            <label>{t('General.ProjectNo')}</label>
            <div className="wrapped-text" title={project.projectNumber || t('General.NA')}>
              {project.projectNumber || t('General.NA')}
            </div>
          </div>
          <div className="ui-col-12 ui-col-md-4 ui-form-group">
            <label>{t('General.PurchaseOrderNo')}</label>
            <div className="wrapped-text" title={project.taskOrderNumber || t('General.NA')}>
              {project.taskOrderNumber || t('General.NA')}
            </div>
          </div>
          <div className="ui-col-12 ui-col-md-4 ui-form-group">
            <label>{t('General.Budget')}</label>
            <div className="wrapped-text">{budget}</div>
          </div>
        </div>

        <hr style={{ margin: '-10px 0 10px 0' }} />

        <div className="ui-row">
          <div className="ui-col-12 ui-col-md-4 ui-form-group">
            <label>{t('General.ProcurementMethod')}</label>
            <div className="wrapped-text" title={procurementMethod}>
              {procurementMethod}
            </div>
          </div>
          <div className="ui-col-12 ui-col-md-4 ui-form-group">
            <label>{t('General.DeliveryMethod')}</label>
            <div className="wrapped-text" title={deliveryMethod}>
              {deliveryMethod}
            </div>
          </div>
          <div className="ui-col-12 ui-col-md-4 ui-form-group">
            <label>{t('General.EstimateType')}</label>
            <div className="wrapped-text" title={estimateType}>
              {estimateType}
            </div>
          </div>
        </div>

        <hr style={{ margin: '-10px 0 10px 0' }} />

        <div className="ui-row">
          <div className="ui-col-12 ui-col-lg-3 ui-form-group">
            <label>{t('General.Location')}</label>
            <div className="wrapped-text filled" title={project.location?.freeformAddress || t('General.NA')}>
              {project.location?.freeformAddress || t('General.NA')}
            </div>
          </div>
          <div className="ui-col-12 ui-col-lg-6 ui-form-group">
            <label className="ui-flex ui-flex-nowrap">
              <span>{t('General.ProjectType')}</span>
              {project.projectType?.category === 99 && rights?.canClassifyProjectType && (
                <Link
                  to={makeDashboardPath(account?.id, '')}
                  className="ui-secondary"
                  style={{ marginLeft: 'auto' }}
                  onClick={(e) => {
                    e.preventDefault()
                    setShowProjectType(true)
                  }}
                >
                  {t('General.CustomProjectType')}
                </Link>
              )}
            </label>
            <div className="wrapped-text filled" title={project.projectType?.type || t('General.NA')}>
              {project.projectType?.type || t('General.NA')}
            </div>
          </div>
          <div className="ui-col-12 ui-col-lg-3 ui-form-group">
            <label>{t('General.ConstructionType')}</label>
            <div className="wrapped-text filled" title={constructionType}>
              {constructionType}
            </div>
          </div>
        </div>

        <hr style={{ margin: '-10px 0 10px 0' }} />

        <div className="ui-form-group">
          <label>{t('General.ProjectDescription')}</label>
          {description}
        </div>

        <div className="estimate-cards">
          <label>{t('General.EstimatePhases')}</label>
          {phases}
        </div>
      </div>

      {showProjectType && <ClassifyProjectType projectType={project.projectType!} onClose={() => setShowProjectType(false)} onChange={() => setShowProjectType(false)} />}
    </>
  )
}
