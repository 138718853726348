import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import { SelectField } from 'components/src/selectField'
import { DisciplineKey } from 'services/src/dto/project'
import { MultiSelectField } from 'components/src/multiSelectField'
import { Availability, YearsOfExperience } from 'services/src/dto/account'

import { ExpertOnboardingCard } from './ExpertOnboardingCard'
import { NewAccountModel } from '../common/model'

export interface ExpertProfessionalExpertiseProps {
  model: NewAccountModel
  onChangeModel: (model: NewAccountModel) => void
  onNext: () => void
  onBack?: () => void
}

export const ExpertProfessionalExpertise: React.FC<ExpertProfessionalExpertiseProps> = ({ model, onChangeModel, onNext, onBack }) => {
  const { t } = useTranslation()

  const [errors, setErrors] = useState<any>({})
  const clearError = (name: string) => {
    const theErrors = { ...errors }
    delete theErrors[name]
    setErrors(theErrors)
  }

  const disciplines = useMemo<{ id: DisciplineKey; label: string }[]>(
    () => [
      { id: DisciplineKey.General, label: t(`General.Disciplines.${DisciplineKey.General}`) },
      { id: DisciplineKey.Mechanical, label: t(`General.Disciplines.${DisciplineKey.Mechanical}`) },
      { id: DisciplineKey.Electrical, label: t(`General.Disciplines.${DisciplineKey.Electrical}`) },
      { id: DisciplineKey.SiteCivil, label: t(`General.Disciplines.${DisciplineKey.SiteCivil}`) }
    ],
    [t]
  )
  const discipline = useMemo(() => disciplines.find((x) => x.id === model.primaryDiscipline), [model, disciplines])

  const yearsOfExpItems = useMemo<
    {
      id: YearsOfExperience
      label: string
    }[]
  >(
    () => [
      { id: YearsOfExperience.Lt2, label: t(`Onboarding.Expert.YearsOfExperienceItems.${YearsOfExperience.Lt2}`) },
      {
        id: YearsOfExperience.In2to5,
        label: t(`Onboarding.Expert.YearsOfExperienceItems.${YearsOfExperience.In2to5}`)
      },
      {
        id: YearsOfExperience.In5to10,
        label: t(`Onboarding.Expert.YearsOfExperienceItems.${YearsOfExperience.In5to10}`)
      },
      {
        id: YearsOfExperience.In10to15,
        label: t(`Onboarding.Expert.YearsOfExperienceItems.${YearsOfExperience.In10to15}`)
      },
      {
        id: YearsOfExperience.In15to20,
        label: t(`Onboarding.Expert.YearsOfExperienceItems.${YearsOfExperience.In15to20}`)
      },
      {
        id: YearsOfExperience.In20to25,
        label: t(`Onboarding.Expert.YearsOfExperienceItems.${YearsOfExperience.In20to25}`)
      },
      { id: YearsOfExperience.Gt25, label: t(`Onboarding.Expert.YearsOfExperienceItems.${YearsOfExperience.Gt25}`) }
    ],
    [t]
  )

  const availabilityItems = useMemo<
    {
      id: Availability
      label: string
    }[]
  >(
    () => [
      { id: Availability.FullTime, label: t(`Onboarding.Expert.${Availability.FullTime}`) },
      { id: Availability.PartTime, label: t(`Onboarding.Expert.${Availability.PartTime}`) },
      { id: Availability.Contractor, label: t(`Onboarding.Expert.${Availability.Contractor}`) }
    ],
    [t]
  )

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      const errors: any = {}
      if (!model.primaryDiscipline) errors.primaryDiscipline = t('General.Errors.Required')
      if (!model.skills.length) errors.skills = t('General.Errors.Required')
      if (!model.yearsOfExp) errors.yearsOfExp = t('General.Errors.Required')
      if (!model.availability) errors.availability = t('General.Errors.Required')

      setErrors(errors)
      if (Object.keys(errors).length > 0) return
      onNext()
    },
    [model, onNext, setErrors, t]
  )

  return (
    <form noValidate onSubmit={submit}>
      <h3 className="intro">{t('Onboarding.Expert.ExpertiseIntro', { name: model.givenName })}</h3>
      <hr />
      <br />
      <ExpertOnboardingCard model={model} />
      <br />

      <div className="ui-text-sm ui-text-muted" style={{ marginBottom: 15 }}>
        {t('Onboarding.Expert.Step2', { N: 2, M: 5 })}
      </div>

      <SelectField
        items={disciplines}
        selected={discipline}
        labelField="label"
        valueField="id"
        placeholder={t('Onboarding.Expert.PrimaryDiscipline')}
        label={t('Onboarding.Expert.PrimaryDiscipline')}
        error={errors.primaryDiscipline}
        onSelectedChange={(pd) => {
          clearError('primaryDiscipline')
          const primaryDiscipline = pd.id;
          onChangeModel({
            ...model,
            primaryDiscipline,
            skills: model.disciplineSkills.find((x) => x.discipline === primaryDiscipline)?.skills.map((s) => ({ label: s })) || []
          })
        }}
      />

      <MultiSelectField
        items={model.skills}
        className="ui-text-sm"
        style={{ height: 250 }}
        label={t('Onboarding.Expert.DisciplineSkills')}
        error={errors.skills}
        onToggle={(item) => {
          clearError('skills')
          const skills = [...model.skills]
          const sel = skills.find((x) => x.label === item.label)
          if (sel) {
            sel.selected = !sel.selected
            onChangeModel({ ...model, skills })
          }
        }}
      />

      <SelectField
        items={yearsOfExpItems}
        selected={model.yearsOfExp}
        labelField="label"
        valueField="id"
        placeholder={t('Onboarding.Expert.YearsOfExperiencePlaceholder')}
        error={errors.yearsOfExp}
        label={t('Onboarding.Expert.YearsOfExperience')}
        onSelectedChange={(yearsOfExp) => {
          clearError('yearsOfExp')
          onChangeModel({ ...model, yearsOfExp })
        }}
      />

      <SelectField
        items={availabilityItems}
        selected={availabilityItems.find((x) => x.id === model.availability?.id)}
        labelField="label"
        valueField="id"
        placeholder={t('Onboarding.Expert.WorkAvailabilityPlaceholder')}
        error={errors.availability}
        label={t('Onboarding.Expert.WorkAvailability')}
        onSelectedChange={(availability) => {
          clearError('availability')
          onChangeModel({ ...model, availability })
        }}
      />

      <div className="ui-row">
        <div className="ui-col-6">
          <button
            type="button"
            className="ui-btn ui-btn-primary"
            style={{ width: '100%' }}
            onClick={() => {
              if (onBack) onBack()
            }}
          >
            {t('Onboarding.Back')}
          </button>
        </div>
        <div className="ui-col-6">
          <button type="submit" className="ui-btn ui-btn-secondary ui-btn-solid" style={{ width: '100%' }}>
            {t('Onboarding.Next')}
          </button>
        </div>
      </div>
    </form>
  )
}
