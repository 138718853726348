import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import moment from 'moment'

import { Modal, ModalProps } from 'components/src/modal'
import { TextAreaField } from 'components/src/textAreaField'
import { OfferItem } from './offerTypes'

export const ExpertDeclineModal: React.FC<
  ModalProps & {
    items: OfferItem[]
    onYes: (reason: string) => void
  }
> = ({ items, onClose, onYes }) => {
  const { t } = useTranslation()

  const [reason, setReason] = useState('')

  return (
    <Modal onClose={onClose} closeOnClickOutside={false} header={<h3 className="ui-text-bold">{t('Expert.DeclineModal.Title')}</h3>}>
      <div className="ui-text-center ui-danger">
        <FontAwesomeIcon icon={faExclamationTriangle} size="3x" />
      </div>
      <br />

      <div className="ui-text-sm" style={{ padding: '0 30px' }}>
        <div className="ui-flex ui-flex-nowrap ui-text-bold">
          <div>{t('Expert.Overview.Phase')}</div>
          <div style={{ marginLeft: 'auto' }}>{t('Expert.Overview.DueDate')}</div>
        </div>

        {items
          .filter((item) => item.phase && !item.estimate)
          .map((phItem) => {
            const estimateItems = items.filter((item) => item.estimate && item.phase?.id === phItem.phase!.id)
            return (
              <div key={phItem.phase!.id} className="ui-flex ui-flex-nowrap">
                <div>
                  <div style={{ marginBottom: 5 }}>{t(`EditProject.${phItem.phase!.phaseType}`)}</div>
                  {estimateItems.map((estItem) => (
                    <div key={estItem.estimate?.id} style={{ paddingLeft: 15 }}>
                      {t(`General.Disciplines.${estItem.estimate!.discipline}`)}
                    </div>
                  ))}
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <div style={{ marginBottom: 5 }}>{moment(phItem.phase!.deliveryDate).format('LL')}</div>
                  {estimateItems.map((estItem) => (
                    <div key={estItem.estimate!.id} className="ui-text-right">
                      {t('General.Currency.Value', { value: estItem.total })}
                    </div>
                  ))}
                </div>
              </div>
            )
          })}

        <hr />
        <div className="ui-flex ui-flex-nowrap ui-text-bold">
          <div>{t('Expert.DeclineModal.TotalOffer')}</div>
          <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: items[items.length - 1].adjTotal })}</div>
        </div>
      </div>
      <br />

      <TextAreaField
        className="ui-text-sm"
        name="why"
        value={reason}
        label={t('Expert.DeclineModal.Reason')}
        placeholder={t('Expert.DeclineModal.ReasonSample')}
        rows={2}
        onChange={(v) => setReason(v)}
      />

      <div>
        <button type="button" className="ui-btn ui-btn-secondary ui-btn-solid" style={{ width: '100%' }} onClick={() => onYes(reason)}>
          {t('Expert.DeclineModal.Title')}
        </button>
      </div>
    </Modal>
  )
}
