import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'

import { useSaving } from 'components/src/saving'
import { useEditProject, useRights, UserType } from 'services/src/state'
import { FeeType, ProjectStatus } from 'services/src/dto/project'
import { ConfirmModal } from 'components/src/modal/ConfirmModal'
import ProjectSvg from 'components/src/svg/Projects'
import logo from 'components/src/img/logo-alt.png'

export const FeeProviderReady: React.FC<{ onSendToClient: () => void }> = ({ onSendToClient }) => {
  const { t } = useTranslation()
  const rights = useRights()
  const [saving] = useSaving()

  const { project } = useEditProject()

  const { phases, total } = useMemo(() => {
    const phases = project.phases.filter((x) => [ProjectStatus.FeeSelected, ProjectStatus.Accepted].includes(x.status!))
    return {
      phases,
      total: phases.reduce((sum, ph) => sum + (ph.manualTotals?.total || 0), 0)
    }
  }, [project])

  const [sendToClient, setSendToClient] = useState(false)

  if (rights?.userType !== UserType.provider || !phases?.length) return null

  return (
    <>
      <div className="ui-frame ui-frame-bg project-edit-client-accept" style={{ maxWidth: 450 }}>
        <div className="the-total">
          <img alt="" src={logo} />
          <div style={{ marginLeft: 10 }}>{t('EditProject.Fees.SendToClient.ReadyToSend', { count: phases.length })}</div>
        </div>

        <div style={{ marginTop: 20 }} className="ui-text-sm">
          {phases.map((ph) => (
            <div key={ph.id} className="ui-flex ui-flex-nowrap">
              <div className="ui-text-bold">{t(`EditProject.${ph.phaseType}`)}</div>
              <div className="ui-flex" style={{ marginLeft: 'auto' }}>
                <div>
                  <div>{t('Subtotal')}:</div>
                  <div>{t('Other fees')}:</div>
                  <div>{t('Tech Fee')}:</div>
                  <div className="ui-text-bold">{t('Phase total')}:</div>
                </div>
                <div className="ui-text-right" style={{marginLeft: 10}}>
                  <div>{t('General.Currency.Value', { value: ph.manualTotals?.subtotal || 0 })}</div>
                  <div>{t('General.Currency.Value', { value: ph.manualTotals?.otherFeeTotal || 0 })}</div>
                  <div>{t('General.Currency.Value', { value: ph.providerFee || 0 })}</div>
                  <div className="ui-text-bold">{t('General.Currency.Value', { value: ph.manualTotals?.total || 0 })}</div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="ui-flex ui-flex-nowrap ui-text-lg ui-text-bold" style={{ marginTop: 20 }}>
          <div style={{ width: 200 }}>{t('General.Total')}</div>
          <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: total })}</div>
        </div>

        <button type="button" disabled={saving} style={{ marginTop: 20, width: '100%' }} className="ui-btn ui-btn-solid ui-btn-secondary" onClick={() => setSendToClient(true)}>
          {t('EditProject.Fees.SendToClient.Title')}
        </button>
      </div>

      {sendToClient && (
        <ConfirmModal
          title={t('EditProject.Fees.SendToClient.Title')}
          message={
            <>
              {/*
              <div className="ui-text-center">
                <ProjectSvg width="75" />
              </div>
              */}
              <div style={{ marginBottom: 20 }}>{t('EditProject.Fees.SendToClient.Message')}</div>

              <div className="ui-text-sm" style={{ padding: '0 20px' }}>
                <div className="ui-flex ui-flex-nowrap ui-text-bold">
                  <div>{t('EditProject.Fees.SendToClient.Phase')}</div>
                  <div style={{ marginLeft: 'auto' }}>{t('EditProject.Fees.SendToClient.Amount')}</div>
                </div>

                {phases.map((ph) => (
                  <div key={ph.id} className="ui-flex ui-flex-nowrap">
                    <div style={{ width: 200 }}>{t(`EditProject.${ph.phaseType}`)}</div>
                    <div style={{ marginLeft: 'auto' }}>
                      {t('General.Currency.Value', {
                        value: ph.feeType === FeeType.Generated ? ph.generatedTotals?.total || 0 : ph.manualTotals?.total || 0
                      })}
                    </div>
                  </div>
                ))}
                <hr />
                <div className="ui-flex ui-flex-nowrap ui-text-bold">
                  <div>{t('EditProject.Fees.SendToClient.Total')}</div>
                  <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: total })}</div>
                </div>
              </div>

              <div className="ui-frame ui-secondary ui-text-bold ui-text-sm ui-flex" style={{ marginTop: 15 }}>
                <div>
                  <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
                </div>
                <div style={{ marginLeft: 10 }}>{t('EditProject.Fees.SendToClient.Warning')}</div>
              </div>
            </>
          }
          yes={t('EditProject.Fees.SendToClient.Title')}
          no={t('General.Cancel')}
          onYes={() => {
            setSendToClient(false)
            onSendToClient()
          }}
          onClose={() => setSendToClient(false)}
        />
      )}
    </>
  )
}

export default FeeProviderReady
