import React from 'react'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { aadConfig } from 'services/src/aad'
import { Loading } from 'components/src/loading'

const MaslError: React.FC<{ error: any }> = ({ error }) => {
  if (error?.errorMessage.includes('user has cancelled entering self-asserted information')) {
    window.location.href = `${process.env.PUBLIC_URL}`
    return null
  }
  return (
    <>
      <h3>An Error Occurred: {error.errorCode}</h3>;<pre>{JSON.stringify(error, null, 2)}</pre>
    </>
  )
}

export const Protected: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const fn = () => <Loading />
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={{
        scopes: aadConfig().scopes,
        redirectStartPage: window.location.href
      }}
      errorComponent={MaslError}
      loadingComponent={fn}
    >
      {children}
    </MsalAuthenticationTemplate>
  )
}
