import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import './style.scss'

import { useTranslation } from 'react-i18next'
import { useCurrentUserAccount, useRights, UserType } from 'services/src/state'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import moment from 'moment'
import {
  FilterChangedEvent,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  IDatasource,
  IGetRowsParams,
  SortChangedEvent,
  SortModelItem,
  ValueFormatterParams,
  ValueGetterParams
} from 'ag-grid-community'
import 'ag-grid-enterprise'

import { getClientTransactions, getProviderTransactions, getTransactionStats, getUserTransactions, TransactionStats } from 'services/src/api'
import { makeDashboardPath, setTitle } from 'services/src/dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare, faTimesCircle } from '@fortawesome/pro-regular-svg-icons'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Transaction } from 'services/src/dto/transaction'
import { TransactionListHeader } from './TransactionListHeader'
import LoadingSvg from '../../svg/Loading'
import { AccountType } from 'services/src/dto/account'

const NoRowsOverlay: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className="no-rows-overlay">
      <h3>{t('General.NoTransactions')}</h3>
    </div>
  )
}

type LoadingCellProps = ICellRendererParams & { loadingMessage?: string }
const LoadingCell = ({ loadingMessage }: LoadingCellProps) => (
  <div className="ag-custom-loading-cell ui-flex ui-flex-nowrap ui-text-muted" style={{ alignItems: 'center', paddingLeft: 20 }}>
    <LoadingSvg style={{ width: 25 }} />
    {loadingMessage && <div style={{ marginLeft: 10 }}>{loadingMessage}</div>}
  </div>
)

type DateCellProps = ICellRendererParams & {
  loadingMessage?: string
}
const DateCell = ({ value, loadingMessage }: DateCellProps) => {
  if (!value)
    return (
      <div className="ag-custom-loading-cell ui-flex ui-flex-nowrap ui-text-muted" style={{ alignItems: 'center' }}>
        <LoadingSvg style={{ width: 25, minWidth: 25 }} />
        {loadingMessage && <div style={{ marginLeft: 10 }}>{loadingMessage}</div>}
      </div>
    )

  return value
}

const transactionDatasource = (gridApi: GridApi, id: string, type: string) =>
  ({
    getRows: ({ sortModel, filterModel, successCallback, failCallback, startRow, endRow }: IGetRowsParams) => {
      let sort = 'name'
      let sortDir = 'asc'
      if (sortModel?.length) {
        sort = sortModel[0].colId
        sortDir = sortModel[0].sort
      }

      let filter: string | undefined
      if (filterModel) {
        const parts: string[] = []
        Object.keys(filterModel).forEach((colId) => {
          const f = filterModel[colId]
          if (f.filterType === 'text') parts.push(`${colId} ${f.type}('${f.filter}')`)
          else if (f.filterType === 'number') {
            if (f.type === 'inRange') parts.push(`${colId} ${f.type}('${f.filter}', '${f.filterTo}')`)
            else parts.push(`${colId} ${f.type}('${f.filter}')`)
          } else if (f.filterType === 'date') {
            if (f.type === 'inRange') parts.push(`${colId} ${f.type}('${moment(f.dateFrom.split(' ')[0]).valueOf()}', '${moment(f.dateTo.split(' ')[0]).valueOf()}')`)
            else parts.push(`${colId} ${f.type}('${moment(f.dateFrom.split(' ')[0]).valueOf()}')`)
          } else if (f.filterType === 'set') {
            parts.push(`${colId} contains('${f.values.join(',')}')`)
          }
        })
        filter = parts.join(' and ')
      }

      if (type === 'client') {
        getClientTransactions(id, startRow || 0, (endRow || 0) - (startRow || 0), sort, sortDir, filter)
          .then(({ total, transactions }) => {
            successCallback(transactions, total)
            if (!total) gridApi.showNoRowsOverlay()
            else gridApi.hideOverlay()
          })
          .catch(() => failCallback())
        return
      }

      if (type === 'provider') {
        getProviderTransactions(id, startRow || 0, (endRow || 0) - (startRow || 0), sort, sortDir, filter)
          .then(({ total, transactions }) => {
            successCallback(transactions, total)
            if (!total) gridApi.showNoRowsOverlay()
            else gridApi.hideOverlay()
          })
          .catch(() => failCallback())
        return
      }

      if (type === 'user') {
        getUserTransactions(id, startRow || 0, (endRow || 0) - (startRow || 0), sort, sortDir, filter)
          .then(({ total, transactions }) => {
            successCallback(transactions, total)
            if (!total) gridApi.showNoRowsOverlay()
            else gridApi.hideOverlay()
          })
          .catch(() => failCallback())
        return
      }

      successCallback([], 0)
      gridApi.showNoRowsOverlay()
    }
  } as IDatasource)

const ProjectCell = ({ data }: Omit<ICellRendererParams, 'data'> & { data: Transaction }) => {
  const [account] = useCurrentUserAccount()

  if (!data || !data.project) return <div />

  return (
    <div>
      <Link to={makeDashboardPath(account?.id, `projects/${data.project.id}`)} className="ui-secondary">
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> {data.project.name}
      </Link>
    </div>
  )
}

export const TransactionList: React.FC = () => {
  const { t } = useTranslation()
  const rights = useRights()
  const [account] = useCurrentUserAccount()

  useEffect(() => {
    setTitle(t('General.Transactions'))
    return () => {
      setTitle()
    }
  }, [t])

  const sortKey = useMemo(() => (rights?.userType === UserType.client ? 'clientTxSort' : 'providerTxSort'), [rights])
  const filterKey = useMemo(() => (rights?.userType === UserType.client ? 'clientTxFilter' : 'providerTxFilter'), [rights])

  const [stats, setStats] = useState<TransactionStats>()

  const [sort, setSort] = useState(() => {
    const s = localStorage.getItem(sortKey)
    return s ? JSON.parse(s) : [{ colId: 'date', sort: 'desc' }]
  })

  const sortChange = useCallback(
    ({ columnApi }: SortChangedEvent) => {
      const s = columnApi.getColumnState()
      const sm = s.filter((x) => !!x.colId && !!x.sort).map<SortModelItem>((x) => ({ colId: x.colId!, sort: x.sort! }))

      if (sm.length <= 0) return

      setSort(sm)
      if (sortKey) localStorage.setItem(sortKey, JSON.stringify(sm))
    },
    [setSort, sortKey]
  )

  const [filter, setFilter] = useState(() => {
    const f = localStorage.getItem(filterKey)
    return f ? JSON.parse(f) : undefined
  })

  const filterChange = useCallback(
    ({ api }: FilterChangedEvent) => {
      const fm = api.getFilterModel()
      if (!fm || !Object.keys(fm).length) {
        if (filterKey) localStorage.removeItem(filterKey)
        setFilter(undefined)
        return
      }

      setFilter(fm)
      if (filterKey) localStorage.setItem(filterKey, JSON.stringify(fm))
    },
    [setFilter, filterKey]
  )

  const [gridApi, setGridApi] = useState<GridApi>()
  const gridReady = useCallback(
    ({ api, columnApi }: GridReadyEvent) => {
      setGridApi(api)
      api.setFilterModel(filter)
      columnApi.applyColumnState({
        state: [...sort]
      })
    },
    [setGridApi, filter, sort]
  )

  useEffect(() => {
    if (!gridApi || !account || !rights) return
    if (rights?.accountType === AccountType.Client) {
      gridApi.setDatasource(transactionDatasource(gridApi, account.id, 'client'))
    } if (rights?.accountType === AccountType.Provider) {
      gridApi.setDatasource(transactionDatasource(gridApi, account.id, 'provider'))
    } else if (rights?.userType === UserType.expert) {
      gridApi.setDatasource(transactionDatasource(gridApi, rights.id, 'user'))
    }
  }, [gridApi, account, rights])

  useEffect(() => {
    if (!account) return
    getTransactionStats({
      resourceId: account.id,
      type: rights?.accountType,
      start: moment.utc().startOf('y').valueOf(),
      end: moment.utc().endOf('y').valueOf(),
      filter
    })
      .then((stats) => setStats(stats))
  }, [account, rights])

  const [height, setHeight] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const resize = () => {
      if (containerRef.current) {
        const r = containerRef.current.getBoundingClientRect()
        setHeight(window.innerHeight - r.top)
      }
    }
    window.addEventListener('resize', resize)
    setTimeout(() => resize())
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  useEffect(() => {
    if (containerRef.current) {
      const r = containerRef.current.getBoundingClientRect()
      containerRef.current.style.overflow = 'inherit'
      setHeight(window.innerHeight - r.top)
    }
  }, [containerRef.current])

  const threeMonthChartOptions = useMemo(() => {
    if (!stats?.threeMonth.length) return undefined

    const options: Highcharts.Options = {
      chart: {
        type: 'column',
        width: 150,
        height: 100,
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        plotBackgroundColor: 'transparent'
      },
      colors: ['#11861b', '#a11c1f'],
      title: { text: undefined },
      xAxis: {
        title: { text: undefined },
        categories: [
          moment().add(-2, 'month').format('MMM'),
          moment().add(-1, 'month').format('MMM'),
          moment().format('MMM')],
        crosshair: true,
        gridLineColor: '#ccc',
        lineColor: '#ccc'
      },
      yAxis: {
        min: 0,
        title: { text: undefined },
        gridLineColor: '#ccc',
        lineColor: '#ccc',
        labels: { enabled: false }
      },
      tooltip: {
        formatter() {
          const self = this as any

          console.log('self', self)

          const lines = [`<strong>${self.x}</strong>`]
          lines.push(`Credits: ${t('General.Currency.Value', { value: self.points[0].y, maxDigits: 0 })}`)
          lines.push(`Debits: ${t('General.Currency.Value', { value: self.points[1].y, maxDigits: 0 })}`)

          return lines.join('<br />')
        },
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.01,
          borderWidth: 0,
          stacking: 'normal'
        }
      },
      series: [
        {
          type: 'column',
          name: 'Credit',
          showInLegend: false,
          data: [
            stats.threeMonth.find((x) => x.month === moment().add(-2, 'month').month() + 1)?.credit || 0,
            stats.threeMonth.find((x) => x.month === moment().add(-1, 'month').month() + 1)?.credit || 0,
            stats.threeMonth.find((x) => x.month === moment().month() + 1)?.credit || 0
          ]
        },
        {
          type: 'column',
          name: 'Debit',
          showInLegend: false,
          data: [
            (stats.threeMonth.find((x) => x.month === moment().add(-2, 'month').month() + 1)?.debit || 0),
            (stats.threeMonth.find((x) => x.month === moment().add(-1, 'month').month() + 1)?.debit || 0),
            (stats.threeMonth.find((x) => x.month === moment().month() + 1)?.debit || 0)
          ]
        }
      ]
    }
    return options
  }, [stats, t])

  return (
    <div className="ui-transaction-list">
      <TransactionListHeader stats={stats} />

      <div className="ui-flex ui-transaction-list-stats ui-frame" style={{ marginBottom: 15 }}>
        {!stats && <LoadingSvg style={{ width: 25, opacity: 0.5 }} />}
        {stats && threeMonthChartOptions === undefined && (
          <div className="none ui-text-center ui-text-muted" style={{marginTop: 20}}>{t('General.NoTransactions')}</div>
        )}

        {stats && (
          <div className="credits ui-text-right" style={{ marginRight: 20 }}>
            <label>{t('General.CreditsYTD')}</label>
            <div className="ui-text-xl">{t('General.Currency.Value', { value: stats.credit || 0, maxDigits: 0 })}</div>
          </div>
        )}

        {stats && (
          <div className="debits ui-text-right" style={{ marginRight: 20 }}>
            <label>{t('General.DebitsYTD')}</label>
            <div className="ui-text-xl">{t('General.Currency.Value', { value: stats.debit || 0, maxDigits: 0 })}</div>
          </div>
        )}

        {threeMonthChartOptions && (
          <div style={{ marginLeft: 20 }}>
            <HighchartsReact highcharts={Highcharts} options={threeMonthChartOptions} />
          </div>
        )}
      </div>

      <div className="ui-transaction-list-filter ui-flex ui-flex-nowrap" style={{ justifyContent: 'flex-end' }}>
        <button
          type="button"
          className={`ui-btn ui-btn-xs${filter ? ' ui-btn-pressed ui-btn-info' : ''}`}
          onClick={() => {
            if (filterKey) localStorage.removeItem(filterKey)
            setFilter(undefined)
            if (gridApi) gridApi.setFilterModel(undefined)
          }}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
          <span style={{ marginLeft: 5 }}>{t('General.ClearFilter')}</span>
        </button>
      </div>

      <div className="ag-theme-cc" style={{ height, width: '100%', overflow: 'hidden' }} ref={containerRef}>
        <AgGridReact
          defaultColDef={{
            width: 150,
            editable: false,
            resizable: true,
            sortable: true,
            sortingOrder: ['asc', 'desc']
          }}
          rowHeight={45}
          autoGroupColumnDef={{ field: 'id' }}
          frameworkComponents={{
            dateCell: DateCell,
            noRowsOverlay: NoRowsOverlay,
            loadingCell: LoadingCell,
            projectCell: ProjectCell
          }}
          blockLoadDebounceMillis={500}
          cacheBlockSize={25}
          rowSelection="single"
          suppressCellSelection
          suppressRowHoverHighlight
          rowModelType="infinite"
          noRowsOverlayComponent="noRowsOverlay"
          onCellClicked={({ node }) => {
            node.setExpanded(!node.expanded)
          }}
          onSortChanged={sortChange}
          onFilterChanged={filterChange}
          onGridReady={gridReady}
        >
          <AgGridColumn field="id" hide />

          <AgGridColumn
            field="date"
            headerName={t('General.Date')}
            valueGetter={({ data }: ValueGetterParams) => (data ? moment(data.createdAt).format('l') : '')}
            cellRenderer="dateCell"
            cellRendererParams={{ loadingMessage: t('General.OneMoment') }}
            filter="agDateColumnFilter"
            filterParams={{
              filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
              defaultOption: 'equals',
              suppressAndOrCondition: true
            }}
            minWidth={120}
            maxWidth={250}
            width={200}
          />

          <AgGridColumn
            field="note"
            headerName={t('General.Transaction')}
            valueGetter={({ data }: ValueGetterParams) => data?.note || ''}
            filter="agTextColumnFilter"
            filterParams={{
              filterOptions: ['contains', 'startsWith', 'endsWith'],
              defaultOption: 'contains',
              suppressAndOrCondition: true
            }}
            minWidth={200}
            flex={1}
          />

          {rights?.accountType === AccountType.Provider && (
            <AgGridColumn
              field="account"
              headerName={t('General.ClientAccount')}
              valueGetter={({ data }: ValueGetterParams) => data?.account?.companyName || ''}
              filter="agTextColumnFilter"
              filterParams={{
                filterOptions: ['contains', 'startsWith', 'endsWith'],
                defaultOption: 'contains',
                suppressAndOrCondition: true
              }}
              minWidth={150}
              flex={0.5}
            />
          )}

          {rights?.accountType === AccountType.Client && (
            <AgGridColumn
              field="pm"
              headerName={t('Projects.ProviderProjectManager')}
              valueGetter={({ data }: ValueGetterParams) => data?.project?.projectManager || ''}
              filter="agTextColumnFilter"
              filterParams={{
                filterOptions: ['contains', 'startsWith', 'endsWith'],
                defaultOption: 'contains',
                suppressAndOrCondition: true
              }}
              minWidth={150}
              flex={0.5}
            />
          )}

          <AgGridColumn
            field="project"
            headerName={t('General.Project')}
            valueGetter={({ data }: ValueGetterParams) => data?.project?.name || ''}
            cellRenderer="projectCell"
            filter="agTextColumnFilter"
            filterParams={{
              filterOptions: ['contains', 'startsWith', 'endsWith'],
              defaultOption: 'contains',
              suppressAndOrCondition: true
            }}
            minWidth={150}
            flex={0.5}
          />

          <AgGridColumn
            field="debit"
            headerName={t('General.Debit')}
            valueGetter={({ data }: ValueGetterParams) => data?.debit}
            valueFormatter={({ value }: ValueFormatterParams) => (value > 0 ? t('General.Currency.Value', { value }) : '')}
            type="rightAligned"
            filter="agNumberColumnFilter"
            filterParams={{
              filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
              defaultOption: 'equals',
              suppressAndOrCondition: true,
              allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
              numberParser: (text?: string) => (!text ? null : parseFloat(text.replace(',', '.')))
            }}
            width={150}
            minWidth={150}
            maxWidth={150}
          />

          <AgGridColumn
            field="credit"
            headerName={t('General.Credit')}
            valueGetter={({ data }: ValueGetterParams) => data?.credit}
            valueFormatter={({ value }: ValueFormatterParams) => (value > 0 ? t('General.Currency.Value', { value }) : '')}
            type="rightAligned"
            filter="agNumberColumnFilter"
            filterParams={{
              filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
              defaultOption: 'equals',
              suppressAndOrCondition: true,
              allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
              numberParser: (text?: string) => (!text ? null : parseFloat(text.replace(',', '.')))
            }}
            width={150}
            minWidth={150}
            maxWidth={150}
          />
        </AgGridReact>
      </div>
    </div>
  )
}
