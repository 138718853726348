import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { PhaseEx, useEditProject, useRights, UserType } from 'services/src/state'
import { ProjectStatus } from 'services/src/dto/project'
import logo from 'components/src/img/logo-alt.png'

export const FeeProviderSent: React.FC = () => {
  const { t } = useTranslation()
  const rights = useRights()

  const { project } = useEditProject()

  const phases = useMemo(() => project.phases.filter((x) => x.status === ProjectStatus.FeeReady), [project])

  const total = useMemo(() => {
    return phases.reduce((sum, ph) => sum + (ph.manualTotals?.total || 0), 0)
  }, [phases])

  const sentAt = useMemo(() => moment(project.statusHistory?.find((x) => x.newStatus === ProjectStatus.FeeReady)?.createdAt), [phases])

  const groups = useMemo(() => {
    const groups: { [key: string]: PhaseEx[] } = { x: [] }
    phases.forEach((ph) => {
      const createdAt = ph.statusHistory?.find((x) => x.newStatus === ProjectStatus.FeeReady)?.createdAt
      if (!createdAt) groups.x.push(ph)
      else {
        const k = moment(createdAt).format('YYYY-MM-DD')
        if (!groups[k]) groups[k] = []
        groups[k].push(ph)
      }
    })
    return groups
  }, [phases])

  if (rights?.userType !== UserType.provider || !phases?.length) return null

  return (
    <div className="ui-frame ui-frame-bg project-edit-client-accept" style={{ maxWidth: 450 }}>
      <div className="the-total">
        <img alt="" src={logo} />
        <div style={{ marginLeft: 10, flex: 1 }}>{t('EditProject.Fees.ClientFeeSent')}</div>
        <div>{sentAt.format('ll')}</div>
      </div>
      <br />

      {Object.keys(groups).map((k, idx) => {
        if (groups[k].length <= 0) return null

        const sx = moment(k)
        return (
          <div key={idx} className="ui-text-sm" style={{ padding: '10px 0' }}>
            {idx > 0 && !sentAt.isSame(sx, 'date') && (
              <div className="ui-text-muted ui-text-xs" style={{ marginTop: idx > 0 ? 10 : 0 }}>
                {sx.format('ll')}
              </div>
            )}
            {groups[k].map((ph) => (
              <div key={ph.id} className="ui-flex ui-flex-nowrap">
                <div>{t(`EditProject.${ph.phaseType}`)}</div>
                <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: ph.manualTotals?.total || 0 })}</div>
              </div>
            ))}
          </div>
        )
      })}

      <div className="ui-flex ui-flex-nowrap ui-text-lg ui-secondary" style={{ marginTop: 10 }}>
        <div style={{ width: 200 }}>{t('General.Total')}</div>
        <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: total })}</div>
      </div>
    </div>
  )
}
