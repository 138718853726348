import React, { useCallback, useEffect, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAccountWithUser, useCurrentUserAccount, useCurrentUserOffice, useRights, useUserAccounts, useUserOffices } from 'services/src/state'
import { Office } from 'services/src/dto/account'
import { CreateInvitation } from 'services/src/dto/invitation'
import { deleteOfficeUser, upsertOffice, deleteOffice, updateOfficeUserRole, getOffice, createInvitation } from 'services/src/api'

import './style.scss'
import { useAlert } from 'components/src/alerts'
import { Loading } from 'components/src/loading'
import { ConfirmModal } from 'components/src/modal/ConfirmModal'
import { setTitle } from 'services/src/dom'
import { useSaving } from 'components/src/saving'
import OfficeProfile from './OfficeProfile'
import OfficeStaff from './OfficeStaff'
import OfficeSubheader from './OfficeSubheader'
import { OfficeNav } from './OfficeNav'
import { InviteModal } from './InviteModal'

export const EditOffice: React.FC = () => {
  const { t } = useTranslation()
  const {
    params: { officeId }
  } = useRouteMatch<{ officeId: string }>()
  const history = useHistory()
  const { alertDanger, alertSuccess } = useAlert()
  const { url, path } = useRouteMatch()
  const rights = useRights(officeId !== 'new' ? officeId : undefined)
  const [, setSaving] = useSaving()

  const [, refreshAccounts] = useUserAccounts()
  const [account] = useCurrentUserAccount()
  const [accountWithUsers, refreshUsers] = useAccountWithUser(account?.id)
  const [, setCurrentOffice] = useCurrentUserOffice()
  const [, refreshOffices, inOfficeRefresh] = useUserOffices()
  const [office, setOffice] = useState<Office>({} as Office)

  const [ready, setReady] = useState(false)
  const [invite, setInvite] = useState(false)
  const [aside, setAside] = useState(true)
  const [confirmDelete, setConfirmDelete] = useState<Office>()

  useEffect(() => {
    setTitle(officeId === 'new' ? t('Offices.NewOffice') : t('Offices.EditOffice'))
    return () => {
      setTitle()
    }
  }, [officeId, t])

  const loadOffice = useCallback(
    (accountId: string, officeId: string) =>
      getOffice(accountId, officeId)
        .then((office) => {
          if (!office) {
            alertDanger({
              title: t('General.Errors.NotFoundTitleAlt'),
              message: t('EditOffice.Errors.NotFound')
            })
            history.replace(`/dashboard/${accountId}/offices`)
            return
          }

          setCurrentOffice(office)
          setOffice({ ...office })
          setReady(true)
        })
        .catch((err) => {
          if (err.message.endsWith('403')) {
            alertDanger({
              title: t('EditOffice.Errors.ForbiddenTitle'),
              message: t('EditOffice.Errors.Forbidden'),
              error: err
            })
          } else if (err.message.endsWith('404')) {
            alertDanger({
              title: t('EditOffice.Errors.NotFoundTitle'),
              message: t('EditOffice.Errors.NotFound'),
              error: err
            })
          } else {
            alertDanger({
              title: t('EditOffice.Errors.GetOfficeTitle'),
              message: t('EditOffice.Errors.GetOffice'),
              error: err
            })
          }

          history.replace(`/dashboard/${accountId}/offices`)
        }),
    [setCurrentOffice, setOffice, setReady, alertDanger, t, history]
  )

  useEffect(() => {
    if (!account || !rights || inOfficeRefresh) return

    if (officeId === 'new') {
      if (!rights?.canCreateOffice) {
        alertDanger({
          title: t('EditOffice.Errors.CreateForbiddenTitle'),
          message: t('EditOffice.Errors.CreateForbidden')
        })
        history.replace(`/dashboard/${account!.id}/offices`)
        return
      }

      setCurrentOffice(undefined)
      setOffice({
        name: '',
        phoneNumbers: [],
        users: []
      } as any)

      setReady(true)
      return
    }

    loadOffice(account.id, officeId).then(() => {})
  }, [account, officeId, loadOffice])

  const handleSendInvitation = useCallback(
    (invitation: CreateInvitation) => {
      setInvite(false)
      setSaving(true)
      createInvitation(account!.id, invitation)
        .then(() => refreshOffices())
        .then(() => {
          alertSuccess({
            title: t('EditOffice.InviteCreateTitle'),
            message: t('EditOffice.InviteCreate', {
              name: `${invitation.givenName} ${invitation.familyName}`,
              email: invitation.emailAddress
            })
          })
        })
        .catch((err) => {
          if (err.message.includes('409')) {
            alertDanger({
              title: t('Settings.Errors.DuplicateInviteTitle'),
              message: t('Settings.Errors.DuplicateInvite', {
                name: `${invitation.givenName} ${invitation.familyName}`,
                email: invitation.emailAddress
              }),
              error: err
            })
            return
          }
          alertDanger({
            title: t('EditOffice.Errors.InviteCreateTitle'),
            message: t('EditOffice.Errors.InviteCreate'),
            error: err
          })
        })
        .finally(() => setTimeout(() => setSaving(false)))
    },
    [account, setInvite, setSaving, alertSuccess, alertDanger, refreshOffices]
  )

  if (!ready || !account) return <Loading size="sm" variant="parent" />

  return (
    <>
      <div className={`${office.id ? 'office-edit-has-nav' : 'office-edit-no-nav'} ${aside ? 'office-edit-nav-open' : 'office-edit-nav-closed'}`}>
        {office?.id && (
          <>
            <OfficeNav visible={aside} url={url} />

            <OfficeSubheader
              account={account}
              office={office}
              canEditOffice={rights?.canEditOffice === true}
              onToggleAside={() => setAside((o) => !o)}
              asideOpen={aside}
              onInvite={rights?.canInviteToOffice ? () => setInvite(true) : undefined}
            />
          </>
        )}

        <div className="office-edit">
          <Route path={path} exact>
            <OfficeProfile
              office={office}
              canEditOffice={!office.id ? rights?.canCreateOffice === true : rights?.canEditOffice === true}
              canDeleteOffice={rights?.canDeleteOffice === true}
              onChange={(o) => setOffice(o)}
              onSubmit={() => {
                setSaving(true)
                upsertOffice(account.id, office)
                  .then((o) => {
                    refreshOffices()
                    if (!office.id) {
                      alertSuccess({
                        title: t('EditOffice.CreatedTitle'),
                        message: t('EditOffice.Created', { name: o.name })
                      })
                      history.replace(`/dashboard/${account.id}/offices/${o.id}`)
                    } else {
                      alertSuccess({
                        title: t('EditOffice.SavedTitle'),
                        message: t('EditOffice.Saved', { name: office.name })
                      })
                      setOffice(o)
                    }
                  })
                  .catch((err) => {
                    if (!office.id) {
                      alertDanger({
                        title: t('EditOffice.Errors.SaveTitle'),
                        message: t('EditOffice.Errors.Save'),
                        error: err
                      })
                    } else {
                      alertDanger({
                        title: t('EditOffice.Errors.EditTitle'),
                        message: t('EditOffice.Errors.Edit'),
                        error: err
                      })
                    }
                  })
                  .finally(() => setSaving(false))
              }}
              onDelete={(o) => {
                setConfirmDelete(o)
              }}
            />
          </Route>

          <Route path={`${path}/staff`}>
            <OfficeStaff
              office={office}
              canEditOffice={rights?.canEditOffice === true}
              onDeleteUser={(user) => {
                if (!user.id) return

                setSaving(true)
                deleteOfficeUser(account.id, office.id, user.id)
                  .then(() => refreshAccounts().then(() => refreshUsers()))
                  .then(() => {
                    alertSuccess({
                      title: t('EditOffice.DeletedTitle'),
                      message: t('EditOffice.Deleted', { name: `${user.givenName} ${user.familyName}` })
                    })
                  })
                  .catch((err) => {
                    alertDanger({
                      title: t('EditOffice.Errors.SaveTitle'),
                      message: t('EditOffice.Errors.Save'),
                      error: err
                    })
                  })
                  .finally(() => setSaving(false))
              }}
              onChangeRole={(userId, role) => {
                if (!account || !office) return

                const user = accountWithUsers?.offices?.find((x) => x.id === office.id)?.users?.find((x) => x.id === userId)
                if (!user) return

                setSaving(true)
                updateOfficeUserRole(account.id, office.id, user.id, role)
                  .then(() => refreshAccounts().then(() => refreshUsers()))
                  .then(() => {
                    alertSuccess({
                      title: t('EditOffice.UserRoleChangeTitle'),
                      message: t('EditOffice.UserRoleChange', {
                        name: `${user.givenName} ${user.familyName}`,
                        role: t(`General.OfficeRoles.${role}`)
                      })
                    })
                  })
                  .catch((err) => {
                    alertDanger({
                      title: t('EditOffice.Errors.UserRoleChangeTitle'),
                      message: t('EditOffice.Errors.UserRoleChange'),
                      error: err
                    })
                  })
                  .finally(() => setSaving(false))
              }}
            />
          </Route>
        </div>
      </div>

      {invite && account && <InviteModal account={account} offices={[office]} office={office} onSend={handleSendInvitation} onClose={() => setInvite(false)} />}

      {confirmDelete && (
        <ConfirmModal
          title={t('EditOffice.DeleteOfficeTitle')}
          message={t('EditOffice.DeleteOffice', { name: confirmDelete.name })}
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={() => {
            setSaving(true)
            setConfirmDelete(undefined)
            deleteOffice(account?.id, office.id)
              .then(() => {
                refreshOffices()
                alertSuccess({
                  title: t('EditOffice.OfficeDeletedTitle'),
                  message: t('EditOffice.OfficeDeleted', { name: office.name })
                })
                history.replace(`/dashboard/${account.id}/offices`)
              })
              .catch((err) => {
                alertDanger({
                  title: t('EditOffice.Errors.OfficeDeletedTitle'),
                  message: t('EditOffice.Errors.OfficeDeleted'),
                  error: err
                })
              })
              .finally(() => setSaving(false))
          }}
          onClose={() => setConfirmDelete(undefined)}
        />
      )}
    </>
  )
}
