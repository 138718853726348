import React, { SVGProps } from 'react'
import './style.scss'

const Hands: React.FC<SVGProps<SVGSVGElement>> = ({ className, ...rest }) => (
  <svg viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" className={`ui-svg ui-svg-hands ${className || ''}`} {...rest}>
    <path d="M14.8587 18.8085L12.8969 17.1596" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.0559 19.8079L11.4566 18.4979" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.32324 16.3472C4.89241 17.9843 9.47586 21.5283 9.47586 21.5283C10.5813 22.4643 11.5805 21.0357 10.6717 20.266L9.97486 19.5895" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M9.97492 19.5893L11.8704 21.2363C11.9569 21.3277 12.0612 21.4003 12.1769 21.4498C12.2925 21.4993 12.4171 21.5245 12.5429 21.524C12.6687 21.5234 12.793 21.4971 12.9082 21.4467C13.0234 21.3962 13.1271 21.3226 13.2129 21.2305C13.2986 21.1384 13.3646 21.0298 13.4067 20.9112C13.4488 20.7926 13.4663 20.6666 13.4579 20.541C13.4495 20.4155 13.4155 20.2929 13.358 20.181C13.3005 20.069 13.2207 19.9701 13.1235 19.8902L13.6016 20.295C13.8 20.4496 14.0504 20.5215 14.3005 20.4956C14.5506 20.4696 14.781 20.3479 14.9434 20.1558C15.1059 19.9638 15.1878 19.7163 15.172 19.4652C15.1562 19.2141 15.044 18.9788 14.8588 18.8086L14.9326 18.8711C15.1309 19.0261 15.3815 19.0982 15.6318 19.0724C15.882 19.0466 16.1126 18.9248 16.2752 18.7326C16.4377 18.5404 16.5196 18.2927 16.5036 18.0414C16.4877 17.7902 16.3752 17.5548 16.1896 17.3848L11.4976 13.5566C11.1927 13.6726 10.877 13.7579 10.5551 13.8113C10.4496 13.8239 10.3476 13.8574 10.255 13.9098C10.1624 13.9621 10.0812 14.0323 10.0159 14.1163C9.58964 14.6657 8.64451 15.6968 7.90374 15.1564C6.9161 14.4357 7.90364 11.3677 10.6802 10.9292C10.6802 10.9292 11.9298 10.5727 14.9899 12.1511L16.1245 11.8072"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2.41712 10.3888L0.467102 17.2795L3.79277 18.2218L5.74279 11.3311L2.41712 10.3888Z" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19.4359 10.5728L16.1103 11.5152L18.0605 18.4058L21.3861 17.4634L19.4359 10.5728Z" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.61157 11.7951C5.61157 11.7951 5.77271 11.5023 9.3001 11.4257" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.4923 17.8738C16.722 17.9367 16.9635 17.9425 17.1958 17.8905C17.4282 17.8386 17.6443 17.7305 17.8253 17.5758" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11.0224 9.05674C13.2566 9.05674 15.0677 7.24446 15.0677 5.00889C15.0677 2.77333 13.2566 0.961048 11.0224 0.961048C8.78828 0.961048 6.97714 2.77333 6.97714 5.00889C6.97714 7.24446 8.78828 9.05674 11.0224 9.05674Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.56354 6.54583C9.96535 6.89397 10.4683 7.10379 10.9982 7.14441C11.3125 7.16407 11.6218 7.05876 11.8588 6.85138C12.0958 6.64399 12.2414 6.35133 12.2639 6.03708C12.2639 5.42557 11.6588 5.12298 10.9982 4.92984C10.3135 4.71506 9.73257 4.43401 9.73257 3.8225C9.75502 3.50824 9.90061 3.21557 10.1376 3.00819C10.3747 2.80081 10.684 2.69549 10.9982 2.71517C11.2133 2.71045 11.4261 2.75894 11.6179 2.85634C11.8097 2.95375 11.9745 3.09705 12.0976 3.27351"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10.9982 2.11018V2.71528" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.9982 7.14426V7.74936" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
export default Hands
