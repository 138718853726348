import React, { useMemo } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons'

import { useCurrentUserOffice, useRights, useUserOffices } from 'services/src/state'
import { Office, ResourceUserRole } from 'services/src/dto/account'

import './style.scss'
import Loading from 'components/src/svg/Loading'

const CELL_HEIGHT = 165
const CELL_WIDTH = 250

export const OfficeCards: React.FC<{
  className?: string
}> = ({ className }) => {
  const { t } = useTranslation()
  const { url } = useRouteMatch()
  const [offices] = useUserOffices()
  const [office] = useCurrentUserOffice()
  const rights = useRights()

  const readyOffices = useMemo<Office[]>(() => {
    if (!offices) return []

    const ready: Office[] = []
    const o = offices.find((x) => x.id === office?.id)
    if (o) ready.push(o)
    offices
      .filter((x) => x.id !== office?.id)
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach((o) => ready.push(o))

    return ready
  }, [office, offices])

  return (
    <div className={`dashboard-home-cell${className ? ` ${className}` : ''}`}>
      {!offices ? (
        <div style={{ height: CELL_HEIGHT }} className="dashboard-home-cell-loading">
          <Loading style={{ width: 30 }} />
        </div>
      ) : (
        <>
          <label>{t('Dashboard.Offices.Label')}</label>
          <div className="dashboard-home-cell-scroll-row">
            {readyOffices.map((o) => {
              const m = o.users?.find((x) => x.role === ResourceUserRole.Owner)
              return (
                <div
                  key={o.id}
                  style={{
                    height: CELL_HEIGHT,
                    width: CELL_WIDTH,
                    minWidth: CELL_WIDTH
                  }}
                  className={`dashboard-home-card dashboard-home-office-card${office?.id === o.id ? ' active' : ''}`}
                >
                  <div className="ui-flex ui-flex-nowrap">
                    <div style={{ width: '70%', paddingRight: 10 }}>
                      <h3>{o.name}</h3>
                    </div>
                    <div style={{ width: '30%', marginTop: 3 }} className="ui-text-muted ui-text-xs">
                      <div className="ui-flex ui-flex-column">
                        <div>{t('Offices.ProjectCount', { count: o.counts?.projects || 0 }).toLowerCase()}</div>
                        <div>{t('Offices.UserCount', { count: o.users?.length || 0 }).toLowerCase()}</div>
                      </div>
                    </div>
                  </div>

                  {m && m.givenName && m.familyName && (
                    <div className="owner">
                      <div>
                        {m.pictureUrl && <div style={{ backgroundImage: `url('${m.pictureUrl}')` }} />}
                        {!m.pictureUrl && <div>{`${m.givenName[0].toUpperCase()}${m.familyName[0].toUpperCase()}`}</div>}
                      </div>
                      <div className="ui-text-muted">
                        <h4>
                          {m.givenName} {m.familyName}
                        </h4>
                        <div>{t('Dashboard.Offices.PortfolioManager')}</div>
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
            {rights?.canCreateOffice && (
              <div
                className="dashboard-home-card dashboard-home-office-card dashboard-home-office-add-card"
                style={{
                  height: CELL_HEIGHT,
                  width: CELL_WIDTH,
                  minWidth: CELL_WIDTH
                }}
              >
                <Link to={`${url}/offices/new`} className="ui-btn ui-btn-primary ui-btn-solid">
                  <FontAwesomeIcon icon={faPlusCircle} />
                  <span style={{ marginLeft: 5 }}>{t('Dashboard.Offices.AddOffice')}</span>
                </Link>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
