import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentUserAccount, useEditProject, useUsers } from 'services/src/state'
import { useSaving } from 'components/src/saving'
import { useAlert } from 'components/src/alerts'
import { Patch } from 'services/src/dto/common'
import { OtherFee, ProjectStatus } from 'services/src/dto/project'
import { User } from 'services/src/dto/account'
import { Link } from 'react-router-dom'
import { makeDashboardPath } from 'services/src/dom'
import DrawingsIcon from 'components/src/svg/DrawingsIcon'
import { FeeGridTitle } from './FeeGridTitle'
import { FeeGridBillingLaborRate } from './FeeGridBillingLaborRate'
import { FeeGridBillableHours } from './FeeGridBillableHours'
import { FeeGridTotals } from './FeeGridTotals'
import { FeeGridExtra } from './FeeGridExtra'
import { FeeGridViewModel, toViewModel } from './common'
import { Footer } from '../Footer'
import { FeeGridPhaseTotals } from './FeeGridPhaseTotals'
import { ConfirmOffersModal } from './ConfirmOffersModal'
import { FeeGridLaborTotals } from './FeeGridLaborTotals'
import { FeeGridProfit } from './FeeGridProfit'
import { FeeGridGeneratedFee } from './FeeGridGeneratedFee'

export const FeeGrid: React.FC<{
  phaseId: string
  variant?: 'all' | 'assign'
}> = ({ phaseId, variant }) => {
  const { t } = useTranslation()
  const [account] = useCurrentUserAccount()
  const [saving, setSaving] = useSaving()
  const { alertSuccess, alertDanger } = useAlert()

  const { getUser } = useUsers()
  const { project, patch } = useEditProject()

  const [ready, setReady] = useState(false)
  const [vm, setVm] = useState<FeeGridViewModel>({} as any)

  useEffect(() => {
    const firstLoad = !vm.phase || vm.phase.id !== phaseId
    toViewModel(project, JSON.parse(JSON.stringify(project.phases.find((ph) => ph.id === phaseId)!)) as any, vm, t, getUser)
      .then((vm) => {
        if (firstLoad) {
          const exp = JSON.parse(localStorage.getItem(`feeExp${phaseId}`) || '{}')
          vm.estimates.forEach((est) => {
            est.isOpen = exp[est.discipline]
          })
        }
        vm.variant = variant || 'all'
        setVm(vm)
      })
      .finally(() => setReady(true))
  }, [project, phaseId, variant, setVm, t])

  const [selected, setSelected] = useState<string>()

  const { offerCount, offerUserCount } = useMemo(() => {
    let offerCount = 0
    const users: User[] = []
    vm.estimates?.forEach((est) => {
      const labor = est.labor.filter((l) => !!l.user && l.status === ProjectStatus.New)
      offerCount += labor.length

      labor.forEach((l) => {
        if (!users.find((u) => u.id === l.user!.id)) users.push(l.user!)
      })
    })

    return { offerCount, offerUserCount: users.length }
  }, [vm])

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      let hasError = false

      const vmx = { ...vm }

      const p: Patch[] = []
      if (vmx.providerFee !== vmx.phase.providerFee) {
        p.push({
          propertyName: `Phases.${vm.phase.id}.ProviderFee`,
          propertyValue: vmx.providerFee
        })
      }

      vmx.estimates.forEach((est) => {
        p.push({
          propertyName: `Phases.${vm.phase.id}.Estimates.${est.estimate.id}.Difficulty`,
          propertyValue: est.difficulty
        })

        p.push({
          propertyName: `Phases.${vm.phase.id}.Estimates.${est.estimate.id}.ManualLevelOfEffortHours`,
          propertyValue: est.hours
        })

        p.push({
          propertyName: `Phases.${vm.phase.id}.Estimates.${est.estimate.id}.ManualBaseRate`,
          propertyValue: est.isManualProviderRate ? est.providerRate : est.estimate.baseRate
        })

        est.otherFees.forEach((of) => {
          of.errors = {}
          if ((of.amount || 0) <= 0) of.errors.amount = t('General.Errors.Required')
          if (!of.name) of.errors.name = t('General.Errors.Required')

          if (Object.keys(of.errors).length) hasError = true
        })

        p.push({
          propertyName: `Phases.${vm.phase.id}.Estimates.${est.estimate.id}.OtherFees`,
          propertyValue: est.otherFees
            .filter((of) => of.name && (of.amount || 0) > 0)
            .map<OtherFee>((of) => ({
              name: of.name,
              description: of.description || undefined,
              amount: of.amount
            }))
        })

        p.push({
          propertyName: `Phases.${vm.phase.id}.Estimates.${est.estimate.id}.Labor`,
          propertyValue: est.labor.map((l) => ({
            id: l.id,
            userId: l.user?.id,
            status: l.status,
            rate: l.rate,
            split: l.split,
            hours: l.hours,
            skills: l.skills
          }))
        })

        // Assign to all phases?
        est.labor
          .filter((l) => l.assignToAll === true)
          .forEach((l) => {
            // Loop through other phases
            vmx.project.phases
              .filter((ph) => ph.id !== vm.phase.id)
              .forEach((ph) => {
                const est2 = ph.estimates?.find((est2) => est2.discipline === est.discipline)
                // Only add if labor collection is empty
                if (!est2 || est2.labor?.length) return

                p.push({
                  propertyName: `Phases.${ph.id}.Estimates.${est2.id}.Labor`,
                  propertyValue: [
                    {
                      userId: l.user?.id,
                      status: l.status,
                      rate: l.rate,
                      split: 1,
                      hours: est2.manualLevelOfEffortHours,
                      skills: l.skills
                    }
                  ]
                })
              })
          })
      })

      if (hasError || p.length <= 0) {
        setVm(vmx)
        return
      }

      setSaving(true)
      patch(p)
        .then(() => {})
        .catch(() => {})
        .finally(() => setSaving(false))
    },
    [vm, patch, setSaving]
  )

  const [confirmSendOffers, setConfirmSendOffers] = useState(false)

  const sendOffers = useCallback(() => {
    const p: Patch[] = []
    vm.estimates.forEach((est) => {
      est.labor
        .filter((l) => l.id && l.status === ProjectStatus.New)
        .forEach((l) => {
          p.push({
            propertyName: `Phases.${vm.phase.id}.Estimates.${est.estimate.id}.Labor.${l.id}.Status`,
            propertyValue: {
              status: ProjectStatus.FeeReady,
              metadata: {
                cost: l.cost,
                hours: l.hours || 0,
                rate: l.rate
              }
            }
          })
        })
    })

    if (p.length <= 0) return

    setSaving(true)
    patch(p)
      .then(() =>
        alertSuccess({
          title: t('EditProject.ConfirmOffers.OffersSentTitle'),
          message: t('EditProject.ConfirmOffers.OffersSent')
        })
      )
      .catch((error) =>
        alertDanger({
          title: t('EditProject.ConfirmOffers.Errors.OffersSentTitle'),
          message: t('EditProject.ConfirmOffers.Errors.OffersSent'),
          error
        })
      )
      .finally(() => setSaving(false))
  }, [vm, patch, setSaving])

  const [inScroll, setInScroll] = useState(false)
  const scroll = useCallback(() => {
    setInScroll((window.document.scrollingElement?.scrollTop || 0) > 0)
  }, [setInScroll])

  useEffect(() => {
    window.addEventListener('scroll', scroll)
    return () => {
      window.removeEventListener('scroll', scroll)
    }
  }, [])

  if (!ready || !vm.phase) return null

  return (
    <>
      <FeeGridPhaseTotals
        vm={vm}
        inScroll={inScroll}
        onExpandAll={() => {
          setVm((current) => {
            const vm = { ...current, estimates: [...current.estimates] }
            const exp: any = {}
            vm.estimates.forEach((est) => {
              est.isOpen = true
              exp[est.discipline] = true
            })
            localStorage.setItem(`feeExp${phaseId}`, JSON.stringify(exp))
            return vm
          })
        }}
        onCollapseAll={() => {
          setVm((current) => {
            const vm = { ...current, estimates: [...current.estimates] }
            vm.estimates.forEach((est) => {
              est.isOpen = false
            })
            localStorage.removeItem(`feeExp${phaseId}`)
            return vm
          })
        }}
        onChangeProviderFee={(pf) => {
          setVm((current) => {
            return { ...current, isDirty: true, providerFee: pf }
          })
        }}
      />

      <div style={{ paddingTop: 145 }}>
        <div className="edit-project-fee-grid">
          {vm.estimates?.map((est) => {
            const canEdit = [ProjectStatus.New, ProjectStatus.Submitted, ProjectStatus.Accepted, ProjectStatus.FeeSelected].includes(vm.phase.status!)
            return (
              <div
                key={est.estimate.id}
                className={`edit-project-fee-grid-item ${selected === est.estimate.id ? 'selected' : 'not-selected'} ${est.isOpen ? 'open' : 'closed'}`}
                tabIndex={-1}
                onFocus={() => setSelected(est.estimate.id)}
              >
                <div className="edit-project-fee-grid-item-main">
                  <div className="ui-flex">
                    <FeeGridTitle
                      vm={vm}
                      estimate={est}
                      onToggle={() => {
                        setVm((current) => {
                          const vm = { ...current, estimates: current.estimates.map((est) => ({ ...est })) }
                          const e = vm.estimates.find((x) => x.estimate.id === est.estimate.id)!
                          e.isOpen = !e.isOpen

                          const exp = JSON.parse(localStorage.getItem(`feeExp${phaseId}`) || '{}')
                          exp[e.discipline] = e.isOpen
                          localStorage.setItem(`feeExp${phaseId}`, JSON.stringify(exp))

                          return vm
                        })
                      }}
                    />

                    <div className="ui-text-sm" style={{ marginLeft: 'auto', marginRight: 20, minWidth: 60, maxWidth: 60 }}>
                      {est.estimate.pages > 0 && (
                        <>
                          <div className="ui-text-xxs ui-text-center ui-text-uppercase ui-text-bold ui-text-muted">{t('EditProject.Fees.Pages')}</div>
                          <div className="ui-text-center" style={{ height: 25, width: 60, lineHeight: '23px' }}>
                            <Link to={makeDashboardPath(account?.id, `projects/${vm.project.id}/phases/${vm.phase.id}/files/drawings`)} style={{ display: 'inline-flex' }} className="ui-secondary">
                              <DrawingsIcon style={{ width: 15, marginRight: 5 }} />
                              {est.estimate.pages}
                            </Link>
                          </div>
                        </>
                      )}
                    </div>

                    {vm.variant !== 'assign' && (
                      <div style={{ marginLeft: 'auto' }}>
                        <FeeGridGeneratedFee estimate={est} />
                      </div>
                    )}
                  </div>

                  <div className="ui-flex">
                    <div style={{ marginLeft: 'auto' }}>
                      <FeeGridBillingLaborRate
                        vm={vm}
                        estimate={est}
                        canEdit={canEdit}
                        onChange={(est) => {
                          setVm((current) => {
                            const vm = {
                              ...current,
                              isDirty: true,
                              estimates: current.estimates.map((est) => ({ ...est }))
                            }
                            const idx = vm.estimates.findIndex((x) => x.estimate.id === est.estimate.id)
                            if (idx >= 0) vm.estimates[idx] = est
                            return vm
                          })
                        }}
                      />
                    </div>

                    <FeeGridBillableHours
                      estimate={est}
                      canEdit={canEdit}
                      onChange={(est) => {
                        setVm((current) => {
                          const vm = {
                            ...current,
                            isDirty: true,
                            estimates: current.estimates.map((est) => ({ ...est }))
                          }
                          const idx = vm.estimates.findIndex((x) => x.estimate.id === est.estimate.id)
                          if (idx >= 0) vm.estimates[idx] = est
                          return vm
                        })
                      }}
                    />

                    <FeeGridTotals vm={vm} estimate={est} />

                    <FeeGridLaborTotals vm={vm} estimate={est} />

                    <FeeGridProfit vm={vm} estimate={est} />
                  </div>
                </div>

                <div className="edit-project-fee-grid-item-ex">
                  <FeeGridExtra
                    vm={vm}
                    estimate={est}
                    onChange={(est) => {
                      setVm((current) => {
                        const vm = {
                          ...current,
                          isDirty: true,
                          estimates: current.estimates.map((est) => ({ ...est }))
                        }
                        const idx = vm.estimates.findIndex((x) => x.estimate.id === est.estimate.id)
                        if (idx >= 0) vm.estimates[idx] = est
                        return vm
                      })
                    }}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <Footer>
        <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center' }}>
          <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center', marginLeft: 'auto' }}>
            {[ProjectStatus.Approved, ProjectStatus.InProgress].includes(vm.phase.status!) && offerCount > 0 && (
              <button type="button" disabled={vm.isDirty || saving} className="ui-btn ui-btn-info ui-btn-solid" style={{ marginRight: 10, width: 'auto' }} onClick={() => setConfirmSendOffers(true)}>
                {t('EditProject.Fees.SendOffers')}
                &nbsp;
                <sup>
                  ({offerCount}/{offerUserCount})
                </sup>
              </button>
            )}

            <button type="button" disabled={!vm.isDirty || saving} className="ui-btn ui-btn-secondary ui-btn-solid" onClick={submit}>
              {t('General.Save')}
            </button>
          </div>
        </div>
      </Footer>

      {confirmSendOffers && (
        <ConfirmOffersModal
          vm={vm}
          onClose={() => setConfirmSendOffers(false)}
          onSend={() => {
            setConfirmSendOffers(false)
            sendOffers()
          }}
        />
      )}
    </>
  )
}
