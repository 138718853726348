import React, { useEffect, useState } from 'react'
import { ProjectContentEx } from 'services/src/state'
import { useMsalAccessToken } from 'services/src/aad'
import { getContent } from 'services/src/api'
import Highlight from 'react-highlight'
import { getLanguage } from 'highlight.js'

export const TextView: React.FC<{ content: ProjectContentEx }> = ({ content }) => {
  const accessToken = useMsalAccessToken()

  const [text, setText] = useState<string>()
  const [language, setLanguage] = useState<string>('plaintext')

  useEffect(() => {
    getContent(`${content.url.includes('sv=') ? content.url : `${content.url}?access_token=${accessToken}`}`).then(({ data }) => {
      setText(data.toString())
      if (content.contentType.startsWith('text/')) {
        const l = content.contentType.substring(5)
        if (getLanguage(l)) setLanguage(l)
      }
      if (content.contentType.endsWith('xml')) setLanguage('json')
      if (content.contentType.endsWith('json')) setLanguage('json')
    })
  }, [content, setText])

  if (!text) return null

  return <Highlight className={language}>{text}</Highlight>
}
