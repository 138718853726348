import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSaving } from 'components/src/saving'

export interface SummaryFooterProps {
  onBack: () => void
  onCancel: () => void
  onSubmit: () => void
  onSaveForLater: () => void
}

export const SummaryFooter: React.FC<SummaryFooterProps> = ({ onBack, onCancel, onSubmit, onSaveForLater }) => {
  const { t } = useTranslation()
  const [saving] = useSaving()
  return (
    <footer className="new-project-footer">
      <div className="ui-flex ui-flex-nowrap">
        <div>
          <button className="ui-btn ui-btn-primary ui-btn-solid" type="button" disabled={saving} onClick={onCancel}>
            {t('General.Exit')}
          </button>
        </div>
        <div className="ui-flex ui-flex-nowrap" style={{ marginLeft: 'auto', justifyContent: 'center' }}>
          <button className="ui-btn ui-btn-secondary ui-btn-solid" type="button" disabled={saving} onClick={onBack}>
            {t('General.Back')}
          </button>
          <button className="ui-btn ui-btn-default ui-btn-solid" type="button" disabled={saving} onClick={onSaveForLater}>
            {t('General.SaveForLater')}
          </button>
          <button className="ui-btn ui-btn-secondary ui-btn-solid" type="button" disabled={saving} onClick={onSubmit}>
            {t('General.Submit')}
          </button>
        </div>
      </div>
    </footer>
  )
}

export interface FooterProps {
  canBack: boolean
  onBack: () => void
  onCancel: () => void
  canNext: boolean
  onNext: () => void
}

export const Footer: React.FC<FooterProps> = ({ canBack, onBack, onCancel, canNext, onNext }) => {
  const { t } = useTranslation()
  const [saving] = useSaving()

  return (
    <footer className="new-project-footer">
      <div className="ui-row">
        <div className="ui-col-6">
          <button className="ui-btn ui-btn-primary ui-btn-solid" type="button" disabled={saving} onClick={onCancel}>
            {t('General.Exit')}
          </button>
        </div>
        <div className="ui-col-6 ui-flex ui-flex-nowrap" style={{ justifyContent: 'flex-end' }}>
          {canBack && (
            <button className="ui-btn ui-btn-secondary ui-btn-solid" type="button" disabled={!canBack || saving} onClick={onBack}>
              {t('General.Back')}
            </button>
          )}
          <button className="ui-btn ui-btn-secondary ui-btn-solid" type="button" disabled={!canNext || saving} onClick={onNext}>
            {t('General.Next')}
          </button>
        </div>
      </div>
    </footer>
  )
}
