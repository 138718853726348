import { faArrowUpFromBracket, faCompassDrafting, faExclamationTriangle, faFileChartPie, faMagnifyingGlass, faQuestionCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios, { Canceler } from 'axios'
import { useAlert } from 'components/src/alerts'
import { DocumentView } from 'components/src/documentView'
import { Dropzone } from 'components/src/dropzone'
import { FileList, ProjectContentGroup } from 'components/src/fileList'
import { ConfirmModal } from 'components/src/modal/ConfirmModal'
import { useSaving } from 'components/src/saving'
import { TabBar } from 'components/src/tabs'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { createProEst, deleteFile, uploadFile, UploadFileEvent } from 'services/src/api'
import { ResourceUserRole } from 'services/src/dto/account'
import { Patch } from 'services/src/dto/common'
import { Content, ProjectContentCategory } from 'services/src/dto/content'
import { DisciplineKey, ProjectStatus } from 'services/src/dto/project'
import { EstimateLaborEx, PhaseEx, ProjectContentEx, useCurrentUserAccount, useEditProject, useProEst, useProjectFilesWs, useRights, UserType } from 'services/src/state'
import { Feature, useFeatures } from 'services/src/state/features'
import { Loading } from '../../../loading'
import { ProEstEstimateGrid } from '../../../proEst'
import { StatusBadge } from '../../../statusBadge'
import { Uploading } from '../common/Uploading'
import { EstimateReview } from './EstimateReview'
import { Footer } from './Footer'
import { PhaseEstimateSummary } from './PhaseEstimateSummary'

export const PhaseEstimate: React.FC<{
  phaseId: string
}> = ({ phaseId }) => {
  const { t } = useTranslation()
  const { path, url } = useRouteMatch()
  const { pathname } = useLocation()
  const history = useHistory()
  const { project, patch } = useEditProject()
  const { alertSuccess, alertDanger } = useAlert()
  const [account] = useCurrentUserAccount()
  const [saving, setSaving] = useSaving()
  const rights = useRights()
  const { hasFeature } = useFeatures()

  const [phase, setPhase] = useState<PhaseEx>()
  const [phaseIds, setPhaseIds] = useState<string[]>([])
  const [categories] = useState<ProjectContentCategory[]>([ProjectContentCategory.Boe, ProjectContentCategory.Estimate])

  useEffect(() => {
    if (pathname.endsWith(`${phaseId}/estimate`)) history.replace(`${pathname}/summary`)
  }, [phaseId, pathname, history])

  useEffect(() => {
    const phase = project.phases.find((x) => x.id === phaseId)!
    if (rights?.userType === UserType.client) {
      if (phase.status === ProjectStatus.InProgress) {
        if (phase.statusHistory![0].metadata?.clientReview !== true) {
          history.goBack()
          return
        }
      } else if (phase.status !== ProjectStatus.Complete) {
        history.goBack()
        return
      }
    }
    setPhase(phase)
    setPhaseIds([phase.id!])
  }, [project, phaseId, rights, setPhase, setPhaseIds, history])

  const inProviderReview = useMemo(() => {
    if (!phase) return false
    if (phase.status !== ProjectStatus.InProgress || phase.statusHistory![0].metadata?.clientReview === true) return false

    const inProviderReview = true
    phase?.estimates
      ?.filter((est) => ![DisciplineKey.ProjectManagement.toString(), DisciplineKey.ProjectPrincipal.toString()].includes(est.discipline!))
      .forEach((est) => {
        // TODO Deal with this
        // if (est.labor?.length && est.status !== ProjectStatus.Complete) inProviderReview = false;
      })

    return inProviderReview
  }, [phase])

  const inClientReview = useMemo(() => phase?.status === ProjectStatus.InProgress && phase.statusHistory![0].metadata?.clientReview === true, [phase])

  const { files: projectFiles } = useProjectFilesWs(project, phaseIds, categories)

  const [files, setFiles] = useState<File[]>()
  const [currentFileIndex, setCurrentFileIndex] = useState<number>(-1)
  const [currentProgress, setCurrentProgress] = useState<number>(0)
  const [uploadCategory, setUploadCategory] = useState(ProjectContentCategory.Boe)

  const canceler = useRef<Canceler>()

  const onProgress = useCallback(
    (e: UploadFileEvent) => {
      if (e.error) {
        canceler.current = undefined
        setCurrentFileIndex(-1)
        setFiles(undefined)
        setCurrentProgress(0)
        if (e.error.code !== 'ERR_CANCELED') {
          alertDanger({
            title: t('General.Errors.FileUploadTitle'),
            message: t('General.Errors.FileUpload'),
            error: e.error
          })
        }
        return
      }

      if (!files) return

      if (e.state === 'cancel') {
        canceler.current = undefined
        setFiles(undefined)
        setCurrentProgress(0)
        return
      }

      if (e.state === 'complete') {
        let nextIndex = currentFileIndex + 1
        if (nextIndex >= files.length) nextIndex = -1
        setCurrentFileIndex(nextIndex)
        if (nextIndex < 0) {
          canceler.current = undefined
          setFiles(undefined)
          setCurrentProgress(0)
        }
        return
      }

      setCurrentProgress(e.progress)
    },
    [canceler, currentFileIndex, setCurrentFileIndex, files, setFiles, setCurrentProgress, alertDanger]
  )

  useEffect(() => {
    if (currentFileIndex < 0 || !files || !project?.id || !phase?.id) return

    // Are we done?
    if (currentFileIndex >= files.length) {
      canceler.current = undefined
      setCurrentFileIndex(-1)
      setFiles(undefined)
      setCurrentProgress(0)
      return
    }

    setCurrentProgress(0)
    uploadFile(
      project.account!.id,
      project.id,
      uploadCategory,
      phase.id,
      files[currentFileIndex],
      onProgress,
      new axios.CancelToken((c: Canceler) => {
        canceler.current = c
      })
    ).catch((error) => {
      canceler.current = undefined
      setCurrentFileIndex(-1)
      setFiles(undefined)
      setCurrentProgress(0)
      alertDanger({
        title: t('General.Errors.FileUploadTitle'),
        message: t('General.Errors.FileUpload'),
        error
      })
    })
  }, [currentFileIndex, setCurrentFileIndex, setFiles, setCurrentProgress, alertDanger])

  const handleDrop = useCallback(
    (category: ProjectContentCategory, acceptedFiles: File[]) => {
      if (!phase?.id) return

      setFiles(acceptedFiles)
      setCurrentProgress(0)
      setUploadCategory(category)
      setTimeout(() => setCurrentFileIndex(0))
    },
    [project, phase, setFiles, setCurrentProgress, setCurrentFileIndex, setUploadCategory]
  )

  const [showBoeFileDialog, setShowBoeFileDialog] = useState(false)
  const [confirmBoeDelete, setConfirmBoeDelete] = useState<Content>()

  const [showEstFileDialog, setShowEstFileDialog] = useState(false)
  const [confirmEstDelete, setConfirmEstDelete] = useState<Content>()

  const [confirmSendToProvider, setConfirmSendToProvider] = useState<boolean>(false)

  const sendToProvider = useCallback(() => {
    setConfirmSendToProvider(false)
    if (!phase || saving) return

    const p: Patch[] = [
      {
        propertyName: `Phases.${phase.id}.Status`,
        propertyValue: {
          status: ProjectStatus.InProgress,
          metadata: { providerReview: true }
        }
      }
    ]

    setSaving(true)
    patch(p, true)
      .then(() => {
        alertSuccess({
          title: t('Phase.BOE.SentToProviderTitle'),
          message: t('Phase.BOE.SentToProvider')
        })
      })
      .catch((error) => {
        alertDanger({
          title: t('Phase.BOE.Errors.SendToProviderTitle'),
          message: t('Phase.BOE.Errors.SendToProvider'),
          error
        })
      })
      .finally(() => setSaving(false))
  }, [phase, patch, saving, setSaving, alertSuccess, alertDanger, t])

  const [confirmMarkComplete, setConfirmMarkComplete] = useState<boolean>(false)

  const makeComplete = useCallback(() => {
    setConfirmMarkComplete(false)
    if (!phase || saving) return

    const p: Patch[] = []

    if (inClientReview) {
      p.push({
        propertyName: `Phases.${phase.id}.Status`,
        propertyValue: {
          status: ProjectStatus.Complete
        }
      })
    } else {
      p.push({
        propertyName: `Phases.${phase.id}.Status`,
        propertyValue: {
          status: ProjectStatus.InProgress,
          metadata: {
            clientReview: true
          }
        }
      })
    }

    setSaving(true)
    patch(p, true)
      .then(() => {
        if (inClientReview) {
          alertSuccess({
            title: t('Phase.BOE.ClientMarkedAsCompleteTitle'),
            message: t('Phase.BOE.ClientMarkedAsComplete')
          })
        } else {
          alertSuccess({
            title: t('Phase.BOE.SentToClientTitle'),
            message: t('Phase.BOE.SentToClient')
          })
        }
      })
      .catch((error) => {
        if (inClientReview) {
          alertDanger({
            title: t('Phase.BOE.Errors.ClientMarkedAsCompleteTitle'),
            message: t('Phase.BOE.Errors.ClientMarkedAsComplete'),
            error
          })
        } else {
          alertDanger({
            title: t('Phase.BOE.Errors.SendToClientTitle'),
            message: t('Phase.BOE.Errors.SendToClient'),
            error
          })
        }
      })
      .finally(() => setSaving(false))
  }, [phase, patch, saving, setSaving, alertSuccess, alertDanger, t, inClientReview])

  const [readyFiles, setReadyFiles] = useState<ProjectContentEx[]>()
  useEffect(() => {
    if (!phase || !projectFiles) return

    const pf = projectFiles.filter((pf) => pf.phaseId === phase.id && pf.category === ProjectContentCategory.Boe)

    if (pf.length <= 0) {
      setReadyFiles([])
      return
    }

    /* if (rights?.userType === UserType.client) {
      if (pf.length === 1) {
        history.replace(`${url}/${pf[0].id}`);
        return;
      }
    } */

    setReadyFiles(pf)
  }, [rights, project, phase, projectFiles, setReadyFiles])

  const footer = useMemo(() => {
    const pu = project.providerAccountWithUsers.users.find((u) => u.id === rights?.id)
    const cu = project.accountWithUsers.users.find((u) => u.id === rights?.id && !project.providerAccountWithUsers.users.find((pu) => pu.id === u.id))

    if (pu) {
      if (phase?.status === ProjectStatus.InProgress && pu.role === ResourceUserRole.Administrator && !inProviderReview)
        return (
          <Footer>
            <button type="button" disabled={saving} className="ui-btn ui-btn-solid ui-btn-secondary" style={{ marginLeft: 'auto', width: 'auto' }} onClick={() => setConfirmSendToProvider(true)}>
              {t('EditProject.SendToProductionManager')}
            </button>
          </Footer>
        )

      if (phase?.status === ProjectStatus.InProgress && pu.role === ResourceUserRole.Owner && inProviderReview)
        return (
          <Footer>
            <button type="button" disabled={saving} className="ui-btn ui-btn-solid ui-btn-secondary" style={{ marginLeft: 'auto', width: 'auto' }} onClick={() => setConfirmMarkComplete(true)}>
              {t('General.SendToClient')}
            </button>
          </Footer>
        )
    }

    if (cu) {
      if ([ResourceUserRole.Owner, ResourceUserRole.Administrator].includes(cu.role) && inClientReview)
        return (
          <Footer>
            <button type="button" disabled={saving} className="ui-btn ui-btn-solid ui-btn-secondary" style={{ marginLeft: 'auto', width: 'auto' }} onClick={() => setConfirmMarkComplete(true)}>
              {t('Phase.BOE.ClientMarkCompleteBtn')}
            </button>
          </Footer>
        )
    }

    return null
  }, [project, phase, inProviderReview])
  const footerRef = useRef<any>()
  footerRef.current = footer

  const [wrapperHeight, setWrapperHeight] = useState(window.innerHeight - 310)

  const resize = useCallback(() => setWrapperHeight(window.innerHeight - (footerRef.current ? 355 : 310)), [setWrapperHeight])
  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  useEffect(() => {
    setWrapperHeight(window.innerHeight - (footer ? 355 : 310))
  }, [footer])

  const [confirmEstMarkComplete, setConfirmEstMarkComplete] = useState<ProjectContentGroup>()

  const makeEstComplete = useCallback(
    (group: ProjectContentGroup) => {
      setConfirmEstMarkComplete(undefined)

      if (!phase || saving || !group.estimates) return

      const p: Patch[] = []

      group.estimates.forEach((est) => {
        est.labor
          .filter((l) => l.userId === group.groupId)
          .forEach((l) => {
            p.push({
              propertyName: `Phases.${phase.id}.Estimates.${est.id}.Labor.${l.id}.Status`,
              propertyValue: {
                status: ProjectStatus.Complete,
                metadata: {
                  reason: 'Labor marked complete by provider.'
                }
              }
            })
          })
      })

      if (!p.length) return

      setSaving(true)
      patch(p, true)
        .then(() => {
          alertSuccess({
            title: t('EditProject.MarkedAsCompleteTitle'),
            message: t('EditProject.MarkedAsComplete')
          })
        })
        .catch((error) => {
          alertDanger({
            title: t('EditProject.Errors.MarkedAsCompleteTitle'),
            message: t('EditProject.Errors.MarkedAsComplete'),
            error
          })
        })
        .finally(() => setSaving(false))
    },
    [phase, patch, saving, setSaving, alertSuccess, alertDanger, setConfirmMarkComplete, t]
  )

  const [confirmSendBackToEst, setConfirmSendBackToEst] = useState<ProjectContentGroup>()

  const sendBackToEst = useCallback(
    (group: ProjectContentGroup) => {
      setConfirmSendBackToEst(undefined)

      if (!phase || saving || !group.estimates) return

      const p: Patch[] = []

      group.estimates.forEach((est) => {
        est.labor
          .filter((l) => l.userId === group.groupId)
          .forEach((l) => {
            p.push({
              propertyName: `Phases.${phase.id}.Estimates.${est.id}.Labor.${l.id}.Status`,
              propertyValue: {
                status: ProjectStatus.InProgress,
                metadata: {}
              }
            })
          })
      })

      if (!p.length) return

      setSaving(true)
      patch(p, true)
        .then(() => {
          alertSuccess({
            title: t('Expert.Estimates.SendBackToEstTitle'),
            message: t('Expert.Estimates.SendBackToEst')
          })
        })
        .catch((error) => {
          alertDanger({
            title: t('Expert.Estimates.Errors.SendBackToEstTitle'),
            message: t('Expert.Estimates.Errors.SendBackToEst'),
            error
          })
        })
        .finally(() => setSaving(false))
    },
    [phase, patch, saving, setSaving, alertSuccess, alertDanger, setConfirmMarkComplete, t]
  )

  const [expertFileGroups, setExpertFileGroups] = useState<ProjectContentGroup[]>()
  useEffect(() => {
    if (!phase || !projectFiles) return

    const files = projectFiles.filter((f) => f.phaseId === phase.id && f.category === ProjectContentCategory.Estimate)

    const groups: ProjectContentGroup[] = []
    if (!files?.length) {
      setExpertFileGroups(groups)
      return
    }

    files.forEach((f) => {
      const userId = f.metadata?.userId || '$none$'

      let g = groups.find((x) => x.groupId === userId)
      if (!g) {
        g = {
          groupId: userId,
          group: '',
          files: [f],
          phase,
          estimates: phase?.estimates?.filter((est) => !!est.labor?.find((l) => l.userId === userId))
        }
        groups.push(g)

        if (g.estimates?.length) {
          const est = g.estimates[0]
          const firstLabor = est.labor?.find((l) => l.userId === userId)!

          const disciplines = g.estimates.map((est) => t(`General.Disciplines.${est.discipline}`))
          const d =
            // eslint-disable-next-line no-nested-ternary
            disciplines.length === 1
              ? disciplines[0]
              : disciplines.length === 2
              ? `${disciplines[0]} & ${disciplines[1]}`
              : (() => {
                  const last = disciplines[disciplines.length - 1]
                  disciplines.splice(disciplines.length - 1, 1)
                  return `${disciplines.join(', ')}, & ${last}`
                })()

          const canComplete = g.estimates.find(
            (est) => (est.labor.filter((l) => l.userId === userId && l.status === ProjectStatus.InProgress && l.statusHistory[0]?.metadata?.providerReview === true)?.length || 0) > 0
          )

          g.group = (
            <div className="ui-flex ui-text-sm" style={{ margin: '5px 0', padding: '0 10px', flexWrap: 'wrap' }}>
              <div>
                <FontAwesomeIcon icon={faCompassDrafting} />
                <strong style={{ marginLeft: 5 }}>
                  {firstLabor.user!.givenName} {firstLabor.user!.familyName}
                </strong>
                &nbsp;-&nbsp;{d}
              </div>
              <div className="ui-flex ui-md-right">
                <StatusBadge style={{ width: 120, maxWidth: 120, marginLeft: 10, cursor: 'default' }} label={t(`Expert.Status.${firstLabor.status}`)} type={firstLabor.status} />
                {canComplete && (
                  <>
                    <div style={{ marginLeft: 10 }} className="ui-text-muted">
                      |
                    </div>
                    <button
                      type="button"
                      disabled={saving}
                      className="ui-btn ui-btn-solid ui-btn-primary ui-btn-xs"
                      style={{ marginLeft: 10, width: 120, maxWidth: 120 }}
                      onClick={() => setConfirmEstMarkComplete(g)}
                    >
                      {t('EditProject.MarkAsComplete')}
                    </button>

                    <button
                      type="button"
                      disabled={saving}
                      className="ui-btn ui-btn-solid ui-btn-danger ui-btn-xs"
                      style={{ marginLeft: 5, width: 150, maxWidth: 150 }}
                      onClick={() => setConfirmSendBackToEst(g)}
                    >
                      {t('EditProject.SendBackToEst')}
                    </button>
                  </>
                )}
              </div>
            </div>
          )
          g.groupText = `${firstLabor.user!.givenName} ${firstLabor.user!.familyName} - ${d}`
        }
      } else {
        g.files.push(f)
      }
    })
    groups.forEach((g) => g.files.sort((a, b) => a.originalName.localeCompare(b.originalName)))

    setExpertFileGroups(groups.sort((a, b) => (a.groupText || '').localeCompare(b.groupText || '')))
  }, [phase, projectFiles, setExpertFileGroups])

  const { estimate, details, loading: proEstLoading, load: loadProEst } = useProEst(project, phase)

  const tabs = useMemo(() => {
    let reviewLabor: EstimateLaborEx[] = []
    phase?.estimates?.forEach((est) => {
      reviewLabor = [...reviewLabor, ...(est.labor?.filter((l) => (l.statusHistory?.length ? l.statusHistory[0].metadata.providerReview : false)) || [])]
    })

    const tabs: any[] = []
    if (rights?.userType === UserType.provider) {
      tabs.push({
        label: (
          <div className="ui-flex">
            <div>{t('EditProject.EstimateReview')}</div>
            {reviewLabor.length > 0 && (
              <div className="ui-badge ui-badge-danger" style={{ marginLeft: 3, width: 15, height: 15, lineHeight: '13px', padding: 0, fontSize: '9px' }}>
                {reviewLabor.length}
              </div>
            )}
          </div>
        ),
        selected: pathname.endsWith('review'),
        to: `${url}/review`
      })
    }

    if (rights?.userType !== UserType.client) {
      tabs.push({
        label: t('EditProject.ExpertFiles'),
        selected: pathname.includes('files/expert'),
        to: `${url}/files/expert`
      })
      tabs.push({ label: '-' })
    }

    tabs.push({
      label: t('EditProject.EstimateSummary'),
      selected: pathname.endsWith('summary'),
      to: `${url}/summary`
    })

    tabs.push({
      label: t('EditProject.EstimateFiles'),
      selected: pathname.includes('files/estimate'),
      to: `${url}/files/estimate`
    })

    if (rights?.userType !== UserType.client) {
      tabs.push({
        label: t('EditProject.EstimateDetails'),
        selected: pathname.endsWith('details'),
        to: `${url}/details`
      })
    }

    return tabs
  }, [phase, pathname, url, rights, t])

  if (!phase || !rights) return null

  return (
    <>
      <TabBar tabs={tabs} style={{ justifyContent: 'center' }} onChange={() => setTimeout(resize)} />
      <br />

      <Switch>
        <Route path={`${path}/files/expert/:contentId`} exact>
          <DocumentView category={ProjectContentCategory.Estimate} back={`${url}/files/expert`} />
        </Route>

        <Route path={`${path}/files/expert`} exact>
          <div className="edit-project-files">
            <div className="edit-project-files-content">
              <div className="estimate-files" style={{ height: wrapperHeight }}>
                {![ProjectStatus.Complete, ProjectStatus.Canceled].includes(phase.status!) && (
                  <>
                    <div className="ui-flex">
                      <div className="ui-form-group" style={{ width: 280, height: 200 }}>
                        <div className="ui-frame ui-frame-bg edit-project-files-instructions" style={{ height: '100%', padding: 0 }}>
                          <div className="title">{t('EditProject.Instructions.Title')}</div>
                          <div className="content" dangerouslySetInnerHTML={{ __html: t('EditProject.Instructions.ExpertEstimate') }} />
                        </div>
                      </div>

                      <div className="edit-project-files-dropzone ui-form-group" style={{ height: 200, marginLeft: 20, flex: 1, minWidth: 300 }}>
                        <Dropzone disabled={saving} onDropAccepted={(files) => handleDrop(ProjectContentCategory.Estimate, files)} showFileDialog={showEstFileDialog}>
                          <div style={{ marginTop: 50, alignItems: 'center' }} className="ui-flex ui-flex-column">
                            <div>{t('General.DropFile')}</div>
                            <button type="button" className="ui-btn ui-btn-solid ui-btn-primary" style={{ marginTop: 30 }}>
                              {t('General.UploadEstimates')}
                              <FontAwesomeIcon icon={faArrowUpFromBracket} style={{ marginLeft: 10 }} />
                            </button>
                          </div>
                        </Dropzone>
                      </div>
                    </div>
                  </>
                )}

                <FileList
                  groupedFiles={expertFileGroups}
                  noFilesLabel={
                    <div className="ui-text-lg ui-warn ui-text-center" style={{ marginTop: 100 }}>
                      <div>
                        <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
                      </div>
                      <div style={{ marginTop: 30 }}>{t('General.NoEstimates')}</div>
                    </div>
                  }
                  canDelete={[ProjectStatus.Approved, ProjectStatus.InProgress].includes(phase.status!)}
                  onDelete={(content) => {
                    setConfirmEstDelete(content)
                  }}
                  onUpload={() => {
                    setShowEstFileDialog(true)
                    setTimeout(() => setShowEstFileDialog(false))
                  }}
                  onOpen={(content) => {
                    history.push(`${url}/files/expert/${content.id}`)
                  }}
                />
              </div>

              {footer}
            </div>
          </div>

          {files && currentFileIndex >= 0 && (
            <Uploading
              filename={files[currentFileIndex].name}
              progress={currentProgress}
              onCancel={() => {
                // Cancel the upload
                if (canceler.current) canceler.current()

                canceler.current = undefined
                setCurrentFileIndex(-1)
                setFiles(undefined)
                setCurrentProgress(0)
              }}
            />
          )}
        </Route>

        <Route path={`${path}/files/estimate/:contentId`} exact>
          <DocumentView category={ProjectContentCategory.Boe} back={`${url}/files/estimate`} />
        </Route>

        <Route path={`${path}/files/estimate`} exact>
          <div className="edit-project-files">
            <div className="edit-project-files-content">
              <div className="boe-files" style={{ height: wrapperHeight }}>
                {rights?.userType === UserType.provider && (
                  <>
                    {hasFeature(Feature.BOE) && (
                      <div
                        className="ui-flex"
                        style={{
                          minHeight: 0,
                          height: 'auto',
                          marginBottom: 10,
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <Link to={`${url}/edit`} className="ui-btn ui-btn-primary ui-btn-solid ui-btn-sm">
                          <FontAwesomeIcon icon={faFileChartPie} />
                          &nbsp;&nbsp;{t('EditProject.BOE.Edit')}
                        </Link>
                        <Link to={`${url}/preview`} style={{ marginLeft: 10 }} className="ui-btn ui-btn-secondary ui-btn-solid ui-btn-sm">
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                          &nbsp;&nbsp;{t('EditProject.BOE.Preview')}
                        </Link>
                      </div>
                    )}

                    <div className="ui-flex">
                      <div className="ui-form-group" style={{ width: 280, height: 200 }}>
                        <div className="ui-frame ui-frame-bg edit-project-files-instructions" style={{ height: '100%', padding: 0 }}>
                          <div className="title">{t('EditProject.Instructions.Title')}</div>
                          <div className="content" dangerouslySetInnerHTML={{ __html: t('EditProject.Instructions.PhaseEstimate') }} />
                        </div>
                      </div>

                      <div className="edit-project-files-dropzone ui-form-group" style={{ height: 200, marginLeft: 20, flex: 1, minWidth: 300 }}>
                        <Dropzone onDropAccepted={(files) => handleDrop(ProjectContentCategory.Boe, files)} showFileDialog={showBoeFileDialog}>
                          <div style={{ marginTop: 50, alignItems: 'center' }} className="ui-flex ui-flex-column">
                            <div>{t('General.DropFile')}</div>
                            <button type="button" className="ui-btn ui-btn-solid ui-btn-primary" style={{ marginTop: 30 }}>
                              {t('General.UploadBoe')}
                              <FontAwesomeIcon icon={faArrowUpFromBracket} style={{ marginLeft: 10 }} />
                            </button>
                          </div>
                        </Dropzone>
                      </div>
                    </div>
                  </>
                )}

                {rights.userType === UserType.client && inClientReview && (
                  <div className="ui-frame ui-frame-bg ui-flex" style={{ height: 'auto', minHeight: 0, marginBottom: 20, alignItems: 'center' }}>
                    <div className="ui-info">
                      <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
                    </div>
                    <div style={{ flex: 1, marginLeft: 20 }}>
                      <div className="ui-info ui-text-italic">{t('EditProject.ClientBoeReadyForReviewMessage')}</div>
                    </div>
                  </div>
                )}

                <FileList
                  files={readyFiles}
                  noFilesLabel={
                    <div className="ui-text-lg ui-warn ui-text-center" style={{ marginTop: 100 }}>
                      <div>
                        <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
                      </div>
                      <div style={{ marginTop: 30 }}>{t('General.NoEstimates')}</div>
                    </div>
                  }
                  canDelete={rights.userType === UserType.provider && [ProjectStatus.Approved, ProjectStatus.InProgress].includes(phase.status!)}
                  onDelete={(content) => {
                    setConfirmBoeDelete(content)
                  }}
                  onUpload={() => {
                    setShowBoeFileDialog(true)
                    setTimeout(() => setShowBoeFileDialog(false))
                  }}
                  onOpen={(content) => {
                    history.push(`${url}/files/estimate/${content.id}`)
                  }}
                />
              </div>

              {footer}
            </div>
          </div>

          {files && currentFileIndex >= 0 && (
            <Uploading
              filename={files[currentFileIndex].name}
              progress={currentProgress}
              onCancel={() => {
                // Cancel the upload
                if (canceler.current) canceler.current()

                canceler.current = undefined
                setCurrentFileIndex(-1)
                setFiles(undefined)
                setCurrentProgress(0)
              }}
            />
          )}
        </Route>

        <Route path={`${path}/review`} exact>
          <div className="estimate-files" style={{ height: wrapperHeight }}>
            <EstimateReview
              phase={phase}
              onComplete={(userId) => {
                const fg = expertFileGroups?.find((g) => g.groupId === userId)
                if (fg) {
                  setConfirmEstMarkComplete(fg)
                  return
                }

                const estimates = phase?.estimates?.filter((est) => !!est.labor.find((l) => l.userId === userId))
                if (!estimates?.length) return
                setConfirmEstMarkComplete({ groupId: userId, estimates, files: [] })
              }}
            />
          </div>
        </Route>

        <Route path={`${path}/summary`} exact>
          <div className="edit-project-files">
            <PhaseEstimateSummary phase={phase} />
            {footer}
          </div>
        </Route>

        <Route path={`${path}/details`} exact>
          <div className="estimate-files" style={{ height: wrapperHeight }}>
            {proEstLoading ? (
              <Loading size="sm" variant="parent" style={{ marginTop: 100 }} className="ui-loading-no-overlay" />
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {estimate && details ? (
                  <ProEstEstimateGrid estimate={estimate} details={details} />
                ) : (
                  <div className="ui-text-center" style={{ marginTop: 80 }}>
                    <div className="ui-text-lg ui-danger">
                      <FontAwesomeIcon icon={faExclamationTriangle} /> {t('ProEst.NoEstimate')}
                    </div>
                    <br />
                    <br />
                    <div style={{ maxWidth: 600, margin: '0 auto' }}>{t('ProEst.NoEstimateEx')}</div>
                    <div className="ui-flex" style={{ marginTop: 20, justifyContent: 'center' }}>
                      <button
                        type="button"
                        className="ui-btn ui-btn-primary ui-btn-solid"
                        onClick={() => {
                          setSaving(true)
                          createProEst(project.account.id, project.id!, phase.id!)
                            .then(({ estimate }) => {
                              if (estimate.id) window.open(`https://cloud.proest.com/projects/${estimate.id}/estimate`, 'proEst')
                              loadProEst()
                            })
                            .finally(() => setSaving(false))
                        }}
                      >
                        {t('ProEst.Create')}
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Route>
      </Switch>

      {confirmEstMarkComplete && (
        <ConfirmModal
          title={t('Expert.Estimates.MarkCompleteTitle')}
          message={
            <>
              <div>{t('Expert.Estimates.MarkComplete')}</div>
              <div className="ui-text-sm" style={{ padding: '10px 20px' }}>
                {(confirmEstMarkComplete.estimates || []).map((est) => (
                  <div key={est.id}>{t(`General.Disciplines.${est.discipline}`)}</div>
                ))}
              </div>
            </>
          }
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={() => makeEstComplete(confirmEstMarkComplete)}
          onClose={() => setConfirmEstMarkComplete(undefined)}
        />
      )}

      {confirmMarkComplete && (
        <ConfirmModal
          title={inClientReview ? t('Phase.BOE.ClientMarkCompleteTitle') : t('Phase.BOE.SendToClientTitle')}
          message={
            <>
              {/* eslint-disable-next-line react/no-unstable-nested-components */}
              {(() => {
                if (inClientReview)
                  return (
                    <>
                      <div className="ui-text-center ui-info">
                        <FontAwesomeIcon icon={faQuestionCircle} size="3x" />
                      </div>
                      <br />
                      <div>{t('Phase.BOE.ClientMarkComplete')}</div>
                    </>
                  )

                const pm = project.users?.find((u) => u.role === ResourceUserRole.Owner && !project.providerAccountWithUsers.users.find((u2) => u2.id === u.id))

                return <div>{t('Phase.BOE.SendToClient', { name: pm ? `${pm.givenName} ${pm.familyName}` : '' })}</div>
              })()}
            </>
          }
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={() => makeComplete()}
          onClose={() => setConfirmMarkComplete(false)}
        />
      )}

      {confirmSendToProvider && (
        <ConfirmModal
          title={t('Phase.BOE.SendToProviderTitle')}
          message={t('Phase.BOE.SendToProvider')}
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={() => sendToProvider()}
          onClose={() => setConfirmSendToProvider(false)}
        />
      )}

      {confirmSendBackToEst && (
        <ConfirmModal
          title={t('Expert.Estimates.SendBackToEstTitle')}
          message={
            <>
              <div>{t('Expert.Estimates.SendBackToEst')}</div>
              <div className="ui-text-sm" style={{ padding: '10px 20px' }}>
                {(confirmSendBackToEst.estimates || []).map((est) => (
                  <div key={est.id}>{t(`General.Disciplines.${est.discipline}`)}</div>
                ))}
              </div>
            </>
          }
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={() => sendBackToEst(confirmSendBackToEst)}
          onClose={() => setConfirmSendBackToEst(undefined)}
        />
      )}

      {confirmBoeDelete && (
        <ConfirmModal
          title={t('EditProject.DeleteBoeTitle')}
          message={t('EditProject.DeleteBoe', { name: confirmBoeDelete.originalName })}
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={() => {
            if (!account || !project.id || !confirmBoeDelete?.id) return
            deleteFile(account.id, project.id, confirmBoeDelete.id)
              .then(() => {
                alertSuccess({
                  title: t('EditProject.DeletedBoeTitle'),
                  message: t('EditProject.DeletedBoe', { name: confirmBoeDelete.originalName })
                })
              })
              .catch(() => {
                alertDanger({
                  title: t('EditProject.Errors.DeletedDocumentTitle'),
                  message: t('EditProject.Errors.DeletedDocument')
                })
              })
              .finally(() => setTimeout(() => setConfirmBoeDelete(undefined)))
          }}
          onClose={() => setConfirmBoeDelete(undefined)}
        />
      )}

      {confirmEstDelete && (
        <ConfirmModal
          title={t('EditProject.DeleteEstimateTitle')}
          message={t('EditProject.DeleteEstimate', { name: confirmEstDelete.originalName })}
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={() => {
            if (!account || !project.id || !confirmEstDelete?.id) return
            deleteFile(account.id, project.id, confirmEstDelete.id)
              .then(() => {
                alertSuccess({
                  title: t('EditProject.DeletedEstimateTitle'),
                  message: t('EditProject.DeletedEstimate', { name: confirmEstDelete.originalName })
                })
              })
              .catch(() => {
                alertDanger({
                  title: t('EditProject.Errors.DeletedDocumentTitle'),
                  message: t('EditProject.Errors.DeletedDocument')
                })
              })
              .finally(() => setTimeout(() => setConfirmEstDelete(undefined)))
          }}
          onClose={() => setConfirmEstDelete(undefined)}
        />
      )}
    </>
  )
}
