import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalProps } from 'components/src/modal'
import { ProjectStatus } from 'services/src/dto/projectShare'
import { User } from 'services/src/dto/account'
import { FeeGridViewModel, FeeGridViewModelEstimate, FeeGridViewModelEstimateLabor } from './common'

export type ConfirmOffersModalProps = ModalProps & {
  vm: FeeGridViewModel
  onSend: () => void
}

interface UserGroup {
  user: User
  labor: (FeeGridViewModelEstimateLabor & { estimate: FeeGridViewModelEstimate })[]
}
interface UserGroups {
  [key: string]: UserGroup
}

export const ConfirmOffersModal: React.FC<ConfirmOffersModalProps> = ({ vm, onClose, onSend }) => {
  const { t } = useTranslation()

  const [userGroups, setUserGroups] = useState<UserGroups>({})
  useEffect(() => {
    const userGroups: UserGroups = {}
    vm.estimates.forEach((est) => {
      est.labor
        .filter((l) => !!l.user && l.status === ProjectStatus.New && (l.hours || 0) > 0 && l.cost > 0)
        .forEach((l) => {
          if (!userGroups[l.user!.id]) userGroups[l.user!.id] = { user: l.user!, labor: [] }
          userGroups[l.user!.id].labor.push({ ...l, estimate: est })
        })
    })

    setUserGroups(userGroups)
  }, [vm, setUserGroups])

  const { totalOffers, totalHours } = useMemo(() => {
    let totalOffers = 0
    let totalHours = 0
    Object.keys(userGroups).forEach((id) => {
      const ug = userGroups[id]
      ug.labor.forEach((l) => {
        totalOffers += l.cost
        totalHours += l.hours!
      })
    })

    return { totalOffers, totalHours }
  }, [userGroups])

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      onSend()
    },
    [onSend, t]
  )

  return (
    <Modal closeOnClickOutside={false} onClose={onClose} header={<h3>{t('EditProject.ConfirmOffers.Title')}</h3>}>
      <div>
        <form onSubmit={submit} noValidate autoComplete="off">
          <div className="ui-text-sm" style={{ padding: '0 20px' }}>
            {Object.keys(userGroups).map((id, idx) => {
              const ug = userGroups[id]
              return (
                <div key={id} style={{ marginTop: idx === 0 ? 0 : 10 }}>
                  <div className="ui-flex">
                    <div className="ui-picture-circle">
                      {ug.user.pictureUrl ? (
                        <div style={{ backgroundImage: `url("${ug.user.pictureUrl}")` }} />
                      ) : (
                        <div>
                          {ug.user.givenName[0].toUpperCase()}
                          {ug.user.familyName[0].toUpperCase()}
                        </div>
                      )}
                    </div>
                    <div className="ui-text-bold" style={{ marginLeft: 5 }}>
                      {ug.user.givenName} {ug.user.familyName}
                      {ug.user.expertType && <>&nbsp; ({t(`General.ExpertTypesAlt.${ug.user.expertType}`)})</>}
                    </div>
                  </div>

                  {ug.labor.map((l, idx) => {
                    let skill = ''
                    if (l.skills?.length) {
                      // eslint-disable-next-line prefer-destructuring
                      if (l.skills.length === 1) skill = l.skills[0]
                      else if (l.skills.length === 2) skill = `${l.skills[0]} and ${l.skills[1]}`
                      else {
                        const s = [...l.skills]
                        const last = s[s.length - 1]
                        s.splice(s.length - 1, 1)
                        skill = `${s.join(', ')}, and ${last}`
                      }
                    }

                    return (
                      <div key={idx} className="ui-flex" style={{ marginLeft: 25 }}>
                        <div style={{ width: 150 }}>{t(`General.Disciplines.${l.estimate.discipline}`)}</div>
                        <div className="ui-text-ellipsis" style={{ flex: 1 }}>
                          {skill}
                        </div>
                        <div className="ui-text-ellipsis ui-text-right" style={{ width: 100 }}>
                          {t('General.Hrs', { count: l.hours })}
                        </div>
                        <div className="ui-text-ellipsis ui-text-right" style={{ width: 110 }}>
                          {t('General.Currency.Value', { value: l.cost })}
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}

            <hr />
            <div className="ui-flex ui-text-bold" style={{ justifyContent: 'flex-end' }}>
              <div className="ui-text-ellipsis" style={{ flex: 1 }}>
                {t('Expert.TotalOffer')}
              </div>
              <div className="ui-text-ellipsis ui-text-right" style={{ width: 100 }}>
                {t('General.Hrs', { count: totalHours })}
              </div>
              <div className="ui-text-ellipsis ui-text-right" style={{ width: 110 }}>
                {t('General.Currency.Value', { value: totalOffers })}
              </div>
            </div>
          </div>

          <div style={{ marginTop: 30 }}>
            <button type="submit" className="ui-btn ui-btn-primary ui-btn-solid" style={{ width: '100%' }}>
              {t('EditProject.ConfirmOffers.Send')}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}
