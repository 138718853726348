export interface CreateInvitation {
  givenName: string
  familyName: string
  emailAddress: string
  accountRole?: string
  officeId?: string
  officeRole?: string
  clientId?: string
  clientRole?: string
  projectId?: string
  projectRole?: string
  phaseId?: string
  phaseRole?: string
  providerRole?: string
  providerClient?: string
  metadata?: { [key: string]: string }
}

export interface Invitation {
  id: string
  givenName: string
  familyName: string
  emailAddress: string
  accountId: string
  accountRole: string
  officeId?: string
  officeRole?: string
  clientId?: string
  clientRole?: string
  projectId?: string
  projectRole?: string
  phaseId?: string
  phaseRole?: string
  providerRole?: string
  sentById: string
  status: InvitationStatus
  statusAt: Date
  accessKey: string
  providerClient?: string
  metadata?: any
}

export enum InvitationStatus {
  Pending = 'Pending',
  Complete = 'Complete',
  Timeout = 'Timeout'
}
