import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { Number } from 'components/src/numberField/Number'
import { ProjectStatus } from 'services/src/dto/projectShare'
import { useSaving } from 'components/src/saving'
import { FeeGridViewModelEstimate } from './common'

export const FeeGridExtraOtherFees: React.FC<{
  estimate: FeeGridViewModelEstimate
  onChange: (estimate: FeeGridViewModelEstimate) => void
}> = ({ estimate, onChange }) => {
  const { t } = useTranslation()
  const [saving] = useSaving()

  const { canEdit } = useMemo(() => {
    const canEdit = [ProjectStatus.New, ProjectStatus.Submitted, ProjectStatus.Accepted, ProjectStatus.FeeSelected].includes(estimate.phase.status!)
    return { canEdit }
  }, [estimate])

  if (!canEdit && !estimate.otherFees?.length) return null

  return (
    <>
      <label className="ui-text-bold ui-text-uppercase ui-text-xs" style={{ marginTop: 20 }}>
        {t('EditProject.Fees.OtherFees.Title')}
      </label>

      <div className="edit-project-fee-grid-other-fees" style={{ overflow: 'visible' }}>
        <div className="ui-row">
          {estimate.otherFees.map((of, idx) => (
            <div key={idx} className="ui-col-12 ui-col-sm-6 ui-col-md-4 ui-col-xl-3 ui-form-group">
              <div className="ui-frame ui-frame-bg ui-frame-shadow ui-flex ui-flex-column" style={{ width: '100%', height: '100%', position: 'relative' }}>
                {canEdit && (
                  <button
                    type="button"
                    disabled={saving || !canEdit}
                    className="ui-btn-empty ui-btn-sm ui-danger"
                    style={{
                      width: 25,
                      height: 20,
                      position: 'absolute',
                      top: 16,
                      right: 20
                    }}
                    onClick={() => {
                      const vmx = { ...estimate, otherFees: estimate.otherFees.map((of) => ({ ...of })) }
                      vmx.otherFees.splice(idx, 1)

                      vmx.otherFeeTotal = vmx.otherFees.reduce((a, b) => a + (b.amount || 0), 0)

                      vmx.total = vmx.subtotal + vmx.otherFeeTotal
                      onChange(vmx)
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: '0.9rem' }} />
                  </button>
                )}

                <div className="ui-flex">
                  <div style={{ width: 100 }} className={of.errors?.amount ? 'ui-has-error' : undefined}>
                    <label className="ui-text-xxs">{t('EditProject.Fees.OtherFees.Amount')}</label>
                    <Number
                      className="ui-input-sm"
                      disabled={saving || !canEdit}
                      value={of.amount || undefined}
                      min={0}
                      max={100000}
                      digits={2}
                      placeholder={t('EditProject.Fees.OtherFees.AmountSample')}
                      onChange={(v) => {
                        const vmx = { ...estimate, otherFees: estimate.otherFees.map((of) => ({ ...of })) }
                        vmx.otherFees[idx].amount = v
                        if (vmx.otherFees[idx].errors) delete vmx.otherFees[idx].errors.amount

                        vmx.otherFeeTotal = vmx.otherFees.reduce((a, b) => a + (b.amount || 0), 0)

                        vmx.total = vmx.subtotal + vmx.otherFeeTotal
                        onChange(vmx)
                      }}
                    />
                    {of.errors?.amount && <div className="ui-error ui-text-xxs">{of.errors.amount}</div>}
                  </div>

                  <div style={{ marginLeft: 10, flex: 1 }} className={of.errors?.name ? 'ui-has-error' : undefined}>
                    <label className="ui-text-xxs">{t('EditProject.Fees.NameOrReference')}</label>
                    <input
                      type="text"
                      disabled={saving || !canEdit}
                      className="ui-input-sm"
                      value={of.name}
                      style={{ width: '100%' }}
                      onChange={(e) => {
                        const vmx = { ...estimate, otherFees: estimate.otherFees.map((of) => ({ ...of })) }
                        vmx.otherFees[idx].name = e.currentTarget.value
                        if (vmx.otherFees[idx].errors) delete vmx.otherFees[idx].errors.name
                        onChange(vmx)
                      }}
                    />
                    {of.errors?.name && <div className="ui-error ui-text-xxs">{of.errors.name}</div>}
                  </div>
                </div>

                <label className="ui-text-xxs" style={{ marginTop: 15 }}>
                  {t('EditProject.Fees.AdditionInfo')}
                </label>
                <textarea
                  className="ui-input-xs"
                  disabled={saving || !canEdit}
                  style={{ flexGrow: 1 }}
                  value={of.description || ''}
                  rows={5}
                  onChange={(e) => {
                    const vmx = { ...estimate, otherFees: estimate.otherFees.map((of) => ({ ...of })) }
                    vmx.otherFees[idx].description = e.currentTarget.value
                    onChange(vmx)
                  }}
                />
              </div>
            </div>
          ))}
        </div>

        {canEdit && (
          <button
            type="button"
            className="ui-btn-empty ui-primary ui-text-sm"
            style={{ marginTop: 15 }}
            onClick={() => {
              const providerFee = [ProjectStatus.Approved, ProjectStatus.InProgress, ProjectStatus.Complete, ProjectStatus.Rejected].includes(estimate.phase.status!)

              const vmx = {
                ...estimate,
                otherFees: [
                  ...estimate.otherFees,
                  {
                    name: '',
                    description: '',
                    amount: 0,
                    providerFee
                  }
                ]
              }
              onChange(vmx)
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;
            {t('EditProject.Fees.AddOtherFee')}
          </button>
        )}
      </div>
    </>
  )
}
