import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PRIMARY_PROVIDER_ID, User } from 'services/src/dto/account'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown, faAngleDoubleUp, faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import { faShieldExclamation } from '@fortawesome/pro-solid-svg-icons'
import { ProjectStatus } from 'services/src/dto/projectShare'
import { StatusBadge } from 'components/src/statusBadge'
import { makeDashboardPath } from 'services/src/dom'
import { useCurrentUserAccount } from 'services/src/state'
import DrawingsIcon from 'components/src/svg/DrawingsIcon'
import { FeeGridViewModel } from './common'
import { Number } from '../../../../numberField/Number'
import { useSaving } from '../../../../saving'
import { HelpPopup } from '../../../../help/HelpPopup'

export const FeeGridPhaseTotals: React.FC<{
  vm: FeeGridViewModel
  inScroll?: boolean
  onExpandAll: () => void
  onCollapseAll: () => void
  onChangeProviderFee: (pf?: number) => void
}> = ({ vm, inScroll, onExpandAll, onCollapseAll, onChangeProviderFee }) => {
  const { t } = useTranslation()
  const [account] = useCurrentUserAccount()
  const [saving] = useSaving()

  const canEdit = [ProjectStatus.New, ProjectStatus.Submitted, ProjectStatus.Accepted, ProjectStatus.FeeSelected].includes(vm.phase.status!)

  const { subtotal, otherFees, total, laborCost, estHours, laborHours, margin, marginClass, negDiff, providerFee, hasWarning } = useMemo(() => {
    let subtotal = 0
    let otherFees = 0

    let providerFee = vm.providerFee
    let total = providerFee || 0

    let estHours = 0

    let laborCost = 0
    let laborHours = 0

    const users: User[] = []

    vm.estimates.forEach((est) => {
      estHours += est.hours

      subtotal += est.subtotal
      total += est.total
      otherFees += est.otherFeeTotal

      est.labor
        .filter((l) => l.status !== ProjectStatus.Rejected)
        .forEach((l) => {
          laborCost += l.cost
          laborHours += l.hours || 0

          if (l.user) {
            if (!users.find((u) => u.id === l.user!.id)) users.push(l.user)
          }
        })
    })

    const sub = vm.phase.negotiatedTotals?.subtotal || subtotal || 0

    const margin = sub > 0 && laborCost > 0 ? (sub - laborCost) / sub : null

    let marginClass = ''
    if (margin !== null) {
      if (margin <= 0.25) marginClass = 'ui-danger ui-text-bold'
      else if (margin > 0.25 && margin <= 0.5) marginClass = 'ui-warn ui-text-bold'
      else if (margin > 0.5) marginClass = 'ui-success ui-text-bold'
    }

    let negDiff = null
    if (vm.phase.negotiatedTotals) negDiff = (vm.phase.negotiatedTotals.total - total) / total

    const hasWarning = [ProjectStatus.FeeReady, ProjectStatus.Approved, ProjectStatus.InProgress].includes(vm.phase.status!)

    return {
      subtotal,
      otherFees,
      total,
      estHours,
      laborCost,
      laborHours,
      margin,
      marginClass,
      users,
      negDiff,
      providerFee,
      hasWarning
    }
  }, [vm])

  return (
    <div className={`edit-project-fee-grid-phase-totals${inScroll ? ' in-scroll' : ''}`}>
      <div
        className="ui-frame ui-frame-selected"
        style={{
          paddingTop: hasWarning ? 27 : 20
        }}
      >
        {hasWarning && (
          <div className="edit-project-fee-grid-phase-totals-alert">
            <FontAwesomeIcon icon={faShieldExclamation} />
            &nbsp;
            {t('EditProject.Fees.TotalsWarning')}
          </div>
        )}

        <div className="ui-flex" style={{ marginBottom: hasWarning ? 8 : 15 }}>
          <div>
            <div className="ui-text-ellipsis" title={t(`EditProject.${vm.phase.phaseType}`)}>
              {t(`EditProject.${vm.phase.phaseType}`)}
            </div>
            {vm.phase.status === ProjectStatus.FeeReady && (
              <StatusBadge
                label={vm.phase.statusHistory![0].oldStatus === ProjectStatus.FeeCounter ? t('Projects.FeeCounterDecline') : t(`Projects.${vm.phase.status}`)}
                type={vm.phase.statusHistory![0].oldStatus === ProjectStatus.FeeCounter ? 'danger' : vm.phase.status}
                style={{ display: 'inline-flex', padding: '2px 7px', fontSize: '0.7rem', fontWeight: 'bold' }}
              />
            )}
            {vm.phase.status === ProjectStatus.Approved && (
              <StatusBadge label={t(`Projects.ApprovedByClient`)} type={ProjectStatus.Approved} style={{ display: 'inline-flex', padding: '2px 7px', fontSize: '0.7rem', fontWeight: 'bold' }} />
            )}
          </div>

          {vm.variant !== 'assign' && (
            <div className="ui-text-sm ui-text-right" style={{ marginLeft: 'auto', width: 150 }}>
              <div className="ui-text-xxs ui-text-uppercase ui-text-bold ui-text-muted" style={{ marginBottom: 2 }}>
                {t('EditProject.Fees.GeneratedFee')}
              </div>
              <div className="ui-text-xs">
                {t('General.Hrs', { count: vm.phase.generatedTotals?.hours || 0 })} / {t('General.Currency.Value', { value: vm.phase.generatedTotals?.total || 0 })}
              </div>
            </div>
          )}
        </div>

        <div className="ui-flex">
          <div className="ui-text-sm" style={{ marginLeft: 'auto', width: 60 }}>
            {(vm.phase.pages || 0) > 0 && (
              <>
                <div className="ui-text-xxs ui-text-center ui-text-uppercase ui-text-bold ui-text-muted" style={{ marginBottom: 5 }}>
                  {t('EditProject.Fees.Pages')}
                </div>
                <div className="ui-text-center" style={{ height: 25, width: 60, lineHeight: '23px' }}>
                  <Link to={makeDashboardPath(account?.id, `projects/${vm.project.id}/phases/${vm.phase.id}/files/drawings`)} style={{ display: 'inline-flex' }} className="ui-secondary">
                    <DrawingsIcon style={{ width: 15, marginRight: 5 }} />
                    {vm.phase.pages === undefined ? '--' : vm.phase.pages}
                  </Link>
                </div>
              </>
            )}
          </div>

          <div className="ui-text-sm">
            <div className="ui-text-xxs ui-text-center ui-text-uppercase ui-text-bold ui-text-muted" style={{ marginBottom: 5 }}>
              {t('EditProject.Fees.BillableHours')}
            </div>
            <div className="ui-frame ui-frame-no-padding ui-text-center" style={{ height: 25, width: 100, lineHeight: '23px' }}>
              {estHours}
            </div>
          </div>

          {vm.variant !== 'assign' && (
            <div className="ui-text-sm" style={{ marginLeft: 20 }}>
              <div className="ui-text-xxs ui-text-center ui-text-uppercase ui-text-bold ui-text-muted" style={{ marginBottom: 5 }}>
                {t('EditProject.Fees.Subtotal')}
              </div>
              <div className="ui-frame ui-frame-no-padding ui-text-center" style={{ height: 25, width: 100, lineHeight: '23px' }}>
                {t('General.Currency.Value', { value: subtotal })}
              </div>
            </div>
          )}

          {vm.variant !== 'assign' && (
            <div className="ui-text-sm" style={{ marginLeft: 10 }}>
              <div className="ui-text-xxs ui-text-center ui-text-uppercase ui-text-bold ui-text-muted" style={{ marginBottom: 5 }}>
                {t('EditProject.Fees.OtherFees.Title')}
              </div>
              <div className="ui-frame ui-frame-no-padding ui-text-center" style={{ height: 25, width: 100, lineHeight: '23px' }}>
                {t('General.Currency.Value', { value: otherFees })}
              </div>
            </div>
          )}

          <div className="ui-text-sm" style={{ marginLeft: 10 }}>
            <div className="ui-text-xxs ui-text-center ui-text-uppercase ui-text-bold ui-text-muted ui-flex" style={{ marginBottom: 5 }}>
              {t('EditProject.Fees.TechFee')}
            </div>

            {(canEdit && account?.id === PRIMARY_PROVIDER_ID) && (
              <Number
                value={providerFee}
                disabled={saving || !canEdit}
                className="ui-text-sm"
                digits={2}
                step={1}
                min={0}
                max={5000}
                style={{
                  height: 25,
                  lineHeight: '23px'
                }}
                onChange={(pf) => {
                  onChangeProviderFee(pf);
                }}
              />
            )}
            {(!canEdit || account?.id !== PRIMARY_PROVIDER_ID) && (
              <div className="ui-frame ui-frame-no-padding ui-text-center" style={{ height: 25, width: 100, lineHeight: '23px' }}>
                {providerFee ? t('General.Currency.Value', { value: providerFee }) : '--'}
              </div>
            )}


          </div>

          {vm.variant !== 'assign' && (
            <div className="ui-text-sm" style={{ marginLeft: 10 }}>
              <div className="ui-text-xxs ui-text-center ui-text-uppercase ui-text-bold ui-text-muted" style={{ marginBottom: 5 }}>
                {t('EditProject.Fees.Total')}
              </div>
              <div className="ui-frame ui-frame-no-padding ui-text-center ui-text-bold" style={{ height: 25, width: 100, lineHeight: '23px' }}>
                {t('General.Currency.Value', { value: total })}
              </div>
            </div>
          )}

          {vm.variant !== 'assign' && vm.phase.negotiatedTotals && (
            <>
              <div className="ui-text-sm" style={{ marginLeft: 20 }}>
                <div className="ui-text-xxs ui-text-center ui-text-uppercase ui-text-bold ui-text-muted ui-danger" style={{ marginBottom: 5 }}>
                  {vm.phase.status === ProjectStatus.FeeCounter ? t('EditProject.Fees.CounterOffer') : t('EditProject.Fees.NegotiatedFee')}
                </div>
                <div className="ui-frame ui-frame-no-padding ui-text-center ui-text-bold" style={{ height: 25, width: 150, lineHeight: '23px' }}>
                  <span className="ui-danger">
                    {t('General.Currency.Value', { value: vm.phase.negotiatedTotals.total })}
                    {negDiff && <>&nbsp;({t('General.PercentValue', { value: (negDiff * 100).toFixed(1) })})</>}
                  </span>
                </div>
              </div>
            </>
          )}

          <div className="ui-text-sm" style={{ marginLeft: 20 }}>
            <div style={{ marginBottom: 5, position: 'relative' }}>
              <div className="ui-text-xxs ui-text-center ui-text-uppercase ui-text-bold ui-text-muted">{t('EditProject.Fees.LaborHours')}</div>
              {laborHours < estHours && (
                <div
                  className="ui-badge ui-badge-danger ui-text-xxs"
                  title={t('EditProject.Fees.UnallocatedHours')}
                  style={{
                    cursor: 'default',
                    fontSize: '0.6rem',
                    fontWeight: 'bold',
                    lineHeight: '0.6rem',
                    position: 'absolute',
                    top: 0,
                    right: -3,
                    width: 12,
                    height: 12,
                    padding: 0
                  }}
                >
                  !
                </div>
              )}
            </div>
            <div className="ui-frame ui-frame-no-padding ui-text-center" style={{ height: 25, width: 100, lineHeight: '23px' }}>
              {laborHours}
            </div>
          </div>

          {vm.variant !== 'assign' && (
            <div className="ui-text-sm" style={{ marginLeft: 10 }}>
              <div className="ui-text-xxs ui-text-center ui-text-uppercase ui-text-bold ui-text-muted" style={{ marginBottom: 5 }}>
                {t('EditProject.Fees.LaborCost')}
              </div>
              <div className="ui-frame ui-frame-no-padding ui-text-center ui-text-bold" style={{ height: 25, width: 100, lineHeight: '23px' }}>
                <span className="ui-danger">{t('General.Currency.Value', { value: laborCost })}</span>
              </div>
            </div>
          )}

          {vm.variant !== 'assign' && (
            <div className="ui-text-sm" style={{ marginLeft: 20 }}>
              <div className="ui-text-xxs ui-text-center ui-text-uppercase ui-text-bold ui-text-muted" style={{ marginBottom: 5 }}>
                {t('EditProject.Fees.FeeMargin')}
              </div>
              <div className="ui-frame ui-frame-no-padding ui-text-center" style={{ height: 25, width: 60, lineHeight: '23px' }}>
                <span className={marginClass}>{margin === null ? '--' : t('General.PercentValue', { value: (margin * 100).toFixed(1) })}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="ui-flex" style={{ justifyContent: 'flex-end' }}>
        <button type="button" className="ui-btn-empty ui-text-xs" tabIndex={-1} onClick={() => onExpandAll()}>
          <FontAwesomeIcon icon={faAngleDoubleDown} />
          &nbsp;{t('General.ExpandAll')}
        </button>
        <button type="button" className="ui-btn-empty ui-text-xs" tabIndex={-1} onClick={() => onCollapseAll()}>
          <FontAwesomeIcon icon={faAngleDoubleUp} />
          &nbsp;{t('General.CollapseAll')}
        </button>
      </div>
    </div>
  )
}
