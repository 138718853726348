import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity, faComments, faDraftingCompass, faEllipsisH, faTimes, faUserChart } from '@fortawesome/pro-regular-svg-icons'
import { faCompress, faExpand } from '@fortawesome/pro-duotone-svg-icons'

import { Comment } from 'services/src/dto/comment'

import './style.scss'
import { CommentChannels, useCommentChannels, useCommentPanel, useComments, useCommentWs } from 'services/src/state/comments'
import { ResourceUserRole } from 'services/src/dto/account'
import { useMe } from 'services/src/state'
import { useLocation } from 'react-router-dom'
import { makeDashboardPath } from 'services/src/dom'
import MarkerBox from '../svg/MarkerBox'
import CommentPanelItems from './CommentPanelItems'
import CommentInput from './CommentInput'

const MIN_ALT_HEIGHT = 200
const MAX_ALT_HEIGHT = 500
const MIN_INPUT_HEIGHT = 150
const MAX_INPUT_HEIGHT = 800
const RESIZE_BAR_HEIGHT = 6

const ResizeBar: React.FC<{
  style?: React.CSSProperties
  onResizeStart: (e: React.MouseEvent<HTMLDivElement>) => void
  onResizeEnd: () => void
}> = ({ style, onResizeStart, onResizeEnd }) => (
  <div
    className="ui-comment-resize-bar"
    style={{ ...(style || {}), height: RESIZE_BAR_HEIGHT }}
    role="button"
    tabIndex={-1}
    onKeyDown={() => {}}
    onMouseDown={(e) => onResizeStart(e)}
    onMouseUp={() => onResizeEnd()}
  >
    <FontAwesomeIcon icon={faEllipsisH} />
  </div>
)

export const CommentPanel: React.FC = () => {
  const { t } = useTranslation()
  const [me] = useMe()
  const { pathname } = useLocation()

  const [{ view, title, accountId }, setPanelState] = useCommentPanel()

  const { postComment } = useCommentWs()
  const { channels, currentChannel, setCurrentChannel } = useCommentChannels()

  const channelsRef = useRef<CommentChannels | undefined>()
  channelsRef.current = channels

  const { comments, loadComments } = useComments()
  const commentsRef = useRef<Comment[] | undefined>([])
  commentsRef.current = currentChannel ? comments[`${currentChannel.channel}${currentChannel.sourceChannel || ''}`] : undefined

  const directChannels = useMemo(() => channels?.direct.filter((ch) => ch.userId !== me?.id), [channels, me])

  const elm = useRef<HTMLDivElement>(document.createElement('div'))
  useEffect(() => {
    document.body.appendChild(elm.current)
    return () => {
      if (elm.current) elm.current.remove()
    }
  }, [])

  const panelRef = useRef<HTMLDivElement>(null)

  const [loadBefore, setLoadBefore] = useState<number>()

  useEffect(() => {
    if (!currentChannel) return

    const lb = commentsRef.current?.length ? commentsRef.current[0].createdAt : Date.now()
    loadComments(lb)
    setLoadBefore(lb)
  }, [currentChannel, loadComments, setLoadBefore])

  const [inAltResize, setInAltResize] = useState(false)
  const [altHeight, setAltHeight] = useState(parseInt(localStorage.getItem('commentsAltHeight') || '200', 10))

  const [inInputResize, setInInputResize] = useState(false)
  const [inputHeight, setInputHeight] = useState(Math.max(MIN_INPUT_HEIGHT, parseInt(localStorage.getItem('commentsInputHeight') || '200', 10)))

  const mouseUp = useCallback(() => {
    setInAltResize(false)
    setInInputResize(false)
  }, [setInInputResize])

  useEffect(() => {
    window.addEventListener('mouseup', mouseUp)
    return () => {
      window.removeEventListener('mouseup', mouseUp)
    }
  }, [mouseUp])

  return ReactDOM.createPortal(
    <div
      className={`ui-comment-panel${view === 'open' ? ' ui-comment-panel-open' : ''}${view === 'full' ? ' ui-comment-panel-open ui-comment-panel-full' : ''} ${
        channels ? 'ui-comment-panel-has-nav' : 'ui-comment-panel-no-nav'
      }`}
      ref={panelRef}
      onMouseMove={(e) => {
        if (inAltResize) {
          const panelHeight = panelRef.current?.clientHeight
          if (!panelHeight) return

          const h = altHeight + e.movementY
          if (h <= MIN_ALT_HEIGHT || h >= MAX_ALT_HEIGHT) return

          setAltHeight(h)
          localStorage.setItem('commentsAltHeight', h.toString())
        } else if (inInputResize) {
          const panelHeight = panelRef.current?.clientHeight
          if (!panelHeight) return

          const h = inputHeight - e.movementY
          if (h <= MIN_INPUT_HEIGHT || h >= MAX_INPUT_HEIGHT) return

          setInputHeight(h)
          localStorage.setItem('commentsInputHeight', h.toString())
        }
      }}
    >
      <div className="ui-comment-panel-header">
        {/* eslint-disable-next-line react/no-danger */}
        {title ? <h3 dangerouslySetInnerHTML={{ __html: title }} /> : <h3>{t('General.Comments')}</h3>}
        <button
          type="button"
          className="ui-btn-empty"
          style={{ borderRadius: 0 }}
          onClick={() => {
            if (view === 'full') setPanelState((s) => ({ ...s, view: 'open' }))
            else if (view === 'open') setPanelState((s) => ({ ...s, view: 'full' }))
          }}
        >
          <FontAwesomeIcon icon={view === 'full' ? faCompress : faExpand} />
        </button>
        <button
          type="button"
          className="ui-btn-empty"
          style={{ borderRadius: 0 }}
          onClick={() => {
            setPanelState((s) => ({ ...s, view: 'closed' }))
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>

      <div className="ui-flex ui-flex-nowrap" style={{ height: window.innerHeight - 50 }}>
        {channels && (
          <div className="ui-comment-panel-nav">
            <div className="edit-project-nav edit-project-comment-nav">
              {channels.groups.length > 0 && (
                <div className="edit-project-nav-group">
                  <label>{t('General.Channels')}</label>
                  {channels.groups.map((ch, idx) => (
                    <a
                      key={idx}
                      href={window.location.href}
                      className={ch.channel === currentChannel?.channel ? ' active' : ''}
                      onClick={(e) => {
                        e.preventDefault()
                        setCurrentChannel(ch)
                      }}
                    >
                      <FontAwesomeIcon icon={faComments} />
                      <span style={{ marginLeft: 5 }} className="ui-flex ui-flex-nowrap">
                        {ch.name}
                        {ch.newCount > 0 && <span className="ui-badge ui-badge-danger">{ch.newCount < 1000 ? ch.newCount : `${Math.floor(ch.newCount / 1000)}k`}</span>}
                      </span>
                    </a>
                  ))}
                </div>
              )}

              {(directChannels?.length || 0) > 0 && (
                <div className="edit-project-nav-group">
                  <label>{t('General.DM')}</label>
                  {directChannels!.map((ch, idx) => (
                    <a
                      key={idx}
                      href={window.location.href}
                      className={`ui-empty-button${ch.channel === currentChannel?.channel ? ' active' : ''}`}
                      onClick={(e) => {
                        e.preventDefault()
                        setCurrentChannel(ch)
                      }}
                    >
                      {(() => {
                        const { userType } = ch
                        return (
                          <>
                            {userType === 'Client' && <FontAwesomeIcon icon={faUserChart} />}
                            {userType === 'Expert' && <FontAwesomeIcon icon={faDraftingCompass} />}
                            {userType === 'Provider' && <FontAwesomeIcon icon={faCity} />}
                          </>
                        )
                      })()}

                      <span style={{ marginLeft: 5 }}>{ch.name}</span>
                      {ch.newCount > 0 && <span className="ui-badge ui-badge-danger">{ch.newCount < 1000 ? ch.newCount : `${Math.floor(ch.newCount / 1000)}k`}</span>}
                      {ch.scope === 'Project' && ch.role === ResourceUserRole.Owner && (
                        <span className="role-marker">
                          <MarkerBox />
                          <span>PM</span>
                        </span>
                      )}
                    </a>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

        <div className="ui-comment-panel-main">
          {/* renderAltView && (
            <>
              <CommentPanelAlt style={{height: altHeight, minHeight: altHeight, visibility: inChange ? 'hidden' : 'visible'}}>
                {renderAltView()}
              </CommentPanelAlt>
              <ResizeBar onResizeStart={() => setInAltResize(true)}
                         onResizeEnd={() => setInAltResize(false)}
                         style={{visibility: inChange ? 'hidden' : 'visible', borderTop: 'none'}}
              />
            </>
          ) */}
          <CommentPanelItems
            comments={commentsRef.current}
            onLoadMore={() => {
              if (!commentsRef.current?.length) return
              if (loadBefore === commentsRef.current[0].createdAt) return
              loadComments(commentsRef.current[0].createdAt)
              setLoadBefore(commentsRef.current[0].createdAt)
            }}
          />

          <ResizeBar onResizeStart={() => setInInputResize(true)} onResizeEnd={() => setInInputResize(false)} style={{ borderBottom: 'none' }} />

          <CommentInput
            height={inputHeight}
            onSend={(content, plainText) => {
              const idx = pathname.indexOf('projects/')
              const url = makeDashboardPath(accountId, pathname.substring(idx))
              postComment(content, plainText, url).then(() => {})
            }}
          />
        </div>
      </div>
    </div>,
    elm.current
  )
}
