import React, { useEffect } from 'react'
import { login } from 'services/src/aad'
import { Loading } from '../loading'
import { StringParam, useQueryParam } from 'use-query-params'

export const Login: React.FC = () => {
  const [back] = useQueryParam('back', StringParam)

  useEffect(() => {
    // if already in b2c, fall back to app entry
    if (localStorage.getItem('inB2c')) {
      localStorage.removeItem('inB2c')
      window.location.href = '/'
      return
    }
    localStorage.setItem('inB2c', 'yes')
    login('/dashboard', back)
      .catch(console.error)
  }, [])

  return <Loading />
}
