import React, { InputHTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react'

export type NumberProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value' | 'type'> & {
  value?: number
  min?: number
  max?: number
  digits?: number
  step?: number
  onChange: (value?: number) => void
  suppressNegative?: boolean
}

export const Number: React.FC<NumberProps> = ({ name, onChange, value, min, max, digits, step, suppressNegative, ...rest }) => {
  const [val, setVal] = useState<string>()

  useEffect(() => {
    setVal((current) => {
      if (!value) {
        const v = 0
        return (digits || 0) > 0 ? v.toFixed(digits) : v.toString(10)
      }

      if (current === undefined) {
        if ((digits || 0) > 0) return value.toFixed(digits)
      }

      return value.toString(10)
    })
  }, [value])

  const keydown = useCallback(
    (e: React.KeyboardEvent) => {
      if (!val) return

      if (val.length <= 0) {
        if (digits && /\d/.test(e.key)) return

        if (/[1-9]/.test(e.key)) return

        if (e.key === '.' && digits) return

        if (e.key === '-') {
          if (suppressNegative || (min !== undefined && min >= 0)) {
            e.preventDefault()
            e.stopPropagation()
            return
          }
        }
      }

      if (val.length >= 1) {
        if (/\d/.test(e.key)) {
          if (parseFloat(`${val}${e.key}`) === 0) {
            e.preventDefault()
            e.stopPropagation()
          }
          return
        }
      }

      if (e.key === '.' && (digits || 0) > 0) {
        if (!val.includes('.')) return
      }

      if (['a', 'c', 'v'].includes(e.key) && e.metaKey) {
        return
      }

      if (['Backspace', 'Delete', 'Tab', 'Enter', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
        return
      }

      e.preventDefault()
      e.stopPropagation()
    },
    [val]
  )

  const { theMin, theMax } = useMemo(
    () => ({
      // eslint-disable-next-line no-nested-ternary
      theMin: min !== undefined ? min : suppressNegative ? 0 : undefined,
      theMax: max
    }),
    [min, max, suppressNegative]
  )

  return (
    <input
      id={name}
      name={name}
      type="number"
      step={step}
      min={theMin}
      max={theMax}
      onPaste={(e) => {
        const v = (e.clipboardData.getData('Text') || '').replace(/\D/g, '')
        if (!v) {
          e.preventDefault()
          e.stopPropagation()
        }
      }}
      onChange={(e) => {
        if (digits) {
          const ns = e.currentTarget.value.indexOf('.')
          if (ns >= 0) {
            const fraction = e.currentTarget.value.substring(ns + 1)
            if (fraction.length > digits) return
          }
        }

        const v = parseFloat(e.currentTarget.value)
        if (suppressNegative && v < 0) return

        if (min !== undefined) {
          if (v < min) return
        }
        if (max !== undefined) {
          if (v > max) return
        }
        setVal(e.currentTarget.value)
        onChange(v || undefined)
      }}
      onBlur={() => {
        const v = parseFloat(val || '0')
        setVal((digits || 0) > 0 ? v.toFixed(digits) : v.toString(10))
      }}
      value={val || ''}
      onKeyDown={keydown}
      autoComplete="new"
      {...rest}
    />
  )
}
