import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faArrowUpRightFromSquare, faCopy, faUpFromLine, faDownFromLine } from '@fortawesome/pro-regular-svg-icons'
import { parsePhoneNumber } from 'libphonenumber-js'
import './style.scss'

import { User } from 'services/src/dto/account'
import { useTranslation } from 'react-i18next'

import { ICellRendererParams, RowClassParams } from 'ag-grid-community'
import 'ag-grid-enterprise'

import { useMe, useRights, UserType } from 'services/src/state'
import { getDisciplines } from 'services/src/common'
import { IconDefinition } from '@fortawesome/pro-light-svg-icons'
import { useSaving } from '../saving'
import { useAlert } from '../alerts'

export type UserEx = User & {
  canEdit?: boolean
  roleLabel?: string
  userIcon?: IconDefinition
  badge?: string
  disciplines?: string[]
  isInherited?: boolean
  isLower?: boolean
}

type NameCellProps = Omit<ICellRendererParams, 'data'> & {
  data: UserEx
}
const NameCell: React.FC<NameCellProps> = ({ data }) => {
  const { t } = useTranslation()
  const [me] = useMe()

  return (
    <div className={`ui-flex ui-text-ellipsis${me?.id === data.id ? ' ui-is-me' : ''}`} style={{ alignItems: 'center' }}>
      {data.userIcon && <FontAwesomeIcon icon={data.userIcon} style={{ marginRight: 5 }} />}
      <div>
        {data.givenName} {data.familyName}
      </div>
      {!data.isLower && data.isInherited && <FontAwesomeIcon icon={faUpFromLine} style={{ marginLeft: 5 }} title={t('General.Inherited.Default')} />}
      {data.isLower && <FontAwesomeIcon icon={faDownFromLine} style={{ marginLeft: 5 }} title={t('General.Inherited.Lower')} />}
    </div>
  )
}

type RoleCellProps = Omit<ICellRendererParams, 'data'> & {
  data: UserEx
}
const RoleCell: React.FC<RoleCellProps> = ({ data }) => {
  if (!data.roleLabel) return null

  return <div className="ui-text-ellipsis">{data.roleLabel}</div>
}

type ActionCellProps = Omit<ICellRendererParams, 'data'> & {
  data: UserEx
  onEdit?: (user: User) => void
  onDelete?: (user: User) => void
  readOnly?: boolean
}
const ActionCell: React.FC<ActionCellProps> = ({ data: user, readOnly, onEdit, onDelete }) => {
  const { t } = useTranslation()
  const [saving] = useSaving()

  if (!user.canEdit || readOnly) return <div />

  return (
    <div className="ui-text-center">
      {onEdit && (
        <button
          type="button"
          disabled={saving}
          className="ui-btn-empty ui-info"
          title={t('General.Edit')}
          onClick={(e) => {
            e.preventDefault()
            if (onEdit) onEdit(user)
          }}
        >
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </button>
      )}
      {onDelete && (
        <button
          type="button"
          disabled={saving}
          className="ui-btn-empty ui-danger"
          title={t('General.Remove')}
          onClick={(e) => {
            e.preventDefault()
            if (onDelete) onDelete(user)
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      )}
    </div>
  )
}

const DetailsCell: React.FC<
  Omit<ICellRendererParams, 'data'> & {
    data: UserEx
  }
> = ({ data }) => {
  const { t } = useTranslation()
  const { alertSuccess } = useAlert()

  const mobilePhone = useMemo(() => {
    if (!data.mobilePhone) return undefined

    const p = parsePhoneNumber(data.mobilePhone.startsWith('+') ? data.mobilePhone : `+${data.mobilePhone}`)
    return p.formatNational()
  }, [data.mobilePhone])

  const businessPhone = useMemo(() => {
    if (!data.businessPhones?.length || !data.businessPhones[0].number) return undefined
    const phone = data.businessPhones[0].number
    const ext = data.businessPhones[0].extension

    const p = parsePhoneNumber(phone.startsWith('+') ? phone : `+${phone}`)
    return `${p.formatNational()} ${(ext ? `x${ext}` : '')}`.trim()
  }, [data.businessPhones])

  const addressLines = useMemo(() => {
    if (!data.personalAddress) return null

    let line1 = ''
    if (data.personalAddress.streetNameAndNumber) line1 = data.personalAddress.streetNameAndNumber
    else if (data.personalAddress.streetNumber && data.personalAddress.streetName) line1 = `${data.personalAddress.streetNumber} ${data.personalAddress.streetName}`
    else if (data.personalAddress.streetName) line1 = data.personalAddress.streetName

    let line2 = ''
    if (data.personalAddress.municipality && (data.personalAddress.countrySubdivisionName || data.personalAddress.countrySubdivision) && data.personalAddress.postalCode)
      line2 = `${data.personalAddress.municipality}, ${data.personalAddress.countrySubdivisionName || data.personalAddress.countrySubdivision} ${data.personalAddress.postalCode}`
    else if (data.personalAddress.municipality && (data.personalAddress.countrySubdivisionName || data.personalAddress.countrySubdivision))
      line2 = `${data.personalAddress.municipality}, ${data.personalAddress.countrySubdivisionName || data.personalAddress.countrySubdivision}`
    else if (data.personalAddress.municipality && data.personalAddress.postalCode) line2 = `${data.personalAddress.municipality} ${data.personalAddress.postalCode}`
    else if ((data.personalAddress.countrySubdivisionName || data.personalAddress.countrySubdivision) && data.personalAddress.postalCode)
      line2 = `${data.personalAddress.countrySubdivisionName || data.personalAddress.countrySubdivision} ${data.personalAddress.postalCode}`

    const line3 = data.personalAddress.country || data.personalAddress.countryCode

    if (!line1) return [data.personalAddress.freeformAddress]

    if (line1 && line2 && line3) return [line1, line2, line3]
    if (line1 && line2) return [line1, line2]
    if (line1) return [line1]

    return [data.personalAddress.freeformAddress]
  }, [data.personalAddress])

  const copyToClipboard = useCallback(
    (text: string) => {
      navigator.clipboard.writeText(text).then(() => {
        alertSuccess({
          title: t('General.CopyToClipboardTitle'),
          message: t('General.CopyToClipboard')
        })
      })
    },
    [alertSuccess, t]
  )

  return (
    <div className="details">
      <div className="ui-frame ui-frame-bg">
        <div className="ui-flex" style={{ alignItems: 'center' }}>
          {data.pictureUrl && (
            <div className="ui-picture-circle ui-picture-circle-md" style={{ marginRight: 10 }}>
              <div style={{ backgroundImage: `url(${data.pictureUrl})` }} />
            </div>
          )}
          <div>
            {data.primaryDiscipline && (
              <div className="ui-badge ui-badge-info ui-badge-xs" style={{ display: 'inline-flex' }}>
                {t(`General.Disciplines.${data.primaryDiscipline}`)}
              </div>
            )}
            <h1 className="ui-secondary">
              {data.givenName} {data.familyName}
            </h1>
          </div>
        </div>

        <div className="ui-row" style={{ marginTop: 30 }}>
          <div className="ui-col-6">
            {(data.emails?.length || 0) > 0 && (
              <div className="details-item">
                <div className="ui-frame ui-text-right" style={{ width: 90 }}>
                  Email:
                </div>
                <div className="ui-frame ui-action-item" style={{ marginLeft: 5 }} onClick={() => copyToClipboard(data.emails![0].email)} role="button" tabIndex={-1}>
                  {data.emails![0].email}
                  <FontAwesomeIcon icon={faCopy} style={{ marginLeft: 5 }} />
                </div>
              </div>
            )}
            {mobilePhone && (
              <div className="details-item">
                <div className="ui-frame ui-text-right" style={{ width: 90 }}>
                  Mobile:
                </div>
                <div className="ui-frame ui-action-item" style={{ marginLeft: 5 }} onClick={() => copyToClipboard(mobilePhone)} role="button" tabIndex={-1}>
                  {mobilePhone}
                  <FontAwesomeIcon icon={faCopy} style={{ marginLeft: 5 }} />
                </div>
              </div>
            )}
            {businessPhone && (
              <div className="details-item">
                <div className="ui-frame ui-text-right" style={{ width: 90 }}>
                  Business:
                </div>
                <div className="ui-frame ui-action-item" style={{ marginLeft: 5 }} onClick={() => copyToClipboard(businessPhone)} role="button" tabIndex={-1}>
                  {businessPhone}
                  <FontAwesomeIcon icon={faCopy} style={{ marginLeft: 5 }} />
                </div>
              </div>
            )}
          </div>

          <div className="ui-col-5 ui-offset-1">
            {addressLines && (
              <div className="details-item" style={{ alignItems: 'flex-start' }}>
                <div className="ui-frame ui-text-right" style={{ width: 90 }}>
                  Address:
                </div>
                <div className="ui-frame ui-action-item" style={{ marginLeft: 5, height: 'auto' }} onClick={() => copyToClipboard(addressLines!.join('\n'))} role="button" tabIndex={-1}>
                  <div>
                    {addressLines.map((l, idx) => (
                      <div key={idx}>{l}</div>
                    ))}
                  </div>
                  <FontAwesomeIcon icon={faCopy} style={{ marginLeft: 10 }} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const NoRowsOverlay = () => {
  const { t } = useTranslation()
  return (
    <div className="no-rows-overlay">
      <h3>{t('General.NoStaff')}</h3>
    </div>
  )
}

export interface ResourceUsersProps {
  users?: UserEx[]
  roles?: { role: string; label: string }[]
  onDelete?: (user: User) => void
  onSelect?: (user: User) => void
  readOnly?: boolean
}

export const ResourceUsers: React.FC<ResourceUsersProps> = ({ users, roles, onDelete, onSelect, readOnly }) => {
  const { t } = useTranslation()
  const rights = useRights()

  const disciplines = useMemo(() => getDisciplines(t).map((d) => d.discipline), [t])

  const [height, setHeight] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)

  const resize = useCallback(() => {
    if (!containerRef.current) return
    const r = containerRef.current.getBoundingClientRect()
    setHeight(window.innerHeight - r.top)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  const expertTypes = useMemo<string[]>(() => [t(`General.ExpertTypesAlt.FTE`), t(`General.ExpertTypesAlt.Contractor`)], [t])

  const readyUsers = useMemo(() => users?.filter((u) => !!u.givenName), [users, roles])

  if (!readyUsers) return null

  return (
    <div className="ui-resource-users">
      <div className="ag-theme-cc" style={{ height, width: '100%', overflow: 'hidden' }} ref={containerRef} onLoad={() => setTimeout(() => resize())}>
        <AgGridReact
          defaultColDef={{
            width: 150,
            minWidth: 150,
            editable: false,
            resizable: true,
            sortable: true,
            sortingOrder: ['asc', 'desc']
          }}
          frameworkComponents={{
            nameCell: NameCell,
            roleCell: RoleCell,
            actionCell: ActionCell,
            noRowsOverlay: NoRowsOverlay,
            detailsCell: DetailsCell
          }}
          detailCellRenderer="detailsCell"
          detailRowHeight={240}
          masterDetail
          rowData={readyUsers}
          rowClassRules={{
            'ag-row-inactive': ({ data }: RowClassParams) => !!data.isInherited || !!data.isLower
          }}
          // animateRows
          rowSelection="single"
          suppressCellSelection
          suppressRowHoverHighlight
          suppressRowClickSelection
          noRowsOverlayComponent="noRowsOverlay"
          onCellClicked={({ column, node }) => {
            if (column.getColId() === 'name') node.setExpanded(!node.expanded)
          }}
          onSortChanged={() => {}}
          onGridReady={() => {
            resize()
          }}
        >
          <AgGridColumn
            field="name"
            headerName={t('General.Name')}
            cellRenderer="agGroupCellRenderer"
            cellRendererParams={{ innerRenderer: 'nameCell' }}
            valueGetter={({ data }: any) => `${data.givenName} ${data.familyName}${data.isInherited ? ' I' : ''}`}
            filter="agTextColumnFilter"
            filterParams={{
              filterOptions: ['contains', 'startsWith', 'endsWith'],
              defaultOption: 'contains',
              suppressAndOrCondition: true
            }}
            minWidth={150}
            width={2000}
            flex={1}
          />

          {rights?.userType === UserType.provider && (
            <AgGridColumn
              field="location"
              headerName={t('General.Location')}
              valueGetter={({ data }: any) => {
                const items: string[] = []
                if (data.personalAddress) {
                  if (data.personalAddress.municipality) items.push(data.personalAddress.municipality)
                  if (data.personalAddress.countrySubdivision) items.push(data.personalAddress.countrySubdivision)
                  else if (data.personalAddress.countrySubdivisionName) items.push(data.personalAddress.countrySubdivisionName)
                }
                return items.join(', ')
              }}
              filter="agTextColumnFilter"
              filterParams={{
                filterOptions: ['contains', 'startsWith', 'endsWith'],
                defaultOption: 'contains',
                suppressAndOrCondition: true
              }}
              minWidth={150}
              width={200}
              flex={0.75}
            />
          )}

          {rights?.userType === UserType.provider && (
            <AgGridColumn
              field="expertType"
              headerName={t('General.ExpertTypeAlt')}
              valueGetter={({ data }: any) => data.expertType}
              cellRenderer={({ data }: any) => (data.expertType ? t(`General.ExpertTypesAlt.${data.expertType}`) : '')}
              filter="agSetColumnFilter"
              filterParams={{
                values: expertTypes,
                suppressSorting: true,
                suppressMiniFilter: true,
                defaultToNothingSelected: true
              }}
              minWidth={150}
              width={150}
            />
          )}

          {rights?.userType === UserType.provider && (
            <AgGridColumn
              field="hourlyRate"
              headerName={t('General.HourlyRate')}
              valueGetter={({ data }: any) => ((data.hourlyRate || 0) > 0 ? t('General.Currency.Value', { value: data.hourlyRate }) : '')}
              type="rightAligned"
              filter="agTextColumnFilter"
              filterParams={{
                filterOptions: ['contains', 'startsWith', 'endsWith'],
                defaultOption: 'contains',
                suppressAndOrCondition: true
              }}
              minWidth={150}
              width={150}
            />
          )}
          {rights?.userType === UserType.provider && (
            <AgGridColumn
              field="discipline"
              headerName={t('General.Discipline')}
              valueGetter={({ data }: any) => {
                if (data.disciplines?.length) {
                  if (data.disciplines.length === 1) return data.disciplines[0]
                  if (data.disciplines.length === 2) return `${data.disciplines[0]} and ${data.disciplines[1]}`

                  const d = [...data.disciplines]
                  const last = d[d.length - 1]
                  const rest = d.splice(0, d.length - 1)
                  return `${rest.join(', ')}, and ${last}`
                }
                return data.primaryDiscipline ? t(`General.Disciplines.${data.primaryDiscipline}`) : ''
              }}
              filter="agSetColumnFilter"
              filterParams={{
                values: disciplines,
                suppressSorting: true,
                suppressMiniFilter: true,
                defaultToNothingSelected: true
              }}
              minWidth={150}
              width={150}
              flex={0.75}
            />
          )}

          <AgGridColumn
            field="role"
            headerName={t('General.Role')}
            filter="agSetColumnFilter"
            filterParams={{
              values: roles?.map((r) => r.role),
              cellRenderer: (p: ICellRendererParams) => {
                if (p.value.startsWith('(')) return p.value
                return roles?.find((r) => r.role === p.value)?.label || ''
              },
              suppressSorting: true,
              suppressMiniFilter: true,
              defaultToNothingSelected: true
            }}
            minWidth={150}
            width={220}
            cellRenderer="roleCell"
          />

          {(onDelete || onSelect) && (
            <AgGridColumn
              field="action"
              headerName=""
              resizable={false}
              sortable={false}
              filter={false}
              suppressMenu
              suppressMovable
              minWidth={80}
              maxWidth={80}
              cellRenderer="actionCell"
              cellRendererParams={{ onDelete, onEdit: onSelect, readOnly }}
            />
          )}
        </AgGridReact>
      </div>
    </div>
  )
}
