import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Account, AccountType } from 'services/src/dto/account'
import docWithCheck from 'components/src/img/docWithCheck.png'
import { makeDashboardPath } from 'services/src/dom'

export interface NextStepsProps {
  account?: Account
}

export const NextSteps: React.FC<NextStepsProps> = ({ account }) => {
  const { t } = useTranslation()


  console.log(account)
  if (!account) return null;

  if (account.type === AccountType.Provider)
    return (
      <>
        <h3 className="intro">{t('Onboarding.Provider.NextSteps')}</h3>
        <hr />
        <br />

        <div className="ui-text-center">
          <img alt="" src={docWithCheck} style={{ width: 200 }} />

          <p style={{ width: '75%', maxWidth: 300, margin: '0 auto' }}>{t('Onboarding.Provider.AccountCreated')}</p>
        </div>
      </>
    )

  return (
    <>
      <h3 className="intro">{t('Onboarding.NextSteps')}</h3>
      <hr />
      <br />

      <div className="ui-text-center">
        <img alt="" src={docWithCheck} style={{ width: 200 }} />

        <p style={{ width: '75%', maxWidth: 300, margin: '0 auto' }}>{t('Onboarding.AccountCreated')}</p>
      </div>
      <br />
      <br />

      <div className="ui-row" style={{ margin: '0 auto', maxWidth: 500 }}>
        <div className="ui-col-12 ui-col-lg-6 ui-form-group ui-flex" style={{ justifyContent: 'center' }}>
          <Link to={makeDashboardPath(account?.id, '')} replace className="ui-btn ui-btn-solid ui-btn-primary" style={{ width: '100%' }}>
            {t('Onboarding.Dashboard')}
          </Link>
        </div>
        <div className="ui-col-12 ui-col-lg-6 ui-form-group ui-flex" style={{ justifyContent: 'center' }}>
          <Link to={makeDashboardPath(account?.id, 'projects/new')} replace className="ui-btn ui-btn-solid ui-btn-secondary" style={{ width: '100%' }}>
            {t('Onboarding.CreateProject')}
          </Link>
        </div>
      </div>
    </>
  )
}
