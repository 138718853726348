import React, { useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDotCircle, faCircle } from '@fortawesome/pro-regular-svg-icons'
import { BudgetRange } from 'services/src/dto/projectSupport'

import './style.scss'
import { Select } from '../select'
import { CurrencyInput } from '../currencyInput'

export interface BudgetFieldProps {
  isFixed: boolean
  onTypeChange: (isFixed: boolean) => void
  name: string
  onChange: (value: { isFixed: true; fixed: number } | { isFixed: false; range: BudgetRange }) => void
  placeholder?: string
  disabled?: boolean
  fixed?: number
  range?: BudgetRange
  label?: string | JSX.Element
  error?: string | JSX.Element
  budgetRanges: BudgetRange[]
}

const formatFixedValue = (isFixed: boolean, fixed: number | undefined, t: TFunction) => {
  if (isFixed && fixed) return t('General.Currency.Value', { value: fixed })
  return ''
}

export const BudgetField: React.FC<BudgetFieldProps> = ({ isFixed, onTypeChange, name, disabled, fixed, range, onChange, placeholder, label, error, budgetRanges }) => {
  const { t } = useTranslation()

  const [, setFixedValue] = useState(formatFixedValue(isFixed, fixed, t))

  return (
    <div className={`ui-form-group ${error ? 'ui-has-error' : ''}`}>
      {label && <label htmlFor={name}>{label}</label>}

      <div className={`ui-budget-field${disabled ? ' ui-disabled' : ''}`}>
        <div className="ui-budget-field-type">
          <div
            role="radio"
            aria-checked={!isFixed}
            tabIndex={-1}
            onKeyDown={() => {}}
            onClick={() => {
              if (disabled) return
              setFixedValue('')
              onTypeChange(false)
            }}
          >
            <div>
              <FontAwesomeIcon icon={!isFixed ? faDotCircle : faCircle} />
            </div>
            <div>{t('General.Currency.Range')}</div>
          </div>
          <div
            role="radio"
            aria-checked={isFixed}
            tabIndex={-1}
            onKeyDown={() => {}}
            onClick={() => {
              setFixedValue('')
              onTypeChange(true)
            }}
          >
            <div>
              <FontAwesomeIcon icon={isFixed ? faDotCircle : faCircle} />
            </div>
            <div>{t('General.Currency.Fixed')}</div>
          </div>
        </div>

        <div className="ui-budget-field-value">
          {isFixed ? (
            <CurrencyInput name={name} disabled={disabled} amount={fixed ? fixed.toFixed(2) : ''} placeholder={placeholder} onChange={(fixed) => onChange({ isFixed: true, fixed: fixed || 0 })} />
          ) : (
            <Select
              items={budgetRanges}
              itemValue={(i) => i.range.replace(/[$<>]/g, '')}
              selected={range}
              disabled={disabled}
              onSelectedChange={(range) => {
                onChange({ isFixed: false, range })
              }}
              placeholder={placeholder}
              labelField="range"
              valueField="id"
            />
          )}
        </div>
        <div className={`ui-budget-field-money${disabled ? ' ui-disabled' : ''}`}>{t('General.Currency.Ident')}</div>
      </div>

      {error && <div className="ui-error">{error}</div>}
    </div>
  )
}
