import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare, faBars } from '@fortawesome/pro-regular-svg-icons'

import './style.scss'
import { PhaseEx, useEditProject, useRights, UserType, useUserAccounts } from 'services/src/state'
import { AccountLogo } from 'components/src/accountLogo'
import { createProEst, uploadAccountLogo } from 'services/src/api'
import { AccountSelector } from 'components/src/accountSelector'
import { UserMenu } from 'components/src/userMenu'
import { useAlert } from 'components/src/alerts'
import { useSaving } from 'components/src/saving'

interface MainHeaderProps {
  desktop?: boolean
  onToggleNav: () => void
  onToggleAltNav: () => void
  subheader?: JSX.Element
  headerRef?: React.ForwardedRef<any>
}

export const MainHeader: React.FC<MainHeaderProps> = ({ desktop, onToggleNav, onToggleAltNav, subheader, headerRef }) => {
  const { t } = useTranslation()
  const [, refreshAccounts] = useUserAccounts()
  const { alertDanger } = useAlert()

  const { pathname } = useLocation()
  const rights = useRights()
  const { project } = useEditProject()
  const [, setSaving] = useSaving()

  const phase = useMemo(() => {
    if (!project?.phases) return undefined

    let phase: PhaseEx | undefined
    const segments = pathname.split('/')
    let idx = segments.findIndex((x) => x === 'phases')
    if (idx > 0) {
      idx++
      if (idx < segments.length) {
        const phaseId = segments[idx].toLowerCase()
        phase = project.phases.find((x) => x.id?.toLowerCase() === phaseId)
      }
    }

    return phase
  }, [project, pathname])

  const proEstBtn = useMemo(() => {
    if (rights?.userType === UserType.client || !rights?.estimateApps.includes('ProEst')) return null

    /*
    https://cloud.proest.com/app/home
    https://cloud.proest.com/projects/797845/estimate
    https://cloud.proest.com/app/projects
    */

    if (!phase) {
      if (rights?.userType === UserType.expert) return null

      return (
        <a href="https://cloud.proest.com/app/projects" target="proEst" style={{ marginRight: 20, paddingLeft: 20, paddingRight: 20 }} className="ui-btn ui-btn-info ui-btn-solid ui-btn-sm">
          {t('Projects.EstimateCenter')}&nbsp;&nbsp;
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      )
    }

    if (phase.proEst)
      return (
        <a
          href={`https://cloud.proest.com/projects/${phase.proEst.id}/estimate`}
          target="proEst"
          style={{ marginRight: 20, paddingLeft: 20, paddingRight: 20 }}
          className="ui-btn ui-btn-info ui-btn-solid ui-btn-sm"
        >
          {t('Projects.EstimateCenter')}&nbsp;&nbsp;
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      )

    if (rights?.userType !== UserType.provider) return null

    return (
      <a
        href="https://cloud.proest.com/app/projects"
        target="proEst"
        style={{ marginRight: 20, paddingLeft: 20, paddingRight: 20 }}
        className="ui-btn ui-btn-info ui-btn-solid ui-btn-sm"
        onClick={(e) => {
          e.preventDefault()

          setSaving(true)
          createProEst(project.account.id, project.id!, phase.id!)
            .then(({ estimate }) => {
              if (estimate.id) window.open(`https://cloud.proest.com/projects/${estimate.id}/estimate`, 'proEst')
            })
            .finally(() => setSaving(false))
        }}
      >
        {t('Projects.EstimateCenter')}&nbsp;&nbsp;
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
      </a>
    )
  }, [phase, rights])

  return (
    <header className={`main-header ${desktop ? 'desktop' : ''}`} ref={headerRef}>
      {!desktop && (
        <button className="nav-toggle nav-toggle-left" type="button" tabIndex={-1} onClick={() => onToggleNav()}>
          <FontAwesomeIcon icon={faBars} />
        </button>
      )}
      {!desktop && (
        <button className="nav-toggle nav-toggle-right" type="button" tabIndex={-1} onClick={() => onToggleAltNav()}>
          <FontAwesomeIcon icon={faBars} />
        </button>
      )}
      <div className="main-header-content">
        <div className="main-header-content-top">
          <AccountLogo
            onUpload={(accountId: string, file) => {
              uploadAccountLogo(accountId, file)
                .then(() => refreshAccounts())
                .catch((err: any) => {
                  alertDanger({
                    title: t('General.Errors.UploadAccountLogoTitle'),
                    message: t('General.Errors.UploadAccountLogo'),
                    error: err
                  })
                })
            }}
          />
          <AccountSelector />
          {proEstBtn}
          <UserMenu />
        </div>
        {subheader && <div className="main-header-content-bottom">{subheader}</div>}
      </div>
    </header>
  )
}
