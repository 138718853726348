import React, { useRef } from 'react'
import './style.scss'
import { useCurrentUserAccount, useRights } from 'services/src/state'
import { AccountType } from 'services/src/dto/account'

export const AccountLogo: React.FC<{
  onUpload?: (accountId: string, file: File) => void
}> = ({ onUpload }) => {
  const [account] = useCurrentUserAccount()
  const rights = useRights()

  const inputRef = useRef<HTMLInputElement>(null)

  if (!account || account.type === AccountType.Expert) return null

  return (
    <div
      className={`ui-account-logo${rights?.canEditAccount ? ' ui-account-logo-can-change' : ''}${account.logoUrl ? ' ui-account-logo-has-logo' : ''}`}
      role="button"
      tabIndex={-1}
      onKeyDown={() => {}}
      onClick={() => {
        if (!rights?.canEditAccount || !inputRef.current) return
        inputRef.current.click()
      }}
    >
      {account.logoUrl && <img alt="" src={account.logoUrl} style={{ width: '100%' }} />}
      {!account.logoUrl && <div className="ui-text-muted">Logo</div>}
      {onUpload && rights?.canEditAccount && (
        <input
          type="file"
          ref={inputRef}
          onChange={(e) => {
            if (!onUpload || !e.target.files?.length) return
            onUpload(account.id, e.target.files[0])
          }}
          accept="image/png,image/jpeg,image/webp"
          maxLength={1024 * 1024 * 10}
          style={{ display: 'none' }}
        />
      )}
    </div>
  )
}
