import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostRequests } from 'services/src/api/boe';
import { endpoints } from 'services/src/lib/axios';
import { Loading } from '../../../loading';

// Set the workerSrc property
GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`;

interface BoeProjectFinalProps {
    handleNext: () => void;
    handlePrevious: () => void;
    boeData: any;
}

const BoeProjectFinal: React.FC<BoeProjectFinalProps> = ({
    handleNext,
    handlePrevious,
    boeData
}) => {
    const { t } = useTranslation();
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const { data, postRequests, loading } = usePostRequests();
    const [pdfUrl, setPdfUrl] = React.useState<string | null>(null);

    useEffect(() => {
        if (Object.keys(boeData).length !== 0) {
            postRequests(endpoints.boe.generateBasisOfEstimate, boeData);
        }
    }, [boeData, postRequests]);

    useEffect(() => {
        if (data?.response) {
            setPdfUrl(data?.response);
        }
    }, [data?.response]);

    const handleNextClick = () => {
        handleNext();
    };

    return (
        <div className="boe-content">
            <div className="boe-form-content">
                {loading && <Loading />}
                <div style={{ height: '500px' }}>
                    {pdfUrl && (
                       // @ts-ignore
                        <Viewer
                            fileUrl={pdfUrl}
                            plugins={[
                                // Register plugins
                                defaultLayoutPluginInstance
                            ]}
                        />
                    )}
                </div>
            </div>
            <div className="boe-form-action-container">
                <button type="button" className="btn-next" onClick={handlePrevious}>
                    {t('General.Previous')}
                </button>
                <button type="button" className="btn-previous" onClick={handleNextClick}>
                    {t('General.Finish')}
                </button>
            </div>
        </div>
    );
};

export default BoeProjectFinal;