import React from 'react'

export interface SubHeaderProps {
  ready?: boolean
  title: string
  subTitle: string
  nextTitle?: string
  nextSubTitle?: string
}

const Header: React.FC<SubHeaderProps> = ({ ready, title, subTitle, nextTitle, nextSubTitle }) => (
  <div className="ui-row new-project-subheader">
    {nextTitle ? (
      <>
        <div className="ui-col-6">
          <h1>{ready ? title : <>&nbsp;</>}</h1>
          <h2 className="ui-text-muted">{ready ? subTitle : <>&nbsp;</>}</h2>
        </div>
        <div className="ui-col-6 ui-text-right">
          <h1>{ready ? nextTitle : <>&nbsp;</>}</h1>
          <h2 className="ui-text-muted">{ready ? nextSubTitle : <>&nbsp;</>}</h2>
        </div>
      </>
    ) : (
      <div className="ui-col-12">
        <h1>{ready ? title : <>&nbsp;</>}</h1>
        <h2 className="ui-text-muted">{ready ? subTitle : <>&nbsp;</>}</h2>
      </div>
    )}
  </div>
)
export default Header
