.error-page .error-page-code {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  opacity: 0.25;
  margin-top: 80px;
}
.error-page .error-page-text {
  margin: 20px 0 120px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9wcm9qZWN0L3BhY2thZ2VzL2NvbXBvbmVudHMvc3JjL3BhZ2VzL2Vycm9yL3N0eWxlLnNjc3MiLCIlM0NpbnB1dCUyMGNzcyUyMGNDR000NSUzRSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFHRTtFQUNFLGtCQUFBO0VBQ0EsZUFBQTtFQUNBLGlCQUFBO0VBQ0EsYUFBQTtFQUNBLGdCQUFBO0FDRko7QURJRTtFQUNFLG9CQUFBO0FDRkoiLCJmaWxlIjoidG8uY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAnfnN0eWxlL3NldHRpbmdzJztcblxuLmVycm9yLXBhZ2Uge1xuICAuZXJyb3ItcGFnZS1jb2RlIHtcbiAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gICAgZm9udC1zaXplOiA0MHB4O1xuICAgIGZvbnQtd2VpZ2h0OiBib2xkO1xuICAgIG9wYWNpdHk6IDAuMjU7XG4gICAgbWFyZ2luLXRvcDogODBweDtcbiAgfVxuICAuZXJyb3ItcGFnZS10ZXh0IHtcbiAgICBtYXJnaW46IDIwcHggMCAxMjBweDtcbiAgfVxufVxuIiwiLmVycm9yLXBhZ2UgLmVycm9yLXBhZ2UtY29kZSB7XG4gIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgZm9udC1zaXplOiA0MHB4O1xuICBmb250LXdlaWdodDogYm9sZDtcbiAgb3BhY2l0eTogMC4yNTtcbiAgbWFyZ2luLXRvcDogODBweDtcbn1cbi5lcnJvci1wYWdlIC5lcnJvci1wYWdlLXRleHQge1xuICBtYXJnaW46IDIwcHggMCAxMjBweDtcbn1cbi8qIyBzb3VyY2VNYXBwaW5nVVJMPWRhdGE6YXBwbGljYXRpb24vanNvbjtjaGFyc2V0PXV0Zi04O2Jhc2U2NCxleUoyWlhKemFXOXVJam96TENKemIzVnlZMlZTYjI5MElqb2lMM0J5YjJwbFkzUXZjR0ZqYTJGblpYTXZZMjl0Y0c5dVpXNTBjeTl6Y21NdmNHRm5aWE12WlhKeWIzSWlMQ0p6YjNWeVkyVnpJanBiSW5OMGVXeGxMbk5qYzNNaVhTd2libUZ0WlhNaU9sdGRMQ0p0WVhCd2FXNW5jeUk2SWtGQlIwVTdSVUZEUlR0RlFVTkJPMFZCUTBFN1JVRkRRVHRGUVVOQk96dEJRVVZHTzBWQlEwVWlMQ0p6YjNWeVkyVnpRMjl1ZEdWdWRDSTZXeUpBYVcxd2IzSjBJQ2QrYzNSNWJHVXZjMlYwZEdsdVozTW5PMXh1WEc0dVpYSnliM0l0Y0dGblpTQjdYRzRnSUM1bGNuSnZjaTF3WVdkbExXTnZaR1VnZTF4dUlDQWdJSFJsZUhRdFlXeHBaMjQ2SUdObGJuUmxjanRjYmlBZ0lDQm1iMjUwTFhOcGVtVTZJRFF3Y0hnN1hHNGdJQ0FnWm05dWRDMTNaV2xuYUhRNklHSnZiR1E3WEc0Z0lDQWdiM0JoWTJsMGVUb2dNQzR5TlR0Y2JpQWdJQ0J0WVhKbmFXNHRkRzl3T2lBNE1IQjRPMXh1SUNCOVhHNGdJQzVsY25KdmNpMXdZV2RsTFhSbGVIUWdlMXh1SUNBZ0lHMWhjbWRwYmpvZ01qQndlQ0F3SURFeU1IQjRPMXh1SUNCOVhHNTlYRzRpWFgwPSAqLyJdfQ== */