import { faLightbulbOn } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface BoeProjectSectionSubheaderProps {
    title: string;
    dynamicText: string;
    boeData: any;
  }

export const BoeProjectSectionSubheader: React.FC<BoeProjectSectionSubheaderProps> = ({ title, dynamicText }) => (
    <div className="boe-content-flex-between">
      <h2 className="boe-section-header">{title}</h2>
      <div className='boe-flex-row'>
        <div className="boe-section-header-right-content">
          <FontAwesomeIcon icon={faLightbulbOn} />
          <p>{dynamicText}</p>
        </div>
      </div>
    </div>
  )