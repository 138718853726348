import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment, { Moment } from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import './style.scss'
import ReactDOM from 'react-dom'
import { useOverlayControl } from 'services/src/state'

import { DESKTOP_SIZE } from '../constants'

export interface DatePickerMarker {
  name?: string
  start?: string
  startLabel?: string
  end?: string
  endLabel?: string
  color?: string
}
export interface DatePickerProps {
  title?: string | JSX.Element
  start?: string
  end?: string
  max?: string
  min?: string
  initDate?: string
  selectRange?: boolean
  noHighlight?: boolean
  maxMonths?: number
  onSelectDate?: (start: Moment, end?: Moment) => void
  className?: string
  style?: React.CSSProperties
  onClose?: () => void
  markers?: DatePickerMarker[]
  forwardRef?: React.ForwardedRef<HTMLDivElement>
}

interface GridDate {
  date: string
  inMonth: boolean
  isToday: boolean
  isStart: boolean
  isEnd: boolean
  inRange: boolean
  canSelect: boolean
  markerStart?: DatePickerMarker
  markerEnd?: DatePickerMarker
  inMarker?: DatePickerMarker
}
interface GridWeek {
  dates: GridDate[]
}
interface GridPanel {
  month: Moment
  weeks: GridWeek[]
}
interface Grid {
  panels: GridPanel[]
}

enum Which {
  start,
  end
}

type DatePickerMarkerWithLoc = DatePickerMarker & {
  left: number
  top: number
}

const DatePickerMarkerPopup: React.FC<{ marker?: DatePickerMarkerWithLoc }> = ({ marker }) => {
  const [elm] = useState(document.createElement('div'))
  React.useEffect(() => {
    document.body.appendChild(elm)
    return () => {
      document.body.removeChild(elm)
    }
  }, [elm])

  if (!marker || !marker.name || marker.left < 0 || marker.top < 0) return null

  return ReactDOM.createPortal(
    <div className="ui-date-picker-marker-popup" style={{ left: marker.left, top: marker.top }}>
      <h3>{marker.name}</h3>
      {marker.start && <div>{marker.startLabel ? `${marker.startLabel}: ${moment(marker.start).format('ll')}` : moment(marker.start).format('ll')}</div>}
      {marker.end && <div>{marker.endLabel ? `${marker.endLabel}: ${moment(marker.end).format('ll')}` : moment(marker.end).format('ll')}</div>}
    </div>,
    elm
  )
}

const TheDatePicker: React.FC<DatePickerProps> = ({
  title,
  start,
  end,
  min,
  max,
  initDate,
  onSelectDate,
  selectRange = true,
  noHighlight = false,
  maxMonths = 2,
  className,
  style,
  onClose,
  markers,
  forwardRef
}) => {
  const { t } = useTranslation()
  const { addOverlay, removeOverlay } = useOverlayControl()

  const [isDesktop, setIsDesktop] = useState(document.body.clientWidth >= DESKTOP_SIZE)

  useEffect(() => {
    const resize = () => setIsDesktop(document.body.clientWidth >= DESKTOP_SIZE)
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [setIsDesktop])

  useEffect(() => {
    addOverlay(TheDatePicker.name)
    return () => {
      removeOverlay(TheDatePicker.name)
    }
  }, [addOverlay, removeOverlay])

  const [startMonth, setStartMonth] = useState(moment(initDate || start || min))
  const [which, setWhich] = useState<Which>(Which.start)

  const [markerPopup, setMarkerPopup] = useState<DatePickerMarkerWithLoc>()

  const grid = useMemo<Grid>(() => {
    const canSelect = (date: Moment) => {
      if (min && date.isBefore(min, 'date')) return false
      return !(max && date.isAfter(max, 'date'))
    }
    const aMonth = moment(startMonth).startOf('month')

    const panelCnt = isDesktop ? maxMonths : 1

    const grid: Grid = { panels: [] }
    for (let m = 0; m < panelCnt; m++) {
      const date = moment(aMonth).startOf('week')

      const panel: GridPanel = { month: moment(aMonth), weeks: [] }
      for (let w = 0; w < 6; w++) {
        const week: GridWeek = { dates: [] }
        for (let d = 0; d < 7; d++) {
          let markerStart: DatePickerMarker | undefined
          let markerEnd: DatePickerMarker | undefined
          let inMarker: DatePickerMarker | undefined

          if (markers) {
            markerStart = markers.find((m) => m.start && date.isSame(moment(m.start), 'date'))
            markerEnd = markers.find((m) => m.end && date.isSame(moment(m.end), 'date'))
            inMarker = markers.find((m) => m.start && m.end && date.isSameOrAfter(moment(m.start), 'date') && date.isSameOrBefore(moment(m.end), 'date'))
          }

          week.dates.push({
            date: moment(date).format(),
            inMonth: aMonth.month() === date.month(),
            isToday: date.isSame(moment(), 'date'),
            isStart: start ? date.isSame(start, 'date') : false,
            isEnd: end ? date.isSame(end, 'date') : false,
            inRange: selectRange && start && end ? date.isBetween(start, end, 'date', '[]') : false,
            canSelect: canSelect(date),
            markerStart,
            markerEnd,
            inMarker
          })
          date.add(1, 'day')
        }
        panel.weeks.push(week)
      }
      grid.panels.push(panel)
      aMonth.add(1, 'month')
    }
    return grid
  }, [start, end, min, max, startMonth, isDesktop, markers, maxMonths, selectRange])

  const dateClick = useCallback(
    (date: GridDate) => {
      if (!onSelectDate || !date.canSelect) return

      if (!selectRange) {
        onSelectDate(moment(date.date))
        return
      }

      if (which === Which.start) {
        if (end && moment(end).isAfter(moment(date.date))) onSelectDate(moment(date.date), moment(end))
        else onSelectDate(moment(date.date))
        setWhich(Which.end)
      } else {
        if (moment(date.date).isBefore(moment(start))) onSelectDate(moment(start))
        else onSelectDate(moment(start), moment(date.date))
        setWhich(Which.start)
      }
    },
    [onSelectDate, selectRange, start, end, which, setWhich]
  )

  return (
    <div
      className={`ui-date-picker ${className || ''}`}
      style={style}
      tabIndex={0}
      role="button"
      ref={forwardRef}
      onBlur={() => {
        if (onClose) onClose()
      }}
    >
      {grid.panels.map((panel, idx) => (
        <div key={idx} className="ui-date-picker-panel">
          <div className="ui-date-picker-panel-header">
            <div
              className="prev"
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
                  e.preventDefault()
                  setStartMonth(moment(startMonth).add(1, 'month'))
                }
              }}
              onMouseDown={(e) => {
                if (e.button === 0) {
                  e.preventDefault()
                  setStartMonth(moment(startMonth).add(-1, 'month'))
                }
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <div className="month">
              {title && <div>{title}</div>}
              <div>{panel.month.format('MMMM, YYYY')}</div>
            </div>
            <div
              className="next"
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
                  e.preventDefault()
                  setStartMonth(moment(startMonth).add(1, 'month'))
                }
              }}
              onMouseDown={(e) => {
                if (e.button === 0) {
                  e.preventDefault()
                  setStartMonth(moment(startMonth).add(1, 'month'))
                }
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
          <div className="ui-date-picker-week-header">
            {moment.weekdaysShort().map((wd, idx) => (
              <div key={idx}>{wd}</div>
            ))}
          </div>
          {panel.weeks.map((week, idx) => (
            <div key={idx} className="ui-date-picker-week">
              {week.dates.map((date, idx) => {
                const classNames: string[] = ['ui-date-picker-date']
                if (date.inMonth) classNames.push('in-month')
                if (date.isToday) classNames.push('in-today')
                if (!date.canSelect) classNames.push('no-select')
                if (!noHighlight) {
                  if (date.inRange) classNames.push('in-range')
                  if (date.isStart) classNames.push('is-start')
                  if (date.isEnd) classNames.push('is-end')
                }

                return (
                  <div
                    key={idx}
                    className={classNames.join(' ')}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
                        e.preventDefault()
                        dateClick(date)
                      }
                    }}
                    onMouseDown={(e) => {
                      if (e.button === 0) {
                        e.preventDefault()
                        dateClick(date)
                      }
                    }}
                  >
                    <div className="ui-date-picker-date-cell">{moment(date.date).date()}</div>

                    {date.inMarker && (
                      <div
                        className={`ui-date-picker-marker ui-date-picker-marker-in${
                          date.inMarker.start && moment(date.inMarker.start).isSame(moment(date.date), 'date') ? ' ui-date-picker-marker-start' : ''
                        }${date.inMarker.end && moment(date.inMarker.end).isSame(moment(date.date), 'date') ? ' ui-date-picker-marker-end' : ''}`}
                        style={{ background: date.inMarker.color }}
                        onMouseMove={(e) => {
                          if (!markerPopup) return
                          setMarkerPopup({ ...markerPopup, left: e.clientX, top: e.clientY })
                        }}
                        onMouseEnter={(e) => {
                          if (!date.inMarker?.name) return
                          setMarkerPopup({ ...date.inMarker, left: e.clientX, top: e.clientY })
                        }}
                        onMouseLeave={() => {
                          setMarkerPopup(undefined)
                        }}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          ))}
          <div className="ui-date-picker-panel-footer">
            <div
              onClick={() => setStartMonth(moment())}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
                  e.preventDefault()
                  setStartMonth(moment())
                }
              }}
              onMouseDown={(e) => {
                if (e.button === 0) {
                  e.preventDefault()
                  setStartMonth(moment())
                }
              }}
            >
              {t('General.Today')}
            </div>
          </div>

          <DatePickerMarkerPopup marker={markerPopup} />
        </div>
      ))}
    </div>
  )
}

export const DatePicker = React.forwardRef<any, DatePickerProps>((props, ref) => <TheDatePicker forwardRef={ref} {...props} />)
