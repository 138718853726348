import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import './style.scss'
import { useCurrentUserAccount, useCurrentUserOffice, useRights, useUserAccounts, useUserOffices } from 'services/src/state'
import { Loading } from 'components/src/loading'
import { setTitle } from 'services/src/dom'
import { CreateInvitation } from 'services/src/dto/invitation'
import { createInvitation } from 'services/src/api'
import { useSaving } from 'components/src/saving'
import { useAlert } from 'components/src/alerts'

import { OfficeTable } from './OfficeTable'
import { OfficeListSubheader } from './OfficeListSubheader'
import { InviteModal } from '../edit/InviteModal'

export const OfficeList: React.FC = () => {
  const { t } = useTranslation()
  const [, refreshAccounts] = useUserAccounts()
  const [account] = useCurrentUserAccount()
  const [offices, refreshOffices] = useUserOffices()
  const [office] = useCurrentUserOffice()
  const rights = useRights()
  const [, setSaving] = useSaving()
  const { alertSuccess, alertDanger } = useAlert()

  useEffect(() => {
    setTitle(t('Offices.TitleAlt'))
    return () => {
      setTitle()
    }
  }, [t])

  const [invite, setInvite] = useState(false)

  const handleSendInvitation = useCallback(
    (invitation: CreateInvitation) => {
      setInvite(false)
      setSaving(true)
      createInvitation(account!.id, invitation)
        .then(() => refreshAccounts())
        .then(() => {
          alertSuccess({
            title: t('EditOffice.InviteCreateTitle'),
            message: t('EditOffice.InviteCreate', {
              name: `${invitation.givenName} ${invitation.familyName}`,
              email: invitation.emailAddress
            })
          })
        })
        .catch((err) => {
          if (err.message.includes('409')) {
            alertDanger({
              title: t('Settings.Errors.DuplicateInviteTitle'),
              message: t('Settings.Errors.DuplicateInvite', {
                name: `${invitation.givenName} ${invitation.familyName}`,
                email: invitation.emailAddress
              }),
              error: err
            })
            return
          }
          alertDanger({
            title: t('EditOffice.Errors.InviteCreateTitle'),
            message: t('EditOffice.Errors.InviteCreate'),
            error: err
          })
        })
        .finally(() => setTimeout(() => setSaving(false)))
    },
    [account, setInvite, setSaving, alertSuccess, alertDanger, refreshOffices]
  )

  if (!account) return <Loading size="sm" variant="parent" />

  return (
    <>
      <OfficeListSubheader onInvite={rights?.canInviteToOffice ? () => setInvite(true) : undefined} />

      <div className="office-index" style={{ paddingBottom: 0 }}>
        <OfficeTable accountId={account.id} sortKey="officeSort" filterKey="officeFilter" onCreate={rights?.canCreateOffice ? () => {} : undefined} />
      </div>

      {invite && account && <InviteModal account={account} offices={offices || []} office={office} onSend={handleSendInvitation} onClose={() => setInvite(false)} />}
    </>
  )
}
