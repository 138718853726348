import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ResourceUserRole, User } from 'services/src/dto/account'
import { CreateInvitation, Invitation, InvitationStatus } from 'services/src/dto/invitation'
import { ResourceUsers, UserEx } from 'components/src/resourceUsers'

import { ConfirmModal } from 'components/src/modal/ConfirmModal'
import { InfoModal } from 'components/src/modal/InfoModal'
import { useCurrentAccountWithUser, useCurrentUserAccount, useMe, useRights, UserType } from 'services/src/state'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { createInvitation, deleteAccountUser, deleteInvitation, resendInvitation } from 'services/src/api'
import { InvitationList } from './InvitationList'
import { useAlert } from '../../alerts'
import { useSaving } from '../../saving'
import { InviteProviderModal } from './InviteProviderModal'
import { TabBar, TabItem } from '../../tabs'
import { ExpertProfile } from './expertProfile'
import { ProviderProfile } from './providerProfile'

export const ProviderStaff: React.FC = () => {
  const { t } = useTranslation()
  const [me] = useMe()
  const { url, path } = useRouteMatch()
  const { pathname } = useLocation()
  const history = useHistory()
  const [account] = useCurrentUserAccount()
  const [accountWithUsers, refreshUsers] = useCurrentAccountWithUser()
  const rights = useRights()
  const { alertSuccess, alertDanger } = useAlert()
  const [, setSaving] = useSaving()

  const [confirmDeleteUser, setConfirmDeleteUser] = useState<UserEx>()
  const [info, setInfo] = useState<{ title: string; message: string }>()
  const [invite, setInvite] = useState<boolean>(false)

  const roles = useMemo(
    () => [
      {
        role: ResourceUserRole.Owner,
        label: t(`General.ProviderRoles.${ResourceUserRole.Owner}`)
      },
      {
        role: ResourceUserRole.Administrator,
        label: t(`General.ProviderRoles.${ResourceUserRole.Administrator}`)
      },
      {
        role: ResourceUserRole.Contributor,
        label: t(`General.ProviderRoles.${ResourceUserRole.Contributor}`)
      },
      {
        role: ResourceUserRole.Guest,
        label: t(`General.ProviderRoles.${ResourceUserRole.Guest}`)
      }
    ],
    [t]
  )

  const readyUsers = useMemo<UserEx[] | undefined>(() => {
    if (!accountWithUsers) return undefined

    const users: UserEx[] = (accountWithUsers.users || []).map((x) => ({
      ...x,
      canEdit: true,
      roleLabel: t(`General.ProviderRoles.${x.role}`)
    }))
    return users
  }, [accountWithUsers])

  const deleteUser = useCallback(
    (user: User) => {
      setConfirmDeleteUser(undefined)
      if (!account || !user.id) return

      setSaving(true)
      deleteAccountUser(account.id, user.id)
        .then(() => refreshUsers())
        .then(() =>
          alertSuccess({
            title: t('Settings.DeletedTitle'),
            message: t('Settings.Deleted', { name: `${user.givenName} ${user.familyName}` })
          })
        )
        .catch((err) =>
          alertDanger({
            title: t('Settings.Errors.SaveTitle'),
            message: t('Settings.Errors.Save'),
            error: err
          })
        )
        .finally(() => setTimeout(() => setSaving(false)))
    },
    [account, setSaving, alertSuccess, alertDanger, refreshUsers]
  )

  const readyInvitations = useMemo(() => accountWithUsers?.invitations.filter((i) => !!i.providerRole && i.status === InvitationStatus.Pending), [accountWithUsers])

  const handleSendInvitation = useCallback(
    (invitation: CreateInvitation) => {
      setInvite(false)
      setSaving(true)
      createInvitation(account!.id, invitation)
        .then(() => refreshUsers())
        .then(() => {
          alertSuccess({
            title: t('Settings.InviteCreateTitle'),
            message: t('Settings.InviteCreate', {
              name: `${invitation.givenName} ${invitation.familyName}`,
              email: invitation.emailAddress
            })
          })
        })
        .catch((err) => {
          if (err.message.includes('409')) {
            alertDanger({
              title: t('Settings.Errors.DuplicateInviteTitle'),
              message: t('Settings.Errors.DuplicateInvite', {
                name: `${invitation.givenName} ${invitation.familyName}`,
                email: invitation.emailAddress
              }),
              error: err
            })
            return
          }
          alertDanger({
            title: t('Settings.Errors.InviteCreateTitle'),
            message: t('Settings.Errors.InviteCreate'),
            error: err
          })
        })
        .finally(() => setTimeout(() => setSaving(false)))
    },
    [account, setInvite, setSaving, alertSuccess, alertDanger, refreshUsers]
  )

  const handleDeleteInvitation = useCallback(
    (invitation: Invitation) => {
      if (!accountWithUsers) return

      setSaving(true)
      deleteInvitation(accountWithUsers.id, invitation.id)
        .then(() => refreshUsers())
        .then(() =>
          alertSuccess({
            title: t('Settings.DeletedInvitationTitle'),
            message: t('Settings.DeletedInvitation', {
              name: `${invitation.givenName} ${invitation.familyName}`,
              email: invitation.emailAddress
            })
          })
        )
        .catch((err) =>
          alertDanger({
            title: t('Settings.Errors.DeletedInvitationTitle'),
            message: t('Settings.Errors.DeletedInvitation'),
            error: err
          })
        )
        .finally(() => setTimeout(() => setSaving(false)))
    },
    [accountWithUsers, setSaving, alertSuccess, alertDanger, refreshUsers]
  )

  const handleResendInvitation = useCallback(
    (invitation: Invitation) => {
      if (!accountWithUsers) return

      setSaving(true)
      resendInvitation(accountWithUsers.id, invitation.id)
        .then(() => refreshUsers())
        .then(() =>
          alertSuccess({
            title: t('Settings.InvitationResentTitle'),
            message: t('Settings.InvitationResent', {
              name: `${invitation.givenName} ${invitation.familyName}`,
              email: invitation.emailAddress
            })
          })
        )
        .catch((err) =>
          alertDanger({
            title: t('Settings.Errors.InvitationResentTitle'),
            message: t('Settings.Errors.InvitationResent'),
            error: err
          })
        )
        .finally(() => setTimeout(() => setSaving(false)))
    },
    [accountWithUsers, setSaving, alertSuccess, alertDanger, refreshUsers]
  )

  const tabs = useMemo<TabItem[]>(() => {
    if (!me) return []

    return [
      {
        label: t('General.Staff'),
        selected: pathname.endsWith('staff'),
        to: url
      },
      {
        label: `${t('General.Invitations')}${readyInvitations?.length ? ` [${readyInvitations.length}]` : ''}`,
        to: `${url}/invitations`,
        selected: pathname.endsWith('invitations')
      }
    ]
  }, [pathname, t, readyInvitations])

  return (
    <>
      {(pathname.endsWith('/staff') || pathname.endsWith('/invitations')) && (
        <>
          <TabBar tabs={tabs} className="settings-tab-bar" onChange={() => {}} />
          <br />
        </>
      )}

      <Switch>
        <Route path={`${path}/invitations`}>
          {readyInvitations && <InvitationList invitations={readyInvitations} roles={roles} onNew={() => setInvite(true)} onDelete={handleDeleteInvitation} onResend={handleResendInvitation} />}
        </Route>

        <Route path={`${path}/users/:userId`}>
          {(rights?.userType === UserType.provider && rights?.canEditAccount) && <ProviderProfile />}
          {(rights?.userType !== UserType.provider && rights?.canEditAccount) && <ExpertProfile />}
        </Route>

        <Route path="*">
          <ResourceUsers
            users={readyUsers}
            roles={roles}
            onSelect={(user: User) => {
              history.push(`${url}/users/${user.id}`)
            }}
            onDelete={(user: User) => {
              if (!accountWithUsers) return

              if (user.role !== ResourceUserRole.Owner) {
                setConfirmDeleteUser(user)
                return
              }

              const owners = accountWithUsers.users.filter((x) => x.id && x.role === ResourceUserRole.Owner)
              if (owners.length > 1) {
                setConfirmDeleteUser(user)
                return
              }

              setInfo({
                title: t('Settings.DeleteProviderOwnerTitle'),
                message: t('Settings.DeleteProviderOwner')
              })
            }}
          />
        </Route>
      </Switch>

      {info && <InfoModal title={info.title} message={info.message} ok={t('General.Ok')} onClose={() => setInfo(undefined)} />}

      {account && invite && <InviteProviderModal account={account} onSend={handleSendInvitation} onClose={() => setInvite(false)} />}

      {confirmDeleteUser && (
        <ConfirmModal
          title={t('Settings.DeleteProviderPeopleTitle')}
          message={t('Settings.DeleteProviderPeople', {
            name: `${confirmDeleteUser.givenName} ${confirmDeleteUser.familyName}`
          })}
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={() => deleteUser(confirmDeleteUser)}
          onClose={() => setConfirmDeleteUser(undefined)}
        />
      )}
    </>
  )
}
