import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import Fuse from 'fuse.js'

import { Client } from 'services/src/dto/account'

import { AutoComplete } from 'components/src/autoComplete'
import { TextField } from 'components/src/textField'
import { TextAreaField } from 'components/src/textAreaField'
import { BudgetField } from 'components/src/budgetField'
import { SelectField } from 'components/src/selectField'
import { getClients, useProjectSupport } from 'services/src/api'
import { useSaving } from 'components/src/saving'
import { NewClient } from './NewClient'
import { Footer } from './Footer'
import { NewProjectModel } from './model'

export interface DetailsProps {
  model: NewProjectModel
  active: boolean
  step: number
  isLastStep: boolean
  onChangeModel: (model: NewProjectModel) => void
  onBack: () => void
  onNext: () => void
  onCancel: () => void
}

export const Details: React.FC<DetailsProps> = ({ model, active, step, isLastStep, onChangeModel, onBack, onNext, onCancel }) => {
  const { t } = useTranslation()
  const [saving] = useSaving()
  const [{ procurementMethods, budgetRanges }] = useProjectSupport()

  const [errors, setErrors] = useState<any>({})
  const clearError = (name: string) => {
    const theErrors = { ...errors }
    delete theErrors[name]
    setErrors(theErrors)
  }

  const [clients, setClients] = useState<Client[]>()

  const [newClient, setNewClient] = useState(false)
  const [aNewClient, setANewClient] = useState(model.ext.newClient)

  const [clientName, setClientName] = useState(model.client?.name || '')
  const [clientSuggestion, setClientSuggestions] = useState<Client[]>([])

  useEffect(() => {
    if (!model.account?.id) return
    getClients(model.account.id).then((clients) => setClients(clients))
  }, [model.account, setClients])

  const submit = useCallback(
    (e?: React.FormEvent) => {
      if (e) e.preventDefault()

      const errors: any = []

      // if (!client) errors.client = t('General.Errors.Required');

      if (!model.ext.isFixedBudget) {
        if (!model.ext.range) errors.budget = t('General.Errors.Required')
      } else if (!model.ext.fixed) errors.budget = t('General.Errors.NumericValueRequired')
      if (!model.ext.procurementMethod) errors.procurementMethod = t('General.Errors.Required')

      setErrors(errors)
      if (Object.keys(errors).length > 0) return

      onNext()
    },
    [model, onNext, setErrors, t]
  )

  if (!active) return null

  return (
    <>
      <div className="new-project-step-header">
        <div>{t('General.StepN', { N: step + 1 })}</div>
        <div>{t('NewProject.Details.Title')}</div>
      </div>

      <form onSubmit={submit} noValidate autoComplete="off">
        <div className={`ui-form-group ${errors.client ? 'ui-has-error' : ''}`}>
          <label htmlFor="type">
            <span>{t('NewProject.Details.ExtClient')}</span>
            &nbsp;&nbsp;
            <span className="ui-text-muted">{t('General.Optional')}</span>
          </label>
          <div className="ui-item-group">
            <AutoComplete
              suggestions={clientSuggestion}
              value={clientName}
              disabled={saving}
              style={{ width: '100%', borderRight: 'none' }}
              onValueChange={(v) => {
                clearError('client')
                setClientName(v)
                onChangeModel({ ...model, client: undefined, ext: { ...model.ext, newClient: undefined } })

                if (!v) {
                  setClientSuggestions([])
                  return
                }

                if (clients) {
                  const fuse = new Fuse(clients, { keys: ['name'] })
                  const result = fuse.search(v)

                  if (result.length > 0 || v.length > 1) setClientSuggestions(result.map((x) => x.item))
                  else setClientSuggestions(clients)
                }
              }}
              placeholder={t('NewProject.Details.ClientLookup')}
              onSelectSuggestion={(client: Client) => {
                onChangeModel({ ...model, client, ext: { ...model.ext, newClient: undefined } })
                setClientName(client.name)
                setClientSuggestions([])
                clearError('client')
              }}
              render={(client: Client) => (
                <div>
                  <FontAwesomeIcon icon={faMapMarker} /> {client.name}
                </div>
              )}
            />

            <button className="ui-btn ui-btn-primary" type="button" style={{ width: 150 }} disabled={saving} onClick={() => setNewClient(true)}>
              <FontAwesomeIcon icon={faPlusCircle} />
              <span style={{ marginLeft: 5 }}>{t('NewProject.Details.AddClient')}</span>
            </button>
          </div>
          {errors.client && <div className="ui-error">{errors.client}</div>}
        </div>

        <div className="ui-row">
          <div className="ui-col-12 ui-col-lg-6">
            <TextField
              name="projectNumber"
              value={model.projectNumber || ''}
              disabled={saving}
              label={
                <>
                  <span>{t('NewProject.Details.ProjectNumber')}</span>
                  &nbsp;&nbsp;
                  <span className="ui-text-muted">{t('General.Optional')}</span>
                </>
              }
              placeholder={t('NewProject.Details.ProjectNumberSample')}
              error={errors.projectNumber}
              onChange={(projectNumber) => {
                onChangeModel({ ...model, projectNumber })
                clearError('projectNumber')
              }}
            />
          </div>

          <div className="ui-col-12 ui-col-lg-6">
            <TextField
              name="taskOrderNumber"
              value={model.taskOrderNumber || ''}
              disabled={saving}
              label={
                <>
                  <span>{t('NewProject.Details.OrderNumber')}</span>
                  &nbsp;&nbsp;
                  <span className="ui-text-muted">{t('General.Optional')}</span>
                </>
              }
              placeholder={t('NewProject.Details.OrderNumberSample')}
              error={errors.orderNumber}
              onChange={(taskOrderNumber) => {
                onChangeModel({ ...model, taskOrderNumber })
                clearError('taskOrderNumber')
              }}
            />
          </div>
        </div>

        <div className="ui-row">
          <div className="ui-col-12 ui-col-lg-6">
            <BudgetField
              isFixed={model.ext.isFixedBudget}
              fixed={model.ext.fixed}
              range={model.ext.range}
              disabled={saving}
              onTypeChange={(isFixedBudget) => {
                onChangeModel({ ...model, ext: { ...model.ext, isFixedBudget } })
                clearError('budget')
              }}
              name="budget"
              label={t('NewProject.Details.Budget')}
              error={errors.budget}
              placeholder={t(model.ext.isFixedBudget ? 'NewProject.Details.BudgetFixedSample' : 'NewProject.Details.BudgetRangeSample')}
              budgetRanges={budgetRanges}
              onChange={(value) => {
                let { fixed } = model.ext
                let { range } = model.ext
                const isFixedBudget = value.isFixed
                if (value.isFixed) {
                  fixed = value.fixed
                } else {
                  range = value.range
                }
                onChangeModel({ ...model, ext: { ...model.ext, isFixedBudget, fixed, range } })
                clearError('budget')
              }}
            />
          </div>

          <div className="ui-col-12 ui-col-lg-6">
            <SelectField
              items={procurementMethods}
              selected={model.ext.procurementMethod}
              valueField="id"
              labelField="method"
              disabled={saving}
              onSelectedChange={(procurementMethod) => {
                onChangeModel({ ...model, ext: { ...model.ext, procurementMethod } })
                clearError('procurementMethod')
              }}
              label={t('NewProject.Details.ProcurementMethod')}
              placeholder={t('NewProject.Details.ProcurementMethodSample')}
              error={errors.procurementMethod}
            />
          </div>
        </div>

        <TextAreaField
          name="description"
          className="ui-text-sm"
          value={model.description || ''}
          disabled={saving}
          label={
            <>
              <span>{t('NewProject.Details.Description')}</span>
              &nbsp;&nbsp;
              <span className="ui-text-muted">{t('General.Optional')}</span>
            </>
          }
          error={errors.description}
          placeholder={t('NewProject.Details.DescriptionSample')}
          onChange={(description) => {
            onChangeModel({ ...model, description })
            clearError('description')
          }}
        />
      </form>

      <Footer canBack={step > 0} onBack={onBack} canNext={!isLastStep} onNext={submit} onCancel={onCancel} />

      {newClient && (
        <NewClient
          client={aNewClient}
          onNew={(client: Client) => {
            setNewClient(false)
            setANewClient(client)
            setClientName(client.name)
            onChangeModel({
              ...model,
              client,
              ext: { ...model.ext, newClient: client }
            })
          }}
          onClose={() => setNewClient(false)}
        />
      )}
    </>
  )
}
