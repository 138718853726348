import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronDown } from '@fortawesome/pro-solid-svg-icons'

import { makeDashboardPath } from 'services/src/dom'
import { useCurrentUserAccount, useCurrentUserOffice, useRights, UserType, useUserAccounts, useUserOffices } from 'services/src/state'

import './style.scss'
import OfficesSvg from 'components/src/svg/Offices'
import { Account, AccountType, Office, PRIMARY_PROVIDER_ID } from 'services/src/dto/account'
import { Select } from 'components/src/select'

export const AccountSelector: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const rights = useRights()
  const [accounts] = useUserAccounts()
  const [account, setAccount] = useCurrentUserAccount()
  const [selectAccount, setSelectAccount] = useState(false)
  const [offices] = useUserOffices()
  const [office, setOffice] = useCurrentUserOffice()
  const [selectOffice, setSelectOffice] = useState(false)

  const officeItems = useMemo(() => {
    if (!offices?.length) return []

    if (offices.length === 1) return offices

    const result: Office[] = [{ id: 'all', name: t('General.AllOffices') } as Office]
    ;[...offices].sort((a, b) => a.name.localeCompare(b.name)).forEach((o) => result.push(o))

    return result
  }, [offices, t])

  const [, setOnFocus] = useState(false)

  const readyAccounts = useMemo(() => {
    return accounts?.filter((x) => x.type !== AccountType.Expert) || []
  }, [accounts])

  if (!accounts || !account)
    return (
      <div className="ui-account-selector">
        <div>...</div>
      </div>
    )

  return (
    <div className="ui-account-selector">
      <div className="ui-account-selector-items">
        {account.type != AccountType.Expert && (
          <div
            className={readyAccounts.length > 1 ? ' has-action' : ''}
            tabIndex={0}
            role="listbox"
            onFocus={() => {
              if (accounts?.length <= 1) return
              setOnFocus(true)
              setSelectAccount(true)
            }}
            onKeyDown={() => {}}
            onClick={() => {
              if (accounts?.length <= 1) return
              setSelectAccount(true)
            }}
          >
            <div className="ui-flex ui-flex-align-center">
              <div>
                {account?.companyName || ''}
                {readyAccounts.length > 1 && <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faChevronDown} />}
              </div>
              <div className="ui-badge ui-badge-xs ui-badge-primary ui-text-uppercase" style={{ marginLeft: 10 }}>
                {account.type === AccountType.Client && t('General.Client')}
                {account.type === AccountType.Provider && account.id === PRIMARY_PROVIDER_ID && t('General.PrimaryProvider')}
                {account.type === AccountType.Provider && account.id !== PRIMARY_PROVIDER_ID && t('General.Provider')}
              </div>
            </div>
          </div>
        )}

        {rights?.userType === UserType.client && (
          <>
            <div>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
            <div style={{ paddingTop: 4 }}>
              <OfficesSvg width={22} />
            </div>
            <div
              style={{ marginLeft: 5 }}
              className={(officeItems.length || 0) > 1 ? ' has-action' : ''}
              tabIndex={0}
              role="button"
              onFocus={() => {
                if ((officeItems.length || 0) <= 1) return
                setOnFocus(true)
                setSelectOffice(true)
              }}
              onKeyDown={() => {}}
              onClick={() => {
                if ((officeItems.length || 0) <= 1) return
                setSelectOffice(true)
              }}
            >
              {office?.name || t('General.AllOffices')}
              {officeItems.length > 1 && <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faChevronDown} />}
            </div>
          </>
        )}
      </div>

      {readyAccounts.length > 1 && (
        <div
          className="ui-account-selector-overlay ui-flex ui-flex-nowrap ui-frame"
          style={{ display: selectAccount ? 'flex' : 'none', border: 'none', padding: 0, alignItems: 'center', overflow: 'visible' }}
        >
          <div style={{ marginRight: 15, width: 50 }} className="ui-text-muted ui-text-xs ui-text-right">
            {t('General.SelectAccount')}
          </div>
          <Select
            items={accounts || []}
            style={{ minWidth: 100, height: 30, flex: 1, marginTop: 1 }}
            selected={account}
            open={selectAccount}
            labelField="companyName"
            onClose={() => {
              setSelectAccount(false)
              setOnFocus(false)
            }}
            onSelectedChange={(a) => {
              if (a.id !== account?.id) {
                setAccount(a)
                setSelectAccount(false)
                setOffice(undefined)
                history.replace(makeDashboardPath(a.id))
              }
              /* if (onFocus) {
                      if ((officeItems.length || 0) <= 1) return;
                      setSelectOffice(true);
                      return;
                    } */
              setOnFocus(false)
            }}
            renderItem={(a: Account) => (
              <div className="ui-text-sm ui-flex ui-flex-nowrap" style={{ alignItems: 'center' }}>
                <div className="ui-flex" style={{ alignItems: 'center', width: 40, height: 25, overflow: 'hidden' }}>
                  {a.logoUrl ? <img alt="" src={a.logoUrl} style={{ width: 30, marginRight: 5 }} /> : <div className="ui-account-selector-no-logo">{a.companyName[0]}</div>}
                </div>

                <div className="ui-flex ui-flex-align-center">
                  <div>{a.companyName || ''}</div>
                  <div className="ui-badge ui-badge-xs ui-badge-primary ui-text-uppercase" style={{ marginLeft: 10 }}>
                    {a.type === AccountType.Client && t('General.Client')}
                    {a.type === AccountType.Provider && a.id === PRIMARY_PROVIDER_ID && t('General.PrimaryProvider')}
                    {a.type === AccountType.Provider && a.id !== PRIMARY_PROVIDER_ID && t('General.Provider')}
                  </div>
                </div>
              </div>
            )}
          />
        </div>
      )}

      {officeItems.length > 2 && (
        <div className="ui-account-selector-overlay ui-flex ui-flex-nowrap ui-frame" style={{ display: selectOffice ? 'flex' : 'none', border: 'none', padding: 0, alignItems: 'center' }}>
          <div style={{ marginRight: 15, width: 50 }} className="ui-text-muted ui-text-xs ui-text-right">
            {t('General.SelectOffice')}
          </div>

          <Select
            items={officeItems || []}
            selected={office || officeItems[0]}
            style={{ minWidth: 100, height: 30, flex: 1, marginTop: 1 }}
            open={selectOffice}
            labelField="name"
            onClose={() => {
              setSelectOffice(false)
              setOnFocus(false)
            }}
            onSelectedChange={(o) => {
              setOffice(o.id === 'all' ? undefined : o)
              setSelectOffice(false)
              setOnFocus(false)
            }}
            renderItem={(o: Office) => <div className={`ui-text-sm${o.id === 'all' ? ' ui-text-italic' : ''}`}>{o.name}</div>}
          />
        </div>
      )}
    </div>
  )
}
