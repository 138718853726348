import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompress, faExpand } from '@fortawesome/pro-duotone-svg-icons'
import { faBan, faCheckSquare, faCity, faDraftingCompass, faExclamationTriangle, faRotateLeft, faSquare, faSquareCheck, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { Address, ResourceUserRole, User, UserStatus } from 'services/src/dto/account'
import { getDisciplines, getPageDisciplines } from 'services/src/common'
import { TextField } from 'components/src/textField'
import { SelectField } from 'components/src/selectField'
import { getUsers, GetUsersParams, useProjectSupport } from 'services/src/api'
import { useEditProject, useGeolocation } from 'services/src/state'
import { BuildingCategory } from 'services/src/dto/projectSupport'
import { ProjectType } from 'services/src/dto/projectShare'

type UserEx = User & {
  id: string
  givenName: string
  familyName: string
  email: string
  role: string
  discipline?: string
  disciplineLabel?: string
  hourlyRate?: number
  personalAddress?: Address
  distance?: number
  noSelect?: boolean
}

const UserCard: React.FC<{
  user: UserEx
  hours?: number
  onSelect: (user: UserEx, double?: boolean) => void
  style?: CSSProperties
  variant?: 'expert' | 'client'
  noSelect?: boolean
}> = ({ variant, user, hours, onSelect, style, noSelect }) => {
  const { t } = useTranslation()

  return (
    <div
      className={`ui-frame ui-frame-bg ui-action-item edit-project-assignment-panel-user-card ${user.status.toLowerCase()}${noSelect ? ' no-select' : ''}`}
      style={{ ...(style || {}), alignItems: 'center', padding: '5px 20px' }}
      onDoubleClick={() => onSelect(user, true)}
      role="button"
      tabIndex={-1}
      onKeyDown={() => {}}
      onFocus={(e) => {
        if (user.status !== UserStatus.Ready) {
          e.preventDefault()
          e.stopPropagation()
          e.currentTarget.blur()
        }
      }}
      onClick={() => {
        if (user.status !== UserStatus.Ready || noSelect) return
        onSelect(user)
      }}
    >
      <div className="ui-flex ui-flex-nowrap user-info" style={{ alignItems: 'center' }}>
        {user.givenName && user.familyName ? (
          <>
            <div className="ui-picture-circle" style={{ width: 27, height: 27, minWidth: 27, minHeight: 27 }}>
              {user.pictureUrl ? (
                <div style={{ backgroundImage: `url('${user.pictureUrl}')`, border: 'none' }} />
              ) : (
                <div style={{ lineHeight: '25px', fontSize: 15 }}>
                  {user.givenName[0].toUpperCase()}
                  {user.familyName[0].toUpperCase()}
                </div>
              )}
            </div>

            <div style={{ marginLeft: 10 }}>
              <div className="ui-flex ui-flex-nowrap">
                <div>
                  <FontAwesomeIcon icon={user.role === ResourceUserRole.Contributor ? faDraftingCompass : faCity} />
                </div>
                <div style={{ marginLeft: 5 }}>{`${user.givenName} ${user.familyName}`}</div>
              </div>
              <div className="ui-text-xs ui-text-muted">{user.email}</div>
              {user.personalAddress && (
                <div className="ui-text-xs ui-text-muted">
                  {(() => {
                    const { municipality, countrySubdivision, countrySubdivisionName, country, freeformAddress } = user.personalAddress
                    if (municipality && (countrySubdivision || countrySubdivisionName) && country) return `${municipality}, ${countrySubdivision || countrySubdivisionName} ${country}`
                    if (municipality && countrySubdivision) return `${municipality}, ${countrySubdivision}`
                    if (municipality && country) return `${municipality} ${country}`

                    return freeformAddress || ''
                  })()}
                </div>
              )}
            </div>

            {variant !== 'client' && (
              <>
                <div className="ui-text-sm ui-text-right" style={{ marginLeft: 'auto', marginTop: 10, width: 60 }}>
                  <label className="ui-text-xxs ui-text-uppercase" style={{ marginBottom: 0 }}>
                    {t('General.Distance')}
                  </label>
                  {user.distance !== undefined && user.distance >= 0 ? `${Math.floor((user.distance / 1000) * 0.6213711922)}mi` : '--'}
                </div>

                <div className="ui-text-sm ui-text-right" style={{ marginTop: 10, width: 60 }}>
                  <label className="ui-text-xxs ui-text-uppercase" style={{ marginBottom: 0 }}>
                    {t('General.Rate')}
                  </label>
                  {user.hourlyRate ? t('General.Currency.Value', { value: user.hourlyRate, maxDigits: 0 }) : '--'}
                </div>

                <div className="ui-text-sm ui-text-right" style={{ marginTop: 10, width: 60 }}>
                  <label className="ui-text-xxs ui-text-uppercase" style={{ marginBottom: 0 }}>
                    {t('General.Cost')}
                  </label>
                  {hours && user.hourlyRate ? t('General.Currency.Value', { value: user.hourlyRate * hours, maxDigits: 0 }) : '--'}
                </div>
              </>
            )}
          </>
        ) : (
          <div>{user.email}</div>
        )}
      </div>

      {user.disciplineLabel && <div className="user-role">{user.disciplineLabel}</div>}
      {user.status !== UserStatus.Ready && <div className="unavailable-tag">{t('EditProject.Assignments.NotAvailable')}</div>}
      {noSelect && <div className="unavailable-tag">{t('EditProject.Assignments.NoSelect')}</div>}
    </div>
  )
}

export const AssignmentPanel: React.FC<{
  discipline?: string
  onSubmit: (user: User, assignToAll?: boolean) => void
  canAssignAll?: boolean
  onClose: () => void
  expertTypes?: string[]
  hours?: number
  variant?: 'expert' | 'client'
  overlay?: boolean
  noSelectIds?: string[]
}> = ({ discipline, onClose, onSubmit, canAssignAll, expertTypes: initExpertTypes, hours, variant, overlay, noSelectIds }) => {
  const { t } = useTranslation()
  const { project } = useEditProject()
  const geo = useGeolocation()

  const [{ buildingCategories }] = useProjectSupport()
  const [category, setCategory] = useState<BuildingCategory>()
  const [projectType, setProjectType] = useState<ProjectType>()

  const [isFullScreen, setIsFullScreen] = useState(false)

  const filterRef = useRef<HTMLInputElement>(null)
  const [filter, setFilter] = useState('')

  const expertTypeItems = useMemo(
    () => [
      {
        id: 'FTE',
        label: t('General.ExpertTypes.FTE')
      },
      {
        id: 'Contractor',
        label: t('General.ExpertTypes.Contractor')
      }
    ],
    [t]
  )

  const [expertTypes, setExpertsTypes] = useState<string[]>([...(initExpertTypes || [])])

  const [hideUnavailable, setHideUnavailable] = useState(false)

  const disciplines = useMemo(() => getDisciplines(t), [t])

  const pageDisciplines = useMemo(() => getPageDisciplines(t), [t])
  const [pageDiscipline, setPageDiscipline] = useState(pageDisciplines.find((x) => x.id === discipline))

  const [users, setUsers] = useState<UserEx[]>([])
  const [noUsers, setNoUsers] = useState<boolean>()

  const loadUsers = useCallback(
    (filter?: string, category?: number, projectTypeId?: string, discipline?: string, expertTypes?: string[], hideUnavailable?: boolean) => {
      const lat = project.location?.lat || geo.latitude || 0
      const lon = project.location?.lon || geo.longitude || 0

      const params: GetUsersParams = {
        lat,
        lon,
        search: filter || undefined,
        disciplines: discipline ? [discipline] : undefined,
        providerOnly: variant !== 'client',
        providerId: variant !== 'client' ? project.provider.id : undefined,
        experts: variant !== 'client',
        accountId: variant === 'client' ? project.account.id : undefined,
        status: hideUnavailable === true ? UserStatus.Ready : undefined
      }
      if (variant !== 'client') {
        if (expertTypes) params.expertType = expertTypes.join(',')

        if (projectTypeId) params.projectTypeId = projectTypeId
        else if ((category || 0) > 0) params.category = category
      }

      getUsers(params).then((users) => {
        setNoUsers(users.length <= 0)
        setUsers(
          users.map((u) => ({
            ...u,
            email: u.emails![0].email,
            role: u.role,
            picture: u.pictureUrl,
            discipline: u.primaryDiscipline,
            disciplineLabel: disciplines.find((d) => d.discipline === u.primaryDiscipline)?.discipline,
            hourlyRate: u.hourlyRate,
            personalAddress: u.personalAddress,
            distance: u.distance,
            noSelect: !!noSelectIds?.find((id) => u.id === id)
          }))
        )
      })
    },
    [geo, project, variant, setUsers]
  )

  useEffect(() => {
    if (!geo.ready || !buildingCategories) return

    const cat = buildingCategories.find((x) => x.category === project.projectType?.category)
    setCategory(cat)
    const pt = cat?.projectTypes.find((x) => x.id === project.projectType?.id)
    setProjectType(pt)

    loadUsers(filter, cat?.category, pt?.id, pageDiscipline?.id, expertTypes, hideUnavailable)
  }, [geo, project, buildingCategories])

  const [selectedUser, setSelectedUser] = useState<UserEx | undefined>()

  const elm = useRef<HTMLDivElement>(document.createElement('div'))
  useEffect(() => {
    document.body.appendChild(elm.current)
    setTimeout(() => filterRef.current?.focus())
    return () => {
      if (elm.current) elm.current.remove()
    }
  }, [])

  const submit = useCallback(
    (e?: React.FormEvent, all?: boolean) => {
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }

      if (!selectedUser) return

      onSubmit(selectedUser, all)
    },
    [onSubmit, users, t, selectedUser]
  )

  const timerRef = useRef<any>()

  return ReactDOM.createPortal(
    <div className={`edit-project-assignment-panel${isFullScreen ? ' panel-full' : ''} variant-${variant || 'expert'} ${overlay ? ' panel-overlay' : ''}`}>
      <div className="edit-project-assignment-panel-header">
        <h3>
          <div>{t('EditProject.Assignments.Title')}</div>
          {discipline && <div className="ui-text-xs">{t(`General.Disciplines.${discipline}`)}</div>}
        </h3>
        <button type="button" className="ui-btn-empty" onClick={() => setIsFullScreen((current) => !current)}>
          <FontAwesomeIcon icon={isFullScreen ? faCompress : faExpand} />
        </button>
        <button type="button" className="ui-btn-empty" onClick={() => onClose()}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>

      <div className="edit-project-assignment-panel-filter">
        <TextField
          autoFocus
          forwardRef={filterRef}
          onChange={(filter) => {
            setFilter(filter)
            clearTimeout(timerRef.current)
            timerRef.current = setTimeout(() => {
              loadUsers(filter, category?.category, projectType?.id, pageDiscipline?.id, expertTypes, hideUnavailable)
            }, 500)
          }}
          value={filter}
          className="ui-input-sm"
          label={t('EditProject.Assignments.FilterByNameOrEmail')}
          placeholder={t('EditProject.Assignments.FilterByNameOrEmailSample')}
        />

        {variant !== 'client' && (
          <>
            <div className="ui-flex ui-flex-nowrap" style={{ maxWidth: '100%' }}>
              <div style={{ width: 50, minWidth: 50, maxWidth: 50 }}>
                <SelectField
                  items={buildingCategories}
                  className="ui-select-sm"
                  label={t('EditProject.Assignments.CatNo')}
                  placeholder={t('EditProject.Assignments.CatNoSample')}
                  labelField="category"
                  valueField="category"
                  selected={category}
                  maxDropDown={20}
                  onSelectedChange={(cat) => {
                    setCategory(cat)
                    setProjectType(undefined)
                    loadUsers(filter, cat.category, undefined, pageDiscipline?.id, expertTypes, hideUnavailable)
                  }}
                />
              </div>
              <div style={{ flex: 1, marginLeft: 10, maxWidth: 450 }}>
                <SelectField
                  items={category?.projectTypes || []}
                  className="ui-select-sm"
                  label={t('EditProject.Assignments.ProjectType')}
                  placeholder={t('EditProject.Assignments.ProjectTypeSample')}
                  labelField="type"
                  valueField="id"
                  maxDropDown={20}
                  selected={projectType}
                  onSelectedChange={(pt) => {
                    setProjectType(pt)
                    loadUsers(filter, category?.category, pt.id, pageDiscipline?.id, expertTypes, hideUnavailable)
                  }}
                />
              </div>
              <div style={{ width: 20, minWidth: 20, maxWidth: 20, paddingTop: 18, marginLeft: 5 }}>
                <button
                  type="button"
                  className="ui-btn-empty"
                  title={t('EditProject.Assignments.ExcludeProjectType')}
                  onClick={() => {
                    setCategory(undefined)
                    setProjectType(undefined)
                    loadUsers(filter, undefined, undefined, pageDiscipline?.id, expertTypes, hideUnavailable)
                  }}
                >
                  <FontAwesomeIcon icon={faBan} />
                </button>
              </div>
              <div style={{ width: 20, minWidth: 20, maxWidth: 20, paddingTop: 18 }}>
                <button
                  type="button"
                  className="ui-btn-empty"
                  title={t('EditProject.Assignments.ResetProjectType')}
                  onClick={() => {
                    const cat = buildingCategories.find((x) => x.category === project.projectType?.category)
                    setCategory(cat)
                    const pt = cat?.projectTypes.find((x) => x.id === project.projectType?.id)
                    setProjectType(pt)

                    loadUsers(filter, cat?.category, pt?.id, pageDiscipline?.id, expertTypes, hideUnavailable)
                  }}
                >
                  <FontAwesomeIcon icon={faRotateLeft} />
                </button>
              </div>
            </div>

            <div className="ui-flex ui-flex-nowrap">
              <div style={{ minWidth: 220, flex: 1 }}>
                <SelectField
                  items={pageDisciplines}
                  className="ui-select-sm"
                  label={t('EditProject.Assignments.FilterByDiscipline')}
                  placeholder={t('EditProject.Assignments.FilterByDisciplineSample')}
                  labelField="discipline"
                  valueField="id"
                  selected={pageDiscipline}
                  onClear={() => {
                    setPageDiscipline(undefined)
                    loadUsers(filter, category?.category, projectType?.id, undefined, expertTypes, hideUnavailable)
                  }}
                  onSelectedChange={(d) => {
                    setPageDiscipline(d)
                    loadUsers(filter, category?.category, projectType?.id, d?.id, expertTypes, hideUnavailable)
                  }}
                />
              </div>

              <div style={{ marginLeft: 5, minWidth: 170, flex: 0.5 }}>
                <SelectField
                  items={expertTypeItems}
                  label={t('EditProject.Assignments.FilterByExpertType')}
                  placeholder={t('EditProject.Fees.SelectMultiDisciplines')}
                  className="ui-select-sm"
                  selected={expertTypes.length > 0}
                  onSelectedChange={({ id }) => {
                    const newExpertTypes: string[] = [...expertTypes]

                    const idx = newExpertTypes.findIndex((x) => x === id)
                    if (idx < 0) newExpertTypes.push(id)
                    else newExpertTypes.splice(idx, 1)
                    setExpertsTypes(newExpertTypes)

                    loadUsers(filter, category?.category, projectType?.id, pageDiscipline?.id, newExpertTypes, hideUnavailable)

                    return true
                  }}
                  renderItem={({ id, label }) => {
                    if (expertTypes.find((s) => s === id))
                      return (
                        <div>
                          <FontAwesomeIcon icon={faSquareCheck} /> {label}
                        </div>
                      )
                    return (
                      <div>
                        <FontAwesomeIcon icon={faSquare} /> {label}
                      </div>
                    )
                  }}
                  renderSelected={() => {
                    if (!expertTypes.length) return <div />
                    if (expertTypes.length === 1) return <div>{expertTypeItems.find((x) => x.id === expertTypes[0])?.label || ''}</div>
                    if (expertTypes.length === 2)
                      return <div>{`${expertTypeItems.find((x) => x.id === expertTypes[0])?.label || ''} and ${expertTypeItems.find((x) => x.id === expertTypes[1])?.label || ''}`}</div>
                    return <div />
                  }}
                />
              </div>

              <div className="ui-flex ui-flex-nowrap ui-text-xs" style={{ alignItems: 'center', paddingTop: 13, marginLeft: 15 }}>
                <div
                  className="ui-action-item"
                  role="button"
                  tabIndex={-1}
                  onKeyDown={() => {}}
                  onClick={() => {
                    const newHideUnavailable = !hideUnavailable
                    setHideUnavailable(newHideUnavailable)
                    loadUsers(filter, category?.category, projectType?.id, pageDiscipline?.id, expertTypes, newHideUnavailable)
                  }}
                >
                  <FontAwesomeIcon icon={hideUnavailable ? faCheckSquare : faSquare} />
                  &nbsp;{t('EditProject.Assignments.HideUnavailable')}
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="edit-project-assignment-panel-selected">
        <label className="ui-text-xs">{t('EditProject.Assignments.Selected')}</label>
        {selectedUser ? (
          <UserCard user={selectedUser} onSelect={() => {}} variant={variant} hours={hours} />
        ) : (
          <div className="ui-text-muted ui-text-center ui-text-sm" style={{ paddingTop: 18 }}>
            {t('EditProject.Assignments.SelectAUser')}
          </div>
        )}
      </div>

      <div className="edit-project-assignment-panel-list">
        {noUsers && (
          <div className="no-users ui-text-muted ui-warn">
            <div>
              <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
            </div>
            <div>{t('EditProject.Assignments.NoUsersFound')}</div>
          </div>
        )}

        {users.length > 0 && (
          <div>
            {users.map((u, idx) => (
              <UserCard
                variant={variant}
                key={u.id}
                user={u}
                hours={hours}
                noSelect={!!noSelectIds?.find((id) => id === u.id)}
                onSelect={(u, d) => {
                  if (u.status !== UserStatus.Ready) return
                  setSelectedUser({ ...u })
                  if (d) setTimeout(() => submit())
                }}
                style={{ margin: idx === 0 ? '20px 0 5px 0' : '5px 0' }}
              />
            ))}
          </div>
        )}
      </div>

      <div className="edit-project-assignment-panel-btn ui-flex">
        <button type="button" className="ui-btn ui-btn-solid ui-btn-primary" style={{ marginRight: 20, width: 'auto' }} onClick={() => onClose()}>
          {t('EditProject.Assignments.Cancel')}
        </button>

        <div style={{ marginLeft: 'auto' }} />

        {canAssignAll && (
          <button type="button" className="ui-btn ui-btn-solid ui-btn-secondary" style={{ marginRight: 10, width: 'auto' }} disabled={!selectedUser} onClick={() => submit(undefined, true)}>
            {t('EditProject.Assignments.AssignAll')}
          </button>
        )}

        <button type="button" className="ui-btn ui-btn-solid ui-btn-secondary" style={{ width: 'auto' }} disabled={!selectedUser} onClick={() => submit()}>
          {t('EditProject.Assignments.Assign')}
        </button>
      </div>
    </div>,
    elm.current
  )
}
