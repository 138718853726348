import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

import { RecoilRoot } from 'recoil'
import { LicenseManager } from 'ag-grid-enterprise'
import { BrowserCacheLocation } from '@azure/msal-browser'

import { initialize as initializeAad } from 'services/src/aad'
import { App } from './App'

LicenseManager.setLicenseKey(`${process.env.REACT_APP_AG_GRID_LICENSE_KEY}`)

const r = localStorage.getItem('logoutRedirect')
if (r) {
  localStorage.removeItem('logoutRedirect')
  window.location.href = r
} else {
  // initialize AAD
  initializeAad({
    configuration: {
      auth: {
        clientId: `${process.env.REACT_APP_CLIENT_ID}`,
        authority: `https://${process.env.REACT_APP_AUTHORITY_DOMAIN}/${process.env.REACT_APP_TENANT}/B2C_1A_signup_signin`,
        knownAuthorities: [`${process.env.REACT_APP_AUTHORITY_DOMAIN}`],
        redirectUri: `${process.env.REACT_APP_REDIRECT_URI}`
      },
      cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: false
      },
      system: {}
    },
    scopes: ['openid', 'offline_access'],
    accountScopes: ['https://devccpdt.onmicrosoft.com/Api/ReadWrite'],
    commentScopes: ['https://devccpdt.onmicrosoft.com/Api/ReadWrite'],
    contentScopes: ['https://devccpdt.onmicrosoft.com/Api/ReadWrite'],
    notificationScopes: ['https://devccpdt.onmicrosoft.com/Api/ReadWrite'],
    projectScopes: ['https://devccpdt.onmicrosoft.com/Api/ReadWrite'],
  })

  document.body.classList.add(`theme-${localStorage.getItem('theme') || 'light'}`)

  // render the app
  ReactDOM.render(
    <React.StrictMode>
      <RecoilRoot>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root')
  )
}
