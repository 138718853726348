export const decZoom = (zoom: number) => {
  let z
  if (zoom > 2) z = zoom - 0.5
  else if (zoom > 1) z = zoom - 0.2
  else if (zoom > 0.9) z = 0.9
  else if (zoom > 0.8) z = 0.8
  else if (zoom > 0.7) z = 0.7
  else if (zoom > 0.6) z = 0.6
  else if (zoom > 0.5) z = 0.5
  else if (zoom > 0.4) z = 0.4
  else if (zoom > 0.3) z = 0.3
  else if (zoom > 0.2) z = 0.2
  else if (zoom > 0.15) z = 0.15
  else if (zoom > 0.1) z = 0.1
  else if (zoom > 0.05) z = 0.05
  else z = 0.01

  return Math.max(0.01, z)
}
export const incZoom = (zoom: number) => {
  let z
  if (zoom < 0.05) z = 0.05
  else if (zoom < 0.1) z = 0.1
  else if (zoom < 0.15) z = 0.15
  else if (zoom < 0.2) z = 0.2
  else if (zoom < 0.3) z = 0.3
  else if (zoom < 0.4) z = 0.4
  else if (zoom < 0.5) z = 0.5
  else if (zoom < 0.6) z = 0.6
  else if (zoom < 0.7) z = 0.7
  else if (zoom < 0.8) z = 0.8
  else if (zoom < 0.9) z = 0.9
  else if (zoom < 1) z = 1
  else if (zoom < 2) z = zoom + 0.2
  else z = zoom + 0.5

  return Math.min(10, z)
}

export interface Point {
  left: number
  top: number
}
