import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

export const OfficeNav: React.FC<{
  visible: boolean
  url: string
}> = ({ visible, url }) => {
  const { t } = useTranslation()
  const location = useLocation()

  if (!visible) return null

  return (
    <div className="office-edit-nav">
      <label>{t('General.Settings')}</label>
      <div className={location.pathname === url ? ' active' : ''}>
        <Link to={url}>{t('General.Profile')}</Link>
      </div>
      <div className={location.pathname.includes('staff') ? ' active' : ''}>
        <Link to={`${url}/staff`}>{t('General.Staff')}</Link>
      </div>
    </div>
  )
}
