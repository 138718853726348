import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useCurrentUserAccount } from 'services/src/state'
import { makeDashboardPath } from 'services/src/dom'
import { NewProjectModel } from './model'
import HandsSvg from '../../../svg/Hands'

export interface SubmittedProps {
  model: NewProjectModel
  active: boolean
}

export const Submitted: React.FC<SubmittedProps> = ({ model, active }) => {
  const { t } = useTranslation()

  const [account] = useCurrentUserAccount()

  useEffect(() => {
    document.body.classList.add('light-bg')
    return () => {
      document.body.classList.remove('light-bg')
    }
  }, [])

  if (!active) return null

  return (
    <div className="new-project-submitted">
      <h3>{t('NewProject.Submitted.Title')}</h3>
      <h4>{t('NewProject.Submitted.Subtitle')}</h4>

      <div className="image">
        <div />
        <HandsSvg width="180" />
      </div>

      <div className="ui-flex ui-flex-nowrap" style={{ justifyContent: 'center', width: 450, margin: '0 auto' }}>
        <Link to={makeDashboardPath(account?.id, `projects/${model.id}`)} style={{ width: '100%' }} className="ui-btn ui-btn-secondary ui-btn-solid ui-btn-lg">
          {t('NewProject.Submitted.ViewProject')}
        </Link>
      </div>

      <br />

      <div className="ui-flex ui-flex-nowrap" style={{ justifyContent: 'center', width: 450, margin: '0 auto' }}>
        <Link to={makeDashboardPath(account?.id, `projects`)} style={{ width: '50%', marginRight: 8 }} className="ui-btn ui-btn-primary ui-btn-solid">
          {t('NewProject.Submitted.ViewWorkspace')}
        </Link>
        <Link to={makeDashboardPath(account?.id, `projects/new/reset`)} style={{ width: '50%', marginLeft: 8 }} className="ui-btn ui-btn-primary ui-btn-solid">
          {t('NewProject.Submitted.CreateNew')}
        </Link>
      </div>
    </div>
  )
}
