import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'

import { DatePickerMarker } from 'components/src/datePicker'
import { useProjectSupport } from 'services/src/api'
import { PhaseEx, ProjectEx } from 'services/src/state'
import { EstimateTypeKey, ProjectStatus } from 'services/src/dto/project'
import { PhaseTypeKey } from 'services/src/dto/projectShare'
import { PhaseModal } from '../common/PhaseModal'
import { Timeline as TimelineCmp, updateTimelineItemsFromProject, timelineItemsToPhases, TimelineItem, updateTimelineMarkersFromProject, adjustTimelineItems } from '../common/Timeline'
import { Footer } from './Footer'
import { NewProjectModel } from './model'

export interface TimelineProps {
  model: NewProjectModel
  active: boolean
  step: number
  isLastStep: boolean
  onChangeModel: (model: NewProjectModel) => void
  onBack: () => void
  onNext: () => void
  onCancel: () => void
}

export const Timeline: React.FC<TimelineProps> = ({ model, active, step, isLastStep, onChangeModel, onBack, onNext, onCancel }) => {
  const { t } = useTranslation()
  const [{ phaseTypes }] = useProjectSupport()

  const [items, setItems] = useState<TimelineItem[]>(updateTimelineItemsFromProject(model, phaseTypes, t))
  const [markers, setMarkers] = useState<DatePickerMarker[]>()

  useEffect(() => {
    const items = updateTimelineItemsFromProject(model, phaseTypes, t)
    setItems(items)

    const markers = updateTimelineMarkersFromProject(model, phaseTypes, t)
    setMarkers(markers)
  }, [model, phaseTypes, t])

  const [phaseModal, setPhaseModal] = useState<TimelineItem>()
  const [phaseModalAction, setPhaseModalAction] = useState<'add' | 'edit'>()

  const submit = useCallback(
    (e?: React.FormEvent) => {
      if (e) e.preventDefault()

      const newItems = [...(items || [])]

      const dateError = false
      let availableCnt = 0
      newItems.forEach((item) => {
        delete item.warn
        delete item.error

        if (!item.available || !item.start || !item.end) return
        availableCnt++
      })

      if (availableCnt <= 0) newItems[0].error = t('NewProject.Timeline.OneRequired')

      setItems(newItems)

      if (availableCnt <= 0 || dateError) return

      onNext()
    },
    [onNext, items, setItems, t]
  )

  if (!active) return null

  return (
    <>
      <div className="new-project-step-header">
        <div>{t('General.StepN', { N: step + 1 })}</div>
        <div>{t('NewProject.Timeline.Title')}</div>
      </div>

      <form onSubmit={submit} noValidate autoComplete="off">
        <TimelineCmp
          items={items || []}
          markers={markers}
          canEdit
          maxPhases={model.estimateType === EstimateTypeKey.IndependentCostReview ? 1 : undefined}
          onChange={(items) => {
            setItems(items)
            onChangeModel({ ...model, phases: timelineItemsToPhases(items) })
          }}
          onEditPhase={(item) => {
            setPhaseModalAction('edit')
            setPhaseModal(item)
          }}
          // initialPhaseLabel={t('NewProject.Timeline.InitialPhase')}
          otherPhasesLabel={
            <div className="ui-text-center" style={{ marginBottom: 15 }}>
              {model.estimateType === EstimateTypeKey.IndependentCostReview ? t('NewProject.Timeline.ReviewOtherPhases') : t('NewProject.Timeline.OtherPhases')}
            </div>
          }
          /*
          otherPhasesSubLabel={
            <div className="ui-danger ui-text-center">
              <FontAwesomeIcon icon={faExclamationTriangle} />
              &nbsp;
              {model.estimateType === EstimateTypeKey.IndependentCostReview
                ? t('NewProject.Timeline.ReviewOtherPhasesOptional')
                : t('NewProject.Timeline.OtherPhasesOptional')}
            </div>
          }
          */
        />
      </form>

      {phaseModal && (
        <PhaseModal
          phaseTypes={phaseTypes}
          project={model as ProjectEx}
          phase={phaseModal.phase!}
          action={phaseModalAction || 'add'}
          onClose={() => setPhaseModal(undefined)}
          onSubmit={(ph: PhaseEx) => {
            const p: NewProjectModel = JSON.parse(JSON.stringify(model))
            if (!p.phases) p.phases = []

            const idx = p.phases.findIndex((x) => x.id === ph.id)
            if (idx < 0) p.phases.push({ ...ph, status: ProjectStatus.New, canEdit: true, canDelete: true })
            else p.phases[idx] = ph

            p.phases = [
              ...p.phases.filter((x) => x.phaseType === PhaseTypeKey.Concept),
              ...p.phases.filter((x) => x.phaseType === PhaseTypeKey.Schematic),
              ...p.phases.filter((x) => x.phaseType === PhaseTypeKey.DesignDoc),
              ...p.phases.filter((x) => x.phaseType === PhaseTypeKey.ConstructionDoc),
              ...p.phases.filter((x) => x.phaseType === PhaseTypeKey.Proposal)
            ]

            const items = updateTimelineItemsFromProject(p, phaseTypes, t)
            p.phases = timelineItemsToPhases(adjustTimelineItems(items))

            onChangeModel(p)

            setPhaseModal(undefined)
          }}
        />
      )}

      <Footer canBack={step > 0} onBack={onBack} canNext={!isLastStep} onNext={submit} onCancel={onCancel} />
    </>
  )
}
