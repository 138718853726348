import React from 'react'
import { useTranslation } from 'react-i18next'
import { FeeGridViewModelEstimate } from './common'

export const FeeGridGeneratedFee: React.FC<{
  estimate: FeeGridViewModelEstimate
}> = ({ estimate }) => {
  const { t } = useTranslation()
  return (
    <div className="ui-text-sm ui-text-right">
      <div className="ui-text-xxs ui-text-uppercase ui-text-bold ui-text-muted" style={{ marginBottom: 2 }}>
        {t('EditProject.Fees.GeneratedFee')}
      </div>
      <div className="ui-text-xs" style={{ marginTop: 2 }}>
        {t('General.Hrs', { count: estimate.generatedHours })} / {t('General.Currency.Value', { value: estimate.generatedTotal })}
      </div>
    </div>
  )
}
