import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faFilePdf, faFileWord, faFileExcel, faFilePowerpoint, faFileCsv, faFileArchive, faFileImage, faDownload, faTrashAlt } from '@fortawesome/pro-regular-svg-icons'

import { Content } from 'services/src/dto/content'

import './style.scss'
import moment from 'moment'
import { useMsalAccessToken } from 'services/src/aad'
import { EstimateEx, PhaseEx, ProjectContentEx } from 'services/src/state'
import { Loading } from '../loading'

export interface ProjectContentGroup {
  groupId: string
  group?: JSX.Element | string
  groupText?: string
  files: ProjectContentEx[]
  phase?: PhaseEx
  estimates?: EstimateEx[]
}

export interface FileListProps {
  noFilesLabel?: JSX.Element | string
  noFilesUploadLabel?: JSX.Element | string
  canCreate?: boolean
  canDelete?: boolean
  onUpload?: () => void
  onDelete: (content: Content) => void
  onOpen: (content: Content) => void
  files?: ProjectContentEx[]
  groupedFiles?: ProjectContentGroup[]
  thumbs?: boolean
  warning?: JSX.Element | string | null
}

export const FileList: React.FC<FileListProps> = ({ noFilesLabel, noFilesUploadLabel, canCreate, canDelete, onUpload, onDelete, onOpen, files, groupedFiles, thumbs, warning }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const accessToken = useMsalAccessToken()

  const [ready, setReady] = useState(false)

  useEffect(() => {
    // setReady(false);
    if (!files && !groupedFiles) return
    setTimeout(() => setReady(true), 500)
  }, [files, groupedFiles, setReady])

  const download = useCallback(
    (content: ProjectContentEx) => {
      const a = document.createElement('a')

      const url = content.urlAlt || content.url
      a.href = url.includes('sv=') ? url : `${url}${url.includes('?') ? '&' : '?'}access_token=${accessToken}&t=${moment().valueOf()}&attached=${encodeURIComponent(content.originalName)}`
      a.download = content.originalName
      a.rel = 'noopener noreferrer'

      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    [accessToken]
  )

  const renderFile = useCallback(
    (f: ProjectContentEx, idx: number) => (
      <div
        key={f.id}
        className={`ui-file-list-item ${idx % 2 === 0 ? 'even' : 'odd'}`}
        role="row"
        tabIndex={-1}
        onKeyDown={() => {}}
        onClick={(e) => {
          const t = e.target as any
          if (t.tagName !== 'DIV') return

          if (
            f.contentType.startsWith('text/') ||
            f.contentType.startsWith('image/') ||
            f.contentType.endsWith('pdf') ||
            f.contentType.endsWith('json') ||
            f.contentType.endsWith('xml') ||
            f.contentType.endsWith('csv') ||
            f.contentType.endsWith('/msword') ||
            f.contentType.includes('wordprocessingml') ||
            f.contentType.endsWith('/ms-excel') ||
            f.contentType.includes('spreadsheetml') ||
            f.contentType.endsWith('/ms-powerpoint') ||
            f.contentType.includes('presentationml')
          ) {
            onOpen(f)
            return
          }
          download(f)
        }}
      >
        <div style={{ width: thumbs ? 50 : 20 }}>
          {(() => {
            if (f.contentType.startsWith('image/')) {
              if (thumbs)
                return (
                  <div
                    style={{
                      width: 40,
                      height: 30,
                      backgroundSize: 'cover',
                      backgroundImage: `url(${f.url.includes('sv=') ? f.url : `${f.url}?access_token=${accessToken}`})`
                    }}
                  />
                )
              return <FontAwesomeIcon icon={faFileImage} />
            }
            if (f.contentType.includes('pdf')) return <FontAwesomeIcon icon={faFilePdf} />
            if (f.contentType === 'text/csv') return <FontAwesomeIcon icon={faFileCsv} />
            if (f.contentType.endsWith('/zip')) return <FontAwesomeIcon icon={faFileArchive} />
            if (f.contentType.endsWith('/msword') || f.contentType.includes('wordprocessingml')) return <FontAwesomeIcon icon={faFileWord} />
            if (f.contentType.endsWith('/ms-excel') || f.contentType.includes('spreadsheetml')) return <FontAwesomeIcon icon={faFileExcel} />
            if (f.contentType.endsWith('/ms-powerpoint') || f.contentType.includes('presentationml')) return <FontAwesomeIcon icon={faFilePowerpoint} />
            return <FontAwesomeIcon icon={faFile} />
          })()}
        </div>
        <div style={{ flex: 1, minWidth: 200 }} className="ui-text-ellipsis">
          {f.originalName}
        </div>
        <div style={{ minWidth: 120, maxWidth: 120, paddingRight: 10 }} className="ui-text-ellipsis ui-text-right">
          {moment(f.createdAt).format('l')}
        </div>
        <div style={{ minWidth: 120, maxWidth: 120, paddingLeft: 10 }} className="ui-text-ellipsis ui-text-left">
          {t('General.SizeAlt', { size: f.size })}
        </div>
        <div style={{ minWidth: 50, maxWidth: 50 }} className="ui-text-ellipsis ui-text-right">
          {canDelete && (
            <button
              type="button"
              className="ui-btn-empty ui-danger"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onDelete(f)
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          )}
          <button
            type="button"
            className="ui-btn-empty"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              download(f)
            }}
          >
            <FontAwesomeIcon icon={faDownload} />
          </button>
        </div>
      </div>
    ),
    [onOpen, download, onDelete]
  )

  return (
    <div className="ui-file-list" style={{ flex: 1, overflow: 'auto', position: 'relative' }}>
      {!ready && !(groupedFiles || files) && <Loading className="ui-loading-no-overlay" size="sm" variant="parent" />}

      {ready && warning && <div className="ui-file-list-alert">{warning}</div>}

      {ready &&
        groupedFiles &&
        (groupedFiles.length <= 0 ? (
          <div className="no-rows-overlay ui-text-center" style={{ paddingTop: 100, paddingBottom: 10 }}>
            {noFilesLabel || t('General.NoFiles')}
            {canCreate && (
              <div className="buttons ui-flex" style={{ justifyContent: 'center' }}>
                <Link
                  to={pathname}
                  className="ui-btn ui-btn-primary ui-btn-solid"
                  onClick={(e) => {
                    e.preventDefault()
                    if (onUpload) onUpload()
                  }}
                >
                  {noFilesUploadLabel || t('General.UploadFiles')}
                </Link>
              </div>
            )}
          </div>
        ) : (
          <div className="ui-file-list-group-list" style={{ flex: 1 }}>
            {groupedFiles.map((group) => (
              <div
                key={group.groupId}
                className={`ui-file-list-group ${group.group ? 'ui-file-list-group-has-label' : 'ui-file-list-group-no-label'}`}
                style={{ visibility: ready ? 'visible' : 'hidden' }}
              >
                {group.group && <div>{group.group}</div>}

                {group.files.length <= 0 ? (
                  <div className="no-rows-overlay ui-text-center" style={{ paddingTop: 100, paddingBottom: 10 }}>
                    <h3>{noFilesLabel || t('General.NoFiles')}</h3>
                    {canCreate && (
                      <div className="buttons ui-flex" style={{ justifyContent: 'center' }}>
                        <Link
                          to={pathname}
                          className="ui-btn ui-btn-primary ui-btn-solid"
                          onClick={(e) => {
                            e.preventDefault()
                            if (onUpload) onUpload()
                          }}
                        >
                          {noFilesUploadLabel || t('General.UploadFiles')}
                        </Link>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>{group.files.map((f, idx) => renderFile(f, idx))}</div>
                )}
              </div>
            ))}
          </div>
        ))}

      {ready && files && (
        <>
          {files.length <= 0 && (
            <div className="no-rows-overlay ui-text-center" style={{ paddingTop: 100, paddingBottom: 10 }}>
              {noFilesLabel || t('General.NoFiles')}
              {canCreate && (
                <div className="buttons ui-flex" style={{ justifyContent: 'center' }}>
                  <Link
                    to={pathname}
                    className="ui-btn ui-btn-primary ui-btn-solid"
                    onClick={(e) => {
                      e.preventDefault()
                      if (onUpload) onUpload()
                    }}
                  >
                    {noFilesUploadLabel || t('General.UploadFiles')}
                  </Link>
                </div>
              )}
            </div>
          )}

          {files.map((f, idx) => renderFile(f, idx))}
        </>
      )}
    </div>
  )
}
