import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Fuse from 'fuse.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons'

import { NewProjectModel } from './model'
import { Account } from 'services/src/dto/account'
import { Footer } from './Footer'

import { useSaving } from 'components/src/saving'
import { TextField } from 'components/src/textField'
import { Link } from 'react-router-dom'
import { makeDashboardPath } from 'services/src/dom'
import { useCurrentUserAccount } from 'services/src/state'

export interface AccountCardProps {
  account: Account
  selected?: boolean
  onSelect: (account: Account) => void
}

export const AccountCard: React.FC<AccountCardProps> = ({ account, selected, onSelect }) => {
  const overview = useMemo(() => account.overview?.split('\n') || [], [account])

  return (
    <div className={`ui-card ui-action-item${selected ? ' selected' : ''}`} style={{ marginBottom: 10 }} onClick={() => onSelect(account)}>
      <div className="ui-flex">
        <div style={{ width: 70, minWidth: 70 }}>
          <div className="ui-picture-circle ui-picture-circle-md">
            {account.logoUrl ? <div style={{ backgroundImage: `url('${account.logoUrl}')` }} /> : <div>{account.companyName[0].toUpperCase()}</div>}
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <h2>{account.companyName}</h2>
          {account.tagLine && <h5 className="ui-text-italic">{account.tagLine}</h5>}
          <div className="ui-flex">
            <div style={{flex: 0.75}}>
              {overview.map((l, i) => (
                <p key={i}>{l}</p>
              ))}
            </div>
            <div style={{marginLeft: 40}}>
              {account.offices?.map(o => {
                return (
                  <Fragment key={o.id}>
                    <strong>{o.name}</strong>
                    {o.address?.municipality && <div>{o.address?.municipality}{o.address?.countrySubdivisionName && <span>, {o.address?.countrySubdivisionName}</span>}</div>}
                    {o.address?.country && <div>{o.address?.country}</div>}
                  </Fragment>
                )
              })}
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}


export interface SelectAccountProps {
  model: NewProjectModel
  which: 'account' | 'provider'
  active: boolean
  step: number
  isLastStep: boolean
  onChangeModel: (model: NewProjectModel) => void
  onBack: () => void
  onNext: () => void
  onCancel: () => void
}

export const SelectAccount: React.FC<SelectAccountProps> = ({ model, which, active, step, isLastStep, onChangeModel, onBack, onNext, onCancel }) => {
  const { t } = useTranslation()
  const [saving] = useSaving()
  const [account] = useCurrentUserAccount()

  const readyAccounts = useMemo(() => {
    const { search, accounts } = model.ext[which]
    if (!search) return accounts

    const fuse = new Fuse(accounts, { keys: ['companyName'] })
    return fuse.search(search).map(x => x.item)
  }, [model, which])

  const [errors, setErrors] = useState<any>({})
  const clearError = (name: string) => {
    const theErrors = { ...errors }
    delete theErrors[name]
    setErrors(theErrors)
  }

  const handleSelect = useCallback((selected: Account) => {
    clearError('account')
    onChangeModel({
      ...model,
      ext: {
        ...model.ext,
        [which]: { ...model.ext[which], selected }}
    });
  }, [model])

  const submit = useCallback((e?: React.FormEvent) => {
    if (e) e.preventDefault()

    const errors: any = []

    if (!model.ext[which].selected) errors.account = t(`NewProject.${which}.SelectRequired`)

    setErrors(errors)
    if (Object.keys(errors).length > 0) return

    onNext()
  }, [model, onNext, setErrors, t])

  if (!active) return null;

  const { search, accounts, selected } = model.ext[which]

  return (
    <div className="new-project-account-select">
      <div className="new-project-account-select-top">
        <div>
          <div className="new-project-step-header" style={{ marginBottom: '1rem' }}>
            <div>{t('General.StepN', { N: step + 1 })}</div>
            <div>{t(accounts.length <= 1 ? `NewProject.${which}.TitleNoSelect` : `NewProject.${which}.Title`)}</div>
          </div>
          <div className={errors.account ? ' ui-has-error' : undefined}>
            <div className="ui-error ui-text-md ui-text-center" style={{ height: '1.5rem', paddingTop: 5 }}>
              {errors.account}
            </div>
          </div>

          {accounts.length > 1 && (
            <div className="ui-flex ui-flex-align-center">
              <div style={{ flex: 1 }}>
                <TextField
                  name="search"
                  value={search}
                  label={t(`NewProject.${which}.Search`)}
                  placeholder={t(`NewProject.${which}.SearchSample`)}
                  disabled={saving}
                  onChange={(search: string) => {
                    onChangeModel({ ...model, ext: { ...model.ext, [which]: { search, accounts } } })
                  }}
                />
              </div>
              {which !== 'provider' && (
                <div style={{ marginLeft: 15 }}>
                  <label>{t(`NewProject.${which}.DontSee`)}</label>
                  <button className="ui-btn ui-btn-primary" style={{ width: 150 }}>
                    <FontAwesomeIcon icon={faCirclePlus} />
                    &nbsp;
                    {t(`NewProject.${which}.Invite`)}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <form onSubmit={submit} noValidate autoComplete="off" style={{ marginTop: accounts.length > 1 ? 180 : 130 }}>
        {accounts.length === 0 && which === 'account' && (
          <>
            <div>
              No clients are available you can create a project for. To get started, invite one or more Clients. Once the client completes the registration and onboarding process, you will be able to
              create a project for them.
            </div>
            <div style={{marginTop: 30, justifyContent: 'center'}} className="ui-flex">
              <Link to={makeDashboardPath(account?.id, 'settings/account/clients?invite=client')} className="ui-btn ui-btn-primary ui-btn-solid">Invite Clients</Link>
            </div>
          </>
        )}
        {accounts.length === 1 && <AccountCard account={selected || accounts[0]} selected={true} onSelect={() => {}} />}

        {accounts.length > 1 && (
          <>
            {readyAccounts.length <= 0 && <div>No accounts found.</div>}
            {readyAccounts.length > 0 && (
              <div className="cards">
                {readyAccounts.map((account) => (
                  <AccountCard key={account.id} account={account} selected={selected?.id === account.id} onSelect={handleSelect} />
                ))}
              </div>
            )}
          </>
        )}
      </form>

      <Footer canBack={step > 0} onBack={onBack} canNext={!isLastStep} onNext={submit} onCancel={onCancel} />
    </div>
  )
}
