import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faMobile, faGraduationCap } from '@fortawesome/pro-regular-svg-icons'
import { parsePhoneNumber } from 'libphonenumber-js'
import { NewExpertModel, NewProviderModel } from './model'

export interface OnboardingCardProps {
  model: NewExpertModel | NewProviderModel
  isProvider?: boolean
}

export const OnboardingCard: React.FC<OnboardingCardProps> = ({ model, isProvider }) => {
  const { t } = useTranslation()

  const mobilePhone = useMemo(() => {
    if (!model.mobilePhone) return undefined

    const p = parsePhoneNumber(model.mobilePhone.startsWith('+') ? model.mobilePhone : `+${model.mobilePhone}`)
    return p.formatNational()
  }, [model])

  const businessPhone = useMemo(() => {
    if (!model.phone) return undefined

    const p = parsePhoneNumber(model.phone.startsWith('+') ? model.phone : `+${model.phone}`)
    return p.formatNational()
  }, [model])

  const skills = useMemo(() => model.skills.filter((x) => x.selected).map((x) => x.label), [model])

  if (isProvider) {
    const m = model as NewProviderModel;
    return (
      <div className="ui-row">
        <div className="ui-col-12">
          <div className="expert-onboarding-card">
            <div className="ui-flex ui-flex-column" style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
              <div className="ui-flex ui-flex-nowrap">
                <div className="ui-picture-circle" style={{ width: 65, height: 65, minWidth: 65, minHeight: 65, overflow: 'hidden' }}>
                  {m.logo ? (
                    <div style={{ backgroundImage: `url('${m.logo.preview}')`, border: 'none', borderRadius: 40 }} />
                  ) : (
                    <div style={{ lineHeight: '65px', fontSize: '35px', borderRadius: 40 }}>
                      {m.companyName[0].toUpperCase()}
                    </div>
                  )}
                </div>

                <div className="ui-flex ui-flex-column" style={{ marginLeft: 15, marginTop: 10 }}>
                  <div className="ui-text-bold" style={{ fontSize: '1.75rem' }}>
                    {m.companyName}
                  </div>

                  {skills.length > 0 && (
                    <div className="ui-flex" style={{ flexWrap: 'wrap', minHeight: 25 }}>
                      {skills.map((s, idx) => (
                        <div key={idx} className="ui-badge" style={{ marginTop: 5, marginRight: 3 }}>
                          {s}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="ui-flex ui-flex-column" />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="ui-row">
      <div className="ui-col-12">
        <div className="expert-onboarding-card">
          <div className="ui-flex ui-flex-column" style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
            <div className="ui-flex ui-flex-nowrap">
              <div className="ui-picture-circle" style={{ width: 65, height: 65, minWidth: 65, minHeight: 65, overflow: 'hidden' }}>
                {model.picture ? (
                  <div style={{ backgroundImage: `url('${model.picture.preview}')`, border: 'none', borderRadius: 40 }} />
                ) : (
                  <div style={{ lineHeight: '65px', fontSize: '35px', borderRadius: 40 }}>
                    {model.givenName && model.givenName[0].toUpperCase()}
                    {model.familyName && model.familyName[0].toUpperCase()}
                  </div>
                )}
              </div>

              <div className="ui-flex ui-flex-column" style={{ marginLeft: 15 }}>
                <div className="ui-text-bold" style={{ fontSize: '1.75rem' }}>
                  {model.givenName} {model.familyName}
                </div>

                {(model.expertRole || model.primaryDiscipline) && (
                  <div className="ui-flex ui-flex-nowrap">
                    {model.expertRole && (
                      <div className="ui-badge ui-badge-secondary" style={{ marginRight: 5 }}>
                        {t(`General.ExpertRoles.${model.expertRole}`)}
                      </div>
                    )}
                    {model.primaryDiscipline && <div className="ui-badge ui-badge-primary">{t(`General.Disciplines.${model.primaryDiscipline}`)}</div>}
                  </div>
                )}

                {skills.length > 0 && (
                  <div className="ui-flex" style={{ flexWrap: 'wrap', minHeight: 25 }}>
                    {skills.map((s, idx) => (
                      <div key={idx} className="ui-badge" style={{ marginTop: 5, marginRight: 3 }}>
                        {s}
                      </div>
                    ))}
                  </div>
                )}

                {model.emails?.length && (
                  <div className="ui-flex ui-flex-nowrap ui-text-sm">
                    <div style={{ width: 15, marginRight: 5 }} className="ui-text-center">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                    <div className="ui-text-ellipsis">{model.emails[0].email}</div>
                  </div>
                )}
                <div className="ui-flex ui-flex-nowrap">
                  {businessPhone && (
                    <div className="ui-flex ui-flex-nowrap ui-text-sm" style={{ marginRight: 15 }}>
                      <div style={{ width: 15, marginRight: 5 }} className="ui-text-center">
                        <FontAwesomeIcon icon={faPhone} />
                      </div>
                      <div className="ui-text-ellipsis">
                        {businessPhone}
                        {model.extension && <span style={{ marginLeft: 5 }}>x{model.extension}</span>}
                      </div>
                    </div>
                  )}
                  {mobilePhone && (
                    <div className="ui-flex ui-flex-nowrap ui-text-sm">
                      <div style={{ width: 15, marginRight: 5 }} className="ui-text-center">
                        <FontAwesomeIcon icon={faMobile} />
                      </div>
                      <div className="ui-text-ellipsis">{mobilePhone}</div>
                    </div>
                  )}
                </div>

                {model.schoolName && model.educationLevel && (
                  <h5 style={{ marginTop: 5 }}>
                    <FontAwesomeIcon style={{ marginRight: 10 }} icon={faGraduationCap} />
                    {`${model.schoolName}, ${model.educationLevel}`}
                  </h5>
                )}
              </div>
            </div>

            <div className="ui-flex ui-flex-column" />
          </div>
        </div>
      </div>
    </div>
  )
}
