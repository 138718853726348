import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { AccountList as AccountListCmp } from 'components/src/accountList'
import { makeDashboardPath, setTitle } from 'services/src/dom'

import './style.scss'
import { useHistory } from 'react-router-dom'
import { useCurrentUserAccount } from 'services/src/state'

export const AccountList: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [account] = useCurrentUserAccount()

  useEffect(() => {
    setTitle(t('Accounts.Title'))
    return () => {
      setTitle(undefined)
    }
  }, [t])

  return (
    <div className="account-index" style={{ paddingBottom: 0 }}>
      <AccountListCmp
        sortKey="providerAccountsSort"
        filterKey="providerAccountsFilter"
        onSelect={(a, e: any) => {
          if (!account?.id) return

          const ev = e.event as any
          if (ev?.metaKey) {
            window.open(makeDashboardPath(account.id, `accounts/${a.id}`))
            return
          }
          history.push(makeDashboardPath(account.id, `accounts/${a.id}`));
        }}
      />
    </div>
  )
}
