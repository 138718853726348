import React from 'react'
import { Account, Office } from 'services/src/dto/account'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/pro-duotone-svg-icons'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import MenuSvg from 'components/src/svg/Menu'
import MenuCloseSvg from 'components/src/svg/MenuClose'
import MenuOpenSvg from 'components/src/svg/MenuOpen'

const OfficeSubheader: React.FC<{
  account?: Account
  office?: Office
  onInvite?: () => void
  onToggleAside: () => void
  asideOpen: boolean
  canEditOffice: boolean
}> = ({ account, office, onInvite, onToggleAside, asideOpen, canEditOffice }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  return (
    <div className="office-edit-subheader">
      <div className="left">
        <div style={office?.id ? undefined : { marginTop: 0 }}>
          <div>
            <Link to={`/dashboard/${account!.id}/offices`} replace>
              {t('General.Offices')}
            </Link>
          </div>
          <div>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
          {(() => {
            if (!office?.id) return <div className="ui-text-muted">{t('General.Create')}</div>
            return (
              <>
                <div>{office.name}</div>
                <div>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
                {(() => {
                  if (pathname.endsWith('staff')) return <div>{t('General.Staff')}</div>

                  return <div>{t('General.Profile')}</div>
                })()}
              </>
            )
          })()}
        </div>
        <div className="ui-text-xs">
          {office?.id ? (
            <>
              <div className={`ui-flex ui-flex-nowrap menu${asideOpen ? ' open' : ''}`} role="button" tabIndex={-1} onKeyDown={() => {}} onClick={() => onToggleAside()}>
                <MenuSvg width={15} />
                <div style={{ margin: '0 5px' }}>{t('General.Menu')}</div>
                {asideOpen ? <MenuCloseSvg width={15} /> : <MenuOpenSvg width={15} />}
              </div>
              <div className="ui-flex ui-flex-nowrap ui-text-muted" style={{ marginLeft: 20 }}>
                <div>{t('Offices.UserCount', { count: office.users?.length || 0 })}</div>
                <div style={{ marginLeft: 10 }}>|</div>
                <div style={{ marginLeft: 10 }}>{t('Offices.ProjectCount', { count: office.counts?.total || 0 })}</div>
              </div>
            </>
          ) : (
            <div>&nbsp;</div>
          )}
        </div>
      </div>
      <div className="right" style={{ paddingTop: 4 }}>
        {canEditOffice && office?.id && !!onInvite && (
          <a
            href={pathname}
            className="ui-btn ui-btn-primary ui-btn-circle ui-btn-invert"
            onClick={(e) => {
              e.preventDefault()
              onInvite()
            }}
          >
            <FontAwesomeIcon icon={faUserPlus} />
          </a>
        )}
      </div>
    </div>
  )
}
export default OfficeSubheader
