import { Loading } from 'components/src/loading';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostRequests } from 'services/src/api/boe';
import { ProjectStatus } from 'services/src/dto/projectShare';
import { endpoints } from 'services/src/lib/axios';
import { useEditProject } from 'services/src/state';
import { TextAreaField } from '../../../textAreaField';
import { BoeProjectSectionSubheader } from './BoeProjectSectionSubheader';
import ProjectBoeProgressBar from './ProjectBoeProgressBar';

interface ProjectBoeBackgroundSectionProps {
  handleNext: () => void;
  handlePrevious: () => void;
  setBoeData: (data: any) => void;
  boeData: any;
  isProgressBarVisible: boolean;
  setIsProgressBarVisible: (value: boolean) => void;
}

interface Errors {
  [key: string]: string | undefined;
  background?: string;
}

const BoeProject14: React.FC<ProjectBoeBackgroundSectionProps> = ({
  handleNext,
  handlePrevious,
  setBoeData,
  boeData,
  isProgressBarVisible,
  setIsProgressBarVisible,
}) => {
  const { t } = useTranslation();
  const { project } = useEditProject();
  const progress = useMemo(() => 12, []);
  const [call, setCall] = useState<boolean>(false);
  const [background, setBackground] = useState<string>(boeData?.background || '');
  const [errors, setErrors] = useState<Errors>({});
  const [phase, setPhase] = useState<string>('');
  const [x, setX] = useState<string>('');

  const positionWords = [
    'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth',
    'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth',
    'eighteenth', 'nineteenth', 'twentieth', 'twenty-first', 'twenty-second', 'twenty-third',
    'twenty-fourth', 'twenty-fifth', 'twenty-sixth', 'twenty-seventh', 'twenty-eighth', 'twenty-ninth',
    'thirtieth', 'thirty-first', 'thirty-second', 'thirty-third', 'thirty-fourth', 'thirty-fifth',
    'thirty-sixth', 'thirty-seventh'
  ];

  useEffect(() => {
    if (project?.phases) {
      const phase = project.phases.find((phase: any) => phase.status === ProjectStatus.InProgress);
      const position = project.phases.findIndex((phase: any) => phase.status === ProjectStatus.InProgress);
      const positionInWords = positionWords[position] || 'none';

      setX(positionInWords);
      setPhase(phase?.phaseType || '');
    }
  }, [project?.phases, positionWords]);

  const clearError = (name: string) => {
    setErrors((current) => {
      const newErrors = { ...current };
      delete newErrors[name];
      return newErrors;
    });
  };

  const { loading, data, error, postRequests } = usePostRequests();

  useEffect(() => {
    if (phase && !call && background === '') {
      setCall(true);
      postRequests(endpoints.boe.projectBackground, { X: x, PHASE: phase });
    }
  }, [x, phase, call, postRequests, background]);

  useEffect(() => {
    if (data?.response) {
      setBackground(data?.response || '');
    }
  }, [data?.response]);

  const handleNextClick = () => {
    setBoeData((prev: any) => ({ ...prev, background }));
    handleNext();
  };

  return (
    <div className="boe-content">
      <div className="boe-form-content">
        <ProjectBoeProgressBar
          progress={progress}
          title={t('BasisOfEstimate.Background.ProgressTitle')}
          leftText={t('BasisOfEstimate.Background.ProgressLeftText')}
          rightText={t('BasisOfEstimate.Background.ProgressRightText')}
          isProgressBarVisible={isProgressBarVisible}
          setIsProgressBarVisible={setIsProgressBarVisible}
          boeData={boeData}
        />
        <BoeProjectSectionSubheader title={t('BasisOfEstimate.BoeProject14.Title')} dynamicText={t('BasisOfEstimate.BoeProject14.Dynamic')} boeData={boeData} />
        {loading && <Loading />}
        {error && (
          <div className="boe-error-container">
            <p>{t('General.Error')}</p>
          </div>
        )}
        <TextAreaField
          name="background"
          label=""
          placeholder=""
          autoFocus
          error={errors.background}
          style={{ height: 324 }}
          value={background}
          onChange={(e) => {
            setBackground(e);
            clearError('background');
          }}
        />
      </div>
      <div className="boe-form-action-container">
        <button type="button" disabled={loading} className="btn-next" onClick={handlePrevious}>
          {t('General.Previous')}
        </button>
        <button type="button" className="btn-previous" onClick={handleNextClick}>
          {t('General.Next')}
        </button>
      </div>
    </div>
  );
};

export default BoeProject14;