import React, { useState, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/pro-duotone-svg-icons'

import { lookupAddress } from 'services/src/api'
import { Address, Client, EmailAddress, PhoneNumber } from 'services/src/dto/account'

import { TextField } from 'components/src/textField'
import { Modal, ModalProps } from 'components/src/modal'
import { AutoComplete } from 'components/src/autoComplete'
import { validateEmail } from 'services/src/validate'
import { useGeolocation } from 'services/src/state'

export type NewClientProps = ModalProps & {
  client?: Client
  onNew: (client: Client) => void
}

export const NewClient: React.FC<NewClientProps> = ({ client, onClose, onNew }) => {
  const { t } = useTranslation()
  const { country_code: geo } = useGeolocation()

  const [errors, setErrors] = useState<any>({})
  const clearError = (name: string) => {
    const theErrors = { ...errors }
    delete theErrors[name]
    setErrors(theErrors)
  }

  const [name, setName] = useState(client?.name || '')
  const [email, setEmail] = useState(() => {
    if (!client || !client.emailAddresses) return ''
    return client.emailAddresses.find((x) => x.label === 'Default')?.email || ''
  })
  const [phone, setPhone] = useState(() => {
    if (!client || !client.phoneNumbers) return ''
    return client.phoneNumbers.find((x) => x.label === 'Default')?.number || ''
  })

  const addressLookupTimer = useRef<any>()
  const [address, setAddress] = useState<Address>()
  const [addressName, setAddressName] = useState(() => {
    if (!client || !client.addresses || !client.addresses.length) return ''
    return client.addresses[0].freeformAddress || ''
  })
  const [addressSuggestions, setAddressSuggestions] = useState<any[]>([])

  const [contactName, setContactName] = useState(client?.contactName || '')
  const [contactEmail, setContactEmail] = useState(() => {
    if (!client || !client.emailAddresses) return ''
    return client.emailAddresses.find((x) => x.label === 'Primary Contact')?.email || ''
  })
  const [contactPhone, setContactPhone] = useState(() => {
    if (!client || !client.phoneNumbers) return ''
    return client.phoneNumbers.find((x) => x.label === 'Primary Contact')?.number || ''
  })

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      const errors: any = {}

      if (!name) errors.name = t('General.Errors.Required')

      if (email && !validateEmail(email)) {
        errors.email = t('General.Errors.InvalidEmail')
      }
      // if (!phone) errors.phone = t('General.Errors.Required');

      let theAddress = address
      if (!address) {
        if (addressName) theAddress = { freeformAddress: addressName }
      }

      if (contactEmail && !validateEmail(contactEmail)) {
        errors.contactEmail = t('General.Errors.InvalidEmail')
      }

      setErrors(errors)
      if (Object.keys(errors).length > 0) return

      const emailAddresses: EmailAddress[] = []
      if (phone)
        emailAddresses.push({
          label: 'Default',
          email
        })
      if (contactEmail)
        emailAddresses.push({
          label: 'Primary Contact',
          email: contactEmail
        })

      const phoneNumbers: PhoneNumber[] = []
      if (phone)
        phoneNumbers.push({
          label: 'Default',
          number: phone
        })
      if (contactPhone)
        phoneNumbers.push({
          label: 'Primary Contact',
          number: contactPhone
        })

      onNew({
        name,
        addresses: theAddress ? [{ ...theAddress, label: 'Default' }] : [],
        emailAddresses,
        phoneNumbers,
        contactName
      } as Client)
    },
    [onNew, setErrors, name, email, phone, address, contactName, contactEmail, contactPhone, addressName, t]
  )

  return (
    <Modal header={<h3>{t('General.AddClient')}</h3>} closeOnClickOutside={false}>
      <form onSubmit={submit} noValidate autoComplete="off">
        <input name="hidden" placeholder="name" style={{ display: 'none' }} />

        <TextField
          name="name"
          value={name}
          error={errors.name}
          label={t('General.BusinessName')}
          onChange={(v: string) => {
            setName(v)
            clearError('name')
          }}
        />

        <TextField
          name="email"
          value={email}
          type="email"
          error={errors.email}
          label={
            <span>
              {t('General.BusinessEmail')} <span className="ui-text-muted">{t('General.Optional')}</span>
            </span>
          }
          onChange={(v: string) => {
            setEmail(v)
            clearError('email')
          }}
        />

        <TextField
          name="phone"
          value={phone}
          error={errors.phone}
          type="tel"
          label={
            <span>
              {t('General.BusinessPhone')} <span className="ui-text-muted">{t('General.Optional')}</span>
            </span>
          }
          onChange={(v: string) => {
            setPhone(v)
            clearError('phone')
          }}
        />

        <div className={`ui-form-group ${errors.address ? 'ui-has-error' : ''}`}>
          <label htmlFor="type">
            <span>
              {t('General.OfficeAddress')} <span className="ui-text-muted">{t('General.Optional')}</span>
            </span>
          </label>

          <AutoComplete
            suggestions={addressSuggestions}
            value={addressName}
            onValueChange={(v) => {
              clearError('address')
              setAddressName(v)
              setAddress(undefined)

              clearTimeout(addressLookupTimer.current)
              addressLookupTimer.current = setTimeout(() => {
                lookupAddress(v, geo ? geo.country_code : 'US').then((result) => setAddressSuggestions(result))
              }, 100)
            }}
            onSelectSuggestion={(address: Address) => {
              clearError('address')
              setAddress(address)
              setAddressName(address.freeformAddress || '')
              setAddressSuggestions([])
            }}
            render={(address: Address) => (
              <div>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                &nbsp;&nbsp;{address.freeformAddress}
              </div>
            )}
          />
          {errors.address && <div className="ui-error">{errors.address}</div>}
        </div>

        <hr />
        <h3>{t('General.PrimaryContact')}</h3>

        <TextField
          name="contactName"
          value={contactName}
          error={errors.contactName}
          label={
            <span>
              {t('General.Name')} <span className="ui-text-muted">{t('General.Optional')}</span>
            </span>
          }
          onChange={(v: string) => {
            setContactName(v)
            clearError('contactName')
          }}
        />

        <TextField
          name="contactEmail"
          value={contactEmail}
          error={errors.contactEmail}
          type="email"
          label={
            <span>
              {t('General.BusinessEmail')} <span className="ui-text-muted">{t('General.Optional')}</span>
            </span>
          }
          onChange={(v: string) => {
            setContactEmail(v)
            clearError('contactEmail')
          }}
        />

        <TextField
          name="contactPhone"
          value={contactPhone}
          error={errors.contactPhone}
          type="tel"
          label={
            <span>
              {t('General.BusinessPhone')} <span className="ui-text-muted">{t('General.Optional')}</span>
            </span>
          }
          onChange={(v: string) => {
            setContactPhone(v)
            clearError('contactPhone')
          }}
        />

        <div className="ui-row">
          <div className="ui-col-3">
            <button
              type="button"
              className="ui-btn ui-btn-secondary ui-btn-solid"
              style={{ width: '100%' }}
              onClick={() => {
                if (onClose) onClose()
              }}
            >
              {t('General.Cancel')}
            </button>
          </div>
          <div className="ui-offset-3 ui-col-6">
            <button type="submit" className="ui-btn ui-btn-primary ui-btn-solid" style={{ width: '100%' }}>
              {t('General.AddClient')}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
