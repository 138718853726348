import React from 'react'
import { Account, User } from 'services/src/dto/account'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { makeDashboardPath } from 'services/src/dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import MenuSvg from 'components/src/svg/Menu'
import MenuCloseSvg from 'components/src/svg/MenuClose'
import MenuOpenSvg from 'components/src/svg/MenuOpen'
import { faUserPlus } from '@fortawesome/pro-duotone-svg-icons'
import { useRights, UserType } from 'services/src/state'
import moment from 'moment'

export const SettingsSubheader: React.FC<{
  account?: Account
  me?: User
  onInvite: () => void
  onToggleAside: () => void
  asideOpen: boolean
}> = ({ account, me, onInvite, onToggleAside, asideOpen }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const rights = useRights()

  return (
    <div className="settings-subheader">
      <div className="left">
        <div style={account ? undefined : { marginTop: 0 }}>
          <div>
            <Link to={makeDashboardPath(account?.id, 'settings/user')} replace>
              {t('General.Settings')}
            </Link>
          </div>
          <div>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>

          {account && (
            <>
              {location.pathname.includes('/account') && (
                <>
                  <div>{account.companyName}</div>
                  <div>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </>
              )}
              {(() => {
                if (location.pathname.endsWith('staff/invitations')) {
                  return (
                    <>
                      <div>{t('General.Staff')}</div>
                      <div><FontAwesomeIcon icon={faChevronRight} /></div>
                    </>
                  )
                }

                if (location.pathname.endsWith('clients/invitations')) {
                  return (
                    <>
                      <div>{t('General.Clients')}</div>
                      <div><FontAwesomeIcon icon={faChevronRight} /></div>
                    </>
                  )
                }

                if (location.pathname.endsWith('user/experience') || location.pathname.endsWith('user/employment') || location.pathname.endsWith('user/security')) {
                  return (
                    <>
                      <div>{t('General.Profile')}</div>
                      <div><FontAwesomeIcon icon={faChevronRight} /></div>
                    </>
                  )
                }

                return null
              })()}
              {(() => {
                if (location.pathname.endsWith('clients')) return <div>{t('General.Clients')}</div>

                if (location.pathname.endsWith('staff')) return <div>{t('General.Staff')}</div>

                if (location.pathname.endsWith('invitations')) return <div>{t('General.Invitations')}</div>

                if (location.pathname.endsWith('account/experience')) return <div>{t('Settings.Provider.ProjectExperience')}</div>

                if (location.pathname.endsWith('experience')) return <div>{t('Settings.Provider.YourExperience')}</div>

                if (location.pathname.endsWith('employment')) return <div>{t('Settings.Provider.Employment')}</div>

                if (location.pathname.endsWith('security')) return <div>{t('General.Security')}</div>

                return <div>{t('General.Profile')}</div>
              })()}
            </>
          )}
        </div>
        <div className="ui-text-xs">
          {account ? (
            <>
              {rights?.userType !== UserType.expert && (
                <div
                  className={`ui-flex ui-flex-nowrap menu${asideOpen ? ' open' : ''}`}
                  style={{ marginRight: 20 }}
                  role="menuitem"
                  tabIndex={-1}
                  onClick={() => onToggleAside()}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') onToggleAside()
                  }}
                >
                  <MenuSvg width={15} />
                  <div style={{ marginLeft: 5, marginRight: 5 }}>{t('General.Menu')}</div>
                  {asideOpen ? <MenuCloseSvg width={15} /> : <MenuOpenSvg width={15} />}
                </div>
              )}

              {account?.counts && location.pathname.includes('/account') ? (
                <div className="ui-flex ui-flex-nowrap ui-text-muted">
                  <div>{t('Settings.UserCount', { count: account!.counts.userTotal })}</div>
                  <div style={{ marginLeft: 10 }}>|</div>
                  <div style={{ marginLeft: 10 }}>{t('Settings.OfficeCount', { count: account!.counts.offices })}</div>
                  {rights?.userType === UserType.client && (
                    <>
                      <div style={{ marginLeft: 10 }}>|</div>
                      <div style={{ marginLeft: 10 }}>{t('Settings.ProjectCount', { count: account!.counts.projects })}</div>
                    </>
                  )}
                </div>
              ) : (
                <div className="ui-text-muted">{me?.onboardedAt ? t('Settings.CreatedAt', { date: moment(me.onboardedAt).toDate() }) : <>&nbsp;</>}</div>
              )}
            </>
          ) : (
            <div>&nbsp;</div>
          )}
        </div>
      </div>
      <div className="right" style={{ paddingTop: 4 }}>
        {account && onInvite && rights?.canInviteAccount && (
          <a
            href={location.pathname}
            className="ui-btn ui-btn-primary ui-btn-circle ui-btn-invert"
            style={{ marginRight: 10 }}
            onClick={(e) => {
              e.preventDefault()
              onInvite()
            }}
          >
            <FontAwesomeIcon icon={faUserPlus} />
          </a>
        )}
      </div>
    </div>
  )
}
