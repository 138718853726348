import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectStatus } from 'services/src/dto/projectShare'
import { FeeGridViewModel, FeeGridViewModelEstimate } from './common'

export const FeeGridProfit: React.FC<{
  vm: FeeGridViewModel
  estimate: FeeGridViewModelEstimate
}> = ({ vm, estimate }) => {
  const { t } = useTranslation()

  const { margin, marginClass } = useMemo(() => {
    const laborTotal = estimate.labor.reduce((total, l) => (total + l.status === ProjectStatus.Rejected ? 0 : l.cost), 0)
    const subtotal = estimate.estimate.negotiatedTotals?.subtotal || estimate.subtotal || 0
    const margin = subtotal > 0 && laborTotal > 0 ? (subtotal - laborTotal) / subtotal : null

    let marginClass = ''
    if (margin !== null) {
      if (margin <= 0.25) marginClass = 'ui-danger ui-text-bold'
      else if (margin > 0.25 && margin <= 0.5) marginClass = 'ui-warn ui-text-bold'
      else if (margin > 0.5) marginClass = 'ui-success ui-text-bold'
    }

    return { margin, marginClass }
  }, [estimate])

  if (vm.variant === 'assign') return null

  return (
    <>
      <div className="ui-text-sm" style={{ marginLeft: 20 }}>
        <div className="ui-flex ui-text-center ui-text-xxs ui-text-uppercase ui-text-bold ui-text-muted" style={{ marginBottom: 5 }}>
          <div style={{ width: 60 }}>{t('EditProject.Fees.FeeMargin')}</div>
        </div>

        <div className="ui-flex ui-frame ui-frame-no-padding ui-text-center" style={{ height: 25, width: 60, lineHeight: '23px' }}>
          <span className={marginClass}>{margin === null ? '--' : t('General.PercentValue', { value: (margin * 100).toFixed(1) })}</span>
        </div>
      </div>
    </>
  )
}
