import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { ResourceUserRole } from 'services/src/dto/account'
import { InvitationStatus } from 'services/src/dto/invitation'
import { PhaseEx, ProjectEx, useCurrentUserAccount, useEditProject, useRights, UserType } from 'services/src/state'
import { InvitationList } from '../../settings/InvitationList'
import { InviteModal } from '../common/InviteModal'
import { PhaseDetails } from './PhaseDetails'
import { PhaseEstimate } from './PhaseEstimate'
import { PhaseFiles } from './PhaseFiles'
import { PhaseOverview } from './PhaseOverview'
import { PhaseStaff } from './PhaseStaff'
// import BoeRightSideNav from './boe/BoeRightSideNav'
import BasisOfEstimate from '../boe/BasisOfEstimate'
import { ExpertEstimate } from './expert/ExpertEstimate'
import { FeeGrid } from './feeGrid/FeeGrid'

export const PhaseEdit: React.FC = () => {
  const { t } = useTranslation()
  const {
    url,
    path,
    params: { phaseId }
  } = useRouteMatch<{ phaseId: string }>()
  const rights = useRights()
  const [account] = useCurrentUserAccount()

  const { project, setProject } = useEditProject()

  const [invite, setInvite] = useState<{ project: ProjectEx; phase?: PhaseEx } | undefined>()
  const readyInvitations = useMemo(
    () => project?.accountWithUsers?.invitations.filter((i) => !!i.phaseRole && i.phaseId === phaseId && i.status === InvitationStatus.Pending),
    [project.accountWithUsers]
  )

  const roles = useMemo(
    () => [
      {
        role: ResourceUserRole.Owner,
        label: t(`General.PhaseRoles.${ResourceUserRole.Owner}`)
      },
      {
        role: ResourceUserRole.Administrator,
        label: t(`General.PhaseRoles.${ResourceUserRole.Administrator}`)
      },
      {
        role: ResourceUserRole.Contributor,
        label: t(`General.PhaseRoles.${ResourceUserRole.Contributor}`)
      },
      {
        role: ResourceUserRole.Guest,
        label: t(`General.PhaseRoles.${ResourceUserRole.Guest}`)
      }
    ],
    [rights, t]
  )

  if (!rights) return null

  return (
    <>
      <Switch>
        <Route path={path} exact>
          <div className="edit-project-content edit-project-content-full">
            <div className="edit-project-main">
              <PhaseOverview phaseId={phaseId} />
            </div>
          </div>
        </Route>

        <Route path={`${path}/edit`}>
          <div className="edit-project-content edit-project-content-full">
            <div className="edit-project-main">{rights?.userType !== UserType.expert ? <PhaseDetails phaseId={phaseId} /> : <Redirect to={url} />}</div>
          </div>
        </Route>

        <Route path={`${path}/fees`} exact>
          <div className="edit-project-content edit-project-content-full">
            <div className="edit-project-main">{rights?.userType === UserType.provider ? <FeeGrid phaseId={phaseId} /> : <Redirect to={url} />}</div>
          </div>
        </Route>

        <Route path={`${path}/assignments`} exact>
          <div className="edit-project-content edit-project-content-full">
            <div className="edit-project-main">{rights?.userType === UserType.provider ? <FeeGrid phaseId={phaseId} variant="assign" /> : <Redirect to={url} />}</div>
          </div>
        </Route>

        <Route path={`${path}/files`}>
          <div className="edit-project-content edit-project-content-full">
            <div className="edit-project-main">
              <PhaseFiles phaseId={phaseId} />
            </div>
          </div>
        </Route>

        <Route path={`${path}/expert-estimate`}>
          <div className="edit-project-content edit-project-content-full">
            <div className="edit-project-main">
              <ExpertEstimate phaseId={phaseId} />
            </div>
          </div>
        </Route>

        <Route path={`${path}/estimate`}>
          <div className="edit-project-content edit-project-content-full">
            <div className="edit-project-main">
              <PhaseEstimate phaseId={phaseId} />
            </div>
          </div>
        </Route>

        <Route path={`${path}/invitations`}>
          <div className="edit-project-content edit-project-content-full">
            <div className="edit-project-main">
              {rights?.userType !== UserType.expert ? (
                <InvitationList
                  invitations={readyInvitations}
                  roles={roles}
                  onNew={() => setInvite({ project, phase: project.phases.find((ph) => ph.id === phaseId) })}
                  onResend={() => { }}
                  onDelete={() => { }}
                />
              ) : (
                <Redirect to={url} />
              )}
            </div>
          </div>
        </Route>

        <Route path={`${path}/staff`}>
          <div className="edit-project-content edit-project-content-full">
            <div className="edit-project-main">{rights?.userType !== UserType.expert ? <PhaseStaff phaseId={phaseId} /> : <Redirect to={url} />}</div>
          </div>
        </Route>

        <Route path={`${path}/boe`}>
              <BasisOfEstimate />
        </Route>

        <Redirect to={url} />
      </Switch>

      {invite && account && (
        <InviteModal
          account={account}
          project={invite.project}
          phase={invite.phase}
          onClose={(invitation) => {
            setInvite(undefined)
            if (!invitation.invitation) return

            const p: ProjectEx = JSON.parse(JSON.stringify(project))
            p.accountWithUsers.invitations.push(invitation.invitation)
            setProject(p)
          }}
        />
      )}
    </>
  )
}
