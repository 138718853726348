import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { TabBar, TabItem } from 'components/src/tabs'
import { useCurrentAccountWithUser, useMe } from 'services/src/state'
import { updateUser } from 'services/src/api'
import { useSaving } from 'components/src/saving'
import { useAlert } from 'components/src/alerts'
import { ClientSecurity } from './ClientSecurity'
import { ClientGeneral } from './ClientGeneral'
import { ClientProfileViewModel } from './model'

export const ClientProfile: React.FC = () => {
  const { t, i18n } = useTranslation()
  const {
    url,
    path,
    params: { userId }
  } = useRouteMatch<{ userId?: string }>()
  const { pathname } = useLocation()
  const history = useHistory()
  const [, setSaving] = useSaving()
  const { alertDanger, alertSuccess } = useAlert()

  const [_, refreshAccountUsers] = useCurrentAccountWithUser()
  const [me, , setMe] = useMe()
  const [vm, setVm] = useState<ClientProfileViewModel>()

  useEffect(() => {
    if (!me) return

    setVm({
      ...JSON.parse(JSON.stringify(me)),
      errors: {}
    })
  }, [me, userId, history, i18n, setVm])

  const tabs = useMemo<TabItem[]>(
    () => [
      {
        label: t('Expert.Settings.Tabs.General'),
        selected: pathname.endsWith('settings') || pathname.endsWith(userId || 'xxx'),
        to: url
      },
      {
        label: t('Expert.Settings.Tabs.Security'),
        to: `${url}/security`,
        selected: pathname.endsWith('security')
      }
    ],
    [pathname, t]
  )

  const submit = useCallback(
    (view: string, vm: ClientProfileViewModel) => {
      const u = { ...vm }
      delete u.picture
      delete u.preview

      setSaving(true)
      updateUser(null, null, u, vm.picture)
        .then((user) => {
          const isMe = user.id === me?.id

          const name = user.familyName.endsWith('s') ? `${user.givenName} ${user.familyName}'` : `${user.givenName} ${user.familyName}'s`
          alertSuccess({
            title: isMe ? t('Expert.Settings.SelfSavedTitle') : t('Expert.Settings.OtherSavedTitle', { name }),
            message: isMe ? t('Expert.Settings.SelfSaved') : t('Expert.Settings.OtherSaved', { name })
          })

          if (isMe) setMe(user)
          return refreshAccountUsers()
        })
        .catch((error) => {
          const name = vm.familyName.endsWith('s') ? `${vm.givenName} ${vm.familyName}'` : `${vm.givenName} ${vm.familyName}'s`
          alertDanger({
            title: t('Expert.Settings.Errors.SavedTitle'),
            message: vm.id === me?.id ? t('Expert.Settings.Errors.SelfSaved') : t('Expert.Settings.Errors.OtherSaved', { name }),
            error
          })
        })
        .finally(() => setSaving(false))
    },
    [setSaving, alertSuccess, alertDanger, me, setMe, t]
  )

  if (!vm) return null

  return (
    <>
      {vm && userId && me?.id !== userId && (
        <div style={{ marginBottom: 20 }}>
          <h3>
            {(() => {
              if (!vm) return null
              if (!userId || me?.id === userId) return t('Expert.Settings.YourProfile')
              if (vm.familyName.endsWith('s')) return t('Expert.Settings.OtherProfile', { name: `${vm.givenName} ${vm.familyName}'` })
              return t('Expert.Settings.OtherProfile', { name: `${vm.givenName} ${vm.familyName}'s` })
            })()}
          </h3>
          <hr />
        </div>
      )}

      <TabBar tabs={tabs} className="settings-tab-bar" />

      <Switch>
        <Route path={`${path}/security`}>
          <ClientSecurity />
        </Route>
        <Route path={path} exact>
          <ClientGeneral vm={vm} onChange={setVm} onSubmit={submit} />
        </Route>
      </Switch>
    </>
  )
}
