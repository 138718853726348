export interface Content {
  [key: string]: any
  id: string
  parentId?: string
  url: string
  urlAlt: string
  originalName: string
  originalExtension: string
  contentType: string
  size: number
  createdAt: Date
  metadata?: { [key: string]: any }
}

export interface ProjectContent extends Content {
  accountId: string
  projectId: string
  phaseId?: string
  category: ProjectContentCategory
  explodeInfo?: ExplodeInfo
  pageExplodeInfo?: ExplodePageInfo
}
export enum ProjectContentCategory {
  Drawing = 'Drawing',
  Picture = 'Picture',
  Document = 'Document',
  Estimate = 'Estimate',
  Boe = 'Boe'
}

export interface ExplodeInfo {
  startedAt?: Date
  completedAt?: Date
  error?: string
  pages?: ExplodePageInfo[]
  totalPages?: number
}

export interface ExplodePageInfo {
  pageNo: number
  pageName: string
  startedAt?: Date
  completedAt?: Date
  error?: string
}
