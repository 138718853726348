import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons'

import 'react-phone-number-input/style.css'
import { SelectField } from 'components/src/selectField'
import { TextField } from 'components/src/textField'
import moment from 'moment'
import { useRights, UserType } from 'services/src/state'
import { NewAccountModel } from '../common/model'
import { ExpertOnboardingCard } from './ExpertOnboardingCard'

export interface ExpertIndustryCertificationsProps {
  model: NewAccountModel
  onChangeModel: (model: NewAccountModel) => void
  onNext: () => void
  onBack?: () => void
}

export const ExpertIndustryCertifications: React.FC<ExpertIndustryCertificationsProps> = ({ model, onChangeModel, onNext, onBack }) => {
  const { t } = useTranslation()
  const rights = useRights()

  const [errors, setErrors] = useState<any>({})
  const clearError = (name: string) => {
    const theErrors = { ...errors }
    delete theErrors[name]
    setErrors(theErrors)
  }

  const months = useMemo<
    {
      id: number
      month: string
    }[]
  >(
    () => [
      { id: 1, month: t('General.Months.January') },
      { id: 2, month: t('General.Months.February') },
      { id: 3, month: t('General.Months.March') },
      { id: 4, month: t('General.Months.April') },
      { id: 5, month: t('General.Months.May') },
      { id: 6, month: t('General.Months.June') },
      { id: 7, month: t('General.Months.July') },
      { id: 8, month: t('General.Months.August') },
      { id: 9, month: t('General.Months.September') },
      { id: 10, month: t('General.Months.October') },
      { id: 11, month: t('General.Months.November') },
      { id: 12, month: t('General.Months.December') }
    ],
    [t]
  )

  const years = useMemo<
    {
      id: number
      year: string
    }[]
  >(() => {
    const years: { id: number; year: string }[] = []
    for (let i = 0; i < 10; i++) {
      const y = moment().year() + i
      years.push({ id: y, year: y.toString() })
    }
    return years
  }, [])

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      onNext()
    },
    [onNext]
  )

  return (
    <form noValidate onSubmit={submit}>
      <h3 className="intro">{t('Onboarding.Expert.IndustryCertificationIntro', { name: model.givenName })}</h3>
      <hr />
      <br />
      <ExpertOnboardingCard model={model} />
      <br />

      <div className="ui-text-sm ui-text-muted" style={{ marginBottom: 15 }}>
        {t('Onboarding.Expert.Step5', {
          N: rights?.userType === UserType.provider ? 4 : 5,
          M: rights?.userType === UserType.provider ? 4 : 5
        })}
      </div>

      <div>
        <TextField
          name="certName"
          value={model.currentCertification.name}
          label={t('Onboarding.Expert.CertificationName')}
          placeholder={t('Onboarding.Expert.CertificationNameSample')}
          error={errors.certName}
          onChange={(name) => {
            const currentCertification = { ...model.currentCertification, name }
            onChangeModel({ ...model, currentCertification })
            clearError('certName')
          }}
        />

        <div className="ui-flex ui-flex-nowrap">
          <div style={{ flex: 0.45, marginRight: 10 }}>
            <TextField
              name="certNo"
              value={model.currentCertification.number}
              label={t('Onboarding.Expert.CertificationNo')}
              placeholder={t('Onboarding.Expert.CertificationNoSample')}
              error={errors.certNo}
              onChange={(number) => {
                const currentCertification = { ...model.currentCertification, number }
                onChangeModel({ ...model, currentCertification })
                clearError('certNo')
              }}
            />
          </div>

          <div style={{ flex: 0.3, marginRight: 10 }}>
            <SelectField
              items={months}
              onSelectedChange={(x) => {
                const currentCertification = { ...model.currentCertification, expMonth: x.id }
                onChangeModel({ ...model, currentCertification })
                clearError('certExpMonth')
              }}
              selected={model.currentCertification.expMonth}
              renderSelected={(sel) => <div>{months[sel - 1].month}</div>}
              labelField="month"
              valueField="id"
              error={errors.certExpMonth}
              label={t('Onboarding.Expert.ExpMonth')}
            />
          </div>

          <div style={{ flex: 0.25 }}>
            <SelectField
              items={years}
              onSelectedChange={(x) => {
                const currentCertification = { ...model.currentCertification, expYear: x.id }
                onChangeModel({ ...model, currentCertification })
                clearError('certExpYear')
              }}
              selected={model.currentCertification.expYear}
              renderSelected={(sel) => <div>{sel}</div>}
              labelField="year"
              valueField="id"
              error={errors.certExpYear}
              label={t('Onboarding.Expert.ExpYear')}
            />
          </div>
        </div>

        <button
          type="button"
          className="ui-btn ui-btn-secondary ui-btn-solid"
          onClick={() => {
            const errors: any = {}
            if (!model.currentCertification.name) errors.certName = t('General.Errors.Required')
            if (!model.currentCertification.number) errors.certNo = t('General.Errors.Required')
            if (!model.currentCertification.expMonth) errors.certExpMonth = t('General.Errors.Required')
            if (!model.currentCertification.expYear) errors.certExpYear = t('General.Errors.Required')

            setErrors(errors)
            if (Object.keys(errors).length > 0) return

            const certifications = [...model.certifications, { ...model.currentCertification }]
            onChangeModel({
              ...model,
              certifications,
              currentCertification: { name: '', number: '', expYear: 0, expMonth: 0 }
            })
          }}
        >
          {t('Onboarding.Expert.AddCertification')}
        </button>
      </div>

      {model.certifications.length > 0 && (
        <div style={{ marginTop: 30, marginBottom: 15 }}>
          <hr />
          <div className="ui-text-sm ui-text-muted">{t('Onboarding.Expert.ActiveCertifications')}</div>
        </div>
      )}

      {model.certifications.map((c, idx) => (
        <div key={idx} className="ui-frame ui-frame-bg ui-text-sm" style={{ padding: '10px 10px 7px 10px', marginBottom: 10, position: 'relative' }}>
          <div
            className="ui-action-item"
            style={{ position: 'absolute', top: 3, right: 5 }}
            role="button"
            tabIndex={-1}
            onKeyDown={() => {}}
            onClick={() => {
              const certifications = [...model.certifications]
              certifications.splice(idx, 1)
              onChangeModel({
                ...model,
                certifications
              })
            }}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </div>

          <div className="ui-text-bold" style={{ marginBottom: 5 }}>
            {c.name}
          </div>
          <div className="ui-flex ui-flex-nowrap">
            <div className="ui-text-bold">{t('Onboarding.Expert.Expires')}:</div>
            <div style={{ marginLeft: 5, width: 100 }}>
              {(() => {
                const m = moment({ year: c.expYear, month: c.expMonth - 1 })
                return m.format('MMMM Y')
              })()}
            </div>
            <div className="ui-text-bold">{t('Onboarding.Expert.No')}</div>
            <div style={{ marginLeft: 5 }}>{c.number}</div>
          </div>
        </div>
      ))}

      <br />
      <br />
      <div className="ui-row">
        <div className="ui-col-6">
          <button
            type="button"
            className="ui-btn ui-btn-primary"
            style={{ width: '100%' }}
            onClick={() => {
              if (onBack) onBack()
            }}
          >
            {t('Onboarding.Back')}
          </button>
        </div>
        <div className="ui-col-6">
          <button type="submit" className="ui-btn ui-btn-secondary ui-btn-solid" style={{ width: '100%' }}>
            {t('Onboarding.Finished')}
          </button>
        </div>
      </div>
    </form>
  )
}
