import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

import '../style.scss'

export const OverlayModal: React.FC = ({ children }) => {
  const elm = useRef<HTMLDivElement>(document.createElement('div'))

  useEffect(() => {
    document.body.appendChild(elm.current)
    return () => {
      if (elm.current) document.body.removeChild(elm.current)
    }
  }, [])

  return ReactDOM.createPortal(
    <div className="admin-overlay-modal">
      {children}
    </div>,
    elm.current
  )
}
