import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostRequests } from 'services/src/api/boe';
import { endpoints } from 'services/src/lib/axios';
import { Loading } from '../../../loading';
import { BoeProjectSectionSubheader } from './BoeProjectSectionSubheader';
import ProjectBoeProgressBar from './ProjectBoeProgressBar';

interface ProjectBoeAssumptionsSectionProps {
  handleNext: () => void;
  handlePrevious: () => void;
  setBoeData: (data: any) => void;
  boeData: any;
  isProgressBarVisible: boolean;
  setIsProgressBarVisible: (value: boolean) => void;
}

interface Errors {
  [key: string]: string | undefined;
  biddingAssumptions?: string;
}

const BoeProject19: React.FC<ProjectBoeAssumptionsSectionProps> = ({
  handleNext,
  handlePrevious,
  setBoeData,
  boeData,
  isProgressBarVisible,
  setIsProgressBarVisible,
}) => {
  const { t } = useTranslation();
  const progress = useMemo(() => 30, []);
  const { data, postRequests, loading, error } = usePostRequests();
  const [errors, setErrors] = useState<Errors>({});
  const [biddingAssumptions, setBiddingAssumption] = useState<string>(boeData?.biddingAssumptions?.introduction || '');
  const [conclusion, setConclusion] = useState<string>(boeData?.biddingAssumptions?.conclusion || '');
  const [assumptionData, setAssumptionData] = useState<any>(boeData?.assumptionData || []);
  const [newAssumption, setNewAssumption] = useState<{ title: string; detail: string }>({ title: '', detail: '' });
  const [selectedDetails, setSelectedDetails] = useState<string[]>(boeData?.biddingAssumptions?.assumptions || []);
  const [showDetailInput, setShowDetailInput] = useState<number | null>(null);
  const [showAssumptionInput, setShowAssumptionInput] = useState<boolean>(false);
  const [call, setCall] = useState<boolean>(false);

  const handleAddAssumption = useCallback(() => {
    if (newAssumption.title && newAssumption.detail) {
      setAssumptionData((prev: any) => [...prev, { title: newAssumption.title, details: [newAssumption.detail] }]);
      setNewAssumption({ title: '', detail: '' });
      setShowAssumptionInput(false);
    }
  }, [newAssumption]);

  const handleAddDetail = useCallback((index: number) => {
    if (newAssumption.detail) {
      setAssumptionData((prev: any) => {
        const updatedAssumptions = prev.map((assumption: any, i: number) => {
          if (i === index) {
            return {
              ...assumption,
              details: [...assumption.details, newAssumption.detail]
            };
          }
          return assumption;
        });
        return updatedAssumptions;
      });
      setNewAssumption((prev) => ({ ...prev, detail: '' }));
      setShowDetailInput(null);
    }
  }, [newAssumption.detail]);

  const handleSelectDetail = useCallback((detail: string) => {
    setSelectedDetails((prev) => {
      if (prev.includes(detail)) {
        return prev.filter((d) => d !== detail);
      }
      return [...prev, detail];
    });
  }, []);

  const handleNextClick = useCallback(() => {
    if (selectedDetails.length === 0) {
      setErrors({ biddingAssumptions: `${t('BasisOfEstimate.BoeProject19.SelectErrorMessage')}` });
      return;
    }
    setBoeData((prev: any) => ({
      ...prev,
      biddingAssumptions: {
        introduction: biddingAssumptions,
        assumptions: selectedDetails,
        conclusion,
      },
      assumptionData,
    }));
    handleNext();
  }, [biddingAssumptions, selectedDetails, conclusion, assumptionData, handleNext, setBoeData]);

  useEffect(() => {
    if (!call && biddingAssumptions === '') {
      setCall(true);
      postRequests(endpoints.boe.biddingAssumptions, {});
    }
  }, [call, postRequests, biddingAssumptions]);

  useEffect(() => {
    if (data?.response) {
      setBiddingAssumption(data.response.descriptions || '');
      setAssumptionData(data.response.assumptions || []);
      setConclusion(data.response.conclusion || '');
    }
  }, [data?.response]);

  return (
    <div className="boe-content">
      <div className="boe-form-content">
        <ProjectBoeProgressBar
          progress={progress}
          title={t('BasisOfEstimate.BoeProject19.ProgressTitle')}
          leftText={t('BasisOfEstimate.BoeProject19.ProgressLeftText')}
          rightText={t('BasisOfEstimate.BoeProject19.ProgressRightText')}
          isProgressBarVisible={isProgressBarVisible}
          setIsProgressBarVisible={setIsProgressBarVisible}
          boeData={boeData}
        />
        <BoeProjectSectionSubheader title={t('BasisOfEstimate.BoeProject19.Title')} dynamicText={t('BasisOfEstimate.BoeProject19.Dynamic')} boeData={boeData} />
        {loading && <Loading />}
        {error && (
          <div className="boe-error-container">
            <p>{t('General.Error')}</p>
          </div>
        )}
        <div className="boe-selectable-content">
          <h3 className="boe-project-physical-title">{biddingAssumptions}</h3>
          {assumptionData.map((assumption: any, index: number) => (
            <div key={index} className="boe-assumption boe-flex-col">
              <h3 className="boe-assumption-title">{assumption.title}</h3>
              <ul className="boe-flex-col">
                {assumption.details.map((detail: string, detailIndex: number) => (
                  <li key={detailIndex}>
                    <input
                      type="checkbox"
                      className="boe-assumption-checkbox"
                      checked={selectedDetails.includes(detail)}
                      onChange={() => handleSelectDetail(detail)}
                    />
                    {detail}
                  </li>
                ))}
              </ul>
              {showDetailInput === index ? (
                <>
                  <input
                    type="text"
                    value={newAssumption.detail}
                    onChange={(e) => setNewAssumption((prev) => ({ ...prev, detail: e.target.value }))}
                    placeholder="Add new detail"
                  />
                  <button type="button" className="boe-form-assumption-button" onClick={() => handleAddDetail(index)}>
                    {t('BasisOfEstimate.BoeProject19.AddDetails')}
                  </button>
                </>
              ) : (
                <button type="button" className="boe-form-assumption-button" onClick={() => setShowDetailInput(index)}>
                  {t('BasisOfEstimate.BoeProject19.WriteAssumption')}
                </button>
              )}
            </div>
          ))}
          {showAssumptionInput ? (
            <div className="boe-add-assumption">
              <input
                type="text"
                value={newAssumption.title}
                onChange={(e) => setNewAssumption((prev) => ({ ...prev, title: e.target.value }))}
                placeholder="New Assumption Title"
              />
              <input
                type="text"
                value={newAssumption.detail}
                onChange={(e) => setNewAssumption((prev) => ({ ...prev, detail: e.target.value }))}
                placeholder="New Assumption Detail"
              />
              <button type="button" className="boe-form-assumption-button" onClick={handleAddAssumption}>
                {t('BasisOfEstimate.BoeProject19.AddAsumption')}
              </button>
            </div>
          ) : (
            <button type="button" className="boe-form-assumption-button-new" onClick={() => setShowAssumptionInput(true)}>
              <FontAwesomeIcon icon={faPlusCircle} />
              {t('BasisOfEstimate.BoeProject19.AddAdditionalAssumption')}
            </button>
          )}
          <h3 className="boe-project-physical-title">{conclusion}</h3>
        </div>
        {errors.biddingAssumptions && (
          <div className="boe-section-header-right-content">
            <p>{errors.biddingAssumptions}</p>
          </div>
        )}
      </div>
      <div className="boe-form-action-container">
        <button type="button" disabled={loading} className="btn-next" onClick={handlePrevious}>
          {t('General.Previous')}
        </button>
        <button type="button" className="btn-previous" onClick={handleNextClick} disabled={loading}>
          {t('General.Next')}
        </button>
      </div>
    </div>
  );
};

export default BoeProject19;