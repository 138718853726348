import { faPrint } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BoeReportPreviewModal } from './BoeReportPreviewModal';

type ReportPreviewProps =  {
    boeData: any;
}

export const ReportPreview: React.FC<ReportPreviewProps> = ({ boeData }) => {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();

    return (
        <>
            <button onClick={() => setOpen(true)} type='button' aria-label='Open Report Preview' className='boe-report-preveiw-button'> <FontAwesomeIcon icon={faPrint} color='#0D355D' size='2x'/> <p className="boe-progress-lable-text">{t('BasisOfEstimate.Preview')}</p> </button>
            {open && <BoeReportPreviewModal boeData={boeData} onClose={() => setOpen(false)} />}

        </>
    )
}

