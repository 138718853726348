import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import { faCheckSquare, faSquare } from '@fortawesome/pro-regular-svg-icons'

import { useSaving } from 'components/src/saving'
import { SelectField } from 'components/src/selectField'
import { Availability, ExpertType, YearsOfExperience } from 'services/src/dto/account'
import { useCurrentUserAccount, useMe } from 'services/src/state'
import { CurrencyInput } from 'components/src/currencyInput'
import { makeDashboardPath } from 'services/src/dom'
import { DisciplineKey } from 'services/src/dto/project'
import { ToggleSwitch } from 'components/src/toggleSwitch'
import { ExpertProfileViewModel } from './model'
import { Footer } from '../Footer'

export const ExpertEmployment: React.FC<{
  vm: ExpertProfileViewModel
  onChange: (vm: ExpertProfileViewModel) => void
  onSubmit: (view: string, vm: ExpertProfileViewModel) => void
}> = ({ vm, onChange, onSubmit }) => {
  const { t } = useTranslation()
  const [saving] = useSaving()
  const [account] = useCurrentUserAccount()
  const [me] = useMe()

  const disciplines = useMemo<{ id: DisciplineKey; label: string }[]>(
    () => [
      { id: DisciplineKey.General, label: t(`General.Disciplines.${DisciplineKey.General}`) },
      { id: DisciplineKey.Mechanical, label: t(`General.Disciplines.${DisciplineKey.Mechanical}`) },
      { id: DisciplineKey.Electrical, label: t(`General.Disciplines.${DisciplineKey.Electrical}`) },
      { id: DisciplineKey.SiteCivil, label: t(`General.Disciplines.${DisciplineKey.SiteCivil}`) }
    ],
    [t]
  )
  const discipline = useMemo(() => disciplines.find((x) => x.id === vm.primaryDiscipline), [vm, disciplines])

  const yearsOfExpItems = useMemo<
    {
      id: YearsOfExperience
      label: string
    }[]
  >(
    () => [
      { id: YearsOfExperience.Lt2, label: t(`Onboarding.Expert.YearsOfExperienceItems.${YearsOfExperience.Lt2}`) },
      {
        id: YearsOfExperience.In2to5,
        label: t(`Onboarding.Expert.YearsOfExperienceItems.${YearsOfExperience.In2to5}`)
      },
      {
        id: YearsOfExperience.In5to10,
        label: t(`Onboarding.Expert.YearsOfExperienceItems.${YearsOfExperience.In5to10}`)
      },
      {
        id: YearsOfExperience.In10to15,
        label: t(`Onboarding.Expert.YearsOfExperienceItems.${YearsOfExperience.In10to15}`)
      },
      {
        id: YearsOfExperience.In15to20,
        label: t(`Onboarding.Expert.YearsOfExperienceItems.${YearsOfExperience.In15to20}`)
      },
      {
        id: YearsOfExperience.In20to25,
        label: t(`Onboarding.Expert.YearsOfExperienceItems.${YearsOfExperience.In20to25}`)
      },
      { id: YearsOfExperience.Gt25, label: t(`Onboarding.Expert.YearsOfExperienceItems.${YearsOfExperience.Gt25}`) }
    ],
    [t]
  )

  const availabilityItems = useMemo<
    {
      id: Availability
      label: string
    }[]
  >(
    () => [
      { id: Availability.FullTime, label: t(`Onboarding.Expert.${Availability.FullTime}`) },
      { id: Availability.PartTime, label: t(`Onboarding.Expert.${Availability.PartTime}`) },
      { id: Availability.Contractor, label: t(`Onboarding.Expert.${Availability.Contractor}`) }
    ],
    [t]
  )
  const [availability, setAvailability] = useState(availabilityItems.find((i) => i.id === vm.availability))

  const expertTypes = useMemo<
    {
      id: ExpertType
      type: string
    }[]
  >(
    () => [
      { id: ExpertType.FTE, type: t(`General.ExpertTypes.FTE`) },
      { id: ExpertType.Contractor, type: t(`General.ExpertTypes.Contractor`) }
    ],
    [t]
  )

  const [expertType, setExpertType] = useState<{ id: ExpertType; type: string } | undefined>(expertTypes.find((x) => x.id === vm.expertType))

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      const vmx: ExpertProfileViewModel = { ...vm, errors: {} }
      if (Object.keys(vmx.errors).length) {
        onChange(vmx)
        return
      }
      onSubmit('employment', vmx)
    },
    [vm, onChange, onSubmit]
  )

  return (
    <form noValidate onSubmit={submit}>
      <div className="settings-user-layout">
        <div className="ui-form-group ui-flex" style={{ alignItems: 'center' }}>
          <ToggleSwitch
            value={vm.available}
            className="ui-info"
            onClick={() => {
              onChange({ ...vm, available: !vm.available })
            }}
          />
          <label style={{ margin: '0 0 0 10px' }}>{t('Settings.EditExpert.AvailableForWork')}</label>
        </div>

        <div className="ui-row">
          <div className="ui-col-8">
            <SelectField
              items={availabilityItems}
              selected={availability}
              labelField="label"
              valueField="id"
              error={vm.errors.availability}
              label={t('Settings.EditExpert.WorkAvailability')}
              placeholder={t('Settings.EditExpert.WorkAvailabilitySample')}
              disabled={saving || vm.id === me?.id}
              onSelectedChange={(x: any) => {
                setAvailability(x)

                const errors = { ...vm.errors }
                delete errors.availability
                onChange({ ...vm, availability: x.id, errors })
              }}
            />
          </div>
          <div className="ui-col-4">
            <SelectField
              items={expertTypes}
              selected={expertType}
              labelField="type"
              valueField="id"
              error={vm.errors.expertType}
              label={t('Settings.EditExpert.ExpertType')}
              placeholder={t('Settings.EditExpert.ExpertTypeSample')}
              disabled={saving || vm.id === me?.id}
              onSelectedChange={(x: any) => {
                setExpertType(x)

                const errors = { ...vm.errors }
                delete errors.expertType
                onChange({ ...vm, expertType: x.id, errors })
              }}
            />
          </div>
        </div>

        {vm.id === me?.id ? (
          <div className="ui-form-group">
            <label>{t('Settings.EditExpert.PrimaryDiscipline')}</label>
            <div className="ui-frame ui-frame-no-padding ui-text-sm ui-text-ellipsis" style={{ height: 36, lineHeight: '34px', padding: '0 10px' }}>
              <span className="ui-text-muted">{discipline ? discipline.label : t('General.NA')}</span>
            </div>
          </div>
        ) : (
          <SelectField
            items={disciplines}
            selected={discipline}
            labelField="label"
            valueField="id"
            placeholder={t('Settings.EditExpert.PrimaryDisciplineSample')}
            label={t('Settings.EditExpert.PrimaryDiscipline')}
            disabled={saving}
            onSelectedChange={(x) => {
              const errors = { ...vm.errors }
              delete errors.primaryDiscipline

              const skills = vm.disciplineSkills?.find((ds) => ds.discipline === x.id)?.skills || []
              onChange({ ...vm, primaryDiscipline: x.id, primaryDisciplineSkills: [], skills, errors })
            }}
          />
        )}

        <div className="ui-flex ui-flex-nowrap">
          <div style={{ flex: 1, marginRight: 5 }}>
            {me?.id !== vm.id ? (
              <SelectField
                items={yearsOfExpItems}
                selected={yearsOfExpItems.find((x) => x.id === vm.yearsOfExperience)}
                labelField="label"
                valueField="id"
                placeholder={t('Settings.EditExpert.YearsOfExpSample')}
                error={vm.errors.yearsOfExperience}
                label={t('Settings.EditExpert.YearsOfExp')}
                disabled={saving}
                onSelectedChange={(yearsOfExp) => {
                  const errors = { ...vm.errors }
                  delete errors.yearsOfExperience
                  onChange({ ...vm, yearsOfExperience: yearsOfExp.id, errors })
                }}
              />
            ) : (
              <div className="ui-form-group">
                <label>{t('Settings.EditExpert.YearsOfExp')}</label>
                <div className="ui-frame ui-frame-no-padding ui-text-sm ui-text-ellipsis" style={{ height: 36, lineHeight: '34px', padding: '0 10px' }}>
                  <span className="ui-text-muted">{vm.yearsOfExperience ? t(`Onboarding.Expert.YearsOfExperienceItems.${vm.yearsOfExperience}`) : t('General.NA')}</span>
                </div>
              </div>
            )}
          </div>

          <div style={{ marginLeft: 5 }}>
            <label>{t('Settings.EditExpert.HourlyRate')}</label>
            {me?.id !== vm.id ? (
              <CurrencyInput
                amount={vm.hourlyRate?.toString() || undefined}
                min={0}
                max={1000}
                placeholder={t('General.Currency.Value', { value: 50.0, minDigits: 2 }).substring(1)}
                disabled={saving}
                onChange={(hourlyRate) => {
                  const errors = { ...vm.errors }
                  delete errors.hourlyRate
                  onChange({ ...vm, hourlyRate, errors })
                }}
              />
            ) : (
              <div className="ui-flex ui-flex-nowrap">
                <div
                  className="ui-frame ui-frame-no-padding ui-text-sm ui-text-right"
                  style={{
                    flex: 1,
                    height: 36,
                    lineHeight: '34px',
                    padding: '0 10px',
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0
                  }}
                >
                  <span className="ui-text-muted">{vm.hourlyRate ? t('General.Currency.Value', { value: vm.hourlyRate }) : t('General.NA')}</span>
                </div>
                <div
                  className="ui-frame ui-frame-no-padding ui-text-sm ui-text-left"
                  style={{
                    flex: 1,
                    height: 36,
                    lineHeight: '34px',
                    padding: '0 10px',
                    borderLeft: 'none',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0
                  }}
                >
                  <span className="ui-text-muted">{t('General.Currency.Ident')}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <SelectField
          items={(vm.skills || []).map((s) => ({ label: s }))}
          style={{ marginBottom: 0 }}
          disabled={saving}
          selected={undefined}
          labelField="label"
          valueField="label"
          placeholder={t('Settings.EditExpert.SkillCount', { count: vm.primaryDisciplineSkills?.length || 0 })}
          label={t('Settings.EditExpert.SelectSkill')}
          renderItem={(i) => {
            const sel = vm.primaryDisciplineSkills?.includes(i.label)
            return (
              <div className={sel ? 'ui-info' : ''}>
                <FontAwesomeIcon icon={sel ? faCheckSquare : faSquare} />
                &nbsp;&nbsp;{i.label}
              </div>
            )
          }}
          onSelectedChange={(i) => {
            const primaryDisciplineSkills = [...(vm.primaryDisciplineSkills || [])]

            const idx = primaryDisciplineSkills.findIndex((s) => s === i.label)

            if (idx < 0) {
              primaryDisciplineSkills.push(i.label)
              primaryDisciplineSkills.sort()
            } else {
              primaryDisciplineSkills.splice(idx, 1)
            }

            onChange({ ...vm, primaryDisciplineSkills })
          }}
        />

        <div className="ui-flex ui-form-group settings-user-skill-badges" style={{ marginTop: -15, alignItems: 'center' }}>
          {vm.primaryDisciplineSkills?.map((s, idx) => (
            <div key={idx} className="ui-badge ui-badge-primary">
              {s}
              &nbsp;&nbsp;
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="ui-action-item"
                title={t('General.Remove')}
                onClick={() => {
                  const primaryDisciplineSkills = [...(vm.primaryDisciplineSkills || [])]
                  primaryDisciplineSkills.splice(idx, 1)

                  onChange({ ...vm, primaryDisciplineSkills })
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <Footer>
        <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center' }}>
          <Link to={makeDashboardPath(account?.id)} className="ui-btn ui-btn-primary ui-btn-solid">
            {t('Dashboard.Title')}
          </Link>
          <button type="submit" style={{ marginLeft: 'auto' }} disabled={!vm.dirty || saving} className="ui-btn ui-btn-secondary ui-btn-solid">
            {t('General.Update')}
          </button>
        </div>
      </Footer>
    </form>
  )
}
