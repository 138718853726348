import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt, faUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'

import './style.scss'
import { Comment } from 'services/src/dto/comment'
import draftToHtml from 'draftjs-to-html'
import { useRights, UserType } from 'services/src/state'
import { RECENT_COMMENTS_KEY, useComments } from 'services/src/state/comments'
import LoadingSvg from '../svg/Loading'

export const RecentComments: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, ...rest }) => {
  const { t } = useTranslation()
  const rights = useRights()

  const [inError, setInError] = useState(false)
  const [isScroll, setInScroll] = useState(false)
  const [newest, setNewest] = useState(0)
  const [newCount, setNewCount] = useState(0)

  const innerRef = useRef<HTMLDivElement>(null)

  const { ready, loadRecent, comments } = useComments()

  const scrollToLatest = useCallback(() => {
    setTimeout(() => {
      if (!innerRef.current) return

      const p = innerRef.current.parentElement
      if (!p) return

      const r1 = innerRef.current.getBoundingClientRect()
      const r2 = p.getBoundingClientRect()

      let scrollHeight = 0
      if (r1.height > r2.height) scrollHeight = r1.height - r2.height + 30
      p.scrollTo(0, scrollHeight)
    })
  }, [innerRef])

  useEffect(() => {
    if (!ready) return
    loadRecent()
  }, [ready, loadRecent])

  useEffect(() => {
    if (!comments) return

    if (comments[RECENT_COMMENTS_KEY]?.length) {
      if (newest > 0) setNewCount(comments[RECENT_COMMENTS_KEY].filter((c) => c.createdAt > newest).length)
      else setNewest(comments[RECENT_COMMENTS_KEY][comments[RECENT_COMMENTS_KEY].length - 1].createdAt)
    }

    setInError(false)
    scrollToLatest()
  }, [comments, setInError, setNewest, setNewCount])

  const formatDate = (comment: Comment) => {
    const m = moment(comment.createdAt)
    if (m.isSame(moment(), 'date')) return m.format('LT')
    return `${m.format('l')} ${m.format('LT')}`
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={`dashboard-home-card${className ? ` ${className}` : ''}`}
      onClick={() => {
        setNewCount(0)
        if (comments[RECENT_COMMENTS_KEY]?.length) setNewest(comments[RECENT_COMMENTS_KEY][comments[RECENT_COMMENTS_KEY].length - 1].createdAt)
      }}
      {...rest}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {inError ? (
        <div className="dashboard-home-recent-comments">
          <h3>{t('General.RecentComments')}</h3>
          <div className="dashboard-home-cell-error">
            <button type="button" tabIndex={-1} className="recycle" onClick={() => loadRecent()}>
              <FontAwesomeIcon icon={faSyncAlt} />
            </button>
            <div>{t(`Dashboard.Errors.LoadFailed`)}</div>
          </div>
        </div>
      ) : !comments || !comments[RECENT_COMMENTS_KEY] ? (
        <div className="dashboard-home-recent-comments dashboard-home-cell-loading">
          <LoadingSvg style={{ width: 30 }} />
        </div>
      ) : (
        <div className="dashboard-home-recent-comments">
          <div onScroll={(e) => setInScroll(e.currentTarget.scrollTop > 0)}>
            <div ref={innerRef}>
              {comments[RECENT_COMMENTS_KEY].length ? (
                <>
                  {comments[RECENT_COMMENTS_KEY].map((comment) => {
                    const raw = JSON.parse(comment.text)
                    const html = draftToHtml(raw)

                    let { url } = comment
                    if (url) {
                      if (rights?.userType !== UserType.client) {
                        const parts = url.split('/')
                        if (parts[2] !== 'projects') {
                          parts.splice(2, 1)
                          url = parts.join('/')
                        }
                      }
                    }

                    return (
                      <div key={comment.id} className={`${comment.sourceUser.id === rights?.id ? 'is-me' : 'is-not-me'} ${comment.isNew ? 'is-new' : 'is-not-new'}`}>
                        <div className="comment-frame">
                          {/* eslint-disable-next-line react/no-danger */}
                          <div className="comment-text" key={comment.id} dangerouslySetInnerHTML={{ __html: html }} />

                          {comment.sourceUser.id === rights?.id ? (
                            <div className="comment-ident">
                              <span className="name ui-is-me">
                                {comment.sourceUser.givenName} {comment.sourceUser.familyName}
                              </span>
                              <span className="time ui-text-muted" style={{ marginLeft: 5 }}>
                                {formatDate(comment)}
                              </span>
                              {url && (
                                <Link to={`${url}?cid=${comment.id}`} className="url">
                                  <FontAwesomeIcon icon={faUpRightFromSquare} />
                                </Link>
                              )}
                            </div>
                          ) : (
                            <div className="comment-ident">
                              {comment.sourceUser.givenName && comment.sourceUser.familyName ? (
                                <>
                                  <span className="name">
                                    {comment.sourceUser.givenName} {comment.sourceUser.familyName}
                                  </span>
                                  <span className="time ui-text-muted">{formatDate(comment)}</span>
                                </>
                              ) : (
                                <span className="time ui-text-muted" style={{ marginLeft: 0 }}>
                                  {formatDate(comment)}
                                </span>
                              )}
                              {url && (
                                <Link to={`${url}?cid=${comment.id}`} className="url">
                                  <FontAwesomeIcon icon={faUpRightFromSquare} />
                                </Link>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </>
              ) : (
                <div className="ui-text-sm ui-text-muted ui-text-center" style={{ marginTop: '35%' }}>
                  {t('General.NoRecentComment')}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
