import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { getAccountById, getCompanyTypes, updateAccount } from 'services/src/api'
import { useUserAccounts } from 'services/src/state'
import { CompanyType } from 'services/src/dto/account'
import { numberOfEmployeesItems } from 'services/src/common'

import { useAlert } from 'components/src/alerts'
import { useSaving } from 'components/src/saving'
import { TabBar, TabItem } from 'components/src/tabs'

import { AccountProfileViewModel } from './model'
import { AccountGeneral } from './AccountGeneral'

export const AccountProfile: React.FC = () => {
  const { t } = useTranslation()
  const {
    url,
    path,
    params: { accountId }
  } = useRouteMatch<{ accountId: string }>()
  const { pathname } = useLocation()
  const [, setSaving] = useSaving()
  const { alertDanger, alertSuccess } = useAlert()

  const [, refreshAccounts] = useUserAccounts()
  const [vm, setVm] = useState<AccountProfileViewModel>()

  useEffect(() => {
    Promise.all([getCompanyTypes(), getAccountById(accountId)]).then(([companyTypeObj, account]) => {
      const companyTypes: CompanyType[] = Object.keys(companyTypeObj).map((id: string) => {
        const x = `${id[0].toUpperCase()}${id.substring(1)}`
        return { id: x, label: companyTypeObj[id] }
      })
      setVm({
        ...account,
        errors: {},
        companyTypes,
        selectedCompanyType: companyTypes.find((ct) => ct.id === account.companyType),
        selectedNumberOfEmployees: numberOfEmployeesItems.find((x) => x.id === account.numberOfEmployees)
      })
    })
  }, [accountId, setVm])

  const tabs = useMemo<TabItem[]>(
    () => [
      {
        label: t('Settings.Account.Tabs.General'),
        selected: pathname.endsWith('account') || pathname.endsWith(accountId || 'xxx'),
        to: url
      }
    ],
    [pathname, accountId, url, t]
  )

  const submit = useCallback(
    (view: string, vm: AccountProfileViewModel) => {
      const a = { ...vm } as any
      delete a.picture
      delete a.preview
      delete a.errors
      delete a.companyTypes

      a.companyType = vm.selectedCompanyType?.id
      delete a.selectedCompanyType

      a.numberOfEmployees = vm.selectedNumberOfEmployees?.id
      delete a.selectedNumberOfEmployees

      setSaving(true)
      updateAccount(a, vm.picture)
        .then(() => {
          alertSuccess({
            title: t('Settings.Account.SavedTitle'),
            message: t('Settings.Account.Saved', { name: vm.companyName })
          })
          setSaving(false)
          return refreshAccounts()
        })
        .catch((error) => {
          alertDanger({
            title: t('Settings.Errors.Account.SavedTitle'),
            message: t('Settings.Errors.Account.Saved', { name: vm.companyName }),
            error
          })
        })
        .finally(() => setSaving(false))
    },
    [setSaving, alertSuccess, alertDanger, refreshAccounts, t]
  )

  if (!vm) return null

  return (
    <>
      <TabBar tabs={tabs} className="settings-tab-bar" />

      <Switch>
        <Route path={path} exact>
          <AccountGeneral vm={vm} onChange={setVm} onSubmit={submit} />
        </Route>
      </Switch>
    </>
  )
}
