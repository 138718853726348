import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { getAccountById, getCompanyTypes } from 'services/src/api'
import { Loading } from 'components/src/loading'
import { numberOfEmployeesItems } from 'services/src/common'

import { AccountEditGeneral } from './AccountEditGeneral'
import { AccountEx } from './common'
import { AccountEditOffices } from './AccountEditOffices'
import { AccountEditClients } from './AccountEditClients'

export const AccountEdit: React.FC = () => {
  const {
    params: { id }
  } = useRouteMatch<{ id: string }>()

  const [account, setAccount] = useState<AccountEx>()

  useEffect(() => {
    getAccountById(id)
      .then((a) => {
        const account = a as AccountEx
        return getCompanyTypes().then((companyTypes) => {
          account.companyTypes = Object.keys(companyTypes).map((id: string) => ({ id, label: companyTypes[id] }))
          account.newCompanyType = account.companyTypes.find((ct) => ct.id === account.companyType)

          account.newNumberOfEmployees = numberOfEmployeesItems.find((x) => x.id === account.numberOfEmployees)

          return account
        })
      })
      .then((account) => {
        setAccount(account)
      })
  }, [id])

  if (!account) return <Loading />

  return (
    <div className="account-edit">
      <AccountEditGeneral account={account} onChange={(a) => setAccount(a)} />

      {/*
      <br />

      <div className="ui-frame ui-frame-bg">
        <AccountEditOffices
          account={account}
          onChange={(a) => setAccount(a)} />
      </div>

      <br />

      <div className="ui-frame ui-frame-bg">
        <AccountEditClients
          account={account}
          onChange={(a) => setAccount(a)} />
      </div>
      */}
    </div>
  )
}
