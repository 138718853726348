import React, { useCallback } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import moment, { Moment } from 'moment'

import { PROJECT_PHASE_MIN_DAYS } from 'components/src/constants'
import { PhaseType } from 'services/src/dto/projectSupport'
import { PhaseTypeKey } from 'services/src/dto/projectShare'
import { PhaseVariations, Project, ProjectStatus } from 'services/src/dto/project'
import { DatePickerMarker } from 'components/src/datePicker'
import { PhaseEx } from 'services/src/state'
import { useSaving } from 'components/src/saving'
import { ProjectDate } from './ProjectDate'

let newId = -1
const newPhaseId = () => (newId--).toString()

export interface TimelineProps {
  onChange: (items: TimelineItem[]) => void
  items: TimelineItem[]
  markers?: DatePickerMarker[]
  initialPhaseLabel?: JSX.Element | string
  otherPhasesLabel?: JSX.Element | string
  otherPhasesSubLabel?: JSX.Element | string
  onEditPhase?: (item: TimelineItem) => void
  canEdit: boolean
  noAdd?: boolean
  maxPhases?: number
}

export interface TimelineItem {
  phase?: PhaseEx
  phaseType: PhaseTypeKey
  phaseId?: string
  type: string
  name?: string
  description?: string
  start?: string
  startLabel?: string
  end?: string
  endLabel?: string
  color?: string
  min: string
  max: string
  available: boolean
  ready: boolean
  error?: string
  warn?: string
  children?: TimelineItem[]
  variations?: PhaseVariations[]
}

const phaseColors = ['rgba(70,184,26,0.5)', 'rgba(37,208,169,0.5)', 'rgba(206,47,47,0.5)', 'rgba(206,157,52,0.5)', 'rgba(96,41,193,0.5)']

export const updateTimelineItemsFromProject = (project: Project, phaseTypes: PhaseType[], t: TFunction): TimelineItem[] => {
  let prevEnd = moment()
  let isFirst = true

  return phaseTypes.map((pt, idx) => {
    const item: TimelineItem = {
      phaseType: pt.id as PhaseTypeKey,
      type: pt.type,
      name: undefined,
      start: undefined,
      end: undefined,
      startLabel: t('General.SubmissionDate'),
      endLabel: t('General.DeliveryDate'),
      color: phaseColors[idx],
      min: '',
      max: '',
      available: true,
      ready: true
    }

    item.min = moment(prevEnd)
      .add(isFirst ? 0 : 1, 'days')
      .format()
    item.max = moment(item.min).add(1, 'year').format()

    const phases = project.phases?.filter((x) => x.phaseType === pt.id)
    if (!phases?.length) {
      item.phaseId = newPhaseId()
      item.phase = { id: item.phaseId, phaseType: pt.id }
      return item
    }

    if (phases.length === 1) {
      const ph = phases[0]
      item.phase = ph as PhaseEx
      item.phaseId = ph.id
      item.name = ph.name
      item.description = ph.description
      item.start = ph.submissionDate ? moment(ph.submissionDate).format() : undefined
      item.end = ph.deliveryDate ? moment(ph.deliveryDate).format() : undefined
      item.variations = ph.variations ? [...ph.variations] : undefined
      if (item.end) {
        prevEnd = moment(item.end)
        isFirst = false
      }
    } else {
      item.phaseId = `group:${pt.id}`
      let childEnd = prevEnd

      item.children = phases.map((ph) => {
        const item = {
          phase: ph as PhaseEx,
          phaseId: ph.id,
          phaseType: pt.id as PhaseTypeKey,
          type: pt.type,
          name: ph.name,
          description: ph.description,
          start: ph.submissionDate ? moment(ph.submissionDate).format() : undefined,
          end: ph.deliveryDate ? moment(ph.deliveryDate).format() : undefined,
          startLabel: t('General.SubmissionDate'),
          endLabel: t('General.DeliveryDate'),
          color: phaseColors[idx],
          min: '',
          max: '',
          available: true,
          ready: true,
          variations: ph.variations ? [...ph.variations] : undefined
        }

        item.min = moment(prevEnd)
          .add(isFirst ? 0 : 1, 'days')
          .format()
        item.max = moment(item.min).add(1, 'year').format()

        if (item.end) {
          isFirst = false
          if (moment(item.end).isAfter(childEnd)) childEnd = moment(item.end)
        }

        return item
      })

      prevEnd = childEnd
    }
    return item
  })
}

export const updateTimelineMarkersFromProject = (project: Project, phaseTypes: PhaseType[], t: TFunction): DatePickerMarker[] => {
  const markers: DatePickerMarker[] = []
  phaseTypes.forEach((pt, idx) => {
    const phs = project.phases?.filter((x) => x.phaseType === pt.id)
    if (!phs) return

    const marker: DatePickerMarker = {
      name: pt.type,
      start: undefined,
      end: undefined,
      startLabel: t('General.SubmissionDate'),
      endLabel: t('General.DeliveryDate'),
      color: phaseColors[idx]
    }

    if (phs.length === 1) {
      const ph = phs[0]
      if (!ph.submissionDate || !ph.deliveryDate) return

      if (ph.name) marker.name = `${marker.name} (${ph.name})`
      marker.start = moment(ph.submissionDate).format()
      marker.end = moment(ph.deliveryDate).format()

      markers.push(marker)
    } else {
      const names: string[] = []
      let start: Moment = moment.invalid()
      let end: Moment = moment.invalid()

      phs.forEach((ph, idx) => {
        if (!ph.submissionDate || !ph.deliveryDate) return

        if (ph.name) names.push(ph.name)
        else names.push(t('General.RomanNumber', { number: idx + 1 }))
        if (!start.isValid() || moment(ph.submissionDate).isBefore(start, 'date')) start = moment(ph.submissionDate)
        if (!end.isValid() || moment(ph.deliveryDate).isAfter(end, 'date')) end = moment(ph.deliveryDate)
      })

      if (start.isValid() && end.isValid()) {
        if (names.length > 0) marker.name = `${marker.name} (${names.join(',')})`
        marker.start = start.format()
        marker.end = end.format()

        markers.push(marker)
      }
    }
  })

  return markers
}

export const timelineItemsToPhases = (items: TimelineItem[]) => {
  let flat: TimelineItem[] = []
  items.forEach((item) => {
    if (item.children?.length) item.children.forEach((child) => flat.push(child))
    else flat.push(item)
  })

  flat = [
    ...flat.filter((x) => x.phaseType === PhaseTypeKey.Concept),
    ...flat.filter((x) => x.phaseType === PhaseTypeKey.Schematic),
    ...flat.filter((x) => x.phaseType === PhaseTypeKey.DesignDoc),
    ...flat.filter((x) => x.phaseType === PhaseTypeKey.ConstructionDoc),
    ...flat.filter((x) => x.phaseType === PhaseTypeKey.Proposal)
  ]

  const phases: PhaseEx[] = []

  flat.forEach((item) => {
    if (item.start && item.end) {
      const ph: PhaseEx = { ...item.phase }
      ph.id = item.phaseId
      if (ph.id?.startsWith('group')) ph.id = undefined
      ph.phaseType = item.phaseType
      ph.name = item.name
      ph.description = item.description
      ph.submissionDate = moment(item.start).toDate()
      ph.deliveryDate = moment(item.end).toDate()
      ph.variations = item.variations
      phases.push(ph)
    }
  })
  return phases
}

export const adjustTimelineItems = (newItems: TimelineItem[]) => {
  let prevEnd: Moment | undefined
  newItems.forEach((item) => {
    if (item.children?.length) {
      let childEnd: Moment | undefined
      item.children.forEach((child) => {
        if (!child.start || !child.end) return

        if (prevEnd) {
          if (prevEnd.isSameOrAfter(moment(child.start), 'date')) {
            const diff = moment(child.end).diff(moment(child.start), 'days')
            child.start = moment(prevEnd).add(1, 'days').format()
            child.end = moment(child.start).add(diff, 'days').format()
          }

          child.min = moment(prevEnd).add(1, 'day').format()
          child.max = moment(child.min).add(1, 'year').format()
        }
        if (!childEnd || moment(child.end).isAfter(childEnd, 'date')) childEnd = moment(child.end)
      })
      prevEnd = childEnd
    } else {
      if (!item.start || !item.end) return

      if (prevEnd) {
        if (prevEnd.isSameOrAfter(moment(item.start), 'date')) {
          const diff = moment(item.end).diff(moment(item.start), 'days')
          item.start = moment(prevEnd).add(1, 'days').format()
          item.end = moment(item.start).add(diff, 'days').format()
        }

        item.min = moment(prevEnd).add(1, 'day').format()
        item.max = moment(item.min).add(1, 'year').format()
      }
      prevEnd = moment(item.end)
    }
  })
  return newItems
}

export const Timeline: React.FC<TimelineProps> = ({ items, markers, onChange, onEditPhase, initialPhaseLabel, otherPhasesLabel, otherPhasesSubLabel, canEdit, noAdd, maxPhases }) => {
  const { t } = useTranslation()
  const [saving] = useSaving()

  const selectDate = useCallback(
    (item: TimelineItem, date: Moment, which: string, parent?: TimelineItem) => {
      const newItems = [...items]

      if (maxPhases) {
        newItems.forEach((i) => {
          i.start = undefined
          i.end = undefined
        })
      }

      const newItem = { ...item }
      delete newItem.error
      delete newItem.warn

      if (item.phaseType === PhaseTypeKey.Concept) {
        if (!newItem.variations) newItem.variations = []
      }

      if (which === 'start') {
        const diff = moment.duration(newItem.start && newItem.end ? moment(newItem.end).valueOf() - moment(newItem.start).valueOf() : 0)
        newItem.start = moment(date).format()
        newItem.end = moment(newItem.start)
          .add(Math.ceil(diff.asDays()) || PROJECT_PHASE_MIN_DAYS, 'days')
          .format()
      } else {
        // const diff = moment.duration(newItem.start && newItem.end ? moment(newItem.end).valueOf() - moment(newItem.start).valueOf() : 0);
        newItem.end = moment(date).format()
        // newItem.start = moment(newItem.end).subtract(Math.ceil(diff.asDays()) || PROJECT_PHASE_MIN_DAYS, 'days').format();
      }

      if (parent) {
        const p = newItems.find((x) => x.phaseId === parent.phaseId)
        if (!p || !p.children) return

        const idx = p.children.findIndex((x) => x.phaseId === item.phaseId)
        if (idx < 0) return

        p.children[idx] = newItem
      } else {
        const idx = newItems.findIndex((x) => x.phaseId === item.phaseId)
        if (idx < 0) return

        newItems[idx] = newItem
      }

      onChange(adjustTimelineItems(newItems))
    },
    [items, onChange, maxPhases]
  )

  return (
    <div style={{ paddingBottom: 50 }}>
      {initialPhaseLabel && (
        <div className="ui-form-group">
          <label>{initialPhaseLabel}</label>
          <div className="ui-text-muted ui-text-sm">
            {t('General.Note')}: {t('Timeline.MinDuration')}
          </div>

          <div className="project-timeline">
            <div className={`ui-form-group${items[0].error ? ' ui-has-error' : ''}`}>
              <ProjectDate
                className={items[0].phaseType}
                status={items[0].phase?.status}
                disabled={saving}
                variations={items[0].phaseType === PhaseTypeKey.Concept ? items[0].variations : undefined}
                onVariationsChange={(v) => {
                  const newItems = [...items]
                  newItems[0].variations = v
                  onChange(newItems)
                }}
                type={items[0].type}
                name={items[0].name}
                description={items[0].description}
                start={items[0].start}
                end={items[0].end}
                min={items[0].min}
                max={items[0].max}
                isAvailable={items[0].available}
                isReady={items[0].ready}
                markers={markers || []}
                onSelectDate={(date, which) => {
                  selectDate(items[0], date, which)
                }}
                onClearDate={() => {
                  const newItems = [...items]
                  newItems[0].start = undefined
                  newItems[0].end = undefined
                  onChange(newItems)
                }}
                onEditDate={(() => {
                  if (!onEditPhase) return undefined

                  const canEdit = items[0].phase?.canEdit === true
                  if (!canEdit) return undefined

                  return () => onEditPhase(items[0])
                })()}
                canEdit={canEdit}
                startedAt={(() => {
                  const sh = items[0].phase?.statusHistory?.find((x) => x.newStatus === ProjectStatus.InProgress)
                  return sh ? sh.createdAt : undefined
                })()}
                completedAt={(() => {
                  const sh = items[0].phase?.statusHistory?.find((x) => x.newStatus === ProjectStatus.Complete || x.newStatus === ProjectStatus.Canceled)
                  return sh ? sh.createdAt : undefined
                })()}
              />
              {items[0].warn && <div className="project-timeline-date-error ui-warn">{items[0].warn}</div>}
              {items[0].error && <div className="project-timeline-date-error ui-error">{items[0].error}</div>}
            </div>
          </div>
        </div>
      )}

      <div className="ui-form-group">
        {otherPhasesLabel && <label>{otherPhasesLabel}</label>}
        {otherPhasesSubLabel && (
          <div className="ui-text-muted ui-text-sm" style={{ marginBottom: 20 }}>
            {otherPhasesSubLabel}
          </div>
        )}

        <div className="project-timeline">
          {items.map((item, idx) => {
            if (initialPhaseLabel && idx <= 0) return null

            // Are there children?
            if (item.children?.length) {
              return (
                <div key={idx} className={`ui-form-group${item.error ? ' ui-has-error' : ''}`}>
                  <div className="ui-frame">
                    {item.children
                      .filter((child) => !!child.start && !!child.end)
                      .map((child, idx) => {
                        if (noAdd && !item.start) return null
                        const canEdit = child.phase?.canEdit === true

                        return (
                          <div key={idx} className={`ui-form-group${child.error ? ' ui-has-error' : ''}`} style={idx === (item.children?.length || 0) - 1 ? { marginBottom: 0 } : undefined}>
                            <ProjectDate
                              className={child.phaseType}
                              status={child.phase?.status}
                              disabled={saving}
                              variations={child.phaseType === PhaseTypeKey.Concept ? child.variations || [] : undefined}
                              onVariationsChange={(v) => {
                                const newItems = [...items]
                                const pIdx = newItems.findIndex((x) => x.phaseId === item.phaseId)
                                if (pIdx < 0) return

                                const p = newItems[pIdx]
                                if (!p.children) return

                                const idx = p.children.findIndex((x) => x.phaseId === child.phaseId)
                                if (idx < 0) return
                                p.children[idx].variations = v

                                onChange(newItems)
                              }}
                              type={child.type}
                              name={child.name}
                              description={child.description}
                              start={child.start}
                              end={child.end}
                              min={child.min}
                              max={child.max}
                              isAvailable={child.available}
                              isReady={child.ready}
                              markers={markers || []}
                              onSelectDate={(start, end) => selectDate(child, start, end, item)}
                              onClearDate={() => {
                                const newItems = [...items]
                                const pIdx = newItems.findIndex((x) => x.phaseId === item.phaseId)
                                if (pIdx < 0) return

                                const p = newItems[pIdx]
                                if (!p.children) return

                                const idx = p.children.findIndex((x) => x.phaseId === child.phaseId)
                                if (idx < 0) return
                                p.children.splice(idx, 1)

                                // If only 1 child left, promote to parent
                                // eslint-disable-next-line prefer-destructuring
                                if (p.children.length === 1) newItems[pIdx] = p.children[0]

                                onChange(newItems)
                              }}
                              onEditDate={onEditPhase && canEdit ? () => onEditPhase(child) : undefined}
                              canEdit={canEdit && ![ProjectStatus.InProgress, ProjectStatus.Complete, ProjectStatus.Canceled].includes(child.phase!.status!)}
                              startedAt={(() => {
                                const sh = child.phase?.statusHistory?.find((x) => x.newStatus === ProjectStatus.InProgress)
                                return sh ? sh.createdAt : undefined
                              })()}
                              completedAt={(() => {
                                const sh = child.phase?.statusHistory?.find((x) => x.newStatus === ProjectStatus.Complete || x.newStatus === ProjectStatus.Canceled)
                                return sh ? sh.createdAt : undefined
                              })()}
                            />
                            {child.warn && <div className="project-timeline-date-error ui-warn">{child.warn}</div>}
                            {child.error && <div className="project-timeline-date-error ui-error">{child.error}</div>}
                          </div>
                        )
                      })}
                  </div>
                </div>
              )
            }

            if (noAdd && !item.start) return null

            return (
              <div key={idx} className={`ui-form-group${item.error ? ' ui-has-error' : ''}`}>
                <ProjectDate
                  className={item.phaseType}
                  status={item.phase?.status}
                  disabled={saving}
                  variations={item.phaseType === PhaseTypeKey.Concept ? item.variations : undefined}
                  onVariationsChange={(v) => {
                    const newItems = [...items]
                    newItems[idx].variations = v
                    onChange(newItems)
                  }}
                  type={item.type}
                  name={item.name}
                  description={item.description}
                  start={item.start}
                  end={item.end}
                  min={item.min}
                  max={item.max}
                  isAvailable={item.available}
                  isReady={item.ready}
                  markers={markers || []}
                  onSelectDate={(start, end) => selectDate(item, start, end)}
                  onClearDate={() => {
                    const newItems = [...items]
                    newItems[idx].start = undefined
                    newItems[idx].end = undefined
                    onChange(newItems)
                  }}
                  onEditDate={onEditPhase && canEdit ? () => onEditPhase(item) : undefined}
                  canEdit={canEdit && ![ProjectStatus.Complete, ProjectStatus.Canceled].includes(item.phase!.status!)}
                  startedAt={(() => {
                    const sh = item.phase?.statusHistory?.find((x) => x.newStatus === ProjectStatus.InProgress)
                    return sh ? sh.createdAt : undefined
                  })()}
                  completedAt={(() => {
                    const sh = item.phase?.statusHistory?.find((x) => x.newStatus === ProjectStatus.Complete || x.newStatus === ProjectStatus.Canceled)
                    return sh ? sh.createdAt : undefined
                  })()}
                />
                {item.warn && <div className="project-timeline-date-error ui-warn">{item.warn}</div>}
                {item.error && <div className="project-timeline-date-error ui-error">{item.error}</div>}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
