import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import logo from 'components/src/img/logo-alt.png'
import { PhaseEx, useEditProject } from 'services/src/state'
import { ProjectStatus } from 'services/src/dto/project'
import moment, { Moment } from 'moment'

type PhaseWithCounter = PhaseEx & {
  counterFee: number
}

export const FeeClientCounter: React.FC = () => {
  const { t } = useTranslation()
  const { project } = useEditProject()

  const { phases, counterTotalFee, counterDate } = useMemo<{
    phases: PhaseWithCounter[]
    totalFee: number
    counterTotalFee: number
    counterDate: Moment
  }>(() => {
    let counterDate = moment()

    const phases = project.phases.filter((x) => x.status === ProjectStatus.FeeCounter).map<PhaseWithCounter>((ph) => ({ ...ph, counterFee: 0 }))
    let totalFee = 0
    let counterTotalFee = 0
    phases.forEach((ph) => {
      const sh = ph.statusHistory?.find((x) => x.newStatus === ProjectStatus.FeeCounter)
      if (!sh) return

      if (moment(sh.createdAt).isBefore(counterDate)) counterDate = moment(sh.createdAt)

      totalFee += sh.metadata?.originalFee || 0

      ph.counterFee = sh.metadata?.fee || 0
      counterTotalFee += ph.counterFee
    })

    return { phases, totalFee, counterTotalFee, counterDate }
  }, [project])

  if (!phases.length) return null

  return (
    <div className="project-edit-client-accept ui-frame ui-frame-bg">
      <div className="the-total">
        <img alt="" src={logo} />
        <div style={{ marginLeft: 15 }}>{t('EditProject.Fees.TotalBillable')}</div>
        <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: counterTotalFee })}</div>
      </div>

      <div className="ui-flex ui-flex-nowrap ui-text-muted ui-text-sm" style={{ marginTop: 20 }}>
        <div>{counterDate.format('LL')}</div>
        <div style={{ marginLeft: 'auto' }}>{t('EditProject.Fees.CounterOffer')}</div>
      </div>

      <div style={{ marginTop: 20 }} className="ui-text-sm">
        {phases.map((ph) => (
          <div key={ph.id} className="ui-flex ui-flex-nowrap">
            <div>
              {t(`EditProject.${ph.phaseType}`)}
              {ph.name && (
                <span className="ui-text-muted" style={{ marginLeft: 5 }}>
                  ({ph.name})
                </span>
              )}
            </div>
            <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: ph.counterFee })}</div>
          </div>
        ))}
      </div>

      <div className="ui-flex ui-flex-nowrap ui-text-xl ui-secondary" style={{ marginTop: 15 }}>
        <div>{t('EditProject.Fees.ClientFeeCounter')}</div>
        <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: counterTotalFee })}</div>
      </div>

      {/* <div className="ui-flex ui-flex-nowrap ui-text-muted ui-text-xs" style={{marginTop:15}}>
          <div>{t('EditProject.Fees.ClientOriginalFee')}</div>
          <div style={{marginLeft:'auto'}}>{t('General.Currency.Value', {value: totalFee})}</div>
        </div> */}
    </div>
  )
}
