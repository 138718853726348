import { faUserPlus } from '@fortawesome/pro-duotone-svg-icons'
import { faChevronRight, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { PhaseEx, ProjectEx, useCurrentUserAccount, useEditProject, useProjectNames, useRights, UserType } from 'services/src/state'

import { CommentBtn } from 'components/src/commentPanel/CommentBtn'
import { useSaving } from 'components/src/saving'
import { Select } from 'components/src/select'
import { StatusBadge } from 'components/src/statusBadge'
import FolderOpenSvg from 'components/src/svg/FolderOpen'
import MenuSvg from 'components/src/svg/Menu'
import MenuCloseSvg from 'components/src/svg/MenuClose'
import MenuOpenSvg from 'components/src/svg/MenuOpen'
import { useProjectSupport } from 'services/src/api'
import { makeDashboardPath } from 'services/src/dom'
import { ProjectStatus } from 'services/src/dto/project'
import { PhaseTypeKey } from 'services/src/dto/projectShare'
import { ClassifyProjectType } from '../common/ClassifyProjectType'
import ProjectBoeTabSwitcher from './ProjectBoeTabSwitcher'

const FeeTabs: React.FC<{ project: ProjectEx }> = ({ project }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [account] = useCurrentUserAccount()

  const url = useMemo(() => {
    const idx = pathname.indexOf('/fees')
    return pathname.substring(0, idx + 5)
  }, [pathname])

  const tabs = useMemo(() => {
    const groups: { [key: string]: PhaseEx[] } = {
      [PhaseTypeKey.Concept]: project.phases.filter((x) => x.phaseType === PhaseTypeKey.Concept && x.status !== ProjectStatus.New),
      [PhaseTypeKey.Schematic]: project.phases.filter((x) => x.phaseType === PhaseTypeKey.Schematic && x.status !== ProjectStatus.New),
      [PhaseTypeKey.DesignDoc]: project.phases.filter((x) => x.phaseType === PhaseTypeKey.DesignDoc && x.status !== ProjectStatus.New),
      [PhaseTypeKey.ConstructionDoc]: project.phases.filter((x) => x.phaseType === PhaseTypeKey.ConstructionDoc && x.status !== ProjectStatus.New),
      [PhaseTypeKey.Proposal]: project.phases.filter((x) => x.phaseType === PhaseTypeKey.Proposal && x.status !== ProjectStatus.New)
    }

    const tabs: JSX.Element[] = []

    Object.keys(groups).forEach((k) => {
      const l = groups[k].length
      if (!l) return

      groups[k].forEach((ph, idx) => {
        tabs.push(
          <Link
            key={ph.id}
            to={makeDashboardPath(account?.id, `projects/${project.id}/phases/${ph.id}/fees`)}
            className={`ui-flex-nowrap ui-flex ui-badge${url.includes(`/phases/${ph.id}/fees`) ? ' fee-tab-active' : ''}`}
          >
            {l <= 1 ? t(`EditProject.${ph.phaseType}`) : `${t(`EditProject.${ph.phaseType}`)} ${t('General.RomanNumber', { number: idx + 1 })}`}
          </Link>
        )
      })
    })

    return tabs
  }, [project.phases, url, t])

  return (
    <div className="edit-project-fees-tabs">
      <Link to={makeDashboardPath(account?.id, `projects/${project.id}/fees`)} className={`ui-flex-nowrap ui-flex ui-badge${url.endsWith(`/${project.id}/fees`) ? ' fee-tab-active' : ''}`}>
        {t('EditProject.Fees.Summary')}
      </Link>
      {tabs}
    </div>
  )
}

export interface ProjectHeaderProps {
  onInvite: () => void
  asideOpen: boolean
  onToggleAside: () => void
}

export const ProjectHeader: React.FC<ProjectHeaderProps> = ({ onInvite, asideOpen, onToggleAside }) => {
  const { t } = useTranslation()
  const [account] = useCurrentUserAccount()
  const { pathname } = useLocation()
  const history = useHistory()
  const rights = useRights()

  const [{ constructionTypes }] = useProjectSupport()
  const { project } = useEditProject()
  const { names } = useProjectNames()
  const [saving] = useSaving()

  const [selectProject, setSelectProject] = useState(false)

  const constructionType = useMemo(() => {
    if (!project.constructionType) return undefined
    return constructionTypes.find((x) => x.id === project.constructionType)?.type
  }, [project, constructionTypes])

  const location = useMemo(() => {
    if (!project?.location) return null
    const { municipality, countrySubdivisionName, country, freeformAddress } = project.location
    if (municipality && countrySubdivisionName && country) return `${municipality}, ${countrySubdivisionName} ${country}`
    if (municipality && countrySubdivisionName) return `${municipality}, ${countrySubdivisionName}`
    return freeformAddress
  }, [project])

  const breadcrumb = useMemo(() => {
    const items: string[] = []
    let after = false
    let phaseIdNext = false
    let phase: PhaseEx | undefined
    pathname.split('/').forEach((p) => {
      if (p === project.id) {
        after = true
        return
      }
      if (!after) return

      p = p.toLowerCase()

      if (p === 'timeline') items.push(t('EditProject.Timeline.Title'))
      else if (p === 'fees') items.push(t('EditProject.Fees.Title'))
      else if (p === 'staff') items.push(t('General.Staff'))
      else if (p === 'invitations') items.push(t('General.Invitations'))
      else if (p === 'drawings') items.push(t('EditProject.Drawings'))
      else if (p === 'documents') items.push(t('EditProject.Documents'))
      else if (p === 'photos') items.push(t('EditProject.Photos'))
      else if (p === 'estimate') items.push(t('EditProject.Estimate'))
      else if (p === 'boe') items.push(t('EditProject.BasisOfEstimate'))
      else if (p === 'phases') phaseIdNext = true
      else if (phaseIdNext) {
        phase = project.phases.find((x) => x.id?.toLowerCase() === p)
        if (phase) {
          if (phase.name) items.push(`${t(`EditProject.${phase.phaseType}`)} (${phase.name})`)
          else items.push(t(`EditProject.${phase.phaseType}`))
        }
        phaseIdNext = false
      }
    })

    return items
  }, [pathname, t])

  const phase = useMemo(() => {
    let phase: PhaseEx | undefined
    const segments = pathname.split('/')
    let idx = segments.findIndex((x) => x === 'phases')
    if (idx > 0) {
      idx++
      if (idx < segments.length) {
        const phaseId = segments[idx].toLowerCase()
        phase = project.phases.find((x) => x.id?.toLowerCase() === phaseId)
      }
    }

    return phase
  }, [project, pathname])

  const [showClassifyProjectType, setShowClassifyProjectType] = useState(false)

  const navAlt = useMemo(() => {
    const readOnly = (style?: React.CSSProperties) => <div style={style || { marginLeft: 'auto' }} />
    const updatedBy = (style?: React.CSSProperties) =>
      project.asyncUpdate && project.updatedBy && project.updatedBy.id !== rights?.id ? (
        <div className="ui-badge ui-badge-warn" style={style || { marginLeft: 'auto' }}>
          <FontAwesomeIcon icon={faExclamationTriangle} />
          &nbsp;
          {t('General.UpdatedBy')}: {project.updatedBy.givenName} {project.updatedBy.familyName}
        </div>
      ) : null

    const classifyProjectType = () => {
      if (rights?.userType !== UserType.provider || project.projectType?.category !== 99) return null
      return (
        <>
          <button type="button" className="ui-btn-danger ui-btn-xs ui-btn-solid" onClick={() => setShowClassifyProjectType(true)} tabIndex={-1}>
            <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: 5 }} />
            {t('EditProject.ClassifyProjectType.Title')}
          </button>

          {showClassifyProjectType && <ClassifyProjectType projectType={project.projectType} onClose={() => setShowClassifyProjectType(false)} onChange={() => setShowClassifyProjectType(false)} />}
        </>
      )
    }

    if (pathname.includes('/fees'))
      return (
        <div className="ui-flex ui-flex-nowrap" style={{ marginLeft: 5, alignItems: 'center', width: '100%' }}>
          <div style={{ marginRight: 20 }}>{t('EditProject.ProjectFees')}</div>
          {classifyProjectType()}
          {rights?.userType !== UserType.client ? (
            <>
              {readOnly()}
              {rights?.userType === UserType.provider && <FeeTabs project={project} />}
              {updatedBy({ marginLeft: 10 })}
            </>
          ) : (
            <>
              {readOnly()}
              {updatedBy()}
            </>
          )}
        </div>
      )

    if (phase) {
      const segments = pathname.split('/')
      let idx = segments.indexOf(phase.id!) + 1
      const segment = idx < segments.length ? segments[idx] : null
      idx++
      const segmentNext = idx < segments.length ? segments[idx] : null

      switch (segment) {
        case 'edit':
          return (
            <div className="ui-flex ui-flex-nowrap" style={{ marginLeft: 5, alignItems: 'center', width: '100%' }}>
              <div style={{ marginRight: 20, width: 130 }}>{t('EditProject.PhaseDetails.Title')}</div>
              {classifyProjectType()}
              {readOnly()}
              {updatedBy()}
            </div>
          )

        case 'estimate':
          return (
            <div className="ui-flex ui-flex-nowrap" style={{ marginLeft: 5, alignItems: 'center', width: '100%' }}>
              <div style={{ marginRight: 20, width: 130 }}>{t('EditProject.PhaseEstimate')}</div>
              {classifyProjectType()}
              {readOnly()}
              {updatedBy()}
            </div>
          )

        case 'boe':
          return (
            <div className="ui-flex ui-flex-nowrap" style={{ marginLeft: 5, alignItems: 'center', width: '100%' }}>
              <div style={{ marginRight: 20, width: 200 }}>
                {(() => {
                  if (segmentNext === 'edit') return t('EditProject.BOE.Edit')
                  if (segmentNext === 'preview') return t('EditProject.BOE.Preview')
                  return t('EditProject.BasisOfEstimate')
                })()}
              </div>
              {classifyProjectType()}
              {readOnly()}
              {updatedBy()}
            </div>
          )

        case 'files':
          return (
            <div className="ui-flex ui-flex-nowrap" style={{ marginLeft: 5, alignItems: 'center', width: '100%' }}>
              <div className="ui-text-ellipsis" style={{ marginLeft: 5, width: 200 }}>
                {t('EditProject.PhaseFiles')}
              </div>
              {classifyProjectType()}
              {readOnly({ marginLeft: 30 })}
              {updatedBy({ marginLeft: 10 })}
            </div>
          )

        case 'staff':
          return (
            <div className="ui-flex ui-flex-nowrap" style={{ marginLeft: 5, alignItems: 'center', width: '100%' }}>
              <div style={{ marginRight: 20, width: 130 }}>{t('EditProject.PhaseStaff')}</div>
              {classifyProjectType()}
              {readOnly()}
              {updatedBy()}
            </div>
          )

        default:
          return (
            <div className="ui-flex ui-flex-nowrap" style={{ marginLeft: 5, alignItems: 'center', width: '100%' }}>
              <div className="ui-text-ellipsis" style={{ marginLeft: 5, width: 200 }}>
                {t('EditProject.PhaseOverview')}
              </div>
              {classifyProjectType()}
              {readOnly()}
              {updatedBy()}
            </div>
          )
      }
    }

    return (
      <div className="ui-flex ui-flex-nowrap" style={{ marginLeft: 5, alignItems: 'center', width: '100%' }}>
        <div style={{ marginRight: 20 }}>
          {(() => {
            if (pathname.endsWith('/edit')) return t('EditProject.ProjectDetails')
            if (pathname.endsWith('/timeline')) return t('EditProject.ProjectTimeline')
            if (pathname.endsWith('/staff')) return t('EditProject.ProjectStaff')
            return t('EditProject.ProjectOverview')
          })()}
        </div>
        {classifyProjectType()}
        {readOnly()}
        {updatedBy()}
      </div>
    )
  }, [pathname, project, phase, saving, rights, showClassifyProjectType, setShowClassifyProjectType])

  return (
    <div className="project-subheader">
      <div className="project-subheader-info">
        <div className="left">
          <div style={project ? undefined : { marginTop: 0 }}>
            <div className="ui-text-ellipsis">
              <Link to={makeDashboardPath(account?.id, 'projects')} replace>
                {t('General.Projects')}
              </Link>
            </div>
            <div>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
            <div className="ui-text-ellipsis">
              <Link
                to={makeDashboardPath(account?.id, `projects/${project.id}`)}
                replace
                tabIndex={-1}
                onClick={(e) => {
                  if (!names || names.length <= 0) return
                  e.preventDefault()
                  setSelectProject(true)
                }}
              >
                {project.name}
              </Link>
            </div>
            {breadcrumb.map((i, idx) => (
              <div key={idx} className="ui-flex ui-flex-nowrap ui-text-ellipsis">
                <div>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
                <div style={{ marginLeft: 10 }}>{i}</div>
              </div>
            ))}
          </div>
          <div className="ui-text-xs">
            <div className="ui-flex ui-flex-nowrap ui-text-muted">
              {project.client?.name && (
                <>
                  <div className="ui-text-ellipsis">{project.client.name}</div>
                  <div style={{ margin: '0 10px' }}>|</div>
                </>
              )}
              {location && (
                <>
                  <div className="ui-text-ellipsis">{location}</div>
                  <div style={{ margin: '0 10px' }}>|</div>
                </>
              )}
              {project.projectType && (
                <>
                  <div className="ui-text-ellipsis">{project.projectType.type}</div>
                  <div style={{ margin: '0 10px' }}>|</div>
                </>
              )}
              {constructionType && <div className="ui-text-ellipsis">{constructionType}</div>}
            </div>
          </div>

          {names && names.length > 0 && (
            <div className="project-select" style={{ display: selectProject ? 'flex' : 'none' }}>
              <span style={{ marginRight: 15, width: 50, lineHeight: 1.1 }} className="ui-text-muted ui-text-xs">
                {t('EditProject.SelectProject')}
              </span>
              <Select
                items={names}
                style={{ width: '100%' }}
                className="ui-select-sm"
                labelField="name"
                valueField="id"
                open={selectProject}
                maxDropDown={20}
                onClose={() => {
                  setSelectProject(false)
                }}
                onSelectedChange={(p) => {
                  history.replace(makeDashboardPath(account?.id, `projects/${p.id}`))
                }}
              />
            </div>
          )}
        </div>
        <div className="right" style={{ paddingTop: 4 }}>
          <div className="ui-flex ui-flex-nowrap">
            <CommentBtn variant="toolbar" />

            {project.canInvite && (
              <a
                href={pathname}
                className="ui-btn ui-btn-primary ui-btn-circle ui-btn-invert"
                style={{ marginLeft: 10 }}
                onClick={(e) => {
                  e.preventDefault()
                  onInvite()
                }}
              >
                <FontAwesomeIcon icon={faUserPlus} />
              </a>
            )}

            {(() => {
              let variant: string | undefined
              let label = rights?.userType === UserType.client ? t(`Projects.ClientStatus.${project.status}`) : t(`Projects.${project.status}`)

              if (project.status === ProjectStatus.FeeReady) {
                const sh = project.statusHistory?.find((x) => x.newStatus === ProjectStatus.FeeReady)
                if (sh?.metadata?.isCounterReject) {
                  label = rights?.userType !== UserType.client ? t('Projects.ClientStatus.FeeCounterDecline') : t('Projects.FeeCounterDecline')
                  variant = 'danger'
                }
              }

              return (
                <div style={{ marginLeft: 30 }}>
                  <StatusBadge
                    prefixLabel={
                      <>
                        {t('General.Project')}
                        <br />
                        {t('Projects.Status')}
                      </>
                    }
                    label={label}
                    type={project.status}
                    variant={variant}
                  />
                </div>
              )
            })()}
          </div>
        </div>
      </div>
      <div className="project-subheader-nav">
        <div className={`ui-flex ui-flex-nowrap menu${asideOpen ? ' open' : ''}`} role="menu" tabIndex={-1} onKeyDown={() => { }} onClick={() => onToggleAside()}>
          <MenuSvg width={15} />
          <div style={{ margin: '0 5px' }}>{t('General.Menu')}</div>
          {asideOpen ? <MenuCloseSvg width={15} /> : <MenuOpenSvg width={15} />}
        </div>

        <div className="ui-flex" style={{ marginLeft: 30, alignItems: 'center', flex: 1 }}>
          <FolderOpenSvg width={18} />
          {navAlt}
        </div>
        <div className='ui-flex boe-top-menu'>
          <ProjectBoeTabSwitcher />
        </div>
      </div>
    </div>
  )
}
