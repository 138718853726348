import { useCallback, useReducer } from 'react';
import { ConverLetter, PurposeSection } from "../dto/basisOfEstimate";
import axiosInstance, { endpoints } from "../lib/axios";

export const generateCoverLetter = async (data: ConverLetter): Promise<string> => {
    const response = await axiosInstance.post(endpoints.boe.coverLetter, data);
    return response.data;
};

export const generatePurposeSection = async (data: PurposeSection): Promise<string> => {
    const response = await axiosInstance.post(endpoints.boe.proposeSection, data);
    return response.data;
};


// Define action types
const actionTypes = {
  REQUEST_START: 'REQUEST_START',
  REQUEST_SUCCESS: 'REQUEST_SUCCESS',
  REQUEST_FAILURE: 'REQUEST_FAILURE',
};

// Define the initial state
const initialState = {
  data: null,
  loading: false,
  error: null,
};

// Define the reducer function
interface State {
    data: any;
    loading: boolean;
    error: any;
}

interface Action {
    type: string;
    payload?: any;
}

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case actionTypes.REQUEST_START:
            return { ...state, loading: true, error: null };
        case actionTypes.REQUEST_SUCCESS:
            return { ...state, loading: false, data: action.payload };
        case actionTypes.REQUEST_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

// Custom hook to handle different HTTP requests
export const useHttpRequest = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const sendRequest = useCallback(async (method, endpoint, data = null) => {
    const controller = new AbortController();
    const { signal } = controller;

    dispatch({ type: actionTypes.REQUEST_START });

    try {
      const response = await axiosInstance({
        method,
        url: endpoint,
        data,
        signal,
      });
      dispatch({ type: actionTypes.REQUEST_SUCCESS, payload: response.data });
    } catch (error) {

        dispatch({ type: actionTypes.REQUEST_FAILURE, payload: error });
    }

    return () => controller.abort();
  }, []);

  return {
    ...state,
    sendRequest,
  };
};

// Usage examples for different HTTP methods
export const useGetRequests = () => {
  const { data, loading, error, sendRequest } = useHttpRequest();
  const getRequests = useCallback((endpoint) => sendRequest('get', endpoint), [sendRequest]);

  return { data, loading, error, getRequests };
};

export const usePostRequests = () => {
  const { data, loading, error, sendRequest } = useHttpRequest();
  const postRequests = useCallback((endpoint, data) => sendRequest('post', endpoint, data), [sendRequest]);

  return { data, loading, error, postRequests };
};

export const usePutRequests = () => {
  const { data, loading, error, sendRequest } = useHttpRequest();
  const putRequests = useCallback((endpoint, data) => sendRequest('put', endpoint, data), [sendRequest]);

  return { data, loading, error, putRequests };
};

export const useDeleteRequests = () => {
  const { data, loading, error, sendRequest } = useHttpRequest();
  const deleteRequests = useCallback((endpoint) => sendRequest('delete', endpoint), [sendRequest]);

  return { data, loading, error, deleteRequests };
};

// use upload file
export const useUploadFile = () => {
  const { data, loading, error, sendRequest } = useHttpRequest();
  const uploadFile = useCallback((endpoint, data) => sendRequest('post', endpoint, data), [sendRequest]);

  return { data, loading, error, uploadFile };
};