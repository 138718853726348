import React from 'react'
import { useTranslation } from 'react-i18next'

import LockSvg from 'components/src/svg/Lock'
import { changePassword } from 'services/src/aad'
import { useRights, UserType } from 'services/src/state'

export const ProviderSecurity: React.FC = () => {
  const { t } = useTranslation()
  const rights = useRights()

  return (
    <form noValidate onSubmit={() => {}}>
      <div className="settings-user-layout">
        <div className="ui-flex" style={{ justifyContent: 'center', marginTop: 90 }}>
          <LockSvg style={{ width: 110, height: 100 }} width="110" height="110" />
        </div>

        <div style={{ width: 150, margin: '20px auto 0 auto' }} className="ui-text-center">
          {rights?.userType === UserType.provider ? t('General.ProviderOffice365Info') : t('Expert.Settings.ClickToResetPassword')}
        </div>

        <div className="ui-flex" style={{ justifyContent: 'center', marginTop: 15 }}>
          {rights?.userType === UserType.provider ? (
            <button type="button" style={{ width: 'auto' }} className="ui-btn ui-btn-primary ui-btn-solid" onClick={() => window.open('https://myaccount.microsoft.com')}>
              {t('General.ProviderOffice365')}
            </button>
          ) : (
            <button type="button" className="ui-btn ui-btn-primary ui-btn-solid" style={{ width: 'auto' }} onClick={() => changePassword()}>
              {t('Expert.Settings.ResetPassword')}
            </button>
          )}
        </div>
      </div>
    </form>
  )
}
