import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMsalName } from 'services/src/aad'
import { CompanyType } from 'services/src/dto/account'
import { numberOfEmployeesItems, PHOTO_TYPES } from 'services/src/common'

import { DropzoneField } from 'components/src/dropzoneField'
import { TextField } from 'components/src/textField'
import { SelectField } from 'components/src/selectField'
import { AddressField } from 'components/src/addressField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/pro-solid-svg-icons'
import { NewAccountModel } from './model'
import { TextAreaField } from 'components/src/textAreaField'

export interface BusinessProfileProps {
  model: NewAccountModel
  companyTypes: CompanyType[]
  onChangeModel: (mode: NewAccountModel) => void
  onNext: () => void
}

export const BusinessProfile: React.FC<BusinessProfileProps> = ({ model, companyTypes, onChangeModel, onNext }) => {
  const { t } = useTranslation()
  const { givenName } = useMsalName()

  const [errors, setErrors] = useState<any>({})
  const clearError = (name: string) => {
    const theErrors = { ...errors }
    delete theErrors[name]
    setErrors(theErrors)
  }

  const onDropLogo = useCallback(
    (acceptedFiles: any[]) => {
      if (!acceptedFiles.length) return
      const logo = Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0])
      })

      onChangeModel({ ...model, logo })
    },
    [model, onChangeModel]
  )

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      const errors: any = {}
      if (!model.companyName) errors.companyName = t('General.Errors.Required')
      else if (model.companyName.length < 2 || model.companyName.length > 250) errors.companyName = t('General.Errors.BetweenNAndMCharacters', { N: 2, M: 250 })
      if (!model.companyType) errors.companyType = t('General.Errors.Required')
      if (!model.numberOfEmployees) errors.numberOfEmployees = t('General.Errors.Required')

      if (!model.companyAddress) errors.address = { street: t('General.Errors.Required') }
      else {
        const ae: any = {}
        if (!model.companyAddress.municipality) ae.city = t('General.Errors.Required')
        if (Object.keys(ae).length > 0) errors.address = ae
      }

      setErrors(errors)
      if (Object.keys(errors).length > 0) return

      onNext()
    },
    [model, onNext, setErrors, t]
  )

  const isProvider = model.createUserType === 'provider'
  const isExpert = model.createUserType === 'expert'

  const intro = () => {
    if (isExpert) return 'Onboarding.Expert.Intro'
    if (isProvider) return 'Onboarding.Expert.Intro'
    return 'Onboarding.ClientBusinessIntro'
  }

  const step = () => {
    if (isExpert) return t('Onboarding.Expert.Step1', {N: 1, M: 5})
    if (isProvider) return t('Onboarding.Provider.Step1', {N: 1, M: 3})
    return t('Onboarding.ClientStep1')
  }

  return (
    <form onSubmit={submit} className="label-primary" noValidate>
      <h3 className="intro">{t(intro(), { name: givenName })}</h3>
      <hr />
      <br />

      <div className="ui-form-group ui-text-sm ui-text-muted">{step()}</div>

      <DropzoneField
        onDrop={onDropLogo}
        variant="round"
        accept={PHOTO_TYPES}
        maxFiles={1}
        maxSize={1024 * 1024}
        rejectLabel={t('Onboarding.InvalidCompanyLogo')}
        label={t('Onboarding.CompanyLogo')}
        error={errors.logo}
      >
        <div className="ui-flex" style={{ justifyContent: 'center' }}>
          {model.logo?.preview ? (
            <div className="bg-preview" style={{ width: 130, height: 130, backgroundImage: `url('${model.logo?.preview}')` }} />
          ) : (
            <div className="empty" style={{ width: 130, height: 130, paddingTop: 40 }}>
              <FontAwesomeIcon icon={faCamera} />
            </div>
          )}
        </div>
      </DropzoneField>

      <TextField
        name="companyName"
        value={model.companyName}
        label={t('Onboarding.CompanyName')}
        error={errors.companyName}
        onChange={(companyName) => {
          onChangeModel({ ...model, companyName })
          clearError('companyName')
        }}
        placeholder={t('Onboarding.CompanyNamePlaceholder')}
      />

      {isProvider && (
        <TextField
          name="tagLine"
          value={model.tagLine}
          label={t('Onboarding.TagLine')}
          error={errors.tagLine}
          onChange={(tagLine) => {
            onChangeModel({ ...model, tagLine })
            clearError('tagLine')
          }}
          placeholder={t('Onboarding.TagLinePlaceholder')}
        />
      )}

      {isProvider && (
        <TextAreaField
          name="overview"
          rows={5}
          value={model.overview}
          label={t('Onboarding.Overview')}
          error={errors.overview}
          onChange={(overview) => {
            onChangeModel({ ...model, overview })
            clearError('overview')
          }}
          placeholder={t('Onboarding.OverviewPlaceholder')}
        />
      )}

      <div className="ui-row">
        <div className="ui-col-12 ui-col-lg-6">
          <SelectField
            items={companyTypes}
            selected={model.companyType}
            onSelectedChange={(companyType) => {
              onChangeModel({ ...model, companyType })
              clearError('companyType')
            }}
            placeholder={t('Onboarding.CompanyTypePlaceholder')}
            label={t('Onboarding.CompanyType')}
            error={errors.companyType}
            labelField="label"
            valueField="id"
            maxDropDown={12}
          />
        </div>
        <div className="ui-col-12 ui-col-lg-6">
          <SelectField
            items={numberOfEmployeesItems}
            selected={model.numberOfEmployees}
            onSelectedChange={(numberOfEmployees) => {
              onChangeModel({ ...model, numberOfEmployees })
              clearError('numberOfEmployees')
            }}
            placeholder={t('Onboarding.EmployeesPlaceholder')}
            label={t('Onboarding.Employees')}
            error={errors.numberOfEmployees}
            labelField="label"
            valueField="id"
            maxDropDown={5}
          />
        </div>
      </div>

      <AddressField
        address={model.companyAddress}
        errors={errors.address || {}}
        onChange={(companyAddress) => {
          onChangeModel({ ...model, companyAddress })
          clearError('address')
        }}
        streetLabel={t('Onboarding.BusinessAddress')}
        streetSample={t('Onboarding.BusinessAddressPlaceholder')}
      />

      <div>
        <button type="submit" className="ui-btn ui-btn-secondary ui-btn-solid" style={{ width: '100%' }}>
          {t('Onboarding.Next')}
        </button>
      </div>
    </form>
  )
}
