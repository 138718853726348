import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { PhaseEx, useEditProject, useRights, UserType } from 'services/src/state'
import { ProjectStatus } from 'services/src/dto/project'
import logo from 'components/src/img/logo-alt.png'

type PhaseWithEx = PhaseEx & {
  isCounter: boolean
}

export const FeeProviderApprove: React.FC = () => {
  const { t } = useTranslation()
  const rights = useRights()

  const { project } = useEditProject()

  const { phases, totalFee } = useMemo(() => {
    let approveDate = moment()
    let isCounter = false

    const phases = project.phases.filter((x) => [ProjectStatus.Approved, ProjectStatus.InProgress, ProjectStatus.Complete].includes(x.status!)).map<PhaseWithEx>((ph) => ({ ...ph, isCounter: false }))

    let totalFee = 0
    let originalTotalFee = 0
    phases.forEach((ph) => {
      const sh = ph.statusHistory?.find((x) => x.newStatus === ProjectStatus.Approved)
      if (!sh) return

      if (!isCounter) isCounter = sh.oldStatus === ProjectStatus.FeeCounter

      if (moment(sh.createdAt).isBefore(approveDate)) approveDate = moment(sh.createdAt)

      if (sh.metadata) {
        originalTotalFee += sh.metadata.originalFee || sh.metadata[`phase.${ph.id}.originalFee`] || 0
        ph.fee = sh.metadata?.fee || sh.metadata[`phase.${ph.id}.fee`] || 0
      }

      totalFee += ph.fee!
    })

    return { phases, totalFee, originalTotalFee, approveDate }
  }, [project])

  if (rights?.userType !== UserType.provider || !phases?.length) return null

  return (
    <div className="ui-frame ui-frame-bg project-edit-client-accept" style={{ maxWidth: 450 }}>
      <div className="the-total">
        <img alt="" src={logo} />
        <div style={{ marginLeft: 10 }}>{t('EditProject.Fees.ClientFeeApproval')}</div>
        <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: totalFee })}</div>
      </div>

      <div className="ui-flex ui-flex-nowrap ui-text-sm ui-text-muted" style={{ marginTop: 20 }}>
        <div>{moment(project.feeApprovedAt).format('ll')}</div>
        <div style={{ marginLeft: 'auto' }}>{t('EditProject.Fees.FeeApproved')}</div>
      </div>

      <div className="ui-text-sm" style={{ marginTop: 20 }}>
        {phases.map((ph) => (
          <div key={ph.id} className="ui-flex ui-flex-nowrap">
            <div>
              {t(`EditProject.${ph.phaseType}`)}
              {ph.name && (
                <span className="ui-text-muted" style={{ marginLeft: 5 }}>
                  ({ph.name})
                </span>
              )}
            </div>
            <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: ph.fee, minDigits: 2, maxDigits: 2 })}</div>
          </div>
        ))}
      </div>

      <div className="ui-flex ui-flex-nowrap ui-text-lg ui-secondary" style={{ marginTop: 10 }}>
        <div style={{ width: 200 }}>{t('General.Total')}</div>
        <div style={{ marginLeft: 'auto' }}>{t('General.Currency.Value', { value: totalFee })}</div>
      </div>

      {/* originalTotalFee > 0 && (originalTotalFee !== totalFee) && (
        <div className="ui-text-sm">
          <div className="ui-flex ui-flex-nowrap ui-text-muted" style={{marginTop: 10}}>
            <div style={{width:200}}>{t('General.OriginalTotal')}</div>
            <div style={{marginLeft: 'auto'}}>
              {t('General.Currency.Value', {value: originalTotalFee})}
            </div>
          </div>
          <div className="ui-flex ui-flex-nowrap ui-text-muted">
            <div style={{width:200}}>{t('General.Adjustment')}</div>
            <div style={{marginLeft: 'auto'}}>
              {t('General.Currency.Value', {value: totalFee - originalTotalFee})}
            </div>
          </div>
        </div>
      ) */}
    </div>
  )
}
