import React, { useCallback } from 'react'
import { ResourceUserRole } from 'services/src/dto/account'
import { DisciplineKey, FeeType } from 'services/src/dto/projectShare'
import { PhaseEx, useEditProject } from 'services/src/state'
import { Estimate } from 'services/src/dto/project'
import { useTranslation } from 'react-i18next'
import { useProjectSupport } from 'services/src/api'

// eslint-disable-next-line react/display-name
export const FeeClientSummaryPrint = React.forwardRef<any, any>((props, ref) => {
  const { t } = useTranslation()

  const [{ budgetRanges, constructionTypes }] = useProjectSupport()
  const { project } = useEditProject()

  const printHours = useCallback((phase: PhaseEx, discipline: DisciplineKey) => {
    const est = phase.estimates?.find((x: Estimate) => x.discipline === discipline)
    if (!est) return <div />

    const total = est.negotiatedTotals ? est.negotiatedTotals.hours : est.manualLevelOfEffortHours
    return <div className="ui-text-center">{total > 0 ? `${total} ${t('General.HoursAlt')}` : '-'}</div>
  }, [t])

  const printTotalHours = useCallback((phase: PhaseEx) => {
    let total = 0
    phase.estimates?.forEach((est) => {
      total += est.negotiatedTotals ? est.negotiatedTotals.hours : est.manualLevelOfEffortHours
    })
    return <div className="ui-text-center">{`${total} ${t('General.HoursAlt')}`}</div>
  }, [t])

  return (
    <div ref={ref}>
      <h3 className="ui-text-bold">{t('EditProject.Fees.ProjectDetailSummary')}</h3>
      <div>{project.name}</div>
      <br />

      {/* SUMMARY */}
      <div>
        <div>
          <div className="ui-text-bold">{t('EditProject.SummaryBar.PrimaryContact')}</div>
          {(() => {
            const pu = project.users?.find((u) => u.role === ResourceUserRole.Owner && !!project.providerAccountWithUsers.users.find((u2) => u2.id === u.id))
            return pu ? <div>{`${pu.givenName} ${pu.familyName}`}</div> : <div>{t('General.NA')}</div>
          })()}
        </div>

        <div className="ui-text-bold" style={{ marginTop: '1rem' }}>
          {t('EditProject.SummaryBar.ProjectCategory')}
        </div>

        <div className="ui-flex">
          <div style={{ width: '12rem' }}>{t('EditProject.SummaryBar.ConstructionType')}</div>
          <div>{constructionTypes.find((x) => x.id === project.constructionType)?.type || t('General.NA')}</div>
        </div>

        <div className="ui-flex">
          <div style={{ width: '12rem' }}>{t('EditProject.SummaryBar.ProjectType')}</div>
          <div>{project.projectType?.type || t('General.NA')}</div>
        </div>

        <div className="ui-flex">
          <div style={{ width: '12rem' }}>{t('EditProject.SummaryBar.BuildingCategory')}</div>
          <div>{project.projectType?.category && project.projectType?.subcategory ? `${project.projectType?.category}.${project.projectType?.subcategory}` : t('General.NA')}</div>
        </div>

        <div className="ui-flex">
          <div style={{ width: '12rem' }}>{t('EditProject.SummaryBar.FeeCategory')}</div>
          <div>{project.projectType?.category || t('General.NA')}</div>
        </div>

        <div className="ui-text-bold" style={{ marginTop: '1rem' }}>
          {t('EditProject.SummaryBar.ConstructionBudget')}
        </div>

        <div className="ui-flex">
          <div style={{ width: '12rem' }}>{t('EditProject.SummaryBar.Type')}</div>
          <div>{t(project.budget?.isFixed ? 'General.Currency.Fixed' : 'General.Currency.Range')}</div>
        </div>

        <div className="ui-flex">
          <div style={{ width: '12rem' }}>{t('EditProject.SummaryBar.Total')}</div>
          <div>{project.budget?.isFixed ? t('General.Currency.Value', { value: project.budget.fixed, maxDigits: 0 }) : budgetRanges.find((x) => x.id === project.budget?.range)?.range || '0'}</div>
        </div>
      </div>

      {/* PHASE BREAKDOWN */}
      {project.phases.map((ph) => (
        <React.Fragment key={ph.id}>
          <div className="ui-text-bold" style={{ marginTop: '1rem' }}>
            {t(`EditProject.${ph.phaseType}`)}
            {ph.name && (
              <span className="ui-text-muted" style={{ marginLeft: 5 }}>
                ({ph.name})
              </span>
            )}
          </div>
          <div className="ui-flex ui-text-xxs" style={{ alignItems: 'flex-end' }}>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {t(`General.Disciplines.${DisciplineKey.ProjectPrincipal}`)}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {t(`General.Disciplines.${DisciplineKey.ProjectManagement}`)}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {t(`General.Disciplines.${DisciplineKey.QaQc}`)}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {t(`General.Disciplines.${DisciplineKey.General}`)}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {t(`General.Disciplines.${DisciplineKey.Mechanical}`)}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {t(`General.Disciplines.${DisciplineKey.Electrical}`)}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {t(`General.Disciplines.${DisciplineKey.SiteCivil}`)}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {t(`General.Disciplines.${DisciplineKey.TechnicalAssistant}`)}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {t(`General.Disciplines.${DisciplineKey.SubjectMaterExpert}`)}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {t('General.TotalHours')}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {t('General.Total')}
            </div>
          </div>
          <div className="ui-flex ui-text-xs" style={{ alignItems: 'flex-end' }}>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {printHours(ph, DisciplineKey.ProjectPrincipal)}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {printHours(ph, DisciplineKey.ProjectManagement)}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {printHours(ph, DisciplineKey.QaQc)}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {printHours(ph, DisciplineKey.General)}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {printHours(ph, DisciplineKey.Mechanical)}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {printHours(ph, DisciplineKey.Electrical)}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {printHours(ph, DisciplineKey.SiteCivil)}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {printHours(ph, DisciplineKey.TechnicalAssistant)}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {printHours(ph, DisciplineKey.SubjectMaterExpert)}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              {printTotalHours(ph)}
            </div>
            <div style={{ width: '5rem' }} className="ui-text-center">
              <div className="ui-text-center ui-text-bold">{t('General.Currency.Value', { value: ph.fee })}</div>
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  )
})
