import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft, faSquare, faCheckSquare } from '@fortawesome/pro-regular-svg-icons'

import { useSaving } from 'components/src/saving'
import { useProjectSupport } from 'services/src/api'
import { ExpertCategoryExperience } from 'services/src/dto/account'
import { ProjectType } from 'services/src/dto/project'
import { makeDashboardPath } from 'services/src/dom'
import { Link } from 'react-router-dom'
import { useCurrentUserAccount } from 'services/src/state'
import { ProviderAccountProfileViewModel } from './model'
import { Footer } from '../Footer'

export const ProviderAccountExperience: React.FC<{
  vm: ProviderAccountProfileViewModel
  onChange: (vm: ProviderAccountProfileViewModel) => void
  onSubmit: (view: string, vm: ProviderAccountProfileViewModel) => void
}> = ({ vm, onChange, onSubmit }) => {
  const { t } = useTranslation()
  const [{ buildingCategories }] = useProjectSupport()
  const [account] = useCurrentUserAccount()
  const [saving] = useSaving()

  const [category, setCategory] = useState(1)

  const availableRef = useRef<HTMLDivElement>(null)

  const [height, setHeight] = useState(window.innerHeight - 380)
  const resize = useCallback(() => {
    setHeight(window.innerHeight - 380)
  }, [])
  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  const toggleAvailable = useCallback(
    (pt: ProjectType) => {
      let categoryExperiences = JSON.parse(JSON.stringify(vm.categoryExperiences || [])) as ExpertCategoryExperience[]
      let ce = categoryExperiences.find((x) => x.category === category)
      if (!ce) {
        ce = {
          category,
          items: []
        }
        categoryExperiences.push(ce)
      }

      let idx = ce.items.findIndex((i) => i.projectTypeId === pt.id)
      if (idx < 0) {
        ce.items.push({ projectTypeId: pt.id, type: pt.type })
        ce.items = ce.items.sort((a, b) => a.type.localeCompare(b.type))
      } else ce.items.splice(idx, 1)

      if (!ce.items.length) {
        idx = categoryExperiences.findIndex((x) => x.category === category)
        if (idx >= 0) categoryExperiences.splice(idx, 1)
      }

      categoryExperiences = categoryExperiences.sort((a, b) => a.category - b.category)

      onChange({ ...vm, categoryExperiences })
    },
    [category, vm, onChange]
  )

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      const vmx: ProviderAccountProfileViewModel = { ...vm, errors: {} }
      if (Object.keys(vmx.errors).length) {
        onChange(vmx)
        return
      }
      onSubmit('experience', vmx)
    },
    [vm, onChange, onSubmit]
  )

  return (
    <form noValidate onSubmit={submit}>
      <div className="settings-user-layout">
        <div className="ui-row">
          <div className="ui-col-4">
            <div className="settings-exp-left" style={{ height }}>
              <label>{t('Settings.Provide.ProjectExperience')}</label>
              <div className="settings-exp-selected-items" style={{ height: '100%' }}>
                {vm.categoryExperiences?.map((ce) => (
                  <div key={ce.category}>
                    <label>{t('Settings.EditExpert.CategoryN', { number: ce.category })}</label>
                    {ce.items.length ? (
                      <>
                        {ce.items.map((i) => (
                          <div key={i.projectTypeId} className="settings-exp-selected-item">
                            {i.type}
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="settings-exp-selected-item none-selected">{t('Settings.EditExpert.NoExpSelected')}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="ui-col-8">
            <div className="settings-exp-right" style={{ height }}>
              <label>{t('Settings.EditExpert.CategoryN', { number: category })}</label>
              <label className="ui-text-xs">{t('Settings.EditExpert.SelectExp')}</label>
              <div className="settings-exp-available-items" ref={availableRef} style={{ height: height - 130 }}>
                {buildingCategories
                  .find((x) => x.category === category)
                  ?.projectTypes.map((pt) => {
                    const selected = vm.categoryExperiences?.find((ce) => ce.category === pt.category)?.items.find((x) => x.projectTypeId === pt.id)
                    return (
                      <div
                        key={pt.id}
                        role="checkbox"
                        aria-checked={!!selected}
                        tabIndex={0}
                        className={`settings-exp-available-item${selected ? ' settings-exp-available-item-selected' : ''}`}
                        onClick={() => toggleAvailable(pt)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') toggleAvailable(pt)
                        }}
                      >
                        <FontAwesomeIcon icon={selected ? faCheckSquare : faSquare} />
                        &nbsp;&nbsp;{pt.type}
                      </div>
                    )
                  })}
              </div>
              <div className="settings-exp-available-nav">
                {category > 1 && (
                  <button
                    type="button"
                    className="ui-btn ui-btn-primary ui-btn-sm"
                    onClick={() => {
                      setCategory(category - 1)
                      if (availableRef.current) availableRef.current.scrollTop = 0
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                    &nbsp;&nbsp;
                    {t('Settings.EditExpert.CategoryN', { number: category - 1 })}
                  </button>
                )}
                {category < 7 && (
                  <button
                    type="button"
                    className="ui-btn ui-btn-primary ui-btn-sm"
                    onClick={() => {
                      setCategory(category + 1)
                      if (availableRef.current) availableRef.current.scrollTop = 0
                    }}
                  >
                    {t('Settings.EditExpert.CategoryN', { number: category + 1 })}
                    &nbsp;&nbsp;
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer>
        <div className="ui-flex ui-flex-nowrap" style={{ alignItems: 'center' }}>
          <Link to={makeDashboardPath(account?.id)} className="ui-btn ui-btn-primary ui-btn-solid">
            {t('Dashboard.Title')}
          </Link>
          <button type="submit" style={{ marginLeft: 'auto' }} disabled={!vm.dirty || saving} className="ui-btn ui-btn-secondary ui-btn-solid">
            {t('General.Update')}
          </button>
        </div>
      </Footer>
    </form>
  )
}
