import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Address, Client, Office } from 'services/src/dto/account'
import { ProjectType } from 'services/src/dto/projectShare'
import { BudgetRange, ConstructionType, DeliveryMethod, EstimateType, ProcurementMethod } from 'services/src/dto/projectSupport'

import { faEdit } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TextField } from 'components/src/textField'
import { usePostRequests } from 'services/src/api/boe'
import { endpoints } from 'services/src/lib/axios'
import {
  ProjectEx, useCurrentUserAccount, useEditProject,
} from 'services/src/state'
import { Loading } from '../../../loading'


interface ConverLetterViewModel {
  projectNumber: string
  taskOrderNumber: string
  proposalDate: string
  projectTitle: string
  projectLocation: string,

  projectManagerName: string
  projectManagerContactInfo: string

  clientCompanyName: string
  clientName: string
  clientSuggestions: Client[]
  client: Client | null
  clientAddress: string
  clientCity: string
  clientState: string
  clientPinCode: string
  clientCountry: string

  projectType: ProjectType | null
  projectTypeName: string
  projectTypeSuggestions: ProjectType[]

  constructionType: ConstructionType | null
  deliveryMethod: DeliveryMethod | null
  procurementMethod: ProcurementMethod | null
  estimateType: EstimateType | null

  name: string
  description: string
  office: Office | null
  location: Address | null
  range: BudgetRange | null
  fixed: number
  isFixedBudget: boolean
  newClient: Client | null
}

const BoeProject10: React.FC<{
  handleNext: () => void,
  setBoeData: (data: any) => void,
  boeData: any
}> = ({
  handleNext,
  setBoeData,
  boeData,
}) => {
    const { t } = useTranslation()
    const [account] = useCurrentUserAccount()
    const { project } = useEditProject()
    const saving = false;

    const [errors, setErrors] = useState<any>({})
    const [coverLetter, setCoverLetter] = useState<string>(boeData?.coverLetter || '');
    const [call, setCall] = useState<boolean>(false);


    const { loading: loadingGetCoverLetter, error: errorCoverLetter, data, postRequests } = usePostRequests();

    useEffect(() => {
      if (coverLetter === '' && !call) {
        setCall(true);
        postRequests(endpoints.boe.coverLetter, {
          clientName: project.account?.companyName,
          projectCost: project?.budget?.isFixed ? project?.budget?.fixed : project?.budget?.range
        });
      }
    }, [postRequests, coverLetter, call, project]);

    useEffect(() => {
      if (data?.response) {
        setCoverLetter(data?.response || '');
      }
    }, [data?.response]);
    const clearError = (name: string) => {
      const theErrors = { ...errors }
      delete theErrors[name]
      setErrors(theErrors)
    }

    const initVm = (project: ProjectEx) => {
      const vm: ConverLetterViewModel = {
        projectNumber: project.projectNumber || '',
        taskOrderNumber: project.taskOrderNumber || '',
        // set default proposal date to current date
        proposalDate: new Date().toISOString().split('T')[0],
        projectTitle: project.name || '',
        projectLocation: project?.location?.freeformAddress || '',

        // project manager details
        projectManagerName: account?.users ? `${account?.users[0]?.familyName} ${account?.users[0]?.givenName}` || '' : '',
        projectManagerContactInfo: account?.offices?.[0]?.address?.freeformAddress ?? '',
        // Client Details
        clientCompanyName: project.account?.companyName || '',
        clientName: project.account?.users ? `${project.account?.users[0]?.familyName} ${project.account?.users[0]?.givenName}` || '' : '',
        clientSuggestions: [],
        clientAddress: project?.account?.offices ? project?.account?.offices[0]?.address?.freeformAddress || '' : '',
        clientCity: project?.account?.offices ? project?.account?.offices[0]?.address?.municipality || '' : '',
        clientState: project?.account?.offices ? project?.account?.offices[0]?.address?.countrySubdivision || '' : '',
        clientPinCode: project?.account?.offices ? project?.account?.offices[0]?.address?.buildingNumber || '' : '',
        clientCountry: project?.account?.offices ? project?.account?.offices[0]?.address?.country || '' : '',

        // Add the missing properties
        client: null,
        projectType: null,
        projectTypeName: '',
        projectTypeSuggestions: [],
        constructionType: null,
        deliveryMethod: null,
        procurementMethod: null,
        estimateType: null,
        name: '',
        description: '',
        office: null,
        location: null,
        range: null,
        fixed: 0,
        isFixedBudget: false,
        newClient: null,
      }
      return vm
    }

    const [vm, setVm] = useState<ConverLetterViewModel>(() => initVm(project))

    useEffect(() => setVm(initVm(project)), [project, setVm])

    const validate = useCallback((vm: ConverLetterViewModel) => {
      const errors: any = []
      if (!vm.proposalDate) {
        errors.proposalDate = t('BasisOfEstimate.ConverLetter.Errors.ProposalDate')
      }
      return errors
    }, [])

    const submit = (e: FormEvent) => {
      e.preventDefault()
      const errors = validate(vm)
      if (Object.keys(errors).length > 0) {
        setErrors(errors)
        return
      }
      const projectData = {
        clientName: vm.clientName,
        clientCompanyName: vm.clientCompanyName,
        projectName: vm.projectTitle,
        projectAddress: vm.projectLocation,
      }
      setBoeData((prev: any) => ({ ...prev, coverLetter, ...projectData }));
      handleNext()
    }

    return (
      <>
        <form noValidate onSubmit={submit} className='boe-form'>
          <div className="boe-form-content">
            {loadingGetCoverLetter && (
              <Loading />
            )}
            {errorCoverLetter && (
              <div className="boe-error-container">
                <p>{t('General.Error')}</p>
              </div>
            )}
            <div className="boe-flex-row">
              <span className='boe-sub-heading'> {`${t('BasisOfEstimate.ConverLetter.ProjectNumber')}:`}</span>
              <span className='boe-sub-heading-bold'>{`${vm.projectNumber}`}</span>
              <FontAwesomeIcon icon={faEdit} />
            </div>
            <h2 className='title'>{t('BasisOfEstimate.ConverLetter.Headings.UpdateBasicInformation')}</h2>
            <div className="ui-row">
              {/* proposal date */}
              <div className="ui-col-12 ui-col-lg-2">
                <TextField
                  name="proposalDate"
                  value={vm.proposalDate}
                  label={t('BasisOfEstimate.ConverLetter.ProposalDate')}
                  disabled={saving}
                  type='date'
                  error={errors.proposalDate}
                  onChange={(proposalDate) => {
                    clearError('proposalDate')
                    setVm((vm) => ({ ...vm, proposalDate }))
                  }}
                />
              </div>
              <div className="ui-col-12 ui-col-lg-1" />
              {/* project title */}
              <div className="ui-col-12 ui-col-lg-4">
                <TextField
                  name="projectTitle"
                  value={vm.projectTitle}
                  label={t('BasisOfEstimate.ConverLetter.ProjectTitle')}
                  disabled={saving}
                  error={errors.projectTitle}
                  onChange={(projectTitle) => {
                    clearError('projectTitle')
                    setVm((vm) => ({ ...vm, projectTitle }))
                  }}
                />
              </div>
              <div className="ui-col-12 ui-col-lg-1" />
              {/* project location */}
              <div className="ui-col-12 ui-col-lg-4">
                <TextField
                  name="projectLocation"
                  value={vm.projectLocation ? vm.projectLocation.toString() : ''}
                  label={t('BasisOfEstimate.ConverLetter.ProjectLocation')}
                  disabled={saving}
                  error={errors.projectLocation}
                  onChange={(projectLocation) => {
                    clearError('projectLocation')
                    setVm((vm) => ({ ...vm, projectLocation }))
                  }}
                />
              </div>
            </div>

            <h2 className='title'>{t('BasisOfEstimate.ConverLetter.Headings.ProjectManagerDetails')}</h2>

            <div className="ui-row">
              {/* project manager name* */}
              <div className="ui-col-12 ui-col-lg-4">
                <TextField
                  name="projectManagerName"
                  value={vm.projectManagerName}
                  label={t('BasisOfEstimate.ConverLetter.ProjectManagerName')}
                  disabled={saving}
                  placeholder={t('BasisOfEstimate.ConverLetter.ProjectManagerName')}
                  error={errors.projectManagerName}
                  onChange={(projectManagerName) => {
                    clearError('projectManagerName')
                    setVm((vm) => ({ ...vm, projectManagerName }))
                  }}
                />
              </div>
              <div className="ui-col-12 ui-col-lg-3">
                <TextField
                  name="projectManagerContactInfo"
                  value={vm.projectManagerContactInfo}
                  label={t('BasisOfEstimate.ConverLetter.ProjectManagerContactInfo')}
                  placeholder={t('BasisOfEstimate.ConverLetter.ProjectManagerContactInfo')}
                  disabled={saving}
                  error={errors.projectManagerContactInfo}
                  onChange={(projectManagerContactInfo) => {
                    clearError('projectManagerContactInfo')
                    setVm((vm) => ({ ...vm, projectManagerContactInfo }))
                  }}
                />
              </div>
            </div>

            <h2 className='title'>{t('BasisOfEstimate.ConverLetter.Headings.ClientDetails')}</h2>

            <div className="ui-row">
              <div className="ui-col-12 ui-col-lg-6">
                <TextField
                  name="clientCompanyName"
                  value={vm.clientCompanyName}
                  label={t('BasisOfEstimate.ConverLetter.ClientCompanyName')}
                  disabled={saving}
                  placeholder={t('BasisOfEstimate.ConverLetter.ClientCompanyName')}
                  error={errors.clientCompanyName}
                  onChange={(clientCompanyName) => {
                    clearError('clientCompanyName')
                    setVm((vm) => ({ ...vm, clientCompanyName }))
                  }}
                />
              </div>
              <div className="ui-col-12 ui-col-lg-6">
                <TextField
                  name="clientName"
                  value={vm.clientName}
                  label={t('BasisOfEstimate.ConverLetter.ClientName')}
                  placeholder={t('BasisOfEstimate.ConverLetter.ClientName')}
                  disabled={saving}
                  error={errors.clientName}
                  onChange={(clientName) => {
                    clearError('clientName')
                    setVm((vm) => ({ ...vm, clientName }))
                  }}
                />
              </div>

              <div className='ui-col-12 ui-col-lg-12'>
                <TextField
                  name="clientAddress"
                  value={vm.clientAddress}
                  label={t('BasisOfEstimate.ConverLetter.ClientAddress')}
                  placeholder={t('BasisOfEstimate.ConverLetter.ClientAddress')}
                  disabled={saving}
                  error={errors.clientAddress}
                  onChange={(clientAddress) => {
                    clearError('clientAddress')
                    setVm((vm) => ({ ...vm, clientAddress }))
                  }}
                />
              </div>
              <div className='ui-col-12 ui-col-lg-2'>
                <TextField
                  name="clientCity"
                  value={vm.clientCity}
                  label={t('BasisOfEstimate.ConverLetter.ClientCity')}
                  placeholder={t('BasisOfEstimate.ConverLetter.ClientCity')}
                  disabled={saving}
                  error={errors.clientCity}
                  onChange={(clientCity) => {
                    clearError('clientCity')
                    setVm((vm) => ({ ...vm, clientCity }))
                  }}
                />
              </div>
              <div className="ui-col-12 ui-col-lg-1" />

              <div className='ui-col-12 ui-col-lg-2'>
                <TextField
                  name="clientState"
                  value={vm.clientState}
                  label={t('BasisOfEstimate.ConverLetter.ClientState')}
                  placeholder={t('BasisOfEstimate.ConverLetter.ClientState')}
                  disabled={saving}
                  error={errors.clientState}
                  onChange={(clientState) => {
                    clearError('clientState')
                    setVm((vm) => ({ ...vm, clientState }))
                  }}
                />
              </div>
              <div className="ui-col-12 ui-col-lg-1" />
              <div className='ui-col-12 ui-col-lg-3'>
                <TextField
                  name="clientCountry"
                  value={vm.clientCountry}
                  label={t('BasisOfEstimate.ConverLetter.ClientCountry')}
                  placeholder={t('BasisOfEstimate.ConverLetter.ClientCountry')}
                  disabled={saving}
                  error={errors.clientCountry}
                  onChange={(clientCountry) => {
                    clearError('clientCountry')
                    setVm((vm) => ({ ...vm, clientCountry }))
                  }}
                />
              </div>
              <div className="ui-col-12 ui-col-lg-1" />
              <div className='ui-col-12 ui-col-lg-2'>
                <TextField
                  name="clientPinCode"
                  value={vm.clientPinCode}
                  label={t('BasisOfEstimate.ConverLetter.ClientPinCode')}
                  placeholder={t('BasisOfEstimate.ConverLetter.ClientPinCode')}
                  disabled={saving}
                  error={errors.clientPinCode}
                  onChange={(clientPinCode) => {
                    clearError('clientPinCode')
                    setVm((vm) => ({ ...vm, clientPinCode }))
                  }}
                />
              </div>
            </div>
          </div>

          {/** flex button to the end of the form */}
          <div className="boe-form-action-container">
            <button type="submit" disabled={saving} className="btn-next">
              {t('General.Next')}
            </button>
          </div>
        </form>
      </>
    )
  }

export default BoeProject10
