import React, {SVGProps} from 'react';
import './style.scss';

const Lock: React.FC<SVGProps<SVGSVGElement>> = ({className, ...rest}) => (
    <svg viewBox="0 0 139 138" fill="none" xmlns="http://www.w3.org/2000/svg" className={`ui-svg ui-svg-lock ${className||''}`} {...rest}>
      <circle cx="69.5" cy="69" r="69"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M52.38 29.9442C45.5996 31.7381 38.7331 33.8588 34.6691 35.1532C33.551 35.5093 32.7658 36.4543 32.6184 37.5342C29.1603 62.8629 37.1504 81.3499 46.6841 93.523C51.4707 99.6347 56.6497 104.155 60.9673 107.127C63.1278 108.614 65.0378 109.689 66.5386 110.379C67.29 110.724 67.9045 110.957 68.3712 111.098C68.7556 111.214 68.9534 111.241 69.0003 111.248C69.0473 111.241 69.245 111.214 69.6294 111.098C70.0962 110.957 70.7107 110.724 71.4621 110.379C72.9629 109.689 74.8729 108.614 77.0334 107.127C81.3509 104.155 86.53 99.6347 91.3165 93.523C100.85 81.3499 108.84 62.8629 105.382 37.5342C105.235 36.4543 104.45 35.5093 103.332 35.1532C99.2676 33.8588 92.401 31.7381 85.6207 29.9442C78.6882 28.11 72.3146 26.75 69.0003 26.75C65.686 26.75 59.3124 28.11 52.38 29.9442ZM50.7174 23.6604C57.4961 21.8669 64.6955 20.25 69.0003 20.25C73.3051 20.25 80.5046 21.8669 87.2832 23.6604C94.214 25.4941 101.197 27.6515 105.304 28.9597C108.732 30.0514 111.325 33.0078 111.823 36.655C115.547 63.9386 106.91 84.1544 96.4339 97.5308C91.2155 104.194 85.5439 109.16 80.7187 112.481C78.3078 114.141 76.0733 115.413 74.1758 116.285C72.4226 117.091 70.5469 117.75 69.0003 117.75C67.4538 117.75 65.5781 117.091 63.8248 116.285C61.9273 115.413 59.6929 114.141 57.282 112.481C52.4567 109.16 46.7852 104.194 41.5667 97.5308C31.0905 84.1544 22.4532 63.9386 26.1782 36.6549C26.6761 33.0078 29.2688 30.0514 32.6966 28.9597C36.804 27.6515 43.7867 25.4941 50.7174 23.6604Z" />
      <path d="M78.75 59.25C78.75 63.4952 76.0369 67.1067 72.25 68.4452L74.7521 81.3829C75.1399 83.3882 73.6037 85.25 71.5612 85.25H66.4388C64.3963 85.25 62.8601 83.3882 63.2479 81.3829L65.75 68.4452C61.9631 67.1067 59.25 63.4952 59.25 59.25C59.25 53.8652 63.6152 49.5 69 49.5C74.3848 49.5 78.75 53.8652 78.75 59.25Z"/>
    </svg>
  )
export default Lock;