import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'
import { BudgetRange, BuildingCategory, ConstructionCategory, ConstructionType, DeliveryMethod, DisciplineSkill, EstimateType, PhaseType, ProcurementMethod } from '../dto/projectSupport'
import { PhaseTypeKey, ProjectType } from '../dto/projectShare'
import { aadConfig, getAccessToken } from '../aad'
import apiProxy from './apiProxy'

export const getConstructionCategories = apiProxy('getConstructionCategories', async (l?: string): Promise<ConstructionCategory[]> => {
  await getAccessToken(aadConfig().projectScopes)
  const { data } = await axios.get(`${process.env.REACT_APP_PROJECT_API}/support/constructioncategories${l ? `?l=${l}` : ''}`)
  return data
})

export const getDisciplineSkills = apiProxy('getDisciplineSkills', async (l?: string): Promise<DisciplineSkill[]> => {
  await getAccessToken(aadConfig().projectScopes)
  const { data } = await axios.get(`${process.env.REACT_APP_PROJECT_API}/support/disciplineskills${l ? `?l=${l}` : ''}`)
  return data
})

export const getConstructionTypes = apiProxy('getConstructionTypes', async (l?: string): Promise<ConstructionType[]> => {
  await getAccessToken(aadConfig().projectScopes)
  const { data } = await axios.get(`${process.env.REACT_APP_PROJECT_API}/support/constructiontypes${l ? `?l=${l}` : ''}`)
  return Object.keys(data).map((key) => ({ id: `${key[0].toUpperCase()}${key.substring(1)}`, type: data[key] }))
})

export const getPhaseTypes = apiProxy('getPhaseTypes', async (l?: string): Promise<PhaseType[]> => {
  await getAccessToken(aadConfig().projectScopes)
  const { data } = await axios.get(`${process.env.REACT_APP_PROJECT_API}/support/phasetypes${l ? `?l=${l}` : ''}`)
  return Object.keys(data).map((key) => ({ id: `${key[0].toUpperCase()}${key.substring(1)}` as PhaseTypeKey, type: data[key] }))
})

export const getProcurementMethods = apiProxy('getProcurementMethods', async (l?: string): Promise<ProcurementMethod[]> => {
  await getAccessToken(aadConfig().projectScopes)
  const { data } = await axios.get(`${process.env.REACT_APP_PROJECT_API}/support/procurementmethods${l ? `?l=${l}` : ''}`)
  return Object.keys(data).map((key) => ({ id: `${key[0].toUpperCase()}${key.substring(1)}`, method: data[key] }))
})

export const getDeliveryMethods = apiProxy('getDeliveryMethods', async (l?: string): Promise<DeliveryMethod[]> => {
  await getAccessToken(aadConfig().projectScopes)
  const { data } = await axios.get(`${process.env.REACT_APP_PROJECT_API}/support/deliverymethods${l ? `?l=${l}` : ''}`)
  return Object.keys(data).map((key) => ({ id: `${key[0].toUpperCase()}${key.substring(1)}`, method: data[key] }))
})

export const getBudgetRanges = apiProxy('getBudgetRanges', async (l?: string): Promise<BudgetRange[]> => {
  await getAccessToken(aadConfig().projectScopes)
  const { data } = await axios.get(`${process.env.REACT_APP_PROJECT_API}/support/budgetranges${l ? `?l=${l}` : ''}`)
  return Object.keys(data).map((key) => ({ id: `${key[0].toUpperCase()}${key.substring(1)}`, range: data[key] }))
})

export const getEstimateTypes = apiProxy('getEstimateTypes', async (l?: string): Promise<EstimateType[]> => {
  await getAccessToken(aadConfig().projectScopes)
  const { data } = await axios.get(`${process.env.REACT_APP_PROJECT_API}/support/estimatetypes${l ? `?l=${l}` : ''}`)
  return Object.keys(data).map((key) => ({ id: `${key[0].toUpperCase()}${key.substring(1)}`, type: data[key] }))
})

export const getBuildingCategories = apiProxy('getBuildingCategories', async (l?: string): Promise<BuildingCategory[]> => {
  await getAccessToken(aadConfig().projectScopes)
  const { data } = await axios.get<BuildingCategory[]>(`${process.env.REACT_APP_PROJECT_API}/support/buildingcategories${l ? `?l=${l}` : ''}`)
  return data
})

export const updateProjectType = apiProxy('updateProjectType', async (projectType: ProjectType, projectId?: string): Promise<ProjectType> => {
  await getAccessToken(aadConfig().projectScopes)
  const { data } = await axios.put(`${process.env.REACT_APP_PROJECT_API}/project-types/${projectType.id}${projectId ? `?projectId=${projectId}` : ''}`, projectType)
  return data
})

export interface ProjectSupportState {
  constructionCategories: ConstructionCategory[]
  constructionTypes: ConstructionType[]
  phaseTypes: PhaseType[]
  procurementMethods: ProcurementMethod[]
  deliveryMethods: DeliveryMethod[]
  budgetRanges: BudgetRange[]
  buildingCategories: BuildingCategory[]
  projectTypes: ProjectType[]
  estimateTypes: EstimateType[]
  language?: string
  loading: boolean
  error?: any
}

export const projectSupportAtom = atom<ProjectSupportState>({
  key: 'projectSupport',
  default: {
    constructionCategories: [],
    constructionTypes: [],
    phaseTypes: [],
    procurementMethods: [],
    deliveryMethods: [],
    budgetRanges: [],
    buildingCategories: [],
    projectTypes: [],
    estimateTypes: [],
    loading: true
  }
})

export type UseProjectSupportHook = [state: ProjectSupportState, refresh: () => void]

export const useProjectSupport = (): UseProjectSupportHook => {
  const [current, setCurrent] = useRecoilState(projectSupportAtom)
  const {
    i18n: { language }
  } = useTranslation()

  const refresh = useCallback(() => {
    setCurrent((c) => ({ ...c, loading: true }))

    Promise.all([
      getConstructionCategories(language),
      getConstructionTypes(language),
      getPhaseTypes(language),
      getProcurementMethods(language),
      getDeliveryMethods(language),
      getBudgetRanges(language),
      getBuildingCategories(language),
      getEstimateTypes(language)
    ])
      .then(([constructionCategories, constructionTypes, phaseTypes, procurementMethods, deliveryMethods, budgetRanges, buildingCategories, estimateTypes]) => {
        // Flat project types list
        let projectTypes: ProjectType[] = []
        buildingCategories.forEach((bc) => {
          projectTypes = [...projectTypes, ...bc.projectTypes]
        })
        projectTypes = projectTypes.sort((a, b) => {
          const ax = a.type.toUpperCase()
          const bx = b.type.toUpperCase()
          if (ax < bx) return -1
          return ax > bx ? 1 : 0
        })

        setCurrent({
          constructionCategories,
          constructionTypes,
          phaseTypes,
          procurementMethods,
          deliveryMethods,
          budgetRanges,
          buildingCategories,
          projectTypes,
          estimateTypes,
          language,
          loading: false,
          error: undefined
        })
      })
      .catch((error) => setCurrent((c) => ({ ...c, loading: false, error })))
  }, [language, setCurrent])

  useEffect(() => {
    if (current.language === language) return
    refresh()
  }, [current.language, language, refresh])

  return [current, refresh]
}
