import React, { useCallback, useMemo, useState } from 'react'
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Office, ResourceUserRole, User } from 'services/src/dto/account'
import { CreateInvitation, Invitation } from 'services/src/dto/invitation'
import { ConfirmModal } from 'components/src/modal/ConfirmModal'
import { InfoModal } from 'components/src/modal/InfoModal'
import { ResourceUsers, UserEx } from 'components/src/resourceUsers'
import { ChangeRoleModal } from 'components/src/resourceUsers/ChangeRoleModal'
import { useCurrentAccountWithUser, useCurrentUserAccount, useRights, useUserAccounts } from 'services/src/state'
import { TabBar, TabItem } from 'components/src/tabs'
import { InvitationList } from 'components/src/pages/settings/InvitationList'
import { createInvitation, deleteInvitation, resendInvitation } from 'services/src/api'
import { useSaving } from 'components/src/saving'
import { useAlert } from 'components/src/alerts'
import { InviteModal } from './InviteModal'

export interface OfficeStaffProps {
  office: Office
  onDeleteUser: (user: User) => void
  onChangeRole: (userId: string, role: ResourceUserRole) => void
  canEditOffice: boolean
}

const OfficeStaff: React.FC<OfficeStaffProps> = ({ office, canEditOffice, onDeleteUser, onChangeRole }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { url, path } = useRouteMatch()
  const rights = useRights()
  const [, setSaving] = useSaving()
  const { alertDanger, alertSuccess } = useAlert()
  const [, refreshAccounts] = useUserAccounts()
  const [account] = useCurrentUserAccount()
  const [accountWithUsers, refreshUsers] = useCurrentAccountWithUser()

  const roles = useMemo(
    () => [
      {
        role: ResourceUserRole.Owner,
        label: t(`General.OfficeRoles.${ResourceUserRole.Owner}`)
      },
      {
        role: ResourceUserRole.Administrator,
        label: t(`General.OfficeRoles.${ResourceUserRole.Administrator}`)
      },
      {
        role: ResourceUserRole.Contributor,
        label: t(`General.OfficeRoles.${ResourceUserRole.Contributor}`)
      },
      {
        role: ResourceUserRole.Guest,
        label: t(`General.OfficeRoles.${ResourceUserRole.Guest}`)
      }
    ],
    [t]
  )

  const [changeRole, setChangeRole] = useState<User>()
  const [confirmDeleteUser, setConfirmDeleteUser] = useState<User>()
  const [info, setInfo] = useState<{ title: string; message: string }>()
  const [invite, setInvite] = useState<boolean>(false)

  const readyUsers = useMemo<UserEx[] | undefined>(() => {
    if (!accountWithUsers) return undefined

    const users: UserEx[] = office.users!.map((x) => ({
      ...x,
      canEdit: true,
      roleLabel: t(`General.OfficeRoles.${x.role}`)
    }))

    accountWithUsers.users
      .filter((x) => x.role === ResourceUserRole.Owner || x.role === ResourceUserRole.Administrator)
      .forEach((x) => {
        if (users.find((u) => u.id === x.id)) return
        users.push({
          ...x,
          roleLabel: t(`General.AccountRoles.${x.role}`),
          badge: t('General.Inherited.Account'),
          isInherited: true
        })
      })

    return users
  }, [accountWithUsers, office, t])

  const readyInvitations = useMemo(() => accountWithUsers?.invitations?.filter((x) => x.status === 'Pending' && x.officeId === office.id && x.officeRole), [accountWithUsers, office])

  const handleSendInvitation = useCallback(
    (invitation: CreateInvitation) => {
      setInvite(false)
      setSaving(true)
      createInvitation(accountWithUsers!.id, invitation)
        .then(() => refreshAccounts().then(() => refreshUsers()))
        .then(() => {
          alertSuccess({
            title: t('EditOffice.InviteCreateTitle'),
            message: t('EditOffice.InviteCreate', {
              name: `${invitation.givenName} ${invitation.familyName}`,
              email: invitation.emailAddress
            })
          })
        })
        .catch((err) => {
          if (err.message.includes('409')) {
            alertDanger({
              title: t('Settings.Errors.DuplicateInviteTitle'),
              message: t('Settings.Errors.DuplicateInvite', {
                name: `${invitation.givenName} ${invitation.familyName}`,
                email: invitation.emailAddress
              }),
              error: err
            })
            return
          }
          alertDanger({
            title: t('EditOffice.Errors.InviteCreateTitle'),
            message: t('EditOffice.Errors.InviteCreate'),
            error: err
          })
        })
        .finally(() => setTimeout(() => setSaving(false)))
    },
    [accountWithUsers, setInvite, setSaving, alertSuccess, alertDanger, refreshAccounts]
  )

  const handleDeleteInvitation = useCallback(
    (invitation: Invitation) => {
      if (!accountWithUsers) return

      setSaving(true)
      deleteInvitation(accountWithUsers.id, invitation.id)
        .then(() => refreshAccounts().then(() => refreshUsers()))
        .then(() => {
          alertSuccess({
            title: t('EditOffice.DeletedInvitationTitle'),
            message: t('EditOffice.DeletedInvitation', {
              name: `${invitation.givenName} ${invitation.familyName}`,
              email: invitation.emailAddress
            })
          })
        })
        .catch((err) => {
          alertDanger({
            title: t('EditOffice.Errors.DeletedInvitationTitle'),
            message: t('EditOffice.Errors.DeletedInvitation'),
            error: err
          })
        })
        .finally(() => setTimeout(() => setSaving(false)))
    },
    [accountWithUsers, setSaving, alertSuccess, alertDanger, refreshAccounts]
  )

  const handleResendInvitation = useCallback(
    (invitation: Invitation) => {
      if (!accountWithUsers) return

      setSaving(true)
      resendInvitation(accountWithUsers.id, invitation.id)
        .then(() => {
          alertSuccess({
            title: t('EditOffice.InvitationResentTitle'),
            message: t('EditOffice.InvitationResent', {
              name: `${invitation.givenName} ${invitation.familyName}`,
              email: invitation.emailAddress
            })
          })
        })
        .catch((err) => {
          alertDanger({
            title: t('EditOffice.Errors.InvitationResentTitle'),
            message: t('EditOffice.Errors.InvitationResent'),
            error: err
          })
        })
        .finally(() => setTimeout(() => setSaving(false)))
    },
    [accountWithUsers, setSaving, alertSuccess, alertDanger, refreshAccounts]
  )

  const tabs = useMemo<TabItem[]>(() => {
    if (!rights) return []

    return [
      {
        label: t('General.Staff'),
        selected: pathname.endsWith('staff'),
        to: url
      },
      {
        label: t('General.Invitations'),
        to: `${url}/invitations`,
        selected: pathname.endsWith('invitations')
      }
    ]
  }, [rights, pathname, url, t])

  return (
    <>
      <TabBar tabs={tabs} className="settings-tab-bar" style={{ justifyContent: 'center' }} onChange={() => {}} />
      <br />

      <Switch>
        <Route path={`${path}/invitations`}>
          {readyInvitations && <InvitationList invitations={readyInvitations} roles={roles} onNew={() => setInvite(true)} onDelete={handleDeleteInvitation} onResend={handleResendInvitation} />}
        </Route>

        <Route path="*">
          <ResourceUsers
            users={readyUsers}
            onSelect={(user) => {
              if (canEditOffice) setChangeRole({ ...user })
            }}
            readOnly={!canEditOffice}
            onDelete={(user) => {
              if (!readyUsers) return

              if (user.role !== ResourceUserRole.Owner) {
                setConfirmDeleteUser(user)
                return
              }

              const owners = readyUsers.filter((x) => x.id && x.role === ResourceUserRole.Owner)
              if (owners.length > 1) {
                setConfirmDeleteUser(user)
                return
              }

              setInfo({
                title: t('EditOffice.DeleteOwnerTitle'),
                message: t('EditOffice.DeleteOwner')
              })
            }}
          />
        </Route>
      </Switch>

      {changeRole && (
        <ChangeRoleModal
          title={t('Settings.ChangeRoleTitle')}
          message={t('Settings.ChangeRole')}
          roles={roles}
          selected={changeRole.role}
          yes={t('General.Ok')}
          no={t('General.Cancel')}
          onClose={() => setChangeRole(undefined)}
          onYes={(role) => {
            if (!readyUsers || changeRole.role === role) {
              setChangeRole(undefined)
              return
            }

            if (changeRole.role === ResourceUserRole.Owner) {
              const owners = readyUsers.filter((x) => x.role === ResourceUserRole.Owner)
              if (owners.length <= 1) {
                setInfo({
                  title: t('EditOffice.ChangeOwnerRoleTitle'),
                  message: t('EditOffice.ChangeOwnerRole')
                })
                return
              }
            }
            onChangeRole(changeRole.id, role as ResourceUserRole)
            setChangeRole(undefined)
          }}
        />
      )}

      {info && <InfoModal title={info.title} message={info.message} ok={t('General.Ok')} onClose={() => setInfo(undefined)} />}

      {invite && account && <InviteModal account={account} offices={[office]} office={office} onSend={handleSendInvitation} onClose={() => setInvite(false)} />}

      {confirmDeleteUser && (
        <ConfirmModal
          title={t('EditOffice.DeletePeopleTitle')}
          message={t('EditOffice.DeletePeople', {
            name: `${confirmDeleteUser.givenName} ${confirmDeleteUser.familyName}`
          })}
          yes={t('General.Yes')}
          no={t('General.No')}
          onYes={() => {
            setConfirmDeleteUser(undefined)
            setTimeout(() => onDeleteUser(confirmDeleteUser))
          }}
          onClose={() => setConfirmDeleteUser(undefined)}
        />
      )}
    </>
  )
}
export default OfficeStaff
